import { useState } from "react";
import StorageKeys from '../constants/storageKeys';
import { isEmpty } from "lodash";

export default function useEvent() {

  const [event, setActualEvent] = useState(
    () => {
      const currentEvent = JSON.parse(localStorage.getItem(StorageKeys.CURRENT_EVENT)) || {}
      return currentEvent;
    }
  );

  const setEvent = (event) => {
    if (isEmpty(event)) {
      localStorage.removeItem(StorageKeys.CURRENT_EVENT);
    } else {
      localStorage.setItem(StorageKeys.CURRENT_EVENT, JSON.stringify(event));
    }
    setActualEvent(event)
  }

  return [event, setEvent];
}
import React, { useContext, useState, useEffect } from 'react';
import { useNavigate, useLocation, Outlet } from 'react-router-dom';
import { Menu, Row, Col, Dropdown, Grid, Drawer } from 'antd';
import { startsWith } from 'lodash';
import AppContext from '../../app/context';
import djpcLogoVertical from '../../images/djpc-logo-vertical.png';
import { logout, getAccountLogo } from '../../api';
import useApi from '../../hooks/useApi';
import { UserOutlined, LogoutOutlined } from '@ant-design/icons';
import { FiMusic, FiFileText, FiHome, FiUser, FiList, FiMenu, FiShoppingBag } from "react-icons/fi";
import { HiOutlineClipboardList } from "react-icons/hi";
import { MdOutlineClose } from "react-icons/md";
import { ImMobile2 } from "react-icons/im";
import './clientLayout.style.scss';
import { getDeviceId } from '../../helpers/deviceHelper';
import EventPermissions from '../../constants/eventPermissions';
import { hasEventPermission } from '../../helpers/permissionHelper';

const ClientLayout = () => {

  const { auth, setAuth, setCurrentEvent } = useContext(AppContext);

  const [isDrawerVisible, setDrawerVisible] = useState(false);
  const [isLogoLoaded, setLogoLoaded] = useState(false);
  const [accountLogo, setAccountLogo] = useState("");

  const [sendRequest] = useApi()
  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    loadPage()
  }, [])

  const loadPage = async () => {
    try {
      const response = await sendRequest(getAccountLogo())
      if (response.logo) {
        setAccountLogo(response.logo)
      }
    } catch (error) {
      console.log(error)
    } finally {
      setLogoLoaded(true)
    }
  }

  const onLogout = async () => {
    try {
      if (auth.user) {
        await logout(auth.user.user_id, getDeviceId())
      }
      setDrawerVisible(false)
      setAuth({})
      setCurrentEvent({})
    } catch (error) {
      console.log(error)
    }
  }

  const onNavigate = (path) => {
    navigate(path)
    setDrawerVisible(false)
  }

  const menu = () => {
    return (
      <Menu>
        <Menu.Item>
          <div onClick={() => navigate("/client/account")}>
            <UserOutlined style={{ marginRight: 8}}/> My Account
          </div>
        </Menu.Item>
        <Menu.Item>
          <div onClick={onLogout}>
            <LogoutOutlined style={{ marginRight: 8}}/> Sign Out
          </div>
        </Menu.Item>
      </Menu>
    )
  };

  const renderMobileMenuIcon = () => {
    if (!screens.md) {
      return (
        <Col>
          <div className="display-flex" onClick={() => setDrawerVisible(true)}>
            <FiMenu size={24}/>
          </div>
        </Col>
      )
    }
  }

  const renderUserIcon = () => {
    if (screens.md) {
      return (
        <Col>
          <Dropdown overlay={menu} placement="bottomRight" trigger="click">
            <div>
              <div className="profile-container">
                { auth.user && auth.user.photo ? (
                  <div className="small-profile-photo" style={{ backgroundImage: `url(${auth.user.photo})`}}></div>
                ) : (
                  <div className="profile-circle">
                    <UserOutlined />
                  </div>
                )}
                { screens.md && (
                  <div className="profile-name">{ auth.user && auth.user.first_name } {auth.user && auth.user.last_name }</div>
                )}
              </div>
            </div>
          </Dropdown>
        </Col>
      )
    }
  }

  const renderLogo = () => {
    if (!isLogoLoaded) {
      return null
    }
    if (accountLogo) {
      return (
        <img src={accountLogo}/>
      )
    } else {
      return (
        <img src={djpcLogoVertical}/>
      )
    }
  }

  const renderHeader = () => {
    return (
      <Row align="middle" gutter={[10,10]} className="flex-1">
        <Col>
          <div className="user-layout--logo" onClick={() => navigate("/")}>
            { renderLogo() }
          </div>
        </Col>
        <Col flex={1}>
        </Col>
        { renderUserIcon() }
        { renderMobileMenuIcon() }
      </Row>
    )
  }

  const NavMenuItem = (props) => {
    const { title, icon, path } = props;
    const selectedClass = startsWith(location.pathname, path) ? "selected" : ""
    return (
      <div className={`nav-menu-item ${selectedClass}`} onClick={() => onNavigate(path)}>
        <div className="nav-menu-item--icon">
          { icon }
        </div>
        <div className="nav-menu-item--title">
          { title }
        </div>
      </div>
    )
  }

  const renderMenu = () => {
    return (
      <>
        <NavMenuItem title="Home" icon={<FiHome />} path="/client/home"/>
        { hasEventPermission(auth.user, EventPermissions.VIEW_MUSIC) && (
          <NavMenuItem title="Music" icon={<FiMusic />} path="/client/music"/>
        )}
        { hasEventPermission(auth.user, EventPermissions.VIEW_QUESTIONNAIRES) && (
          <NavMenuItem title="Questionnaires" icon={<FiFileText />} path="/client/questionnaires"/>
        )}
        { hasEventPermission(auth.user, EventPermissions.VIEW_TIMELINE) && (
          <NavMenuItem title="Timeline" icon={<FiList />} path="/client/timeline"/>
        )}
        { (hasEventPermission(auth.user, EventPermissions.VIEW_FINANCIALS) || hasEventPermission(auth.user, EventPermissions.VIEW_SERVICES)) && (
          <NavMenuItem title="Services" icon={<FiShoppingBag />} path="/client/services"/>
        )}
        { hasEventPermission(auth.user, EventPermissions.VIEW_DOCUMENTS) && (
          <NavMenuItem title="Documents" icon={<HiOutlineClipboardList />} path="/client/documents"/>
        )}
        <NavMenuItem title="Account" icon={<FiUser />} path="/client/account"/>
        <div className="b-border mv-10"></div>
        <NavMenuItem title="Mobile App" icon={<ImMobile2 />} path="/client/mobile-app"/>
      </>
    )
  }

  const renderSider = () => {
    if (screens.md) {
      return (
        <div className="user-layout--sider">
          { renderMenu() }
        </div>
      )
    }
  }

  const renderMain = () => {
    const paddingLeft = screens.md ? "240px" : "";
    return (
      <div className="user-layout--main" style={{ paddingLeft: paddingLeft }}>
        <Outlet/>
      </div>
    )
  }

  return (
    <div className="user-layout">
      <div className="user-layout--header">
        { renderHeader() }
      </div>
      <div className="user-layout--body">
        { renderSider() }
        { renderMain() }
      </div>
      <Drawer placement="right" width={240} closable={false} onClose={() => setDrawerVisible(false)} visible={isDrawerVisible} bodyStyle={{ padding: 0, paddingBottom: 20, overflowX: 'hidden' }}>
        <Row align="middle" className="p-15">
          <Col flex={1}>
          </Col>
          <Col>
            <div className="display-flex" onClick={() => setDrawerVisible(false)}><MdOutlineClose size={30} color={"#CCC"}/></div>
          </Col>
        </Row>
        <div className="mobile-profile mb-10">
          { auth.user && auth.user.photo ? (
            <div className="small-profile-photo size-80" style={{ backgroundImage: `url(${auth.user.photo})`}}></div>
          ) : (
            <div className="mobile-profile-circle">
              <UserOutlined />
            </div>
          )}
          <div className="fw-700 mt-10">{ auth.user && auth.user.first_name } {auth.user && auth.user.last_name }</div>
        </div>
        { renderMenu() }
        { !screens.md && (
          <div className="ph-20 mt-10 pt-20 t-border">
            <button className="page-title-button" style={{ width: '100%'}} onClick={() => onLogout()}>Sign Out</button> 
          </div>
        )}
      </Drawer>
    </div>
  );
};

export default ClientLayout;

import React, { useEffect } from "react";
import AdminContent from '../../components/adminContent';
import PaymentStatus from '../../components/paymentMethodStatus';
import FloatingContainer from '../../components/floatingContainer'
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const PaymentMethodCompletePage = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const renderElements = () => {  
    return (
      <Elements stripe={stripePromise}>
        <PaymentStatus />
      </Elements>
    );
  }

  const renderContent = () => {
    return (
      <FloatingContainer className="ph-20" verticalPadding={40} maxWidth={600}>
        { renderElements() }
      </FloatingContainer>
    )
  }

  return (
    <AdminContent body={renderContent()}/>
  );
}

export default PaymentMethodCompletePage;

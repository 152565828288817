import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { FaCheckCircle } from "react-icons/fa";
import NumberFormat from 'react-number-format';
import { MdOutlineEdit, MdOutlineClose, MdErrorOutline, MdCheckCircleOutline } from "react-icons/md";
import { BsArrowLeft, BsClockHistory } from "react-icons/bs";
import { Row, Col, Input, Modal, notification } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import FloatingContainer from '../../components/floatingContainer'
import LoadingSpinner from '../../components/loading';
import { cloneDeep, get, isNull, isUndefined, startsWith } from "lodash";
import { 
  getInvoicePaymentInfo, 
  createStripeCheckoutSession, 
  createPayPalOrder, 
  capturePayPalPayment,
  generateClientToken, 
  confirmManualPayment, 
  deletePendingInvoicePayment
} from '../../api';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import { formatCurrencyString } from '../../helpers/contractHelper';
import { formatDateShort, formatDateMedium } from '../../helpers/dateHelper';
import {loadStripe} from '@stripe/stripe-js';
import {
  Elements,
  PaymentElement,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
import { 
  PayPalScriptProvider, 
  PayPalButtons, 
  PayPalHostedFieldsProvider,
  PayPalHostedField,
  usePayPalScriptReducer 
} from "@paypal/react-paypal-js";

const BASE_URL = process.env.REACT_APP_BASE_URL;
const PAYPAL_CLIENT_ID = process.env.REACT_APP_PAYPAL_CLIENT_ID

const InvoicePaymentPage = () => {

  const [isLoading, setLoading] = useState(true);
  const [invoiceStatus, setInvoiceStatus] = useState("");
  const [eventInvoice, setEventInvoice] = useState({});
  const [stripeClientSecret, setStripeClientSecret] = useState("");
  const [stripePromise, setStripePromise] = useState("");
  const [payPalMerchantId, setPayPalMerchantId] = useState("");
  const [clientToken, setClientToken] = useState("");
  const [manualPaymentMethodMessage, setManualPaymentMethodMessage] = useState("");
  const [isStripeConnected, setStripeConnected] = useState(false);
  const [isPayPalConnected, setPayPalConnected] = useState(false);
  const [paymentSchedulePayments, setPaymentSchedulePayments] = useState([]);
  const [selectedPaymentIds, setSelectedPaymentIds] = useState([]);
  const [selectedTipValue, setSelectedTipValue] = useState(0);
  const [selectedTipOption, setSelectedTipOption] = useState("");
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState({});
  const [isTipsEnabled, setTipsEnabled] = useState(false);
  const [pendingPayment, setPendingPayment] = useState({});

  const [hasMultiplePayments, setMultiplePayments] = useState(false);
  const [hasMultiplePaymentMethods, setMultiplePaymentMethods] = useState(false);
  const [isPaymentAmountSelected, setPaymentAmountSelected] = useState(false);
  const [isPaymentMethodSelected, setPaymentMethodSelected] = useState(false);
  const [isTipSelected, setTipSelected] = useState(false);
  const [isBalanceOverride, setBalanceOverride] = useState(false);
  const [hasPendingPayment, setHasPendingPayment] = useState(false);

  useDocumentTitle("Invoice Payment")
  const params = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0)
    if (params.id) {
      loadPage()
    } else {
      setInvoiceStatus("INVALID")
      setLoading(false)
    }
  }, [])

  const loadPage = async () => {
    try {
      const results = await getInvoicePaymentInfo(params.id)
      const status = get(results, "data.status", null)
      if (status == "not-found" || status == "failed") {
        setInvoiceStatus("INVALID")
        setLoading(false)
        return
      }
      setEventInvoice(results.data)

      // Check if there is a pending manual payment
      const hasPendingManualPayment = get(results, "data.has_pending_manual_payment", false)
      const pendingManualPayment = get(results, "data.pending_manual_payment", {})
      if (hasPendingManualPayment) {
        setPendingPayment(pendingManualPayment)
      }

      const initIsMultiplePayments = get(results, "data.multiple_payments", false)
      const initIsMultiplePaymentMethods = get(results, "data.multiple_payment_methods", false)
      const initIsTipsEnabled = get(results, "data.tips", false)
      const initBalanceOverride = get(results, "data.has_custom_payment", false)
      const initHasPendingPayment = get(results, "data.has_pending_manual_payment", false)
      setMultiplePayments(initIsMultiplePayments)
      setMultiplePaymentMethods(initIsMultiplePaymentMethods)
      setTipsEnabled(initIsTipsEnabled)
      setBalanceOverride(initBalanceOverride)
      setHasPendingPayment(initHasPendingPayment)

      const paymentScheduleItems = get(results, "data.payment_schedules", [])
      const paymentMethodResults = get(results, "data.payment_methods", [])
      const stripeObject = get(results, "data.stripe", {})
      const paypalObject = get(results, "data.paypal", {})

      setPaymentSchedulePayments(paymentScheduleItems)
      setPaymentMethods(paymentMethodResults)

      // Automatically select the first payment
      var initSelectedPaymentIds = []
      if (paymentScheduleItems.length > 0) {
        initSelectedPaymentIds = [paymentScheduleItems[0].payment_schedule_id]
        setSelectedPaymentIds(initSelectedPaymentIds)
      }

      // Set default selected payment method
      var initSelectedPaymentMethodId = ""
      if (paymentMethodResults.length > 0) {
        setSelectedPaymentMethod(paymentMethodResults[0])
        initSelectedPaymentMethodId = paymentMethodResults[0].id
      }

      // If Stripe is connected...
      if (stripeObject.connected) {
        const stripePromiseResults = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY, { stripeAccount: stripeObject.stripe_account_id });
        setStripePromise(stripePromiseResults)
        setStripeConnected(true)
      }

      // If PayPal is connected...
      if (paypalObject.connected) {
        setPayPalMerchantId(paypalObject.merchant_id)
        setPayPalConnected(true)
      }

      // If this is a simple screen without stages, get necessary payment processing info
      if (!initIsMultiplePayments && !initIsMultiplePaymentMethods && !isTipsEnabled) {
        // Get Stripe info
        if (stripeObject.connected) {
          const body = {
            invoice_id: params.id,
            payment_schedule_ids: initSelectedPaymentIds,
            tip_amount: 0,
            selected_payment_method: initSelectedPaymentMethodId
          }
          const results = await createStripeCheckoutSession(body)
          setStripeClientSecret(results.data.client_secret)
        }
        // Get PayPal credit card token if Stripe is not connected
        if (paypalObject.connected && !stripeObject.connected) {
          const results = await generateClientToken({ invoice_id: params.id })
          const clientTokenResults = get(results, "data.client_token", "")
          setClientToken(clientTokenResults)
        }
      }

      setLoading(false)
    } catch (error) {
      console.log(error)
      setInvoiceStatus("INVALID")
      setLoading(false)
    } finally {
      setLoading(false)
    }
  }

  const createOrder = async () => {
    try {
      const body = {
        invoice_id: params.id,
        payment_schedule_ids: selectedPaymentIds,
        balance_override: isBalanceOverride,
        tip_amount: selectedTipValue
      }
      const results = await createPayPalOrder(body)
      return results.data.id
    } catch {
      return ""
    }
  }

  const onApprove = async (data) => {
    try {
      const body = {
        invoice_id: params.id,
        order_id: data.orderID
      }
      const results = await capturePayPalPayment(body)
      console.log(results)

    } catch {}
  }

  const onPaymentOptionClick = (id) => {
    var newSelectedPaymentIds = cloneDeep(selectedPaymentIds)

    // Do nothing if it's the first payment
    if (newSelectedPaymentIds.length > 0 && newSelectedPaymentIds[0] == id) {
      return
    }

    // Toggle selected
    if (newSelectedPaymentIds.includes(id)) {
      newSelectedPaymentIds = newSelectedPaymentIds.filter(x => x != id)
    } else {
      newSelectedPaymentIds.push(id)
    }

    setSelectedPaymentIds(newSelectedPaymentIds)
  }

  const onTipOptionClick = (option, value) => {
    setSelectedTipOption(option)
    if (option == "CUSTOM") {
      setSelectedTipValue(null)
    } else {
      setSelectedTipValue(value)
    }
  }

  const getPaymentAmount = () => {
    if (isBalanceOverride) {
      return Number(eventInvoice.balance)
    }
    return paymentSchedulePayments.reduce((x,y) => {
      if (selectedPaymentIds.includes(y.payment_schedule_id)) {
        return x + Number(y.amount)
      }
      return x;
    }, 0)
  }

  const getTotalAmount = () => {
    const tipAmount = !isNull(selectedTipValue) ? selectedTipValue : 0
    return getPaymentAmount() + tipAmount
  }

  const onChooseAmount = async () => {
    setPaymentAmountSelected(true)
    setTipSelected(false)
    setSelectedTipOption("")
    setSelectedTipValue(0)

    if (selectedPaymentMethod.manual || hasMultiplePaymentMethods || isTipsEnabled) {
      return
    }
    getPaymentProcessorInfo()
  }

  const onChooseMethod = async () => {
    setPaymentMethodSelected(true)
    if (selectedPaymentMethod.manual) {
      return
    }
    getPaymentProcessorInfo()
  }

  const onChooseTip = async () => {
    setTipSelected(true)
    setPaymentMethodSelected(false)
    if (hasMultiplePaymentMethods) {
      return
    }
    await getPaymentProcessorInfo()
  }

  const getPaymentProcessorInfo = async () => {
    try {
      setStripeClientSecret("")
      setClientToken("")

      // Get Stripe client secret
      if (isStripeConnected) {
        const body = {
          invoice_id: eventInvoice.invoice_id,
          payment_schedule_ids: selectedPaymentIds,
          balance_override: isBalanceOverride,
          tip_amount: selectedTipValue,
          selected_payment_method: selectedPaymentMethod.id
        }
        const results = await createStripeCheckoutSession(body)
        setStripeClientSecret(results.data.client_secret)
      }

      // Get PayPal client token (if credit card fields are being displayed)
      if (isPayPalConnected && !isStripeConnected) {
        const results = await generateClientToken({ invoice_id: params.id })
        const clientTokenResults = get(results, "data.client_token", "")
        setClientToken(clientTokenResults)
      }
    } catch {}
  }

  const isValidLink = (link) => {
    return !isUndefined(link) && !isNull(link) && link != ""
  }

  const onConfirmManualPayment = async () => {
    try {
      const body = {
        payment_method_name: selectedPaymentMethod.name,
        payment_amount: getTotalAmount(),
        tip_amount: selectedTipValue,
        message: manualPaymentMethodMessage,
        payment_schedule_ids: selectedPaymentIds
      }
      await confirmManualPayment(params.id, body)

      if (isValidLink(selectedPaymentMethod.link)) {
        var paymentLink = selectedPaymentMethod.link
        if (!startsWith(paymentLink, "https") || !startsWith(paymentLink, "http")) {
          paymentLink = `https://${paymentLink}`
        }
        window.open(paymentLink, "_blank")
      }
      navigate(`/v/invoice/${params.id}/confirmation?type=manual`)
    } catch {
      notification.error({
        message: 'Error!',
        description: 'There was a problem sending your message.',
        duration: 3
      });
    }
  }

  const onRemovePendingPayment = async () => {
    try {
      if (pendingPayment.invoice_payment_id) {
        await deletePendingInvoicePayment(pendingPayment.invoice_payment_id)
        await loadPage()
        notification.success({
          message: 'Success!',
          description: 'Your pending payment has been removed.',
          duration: 3
        });
      } else {
        notification.error({
          message: 'Error!',
          description: 'There was a problem removing your pending payment.',
          duration: 3
        });
      }
    } catch {
      notification.error({
        message: 'Error!',
        description: 'There was a problem removing your pending payment.',
        duration: 3
      });
    }
  }

  // ----------------
  // OPTION BOXES
  // ----------------
  const renderPaymentOption = (option, index) => {
    const selected = selectedPaymentIds.includes(option.payment_schedule_id)
    const selectedClass = selected ? { border: '1px solid #536DFE'} : { border: '1px solid #CCC'}
    return (
      <Col xs={24} sm={12} key={index}>
        <div className="ph-20 cursor-pointer bg-white" style={selectedClass} onClick={() => onPaymentOptionClick(option.payment_schedule_id)}>
          <Row align="middle">
            <Col flex={1} className="text-center">
              <div style={{ height: 26, marginTop: 20, textAlign: "right" }}>
                { selected ? (
                  <FaCheckCircle style={{ fontSize: 20, color: '#536DFE' }}/>
                ): (
                  <FaCheckCircle style={{ fontSize: 20, color: '#dcdcdc' }}/>
                )}
              </div>
              <div className="">Payment #{index + 1}</div>
              <div className="fs-20 fw-700">{formatCurrencyString(option.amount, eventInvoice.currency)}</div>
              <div className="c-text-gray">Due {formatDateShort(option.due_date, eventInvoice.account_settings, true)}</div>
              <div style={{ height: 26, marginBottom: 20 }}></div>
            </Col>
          </Row>
        </div>
      </Col>
    )
  }

  const renderTipOption = (text, value, option) => {
    var tipValue = 0
    if (option != "NO_TIP" && option != "CUSTOM") {
      tipValue = getPaymentAmount() * value
    }
    const selected = selectedTipOption == option
    const selectedClass = selected ? { border: '1px solid #536DFE'} : { border: '1px solid #CCC'}
    return (
      <Col xs={12} sm={8}>
        <div className="p-20 cursor-pointer bg-white" style={selectedClass} onClick={() => onTipOptionClick(option, tipValue)}>
          <div style={{ height: 26, textAlign: "right" }}>
            { selected && (
              <FaCheckCircle style={{ fontSize: 20, color: '#536DFE' }}/>
            )}
          </div>
          <div style={{ height: 60, display: "flex", flexDirection: "column", alignItems: 'center', justifyContent: 'center' }}>
            <div className="fs-20 fw-700">{text}</div>
            { tipValue > 0 && (
              <div className="c-text-gray">{formatCurrencyString(tipValue, eventInvoice.currency)}</div>
            )}
          </div>
          <div style={{ height: 26 }}></div>
        </div>
      </Col>
    )
  }

  const renderPaymentMethodOption = (option, index) => {
    const selected = option.id == selectedPaymentMethod.id
    const selectedClass = selected ? { border: '1px solid #536DFE'} : { border: '1px solid #CCC'}
    return (
      <Col xs={24} sm={12} key={index}>
        <div className="ph-20 cursor-pointer bg-white" style={selectedClass} onClick={() => setSelectedPaymentMethod(option)}>
          <Row align="middle">
            <Col flex={1} className="text-center">
              <div style={{ height: 26, marginTop: 20, textAlign: "right" }}>
                { selected && (
                  <FaCheckCircle style={{ fontSize: 20, color: '#536DFE' }}/>
                )}
              </div>
              <div className="pv-10 fw-700 fs-16">{option.name}</div>
              <div style={{ height: 26, marginBottom: 20 }}></div>
            </Col>
          </Row>
        </div>
      </Col>
    )
  }

  // ----------------
  // SELECTED SECTIONS
  // ----------------
  const renderSelectedPaymentAmountSection = () => {
    return (
      <>
        <div className="fs-24 fw-700">Payment amount</div>
        <Row align="middle" className="mb-50">
          <Col flex={0}>
            <div className="fs-18">{formatCurrencyString(getPaymentAmount(), eventInvoice.currency)}</div>
          </Col>
          <Col flex={0}>
            <div className="display-flex" onClick={() => setPaymentAmountSelected(false)}><MdOutlineEdit style={{ fontSize: 20, color: '#999', marginLeft: 10 }}/></div>
          </Col>
        </Row>
      </>
    )
  }

  const renderSelectedTipAmountSection = () => {
    const tipText = selectedTipValue > 0 ? formatCurrencyString(selectedTipValue, eventInvoice.currency) : "None"
    return (
      <>
        <div className="fs-24 fw-700">Tip</div>
        <Row align="middle" className="mb-50">
          <Col flex={0}>
            <div className="fs-18">{tipText}</div>
          </Col>
          <Col flex={0}>
            <div className="display-flex" onClick={() => setTipSelected(false)}><MdOutlineEdit style={{ fontSize: 20, color: '#999', marginLeft: 10 }}/></div>
          </Col>
        </Row>
      </>
    )
  }

  const renderSelectedPaymentMethodSection = () => {
    return (
      <>
        <Row align="middle" className="mb-10">
          <Col flex={0}>
            <div className="fs-24 fw-700">Pay with {selectedPaymentMethod.name}</div>
          </Col>
          <Col flex={0}>
            <div className="display-flex" onClick={() => setPaymentMethodSelected(false)}><MdOutlineEdit style={{ fontSize: 20, color: '#999', marginLeft: 10 }}/></div>
          </Col>
        </Row>
      </>
    )
  }

  // ----------------
  // READONLY SECTIONS
  // ----------------
  const renderSinglePaymentSection = () => {
    return (
      <>
        <div className="fs-24 fw-700">Payment amount</div>
        <div className="fs-18 mb-50">{formatCurrencyString(getPaymentAmount(), eventInvoice.currency)}</div>
      </>
    )
  }

  const renderSinglePaymentMethodSection = () => {
    return (
      <>
         <div className="fs-24 fw-700 mb-10">Pay with {selectedPaymentMethod.name}</div>
      </>
    )
  }

  // ----------------
  // SELECT SECTIONS
  // ----------------
  const renderChoosePaymentMethodSection = () => {
    return (
      <>
        <div className="fs-24 fw-700 mb-15">How would you like to pay?</div>
        <Row gutter={[15, 15]} className="mb-20">
          { paymentMethods.map((x,i) => renderPaymentMethodOption(x,i) )}
        </Row>
        <button className="primary-button" style={{ borderRadius: 0, width: 300 }} onClick={() => onChooseMethod()}>Continue</button>
      </>
    )
  }

  const renderChoosePaymentAmountSection = () => {
    return (
      <>
          <div className="fs-24 fw-700">Choose your payment amount</div>
          <div className="fs-16 mb-30">Your invoice is split into {paymentSchedulePayments.length} payments</div>
          <Row gutter={[15, 15]} className="mb-20">
            { paymentSchedulePayments.map((x,i) => renderPaymentOption(x,i) )}
          </Row>
          <button className="primary-button" style={{ borderRadius: 0, width: 300 }} onClick={() => onChooseAmount()}>Pay {formatCurrencyString(getPaymentAmount(), eventInvoice.currency)}</button>
      </>
    )
  }

  const chooseTipSection = () => {
    const symbol = "$"
    return (
      <>
          <div className="fs-24 fw-700 mb-20">Would you like to add a tip?</div>
          <Row gutter={[15, 15]} className="mb-20">
            { renderTipOption("5%", .05, "5_PERCENT")}
            { renderTipOption("10%", .10, "10_PERCENT")}
            { renderTipOption("15%", .15, "15_PERCENT")}
            { renderTipOption("Custom", 0, "CUSTOM")}
            { renderTipOption("No tip", 0, "NO_TIP")}
          </Row>
          { selectedTipOption == "CUSTOM" && (
            <div>
              <div className="fw-700 mb-10">Enter a tip amount:</div>
              <NumberFormat 
                className="ant-input ant-input-lg mb-15" 
                displayType={'input'} 
                thousandSeparator={true} 
                prefix={symbol} 
                decimalScale={2} 
                fixedDecimalScale={true}
                style={{ width: 300 }}
                placeholder={`${symbol}0.00`}
                value={selectedTipValue}
                onValueChange={(e) => setSelectedTipValue(Number(e.value))}
              />
            </div>
          )}
          <button className="primary-button" style={{ borderRadius: 0, width: 300 }} onClick={() => onChooseTip()} disabled={selectedTipOption == ""}>Continue</button>
      </>
    )
  }

  // ----------------
  // INDIVIDUAL FLOWS
  // ----------------
  const renderMultiPaymentMultiMethodTips = () => {
    if (!isPaymentAmountSelected) {
      return renderChoosePaymentAmountSection()
    } else if (!isTipSelected) {
      return (
        <>
          { renderSelectedPaymentAmountSection() }
          { chooseTipSection() }
        </>
      )
    } else if (!isPaymentMethodSelected) {
      return (
        <>
          { renderSelectedPaymentAmountSection() }
          { renderSelectedTipAmountSection() }
          { renderChoosePaymentMethodSection() }
        </>
      )
    } else {
      return (
        <>
          { renderSelectedPaymentAmountSection() }
          { renderSelectedTipAmountSection() }
          { renderSelectedPaymentMethodSection() }
          { renderPaymentProcessorFields() }
        </>
      )
    }
  }

  const renderMultiPaymentMultiMethodNoTips = () => {
    if (!isPaymentAmountSelected) {
      return renderChoosePaymentAmountSection()
    } else if (!isPaymentMethodSelected) {
      return (
        <>
          { renderSelectedPaymentAmountSection() }
          { renderChoosePaymentMethodSection() }
        </>
      )
    } else {
      return (
        <>
          { renderSelectedPaymentAmountSection() }
          { renderSelectedPaymentMethodSection() }
          { renderPaymentProcessorFields() }
        </>
      )
    }
  }

  const renderMultiPaymentSingleMethodTips = () => {
    if (!isPaymentAmountSelected) {
      return renderChoosePaymentAmountSection()
    } else if (!isTipSelected) {
      return (
        <>
          { renderSelectedPaymentAmountSection() }
          { chooseTipSection() }
        </>
      )
    } else {
      return (
        <>
          { renderSelectedPaymentAmountSection() }
          { renderSelectedTipAmountSection() }
          { renderSinglePaymentMethodSection() }
          { renderPaymentProcessorFields() }
        </>
      )
    }
  }

  const renderMultiPaymentSingleMethodNoTips = () => {
    if (!isPaymentAmountSelected) {
      return renderChoosePaymentAmountSection()
    } else {
      return (
        <>
          { renderSelectedPaymentAmountSection() }
          { renderSinglePaymentMethodSection() }
          { renderPaymentProcessorFields() }
        </>
      )
    }
  }

  const renderSinglePaymentMultiMethodTips = () => {
    if (!isTipSelected) {
      return (
        <>
          { renderSinglePaymentSection() }
          { chooseTipSection() }
        </>
      )
    } else if (!isPaymentMethodSelected) {
      return (
        <>
          { renderSinglePaymentSection() }
          { renderSelectedTipAmountSection() }
          { renderChoosePaymentMethodSection() }
        </>
      )
    } else {
      return (
        <>
          { renderSinglePaymentSection() }
          { renderSelectedTipAmountSection() }
          { renderSelectedPaymentMethodSection() }
          { renderPaymentProcessorFields() }
        </>
      )
    }
  }

  const renderSinglePaymentMultiMethodNoTips = () => {
    if (!isPaymentMethodSelected) {
      return (
        <>
          { renderSinglePaymentSection() }
          { renderChoosePaymentMethodSection() }
        </>
      )
    } else {
      return (
        <>
          { renderSinglePaymentSection() }
          { renderSelectedPaymentMethodSection() }
          { renderPaymentProcessorFields() }
        </>
      )
    }
  }

  const renderSinglePaymentSingleMethodTips = () => {
    if (!isTipSelected) {
      return (
        <>
          { renderSinglePaymentSection() }
          { chooseTipSection() }
        </>
      )
    } else {
      return (
        <>
          { renderSinglePaymentSection() }
          { renderSelectedTipAmountSection() }
          { renderPaymentProcessorFields() }
        </>
      )
    }
  }

  const renderSinglePaymentSingleMethodNoTips = () => {
    return (
      <>
        { renderPaymentProcessorFields() }
      </>
    )
  }

  // ---------------------
  // ALL POSSIBLE STAGES
  // ---------------------
  const renderStages = () => {
    if (hasMultiplePayments && hasMultiplePaymentMethods && isTipsEnabled) {
      // Multiple Payments + Multiple Methods + Tips Enabled
      return renderMultiPaymentMultiMethodTips()
    } else if (hasMultiplePayments && hasMultiplePaymentMethods && !isTipsEnabled) {
      // Multiple Payments + Multiple Methods + Tips Disabled
      return renderMultiPaymentMultiMethodNoTips()
    } else if (hasMultiplePayments && !hasMultiplePaymentMethods && isTipsEnabled) {
      // Multiple Payments + Single Method + Tips Enabled
      return renderMultiPaymentSingleMethodTips()
    } else if (hasMultiplePayments && !hasMultiplePaymentMethods && !isTipsEnabled) {
      // Multiple Payments + Single Method + Tips Disabled
      return renderMultiPaymentSingleMethodNoTips()
    } else if (!hasMultiplePayments && hasMultiplePaymentMethods && isTipsEnabled) {
      // Single Payment + Mutiple Methods + Tips Enabled
      return renderSinglePaymentMultiMethodTips()
    } else if (!hasMultiplePayments && hasMultiplePaymentMethods && !isTipsEnabled) {
      // Single Payment + Mutiple Methods + Tips Disabled
      return renderSinglePaymentMultiMethodNoTips()
    } else if (!hasMultiplePayments && !hasMultiplePaymentMethods && isTipsEnabled) {
      // Single Payment + Single Method + Tips Enabled
      return renderSinglePaymentSingleMethodTips()
    } else if (!hasMultiplePayments && !hasMultiplePaymentMethods && !isTipsEnabled) {
      // Single Payment + Single Method + Tips Disabled
      return renderSinglePaymentSingleMethodNoTips()
    }
  }

  const renderPage = () => {
    if (invoiceStatus == "INVALID") {
      return (
        <FloatingContainer className="ph-15" verticalPadding={50} maxWidth={800}>
          <div className="bg-white border radius-8 mb-20 p-30 text-center">
            <div className="fw-700">This link is invalid.</div>
            <div className="mt-5 c-text-gray">Please check the link and try again.</div>
          </div>
        </FloatingContainer>
      )
    }

    if (invoiceStatus == "PAID") {
      return (
        <FloatingContainer className="ph-15" verticalPadding={50} maxWidth={800}>
          <div className="bg-white border radius-8 mb-20 p-30 text-center">
            <div className="fw-700">This invoice has already been paid.</div>
            <div className="mt-5 c-text-gray">Thank you!</div>
          </div>
        </FloatingContainer>
      )
    }

    if (hasPendingPayment) {
      return (
        <FloatingContainer className="ph-15" verticalPadding={50} maxWidth={800}>
          <div className="bg-white border radius-8 mb-20 p-30 text-center">
            <BsClockHistory size={60} color={"#00b894"}/>
            <div className="fw-700 mt-20">A payment of {formatCurrencyString(pendingPayment.amount, eventInvoice.currency)} has been initiated on { formatDateMedium(pendingPayment.payment_date, eventInvoice.account_settings, true) }.</div>
            <div className="mt-5 c-text-gray">This payment must be processed before making any additional payments. If you have already completed this payment, please reach out to us so we can update our records. If you would like to remove this pending payment and use another payment method, click the button below.</div>
            <button className="small-primary-button mt-20" onClick={() => onRemovePendingPayment()}>Remove Pending Payment</button>
            <div className="mt-15">
              <div className="blue-link" onClick={() => navigate(`/v/invoice/${params.id}`)}>Back to Invoice</div>
            </div>
          </div>
        </FloatingContainer>
      )
    }

    return (
      <FloatingContainer verticalPadding={30} maxWidth={600}>
        { renderStages() }
      </FloatingContainer>
    )
  }

  const renderContent = () => {
    return (
      <div className="p-20">
        <div>
          <Row align="middle" gutter={[15]}>
            <Col flex={0} className="pb-20 cursor-pointer" onClick={() => navigate(`/v/invoice/${params.id}`)}>
              <div className="display-flex">
                <BsArrowLeft style={{ fontSize: 24 }}/>
              </div>
            </Col>
            <Col flex={0} className="pb-20 cursor-pointer" onClick={() => navigate(`/v/invoice/${params.id}`)}>
              <div className="fs-16 fw-600">Invoice #{eventInvoice.invoice_number}</div>
            </Col>
          </Row>
        </div>
        { renderPage() }
      </div>
    )
  }

  const ButtonWrapper = ({ showSpinner }) => {
    const [{ isPending }] = usePayPalScriptReducer();
  
    return (
        <>
            { (showSpinner && isPending) && <div className="text-center"><LoadingOutlined/></div> }
            <PayPalButtons
                style={{ layout: "vertical", shape: "pill" }}
                createOrder={createOrder}
                onApprove={onApprove}
            />
        </>
    );
  }

  const StripePaymentForm = (props) => {

    const [isProcessing, setProcessing] = useState(false);
    const [isProcessingModalVisible, setProcessingModalVisible] = useState(false);
    const [isProcessingError, setProcessingError] = useState(false);

    const stripe = useStripe();
    const elements = useElements();

    const onStripeSubmit = async (event) => {

      event.preventDefault();

      try {
        if (!stripe || !elements) {
          return;
        }

        setProcessing(true)
        setProcessingModalVisible(true)

        const { error, paymentIntent } = await stripe.confirmPayment({
          elements,
          confirmParams: {
            return_url: `${BASE_URL}/v/invoice/${params.id}/confirmation`,
          },
        });

        if (error) {
          setProcessingError(true)
        }
        setProcessing(false)

      } catch (error) {
        console.log(error)
        setProcessing(false)
        setProcessingModalVisible(true)
        setProcessingError(true)
      }
    }

    const renderProcessingModal = () => {
      return (
        <Modal visible={isProcessingModalVisible} closable={false} footer={null} width={400} wrapClassName="rounded-modal">
          { isProcessingError ? (
            <div className="mt-10">
              <div className="text-center c-red fs-50"><MdErrorOutline/></div>
              <div className="fw-700 fs-20 text-center">Payment Error</div>
              <div className="p-15 text-center">There was an issue processing your payment. Please check your card details and try again.</div>
              <button className="primary-button" type="button" onClick={() => setProcessingModalVisible()}>OK</button>
            </div>
          ) : (
            <div className="mt-10">
              <div className="fw-700 fs-20 text-center">Processing payment...</div>
              <div className="p-30 fs-20 text-center"><LoadingOutlined/></div>
            </div>
          )}
        </Modal>
      )
    }

    return (
      <form onSubmit={onStripeSubmit}>
        <Row gutter={[15,15]}>
          <Col xs={24}>
            <PaymentElement/> 
          </Col>
          <Col xs={24}>
            { isProcessing ? (
              <button disabled={true} className="primary-button" style={{ borderRadius: 0 }}><LoadingOutlined/></button>
            ) : (
              <button className="primary-button" style={{ borderRadius: 0 }}>Pay {formatCurrencyString(getTotalAmount(), eventInvoice.currency)}</button>
            )}
          </Col>
        </Row>
        { renderProcessingModal() }
      </form>
    )
  }

  const renderPayPalButton = () => {
    return (
      <PayPalScriptProvider options={{ 
        clientId: PAYPAL_CLIENT_ID, 
        components: "buttons", 
        merchantId: payPalMerchantId, 
        currency: eventInvoice.currency, 
        disableFunding: ["card","paylater","venmo"],
        dataPartnerAttributionId: "DJPLANNINGCENTER_SP_PPCP",
      }}>
        <ButtonWrapper showSpinner={true} />
      </PayPalScriptProvider>
    )
  }

  const renderPayPalCreditCard = () => {
    return (
      <PayPalScriptProvider options={{ 
        clientId: PAYPAL_CLIENT_ID, 
        dataClientToken: clientToken, 
        components: "buttons,hosted-fields", 
        merchantId: payPalMerchantId, 
        currency: eventInvoice.currency, 
        dataPartnerAttributionId: "DJPLANNINGCENTER_SP_PPCP" ,
        intent: "capture"
      }}>
        <ButtonWrapper showSpinner={true} />
        <Row align="middle" className="mv-15">
          <Col flex={1}>
            <div className="b-border"></div>
          </Col>
          <Col flex={0}>
            <div className="mh-15 fw-600">OR</div>
          </Col>
          <Col flex={1}>
            <div className="b-border"></div>
          </Col>
        </Row>
        <PayPalHostedFieldsProvider createOrder={createOrder}>
          <PayPalHostedField 
            id="card-number"
            hostedFieldType="number"
            options={{
              selector: "#card-number",
              placeholder: "4111 1111 1111 1111",
            }}/>
          <PayPalHostedField 
            id="cvv"
            hostedFieldType="cvv" 
            options={{
              selector: "#cvv",
              placeholder: "123",
              maskInput: true,
            }}/>
          <PayPalHostedField 
            id="expiration-date"
            hostedFieldType="expirationDate" 
            options={{
              selector: "#expiration-date",
              placeholder: "MM/YYYY",
            }}/>
        </PayPalHostedFieldsProvider>
      </PayPalScriptProvider>
    )
  }

  const renderStripeCreditCard = () => {
    if (stripeClientSecret == "") {
      return (
        <div className="mv-50 text-center">
          <LoadingOutlined style={{ fontSize: 40 }}/>
        </div>
      )
    }
    return (
      <Elements stripe={stripePromise} options={{
        clientSecret: stripeClientSecret,
        appearance: {
          theme: 'flat',
          variables: {
            borderRadius: "0px"
          },
          rules: {
            ".Input": {
              border: "1px solid #d9d9d9",
              backgroundColor: "#FFFFFF",
            },
            ".Input--empty": {
              border: "1px solid #d9d9d9",
              backgroundColor: "#FFFFFF"
            },
            ".Input--invalid": {
              border: "1px solid #e74c3c",
              backgroundColor: "#FFFFFF",
              boxShadow: "0 0 0 1px #e74c3c"
            },
            ".Input:focus": {
              outline: "none"
            },
            ".Dropdown": {
              border: "1px solid #d9d9d9",
              backgroundColor: "#FFFFFF"
            },
            ".Label": {
              fontWeight: "600",
              fontFamily: "'Open Sans', sans-serif",
              color: "#777777",
              textTransform: "uppercase",
              fontSize: "12px",
              marginLeft: "5px"
            },
            ".Error": {
              fontFamily: "'Open Sans', sans-serif",
              color: "#e74c3c",
              fontSize: "12px"
            },
          }
        }
      }}>
        <StripePaymentForm/>
      </Elements>
    )
  }

  const renderPaymentProcessorFields = () => {
    // MANUAL PAYMENT METHOD
    if (selectedPaymentMethod.manual) {
      return (
        <div className="p-30 bg-white" style={{ border: '1px solid #CCC'}}>
          <div className="fw-600 fs-14 text-center c-text-gray">PAYMENT AMOUNT</div>
          <div className="fw-700 fs-24 text-center">{formatCurrencyString(getTotalAmount(), eventInvoice.currency)}</div>
          <div className="b-border mv-30"></div>
          <div className="fw-700 fs-16 mb-5">Payment Instructions</div>
          <div className="bg-gray p-20 line-breaks">{selectedPaymentMethod.instructions}</div>
          <div className="fw-700 fs-16 mb-5 mt-30">Send us a message</div>
          <div className="mb-5">Let us know your payment is on its way!</div>
          <Input.TextArea placeholder="Message (optional)" value={manualPaymentMethodMessage} onChange={(e) => setManualPaymentMethodMessage(e.target.value)} rows={5}/>
          <button className="primary-button mt-20" style={{ borderRadius: 0 }} onClick={() => onConfirmManualPayment()}>Submit</button>
        </div>
      )
    }

    // STRIPE + PAYPAL
    if (isStripeConnected && isPayPalConnected) {
      return (
        <div className="p-30 bg-white" style={{ border: '1px solid #CCC'}}>
          <div className="fw-600 fs-14 text-center c-text-gray">PAYMENT AMOUNT</div>
          <div className="fw-700 fs-24 mb-30 text-center">{formatCurrencyString(getTotalAmount(), eventInvoice.currency)}</div>
          { renderPayPalButton() }
          <Row align="middle" className="mv-15">
            <Col flex={1}>
              <div className="b-border"></div>
            </Col>
            <Col flex={0}>
              <div className="mh-15 fw-600">OR</div>
            </Col>
            <Col flex={1}>
              <div className="b-border"></div>
            </Col>
          </Row>
          { renderStripeCreditCard() }
        </div>
      )
    }

    // STRIPE ONLY
    if (isStripeConnected) {
      return (
        <div className="p-30 bg-white" style={{ border: '1px solid #CCC'}}>
          <div className="fw-600 fs-14 text-center c-text-gray">PAYMENT AMOUNT</div>
          <div className="fw-700 fs-24 text-center">{formatCurrencyString(getTotalAmount(), eventInvoice.currency)}</div>
          <div className="b-border mv-20"></div>
          { renderStripeCreditCard() }
        </div>
      )
    }

    // PAYPAL ONLY
    if (isPayPalConnected) {
      return (
        <div className="p-30 bg-white" style={{ border: '1px solid #CCC'}}>
          <div className="fw-600 fs-14 text-center c-text-gray">PAYMENT AMOUNT</div>
          <div className="fw-700 fs-24 text-center">{formatCurrencyString(getPaymentAmount(), eventInvoice.currency)}</div>
          <div className="b-border mv-20"></div>
          {/* { renderPayPalButton() }
          <Row align="middle" className="mv-15">
            <Col flex={1}>
              <div className="b-border"></div>
            </Col>
            <Col flex={0}>
              <div className="mh-15 fw-600">OR</div>
            </Col>
            <Col flex={1}>
              <div className="b-border"></div>
            </Col>
          </Row> */}
          { renderPayPalCreditCard() }
        </div>
      )
    }
  }

  if (isLoading) {
    return (
      <div className="mt-80">
        <LoadingSpinner/>
      </div>
    )
  }

  return (
    <div className="event-contract--container" style={{ minHeight: '100vh' }}>
      { renderContent() }
    </div>
  );
}

export default InvoicePaymentPage;

import React from "react";
import { Row, Col, Modal, notification } from 'antd';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { MdOutlineClose } from "react-icons/md";
const BASE_URL = process.env.REACT_APP_BASE_URL;

const ShareModal = props => {

  const { isVisible, setVisible = () => {}, title = "", link = "" } = props;

  const fullLink = link.includes(BASE_URL) ? link : `${BASE_URL}${link}`

  const copyLink = () => {
    notification.success({
      message: 'Success!',
      description: 'The link has been copied to your clipboard.',
      duration: 3
    });
  }

  return (
    <Modal visible={isVisible} footer={null} onCancel={() => setVisible(false)} width={400} closable={false} maskClosable={false} wrapClassName="rounded-modal">
      <Row align="middle">
        <Col flex={1}>
          <div className="fw-700 fs-18">{ title }</div>
        </Col>
        <Col>
          <div className="display-flex" onClick={() => setVisible(false)}><MdOutlineClose size={30} color={"#CCC"}/></div>
        </Col>
      </Row>
      <div className="mt-15">
        <div className="display-flex flex-row flex-middle bg-gray p-15 mt-5 radius-4">
          <div className="flex-1" style={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'pre', userSelect: 'none' }}>{fullLink}</div>
          <CopyToClipboard text={fullLink} onCopy={() => copyLink()}>
            <div className="ml-5 blue-link">Copy link</div>
          </CopyToClipboard>
        </div>
      </div>
      <div className="mt-10">
        <button className="primary-button" type="button" onClick={() => setVisible(false)}>Done</button>
      </div>
    </Modal>
  );
};

export default ShareModal;
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import moment from 'moment';
import {isMobile, browserName} from 'react-device-detect';
import { BlobProvider, PDFDownloadLink } from '@react-pdf/renderer';
import useApi from '../../hooks/useApi';
import EventSheetPDF from '../../pdfs/eventSheetPDF';
import LoadingSpinner from '../../components/loading';

import { getEventSheetDetails } from '../../api';
import useDocumentTitle from '../../hooks/useDocumentTitle';

const EventSheetPDFPage = () => {

  const [isLoading, setLoading] = useState(true);
  const [event, setEvent] = useState({});

  useDocumentTitle("Event Sheet")
  const navigate = useNavigate();
  const params = useParams();
  const [sendRequest] = useApi()

  useEffect(() => {
    window.scrollTo(0, 0)
    const eventId = params.id;
    if (!eventId) {
      navigate("/", { replace: true })
    } else {
      loadPage()
    }
  }, [])

  const loadPage = async () => {
    try {
      const response = await getEventSheetDetails(params.id);
      setEvent(response.data)
      setLoading(false)
    } catch {}
  }

  if (isLoading) {
    return (
      <div className="mt-80">
        <LoadingSpinner/>
      </div>
    )
  }

  const renderDocument = () => {
    return <EventSheetPDF event={event}/>
  }

  const renderDownloadPage = () => {
    return (
      <div className="text-center ph-20 pt-80">
        <div className="fw-700 fs-16">{event.event_name}</div>
        <div className="mb-20">Event Sheet</div>
        <PDFDownloadLink document={ renderDocument() } fileName={`timeline-${moment(event.event_date).format("YYYYMMDD")}.pdf`}>
          <button className="small-primary-button" onClick={() => {}} style={{ width: 200 }}>Download PDF</button>
        </PDFDownloadLink>
      </div>
    )
  }

  return (
    <BlobProvider document={renderDocument()}>
      {({ blob, url, loading, error }) => {
        if (url) {
          if (isMobile && browserName == "Chrome") {
            return renderDownloadPage()
          } else {
            window.location.replace(url)
          }
          return null;
        }

        if (error) {
          return <div className="mt-80 text-center">An error occured loading the PDF. Please try again.</div>;
        }

        return (
          <div className="mt-80">
            <LoadingSpinner/>
          </div>
        )
      }}
    </BlobProvider>
  );
}

export default EventSheetPDFPage;

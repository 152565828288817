import React, { useEffect } from 'react';
import StorageKeys from '../constants/storageKeys';

const AppInit = () => {

  useEffect(() => {
    initApp()
  }, []);

  // Only called once when the page loads for the first time
  const initApp = async () => {
    try {
      // Reset currency
      localStorage.removeItem(StorageKeys.CURRENCY)
      // Reset account settings
      localStorage.removeItem(StorageKeys.ACCOUNT_SETTINGS)
    } catch {}
  }

  return <div></div>;
};

export default AppInit;

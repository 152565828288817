import React, { useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { Image } from 'antd';
import { BackToHomeLink, DocLink } from '../../../components/docComponents'
import AdminContent from '../../../components/adminContent';
import FloatingContainer from '../../../components/floatingContainer'
import contractTemplateExampleImage from '../../../images/contract-template-example.png';
import useDocumentTitle from '../../../hooks/useDocumentTitle';

const DocsInvoicesPage = () => {

  useDocumentTitle("Invoices | Documentation")
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const renderContent = () => {
    return (
      <FloatingContainer className="ph-20 bg-white" verticalPadding={20} maxWidth={1000}>
        <BackToHomeLink/>
        <div className="fw-700 fs-24 mt-20">Invoices</div>
        <div className="fw-500 fs-16 c-text-gray">Create and send invoices to collect payment.</div>

        <div className="b-border mv-20"></div>
        <div className="fw-700 fs-18">Creating and Sending an Invoice</div>

        <ol>
          <li className="mb-15 mt-15">
            Go to the <DocLink to="/admin/events">Events</DocLink> page and select an event (or create a new one). Add any packages and/or add-ons on the <b>Services</b> tab.
          </li>
          <li className="mb-15">
           Select the <b>Documents</b> tab and click the <b>"+"</b> icon in the <b>Invoices</b> section.
          </li>
          <li className="mb-15">
            In the <b>New Invoice</b> modal, select the client that should be billed and click <b>Continue</b>.
            <div className="bg-gray pv-10 ph-15 radius-8 mt-10">Note: You can also select "Other" and enter the details for someone else who does not have access to the planning center.</div>
          </li>
          <li className="mb-15">
            Update the invoice date, due date, and notes section.
          </li>
          <li className="mb-15">
            Select the <b>Include Pay Button</b> checkbox if desired.
            <div className="bg-gray pv-10 ph-15 radius-8 mt-10">Note: A Payment Link must be configured for this to work (See the "Configuring Default Invoice Settings" section below).</div>
          </li>
          <li className="mb-15">
            Once you are happy with your changes, select the <b>Next: Save & Preview</b> button.
          </li>
          <li className="mb-15">
            Review the invoice to make sure all information is correct. Once you are done, select the <b>Next: Send Invoice</b> button.
          </li>
          <li className="mb-15">
            Update the <b>Subject</b> and <b>Message</b> of the email if needed, select "Send me a copy" (optional), and click <b>Send Invoice</b>. An email with a link to the invoice will be sent to your client for them to review and pay.
            <div className="bg-gray pv-10 ph-15 radius-8 mt-10">Note: If you prefer to copy a link to the invoice to send in your own email or text message, you can select <b>Copy link</b>.</div>
          </li>
        </ol>
        <div className="ml-20 mt-20">
          <button className="small-primary-button mb-5" onClick={() => navigate("/admin/events")}>Go to Events</button>
        </div>

        <div className="b-border mv-20"></div>
        <div className="fw-700 fs-18">Managing Invoices</div>

        <ol>
          <li className="mb-15 mt-15">
            Navigate to the <b>Documents</b> tab of an event and go to the <b>Invoices</b> section.
          </li>
          <li className="mb-15">
            Select the three dots next to an invoice to display a menu.
          </li>
          <li className="mb-15">
            Select from one of the following options:
            <ul className="mt-10">
              <li><b>Edit Invoice</b> (Draft & Sent status only)</li>
              <li className="mt-10"><b>Preview Invoice</b> (Draft & Sent status only)</li>
              <li className="mt-10"><b>Send Invoice</b> (Draft status only)</li>
              <li className="mt-10"><b>Re-send Invoice</b> (Sent status only)</li>
              <li className="mt-10"><b>Mark as Sent</b> (Draft status only)</li>
              <li className="mt-10"><b>Mark as Paid</b> (Draft & Sent status only)</li>
              <li className="mt-10"><b>Revert to Draft</b> (Sent & Paid status only)</li>
              <li className="mt-10"><b>Copy Invoice Link</b> (Sent & Paid status only)</li>
              <li className="mt-10"><b>View / Download PDF</b> (Sent & Paid status only)</li>
              <li className="mt-10"><b>Remove Invoice</b></li>
            </ul>
          </li>
        </ol>

        <div className="b-border mv-20"></div>
        <div className="fw-700 fs-18">Configuring Default Invoice Settings</div>

        <ol>
          <li className="mb-15 mt-15">
            Go to the <DocLink to="/admin/setup/payments">Invoices & Payments</DocLink> page under Account Setup.
          </li>
          <li className="mb-15">
           Select the <b>Edit</b> link in the <b>Invoice Settings</b> section.
          </li>
          <li className="mb-15">
            Enter an optional value for <b>Payment Link</b>.
            <div className="bg-gray pv-10 ph-15 radius-8 mt-10">Note: A link to your preferred payment portal can be added here. When a payment link is configured, you will have the option to select the "Include Pay Button" checkbox when creating an invoice. This will display a "Pay Invoice" button on the invoice that will navigate to this link.</div>
          </li>
          <li className="mb-15">
            Enter an optional value for <b>Default Notes</b>.
            <div className="bg-gray pv-10 ph-15 radius-8 mt-10">Note: Any text entered here will auto-populate in the notes section for any new invoices. Notes on individual invoices can still be edited, but this will save you time if you use the same notes for every invoice.</div>
          </li>
          <li className="mb-15">
            Click the <b>Save</b> button.
          </li>
        </ol>
        <div className="ml-20 mt-20">
          <button className="small-primary-button mb-5" onClick={() => navigate("/admin/setup/payments")}>Go to Invoice Settings</button>
        </div>

        <div className="b-border mv-20"></div>
        <div className="fw-700 fs-18 mb-15">Invoice Statuses</div>
        Each invoice will be assigned a status depending on what stage of the process it is in.
        <ul>
          <li className="mb-15 mt-15">
            <b>Draft</b> 
            <br/>
            The invoice is in the editing stage and has not been sent to the client yet.
          </li>
          <li className="mb-15 mt-15">
            <b>Sent</b> 
            <br/>
            The invoice has been sent to the client, and it is awaiting payment.
          </li>
          <li className="mb-15 mt-15">
            <b>Paid</b> 
            <br/>
            The invoice has been paid by the client, therefore it is complete.
          </li>
        </ul>
        <div className="b-border mv-50"></div>
      </FloatingContainer>
    )
  }

  return (
    <AdminContent body={renderContent()}/>
  );
}

export default DocsInvoicesPage;

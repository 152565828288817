import React, {useState} from 'react';
import {useStripe, useElements, PaymentElement, CardElement} from '@stripe/react-stripe-js';
import { LoadingOutlined } from '@ant-design/icons';
const BASE_URL = process.env.REACT_APP_BASE_URL;

const SetupForm = (props) => {
  const { returnPath } = props;
  const stripe = useStripe();
  const elements = useElements();

  const [isSubmitting, setSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const handleSubmit = async (event) => {
    setSubmitting(true)
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      setSubmitting(false)
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const {error} = await stripe.confirmSetup({
      //`Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        return_url: `${BASE_URL}${returnPath}`,
      }
    });

    if (error) {
      // This point will only be reached if there is an immediate error when
      // confirming the payment. Show error to your customer (for example, payment
      // details incomplete)
      setErrorMessage(error.message);
      setSubmitting(false)
    } else {
      setSubmitting(false)
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
  };

  const renderSubmitButton = () => {
    if (isSubmitting) {
      return (
        <button className="primary-button" style={{ maxWidth: 400 }} type="submit"><LoadingOutlined/></button>
      )
    } else {
      return (
        <button className="primary-button" style={{ maxWidth: 400 }} type="submit">Update Payment Method</button>
      )
    }
  }

  return (
    <form onSubmit={handleSubmit}>
      <div className="fw-700 fs-16 mb-5">Card Details</div>
      <div className="border p-20 radius-8 bg-gray">
        <PaymentElement options={ { terms: { card: "never" }} } />
      </div>
      <div className="b-border mt-20 mb-5"></div>
      {/* <button disabled={!stripe}>Submit</button> */}
      <div className="text-center">
        { renderSubmitButton() }
      </div>
      {/* Show error message to your customers */}
      {errorMessage && <div className="c-red mt-10">{errorMessage}</div>}
    </form>
  )
};

export default SetupForm;
import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from 'react-router-dom';
import { Row, Col, Modal, Dropdown, Menu, Input, notification } from 'antd';
import { toLower, cloneDeep, isNull } from "lodash";
import { getClients, deleteClient } from '../../../api';
import AppContext from '../../../app/context';
import LoadingSpinner from '../../../components/loading';
import AdminContent from '../../../components/adminContent';
import FloatingContainer from '../../../components/floatingContainer'
import ErrorCard from '../../../components/errorCard';
import useApi from '../../../hooks/useApi';
import useDocumentTitle from '../../../hooks/useDocumentTitle';
import { EditOutlined, DeleteOutlined, ExclamationCircleOutlined, UserOutlined, SearchOutlined } from '@ant-design/icons';
import { MdOutlineMoreHoriz, MdOutlineClose } from "react-icons/md";
import emptyStateImage from '../../../images/empty-state-icon.png';
import emptySearchImage from '../../../images/empty-search-icon.png';

const ClientsPage = () => {

  const [isLoading, setLoading] = useState(true);
  const [isError, setError] = useState(false);
  const [clients, setClients] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [isConfirmRemoveClientModalVisible, setConfirmRemoveClientModalVisible] = useState(false);
  const [isRemoveErrorModalVisible, setRemoveErrorModalVisible] = useState(false);
  const [selectedClient, setSelectedClient] = useState({});

  useDocumentTitle("Clients")
  const { auth, setAuth } = useContext(AppContext);
  const [sendRequest] = useApi()
  const navigate = useNavigate();

  const menu = (client) => {
    return (
      <Menu>
        <Menu.Item>
          <div onClick={() => handleEdit(client)}>
            <EditOutlined style={{ marginRight: 8}}/> Edit
          </div>
        </Menu.Item>
        <Menu.Item>
          <div onClick={() => handleDelete(client)}>
            <DeleteOutlined style={{ marginRight: 8}}/> Delete
          </div>
        </Menu.Item>
      </Menu>
    )
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    refreshPage()
  }, []);

  const refreshPage = async () => {
    try {
      const response = await sendRequest(getClients())
      setClients(response);
    } catch {
      setError(true)
    } finally {
      setLoading(false)
    }
  }

  const onNewClick = () => {
    navigate("/admin/clients/new")
  }

  const handleEdit = (client) => {
    navigate(`/admin/clients/${client.user_id}/edit`)
  };

  const handleDelete = (client) => {
    setSelectedClient(client)
    setConfirmRemoveClientModalVisible(true)
  };

  const confirmDelete = async () => {
    try {
      setConfirmRemoveClientModalVisible(false)
      const response = await sendRequest(deleteClient(selectedClient.user_id));
      if (response.status == "in-use") {
        setRemoveErrorModalVisible(true)
      } else {
        await refreshPage()
        displayRemoveSuccess()
      }
    } catch {
      notification.error({
        message: 'Error!',
        description: 'There was an issue deleting this client.',
        duration: 3
      });
    }
  }

  const displayRemoveSuccess = () => {
    notification.success({
      message: 'Success!',
      description: 'The client has been delete.',
      duration: 3
    });
  }

  const getFilteredClients = () => {
    const allClients = cloneDeep(clients)
    var filteredClients = allClients;
    if (searchText != "") {
      filteredClients = filteredClients.filter(x => {
        const lowerClientFirstName = toLower(x.first_name)
        const lowerClientLastName = toLower(x.last_name)
        const lowerClientFullName = `${lowerClientFirstName} ${lowerClientLastName}`

        const matchFirst = lowerClientFirstName.includes(toLower(searchText))
        const matchLast = lowerClientLastName.includes(toLower(searchText))
        const matchFull = lowerClientFullName.includes(toLower(searchText))

        return matchFirst || matchLast || matchFull
      })
    }

    return filteredClients;
  }

  const onSearchTextChange = (text) => {
    setSearchText(text)
  }

  const renderClientResults = () => {
    if (getFilteredClients().length > 0) {
      return getFilteredClients().map((x,i) => renderClient(x,i));
    } else {
      return (
        <div className="ph-20 pv-40 text-center">
          <div>
            <img src={emptySearchImage} width={200}/>
          </div>
          <div className="fs-18 fw-700 mt-30">
            No clients found
          </div>
          <div className="fs-14 fw-500 c-text-gray">
            Adjust your filter or search again!
          </div>
        </div>
      )
    }
  }

  const renderHeader = () => {
    return (
      <Row align="middle" className="p-20">
        <Col flex={1}>
          <div className="fs-24 fw-700">Clients</div>
        </Col>
        <Col>
          { clients.length > 0 && (
            <button className="page-title-button" onClick={onNewClick}>New Client</button>
          )}
        </Col>
      </Row>
    )
  }

  const renderClient = (client, index) => {
    var clientPhoto = null;
    if (client.photo) {
      clientPhoto = client.photo;
    } else if (!isNull(client.accounts) && client.accounts.length > 0) {
      const clientAccounts = client.accounts.filter(x => x.account_id == auth.user.account_id)
      if (clientAccounts.length > 0) {
        clientPhoto = clientAccounts[0].internal_photo
      }
    }
    return (
      <div key={index} className="p-10 b-border">
        <Row gutter={[15,15]} align="middle">
          <Col onClick={() => handleEdit(client)}>
            { clientPhoto ? (
              <div className="small-profile-photo size-60" style={{ backgroundImage: `url(${clientPhoto})`}}></div>
            ) : (
              <div className="display-flex">
                <div className="user-profile-circle"><UserOutlined/></div>
              </div>
            )}
          </Col>
          <Col flex={1} onClick={() => handleEdit(client)}>
            <div className="fs-14 fw-700">{client.first_name} {client.last_name}</div>
            <div className="fs-14 fw-500 c-text-gray line-1-1">{client.email}</div>
          </Col>
          <Col flex={0}>
            <div className="display-flex mr-5">
              <Dropdown overlay={menu(client)} placement="bottomRight" trigger="click">
                <div className="dots-container">
                  <MdOutlineMoreHoriz style={{ fontSize: 24, color: '#999'}}/>
                </div>
              </Dropdown>
            </div>
          </Col>
        </Row>
      </div>
    )
  }

  const renderClientBox = () => {
    return (
      <div className="shadow-card">
        <div className="p-15 b-border display-flex flex-row">
          <div className="flex-1">
            <Input 
              className="list-search-input-square" 
              size="large" 
              placeholder="Search clients" 
              value={searchText} 
              onChange={(e) => onSearchTextChange(e.target.value)} 
              allowClear={true} 
              prefix={<SearchOutlined color="#e4e4e4" style={{ marginRight: 6 }}/>} 
            />
          </div>
        </div>
        { renderClientResults() } 
      </div>
    )
  }

  const renderClients = () => {
    if (clients.length == 0) {
      return (
        <div className="shadow-card ph-20 pv-50 text-center">
          <div>
            <img src={emptyStateImage} width={300}/>
          </div>
          <div className="fs-18 fw-700 mt-30">
            No clients yet
          </div>
          <div className="fs-14 fw-500 c-text-gray">
            Create an client to get started!
          </div>
          <button className="primary-button mt-30" style={{ width: 200 }} onClick={onNewClick}>New Client</button>
        </div>
      )
    } else {
      return renderClientBox()
    }
  }

  const renderConfirmRemoveClientModal = () => {
    return (
      <Modal visible={isConfirmRemoveClientModalVisible} closable={false} footer={null} width={400} wrapClassName="rounded-modal">
        <div className="text-right" onClick={() => setConfirmRemoveClientModalVisible(false)}><MdOutlineClose size={30} color={"#CCC"}/></div>
        <div className="mt-10">
          <div className="fw-700 fs-20 text-center">Are you sure?</div>
          <div className="fw-500 fs-14 mt-20 mb-20 text-center">Are you sure you would like to delete <span className="fw-700">{selectedClient.first_name} {selectedClient.last_name}</span>? This action cannot be undone.</div>
          <button className="primary-button warning" type="button" onClick={() => confirmDelete()}>Delete Client</button>
          <div className="text-center mt-15">
            <div className="blue-link" onClick={() => setConfirmRemoveClientModalVisible(false)}>Cancel</div>
          </div>
        </div>
      </Modal>
    )
  }

  const renderRemoveErrorModal = () => {
    return (
      <Modal visible={isRemoveErrorModalVisible} closable={false} footer={null} width={400} wrapClassName="rounded-modal">
        <div className="text-right" onClick={() => setRemoveErrorModalVisible(false)}><MdOutlineClose size={30} color={"#CCC"}/></div>
        <div className="mt-10">
          <div className="fw-700 fs-20 text-center">Cannot Remove Client</div>
          <div className="fw-500 fs-14 mt-20 mb-20 text-center"><span className="fw-700">{selectedClient.first_name} {selectedClient.last_name}</span> is assigned to one or more events. These events must be removed before this client can be removed.</div>
          <button className="primary-button" type="button" onClick={() => setRemoveErrorModalVisible()}>OK</button>
        </div>
      </Modal>
    )
  }

  const renderContent = () => {
    if (isLoading) {
      return <LoadingSpinner/>
    }
    if (isError) {
      return <ErrorCard/>
    }
    return (
      <>
        <FloatingContainer className="ph-20" verticalPadding={20} maxWidth={800}>
          { renderClients() }
        </FloatingContainer>
        { renderConfirmRemoveClientModal() }
        { renderRemoveErrorModal() }
      </>
    )
  }

  return (
    <AdminContent body={renderContent()} header={renderHeader()} headerHeight={72}/>
  );
}

export default ClientsPage;

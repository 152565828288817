import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Row, Col, Form, Input } from 'antd';
import { submitContactForm } from '../../api';
import FloatingContainer from '../../components/floatingContainer'
import FadeDownWhileInView from '../../components/fadeDownWhileInView'
import useDocumentTitle from '../../hooks/useDocumentTitle';

const ContactPage = () => {

  useDocumentTitle("Account Deletion")
  const navigate = useNavigate();
  const [form] = Form.useForm();

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const onSubmit = async (values) => {
    try {
      const body = {
        ...values,
        message: getMessage(values)
      }
      await submitContactForm(body);
      navigate("/contact/thank-you")
    } catch(error) {

    }
  }

  const getMessage = (values) => {
    var message = "ACCOUNT DELETION REQUEST | Company Name: " + values["company_name"] + " | " + values["message"]
    return message
  }

  const renderConctactForm = () => {
    return (
      <FadeDownWhileInView delay={0.2} className="shadow-card p-30">
        <Form form={form} layout="vertical" name="client" onFinish={onSubmit}>
          <Row gutter={[20,20]} className="mt-15">
            <Col xs={24} md={12}>
              <Form.Item
                name={"first_name"}
                rules={[{ required: true, message: `First Name is required!`, validateTrigger: "onBlur" }]}
              >
                <Input size="large" placeholder="First Name"/>
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                name={"last_name"}
                rules={[{ required: true, message: `Last Name is required!`, validateTrigger: "onBlur" }]}
              >
                <Input size="large" placeholder="Last Name"/>
              </Form.Item>
            </Col>
            <Col xs={24}>
              <Form.Item
                name={"email"}
                rules={[
                  { required: true, message: `Email is required!`, validateTrigger: "onBlur" },
                  { type: "email", message: `Please enter a valid email address` }
                ]}
              >
                <Input size="large" placeholder="Email"/>
              </Form.Item>                
            </Col>
            <Col xs={24} md={24}>
              <Form.Item
                name={"company_name"}
                rules={[{ required: true, message: `Company Name is required!`, validateTrigger: "onBlur" }]}
              >
                <Input size="large" placeholder="Company Name"/>
              </Form.Item>
            </Col>
            <Col xs={24}>
              <Form.Item
                name={"message"}
                rules={[
                  { required: true, message: `Message is required!`, validateTrigger: "onBlur" }
                ]}
              >
                <Input.TextArea rows={4} size="large" placeholder="Message"/>
              </Form.Item>   
            </Col>
          </Row>
          <button className="primary-button" type="submit">
            Submit
          </button>
        </Form>
      </FadeDownWhileInView>
    )
  }

  const renderContent = () => {
      return (
      <div>
        <FloatingContainer className="bg-blue-gradient" verticalPadding={60} maxWidth={600}>
          <FadeDownWhileInView className="fs-30 fw-700 text-center c-white">Account Deletion</FadeDownWhileInView>
          <FadeDownWhileInView className="fs-16 c-white mt-20 line-1-3 text-center ph-20">If you wish to delete your DJ Planning Center account, please fill out the form below. Once this request is processed, you will no longer have access to your events and all of your personal and account information will be removed from our system. </FadeDownWhileInView>
        </FloatingContainer>
        <FloatingContainer className="bg-gray ph-20" verticalPadding={60} maxWidth={800}>
          <Row gutter={[30]} align="middle">
            <Col xs={24}>
              { renderConctactForm() }
            </Col>
          </Row>
        </FloatingContainer>
      </div>
      )
  }

  return (
    <div>
      { renderContent() }
    </div>
  );
}

export default ContactPage;

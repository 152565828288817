import { mergeAttributes, Node } from '@tiptap/core'
import { ReactNodeViewRenderer } from '@tiptap/react'

import EventServicesComponent from './eventServicesComponent'

export default Node.create({
  name: 'event-services',

  group: 'block',

  content: 'inline*',

  addAttributes() {
    return {}
  },

  parseHTML() {
    return [
      {
        tag: 'event-services',
      },
    ]
  },

  renderHTML({ HTMLAttributes }) {
    return ['event-services', mergeAttributes(HTMLAttributes)]
  },

  addNodeView() {
    return ReactNodeViewRenderer(EventServicesComponent)
  },
})
import React, { useEffect, useState, useContext } from "react";
import { Row, Col, Upload, Form, Input, Modal, notification } from 'antd';
import { isEmpty } from 'lodash';
import { useNavigate } from 'react-router-dom';
import AppContext from '../../app/context';
import AdminContent from '../../components/adminContent';
import EventCard from '../../components/eventCard';
import useApi from '../../hooks/useApi';
import { logout, changePassword, uploadUserPhoto, refreshToken, getEvent, getEvents, switchClientEvent } from '../../api';
import { UserOutlined, EyeInvisibleOutlined, EyeTwoTone, LoadingOutlined } from '@ant-design/icons';
import { MdOutlineClose, MdPhotoCamera } from "react-icons/md";
import FloatingContainer from '../../components/floatingContainer'
import { getDeviceId } from '../../helpers/deviceHelper';
import { validatePassword } from '../../helpers/passwordHelper'
import { resizeFile, isValidFileType } from '../../helpers/imageHelper';
import userPlaceholderImage from '../../images/user-placeholder.png';
import useDocumentTitle from '../../hooks/useDocumentTitle';

const AccountPage = () => {

  const [event, setEvent] = useState({})
  const [events, setEvents] = useState([])
  const [user, setUser] = useState({})
  const [isChangePasswordModalVisible, setChangePasswordModalVisible] = useState(false);
  const [isSwitchEventModalVisible, setSwitchEventModalVisible] = useState(false);
  const [changePasswordError, setChangePasswordError] = useState("")
  const [imageUrl, setImageUrl] = useState(null);
  const [isPhotoSaving, setPhotoSaving] = useState(false);

  useDocumentTitle("Account")
  const { auth, setAuth, setCurrentEvent } = useContext(AppContext);

  const [changePasswordForm] = Form.useForm();
  const [sendRequest, refreshAccessToken] = useApi()
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
    if (auth.user) {
      setUser(auth.user)
    }
    refreshPage()
  }, []);

  const refreshPage = async () => {
    try {
      const eventResults = await sendRequest(getEvent(auth.user.event_id));
      setEvent(eventResults)
    } finally { }
  }

  const onLogout = async () => {
    try {
      if (auth.user) {
        await logout(auth.user.user_id, getDeviceId())
      }
      setAuth({})
      setCurrentEvent({})
    } catch (error) {
      console.log(error)
    }
  }

  const startChangePassword = () => {
    setChangePasswordError("")
    changePasswordForm.resetFields()
    setChangePasswordModalVisible(true)
  }

  const savePassword = async (values) => {
    setChangePasswordError("")
    try {
      const passwordResponse = await sendRequest(changePassword(values))
      if (passwordResponse.status == "invalid-password") {
        setChangePasswordError("The current password you entered is incorrect.")
      } else if (passwordResponse.status == "success") {
        setAuth(passwordResponse.data)
        setChangePasswordModalVisible(false)
        notification.success({
          message: 'Success!',
          description: 'Your password has been changed successfully.',
          duration: 3
        });
      } else {
        setChangePasswordModalVisible(false)
      }
    } catch (error) {
      setChangePasswordModalVisible(false)
      console.log(error)
    }
  }

  const onSwitchEvent = async () => {
    try {
      const eventsResponse = await sendRequest(getEvents())
      const filteredEvents = eventsResponse.filter((x) => x.event_id != auth.user.event_id)
      setEvents(filteredEvents)
      setSwitchEventModalVisible(true)
    } catch (error) {
      console.log(error)
    }
  }

  const onConfirmSwitchEvent = async (eventId) => {
    try {
      // Don't do anything if it's the same event
      if (auth.user.event_id == eventId) {
        setSwitchEventModalVisible(false)
        return
      }

      // Switch event id
      await sendRequest(switchClientEvent(auth.user.user_id, eventId))

      // Refresh token with new event id
      await refreshAccessToken()

      // Refresh event
      const eventResults = await sendRequest(getEvent(eventId));
      setEvent(eventResults)

      setSwitchEventModalVisible(false)

      notification.success({
        message: 'Success!',
        description: 'You have successfully switched events.',
        duration: 3
      });
    } catch (error) {
      console.log(error)
    }
  }

  const handleImageChange = async (info) => {
    try {
      // Make sure the file type is JPG or PNG
      if (!isValidFileType(info.file)) {
        notification.error({
          message: 'Error',
          description: 'Only file types of JPG or PNG are supported.',
          duration: 3
        });
        return
      }

      setPhotoSaving(true)

      // Resize photo
      const newFile = await resizeFile(info.file)
      setImageUrl(newFile)

      // Get blob from URI
      let response = await fetch(newFile);
      let blob = await response.blob()

      // Upload photo
      const formData = new FormData();
      formData.append('photo', blob);
      formData.append('user_id', auth.user.user_id);
      await sendRequest(uploadUserPhoto(formData))
  
      // Refresh token
      const authResponse = await sendRequest(refreshToken({ user_id: auth.user.user_id, refresh_token: auth.refresh_token, source: "web", device_id: getDeviceId() }))
      setAuth(authResponse)
    } catch (error) {
      notification.error({
        message: 'Error',
        description: 'Something went wrong! Please try again later.',
        duration: 3
      });
    } finally {
      setPhotoSaving(false)
    }
  };

  const handleBeforeUpload = () => {
    return false
  };

  const renderHeader = () => {
    return (
      <div className="fs-24 fw-700 p-20">Account</div>
    )
  }

  const renderLinkedClient = (client, index) => {
    return (
      <div className="shadow-card p-15" key={index}>
        <Row gutter={[15,15]} align="middle">
          <Col>
            <div className="user-profile-circle">
              <UserOutlined/>
            </div>
          </Col>
          <Col flex={1}>
            <div className="fw-700">{client.first_name} {client.last_name}</div>
            <div className="fw-500 c-text-gray line-1-2">{client.email}</div>
          </Col>
        </Row>
      </div>
    )
  }

  const renderLinkedClients = () => {
    if (event.clients && event.clients.length > 1) {
      const otherClients = event.clients.filter(x => x.user_id != user.user_id)
      return (
        <>
          <div className="fs-20 fw-700 ml-10 mb-10 mt-20">Linked Accounts</div>
          {otherClients.map((x,i) => renderLinkedClient(x,i))}
        </>
      )
    }
    return null;
  }

  const renderCurrentEvent = () => {
    return (
      <>
        <div className="display-flex flex-row flex-middle mb-10 mt-20">
          <div className="fs-20 fw-700 ml-10 flex-1">Current Event</div>
          <div className="flex-0">
            <div className="blue-link" onClick={() => onSwitchEvent()}>Switch</div>
          </div>
        </div>
        <div className="shadow-card">
          <EventCard event={event} accountSettings={event.account_settings} rightIcon={<></>} className={"b-border"} onClick={() => {}}/>
        </div>
      </>
    )
  }

  const renderChangePasswordModal = () => {
    return (
      <Modal visible={isChangePasswordModalVisible} footer={null} onCancel={() => setChangePasswordModalVisible(false)} width={400} closable={false} maskClosable={false} wrapClassName="rounded-modal">
        <Row align="middle">
          <Col flex={1}>
            <div className="fw-700 fs-18">Change Password</div>
          </Col>
          <Col>
            <div className="display-flex" onClick={() => setChangePasswordModalVisible(false)}><MdOutlineClose size={30} color={"#CCC"}/></div>
          </Col>
        </Row>
        <div className="">
          <Form form={changePasswordForm} layout="vertical" name="verify" onFinish={savePassword}>
            <div className="mb-10 mt-20">
              <Form.Item
                name={"password"}
                className="mt-20 mb-10"
                rules={[
                  { required: true, message: `Current Password is required!`, validateTrigger: 'onBlur'  }
                ]}
              >
                <Input.Password
                  size="large"
                  placeholder="Current Password"
                  iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                />
              </Form.Item>
              <Form.Item
                name={"new_password"}
                className="mb-10"
                rules={[
                  { required: true, message: `New Password is required!`, validateTrigger: 'onBlur'  },
                  { validator: validatePassword, validateTrigger: 'onBlur' }
                ]}
              >
                <Input.Password
                  size="large"
                  placeholder="New Password"
                  iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                />
              </Form.Item>
            </div>
            { !isEmpty(changePasswordError) && (
              <div className="c-red text-center">{changePasswordError}</div>
            )}
            <button className="primary-button" type="submit">Change Password</button>
            <div className="text-center mt-15">
              <div className="blue-link" onClick={() => setChangePasswordModalVisible(false)}>Cancel</div>
            </div>
          </Form>
        </div>
      </Modal>
    )
  }

  const renderSwitchEvents = (item, index) => {
    return (
      <div key={index}>
        <EventCard event={item} accountSettings={event.account_settings} rightIcon={<></>} className={"b-border"} onClick={() => onConfirmSwitchEvent(item.event_id)}/>
      </div>
    )
  }

  const renderSwitchEventModal = () => {
    return (
      <Modal visible={isSwitchEventModalVisible} footer={null} onCancel={() => setSwitchEventModalVisible(false)} width={500} closable={false} maskClosable={false} wrapClassName="rounded-modal">
        <Row align="middle">
          <Col flex={1}>
            <div className="fw-700 fs-18">Switch Event</div>
          </Col>
          <Col>
            <div className="display-flex" onClick={() => setSwitchEventModalVisible(false)}><MdOutlineClose size={30} color={"#CCC"}/></div>
          </Col>
        </Row>
        <div className="mt-10">
          { events.length > 0 ? (
            <>
              <div className="bg-gray radius-8 p-15 text-center mb-15">
                <div>Select the event you would like to switch to.</div>
              </div>
              <div className="t-border">
                { events.map((x,i) => renderSwitchEvents(x,i))}
              </div>
            </>
          ) : (
            <div className="bg-gray radius-8 ph-15 pv-30 text-center">
              <div>There are no other events assigned to you.</div>
            </div>
          )}
          <button className="primary-button mt-20" type="button" onClick={() => setSwitchEventModalVisible()}>Cancel</button>
        </div>
      </Modal>
    )
  }

  const renderProfileHeader = () => {
    var image = userPlaceholderImage
    var borderClass = ""
    if (imageUrl) {
      image = imageUrl
      borderClass = "gray-border"
    } else if (auth.user && auth.user.photo) {
      image = auth.user.photo
      borderClass = "gray-border"
    }
    return (
      <div className="shadow-card ph-20 pv-30 mb-15">
        <div className="text-center">
          <div className={`profile-photo-container ${borderClass}`}>
            <Upload
              showUploadList={false}
              beforeUpload={handleBeforeUpload}
              onChange={handleImageChange}
              maxCount={1}
            >
              <div className="profile-photo" style={{ backgroundImage: `url(${image})`}}>
                <div className="photo-overlay">
                  <MdPhotoCamera/>
                </div>
                { isPhotoSaving && (
                  <div className="photo-spinner">
                    <LoadingOutlined/>
                  </div>
                )}
              </div>
            </Upload>
          </div>
          <div className="fs-18 fw-700 mt-5">{ auth.user && auth.user.first_name } {auth.user && auth.user.last_name }</div>
          <div className="fs-14 fw-300">{ auth.user && auth.user.email }</div>
        </div>
      </div>
    )
  }

  const renderContent = () => {
    return (
      <FloatingContainer className="ph-20 mb-80" verticalPadding={20} maxWidth={500}>
        { renderProfileHeader() }
        <div className="shadow-card p-20">
          <Row>
            <Col flex={1}>
              <div className="fs-14 fw-700">Password</div>
            </Col>
            <Col>
              <div className="admin-link" onClick={() => startChangePassword()}>Change</div>
            </Col>
          </Row>
          <div className="fs-14 fw-300 b-border pb-20 mb-20">******</div>
          {/* <Row>
            <Col flex={1}>
              <div className="fs-14 fw-700">Timezone</div>
            </Col>
            <Col>
              <div className="admin-link">Change</div>
            </Col>
          </Row>
          <div className="fs-14 fw-300">(GMT-7:00) Arizona</div>
          <div className="b-border mt-15"></div> */}
          <button className="small-primary-button" style={{ width: "100%" }} onClick={onLogout}>Sign Out</button>
        </div>
        { renderLinkedClients() }
        { renderCurrentEvent() }
        { renderChangePasswordModal() }
        { renderSwitchEventModal() }
      </FloatingContainer>
    )
  }

  return (
    <AdminContent header={renderHeader()} body={renderContent()}/>
  );
}

export default AccountPage;

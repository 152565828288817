import React from 'react';
import moment from 'moment';
import { MdOutlineArrowForwardIos } from "react-icons/md";
import { isArray } from 'lodash';
import { Grid } from 'antd';
import { formatEventDayMonth } from '../helpers/dateHelper';

const EventCard = props => {
  const { event = {}, accountSettings = {}, rightIcon = null, className = "", onClick = () => {}, showType = false } = props;

  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();

  const renderIcon = () => {
    if (rightIcon) {
      return rightIcon;
    } else {
      return (
        <MdOutlineArrowForwardIos style={{ fontSize: 20, color: '#CCC' }}/>
      )
    }
  }

  const renderEventCard = () => {
    const eventDate = moment(event.event_date, "YYYY-MM-DD")
    const eventDay = eventDate.format("dddd").toUpperCase();
    const eventMonthDay = formatEventDayMonth(event.event_date, accountSettings);
    const eventYear = eventDate.format("YYYY");
    
    var venueName = ""
    var subText = ""
    if (isArray(event.venues) && event.venues.length > 0) {
      const venue = event.venues[0]
      if (venue.city) {
        const cityState = venue.state ? `${venue.city}, ${venue.state}` : venue.city
        venueName = `${venue.venue_name} (${cityState})`
      } else {
        venueName = venue.venue_name
      }
      subText = showType && screens.md ? `${event.account_event_type_name} | ${venueName}` : venueName
    } else {
      subText = event.account_event_type_name
    }

    return (
      <div className={`admin-event-card ${className}`}>
        <div className="admin-event-card-date-box" onClick={onClick}>
          <div className="admin-event-card-date-inner">
            <div className="event-day">{ eventDay }</div>
            <div className="event-date">{ eventMonthDay }</div>
            <div className="event-year">{ eventYear }</div>
          </div>
        </div>
        <div className="admin-event-card--col-2" onClick={onClick}>
          <div className="event-box-name">{ event.event_name }</div>
          <div className="event-box-location">{ subText }</div>
        </div>
        <div className="admin-event-card--col-3">
          { renderIcon() }
        </div>
      </div>
    )
  }

  return renderEventCard()
};

export default EventCard;

import React, { useEffect, useState, useContext, forwardRef, useImperativeHandle } from "react";
import { Row, Col, Modal, Form, Switch, notification } from 'antd';
import { isNull, isEmpty, startCase } from 'lodash';
import moment from 'moment';
import useApi from '../hooks/useApi';
import AppContext from '../app/context';
import useAccountSettings from '../hooks/useAccountSettings';
import Permissions from '../constants/permissions';
import TimeOffStatus from '../constants/timeOffStatus';
import CalendarRepeatOptions from '../constants/calendarRepeatOptions';
import { hasPermission } from '../helpers/permissionHelper';
import { MdOutlineClose } from "react-icons/md";
import { LoadingOutlined } from "@ant-design/icons";
import { 
  createTimeOff, 
  updateTimeOff
} from '../api';
import {
  renderDateField,
  renderTimeField,
  renderSearchSelectField,
  renderSelectField,
  renderTextAreaField,
  renderFormLabel,
} from '../components/formFields'

const TimeOffModal = forwardRef((props, ref) => {

  const {
    isTimeOffModalVisible = false,
    setTimeOffModalVisible = () => {},
    employees = [],
    selectedTimeOff = {},
    setSelectedTimeOff = () => {},
    refreshTimeOff = () => {},
    selectedDay = moment().format("YYYY-MM-DD")
  } = props;

  const [isSaving, setSaving] = useState(false);
  const [allDayTimeOff, setAllDayTimeOff] = useState(false);
  const [repeatOptions, setRepeatOptions] = useState([]);
  const [shouldRepeat, setShouldRepeat] = useState(false);
  const [recurrenceType, setRecurrenceType] = useState("");
  const [shouldRecurrenceEnd, setShouldRecurrenceEnd] = useState(false);
  const [timeOffStartDate, setTimeOffStartDate] = useState(moment().format("YYYY-MM-DD"));
  const [timeOffEndDate, setTimeOffEndDate] = useState(moment().format("YYYY-MM-DD"));
  const [timeOffError, setTimeOffError] = useState("");
  const [isAutomaticApproval, setAutomaticApproval] = useState(false);

  const { auth, setAuth } = useContext(AppContext);
  const user = auth.user ? auth.user : {}
  const canManageTimeOff = hasPermission(user, Permissions.MANAGE_TIME_OFF)

  const [sendRequest] = useApi()
  const [accountSettings] = useAccountSettings()

  const [timeOffForm] = Form.useForm();

  const timeOffStatuses = [
    { value: TimeOffStatus.PENDING, text: "Pending" },
    { value: TimeOffStatus.APPROVED, text: "Approved" },
    { value: TimeOffStatus.REJECTED, text: "Rejected" }
  ]

  const endRepeatOptions = [
    { value: "never", text: "Never"},
    { value: "on_date", text: "On Date"},
  ]

  useEffect(() => {
    window.scrollTo(0, 0);
    loadPage()
  }, []);

  useImperativeHandle(ref, () => ({
    handleAddTimeOff,
    handleEditTimeOff
  }));

  const loadPage = async () => {
    try {
      var options = []
      for (const [key, value] of Object.entries(CalendarRepeatOptions)) {
        options.push({ value: value, text: startCase(value)})
      }
      setRepeatOptions(options)
      setAutomaticApproval(accountSettings.time_off_approval == "AUTOMATIC")
    } catch { }
  }

  const onTimeOffSubmit = async (values) => {
    try {
      if (isSaving) { return }

      // Validate inputs
      if (moment(values.start_date).isValid() && moment(values.end_date).isValid()) {
        if (values.start_date.isAfter(values.end_date)) {
          setTimeOffError("The start date must come before the end date.")
          return
        }
        if (!allDayTimeOff && values.start_date.isSame(values.end_date)) {
          if (values.start_time.isAfter(values.end_time)) {
            setTimeOffError("The start time must come before the end time.")
            return
          }
        }
        if (shouldRecurrenceEnd && moment(values.recurrence_end_date).isValid()) {
          if (values.start_date.isAfter(values.recurrence_end_date)) {
            setTimeOffError("The repeat end date must come before the start date.")
            return
          }
        }
      }

      setSaving(true)

      var startTime = formatTimeForDB(values.start_time)
      var endTime = formatTimeForDB(values.end_time)
      if (allDayTimeOff) {
        startTime = null
        endTime = null
      }
      if (isEmpty(selectedTimeOff)) {
        const initialStatus = isAutomaticApproval ? TimeOffStatus.APPROVED : TimeOffStatus.PENDING
        const body = {
          user_id: canManageTimeOff ? values.user_id : auth.user.user_id,
          start_date: formatDateForDB(values.start_date),
          end_date: formatDateForDB(values.end_date),
          start_time: startTime,
          end_time: endTime,
          recurrence_type: values.recurrence_type ? values.recurrence_type : CalendarRepeatOptions.NONE,
          recurrence_end_date: formatDateForDB(values.recurrence_end_date),
          all_day: allDayTimeOff,
          description: values.description,
          status: canManageTimeOff ? values.status : initialStatus
        }
        await sendRequest(createTimeOff(body))
        notification.success({
          message: 'Success!',
          description: 'Your time off request has been submitted.',
          duration: 3
        });
      } else {
        const newStatus = !isAutomaticApproval && selectedTimeOff.status == TimeOffStatus.APPROVED ? TimeOffStatus.PENDING : selectedTimeOff.status
        const body = {
          user_id: canManageTimeOff ? values.user_id : auth.user.user_id,
          start_date: formatDateForDB(values.start_date),
          end_date: formatDateForDB(values.end_date),
          start_time: startTime,
          end_time: endTime,
          recurrence_type: values.recurrence_type ? values.recurrence_type : CalendarRepeatOptions.NONE,
          recurrence_end_date: formatDateForDB(values.recurrence_end_date),
          all_day: allDayTimeOff,
          description: values.description,
          status: canManageTimeOff ? values.status : newStatus
        }
        await sendRequest(updateTimeOff(selectedTimeOff.time_off_id, body))
        notification.success({
          message: 'Success!',
          description: 'Your time off request has been updated.',
          duration: 3
        });
      }
      await refreshTimeOff()
      setTimeOffModalVisible(false)
    } catch(error) {
      notification.error({
        message: 'Error!',
        description: 'There was a problem saving your time off request.',
        duration: 3
      });
      setTimeOffModalVisible(false)
    } finally {
      setSaving(false)
    }
 }

 const formatDateForDB = (date) => {
  if (!isEmpty(date)) {
    return date.format("YYYY-MM-DD")
  }
  return null;
}

 const formatTimeForDB = (date) => {
    if (!isEmpty(date)) {
      return date.format("H:mm")
    }
    return null;
  }

  const handleCancelTimeOff = () => {
    setTimeOffModalVisible(false)
  }

  const handleAddTimeOff = () => {
    setSelectedTimeOff({})
    setTimeOffError("")
    setAllDayTimeOff(true)
    setShouldRepeat(true)
    setRecurrenceType(CalendarRepeatOptions.NONE)
    setShouldRecurrenceEnd(false)
    setTimeOffStartDate(selectedDay)
    setTimeOffEndDate(selectedDay)
    timeOffForm.resetFields()
    const initialStatus = canManageTimeOff ? TimeOffStatus.APPROVED : TimeOffStatus.PENDING
    const fields = {
      user_id: auth.user.user_id,
      status: initialStatus,
      start_date: !isNull(selectedDay) ? moment(selectedDay, "YYYY-MM-DD") : null,
      end_date: !isNull(selectedDay) ? moment(selectedDay, "YYYY-MM-DD") : null,
      recurrence_type: CalendarRepeatOptions.NONE,
      recurrence_end: "never",
      recurrence_end_date: null
    }
    timeOffForm.setFieldsValue(fields)
    setTimeOffModalVisible(true)
  }

  const handleEditTimeOff = (timeOff) => {
    setSelectedTimeOff(timeOff)
    setTimeOffError("")
    setAllDayTimeOff(timeOff.all_day == true)
    setShouldRepeat(timeOff.start_date == timeOff.end_date)
    setRecurrenceType(timeOff.recurrence_type)
    const hasEndDate = !isNull(timeOff.recurrence_end_date)
    setShouldRecurrenceEnd(hasEndDate)
    setTimeOffStartDate(timeOff.start_date)
    setTimeOffEndDate(timeOff.end_date)
    const fields = {
      user_id: timeOff.user_id,
      start_date: moment(timeOff.start_date, "YYYY-MM-DD"),
      end_date: moment(timeOff.end_date, "YYYY-MM-DD"),
      start_time: !isEmpty(timeOff.start_time) ? moment(timeOff.start_time, "H:mm") : null,
      end_time: !isEmpty(timeOff.end_time) ? moment(timeOff.end_time, "H:mm") : null,
      description: timeOff.description,
      recurrence_type: timeOff.recurrence_type,
      recurrence_end: hasEndDate ? "on_date" : "never", 
      recurrence_end_date: hasEndDate ? moment(timeOff.recurrence_end_date, "YYYY-MM-DD") : null,
      status: timeOff.status
    }
    timeOffForm.setFieldsValue(fields)
    setTimeOffModalVisible(true)
  }

  const onTimeOffStartDateChange = (value) => {
    const newDate = !isNull(value) ? value.format("YYYY-MM-DD") : null
    // If currently selected dates (before the change) are the same, adjust the end date as well
    if (timeOffStartDate == timeOffEndDate) {
      timeOffForm.setFieldsValue({ end_date: value })
      setTimeOffEndDate(newDate)
    } else if (newDate == timeOffEndDate) {
      resetRepeatFields()
    } else {
      resetRepeatFields()
      setShouldRepeat(false)
    }
    setTimeOffStartDate(newDate)
  }

  const onTimeOffEndDateChange = (value) => {
    const newDate = !isNull(value) ? value.format("YYYY-MM-DD") : null
    setTimeOffEndDate(newDate)
    if (newDate == timeOffStartDate) {
      resetRepeatFields()
    } else {
      resetRepeatFields()
      setShouldRepeat(false)
    }
  }

  const resetRepeatFields = () => {
    setShouldRepeat(true)
    setRecurrenceType(CalendarRepeatOptions.NONE)
    setShouldRecurrenceEnd(false)
    const fields = {
      recurrence_type: CalendarRepeatOptions.NONE,
      recurrence_end: "never",
      recurrence_end_date: null
    }
    timeOffForm.setFieldsValue(fields)
  }

  const onEndRepeatChange = (value) => {
    setShouldRecurrenceEnd(value == "on_date")
  }

  const onRecurrenceTypeChange = (value) => {
    setRecurrenceType(value)
    if (value == CalendarRepeatOptions.NONE) {
      timeOffForm.setFieldsValue({ recurrence_end: "never", recurrence_end_date: null })
      setShouldRecurrenceEnd(false)
    }
  }

  const renderTimeOffModal = () => {
    const employeeData = employees.map((user) => {
      return {
        value: user.user_id,
        text: user.first_name + " " + user.last_name
      }
    })
    const addTimeOffText = canManageTimeOff ? "Add Time Off" : "Submit"
    const showApprovedEditMesssage = !canManageTimeOff && !isAutomaticApproval && !isEmpty(selectedTimeOff) && selectedTimeOff.status == TimeOffStatus.APPROVED
    return (
      <Modal visible={isTimeOffModalVisible} footer={null} closable={false} wrapClassName="rounded-modal">
        <Row align="middle">
          <Col flex={1}>
            <div className="fw-700 fs-18">{ isEmpty(selectedTimeOff) ? "Add" : "Edit"} Time Off</div>
          </Col>
          <Col>
            <div className="display-flex" onClick={handleCancelTimeOff}><MdOutlineClose size={30} color={"#CCC"}/></div>
          </Col>
        </Row>
        <Form form={timeOffForm} layout="vertical" name="event-type" onFinish={onTimeOffSubmit}>
          { showApprovedEditMesssage && (
            <Row gutter={[10,10]} className="mt-15">
              <Col xs={24}>
                <div className="bg-light-red c-red p-10">Since this request has already been approved, any updates will set the status back to "Pending" and require re-approval.</div>
              </Col>
            </Row>
          )}
          <Row gutter={[10,10]} className="mt-15">
            { canManageTimeOff ? (
              <Col xs={24}>
                { renderSearchSelectField("Staff Member", "user_id", "Select a staff member", employeeData, true)}
              </Col>
            ) : (
              <Col xs={24}>
                { renderFormLabel("Staff Member")}
                <div>{user.first_name} {user.last_name}</div>
              </Col>
            )}
            <Col xs={24}>
              { renderTextAreaField("Reason", "description", 3, true)}
            </Col>
            { canManageTimeOff && (
              <Col xs={24}>
                { renderSelectField("Status", "status", "Select a status", timeOffStatuses, true)}
              </Col>
            )}
            <Col xs={24}>
              <Switch checked={allDayTimeOff} onChange={(value) => setAllDayTimeOff(value)} /> <span className="ml-10 fw-600">All day</span>
            </Col>
            <Col xs={12} md={12}>
              { renderDateField("Start Date", "start_date", accountSettings, onTimeOffStartDateChange) }
            </Col>
            { !allDayTimeOff && (
              <Col xs={12} md={12}>
                { renderTimeField("Start Time", "start_time", true, accountSettings) }
              </Col>
            )}
            <Col xs={12} md={12}>
              { renderDateField("End Date", "end_date", accountSettings, onTimeOffEndDateChange) }
            </Col>
            { !allDayTimeOff && (
              <Col xs={12} md={12}>
                { renderTimeField("End Time", "end_time", true, accountSettings) }
              </Col>
            )}
            { shouldRepeat && (
              <Col xs={24}>
                { renderSelectField("Repeat", "recurrence_type", "Select an option...", repeatOptions, true, onRecurrenceTypeChange)}
              </Col>
            )}
            { recurrenceType != CalendarRepeatOptions.NONE && (
              <Col xs={12}>
                { renderSelectField("End Repeat", "recurrence_end", "Select an option...", endRepeatOptions, true, onEndRepeatChange)}
              </Col>
            )}
            { shouldRecurrenceEnd && (
              <Col xs={12}>
                { renderDateField("Date", "recurrence_end_date", accountSettings) }
              </Col>
            )}
            { !isEmpty(timeOffError) && (
              <Col xs={24}>
                <div className="c-red text-center mt-10">{timeOffError}</div>
              </Col>
            )}
          </Row>
          <div className="admin-modal-footer">
            { isSaving ? (
              <button className="primary-button" type="button"><LoadingOutlined/></button>
            ) : (
              <button className="primary-button" type="submit">{isEmpty(selectedTimeOff) ? addTimeOffText : "Save"}</button>
            )}
            <div className="text-center mt-15">
              <div className="blue-link" onClick={handleCancelTimeOff}>Cancel</div>
            </div>
          </div>
        </Form>
      </Modal>
    )
  }

  return renderTimeOffModal();
});

export default TimeOffModal;

import React from "react";
import { Document, Page, Text, View, StyleSheet, Font, Image } from '@react-pdf/renderer';
import { isEmpty, isArray } from "lodash";
import openSansRegular from '../fonts/OpenSans/OpenSans-Regular.ttf'
import openSansItalic from '../fonts/OpenSans/OpenSans-Italic.ttf'
import openSansBold from '../fonts/OpenSans/OpenSans-Bold.ttf'
import openSansBoldItalic from '../fonts/OpenSans/OpenSans-BoldItalic.ttf'
import { formatCurrencyString, getNumericCurrency } from '../helpers/contractHelper';
import { formatDateShort, formatEventDateLong } from '../helpers/dateHelper';

Font.register({ family: 'OpenSans', fonts: [
  { src: openSansRegular },
  { src: openSansItalic, fontStyle: 'italic' },
  { src: openSansBold, fontWeight: 700 },
  { src: openSansBoldItalic, fontStyle: 'italic', fontWeight: 700 },
 ]});

const InvoicePDF = (props) => {
  const { invoice } = props;

  const styles = StyleSheet.create({
    page: {
      paddingTop: 25,
      paddingBottom: 65,
      paddingHorizontal: 25
    },
    row: {
      display: "flex",
      flexDirection: 'row'
    },
    flex0: {
      flex: 0
    },
    flex1: {
      flex: 1
    },
    invoiceCompanyName: {
      fontSize: 14,
      fontFamily: "OpenSans",
      fontWeight: 700,
    },
    invoiceHeader: {
      fontSize: 20,
      fontFamily: "OpenSans",
      textAlign: "right"
    },
    invoiceLabel: {
      fontSize: 10,
      fontFamily: "OpenSans",
      fontWeight: 700
    },
    invoiceData: {
      fontSize: 10,
      fontFamily: "OpenSans",
    },
    tableHeaderRow: {
      display: "flex",
      flexDirection: 'row',
      padding: 10,
      backgroundColor: "#F7F7F7",
      borderTop: "1px solid #e8e8e8",
      borderBottom: "1px solid #e8e8e8"
    },
    tableRow: {
      display: "flex",
      flexDirection: 'row',
      padding: 10,
      borderBottom: "1px solid #e8e8e8"
    },
    tableRowNoBorder: {
      display: "flex",
      flexDirection: 'row',
      padding: 10
    },
    tableRowData: {
      fontSize: 10,
      fontFamily: "OpenSans",
      textAlign: 'right'
    },
    tableRowDataLabel: {
      fontSize: 10,
      fontFamily: "OpenSans",
      fontWeight: 700,
      textAlign: 'right'
    },
    tableRowDiscountData: {
      fontSize: 8,
      fontFamily: "OpenSans",
      color: "#536DFE",
      textAlign: 'right'
    },
    tableHeaderLabel: {
      fontSize: 8,
      fontFamily: "OpenSans",
      fontWeight: 700
    },
    amountDueBox: {
      backgroundColor: "#F7F7F7",
      padding: 14,
      borderRadius: 4
    },
    amountDueLabel: {
      fontSize: 10,
      fontFamily: "OpenSans",
      fontWeight: 700,
      textAlign: 'center'
    },
    amountDueText: {
      fontSize: 16,
      fontFamily: "OpenSans",
      color: "#536DFE",
      textAlign: 'center',
      marginTop: 2
    }
  });

  const getDiscountText = (item) => {
    if (item.discount_type == "PERCENTAGE") {
      return `Discount applied (${item.discount_percentage})`
    } else {
      return "Discount applied"
    }
  }
    
  const renderTableHeader = () => {
    return (
      <View style={[styles.tableHeaderRow, { marginTop: 40 }]}>
        <View style={styles.flex1}>
          <Text style={styles.tableHeaderLabel}>ITEM</Text>
        </View>
        <View style={{ width: 120 }}>
          <Text style={[styles.tableHeaderLabel, { textAlign: "right"}]}>QTY</Text>
        </View>
        <View style={{ width: 120 }}>
          <Text style={[styles.tableHeaderLabel, { textAlign: "right"}]}>PRICE</Text>
        </View>
        <View style={{ width: 120 }}>
          <Text style={[styles.tableHeaderLabel, { textAlign: "right"}]}>AMOUNT</Text>
        </View>
      </View>
    )
  }
  
  const renderLabelData = (label, data) => {
    return (
      <View style={[styles.row, { marginBottom: 2 }]}>
        <View style={{ width: 80 }}>
          <Text style={styles.invoiceLabel}>{label}</Text>
        </View>
        <View style={{  }}>
          <Text style={styles.invoiceData}>{data}</Text>
        </View>
      </View>
    )
  }

  const renderLineItemRow = (item, index) => {
    return (
      <View style={[styles.tableRow]} key={index}>
        <View style={styles.flex1}>
          <Text style={[styles.tableRowData, { textAlign: "left"}]}>{item.name}</Text>
          { item.discount && (
            <Text style={[styles.tableRowDiscountData, { textAlign: "left", marginLeft: 8 }]}>{ getDiscountText(item) }</Text>
          )}
        </View>
        <View style={{ width: 120 }}>
          <Text style={styles.tableRowData}>{item.quantity}</Text>
        </View>
        <View style={{ width: 120 }}>
          <Text style={styles.tableRowData}>{formatCurrencyString(item.item_price, invoice.currency)}</Text>
        </View>
        <View style={{ width: 120 }}>
        <Text style={styles.tableRowData}>{formatCurrencyString(item.total_price, invoice.currency)}</Text>
          { item.discount && (
           <Text style={styles.tableRowDiscountData}>- { formatCurrencyString(item.total_discount, invoice.currency)}</Text>
          )}
        </View>
      </View>
    )
  }
  
  const renderContent = () => {
    const billToUser = !isEmpty(invoice.bill_to_user) ? invoice.bill_to_user : {}
    const hasTax = getNumericCurrency(invoice.tax) > 0
    return (
      <View>
        <View style={styles.row}>
          <View style={styles.flex1}>
            { !isEmpty(invoice.logo) ? (
              <Image source={invoice.logo} style={{ width: 160 }}/>
            ) : (
              <Text style={styles.invoiceCompanyName}>{invoice.company_name}</Text>
            )}
          </View>
          <View style={styles.flex1}>
            <Text style={styles.invoiceHeader}>INVOICE</Text>
          </View>
        </View>
        <View style={[styles.row, { marginTop: 40 }]}>
          <View style={styles.flex1}>
            <Text style={styles.invoiceLabel}>Bill To:</Text>
            <Text style={styles.invoiceData}>{billToUser.first_name} {billToUser.last_name}</Text>
            <Text style={styles.invoiceData}>{billToUser.email}</Text>
            <Text style={[styles.invoiceLabel, { marginTop: 10 }]}>Event Date:</Text>
            <Text style={styles.invoiceData}>{formatEventDateLong(invoice.event_date, invoice.account_settings)}</Text>
          </View>
          <View style={styles.flex1}>
            { renderLabelData("Invoice #:", invoice.invoice_number) }
            { renderLabelData("Invoice Date:", formatDateShort(invoice.create_datetime, invoice.account_settings)) }
            { renderLabelData("Due Date:", formatDateShort(invoice.due_date, invoice.account_settings)) }
          </View>
        </View>
        { renderTableHeader() }
        { isArray(invoice.line_items) && invoice.line_items.map((item,index) => renderLineItemRow(item, index))}
        { hasTax && (
          <>
            <View style={[styles.tableRowNoBorder]}>
              <View style={styles.flex1}></View>
              <View style={{ width: 120 }}>
                <Text style={[styles.tableRowDataLabel, { textAlign: "right"}]}>Subtotal:</Text>
              </View>
              <View style={{ width: 120 }}>
                <Text style={[styles.tableRowData, { textAlign: "right"}]}>{ formatCurrencyString(invoice.subtotal, invoice.currency) }</Text>
              </View>
            </View>
            <View style={[styles.tableRow]}>
              <View style={styles.flex1}></View>
              <View style={{ width: 120 }}>
                <Text style={[styles.tableRowDataLabel, { textAlign: "right"}]}>Tax:</Text>
              </View>
              <View style={{ width: 120 }}>
                <Text style={[styles.tableRowData, { textAlign: "right"}]}>{ formatCurrencyString(invoice.tax, invoice.currency)}</Text>
              </View>
            </View>
          </>
        )}
        <View style={[styles.tableRowNoBorder]}>
          <View style={styles.flex1}></View>
          <View style={{ width: 120 }}>
            <Text style={styles.tableRowDataLabel}>Total:</Text>
          </View>
          <View style={{ width: 120 }}>
            <Text style={styles.tableRowData}>{ formatCurrencyString(invoice.total, invoice.currency)}</Text>
          </View>
        </View>
        <View style={[styles.tableRow]}>
          <View style={styles.flex1}></View>
          <View style={{ width: 120 }}>
            <Text style={styles.tableRowDataLabel}>Payments:</Text>
          </View>
          <View style={{ width: 120 }}>
            <Text style={styles.tableRowData}>({ formatCurrencyString(invoice.payment_total, invoice.currency) })</Text>
          </View>
        </View>
        <View style={[styles.tableRow]}>
          <View style={styles.flex1}></View>
          <View style={{ width: 120 }}>
            <Text style={styles.tableRowDataLabel}>Balance:</Text>
          </View>
          <View style={{ width: 120 }}>
            <Text style={styles.tableRowDataLabel}>{ formatCurrencyString(invoice.balance, invoice.currency) }</Text>
          </View>
        </View>
        <View style={[styles.row, { marginTop: 40 }]}>
          <View style={styles.flex1}>
            { invoice.notes && (
              <>
                <Text style={styles.invoiceLabel}>Notes:</Text>
                <Text style={[styles.invoiceData, { marginTop: 2, marginRight: 20 }]}>{ invoice.notes }</Text>
              </>
            )}
          </View>
          <View style={{ width: 160 }}>
            <View style={styles.amountDueBox}>
              <Text style={styles.amountDueLabel}>Amount Due</Text>
              <Text style={styles.amountDueText}>{formatCurrencyString(invoice.balance, invoice.currency)}</Text>
            </View>
          </View>
        </View>
      </View>
    )
  }

  return (
  <Document>
    <Page size="A4" style={styles.page}>
      { renderContent() }
    </Page>
  </Document>
  );
}

export default InvoicePDF;

import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import useApi from '../../../hooks/useApi';
import { Form, Row, Col, Modal, Grid, Dropdown, Menu, notification, Checkbox, Tooltip } from 'antd';
import { 
  getCalendarLinks,
  createCalendarLink,
  updateCalendarLink,
  deleteCalendarLink,
  getAdminUsers,
  getAccountSettings,
  updateAccountCalendarSettings
} from '../../../api';
import LoadingSpinner from '../../../components/loading';
import AdminContent from '../../../components/adminContent';
import FloatingContainer from '../../../components/floatingContainer'
import { MdArrowForwardIos, MdOutlineClose, MdOutlineMoreHoriz, MdInfoOutline } from "react-icons/md";
import {
  renderInputField,
  renderSelectField
} from '../../../components/formFields'
import AppContext from '../../../app/context';
import Permissions from '../../../constants/permissions';
import CalendarEventDetailTypes from '../../../constants/calendarEventDetailTypes';
import { hasPermission } from '../../../helpers/permissionHelper';
import useDocumentTitle from '../../../hooks/useDocumentTitle';
import useAccountSettings from '../../../hooks/useAccountSettings';
import { DeleteOutlined, EditOutlined, LinkOutlined } from '@ant-design/icons';
import { cloneDeep, isEmpty } from "lodash";
import FeatureFlags from "../../../constants/featureFlags";

const CalendarSettingsPage = () => {

  const [isLoading, setLoading] = useState(true);
  const [isCalendarLinkModalVisible, setCalendarLinkModalVisible] = useState(false);
  const [isConfirmRemoveCalendarLinkModalVisible, setConfirmRemoveCalendarLinkModalVisible] = useState(false);
  const [isUpdateCalendarSettingsModalVisible, setUpdateCalendarSettingsModalVisible] = useState(false);
  const [isCalendarLinkNew, setCalendarLinkNew] = useState(false);
  const [selectedCalendar, setSelectedCalendar] = useState({});
  const [calendars, setCalendars] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [selectedEmployees, setSelectedEmployees] = useState([]);
  const [calendarLinkError, setCalendarLinkError] = useState("");
  const [eventDetailTypes, setEventDetailTypes] = useState([]);
  const [selectedEventDetailTypes, setSelectedEventDetailTypes] = useState([]);
  const [accountSettings, setAccountSettings] = useState({});
  const [timeOffApproval, setTimeOffApproval] = useState("");

  const { auth, setAuth } = useContext(AppContext);
  const user = auth.user ? auth.user : {}
  const canViewAllEvents = hasPermission(user, Permissions.VIEW_ALL_EVENTS);

  useDocumentTitle("Calendar Settings")
  const navigate = useNavigate();
  const [calendarLinkForm] = Form.useForm();
  const [calendarSettingsForm] = Form.useForm();
  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();
  const [sendRequest] = useApi()
  const [_d, setPersistedAccountSettings] = useAccountSettings()

  const calendarStartDayOptions = [
    { value: "Sunday", label: "Sunday" },
    { value: "Monday", label: "Monday" }
  ]

  const calendarTimeOffApprovalOptions = [
    { value: "MANUAL", text: "Manually approve all requests" },
    { value: "AUTOMATIC", text: "Automatically approve all requests" }
  ]

  useEffect(() => {
    window.scrollTo(0, 0);
    refreshPage();
  }, []);

  const refreshPage = async () => {
    try {
      const calendarResults = await sendRequest(getCalendarLinks())
      setCalendars(calendarResults)

      if (FeatureFlags.TIME_OFF) {
        await refreshAccountSettings()
      }

      const employeeResults = await sendRequest(getAdminUsers())
      setEmployees(employeeResults)

      var types = []
      for (const [key, value] of Object.entries(CalendarEventDetailTypes)) {
        types.push(value)
      }
      setEventDetailTypes(types)

      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  const refreshAccountSettings = async () => {
    try {

      const accountSettingsResults = await sendRequest(getAccountSettings())
      setAccountSettings(accountSettingsResults)

    } catch (error) { }
  }

  const onAddCalendarLink = () => {
    setCalendarLinkNew(true)
    setSelectedEmployees([])
    setSelectedEventDetailTypes([])
    setCalendarLinkError("")
    setCalendarLinkModalVisible(true)
    calendarLinkForm.resetFields()
  }

  const saveCalendarLink = async (values) => {
    if (selectedEmployees.length == 0) {
      setCalendarLinkError("At least one staff member must be selected!")
      return
    }
    try {
      const body = {
        calendar_name: values["calendar_name"],
        selected_user_events: selectedEmployees,
        event_details: selectedEventDetailTypes
      }
      if (isCalendarLinkNew) {
        await sendRequest(createCalendarLink(body))
      } else {
        await sendRequest(updateCalendarLink(selectedCalendar.calendar_id, body))
      }
      await refreshPage()
      setCalendarLinkModalVisible(false)
    } catch (error) {
      setCalendarLinkModalVisible(false)
    }
  }

  const handleEditCalendarLink = (item) => {
    setSelectedCalendar(item)
    setSelectedEmployees(item.selected_user_events)
    setSelectedEventDetailTypes(item.event_details)
    setCalendarLinkNew(false)
    setCalendarLinkError("")
    setCalendarLinkModalVisible(true)
    calendarLinkForm.setFieldsValue({ calendar_name: item.calendar_name, calendar_type: item.calendar_type })
  }

  const confirmDeleteLink = async () => {
    try {
      await sendRequest(deleteCalendarLink(selectedCalendar.calendar_id))
      await refreshPage()
    } catch (error) {
      notification.error({
        message: 'Error!',
        description: 'There was a problem removing your link',
        duration: 3
      });
    } finally {
      setConfirmRemoveCalendarLinkModalVisible(false)
    }
  }

  const handleDeleteCalendarLink = (item) => {
    setSelectedCalendar(item)
    setConfirmRemoveCalendarLinkModalVisible(true)
  }

  const copyLink = () => {
    notification.success({
      message: 'Success!',
      description: 'The link has been copied to your clipboard.',
      duration: 3
    });
  }

  const toggleSelectedEmployee = (user_id) => {
    setCalendarLinkError("")
    var newSelectedEmployees = cloneDeep(selectedEmployees)
    if (newSelectedEmployees.includes(user_id)) {
      newSelectedEmployees = newSelectedEmployees.filter(x => x != user_id)
    } else {
      newSelectedEmployees.push(user_id)
    }
    setSelectedEmployees(newSelectedEmployees)
  }

  const toggleSelectedEventDetail = (key) => {
    var newSelectedTypes = cloneDeep(selectedEventDetailTypes)
    if (newSelectedTypes.includes(key)) {
      newSelectedTypes = newSelectedTypes.filter(x => x != key)
    } else {
      newSelectedTypes.push(key)
    }
    setSelectedEventDetailTypes(newSelectedTypes)
  }

  const isEmployeeDisabled = (employee) => {
    return employee.user_id != auth.user.user_id && !canViewAllEvents
  }

  const startUpdateCalendarSettings = () => {
    const fields = {
      calendar_start_day: accountSettings.calendar_start_day,
      time_off_approval: accountSettings.time_off_approval
    }
    calendarSettingsForm.setFieldsValue(fields)
    setTimeOffApproval(accountSettings.time_off_approval)
    setUpdateCalendarSettingsModalVisible(true)
  }

  const saveCalendarSettings = async (values) => {
    try {
      const body = {
        calendar_start_day: values["calendar_start_day"],
        time_off_approval: values["time_off_approval"]
      }
      await sendRequest(updateAccountCalendarSettings(body))
      await refreshAccountSettings()
      setPersistedAccountSettings({})
      setUpdateCalendarSettingsModalVisible(false)
    } catch (error) {
      setUpdateCalendarSettingsModalVisible(false)
    }
  }

  const getTimeOffApprovalLabel = (value) => {
    const foundOption = calendarTimeOffApprovalOptions.find(x => x.value == value)
    if (!isEmpty(foundOption)) {
      return foundOption.text
    }
    return ""
  }

  const getTimeOffApprovalDescription = () => {
    if (timeOffApproval == "MANUAL") {
      return `Based on this setting, all time off requests submitted by staff members will be created in a "Pending" status. Any admin or staff member with the "Manage Time Off" permission will have to manually approve or reject the time off request on the calendar.`
    } else {
      return `Based on this setting, all time off requests submitted by staff members will be automatically marked as "Approved".`
    }
  }

  const menu = (item) => {
    return (
      <Menu>
        <Menu.Item>
          <CopyToClipboard text={item.link} onCopy={() => copyLink()}>
            <div>
              <LinkOutlined style={{ marginRight: 8}}/> Copy Link
            </div>
          </CopyToClipboard>
        </Menu.Item>
        <Menu.Item>
          <div onClick={() => handleEditCalendarLink(item)}>
            <EditOutlined style={{ marginRight: 8}}/> Edit
          </div>
        </Menu.Item>
        <Menu.Item>
          <div onClick={() => handleDeleteCalendarLink(item)}>
            <DeleteOutlined style={{ marginRight: 8}}/> Remove
          </div>
        </Menu.Item>
      </Menu>
    )
  };

  const renderHeader = () => {
    return (
      <div className="p-20">
        <div>
          <span className="c-blue fw-700 cursor-default" onClick={() => navigate("/admin/setup")}>
            Setup
          </span>
          <span className="fs-10 mh-5"><MdArrowForwardIos/></span>
          <span className="cursor-default c-text-gray">
            Calendar
          </span>
        </div>
        <div className="fw-700 fs-24 mt-5">Calendar</div>
      </div>
    )
  }

  const renderEmployeeCheckbox = (employee, index) => {
    const isDisabled = isEmployeeDisabled(employee)
    const disabledText = isDisabled ? ` (requires permissions)` : ""
    const isSelected = selectedEmployees.includes(employee.user_id)
    return (
      <div className="mt-10" key={index}>
        <Checkbox checked={isSelected} onClick={() => toggleSelectedEmployee(employee.user_id)} disabled={isDisabled}>
          <div className="">{employee.first_name} {employee.last_name}{disabledText}</div>
        </Checkbox>
      </div>
    )
  }

  const renderEventDetailCheckbox = (type, index) => {
    const isSelected = selectedEventDetailTypes.includes(type.key)
    return (
      <div className="mt-10" key={index}>
        <Checkbox checked={isSelected} onClick={() => toggleSelectedEventDetail(type.key)}>
          <div className="">{type.value}</div>
        </Checkbox>
      </div>
    )
  }

  const renderUnassignedCheckbox = () => {
    const isDisabled = !canViewAllEvents
    const disabledText = isDisabled ? ` (requires permissions)` : ""
    const isSelected = selectedEmployees.includes(1)
    return (
      <div className="mt-10">
        <Checkbox checked={isSelected} onClick={() => toggleSelectedEmployee(1)} disabled={isDisabled}>
          <div className="">Unassigned{disabledText}</div>
        </Checkbox>
      </div>
    )
  }

  const renderLabelWithTooltip = (label, tooltip) => {
    return (
      <div className="display-flex flex-middle">
        <div className="fs-14 fw-700 display-flex">{label}</div>
        <Tooltip title={tooltip}>
          <MdInfoOutline style={{ fontSize: 14, marginLeft: 6, color: '#777'}}/>
        </Tooltip>
      </div>
    )
  }

  const renderCalendarLinkModal = () => {
    return (
      <Modal visible={isCalendarLinkModalVisible} footer={null} onCancel={() => setCalendarLinkModalVisible(false)} width={500} closable={false} maskClosable={false} wrapClassName="rounded-modal">
        <Row align="middle">
          <Col flex={1}>
            <div className="fw-700 fs-18">{ isCalendarLinkNew ? "Add iCal Link" : "Edit iCal Link" }</div>
          </Col>
          <Col>
            <div className="display-flex" onClick={() => setCalendarLinkModalVisible(false)}><MdOutlineClose size={30} color={"#CCC"}/></div>
          </Col>
        </Row>
        <div className="">
          <Form form={calendarLinkForm} layout="vertical" name="verify" onFinish={saveCalendarLink}>
            <div className="mt-10 mb-15">
              {renderInputField("Name", "calendar_name", true, false, "Enter a name...")}
              <div className="mt-20">
                <div className="fw-700">Include events assigned to:</div>
                { employees.map((employee, index) => renderEmployeeCheckbox(employee, index))}
                {renderUnassignedCheckbox()}
              </div>
              <div className="mt-20">
                <div className="fw-700">Include event details:</div>
                { eventDetailTypes.map((type, index) => renderEventDetailCheckbox(type, index))}
              </div>
              { calendarLinkError && (
                <div className="mt-15 c-red">
                  { calendarLinkError }
                </div>
              )}
            </div>
            <button className="primary-button" type="submit">Save</button>
            <div className="text-center mt-15">
              <div className="blue-link" onClick={() => setCalendarLinkModalVisible(false)}>Cancel</div>
            </div>
          </Form>
        </div>
      </Modal>
    )
  }

  const renderCalendarLink = (calendar, index) => {
    return (
      <Row className={`b-border pv-15`} gutter={[10,10]} align="middle" wrap={false} key={index}>
        <Col flex={1}>
          <div>
            <div className="fs-14 fw-700">{calendar.calendar_name}</div>
            { screens.md ? (
              <div className="fs-14" style={{ }}>{calendar.link}</div>
            ) : (
              <div className="fs-14" style={{ }}>ID: {calendar.calendar_id}</div>
            )}
          </div>
        </Col>
        <Col flex={0}>
          <CopyToClipboard text={calendar.link} onCopy={() => copyLink()}>
            <div className="dots-container">
              <LinkOutlined style={{ fontSize: 20, color: '#999'}}/>
            </div>
          </CopyToClipboard>
        </Col>
        <Col flex={0}>
          <Dropdown overlay={menu(calendar)} placement="bottomRight" trigger="click">
            <div className="dots-container">
              <MdOutlineMoreHoriz style={{ fontSize: 24, color: '#999'}}/>
            </div>
          </Dropdown>
        </Col>
      </Row>
    )
  }

  const renderConfirmRemoveModal = () => {
    return (
      <Modal visible={isConfirmRemoveCalendarLinkModalVisible} closable={false} footer={null} width={400} wrapClassName="rounded-modal">
        <div className="text-right" onClick={() => setConfirmRemoveCalendarLinkModalVisible(false)}><MdOutlineClose size={30} color={"#CCC"}/></div>
        <div className="mt-10">
          <div className="fw-700 fs-20 text-center">Remove iCal Link</div>
          <div className="fw-500 fs-14 mt-20 mb-20 text-center">Are you sure you would like to remove this link? If so, please unsubscribe from this calendar within your calendar application. All calendars that are subscribed to this feed will no longer populate events.</div>
          <button className="primary-button warning" type="button" onClick={() => confirmDeleteLink()}>Remove Link</button>
          <div className="text-center mt-15">
            <div className="blue-link" onClick={() => setConfirmRemoveCalendarLinkModalVisible(false)}>Cancel</div>
          </div>
        </div>
      </Modal>
    )
  }

  const renderUpdateCalendarSettingsModel = () => {
    return (
      <Modal visible={isUpdateCalendarSettingsModalVisible} footer={null} onCancel={() => setUpdateCalendarSettingsModalVisible(false)} width={500} closable={false} maskClosable={false} wrapClassName="rounded-modal">
        <Row align="middle">
          <Col flex={1}>
            <div className="fw-700 fs-18">Update Calendar Settings</div>
          </Col>
          <Col>
            <div className="display-flex" onClick={() => setUpdateCalendarSettingsModalVisible(false)}><MdOutlineClose size={30} color={"#CCC"}/></div>
          </Col>
        </Row>
        <div className="">
          <Form form={calendarSettingsForm} layout="vertical" name="verify" onFinish={saveCalendarSettings}>
            <div className="mt-10">
              {renderSelectField("Calendar Start Day", "calendar_start_day", "Select an option...", calendarStartDayOptions, true)}
            </div>
            <div className="mt-10">
              {renderSelectField("Time Off Approval", "time_off_approval", "Select an option...", calendarTimeOffApprovalOptions, true, (value) => setTimeOffApproval(value))}
            </div>
            <div className="bg-gray p-10 mt-10 fs-12">
              { getTimeOffApprovalDescription()}
            </div>
            <button className="primary-button" type="submit">Save</button>
            <div className="text-center mt-15">
              <div className="blue-link" onClick={() => setUpdateCalendarSettingsModalVisible(false)}>Cancel</div>
            </div>
          </Form>
        </div>
      </Modal>
    )
  }

  const renderCalendarSettingsSection = () => {
    return (
      <div className="shadow-card p-20 mb-20">
        <Row className="b-border pb-15 mb-15">
          <Col flex={1}>
            <div className="fs-20 fw-700 line-1">Calendar Settings</div>
          </Col>
          <Col>
            <div className="blue-link" onClick={() => startUpdateCalendarSettings()}>Edit</div>
          </Col>
        </Row>
        { renderLabelWithTooltip("Calendar Start Day", "This setting will determine what day of the week the calendar starts.")}
        <div className="fs-14 mb-15">{ accountSettings.calendar_start_day }</div>
        <div className="b-border mb-15"></div>
        { renderLabelWithTooltip("Time Off Approval", "This setting will determine how time off requests that are submitted by staff members are approved.")}
        <div className="fs-14 mb-15">{getTimeOffApprovalLabel(accountSettings.time_off_approval)}</div>
      </div>
    )
  }

  const renderContent = () => {
    return (
      <>
        <FloatingContainer className="ph-20 mb-80" verticalPadding={20} maxWidth={800}>
          { FeatureFlags.TIME_OFF && renderCalendarSettingsSection() }
          <div className="shadow-card p-20">
            <Row className="b-border pb-15">
              <Col flex={1}>
                <div className="fs-20 fw-700 line-1">iCal Links</div>
              </Col>
              <Col>
                <div className="blue-link" onClick={() => navigate("/admin/docs/calendar-links")}>Learn More</div>
              </Col>
            </Row>
            { calendars.map((x,i) => renderCalendarLink(x,i))}
            { calendars.length > 0 && (
              <div className="blue-link mt-15" onClick={() => onAddCalendarLink()}>+ Add Link</div>
            )}
            { calendars.length == 0 && (
              <div className="bg-gray mt-15 p-20 text-center">
                <div>Create an iCal link to export your events to your calendar. By subscribing to this link, your calendar will continuously be updated with the latest information in DJ Planning Center.</div>
                <button className="small-primary-button mt-15" onClick={onAddCalendarLink}>Add Link</button>
              </div>
            )}
          </div>
        </FloatingContainer>
        { renderCalendarLinkModal() }
        { renderConfirmRemoveModal() }
        { renderUpdateCalendarSettingsModel() }
      </>
    )
  }

  if (isLoading) {
    return <LoadingSpinner/>
  }

  return (
    <AdminContent header={renderHeader()} body={renderContent()}/>
  );
}

export default CalendarSettingsPage;
import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {SortableContainer, sortableElement} from 'react-sortable-hoc';
import arrayMove from 'array-move';
import { isUndefined, cloneDeep } from "lodash";
import { Row, Col, Input, notification } from 'antd';
import { getSystemRecommendedPlaylist, updateSystemRecommendedPlaylist, createSystemRecommendedPlaylist } from '../../api';
import LoadingSpinner from '../../components/loading';
import AdminContent from '../../components/adminContent';
import useApi from '../../hooks/useApi';
import MusicModal from '../../components/musicModal';
import PlatformSelectionModal from '../../modals/platformSelectionModal';
import ImportPlaylistModal from '../../modals/importPlaylistModal';
import CustomSongModal from '../../modals/customSongModal';
import { FiPlus } from "react-icons/fi";
import { MdRemoveCircleOutline, MdDragIndicator, MdArrowForwardIos } from "react-icons/md";
import emptyMusicImage from '../../images/empty-music-icon.png';

const NewSystemRecommendedPlaylistPage = (props) => {

  const timerRef = useRef(null);

  const [isLoading, setLoading] = useState(true);
  const [searchText, setSearchText] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [playlistSongs, setPlaylistSongs] = useState([]);
  const [playlistName, setPlaylistName] = useState("");
  const [isMusicSearchModalVisible, setMusicSearchModalVisible] = useState(false);
  const [isPlatformSelectionVisible, setPlatformSelectionVisible] = useState(false);
  const [isCustomSongModalVisible, setCustomSongModalVisible] = useState(false);
  const [isImportPlaylistModalVisible, setImportPlaylistModalVisible] = useState(false);
  const [selectedPlatform, setSelectedPlatform] = useState("spotify");

  const navigate = useNavigate();
  const params = useParams();
  const [sendRequest] = useApi()

  const playlistId = params.id;
  const isNewPlaylist = isUndefined(playlistId);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (!isNewPlaylist) {
      refreshPage();
    } else {
      setLoading(false)
    }
  }, []);

  const refreshPage = () => {
    sendRequest(getSystemRecommendedPlaylist(playlistId)).then(response => {
      setPlaylistName(response.playlist_name)
      setPlaylistSongs(response.songs)
      setLoading(false)
    }).catch((e) => setLoading(false))
  }

  const onSubmit = () => {

    var body = {
      playlist_name: playlistName,
      songs: playlistSongs
    }

    if (isNewPlaylist) {
      sendRequest(createSystemRecommendedPlaylist(body)).then(response => {
        displaySuccess()
      })
    } else {
      sendRequest(updateSystemRecommendedPlaylist(playlistId, body)).then((response) => {
        displaySuccess()
      })
    }
  }

  const displaySuccess = () => {
    notification.success({
      message: 'Success!',
      description: 'Your recommended playlist has been saved.',
      duration: 3
    });
    navigate("/system/recommended-playlists");
  }
  
  const onSortEnd = (oldIndex, newIndex) => {
    var newSongs = cloneDeep(playlistSongs)
    if (oldIndex !== newIndex) {
      const sortedSongs = arrayMove(newSongs, oldIndex, newIndex).filter(el => !!el);
      setPlaylistSongs(sortedSongs)
    }
  };

  const SortableItem = sortableElement(({value, itemIndex}) => (
    <li className="">
      {renderPlaylistSong(value, itemIndex)}
    </li>
  ));

  const SortableList = SortableContainer(({items, sectionIndex}) => {
    return (
      <ul className="question-row-container">
        {items.map((value, index) => (
          <SortableItem key={`item-${index}`} index={index} itemIndex={index} value={value} disabled={!isEdit} />
        ))}
      </ul>
    );
  });

  const addSong = (song) => {
    const newPlaylistSongs = [].concat(playlistSongs);
    const existingSong = newPlaylistSongs.find((x) => x.song_id == song.song_id);

    if (existingSong) {
      return;
    }

    newPlaylistSongs.push(song)
    setPlaylistSongs(newPlaylistSongs)
  }

  const removeSong = (song, index) => {
    const newPlaylistSongs = [].concat(playlistSongs);
    newPlaylistSongs.splice(index, 1);
    setPlaylistSongs(newPlaylistSongs)
  }

  const selectPlatform = (platform) => {
    setSelectedPlatform(platform)
    setPlatformSelectionVisible(false)
    setMusicSearchModalVisible(true)
  }

  const displayCustomSong = () => {
    setPlatformSelectionVisible(false)
    setCustomSongModalVisible(true)
  }

  const startImportPlaylist = () => {
    setMusicSearchModalVisible(false)
    setImportPlaylistModalVisible(true)
  }

  const saveImportedSongs = async (songs) => {
    const newPlaylistSongs = cloneDeep(playlistSongs);

    for (const song of songs) {
      newPlaylistSongs.push(song)
    }

    setPlaylistSongs(newPlaylistSongs)
    setImportPlaylistModalVisible(false)
  }

  const saveCustomSong = async (values) => {
    const newPlaylistSongs = cloneDeep(playlistSongs);

    const song = {
      title: values.title,
      artist: values.artist,
      image: "",
      notes: "",
      duration: 0,
      url: "",
      preview_url: "",
      source: "custom",
      rank: null
    }

    newPlaylistSongs.push(song)
    setPlaylistSongs(newPlaylistSongs)
    setCustomSongModalVisible(false)
  }

  const renderPlaylistSong = (item, index) => {
    return (
      <div className="music-playlist-song" key={index}>
        <div className="flex-0">
          <div className="c-text-gray fs-18 fw-600">{index + 1}</div>
        </div>
        <div className="flex-0">
          <img src={item.image} style={{ width: 60 }}/>
        </div>
        <div className="music-playlist-song--col">
          <div className="music-playlist-song-text fs-14 fw-700 line-1-2">{item.title}</div>
          <div className="music-playlist-song-text fs-14 c-text-gray">{item.artist}</div>
        </div>
        <div className="display-flex mr-10">
          { !isEdit && (
            <MdRemoveCircleOutline style={{ fontSize: 24, color: '#CCC'}} onClick={() => removeSong(item,index)}/>
          )}
          { isEdit && (
            <MdDragIndicator style={{ fontSize: 24, color: '#CCC'}}/>
          )}
        </div>
      </div>
    )
  }

  const renderHeader = () => {
    return (
      <div className="p-20">
        <div className="pb-10">
          <span className="c-blue fw-700 cursor-default" onClick={() => navigate(-1)}>
            Recommended Playlists
          </span>
          <span className="fs-10 mh-5"><MdArrowForwardIos/></span>
          <span className="cursor-default c-text-gray">
            Edit
          </span>
        </div>
        <Input placeholder="Playlist Name" size="large" value={playlistName} onChange={(e) => setPlaylistName(e.target.value)} style={{ fontSize: 18, fontWeight: 700, maxWidth: 500}}/>
      </div>
    )
  }

  const renderPlaylist = () => {
    if (playlistSongs.length == 0 && !isEdit) {
      return (
        <div className="shadow-card ph-20 pv-50 text-center">
          <div>
            <img src={emptyMusicImage} width={200}/>
          </div>
          <div className="fs-18 fw-700 mt-30">
            No songs yet
          </div>
          <div className="fs-14 fw-500 c-text-gray">
            Add songs to get started.
          </div>
          <button className="primary-button mt-30" style={{ width: 200 }} onClick={() => setPlatformSelectionVisible(true)}>Add Songs</button>
        </div>
      )
    }
    return (
      <>
        <Row>
          <Col flex={1}>
            <div className="fs-16 fw-700 mb-10 ml-10">Songs ({playlistSongs.length})</div>
          </Col>
          <Col>
            <div className="admin-link mr-10" onClick={() => setIsEdit(!isEdit)}>{isEdit ? "Done" : "Reorder"}</div>
          </Col>
        </Row>
        <div className="shadow-card">
          <div className="display-flex flex-middle p-10 b-border" onClick={() => setPlatformSelectionVisible(true)}>
            <div className="flex-0">
              <div className="song-image-icon"><FiPlus/></div>
            </div>
            <div className="flex-1 fw-700 ml-15">Add Songs</div>
          </div>
          <SortableList items={playlistSongs} onSortEnd={({ oldIndex, newIndex }) => onSortEnd(oldIndex, newIndex)} helperClass="question-row-dragging" pressDelay={100} lockAxis="y"/>
        </div>
      </>
    )
  }

  const renderContent = () => {
    return (
      <div className="p-15" style={{ maxWidth: 600, marginBottom: 70 }}>
        { renderPlaylist() }
        <div className="sticky-footer p-0">
          <div className="bg-white t-border p-15">
            <button className="page-title-button small-100" onClick={onSubmit}>Save</button>
          </div>
        </div>
        <MusicModal 
          isVisible={isMusicSearchModalVisible} 
          title="Add Songs" 
          eventName={""} 
          onCancel={() => setMusicSearchModalVisible(false)}
          onSelect={addSong}
          selectedEvent={{}}
          selectedSongs={playlistSongs}
          selectedPlatform={selectedPlatform}
          startImportPlaylist={startImportPlaylist}
        />
        <PlatformSelectionModal 
          isVisible={isPlatformSelectionVisible}
          setVisible={setPlatformSelectionVisible}
          selectPlatform={selectPlatform}
          addCustomSong={displayCustomSong}
        />
        <ImportPlaylistModal 
          isVisible={isImportPlaylistModalVisible}
          setVisible={setImportPlaylistModalVisible}
          selectedPlatform={selectedPlatform}
          saveImportedSongs={saveImportedSongs}
        />
        <CustomSongModal 
          isVisible={isCustomSongModalVisible}
          setVisible={setCustomSongModalVisible}
          saveCustomSong={saveCustomSong}
        />
      </div>
    )
  }

  if (isLoading) {
    return <LoadingSpinner/>
  }

  return (
    <AdminContent header={renderHeader()} body={renderContent()}/>
  );
}

export default NewSystemRecommendedPlaylistPage;

import React, { useEffect, useState } from "react";
import { Row, Col, Table } from 'antd';
import { useNavigate, useLocation, Outlet } from 'react-router';
import useApi from '../../hooks/useApi';
import { getSystemRecommendedPlaylistGroupLists } from '../../api';
import LoadingSpinner from '../../components/loading';
import AdminContent from '../../components/adminContent';

const SystemRecommendedPlaylistGroupListsPage = (props) => {

  const [isLoading, setLoading] = useState(true);
  const [groupLists, setGroupLists] = useState([]);

  const navigate = useNavigate();
  const [sendRequest] = useApi()

  const columns = [
    {
      title: 'Group List Key',
      dataIndex: 'group_list_key',
      key: 'group_list_key',
      render: (text) => text
    },
    {
      title: 'Groups',
      dataIndex: 'group_count',
      key: 'group_count',
      render: (text, record) => record.system_recommended_playlist_group_ids.length
    }
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
    refreshPage()
  }, []);

  const refreshPage = () => {
    sendRequest(getSystemRecommendedPlaylistGroupLists()).then(response => {
      setGroupLists(response);
      setLoading(false)
    }).catch((e) => setLoading(false))
  }

  const renderHeader = () => {
    return (
      <Row align="middle" className="p-20">
        <Col flex={1}>
          <div className="admin-page-title">Playlist Group Lists</div>
        </Col>
        <Col>
          <button className="page-title-button" onClick={() => navigate("/system/recommended-playlist-group-lists/new")}>New</button>
        </Col>
      </Row>
    )
  }

  const renderContent = () => {

    const data = groupLists.map((x, i) => {
      return {
        ...x,
        key: i
      }
    });

    return (
      <div className="p-15" style={{ maxWidth: 800 }}>
        <div className="admin-page-content-box">
          <Table 
            columns={columns} 
            dataSource={data}
            pagination={false}
            onRow={(record, rowIndex) => {
              return {
                onClick: e => navigate(`/system/recommended-playlist-group-lists/${record.group_list_id}/edit`)
              };
            }}
           />
        </div>
      </div>
    )
  }

  if (isLoading) {
    return <LoadingSpinner/>
  }

  return (
    <AdminContent header={renderHeader()} body={renderContent()}/>
  );
}

export default SystemRecommendedPlaylistGroupListsPage;

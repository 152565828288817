import React, { useEffect, useState, useContext } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import qs from "qs";
import { get } from 'lodash';
import moment from 'moment';
import { jsPDF } from "jspdf";
import { Grid } from 'antd';
import useApi from '../../hooks/useApi';
import { getTimelinePreview } from '../../api';
import { MdOutlineSearch, MdAddCircleOutline, MdOutlineStickyNote2, MdCheck, MdOutlineMoreHoriz, MdRemoveCircleOutline, MdOutlineClose, MdArrowForwardIos } from "react-icons/md";


const TimelinePreviewPage = (props) => {

  const [isLoading, setLoading] = useState(true);
  const [timelineName, setTimelineName] = useState("");
  const [eventName, setEventName] = useState("");
  const [eventDate, setEventDate] = useState(moment());
  const [events, setEvents] = useState([]);
  const [isPreview, setPreview] = useState(false);

  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();
  const [sendRequest] = useApi()

  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();

  useEffect(() => {
    window.scrollTo(0, 0)
    const queryStrings = qs.parse(location.search, { ignoreQueryPrefix: true })
    if (queryStrings.type && queryStrings.type == "preview") {
      setPreview(true)
    }
    const timelineId = params.id;
    if (!timelineId) {
      navigate("/", { replace: true })
    } else {
      getTimelinePreview(timelineId).then(response => {
        setTimelineName(response.data.timeline_name)
        setEventName(response.data.event_name)
        setEventDate(moment(response.data.event_date))
        setEvents(response.data.events);
        setLoading(false)
      }).catch((e) => setLoading(false))
    }
  }, [])

  const downloadPDF = () => {
    const doc = new jsPDF('p', 'pt', 'a4');

    doc.html(document.getElementById('pdf-document'), {
      callback: function (doc) {
        doc.save();
      },
      filename: 'event-timeline.pdf',
      x: 20,
      y: 20
   });
  }

  const renderEventSong = (song, index) => {
    return (
      <div className="line-1-2" key={index}>
        <span className="fw-600">"{song.title}"</span>
        <span className="fw-300"> - {song.artist}</span>
      </div>
    )
  }

  const renderEvent = (event, index) => {
    const borderClass = index < events.length - 1 ? "b-border" : ""
    return (
      <div className={`timeline-preview-row ${borderClass}`} key={index}>
        <div className="timeline-preview-row--time">
          <div className="fw-700">{ event.time }</div>
        </div>
        <div className="timeline-preview-row--event">
          <div className="fw-600">{ event.name }</div>
          <div className="fw-500 c-text-gray">{ event.notes }</div>
        </div>
        { event.songs && event.songs.length > 0 && (
          <div className="timeline-preview-row--songs">
            { event.songs && event.songs.map((x,i) => renderEventSong(x,i))}
          </div>
        )}
      </div>
    )
  }

  const renderHeader = () => {
    return (
      <div className={`timeline-preview-row b-border`}>
        <div className="timeline-preview-row--time">
          <div className="fw-700 fs-16">Time</div>
        </div>
        <div className="timeline-preview-row--event">
          <div className="fw-700 fs-16">Event</div>
        </div>
        <div className="timeline-preview-row--songs-header">
          <div className="fw-700 fs-16">Music</div>
        </div>
      </div>
    )
  }

  const renderTopBar = () => {
    if (isPreview) {
      return (
        <div className="timeline-preview-bar">
          <div onClick={() => navigate(-1)}>
            <MdOutlineClose size={30} color={"#CCC"}/>
          </div>
        </div>
      )
    }
    return null;
  }

  const renderContent = () => {
    return (
      <div>
        { renderTopBar() }
        <div style={{ margin: "0 auto", paddingTop: 30,  paddingBottom: 30, maxWidth: 1000}}>
          {/* <div className="text-right mr-20 mb-10">
            <button className="small-primary-button" style={{ width: 140 }} onClick={() => downloadPDF()}>Download PDF</button>
          </div> */}
          <div className="print-card">
            <div className="text-center fs-24 fw-700">{ eventName }</div>
            <div className="text-center fs-16 c-text-gray">{eventDate.format("dddd, MMMM D, YYYY")}</div>
            <div className="mt-30">
              { renderHeader() }
              { events.map((x,i) => renderEvent(x,i))}
            </div>
          </div>
        </div>
        <div style={{display: "none" }}>
          <div id="pdf-document" style={{ width: 612}}>
            <div className="text-center fw-700 fs-16 line-1">{eventName}</div>
            <div className="text-center fw-500 fs-12">{eventDate.format("dddd, MMMM D, YYYY")}</div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="bg-gray" style={{ minHeight: '100vh'}}>
      { renderContent() }
    </div>
  );
}

export default TimelinePreviewPage;

import React, { useEffect, useState } from "react";
import { Tag, Row, Col } from 'antd';
import { isNull, isArray } from "lodash";
import moment from "moment";
import { useNavigate, useParams } from "react-router-dom";
import useApi from '../../hooks/useApi';
import { getSystemAccountEvent } from '../../api';
import LoadingSpinner from '../../components/loading';
import AdminContent from '../../components/adminContent';
import FloatingContainer from '../../components/floatingContainer'
import { MdArrowForwardIos } from "react-icons/md";
import {
  formatCurrencyString,
  getDiscountMessage
} from '../../helpers/contractHelper';

const EventDetailsPage = () => {

  const [isLoading, setLoading] = useState(true);
  const [account, setAccount] = useState({});
  const [event, setEvent] = useState({});

  const navigate = useNavigate();
  const params = useParams();
  const [sendRequest] = useApi()

  const accountId = params.account_id;
  const eventId = params.event_id;

  useEffect(() => {
    window.scrollTo(0, 0);
    if (accountId) {
      refreshPage()
    }
  }, []);

  const refreshPage = async () => {
    try {
      const eventResults = await sendRequest(getSystemAccountEvent(accountId, eventId));
      setEvent(eventResults)
    } finally {
      setLoading(false)
    }
  }

  const renderHeader = () => {
    return (
      <div className="p-20">
        <div>
          <span className="c-blue fw-700 cursor-default" onClick={() => navigate(`/system/accounts`)}>
            Accounts
          </span>
          <span className="fs-10 mh-5"><MdArrowForwardIos/></span>
          <span className="cursor-default c-text-gray">
            { accountId }
          </span>
          <span className="fs-10 mh-5"><MdArrowForwardIos/></span>
          <span className="c-blue fw-700 cursor-default" onClick={() => navigate(`/system/accounts/${accountId}`)}>
            Events
          </span>
          <span className="fs-10 mh-5"><MdArrowForwardIos/></span>
          <span className="cursor-default c-text-gray">
            { eventId }
          </span>
        </div>
        <div className="fw-700 fs-24 mt-5">{ account.company_name }</div>
      </div>
    )
  }

  const renderField = (label, value) => {
    return <div><b>{label}:</b> {value}</div>;
  }

  const renderEventDetails = () => {
    return (
      <>
        <div className="fw-700 fs-20 ml-10 mb-10">Event Details</div>
        <div className="shadow-card mb-20 p-20">
          { renderField("Event ID", event.event_id)}
          { renderField("Event Name", event.event_name)}
          { renderField("Event Type", event.account_event_type_name)}
          { renderField("Event Date", moment(event.event_date, "YYYY-MM-DD").format("MMMM DD, YYYY"))}
          { renderField("Start Time", moment(event.start_time, "H:mm").format("h:mm A") )}
          { renderField("End Time", moment(event.end_time, "H:mm").format("h:mm A") )}
          { renderField("Lockdown Days", event.lockdown_days)}
          { renderField("Internal Notes", event.internal_notes)}
        </div>
      </>
    )
  }

  const renderVenue = () => {
    const venue = event.venue ? event.venue : {}
    return (
      <>
        <div className="fw-700 fs-20 ml-10 mb-10">Venue</div>
        <div className="shadow-card mb-20 p-20">
          { renderField("Venue ID", venue.venue_id)}
          { renderField("Venue Name", venue.venue_name)}
          { renderField("Address", venue.address_line_1 + " " + venue.address_line_2)}
          { renderField("City / State", venue.city + ", " + venue.state)}
          { renderField("Zip", venue.zip)}
          { renderField("Contact Name", venue.contact_name)}
          { renderField("Contact Email", venue.contact_email)}
          { renderField("Contact Phone", venue.contact_phone)}
          { renderField("Notes", venue.notes)}
        </div>
      </>
    )
  }

  const renderEmployees = () => {
    const employees = event.employees ? event.employees : []
    return (
      <>
        <div className="fw-700 fs-20 ml-10 mb-10">Assigned Staff</div>
        { employees.map((employee, i) => (
          <div className="shadow-card mb-20 p-20" key={i}>
            { renderField("User ID", employee.user_id)}
            { renderField("Name", employee.first_name + " " + employee.last_name)}
            { renderField("Email", employee.email)}
            { renderField("Last Invitation Date", moment(employee.last_invitation_datetime).format("MMMM DD, YYYY @ h:mma"))}
            { renderField("Last Login Date", moment(employee.last_login_datetime).format("MMMM DD, YYYY @ h:mma"))}
            { renderField("Login with app?", employee.login_with_app ? "Yes" : "No")}
          </div>
        ))}
      </>
    )
  }

  const renderClients = () => {
    const clients = event.clients ? event.clients : []
    return (
      <>
        <div className="fw-700 fs-20 ml-10 mb-10">Clients</div>
        { clients.map((client, i) => (
          <div className="shadow-card mb-20 p-20" key={i}>
            { renderField("User ID", client.user_id)}
            { renderField("Name", client.first_name + " " + client.last_name)}
            { renderField("Email", client.email)}
            { renderField("Last Invitation Date", moment(client.last_invitation_datetime).format("MMMM DD, YYYY @ h:mma"))}
            { renderField("Last Login Date", moment(client.last_login_datetime).format("MMMM DD, YYYY @ h:mma"))}
            { renderField("Login with app?", client.login_with_app ? "Yes" : "No")}
          </div>
        ))}
      </>
    )
  }
        
  const renderPlaylistSong = (song, index) => {
    return (
      <div key={index} className="b-border p-5">
        <div className="">{ song.title } - { song.artist }</div>
      </div>
    )
  }

  const renderPlaylist = (playlist, index) => {
    const songs = playlist.songs ? playlist.songs : []
    return (
      <div key={index} className="shadow-card mb-20 p-20">
        <div className="fw-700 fs-16 b-border pb-5">{ playlist.playlist_name}</div>
        { songs.map((x,i) => renderPlaylistSong(x,i))}
      </div>
    )
  }

  const renderPlaylists = () => {
    const playlists = event.playlists ? event.playlists : []
    return (
      <>
        <div className="fw-700 fs-20 ml-10 mb-10">Playlists</div>
        <div className="">
         { playlists.map((playlist, i) => renderPlaylist(playlist, i))}
        </div>
      </>
    )
  }

  const renderQuestion = (question, index) => {
    if (question.type == "checkbox") {
      var answerArray = question.answer.split("::")
      answerArray = answerArray.filter(x => x != "");
      return (
        <div className="pt-10" key={index}>
          <div className="fw-700">{question.label}</div>
          { answerArray.map((x,i) => (
            <div key={i} className="fw-500">{x}</div>
          ))}
          { answerArray.length == 0 && (
            <div className="fw-500">--</div>
          )}
        </div>
      )
    } else {
      var answer = ""
      if (!question.answer) {
        answer = "--"
      } else {
        answer = question.answer
      }  
      return (
        <div className="pt-10" key={index}>
          <div className="fw-700">{question.label}</div>
          <div className="fw-500">{answer}</div>
        </div>
      )
    }
  }

  const renderQuestionnaireSection = (section, index, sections) => {
    const paddingClass = index < sections.length - 1 ? "pb-30" : ""
    return (
      <div className={paddingClass} key={index}>
        <div className="fs-16 fw-700 pb-5 bg-gray p-5">{ section.name }</div>
        { section.questions.map((x,i) => renderQuestion(x,i))}
      </div>
    )
  }

  const renderQuestionnaire = (questionnaire, index) => {
    const sections = questionnaire.sections ? questionnaire.sections: []
    return (
      <div key={index}>
        <div className="fw-700 fs-16 mb-30">{ questionnaire.questionnaire_name}</div>
        { sections.map((x,i) => renderQuestionnaireSection(x,i,sections))}
      </div>
    )
  }

  const renderQuestionnaires = () => {
    const questionnaires = event.questionnaires ? event.questionnaires : []
    return (
      <>
        <div className="fw-700 fs-20 ml-10 mb-10">Questionnaires</div>
        <div className="shadow-card mb-20 p-20">
          { questionnaires.map((x,i) => renderQuestionnaire(x,i))}
        </div>
      </>
    )
  }

  const renderTimelines = () => {
    return (
      <>
        <div className="fw-700 fs-20 ml-10 mb-10">Timelines</div>
        <div className="shadow-card mb-20 p-20">

        </div>
      </>
    )
  }

  const renderOldSpecialEvent = (specialEvent, index) => {
    const songs = !isNull(specialEvent.songs) ? specialEvent.songs : [];
    return (
      <div key={index} className="border radius-8 p-15 mb-5">
        <div className="fw-700">{ specialEvent.special_event_name}</div>
        <div className="fw-500">Song Limit: { specialEvent.song_limit}</div>
        <div className="fw-500 mb-5">Songs Selected: { songs.length }</div>
        <div className="fw-700 mt-10">Songs:</div>
        { songs.map((song, index) => (
          <div key={index}>{song.title} - {song.artist}</div>
        ))}
      </div>
    )
  }

  const renderOldSpecialEvents = () => {
    const specialEvents = event.old_special_events ? event.old_special_events : []
    return (
      <>
        <div className="fw-700 fs-20 ml-10 mb-10">Old Special Events</div>
        <div className="shadow-card mb-20 p-20">
          { specialEvents.map((x,i) => renderOldSpecialEvent(x,i))}
        </div>
      </>
    )
  }

  const renderSpecialEventRecord = (specialEvent, index) => {
    const songs = !isNull(specialEvent.songs) ? specialEvent.songs : [];
    return (
      <div key={index} className="border radius-8 p-15 mb-5">
        <div className="fw-700">{ specialEvent.special_event_name}</div>
        <div className="fw-500">Song Limit: { specialEvent.song_limit}</div>
        <div className="fw-500 mb-5">Songs Selected: { songs.length }</div>
        { specialEvent.active ? (
          <Tag color="green">SELECTED</Tag>
        ) : (
          <Tag color="red">NOT SELECTED</Tag>
        )}
        <div className="fw-700 mt-10">Songs:</div>
        { songs.map((song, index) => (
          <div key={index}>{song.title} - {song.artist}</div>
        ))}
      </div>
    )
  }

  const renderSpecialEventSection = (section, index) => {
    const specialEventRecords = !isNull(section.special_events) ? section.special_events : [];
    return (
      <div key={index} className="mb-20">
       <div className="fw-700 fs-18 mb-10 ml-10">{ section.section_name }</div>
       { specialEventRecords.map((x,i) => renderSpecialEventRecord(x,i)) }
      </div>
    )
  }

  const renderSpecialEvents = () => {
    const sections = event.special_event_sections ? event.special_event_sections : []
    return (
      <>
        <div className="fw-700 fs-20 ml-10 mb-10">Special Events</div>
        <div className="shadow-card mb-20 p-20">
          { sections.map((x,i) => renderSpecialEventSection(x,i))}
        </div>
      </>
    )
  }

  const renderPackage = (eventPackage, index) => {
    return (
      <Row align="middle" className="mh-20 pv-15 b-border ph-10" key={index}>
        <Col flex={1}>
          <div className="fs-14 fw-700">{eventPackage.package_name}</div>
          { eventPackage.discount && (
            <div className="c-blue fs-12">{getDiscountMessage(eventPackage)}</div>
          )}
        </Col>
        <Col>
          { eventPackage.discount ? (
            <>
              <div className="fs-14 fw-500 text-right c-text-gray strikethrough">{ eventPackage.price }</div>
              <div className="fs-14 fw-500 text-right">{ eventPackage.total }</div>
            </>
          ) : (
            <div className="fs-14 fw-500 text-right">{ eventPackage.price }</div>
          )}
        </Col>
      </Row> 
    )
  }

  const renderAddOn = (addOn, index) => {
    return (
      <Row align="middle" className="mh-20 pv-15 b-border ph-10" key={index}>
        <Col flex={1}>
          <div className="fs-14 fw-700">{ addOn.add_on_name }</div>
          <div className="">
            <span className="c-text-gray">{addOn.quantity} x {addOn.price}</span>
            { addOn.discount && (
              <div className="c-blue fs-12">{getDiscountMessage(addOn)}</div>
            )}
          </div>
        </Col>
        <Col>
        { addOn.discount ? (
          <>
            <div className="c-text-gray strikethrough">{ addOn.sub_total }</div>
            <div className="">{ addOn.total }</div>
          </>
        ) : (
          <div className="fs-14 fw-500">{ addOn.total }</div>
        )}
        </Col>
      </Row>
    )
  }


  const renderFinancials = () => {
    const summary = event.financial_summary ? event.financial_summary : []
    return (
      <>
        <div className="fw-700 fs-20 ml-10 mb-10">Financials</div>
        <div className="shadow-card mb-20 p-20">
          { isArray(summary.packages) && summary.packages.map((eventPackage,index) => renderPackage(eventPackage,index))}
          { isArray(summary.add_ons) && summary.add_ons.map((addOn,index) => renderAddOn(addOn, index))}
          <Row align="middle" className="mh-20 pt-15 ph-10">
            <Col xs={16}>
              <div className="fs-14 fw-700 text-right">Subtotal:</div>
            </Col>
            <Col xs={8}>
              <div className="fs-14 fw-500 text-right">{ summary.subtotal }</div>
            </Col>
          </Row>
          <Row align="middle" className="mh-20 pt-15 ph-10">
            <Col xs={16}>
              <div className="fs-14 fw-700 text-right">Discount (depreciated):</div>
            </Col>
            <Col xs={8}>
              <div className="fs-14 fw-500 text-right">{ summary.discount }</div>
            </Col>
          </Row>
          <Row align="middle" className="mh-20 pv-15 b-border ph-10">
            <Col xs={16}>
              <div className="fs-14 fw-700 text-right">Tax:</div>
            </Col>
            <Col xs={8}>
              <div className="fs-14 fw-500 text-right">{ summary.tax }</div>
            </Col>
          </Row>
          <Row align="middle" className="mh-20 pt-15 ph-10">
            <Col xs={16}>
              <div className="fs-14 fw-700 text-right">Total:</div>
            </Col>
            <Col xs={8}>
              <div className="fs-14 fw-700 text-right">{ summary.total_price }</div>
            </Col>
          </Row>
          <Row align="middle" className="mh-20 pv-15 b-border ph-10">
            <Col xs={16}>
              <div className="fs-14 fw-700 text-right">Payments:</div>
            </Col>
            <Col xs={8}>
              <div className="fs-14 fw-500 text-right">({summary.total_payments })</div>
            </Col>
          </Row>
          <Row align="middle" className="mh-20 pv-15 ph-10">
            <Col xs={16}>
              <div className="fs-14 fw-700 text-right">Balance:</div>
            </Col>
            <Col xs={8}>
              <div className="fs-14 fw-700 text-right">{ summary.balance }</div>
            </Col>
          </Row>
        </div>
        <div className="fw-700 fs-20 ml-10 mb-10">Other Financials</div>
        <div className="shadow-card mb-20 p-20">
          <Row align="middle" className="mh-20 pv-15 ph-10">
            <Col xs={12}>
              <div className="fs-14 fw-700">Deposit</div>
              <div className="fs-14 fw-500">{ formatCurrencyString(summary.deposit) }</div>
            </Col>
          </Row> 
        </div>
      </>
    )
  }

  const renderContent = () => {
    return (
      <FloatingContainer className="ph-20" verticalPadding={20} maxWidth={1000}>
        { renderEventDetails() }
        { renderVenue() }
        { renderEmployees() }
        { renderClients() }
        { renderPlaylists() }
        { renderQuestionnaires() }
        { renderTimelines() }
        { renderOldSpecialEvents() }
        { renderSpecialEvents() }
        { renderFinancials() }
      </FloatingContainer>
    )
  }

  if (isLoading) {
    return <LoadingSpinner/>
  }

  return (
    <AdminContent header={renderHeader()} body={renderContent()}/>
  );
}

export default EventDetailsPage;

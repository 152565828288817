import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Row, Col, Grid } from 'antd';
import { isNull } from "lodash";
import FloatingContainer from '../../../../components/floatingContainer'
import { FiEdit } from "react-icons/fi";
import { BsArrowRight } from "react-icons/bs";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";

const EventPlanningTabEventTemplate = (props) => {

  const { specialEventSections = [], event, onSelectPlanningTab = () => {} } = props;

  const navigate = useNavigate();
  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();

  useEffect(() => {
  }, [])

  const getSpecialEventType = (event) => {
    if (event.is_playlist) {
      return "PLAYLIST";
    } else if (event.songs_allowed) {
      return "SPECIAL SONG"
    } else {
      return "NO SONGS"
    }
  }

  const getSpecialEventSubText = (event) => {
    return `Song Limit: ${event.song_limit == 0 ? "N/A" : event.song_limit}`
  }

  const renderSpecialEvent = (specialEvent, index, records) => {
    const borderClass = (index + 1) < records.length ? "b-border" : ""
    return (
      <div className={`${borderClass}`} key={index}>
        <Row align="middle" gutter={[15]} className="ph-10 pv-15">
          <Col flex={1}>
            <div className="fw-600 fs-16">{ specialEvent.special_event_name }</div>
            <div className="c-text-gray fs-12 mt-2"> <span className="bg-light-blue ph-5 c-blue fw-700 fs-10 mr-5" style={{ paddingTop: 2, paddingBottom: 2}}>{getSpecialEventType(specialEvent)}</span>{getSpecialEventSubText(specialEvent)}</div>
          </Col>
        </Row>
      </div>
    )
  };

  const renderSection = (section, index) => {
    const specialEventRecords = !isNull(section.special_events) ? section.special_events : [];
    return (
      <div key={index} className={`shadow-card mb-20`}>
        <div className="mh-20 pb-10">
          <Row align="middle" className="pv-15 b-border">
            <Col flex={1}>
              <div className="fs-18 fw-700">{ section.section_name }</div>
              { section.description && (
                <div className="c-text-gray mt-5 line-breaks">{ section.description }</div>
              )}
            </Col>
          </Row>
          { specialEventRecords.map((x,i) => renderSpecialEvent(x,i,specialEventRecords))}
        </div>
      </div>
    )
  }

  const renderPlanningSecondTab = () => {
    return (
      <>
        <div className="toolbar-container floating">
          <div className="flex-row flex-middle">
            <div className="blue-link" onClick={() => onSelectPlanningTab(null)}>Planning</div>
            <div className="mh-5 display-flex"><MdOutlineKeyboardArrowRight/></div>
            <div className="fw-600">{ screens.sm ? "Special Event Template" : "Template"}</div>
          </div>
          <div className="flex-1"></div>
          <div className="toolbar-button" onClick={() => navigate(`/admin/events/${event.event_id}/event-template`)}>
            <FiEdit style={{ fontSize: 20, color: '#536DFE', marginRight: 8}}/> Edit Template
          </div>
        </div>
        <FloatingContainer className="ph-20 mb-20" verticalPadding={20} maxWidth={600}>
          { specialEventSections.length > 0 && (
            <div className="shadow-card mb-20 pv-15">
              <div className="bg-gray radius-8 p-15 mh-20">
                The template assigned to this event represents the list of all possible special events the client has to choose from. They will be presented with this list and select the ones that apply to their event.
                <div className="mt-10">
                  <div className="blue-link flex-row flex-1 flex-middle" onClick={() => navigate("/admin/docs/special-event-templates")}>Learn more <BsArrowRight style={{ marginLeft: 5 }}/></div>
                </div>
              </div>
            </div>
          )}
          <div>
            { specialEventSections.map((x, i) => renderSection(x, i))}
            { specialEventSections.length == 0 && (
              <div className="shadow-card mb-20 pv-50 text-center">
                <div className="ph-20 pb-30 text-center c-text-gray">No sections or special events exist for this event.</div>
                <button className="page-title-button" onClick={() => navigate(`/admin/events/${event.event_id}/event-template`)}>Edit Template</button>
              </div>
            )}
          </div>
        </FloatingContainer>
      </>
    )
  }

  return renderPlanningSecondTab();
}

export default EventPlanningTabEventTemplate;

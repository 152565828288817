import React, { useEffect, useState, useContext } from "react";
import { renderMatches, useLocation, useNavigate, useParams } from "react-router-dom";
import qs from "qs";
import moment from 'moment';
import { cloneDeep, isNull, toLower } from 'lodash';
import AppContext from '../../../app/context';
import useApi from '../../../hooks/useApi';
import { Row, Col, Dropdown, Menu, Input, Select, Grid, Collapse, Checkbox } from 'antd';
import { EditOutlined, DeleteOutlined, SearchOutlined} from '@ant-design/icons';
import { MdOutlineMoreHoriz, MdOutlineFilterList, MdArrowForwardIos } from "react-icons/md";
import { getEvent, getEventPlaylistsPrep, getEventSpecialEvents } from '../../../api';
import LoadingSpinner from '../../../components/loading';
import AdminContent from '../../../components/adminContent';
import EventCard from '../../../components/eventCard';
import Permissions from '../../../constants/permissions';
import { hasPermission } from '../../../helpers/permissionHelper';
import emptyStateImage from '../../../images/empty-state-icon.png';
import emptySearchImage from '../../../images/empty-search-icon.png';
import FadeWhileInView from '../../../components/fadeWhileInView'

const { Option } = Select;

const EventPrepPage = (props) => {

  const { auth, setAuth } = useContext(AppContext);
  const user = auth.user ? auth.user : {}

  const navigate = useNavigate();
  const location = useLocation()
  const params = useParams();
  const [sendRequest] = useApi()

  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();

  const [isLoading, setLoading] = useState(true);
  const [events, setEvents] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState("upcoming");
  const [searchText, setSearchText] = useState("");
  const [event, setEvent] = useState({});
  const [selectedTab, setSelectedTab] = useState("music");
  const [playlists, setPlaylists] = useState([]);
  const [specialEvents, setSpecialEvents] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
    const queryStrings = qs.parse(location.search, { ignoreQueryPrefix: true })
    if (queryStrings.tab) {
      setSelectedTab(queryStrings.tab)
    }
    refreshPage()
  }, []);

  const refreshPage = async () => {
    try {
      const eventResults = await sendRequest(getEvent(params.id));
      setEvent(eventResults)

      const eventPlaylistsResults = await sendRequest(getEventPlaylistsPrep(params.id));
      setPlaylists(eventPlaylistsResults);

      const eventSpecialEventsResults = await sendRequest(getEventSpecialEvents(params.id));
      const events = eventSpecialEventsResults.filter(x => x.allow_song_selection)
      setSpecialEvents(events)

      setLoading(false)
    } catch(error) {
      console.log(error)
      setLoading(false)
    }
  }

  // const getFilteredEvents = () => {
  //   const today = moment().format("YYYY-MM-DD")
  //   const allEvents = cloneDeep(events)
  //   var filteredEvents = [];
  //   if (selectedFilter == "upcoming") {
  //     filteredEvents = allEvents.filter(x => x.event_date > today)
  //   } else if (selectedFilter == "past") {
  //     filteredEvents = allEvents.filter(x => x.event_date < today)
  //   } else {
  //     filteredEvents = allEvents;
  //   }

  //   if (searchText != "") {
  //     filteredEvents = filteredEvents.filter(x => {
  //       const lowerEventName = toLower(x.event_name)
  //       return lowerEventName.includes(toLower(searchText))
  //     })
  //   }

  //   return filteredEvents;
  // }

  const onFilterChange = (filter) => {
    setSelectedFilter(filter)
    updateUrl(filter, searchText)
  }

  // const onSearchTextChange = (text) => {
  //   setSearchText(text)
  //   updateUrl(selectedFilter, text)
  // }

  const updateUrl = (filter, search) => {
    if (filter != "" && search == "") {
      navigate(`/admin/events?filter=${filter}`, { replace: true })
    } else if (filter == "" && search != "") {
      navigate(`/admin/events?q=${search}`, { replace: true })
    } else if (filter != "" && search != "") {
      navigate(`/admin/events?filter=${filter}&q=${search}`, { replace: true })
    } else {
      navigate(`/admin/events`, { replace: true })
    }
  }

  const onSelectTab = (tab) => {
    navigate(`/admin/events/${event.event_id}/prep?tab=${tab}`, { replace: true })
    setSelectedTab(tab)
  }

  const menu = (event) => {
    return (
      <Menu>
        <Menu.Item>
          <div onClick={() => navigate(`/admin/events/${event.event_id}`)}>
            <EditOutlined style={{ marginRight: 8}}/> Edit
          </div>
        </Menu.Item>
        {/* <Menu.Item>
          <div onClick={() => {}}>
            <DeleteOutlined style={{ marginRight: 8}}/> Delete
          </div>
        </Menu.Item> */}
      </Menu>
    )
  };

  const filterMenu = () => {
    return (
      <Menu>
        <Menu.Item>
          <div onClick={() => onFilterChange("upcoming")}>
            Upcoming Events
          </div>
        </Menu.Item>
        <Menu.Item>
          <div onClick={() => onFilterChange("past")}>
            Past Events
          </div>
        </Menu.Item>
        <Menu.Item>
          <div onClick={() => onFilterChange("all")}>
            All Events
          </div>
        </Menu.Item>
      </Menu>
    )
  };

  const renderRightIcon = (event) => {
    return (
      <div className="display-flex mr-5">
        <Dropdown overlay={menu(event)} placement="bottomRight" trigger="click">
          <div className="dots-container">
            <MdOutlineMoreHoriz style={{ fontSize: 24, color: '#999'}}/>
          </div>
        </Dropdown>
      </div>
    )
  }

  // const renderEventCard = (event, index) => {
  //   return <EventCard event={event} rightIcon={renderRightIcon(event)} className={"b-border"} onClick={() => navigate(`/admin/events/${event.event_id}`)} key={index}/>;
  // }

  // const renderHeader = () => {
  //   return (
  //     <Row align="middle" className="p-20">
  //       <Col flex={1}>
  //         <div className="fs-24 fw-700">Prep Event</div>
  //       </Col>
  //     </Row>
  //   )
  // }

  const renderHeader = () => {
    return (
      <div className="p-20">
        <div>
          <span className="c-blue fw-700 cursor-default" onClick={() => navigate("/admin/events")}>
            Events
          </span>
          <span className="fs-10 mh-5"><MdArrowForwardIos/></span>
          <span className="c-blue fw-700 cursor-default" onClick={() => navigate(-1)}>
            Event Details
          </span>
          <span className="fs-10 mh-5"><MdArrowForwardIos/></span>
          <span className="cursor-default c-text-gray">
            Event Prep
          </span>
        </div>
        <div className="fw-700 fs-24 mt-5">{event.event_name}</div>
        <div className="fs-16 c-text-gray fw-500">{ moment(event.event_date, "YYYY-MM-DD").format("dddd, MMMM D, YYYY") }</div>
        <div className="tab-slider mt-10">
          <div className={`tab-slider--tab ${selectedTab == "music" ? "selected" : ""}`} onClick={() => onSelectTab("music")}>
            Music
          </div>
          <div className={`tab-slider--tab ${selectedTab == "questionnaires" ? "selected" : ""}`} onClick={() => onSelectTab("questionnaires")}>
            Questionnaires
          </div>
          <div className={`tab-slider--tab ${selectedTab == "timelines" ? "selected" : ""}`} onClick={() => onSelectTab("timelines")}>
            Timelines
          </div>
        </div>
      </div>
    )
  }

  // const renderEventsResults = () => {
  //   if (getFilteredEvents().length > 0) {
  //     return getFilteredEvents().map((event,i) => renderEventCard(event,i));
  //   } else {
  //     return (
  //       <FadeWhileInView duration={0.5} className="ph-20 pv-40 text-center">
  //         <div>
  //           <img src={emptySearchImage} width={200}/>
  //         </div>
  //         <div className="fs-18 fw-700 mt-30">
  //           No events found
  //         </div>
  //         <div className="fs-14 fw-500 c-text-gray">
  //           Adjust your filter or search again!
  //         </div>
  //       </FadeWhileInView>
  //     )
  //   }
  // }

  // const renderEventsBox = () => {
  //   return (
  //     <div className="shadow-card">
  //       <div className="p-15 b-border display-flex flex-row flex-middle">
  //         {/* <Row gutter={[10,10]}>
  //           <Col xs={24} sm={12} md={16}>
  //             <Input className="list-search-input" size="large" placeholder="Search events" value={searchText} onChange={(e) => onSearchTextChange(e.target.value)} allowClear={true} prefix={<SearchOutlined color="#e4e4e4" style={{ marginRight: 6 }}/>} />
  //           </Col>
  //           <Col xs={24} sm={12} md={8}>
  //             <Select className="list-select-filter" size="large" defaultValue="upcoming" value={selectedFilter} style={{ width: "100%" }} onChange={(value) => onFilterChange(value)}>
  //               <Option value="upcoming">Upcoming Events</Option>
  //               <Option value="past">Past Events</Option>
  //               <Option value="all">All Events</Option>
  //             </Select>
  //           </Col>
  //         </Row> */}
  //         <div className="flex-1">
  //           <Input className="list-search-input" size="large" placeholder="Search events" value={searchText} onChange={(e) => onSearchTextChange(e.target.value)} allowClear={true} prefix={<SearchOutlined color="#e4e4e4" style={{ marginRight: 6 }}/>} />
  //         </div>
  //         <div className="flex-0 ml-10">
  //           { screens.sm && (
  //             <Select className="list-select-filter" size="large" defaultValue="upcoming" value={selectedFilter} style={{ width: 200 }} onChange={(value) => onFilterChange(value)}>
  //               <Option value="upcoming">Upcoming Events</Option>
  //               <Option value="past">Past Events</Option>
  //               <Option value="all">All Events</Option>
  //             </Select>
  //           )}
  //           { !screens.sm && (
  //             <Dropdown overlay={filterMenu()} placement="bottomRight" trigger="click">
  //               <div className="dots-container">
  //                 <MdOutlineFilterList style={{ fontSize: 24, color: '#999'}}/>
  //               </div>
  //             </Dropdown>
  //           )}
  //         </div>
  //       </div>
  //       { renderEventsResults() }
  //     </div>
  //   )
  // }

  // const renderEvents = () => {
  //   if (events.length == 0) {
  //     return (
  //       <div className="shadow-card ph-20 pv-50 text-center">
  //         <div>
  //           <img src={emptyStateImage} width={300}/>
  //         </div>
  //         <div className="fs-18 fw-700 mt-30">
  //           No events yet
  //         </div>
  //         <div className="fs-14 fw-500 c-text-gray">
  //           Create an event to get started!
  //         </div>
  //         <button className="primary-button mt-30" style={{ width: 200 }} onClick={() => navigate("/admin/events/new")}>Add Event</button>
  //       </div>
  //     )
  //   } else {
  //     return renderEventsBox()
  //   }
  // }

  const renderPanelHeader = (song) => {
    return (
      <div className="">
        <div className="fs-14 fw-700">{song.title} <span className="fs-14 fw-500">| {song.artist}</span></div>
      </div>
    )
  }


  const renderMatch = (match, index) => {
    return (
      <div key={index} className="flex-row b-border pv-15">
        <div className="flex-0">
          <Checkbox checked={false} onClick={(e) => {}}></Checkbox>
        </div>
        <div className="flex-1 pl-15">
          <div className="fs-14 fw-700">{match.title} <span className="fs-14 fw-500">| {match.artist}</span></div>
          <div className="fs-14 fw-500 c-text-gray">{match.path}</div>
        </div>
      </div>
    )
  }

  const renderMatches = (song) => {
    if (song.results && song.results.length > 0) {
      return song.results.map((x,i) => renderMatch(x,i))
    } else {
      return (
        <div className="fs-12 fw-500 c-text-gray text-center p-15">No matches found in your database</div>
      )
    }
  }

  const renderPlaylistSong = (song, index) => {
    return (
      <Collapse.Panel key={index} header={renderPanelHeader(song)}>
        {/* <div className="fs-12 fw-600 ph-20 mt-15">{"Matches in your database:"}</div> */}
        <div className="ph-15 bg-gray m-15 radius-8">
          { renderMatches(song) }
          {/* <div className="fs-12 fw-500 c-text-gray text-center p-15">No matches found in your database</div> */}
          {/* <div className="flex-row b-border pv-15">
            <div className="flex-0">
              <Checkbox checked={true} onClick={(e) => {}}></Checkbox>
            </div>
            <div className="flex-1 pl-15">
              <div className="fs-14 fw-700">{song.title} <span className="fs-14 fw-500">| {song.artist}</span></div>
              <div className="fs-14 fw-500 c-text-gray">{"C:/something/song.mp3"}</div>
            </div>
          </div>
          <div className="flex-row pv-15">
            <div className="flex-0">
              <Checkbox checked={false} onClick={(e) => {}}></Checkbox>
            </div>
            <div className="flex-1 pl-15">
              <div className="fs-14 fw-700">{song.title} <span className="fs-14 fw-500">| {song.artist}</span></div>
              <div className="fs-14 fw-500 c-text-gray">{"C:/something/song.mp3"}</div>
            </div>
          </div> */}
        </div>
      </Collapse.Panel>
    )
  }

  const renderPlaylistSongs = (playlist) => {
    if (!isNull(playlist.songs) && playlist.songs.length > 0) {
      const activeKeys = playlist.songs.map((x,i) => String(i))
      return (
        <div className="shadow-card collapse-music-prep mb-20">
          <Collapse ghost defaultActiveKey={activeKeys}>
            { playlist.songs.map((x,i) => renderPlaylistSong(x,i))}
          </Collapse>
        </div>
      )
    } else {
      return (
        <div className="shadow-card mb-20">
          <div className="fs-14 fw-500 c-text-gray text-center ph-15 pv-30">No songs added</div>
        </div>
      )
    }
  }

  const renderPlaylist = (playlist, index) => {
    return (
      <div key={index}>
        <div className="fs-20 fw-700 mb-10">{playlist.playlist_name}</div>
        { renderPlaylistSongs(playlist) }
        {/* { !isNull(playlist.songs) && playlist.songs.length > 0 && (
          <div className="shadow-card collapse-music-prep mb-20">
            <Collapse ghost>
              { playlist.songs && playlist.songs.map((x,i) => renderPlaylistSong(x,i))}
            </Collapse>
          </div>
        )} */}
      </div>
    )
  }

  const renderMusicTab = () => {
    return (
      <div>
        { playlists.map((x,i) => renderPlaylist(x,i))}
      </div>
    )
  }

  const renderQuestionnairesTab = () => {

  }

  const renderTimelinesTab = () => {

  }

  const renderTabs = () => {
    if (selectedTab == "music") {
      return renderMusicTab()
    } else if (selectedTab == "questionnaires") {
      return renderQuestionnairesTab()
    } else if (selectedTab == "timelines") {
      return renderTimelinesTab()
    }
  }

  const renderContent = () => {
    if (isLoading) {
      return <LoadingSpinner/>;
    }
    return (
      <div className="p-15" style={{ maxWidth: 800 }}>
        { renderTabs() }
      </div>
    )
  }

  return (
    <AdminContent body={renderContent()} header={renderHeader()}/>
  );
}

export default EventPrepPage;

import React, { useEffect } from "react";
import { Row, Col, Modal, Form, Button } from 'antd';
import { MdOutlineClose } from "react-icons/md";
import { 
  renderInputField,
  renderTextAreaField
} from '../components/formFields'

const CustomSongModal = props => {

  const { isVisible, setVisible = () => {}, saveCustomSong = () => {} } = props;

  const [customSongForm] = Form.useForm();

  useEffect(() => {
    if (isVisible) {
      customSongForm.resetFields()
    }
  }, [isVisible]);

  return (
    <Modal visible={isVisible} footer={null} onCancel={() => setVisible(false)} width={400} closable={false} maskClosable={false} wrapClassName="rounded-modal">
      <Row align="middle">
        <Col flex={1}>
          <div className="fw-700 fs-18">Add Custom Song</div>
        </Col>
        <Col>
          <div className="display-flex" onClick={() => setVisible(false)}><MdOutlineClose size={30} color={"#CCC"}/></div>
        </Col>
      </Row>
      <Form form={customSongForm} layout="vertical" name="customSong" onFinish={saveCustomSong}>
        <Row gutter={[10,10]} className="mt-10">
          <Col xs={24}>
            {renderInputField("Title", "title", true)}
          </Col>
          <Col xs={24}>
            {renderInputField("Artist", "artist", true)}
          </Col>
          <Col xs={24}>
            {renderTextAreaField("Notes (optional)", "notes", 3, false)}
          </Col>
        </Row>
        <div className="text-right mt-15">
          <Button className="admin-small-button secondary mr-10" onClick={() => setVisible(false)}>Cancel</Button>
          <Button className="admin-small-button" htmlType="submit">Save</Button>
        </div>
      </Form>
    </Modal>
  );
};

export default CustomSongModal;

import React from 'react';
import { LoadingOutlined } from '@ant-design/icons';

const PrimaryButton = props => {
  const { title = "", className = "", isProcessing = false, type = "button", onClick = () => {} } = props;

  return (
    <>
     { isProcessing ? (
        <button disabled={true} className={`primary-button ${className}`} style={{ borderRadius: 0 }}><LoadingOutlined/></button>
      ) : (
        <button className={`primary-button ${className}`} style={{ borderRadius: 0 }} type={type} onClick={onClick}>{title}</button>
      )}
    </>
  )
};

export default PrimaryButton;

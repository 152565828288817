export default {
  EVENT_TYPE: {
    key: "EVENT_TYPE",
    value: 'Event Type'
  },
  ARRIVAL_TIME: {
    key: "ARRIVAL_TIME",
    value: 'Arrival Time'
  },
  START_TIME: {
    key: "START_TIME",
    value: 'Start Time'
  },
  END_TIME: {
    key: "END_TIME",
    value: 'End Time'
  },
  INTERNAL_NOTES: {
    key: "INTERNAL_NOTES",
    value: 'Internal Notes'
  },
  VENUE_DETAILS: {
    key: "VENUE",
    value: 'Venue Details'
  },
  CLIENT_NAMES: {
    key: "CLIENT_NAMES",
    value: 'Client Names'
  },
  CLIENT_CONTACT_INFO: {
    key: "CLIENT_CONTACT_INFO",
    value: 'Client Contact Information'
  },
  ASSIGNED_STAFF_NAMES: {
    key: "ASSIGNED_STAFF_NAMES",
    value: 'Assigned Staff Names'
  },
  ASSIGNED_STAFF_CONTACT_INFO: {
    key: "ASSIGNED_STAFF_CONTACT_INFO",
    value: 'Assigned Staff Contact Information'
  },
  PACKAGES: {
    key: "PACKAGES",
    value: 'Packages'
  },
  ADD_ONS: {
    key: "ADD_ONS",
    value: 'Add-Ons'
  },
  PRICES: {
    key: "PRICES",
    value: 'Package & Add-On Prices'
  }
}
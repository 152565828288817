import React, { useEffect, useState } from "react";
import { Row, Col } from 'antd';
import { useNavigate, Navigate } from 'react-router-dom';
import useApi from '../../hooks/useApi';
import LoadingSpinner from '../../components/loading';
import PlanCard from '../../components/planCard';
import FloatingContainer from '../../components/floatingContainer'
import FadeDownWhileInView from '../../components/fadeDownWhileInView'
import { createStripeSubscription, getSelectPlanPromoCodes, getAccountDetails } from '../../api';
import { LoadingOutlined } from '@ant-design/icons';

const SelectPlanPage = () => {

  const [isLoading, setLoading] = useState(true);
  const [isSubmitting, setSubmitting] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState("");
  const [promoCodes, setPromoCodes] = useState([]);
  const [accountDetails, setAccountDetails] = useState({});

  const navigate = useNavigate();
  const [sendRequest] = useApi()

  useEffect(() => {
    window.scrollTo(0, 0);
    refreshPage();
  }, []);

  const refreshPage = async () => {
    try {
      const accountDetailsResults = await sendRequest(getAccountDetails())
      setAccountDetails(accountDetailsResults)

      const promoCodeResults = await sendRequest(getSelectPlanPromoCodes())
      setPromoCodes(promoCodeResults)
    } finally {
      setLoading(false)
    }
  }

  const onSelectPlan = async (plan) => {
    const promoCodeRecord = promoCodes.filter(x => x.plan_id == plan)
    const promoCodeId = promoCodeRecord.length > 0 ? promoCodeRecord[0].promo_code_id : null
    try {
      setSubmitting(true)
      setSelectedPlan(plan)
      const {clientSecret} = await sendRequest(createStripeSubscription({ plan: plan, promo_code_id: promoCodeId }));
      setSubmitting(false)
      navigate("/admin/subscribe", { state: { clientSecret: clientSecret } })
    } catch {
      setSubmitting(false)
      setSelectedPlan("")
    }
  }

  const renderSelectButton = (plan) => {
    if (isSubmitting && selectedPlan == plan) {
      return (
        <button className="primary-button"><LoadingOutlined/></button>
      )
    } else {
      return (
        <button className="primary-button" onClick={() => onSelectPlan(plan)}>Select</button>
      )
    }
  }

  const renderSaleHeader = () => {
    const promoCodeRecord = promoCodes.filter(x => x.plan_id == "BASIC")
    if (promoCodeRecord.length > 0) {
      return (
        <FadeDownWhileInView delay={0.2} className="shadow-card p-15 mb-20">
          <div className="bg-gray radius-8 p-15">
            <div className="fs-14 fw-700 line-1-2 text-center c-blue mb-10">Limited time only!</div>
            <div className="fs-18 fw-700 line-1-2 text-center mb-10">{promoCodeRecord[0].description}</div>
            <div className="fs-12 fw-500 line-1-2 text-center c-text-gray">Promo code <b>{promoCodeRecord[0].promo_code}</b> automatically applied at checkout</div>
          </div>
        </FadeDownWhileInView>
      )
    }
    return null;
  }

  const renderPage = () => {
    var standardPrice = "$35"
    var premiumPrice = "$60"
    var standardPlanId = "STANDARD"
    var premiumPlanId = "PREMIUM_V2"
    // Account ID 1087 is the last sign up with the old rate, so we want to honor it
    // if (accountDetails.account_id <= 1087) {
    //   standardPrice = "$19.99"
    //   premiumPrice = "$39.99"
    //   standardPlanId = "BASIC"
    //   premiumPlanId = "PREMIUM"
    // }

    var standardDiscountPrice = ""
    var premiumDiscountPrice = ""
    const standardPromoRecord = promoCodes.filter(x => x.plan_id == standardPlanId)
    const premiumPromoRecord = promoCodes.filter(x => x.plan_id == premiumPlanId)
    if (standardPromoRecord.length > 0) {
      standardDiscountPrice = standardPromoRecord[0].discount_price.replace(".00", "")
    }
    if (premiumPromoRecord.length > 0) {
      premiumDiscountPrice = premiumPromoRecord[0].discount_price.replace(".00", "")
    }
    return (
      <FloatingContainer className="ph-20" verticalPadding={40} maxWidth={800}>
        <FadeDownWhileInView className="text-center c-text-gray">STEP 1 OF 2</FadeDownWhileInView>
        <FadeDownWhileInView className="fs-24 fw-700 text-center mb-30">Select your plan</FadeDownWhileInView>
        { renderSaleHeader() }
        <Row gutter={[20,20]}>
          <Col xs={24} md={12}>
            <FadeDownWhileInView delay={0.4}>
              <PlanCard plan="STANDARD" price={standardPrice} discountPrice={standardDiscountPrice} button={renderSelectButton(standardPlanId)}/>
            </FadeDownWhileInView>
          </Col>
          <Col xs={24} md={12}>
            <FadeDownWhileInView delay={0.6}>
              <PlanCard plan="PREMIUM" price={premiumPrice} discountPrice={premiumDiscountPrice} button={renderSelectButton(premiumPlanId)}/>
            </FadeDownWhileInView>
          </Col>
        </Row>
      </FloatingContainer>
    )
  }

  if (isLoading) {
    return <LoadingSpinner/>;
  }

  if (accountDetails.status == "ACTIVE") {
    return <Navigate to="/admin/dashboard"/>; 
  }

  return renderPage();
}

export default SelectPlanPage;

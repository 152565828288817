import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';

const RouteChangeTracker = () => {

    let location = useLocation();

    useEffect(() => {
        if (process.env.NODE_ENV !== "development") {
            ReactGA.send({ hitType: "pageview", page: location.pathname, title: location.pathname });
        }
      }, [location.pathname]);

    return <div></div>;
};

export default RouteChangeTracker;
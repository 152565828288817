import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from 'react-router-dom';
import qs from "qs";
import moment from "moment";
import { isArray } from 'lodash';
import { Row, Col, Select, Collapse, Grid } from 'antd';
import AdminContent from '../../../components/adminContent';
import FloatingContainer from '../../../components/floatingContainer'
import { MdArrowForwardIos } from "react-icons/md";
import LoadingSpinner from '../../../components/loading';
import useApi from '../../../hooks/useApi';
import { getEventReport } from '../../../api';
import useDocumentTitle from '../../../hooks/useDocumentTitle';
import useAccountSettings from '../../../hooks/useAccountSettings';
import { formatDateShort, formatEventDateShort } from '../../../helpers/dateHelper';

const EventsByVenueReportPage = () => {

  const [isLoading, setLoading] = useState(true);
  const [dateFilter, setDateFilter] = useState("all");
  const [reportData, setReportData] = useState({});
  const [tableData, setTableData] = useState([]);

  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();

  useDocumentTitle("Events by Venue | Report")
  const navigate = useNavigate();
  const location = useLocation();
  const [sendRequest] = useApi()
  const [accountSettings] = useAccountSettings()

  useEffect(() => {
    window.scrollTo(0, 0);
    const queryStrings = qs.parse(location.search, { ignoreQueryPrefix: true })
    if (queryStrings.filter) {
      setDateFilter(queryStrings.filter)
      loadPage(queryStrings.filter)
    } else {
      onChangeDateRange("all")
    }
  }, []);

  const loadPage = async (filter) => {
    try {
      setLoading(true)
      const reportResults = await sendRequest(getEventReport("venue", filter))
      setReportData(reportResults)
      setTableData(reportResults.data)
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  const onChangeDateRange = (value) => {
    setDateFilter(value)
    navigate(`/admin/reports/events-by-venue?filter=${value}`, { replace: true })
    loadPage(value)
  }

  const renderDateRange = () => {
    if (reportData.start_date == "2020-01-01") {
      return formatDateShort(moment().format(), accountSettings)
    }
    const startDate = reportData.start_date ? formatEventDateShort(reportData.start_date, accountSettings): "N/A"
    const endDate = reportData.end_date ? formatEventDateShort(reportData.end_date, accountSettings) : "N/A"
    return `${startDate} - ${endDate}`
  }

  const renderHeader = () => {
    return (
      <Row align="middle" className="p-20">
        <Col flex={1}>
          <div>
            <span className="c-blue fw-700 cursor-default" onClick={() => navigate("/admin/reports")}>
              Reports
            </span>
            <span className="fs-10 mh-5"><MdArrowForwardIos/></span>
            <span className="cursor-default c-text-gray">
              Events by Venue
            </span>
          </div>
        </Col>
      </Row>
    )
  }

  const renderCollapseHeader = (x) => {
    return (
      <Row className="">
        <Col flex={1}>
          <div className="fw-600">{x.label}</div>
        </Col>
        <Col flex={0}>
          <div className="text-right fw-600">{x.event_count}</div>
        </Col>
      </Row>
    )
  }

  const renderEventTableHeader = () => {
    if (screens.sm) {
      return (
        <Row className="b-border pv-5 ph-20 bg-gray c-text-gray">
          <Col xs={6}>
            <div className="fs-10 fw-700">EVENT DATE</div>
          </Col>
          <Col xs={6}>
            <div className="fs-10 fw-700">EVENT TYPE</div>
          </Col>
          <Col xs={10}>
            <div className="fs-10 fw-700">EVENT NAME</div>
          </Col>
          <Col xs={2}>
          </Col>
        </Row>
      )
    } else {
      return (
        <Row className="b-border pv-5 ph-20 bg-gray c-text-gray">
          <Col xs={24}>
            <div className="fs-10 fw-700">EVENTS</div>
          </Col>
        </Row>
      )
    }
  }

  const renderEvent = (event, index, recordEvents) => {
    const borderClass = index < recordEvents.length - 1 ? "b-border" : ""
    if (screens.sm) {
      return (
        <Row className={`${borderClass} ph-20 pv-10 fs-12`} key={index}>
          <Col xs={6}>
            <div>{formatEventDateShort(event.event_date, accountSettings)}</div>
          </Col>
          <Col xs={6}>
            <div className="fs-12">{event.account_event_type_name}</div>
          </Col>
          <Col xs={10}>
            <div className="fs-12">{event.event_name}</div>
          </Col>
          <Col xs={2} className="text-right">
            <div className="blue-link fs-12" onClick={() => navigate(`/admin/events/${event.event_id}`)}>View</div>
          </Col>
        </Row>
      )
    } else {
      return (
        <Row className={`${borderClass} ph-20 pv-10 fs-12`} key={index}>
          <Col xs={20}>
            <div>{formatEventDateShort(event.event_date, accountSettings)}</div>
            <div>{event.event_name}</div>
            <div>{event.account_event_type_name}</div>
          </Col>
          <Col xs={4} className="text-right">
            <div className="blue-link fs-12" onClick={() => navigate(`/admin/events/${event.event_id}`)}>View</div>
          </Col>
        </Row>
      )
    }
  }

  const renderEventTable = (x) => {
    if (isArray(x.events) && x.events.length > 0) {
      return (
        <div className="border radius-8" style={{ marginTop: -12 }}>
          { renderEventTableHeader() }
          { isArray(x.events) && x.events.map((event,i) => renderEvent(event,i,x.events))}
        </div>
      )
    } else {
      return (
        <div className="border radius-8 text-center p-20 c-text-gray" style={{ marginTop: -12 }}>
          There are no events for this venue
        </div>
      )
    }
  }

  const renderReport = () => {
    if (isLoading) {
      return <LoadingSpinner/>
    }

    return (
      <FloatingContainer className="ph-20 bg-white" verticalPadding={30} maxWidth={1000}>
        <div className="fw-700 fs-24 c-blue">Events by Venue</div>
        <div className="fs-14 c-text-gray mb-30">{renderDateRange()}</div>
        <div className="b-border pb-30 mb-30">This report shows a summary of events for each venue.</div>
        <Row className="bg-gray pl-20 b-border pv-15">
          <Col xs={16}>
            <div className="fs-12 fw-700">VENUE</div>
          </Col>
          <Col xs={8}>
            <div className="fs-12 text-right fw-700 pr-20">EVENT COUNT</div>
          </Col>
        </Row>
        { tableData.map((x,i) => (
          <Collapse ghost>
            <Collapse.Panel header={renderCollapseHeader(x)} key="1" style={{ borderBottom: '1px solid #e8e8e8'}}>
              { renderEventTable(x) }
            </Collapse.Panel>
          </Collapse>
        ))}
      </FloatingContainer>
    )
  }

  const renderContent = () => {
    return (
      <>
        <FloatingContainer className="ph-20 b-border bg-gray" verticalPadding={30} maxWidth={1000}>
          <div className="fw-700 mb-5">Event Date Range</div>
          <div>
            <Select
              style={{ width: 200 }}
              placeholder={"Select"}
              value={dateFilter}
              onChange={(value) => onChangeDateRange(value)}
            >
              <Select.Option value="all">All</Select.Option>
              <Select.Option value="this-month">This Month</Select.Option>
              <Select.Option value="this-month-to-date">This Month-to-Date</Select.Option>
              <Select.Option value="last-month">Last Month</Select.Option>
              <Select.Option value="this-year">This Year</Select.Option>
              <Select.Option value="this-year-to-date">This Year-to-Date</Select.Option>
              <Select.Option value="last-year">Last Year</Select.Option>
              <Select.Option value="last-year-to-date">Last Year-to-Date</Select.Option>
            </Select>
          </div>
        </FloatingContainer>
        { renderReport() }
      </>
    )
  }

  return (
    <AdminContent body={renderContent()} header={renderHeader()} whiteBackground={true}/>
  );
}

export default EventsByVenueReportPage;

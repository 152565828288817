import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { Row, Col, Form, Modal, Dropdown, Menu } from 'antd';
import { getContractTemplates, createContractTemplate, deleteContractTemplate } from '../../../api';
import LoadingSpinner from '../../../components/loading';
import AdminContent from '../../../components/adminContent';
import useApi from '../../../hooks/useApi';
import { EditOutlined, ExclamationCircleOutlined, DeleteOutlined } from '@ant-design/icons';
import { 
  renderInputField
} from '../../../components/formFields'
import useDocumentTitle from '../../../hooks/useDocumentTitle';
import FloatingContainer from '../../../components/floatingContainer'
import { MdOutlineClose, MdOutlineMoreHoriz } from "react-icons/md";
import { FiFileText } from "react-icons/fi";
import { BsArrowRight } from "react-icons/bs";
import emptyStateImage from '../../../images/empty-state-icon.png';

const ContractTemplatesPage = () => {

  const [isLoading, setLoading] = useState(true);
  const [templates, setTemplates] = useState([]);
  const [isNewModalVisible, setNewModalVisible] = useState(false);
  const [isConfirmRemoveVisible, setConfirmRemoveVisible] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState({});

  useDocumentTitle("Contract Templates")
  const [sendRequest] = useApi()
  const navigate = useNavigate();

  const [form] = Form.useForm();

  useEffect(() => {
    window.scrollTo(0, 0);
    refreshPage()
  }, []);

  const refreshPage = async () => {
    try {
      const templateResults = await sendRequest(getContractTemplates());
      setTemplates(templateResults);
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  const menu = (item) => {
    return (
      <Menu>
        <Menu.Item>
          <div onClick={() => handleEdit(item)}>
            <EditOutlined style={{ marginRight: 8}}/> Edit
          </div>
        </Menu.Item>
        {/* <Menu.Item>
          <div onClick={() => handleDuplicate(item)}>
            <CopyOutlined style={{ marginRight: 8}}/> Duplicate
          </div>
        </Menu.Item> */}
        <Menu.Item>
          <div onClick={() => handleDelete(item)}>
            <DeleteOutlined style={{ marginRight: 8}}/> Delete
          </div>
        </Menu.Item>
      </Menu>
    )
  };
  
  const onNewClick = () => {
    form.setFieldsValue({ contract_template_name: "" })
    setNewModalVisible(true);
  }

  const handleCancel = () => {
    setNewModalVisible(false);
  };

  const handleEdit = (template) => {
    navigate(`/admin/contract-templates/${template.contract_template_id}/edit`)
  };

  const handleDelete = (template) => {
    setSelectedTemplate(template)
    setConfirmRemoveVisible(true)
  };

  const confirmDelete = async () => {
    try {
      await sendRequest(deleteContractTemplate(selectedTemplate.contract_template_id))
      await refreshPage()
    } finally {
      setConfirmRemoveVisible(false)
    }
  }

  const onSubmit = async (values) => {
    try {
      const body = {
        contract_template_name: values["contract_template_name"],
        active: true
      }
      const results = await sendRequest(createContractTemplate(body))
      navigate(`/admin/contract-templates/${results.contract_template_id}/edit`)
    } catch {}
  }
  
  const renderHeader = () => {
    return (
      <Row align="middle" className="p-20">
        <Col flex={1}>
          <div className="fs-24 fw-700">Contract Templates</div>
          <div className="blue-link flex-row flex-1 flex-middle" onClick={() => navigate("/admin/docs/contracts")}>Learn more <BsArrowRight style={{ marginLeft: 5 }}/></div>
        </Col>
        <Col>
          { templates.length > 0 && (
            <button className="page-title-button" onClick={onNewClick}>New Template</button>
          )}
        </Col>
      </Row>
    )
  }

  const renderTemplate = (template, index) => {
    return (
      <div key={index} className="flex-row p-10 b-border">
        <div onClick={() => handleEdit(template)}>
          <div className="card-icon"><FiFileText/></div>
        </div>
        <div className="display-flex flex-middle flex-1 mh-15" onClick={() => handleEdit(template)}>
          <div className="fs-14 fw-700 line-1-2">{template.contract_template_name}</div>
        </div>
        <div className="display-flex flex-middle mr-5">
          <Dropdown overlay={menu(template)} placement="bottomRight" trigger="click">
            <div className="dots-container">
              <MdOutlineMoreHoriz style={{ fontSize: 24, color: '#999'}}/>
            </div>
          </Dropdown>
        </div>
      </div>
    )
  }

  const renderTemplatesBox = () => {
    return (
      <div className="shadow-card">
        { templates.map((x,i) => renderTemplate(x,i)) }
      </div>
    )
  }

  const renderTemplates = () => {
    if (templates.length == 0) {
      return (
        <div className="shadow-card ph-20 pv-50 text-center">
          <div>
            <img src={emptyStateImage} width={250}/>
          </div>
          <div className="fs-18 fw-700 mt-30">
            No contract templates yet
          </div>
          <div className="fs-14 fw-500 c-text-gray">
            Create a template to get started!
          </div>
          <button className="primary-button mt-30" style={{ width: 250 }} onClick={onNewClick}>Create Contract Template</button>
          <div className="mt-10">
            <div className="blue-link" onClick={() => navigate("/admin/docs/contracts")}>Learn More</div>
          </div>
        </div>
      )
    } else {
      return renderTemplatesBox()
    }
  }

  const renderContent = () => {
    if (isLoading) {
      return <LoadingSpinner/>
    }
    return (
      <FloatingContainer className="ph-20" verticalPadding={20} maxWidth={800}>
        { renderTemplates() }
        { renderModal() }
        { renderConfirmRemoveModal() }
      </FloatingContainer>
    )
  }

  const renderModal = () => {
    return (
      <Modal visible={isNewModalVisible} footer={null} closable={false} wrapClassName="rounded-modal">
        <Row align="middle">
          <Col flex={1}>
            <div className="fw-700 fs-18">New Contract Template</div>
          </Col>
          <Col>
            <div className="display-flex" onClick={handleCancel}><MdOutlineClose size={30} color={"#CCC"}/></div>
          </Col>
        </Row>
        <Form form={form} layout="vertical" name="new-questionnaire" onFinish={onSubmit}>
          <Row gutter={[10,10]} className="mt-15">
            <Col xs={24}>
              {renderInputField("Name", "contract_template_name", true)}
            </Col>
          </Row>
          <div className="admin-modal-footer">
            <button className="primary-button" type="submit" onClick={() => {}}>Continue</button>
            <div className="text-center mt-15">
              <div className="blue-link" onClick={handleCancel}>Cancel</div>
            </div>
          </div>
        </Form>
      </Modal>
    )
  }

  const renderConfirmRemoveModal = () => {
    return (
      <Modal visible={isConfirmRemoveVisible} closable={false} footer={null} width={400} wrapClassName="rounded-modal">
        <div className="text-right" onClick={() => setConfirmRemoveVisible(false)}><MdOutlineClose size={30} color={"#CCC"}/></div>
        <div className="mt-10">
          <div className="fw-700 fs-20 text-center">Remove Contract Template</div>
          <div className="fw-500 fs-14 mt-20 mb-20 text-center">Are you sure you would like to remove <span className="fw-700">{selectedTemplate.contract_template_name}</span>? This action cannot be undone.</div>
          <button className="primary-button warning" type="button" onClick={() => confirmDelete()}>Remove Template</button>
          <div className="text-center mt-15">
            <div className="blue-link" onClick={() => setConfirmRemoveVisible(false)}>Cancel</div>
          </div>
        </div>
      </Modal>
    )
  }

  return (
    <AdminContent header={renderHeader()} body={renderContent()}/>
  );
}

export default ContractTemplatesPage;

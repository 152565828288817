import React, { useEffect, useState, useContext } from "react";
import { Row, Col, Table, Select, Modal, Form, Button } from 'antd';
import qs from "qs";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getStripeSetupIntent, getEventTypes, upsertAccountSpecialEvents } from '../../api';
import { isNull } from 'lodash';
import LoadingSpinner from '../../components/loading';
import SetupForm from '../../components/setupForm';
import useApi from '../../hooks/useApi';
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const AddUpdatePaymentMethodPage = (props) => {

  const [isLoading, setLoading] = useState(true);
  const [clientSecret, setClientSecret] = useState(null);
  const [title, setTitle] = useState("");

  const location = useLocation();

  // const { auth, setAuth } = useContext(AppContext);

  const [sendRequest] = useApi()

  useEffect(() => {
    window.scrollTo(0, 0);
    const queryStrings = qs.parse(location.search, { ignoreQueryPrefix: true })
    if (queryStrings.type == "trial-ended") {
      setTitle("Add Payment Method")
    } else {
      setTitle("Edit Payment Method")
    }
    refreshPage()
  }, []);

  const refreshPage = () => {
    sendRequest(getStripeSetupIntent()).then(response => {
      setClientSecret(response.client_secret)
      setLoading(false)
    }).catch((e) => {
      setLoading(false)
    })
  }

  const renderElements = () => {
    if (isNull(clientSecret)) {
      return null
    }

    const options = {
      // passing the client secret obtained in step 2
      clientSecret: clientSecret,
      // Fully customizable with appearance API.
      // appearance: {/*...*/},
    };
  
    return (
      <Elements stripe={stripePromise} options={options}>
        <SetupForm returnPath="/admin/payment-method/complete"/>
      </Elements>
    );
  }

  const renderContent = () => {
    return (
      <div className="center-content-box ph-20 pb-20">
        <div className="text-center fs-24 fw-700 pv-30">{title}</div>
        { renderElements() }
      </div>
    )
  }

  if (isLoading) {
    return <LoadingSpinner/>
  }

  return (
    <div className="center-content-page">
      { renderContent() }
    </div>
  );
}

export default AddUpdatePaymentMethodPage;

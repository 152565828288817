import React, { useEffect, useState } from "react";
import { Table, Row, Col, Select, DatePicker, notification, Modal, Input, Tag } from 'antd';
import { isArray } from 'lodash';
import moment from "moment";
import { useNavigate, useParams } from "react-router-dom";
import useApi from '../../hooks/useApi';
import { getSystemAccount, updateAccountStatus, updateAccountTrialExpiration, deleteAccount, getSystemInvitationLink } from '../../api';
import LoadingSpinner from '../../components/loading';
import AdminContent from '../../components/adminContent';
import AccountStatus from '../../constants/accountStatus';
import FloatingContainer from '../../components/floatingContainer'
import { MdArrowForwardIos, MdOutlineClose } from "react-icons/md";

const AccountPage = () => {

  const [isLoading, setLoading] = useState(true);
  const [account, setAccount] = useState({});
  const [accountStatuses, setAccountStatuses] = useState([]);
  const [selectedAccountStatus, setSelectedAccountStatus] = useState("");
  const [selectedTrialExpiration, setSelectedTrialExpiration] = useState(moment());
  const [isDeleteModalVisible, setDeleteModalVisible] = useState(false);
  const [deleteConfirmationText, setDeleteConfirmationText] = useState("");

  const navigate = useNavigate();
  const params = useParams();
  const [sendRequest] = useApi()

  const accountId = params.id;

  const employeeColumns = [
    {
      title: 'User ID',
      dataIndex: 'user_id',
      key: 'user_id',
      render: (text, record) => text
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (text, record) => record.first_name + " " + record.last_name
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      render: (text, record) => text
    },
    {
      title: 'Role',
      dataIndex: 'admin_user_role_id',
      key: 'admin_user_role_id',
      render: (text, record) => text
    },
    {
      title: 'Status',
      dataIndex: 'active',
      key: 'active',
      render: (text, record) => <Tag color={record.active ? "green" : "red"}>{record.active ? "ACTIVE" : "INACTIVE"}</Tag>
    }
  ];

  const clientColumns = [
    {
      title: 'User ID',
      dataIndex: 'user_id',
      key: 'user_id',
      render: (text, record) => text
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (text, record) => record.first_name + " " + record.last_name
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      render: (text, record) => text
    },
    {
      title: 'Status',
      dataIndex: 'active',
      key: 'active',
      render: (text, record) => <Tag color={record.active ? "green" : "red"}>{record.active ? "ACTIVE" : "INACTIVE"}</Tag>
    },
    {
      title: 'Email',
      dataIndex: 'email_verified',
      key: 'email_verified',
      render: (text, record) => <Tag color={record.email_verified ? "green" : "gold"}>{record.email_verified ? "VERIFIED" : "PENDING"}</Tag>
    },
    {
      title: 'Invitation',
      dataIndex: 'invite',
      key: 'invite',
      render: (text, record) => <div className="blue-link" onClick={() => getInviteLink(record)}>Link</div>
    },
  ];

  const venueColumns = [
    {
      title: 'Venue ID',
      dataIndex: 'venue_id',
      key: 'venue_id',
      render: (text, record) => text
    },
    {
      title: 'Name',
      dataIndex: 'venue_name',
      key: 'venue_name',
      render: (text, record) => text
    },
    {
      title: 'City / State',
      dataIndex: 'address',
      key: 'address',
      render: (text, record) => record.city + ", " + record.state
    }
  ];

  const eventColumns = [
    {
      title: 'Event ID',
      dataIndex: 'event_id',
      key: 'event_id',
      render: (text, record) => text
    },
    {
      title: 'Name',
      dataIndex: 'event_name',
      key: 'event_name',
      render: (text, record) => text
    },
    {
      title: 'Event Date',
      dataIndex: 'event_date',
      key: 'event_date',
      render: (text, record) => moment(text, "YYYY-MM-DD").format("MMM D, YYYY")
    }
  ];

  const questionnaireColumns = [
    {
      title: 'Questionnaire ID',
      dataIndex: 'questionnaire_id',
      key: 'questionnaire_id',
      render: (text, record) => text
    },
    {
      title: 'Name',
      dataIndex: 'questionnaire_name',
      key: 'questionnaire_name',
      render: (text, record) => text
    },
    {
      title: 'Sections',
      dataIndex: 'sections',
      key: 'sections',
      render: (text, record) => isArray(record.sections) ? record.sections.length : 0
    }
  ];

  const workflowColumns = [
    {
      title: 'Workflow ID',
      dataIndex: 'workflow_id',
      key: 'workflow_id',
      render: (text, record) => text
    },
    {
      title: 'Name',
      dataIndex: 'workflow_name',
      key: 'workflow_name',
      render: (text, record) => text
    },
    {
      title: 'Steps',
      dataIndex: 'steps',
      key: 'steps',
      render: (text, record) => isArray(record.steps) ? record.steps.length : 0
    }
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
    if (accountId) {
      refreshPage()
    }
  }, []);

  const refreshPage = async () => {
    try {
      const accountResults = await sendRequest(getSystemAccount(accountId));
      setAccount(accountResults)
      setSelectedAccountStatus(accountResults.status)
      setSelectedTrialExpiration(moment(accountResults.trial_expiration_datetime))

      const statusArray = []
      for (const key in AccountStatus) {
        statusArray.push(key)
      }
      setAccountStatuses(statusArray)
    } finally {
      setLoading(false)
    }
  }

  const saveAccountStatus = async () => {
    try {
      const results = await sendRequest(updateAccountStatus(accountId, { status: selectedAccountStatus }));
      if (results.status == "success") {
        notification.success({
          message: 'Success!',
          description: 'Your changes have been saved.',
          duration: 2
        });
        await refreshPage()
      }
    } catch {}
  }

  const saveAccountTrialExpiration = async () => {
    try {
      const results = await sendRequest(updateAccountTrialExpiration(accountId, { trial_expiration_datetime: selectedTrialExpiration.format() }));
      if (results.status == "success") {
        notification.success({
          message: 'Success!',
          description: 'Your changes have been saved.',
          duration: 2
        });
        await refreshPage()
      }
    } catch {}
  }

  const startDeleteAccount = async () => {
    setDeleteConfirmationText("")
    setDeleteModalVisible(true)
  }

  const confirmDeleteAccount = async () => {
    try {
      if (deleteConfirmationText == "DELETE") {
        const deleteResults = await sendRequest(deleteAccount({ account_id: accountId }));
        if (deleteResults.success) {
          notification.success({
            message: 'Success!',
            description: 'This account has been deleted.',
            duration: 3
          });
          navigate("/system/accounts")
        } else {
          notification.error({
            message: 'Error',
            description: 'There was an error deleting this account. Please check the logs.',
            duration: 3
          });
        }
        setDeleteModalVisible(false)
      } else {
        notification.error({
          message: 'Error',
          description: 'Delete could not be confirmed.',
          duration: 3
        });
      }
    } catch {}
  }

  const getInviteLink = async (user) => {
    try {
      const results = await sendRequest(getSystemInvitationLink(user.user_id));
      if (results.status == "success") {
        Modal.success({
          title: 'Invitation Link',
          content: results.link,
          okText: 'OK',
          onOk: () => {}
        });
      } else if (results.status == "user-already-active") {
        Modal.error({
          title: 'User Already Active',
          content: "This user has already setup their account.",
          okText: 'OK',
          onOk: () => {}
        });
      }
      console.log(results)
    } catch {}
  }

  const renderHeader = () => {
    return (
      <div className="p-20">
        <div>
          <span className="c-blue fw-700 cursor-default" onClick={() => navigate(-1)}>
            Accounts
          </span>
          <span className="fs-10 mh-5"><MdArrowForwardIos/></span>
          <span className="cursor-default c-text-gray">
            { accountId }
          </span>
        </div>
        <div className="fw-700 fs-24 mt-5">{ account.company_name }</div>
      </div>
    )
  }

  const renderDate = (date) => {
    if (date) {
      return moment(date).format("MM/DD/YYYY @ hh:mma") 
    } else {
      return "--"
    }
  }

  const getDataWithKeys = (data) => {
    if (!data) {
      return []
    }
    return data.map((x, i) => {
      return {
        ...x,
        key: i
      }
    });
  }

  const renderEvents = () => {
    return (
      <>
      <div className="fw-700 fs-20 ml-10 mb-10">Events</div>
      <div className="shadow-card mb-20">
        <Table 
          columns={eventColumns} 
          dataSource={getDataWithKeys(account.events)}
          pagination={false}
          onRow={(record, rowIndex) => {
            return {
              onClick: e => navigate(`/system/accounts/${accountId}/events/${record.event_id}`)
            };
          }}
          />
      </div>
      </>
    )
  }

  const renderEmployees = () => {
    return (
      <>
      <div className="fw-700 fs-20 ml-10 mb-10">Staff</div>
      <div className="shadow-card mb-20">
        <Table 
          columns={employeeColumns} 
          dataSource={getDataWithKeys(account.employees)}
          pagination={false}
          onRow={(record, rowIndex) => {
            return {
              onClick: e => {}
            };
          }}
          />
      </div>
      </>
    )
  }

  const renderClients = () => {
    return (
      <>
      <div className="fw-700 fs-20 ml-10 mb-10">Clients</div>
      <div className="shadow-card mb-20">
        <Table 
          columns={clientColumns} 
          dataSource={getDataWithKeys(account.clients)}
          pagination={false}
          onRow={(record, rowIndex) => {
            return {
              onClick: e => {}
            };
          }}
          />
      </div>
      </>
    )
  }

  const renderVenues = () => {
    return (
      <>
      <div className="fw-700 fs-20 ml-10 mb-10">Venues</div>
      <div className="shadow-card mb-20">
        <Table 
          columns={venueColumns} 
          dataSource={getDataWithKeys(account.venues)}
          pagination={false}
          onRow={(record, rowIndex) => {
            return {
              onClick: e => {}
            };
          }}
          />
      </div>
      </>
    )
  }

  const renderQuestionnaires = () => {
    return (
      <>
      <div className="fw-700 fs-20 ml-10 mb-10">Questionnaires</div>
      <div className="shadow-card mb-20">
        <Table 
          columns={questionnaireColumns} 
          dataSource={getDataWithKeys(account.questionnaires)}
          pagination={false}
          onRow={(record, rowIndex) => {
            return {
              onClick: e => {}
            };
          }}
          />
      </div>
      </>
    )
  }

  const renderWorkflows = () => {
    return (
      <>
      <div className="fw-700 fs-20 ml-10 mb-10">Workflows</div>
      <div className="shadow-card mb-20">
        <Table 
          columns={workflowColumns} 
          dataSource={getDataWithKeys(account.workflows)}
          pagination={false}
          onRow={(record, rowIndex) => {
            return {
              onClick: e => {}
            };
          }}
          />
      </div>
      </>
    )
  }

  const renderDetails = () => {
    return (
      <div className="mt-10">
        <div className="fw-700 fs-20 ml-10 mb-10">Account Info</div>
        <div className="shadow-card">
          <Row align="middle" className="p-20" gutter={[20,20]}>
            <Col xs={12}>
              <div className="fw-700">Account ID</div>
              <div className="">{ account.account_id }</div>
            </Col>
            <Col xs={12}>
              <div className="fw-700">Company Name</div>
              <div className="">{ account.company_name }</div>
            </Col>
            <Col xs={12}>
              <div className="fw-700">Status</div>
              <div className="">{ account.status }</div>
            </Col>
            <Col xs={12}>
              <div className="fw-700">Active</div>
              <div className="">{ account.active ? "Yes" : "No" }</div>
            </Col>
            <Col xs={12}>
              <div className="fw-700">Created Date</div>
              <div className="">{ renderDate(account.create_datetime) }</div>
            </Col>
            <Col xs={12}>
              <div className="fw-700">Trial Expiration Date</div>
              <div className="">{ renderDate(account.trial_expiration_datetime) }</div>
            </Col>
          </Row>
        </div>
        <div className="fw-700 fs-20 ml-10 mt-20 mb-10">Subscription Info</div>
        <div className="shadow-card">
          <Row align="middle" className="p-20" gutter={[20,20]}>
            <Col xs={12}>
              <div className="fw-700">Subscription ID</div>
              <div className="">{ account.subscription_id }</div>
            </Col>
            <Col xs={12}>
              <div className="fw-700">Price ID</div>
              <div className="">{ account.price_id }</div>
            </Col>
            <Col xs={12}>
              <div className="fw-700">Customer ID</div>
              <div className="">{ account.payment_customer_id }</div>
            </Col>
            <Col xs={12}>
              <div className="fw-700">Promo Code</div>
              <div className="">{ account.promo_code }</div>
            </Col>
            <Col xs={12}>
              <div className="fw-700">Promo Expiration Date</div>
              <div className="">{ renderDate(account.promo_expiration_datetime)}</div>
            </Col>
            <Col xs={12}>
              <div className="fw-700">Next Billing Date</div>
              <div className="">{ renderDate(account.next_billing_datetime)}</div>
            </Col>
            <Col xs={12}>
              <div className="fw-700">Cancellation Date</div>
              <div className="">{ renderDate(account.cancellation_datetime)}</div>
            </Col>
            <Col xs={12}>
              <div className="fw-700">Cancelled At Date</div>
              <div className="">{ renderDate(account.cancelled_at_datetime)}</div>
            </Col>
          </Row>
        </div>
        <div className="fw-700 fs-20 ml-10 mt-20 mb-10">Payment Method</div>
        <div className="shadow-card mb-20">
          <Row align="middle" className="p-20" gutter={[20,20]}>
            <Col xs={12}>
              <div className="fw-700">Payment Method ID</div>
              <div className="">{ account.payment_method_id }</div>
            </Col>
            <Col xs={12}>
              <div className="fw-700">Card Type</div>
              <div className="">{ account.card_type }</div>
            </Col>
            <Col xs={12}>
              <div className="fw-700">Last 4</div>
              <div className="">{ account.last_4 }</div>
            </Col>
            <Col xs={12}>
              <div className="fw-700">Expiration Date</div>
              <div className="">{ account.exp_month } / { account.exp_year }</div>
            </Col>
            <Col xs={12}>
              <div className="fw-700">Zip Code</div>
              <div className="">{ account.zip_code }</div>
            </Col>
          </Row>
        </div>
      </div>
    )
  }

  const renderMaintenanceSection = () => {
    return (
      <div className="mt-10">
        <div className="fw-700 fs-20 ml-10 mb-10">Maintenance</div>
        <div className="shadow-card">
          <Row align="middle" className="p-20" gutter={[20,20]}>
            <Col xs={12}>
              <div className="fw-700 mb-5">Account Status</div>
              <Select
                style={{ width: '100%'}}
                allowClear
                placeholder={"Select a status"}
                size='large'
                value={selectedAccountStatus}
                onChange={(value) => setSelectedAccountStatus(value)}
              >
                {accountStatuses.map((status) => (
                  <Select.Option value={status} key={status}>{status}</Select.Option>
                ))}
              </Select>
              <button className="small-primary-button mt-15" onClick={saveAccountStatus}>Save</button>
            </Col>
            <Col xs={12}>
              <div className="fw-700 mb-5">Trial Expiration Date</div>
              <DatePicker size="large" format="MM/DD/YYYY" value={selectedTrialExpiration} onChange={(e) => setSelectedTrialExpiration(e)}/>
              <div>
                <button className="small-primary-button mt-15" onClick={saveAccountTrialExpiration}>Save</button>
              </div>
            </Col>
          </Row>
        </div>
        <div className="shadow-card mt-20 p-20 text-center">
          <div className="blue-link c-red" onClick={startDeleteAccount}>Delete Account</div>
        </div>
      </div>
    )
  }

  const renderConfirmDeleteModal = () => {
    return (
      <Modal visible={isDeleteModalVisible} closable={false} footer={null} width={400} wrapClassName="rounded-modal">
        <div className="text-right" onClick={() => setDeleteModalVisible(false)}><MdOutlineClose size={30} color={"#CCC"}/></div>
        <div className="mt-10">
          <div className="fw-700 fs-20 text-center">Delete Account</div>
          <div className="fw-500 fs-14 mt-20 mb-20 text-center">Are you sure you would like to delete the account <span className="fw-700">{accountId}</span>?</div>
          <div><Input size="large" value={deleteConfirmationText} onChange={(e) => setDeleteConfirmationText(e.target.value)}/></div>
          <button className="primary-button warning" type="button" onClick={() => confirmDeleteAccount()}>Confirm Delete</button>
          <div className="text-center mt-15">
            <div className="blue-link" onClick={() => setDeleteModalVisible(false)}>Cancel</div>
          </div>
        </div>
      </Modal>
    )
  }

  const renderContent = () => {
    return (
      <FloatingContainer className="ph-20" verticalPadding={20} maxWidth={1000}>
        { renderDetails() }
        { renderEvents() }
        { renderEmployees() }
        { renderClients() }
        { renderVenues() }
        { renderQuestionnaires() }
        { renderWorkflows() }
        { renderMaintenanceSection() }
        { renderConfirmDeleteModal() }
      </FloatingContainer>
    )
  }

  if (isLoading) {
    return <LoadingSpinner/>
  }

  return (
    <AdminContent header={renderHeader()} body={renderContent()}/>
  );
}

export default AccountPage;

import React from 'react';
import { Row, Col } from 'antd';
import { Carousel } from 'react-responsive-carousel';
import djWorkflowImage from '../images/dj-workflow.png';
import djNotificationsImage from '../images/dj-notifications.png';
import djTasksImage from '../images/dj-tasks.png';
import djTasksSendEmailImage from '../images/dj-tasks-send-email.png';
import djTasksSendTextImage from '../images/dj-tasks-send-text.png';
import djTasksTextMessageImage from '../images/dj-tasks-text-message.png';
import djHome1Image from '../images/dj-home-1.png';
import djHome2Image from '../images/dj-home-2.png';
import djEventsEventDetails1Image from '../images/dj-events-event-details-1.png';
import djEventsEventDetails2Image from '../images/dj-events-event-details-2.png';
import djEventsEventDetails3Image from '../images/dj-events-event-details-3.png';
import djEventDetailsPlanningImage from '../images/dj-event-details-planning.png';
import djEventDetailsPlanningSpecialEventsImage from '../images/dj-event-details-planning-special-events.png';
import djEventDetailsPlanningFavoriteGenresImage from '../images/dj-event-details-planning-favorite-genres.png';
import djEventDetailsPlanningPlaylistImage from '../images/dj-event-details-planning-playlist.png';
import djEventDetailsPlanningQuestionnaireImage from '../images/dj-event-details-planning-questionnaire.png';
import djEventDetailsPlanningTimelineImage from '../images/dj-event-details-planning-timeline.png';
import djContractsImage from '../images/dj-contracts.png';
import djContractsOptionsImage from '../images/dj-contracts-options.png';
import djServicesImage from '../images/dj-services.png';
import djFinancials1Image from '../images/dj-financials-1.png';
import djFinancials2Image from '../images/dj-financials-2.png';
import FloatingContainer from './floatingContainer'
import FadeDownWhileInView from './fadeDownWhileInView'

const DJFeatures = () => {
  return (
    <>
      <FloatingContainer className="bg-blue-gradient" verticalPadding={60} maxWidth={1000}>
        <FadeDownWhileInView className="fs-30 fw-700 text-center c-white">DJ Features</FadeDownWhileInView>
      </FloatingContainer>

      <FloatingContainer className="bg-white" verticalPadding={60} maxWidth={1000}>
        <Row gutter={[0,30]} align="middle" className="text-center">
          <Col xs={24} md={12}>
            <FadeDownWhileInView>
              <div className="app-carousel">
                <Carousel 
                  showThumbs={false}
                  showArrows={false}
                  showStatus={false}
                >
                  <div className="app-carousel--image">
                    <img src={djHome1Image}/>
                  </div>
                  <div className="app-carousel--image">
                    <img src={djHome2Image}/>
                  </div>
                </Carousel>
              </div>
            </FadeDownWhileInView>
          </Col>
          <Col xs={24} md={12}>
            <FadeDownWhileInView className="p-20">
              <div className="fs-24 fw-700 line-1-2">Manage Events</div>
              <div className="fs-16 fw-500 c-text-gray mt-10 line-1-4">From the home screen, you can view a calendar of all your events to quickly check your availability. You'll also have quick access to your next event and a summary of your events for the year.</div>
            </FadeDownWhileInView>
          </Col>
        </Row>
      </FloatingContainer>

      <FloatingContainer className="bg-gray" verticalPadding={60} maxWidth={1000}>
        <Row gutter={[30,30]} align="middle" className="text-center">
          <Col xs={24} md={12}>
            <FadeDownWhileInView className="p-20">
              <div className="fs-24 fw-700 line-1-2">View Event Details</div>
              <div className="fs-16 fw-500 c-text-gray mt-10 line-1-4">After you send an invitation to your client, you can monitor their status from the event details screen. Check if they have accepted the invite, whether or not they have downloaded the app, and when they last logged in. You can also choose to call, message, or email them with the tap of a button. Other information such as venue, assigned staff, and internal notes can be seen here as well.</div>
            </FadeDownWhileInView>
          </Col>
          <Col xs={24} md={12}>
            <FadeDownWhileInView>
              <div className="app-carousel">
                <Carousel 
                  showThumbs={false}
                  showArrows={false}
                  showStatus={false}
                >
                  <div className="app-carousel--image">
                    <img src={djEventsEventDetails1Image}/>
                  </div>
                  <div className="app-carousel--image">
                    <img src={djEventsEventDetails2Image}/>
                  </div>
                  <div className="app-carousel--image">
                    <img src={djEventsEventDetails3Image}/>
                  </div>
                </Carousel>
              </div>
            </FadeDownWhileInView>
          </Col>
        </Row>
      </FloatingContainer>

      <FloatingContainer className="bg-white" verticalPadding={60} maxWidth={1000}>
        <Row gutter={[30,30]} align="middle" className="text-center">
          <Col xs={24} md={12}>
            <FadeDownWhileInView>
              <div className="app-carousel">
                <Carousel 
                  showThumbs={false}
                  showArrows={false}
                  showStatus={false}
                >
                  <div className="app-carousel--image">
                    <img src={djEventDetailsPlanningImage}/>
                  </div>
                  <div className="app-carousel--image">
                    <img src={djEventDetailsPlanningSpecialEventsImage}/>
                  </div>
                  <div className="app-carousel--image">
                    <img src={djEventDetailsPlanningFavoriteGenresImage}/>
                  </div>
                  <div className="app-carousel--image">
                    <img src={djEventDetailsPlanningPlaylistImage}/>
                  </div>
                  <div className="app-carousel--image">
                    <img src={djEventDetailsPlanningQuestionnaireImage}/>
                  </div>
                  <div className="app-carousel--image">
                    <img src={djEventDetailsPlanningTimelineImage}/>
                  </div>
                </Carousel>
              </div>
            </FadeDownWhileInView>
          </Col>
          <Col xs={24} md={12}>
            <FadeDownWhileInView className="p-20">
              <div className="fs-24 fw-700 line-1-2">Collaborate on Planning</div>
              <div className="fs-16 fw-500 c-text-gray mt-10 line-1-4">Preview the information your clients have submitted by tapping on music, timeline, or questionnaire. You also have the same edit permissions as your client, so you can jump in and make changes at any time. A link can be shared with other vendors so they can have read-only access to the content through a browser.</div>
            </FadeDownWhileInView>
          </Col>
        </Row>
      </FloatingContainer>

      <FloatingContainer className="bg-gray" verticalPadding={60} maxWidth={1000}>
        <Row gutter={[30,30]} align="middle" className="text-center">
          <Col xs={24} md={12}>
            <FadeDownWhileInView className="p-20">
              <div className="fs-24 fw-700 line-1-2">Manage Contracts</div>
              <div className="fs-16 fw-500 c-text-gray mt-10 line-1-4">After you create a contract for your client to sign electronically through the website, you can manage the status of your contracts and share links through the app. You can also manage any packages and/or add-ons that are assigned to the event and view a full financial breakdown.</div>
            </FadeDownWhileInView>
          </Col>
          <Col xs={24} md={12}>
            <FadeDownWhileInView>
              <div className="app-carousel">
                <Carousel 
                  showThumbs={false}
                  showArrows={false}
                  showStatus={false}
                >
                  <div className="app-carousel--image">
                    <img src={djContractsImage}/>
                  </div>
                  <div className="app-carousel--image">
                    <img src={djContractsOptionsImage}/>
                  </div>
                  <div className="app-carousel--image">
                    <img src={djServicesImage}/>
                  </div>
                  <div className="app-carousel--image">
                    <img src={djFinancials1Image}/>
                  </div>
                  <div className="app-carousel--image">
                    <img src={djFinancials2Image}/>
                  </div>
                </Carousel>
              </div>
            </FadeDownWhileInView>
          </Col>
        </Row>
      </FloatingContainer>

      <FloatingContainer className="bg-white" verticalPadding={60} maxWidth={1000}>
        <Row gutter={[0,30]} align="middle" className="text-center">
          <Col xs={24} md={12}>
            <FadeDownWhileInView>
              <img src={djWorkflowImage} className="app-image"/>
            </FadeDownWhileInView>
          </Col>
          <Col xs={24} md={12}>
            <FadeDownWhileInView className="p-20">
              <div className="fs-24 fw-700 line-1-2">Create Workflows</div>
              <div className="fs-16 fw-500 c-text-gray mt-10 line-1-4">Custom workflows can be created to remind you of important tasks leading up to an event. Reminders like "inititate a planning meeting 4 weeks before the wedding" can be set so that you never have to worry about dropping the ball.</div>
            </FadeDownWhileInView>
          </Col>
        </Row>
      </FloatingContainer>

      <FloatingContainer className="bg-gray" verticalPadding={60} maxWidth={1000}>
        <Row gutter={[30,30]} align="middle" className="text-center">
          <Col xs={24} md={12}>
            <FadeDownWhileInView className="p-20">
              <div className="fs-24 fw-700 line-1-2">Complete Tasks</div>
              <div className="fs-16 fw-500 c-text-gray mt-10 line-1-4">The "My Tasks" screen will give you a snapshot of all tasks that are due accross all of your events. This makes it easy to know what you need to do each day to ensure every client is receiving the best service. If the task involves sending a message, tapping on the task will populate an email or text message with the client's information and the custom text you define when setting up the workflow.</div>
            </FadeDownWhileInView>
          </Col>
          <Col xs={24} md={12}>
            <FadeDownWhileInView>
              <div className="app-carousel">
                <Carousel 
                  showThumbs={false}
                  showArrows={false}
                  showStatus={false}
                >
                  <div className="app-carousel--image">
                    <img src={djTasksImage}/>
                  </div>
                  <div className="app-carousel--image">
                    <img src={djTasksSendEmailImage}/>
                  </div>
                  <div className="app-carousel--image">
                    <img src={djTasksSendTextImage}/>
                  </div>
                  <div className="app-carousel--image">
                    <img src={djTasksTextMessageImage}/>
                  </div>
                </Carousel>
              </div>
            </FadeDownWhileInView>
          </Col>
        </Row>
      </FloatingContainer>

      <FloatingContainer className="bg-white" verticalPadding={60} maxWidth={1000}>
        <Row gutter={[30,30]} align="middle" className="text-center">
          <Col xs={24} md={12}>
            <FadeDownWhileInView>
              <img src={djNotificationsImage} className="app-image"/>
            </FadeDownWhileInView>
          </Col>
          <Col xs={24} md={12}>
            <FadeDownWhileInView className="p-20">
              <div className="fs-24 fw-700 line-1-2">Receive Notifications</div>
              <div className="fs-16 fw-500 c-text-gray mt-10 line-1-4">You will receive a push notification any time a client makes an update to their event. You can also view all of your client's activity on the notifications screen. Tapping on a notification will take you directly to the event details so you can quickly see what information your client has added.</div>
            </FadeDownWhileInView>
          </Col>
        </Row>
      </FloatingContainer>
    </>
  );
};

export default DJFeatures;

import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Row, Col, Modal, Menu, Dropdown, Form, Select, notification } from 'antd';
import { DeleteOutlined, EyeOutlined, EditOutlined } from '@ant-design/icons';
import { MdOutlineClose, MdOutlineMoreHoriz, MdOutlineEdit, MdOutlineUndo, MdOutlineAttachMoney } from "react-icons/md";
import { FiPlus, FiSend, FiExternalLink, FiCheck, FiDollarSign, FiLink, FiTrash } from "react-icons/fi";
import { FaSignature, FaRegFilePdf } from "react-icons/fa";
import { isEmpty, isUndefined, cloneDeep, isArray } from "lodash";
import FloatingContainer from '../../../../components/floatingContainer';
import StatusTag from '../../../../components/statusTag';
import useApi from '../../../../hooks/useApi';
import useCurrency from '../../../../hooks/useCurrency';
import useAccountSettings from '../../../../hooks/useAccountSettings';
import AppContext from '../../../../app/context';
import ContractStatus from '../../../../constants/contractStatus';
import InvoiceStatus from '../../../../constants/invoiceStatus';
import ShareModal from '../../../../modals/shareModal';
import { formatCurrencyString } from '../../../../helpers/contractHelper';
import { formatDateShort } from '../../../../helpers/dateHelper';
import { 
  createEventContract,
  getContractTemplates,
  getContractTemplate,
  deleteEventContract,
  revertEventContract,
  createInvoice,
  createEventInvoice,
  deleteEventInvoice,
  deleteInvoice,
  updateEventInvoiceStatus,
  updateInvoiceStatus,
  getInvoiceTemplates
} from '../../../../api';
import { 
  renderInputField,
  renderSearchSelectField,
  renderEmailField,
  renderFormLabel
} from '../../../../components/formFields'
const BASE_URL = process.env.REACT_APP_BASE_URL;

const EventDocumentsTab = (props) => {

  const { event, clients = [], employees = [], eventContracts = [], eventInvoices = [], invoices = [], refreshFinancials = () => {}, canViewFinancials = true, isNewInvoicesEnabled = false, isNewInvoicesDisplayed = false} = props;

  const [contractTemplates, setContractTemplates] = useState([]);
  const [selectedContract, setSelectedContract] = useState({});
  const [selectedInvoice, setSelectedInvoice] = useState({});
  const [isNewContractModalVisible, setNewContractModalVisible] = useState(false);
  const [isConfirmRemoveContractModalVisible, setConfirmRemoveContractModalVisible] = useState(false);
  const [isConfirmRevertContractModalVisible, setConfirmRevertContractModalVisible] = useState(false);
  const [isConfirmRemoveInvoiceModalVisible, setConfirmRemoveInvoiceModalVisible] = useState(false);
  const [isConfirmRemoveInvoiceV2ModalVisible, setConfirmRemoveInvoiceV2ModalVisible] = useState(false);
  const [isConfirmCancelVisible, setConfirmCancelVisible] = useState(false);
  const [isNewInvoiceModalVisible, setNewInvoiceModalVisible] = useState(false);
  const [isNewInvoiceV2ModalVisible, setNewInvoiceV2ModalVisible] = useState(false);
  const [selectedBillTo, setSelectedBillTo] = useState("");
  const [isShareModalVisible, setShareModalVisible] = useState(false);
  const [invoiceError, setInvoiceError] = useState("");
  const [invoiceTemplates, setInvoiceTemplates] = useState([]);

  const { auth, setAuth } = useContext(AppContext);
  const [newContractForm] = Form.useForm();
  const [newInvoiceForm] = Form.useForm();
  const [sendRequest] = useApi()
  const [currency, _] = useCurrency()
  const [accountSettings] = useAccountSettings()
  const navigate = useNavigate();

  const onAddContract = async () => {
    try {
      if (contractTemplates.length == 0) {
        const templates = await sendRequest(getContractTemplates())
        const templateData = templates.map((template) => {
          return {
            value: template.contract_template_id,
            text: template.contract_template_name
          }
        })
        setContractTemplates(templateData)
      }
      const clientsCopy = cloneDeep(clients)
      const userId = (isArray(clientsCopy) && clientsCopy.length > 0) ? clientsCopy[0].user_id : null;
      newContractForm.setFieldsValue({ event_contract_name: `${event.event_name} Contract`, contract_template_id: null, user_id: userId })
      setNewContractModalVisible(true)
    } catch {}
  }

  const onNewContractSubmit = async (values) => {
    try {
      var template = null;
      if (values["contract_template_id"]) {
        const templateResults = await sendRequest(getContractTemplate(values["contract_template_id"]))
        template = templateResults.content;
      }
      const body = {
        event_contract_name: values["event_contract_name"],
        template: template,
        status: ContractStatus.DRAFT,
        client_user_id: !isUndefined(values["user_id"]) ? values["user_id"] : null,
        admin_user_id: auth.user.user_id
      }
      const results = await sendRequest(createEventContract(event.event_id, body))
      navigate(`/admin/events/${event.event_id}/contracts/${results.event_contract_id}/edit`)
    } catch {
      setNewContractModalVisible(false)
    }
  }

  const handleRemoveContract = (contract) => {
    setSelectedContract(contract)
    setConfirmRemoveContractModalVisible(true)
  }

  const handleRevertContract = (contract) => {
    setSelectedContract(contract)
    setConfirmRevertContractModalVisible(true)
  }

  const revertContract = async () => {
    try {
      setConfirmRevertContractModalVisible(false)
      await sendRequest(revertEventContract(event.event_id, selectedContract.event_contract_id));
      await refreshFinancials()
    } catch {}
  }

  const deleteContract = () => {
    setConfirmRemoveContractModalVisible(false)
    sendRequest(deleteEventContract(event.event_id, selectedContract.event_contract_id)).then(() => {
      refreshFinancials()
    })
  }

  const onAddInvoice = () => {
    // Reset all fiels
    newInvoiceForm.resetFields()

    // Automatically populate first client if there is one
    if (clients.length > 0) {
      const userId = clients[0].user_id
      newInvoiceForm.setFieldsValue({ bill_to_user_id: userId })
      setSelectedBillTo(userId)
    } else {
      newInvoiceForm.setFieldsValue({ bill_to_user_id: 1})
      setSelectedBillTo(1)
    }
    setNewInvoiceModalVisible(true)
  }

  const onAddInvoiceV2 = async () => {
    try {
      setInvoiceError("")
      // Reset all fiels
      newInvoiceForm.resetFields()

      // Get invoice templates if note populated yet
      if (invoiceTemplates.length == 0) {
        const results = await sendRequest(getInvoiceTemplates());
        setInvoiceTemplates(results)
      }
  
      // Automatically populate first client if there is one
      if (clients.length > 0) {
        const userId = clients[0].user_id
        newInvoiceForm.setFieldsValue({ bill_to_user_id: userId })
        setSelectedBillTo(userId)
      } else {
        newInvoiceForm.setFieldsValue({ bill_to_user_id: 1})
        setSelectedBillTo(1)
      }
      setNewInvoiceV2ModalVisible(true)
    } catch {
      notification.error({
        message: 'Error!',
        description: 'Something went wrong.',
        duration: 3
      });
    }
  }

  const onNewInvoiceSubmit = async (values) => {
    try {
      const userId = values["bill_to_user_id"]
      const body = {
        ...values,
        bill_to_user_id: userId == 1 ? null : userId
      }
      const results = await sendRequest(createEventInvoice(event.event_id, body))
      navigate(`/admin/events/${event.event_id}/invoices/${results.invoice_id}/edit`)
    } catch {
      setNewInvoiceModalVisible(false)
    }
  }

  const onNewInvoiceSubmitV2 = async (values) => {
    try {
      const userId = values["bill_to_user_id"]
      const body = {
        ...values,
        bill_to_user_id: userId == 1 ? null : userId,
        event_id: event.event_id
      }
      const results = await sendRequest(createInvoice(body))
      if (results.status == "no-services") {
        setInvoiceError("No services have been added to this event. Please add packages and/or add-ons before continuing.")
      } else if (results.status == "no-line-items") {
        setInvoiceError("All packages and add-ons have already been included in previous invoices.")
      } else if (results.invoice_id) {
        navigate(`/admin/invoices/${results.invoice_id}/edit`)
      } else {
        notification.error({
          message: 'Error!',
          description: 'Something went wrong.',
          duration: 3
        });
      }
    } catch {
      setNewInvoiceV2ModalVisible(false)
    }
  }

  const handleRemoveInvoice = (invoice) => {
    setSelectedInvoice(invoice)
    setConfirmRemoveInvoiceModalVisible(true)
  }

  const handleRemoveInvoiceV2 = (invoice) => {
    setSelectedInvoice(invoice)
    setConfirmRemoveInvoiceV2ModalVisible(true)
  }

  const handleCancelInvoice = (invoice) => {
    setSelectedInvoice(invoice)
    setConfirmCancelVisible(true)
  };

  const handleCopyInvoiceLink = (invoice) => {
    setSelectedInvoice(invoice)
    setShareModalVisible(true)
  }

  const deleteInvoiceConfirm = async () => {
    try {
      await sendRequest(deleteEventInvoice(event.event_id, selectedInvoice.invoice_id))
      await refreshFinancials()
      setConfirmRemoveInvoiceModalVisible(false)
      notification.success({
        message: 'Success!',
        description: 'Your invoice has been deleted.',
        duration: 3
      });
    } catch {
      setConfirmRemoveInvoiceModalVisible(false)
    }
  }

  const deleteInvoiceV2 = async () => {
    try {
      await sendRequest(deleteInvoice(selectedInvoice.invoice_id))
      await refreshFinancials()
      setConfirmRemoveInvoiceV2ModalVisible(false)
      notification.success({
        message: 'Success!',
        description: 'Your invoice has been deleted.',
        duration: 3
      });
    } catch {
      setConfirmRemoveInvoiceV2ModalVisible(false)
    }
  }

  const confirmCancelInvoice = async () => {
    try {
      await handleUpdateInvoiceStatus(selectedInvoice.invoice_id, InvoiceStatus.CANCELLED)
    } finally {
      setConfirmCancelVisible(false)
    }
  }

  const onUpdateInvoiceStatus = async (invoice_id, status) => {
    try {
      await sendRequest(updateEventInvoiceStatus(event.event_id, invoice_id, { status: status }))
      await refreshFinancials()
      notification.success({
        message: 'Success!',
        description: 'The invoice status has been updated.',
        duration: 3
      });
    } catch {}
  }

  const onBillToChange = (value) => {
    setSelectedBillTo(value)
    const fields = {
      bill_to_first_name: null,
      bill_to_last_name: null,
      bill_to_email: null
    }
    newInvoiceForm.setFieldsValue(fields)
  }

  const onPrintEventInfo = (url) => {
    window.open(`${url}`, "_blank")
  }

  const onViewInvoicePDF = (invoice) => {
    window.open(`${BASE_URL}/pdfs/invoice/${invoice.invoice_id}`, "_blank")
  }

  const handleEditInvoice = (invoice) => {
    navigate(`/admin/invoices/${invoice.invoice_id}/edit`)
  };

  const handlePreviewInvoice = (invoice) => {
    navigate(`/admin/invoices/${invoice.invoice_id}/preview`)
  };

  const handleSendInvoice = (invoice) => {
    navigate(`/admin/invoices/${invoice.invoice_id}/send`)
  };

  const handleViewInvoice = (invoice) => {
    navigate(`/admin/invoices/${invoice.invoice_id}`)
  };

  const handleUpdateInvoiceStatus = async (id, status) => {
    try {
      await sendRequest(updateInvoiceStatus(id, { status: status }))
      await refreshFinancials()
      notification.success({
        message: 'Success!',
        description: 'The invoice status has been updated successfully.',
        duration: 3
      });
    } catch {
      notification.error({
        message: 'Error!',
        description: 'Something went wrong.',
        duration: 3
      });
    }
  }

  const navigateToInvoicePDF = (item) => {
    window.open(`${BASE_URL}/pdfs/v2/invoice/${item.invoice_id}`, "_blank")
  }

  const copyLink = () => {
    notification.success({
      message: 'Success!',
      description: 'The link has been copied to your clipboard.',
      duration: 3
    });
  }

  const contractMenu = (item, index) => {
    return (
      <Menu>
        { (item.status == ContractStatus.DRAFT || item.status == ContractStatus.AWAITING_SEND || item.status == ContractStatus.AWAITING_SIGNATURE) && (
          <Menu.Item className="ph-15 pv-10">
            <div onClick={() => navigate(`/admin/events/${event.event_id}/contracts/${item.event_contract_id}/edit`)}>
              <MdOutlineEdit style={{ marginRight: 8}}/> Edit Contract
            </div>
          </Menu.Item>
        )}
        { item.status == ContractStatus.AWAITING_SIGNATURE && (
          <Menu.Item className="ph-15 pv-10">
            <div onClick={() => navigate(`/admin/events/${event.event_id}/contracts/${item.event_contract_id}/send`)}>
              <FiSend style={{ marginRight: 8}}/> Re-send Contract
            </div>
          </Menu.Item>
        )}
        { (item.status == ContractStatus.AWAITING_SEND || item.status == ContractStatus.AWAITING_SIGNATURE) && (
          <Menu.Item className="ph-15 pv-10">
            <div onClick={() => handleRevertContract(item)}>
              <MdOutlineUndo style={{ marginRight: 8}}/> Revert to Draft
            </div>
          </Menu.Item>
        )}
        { item.status == ContractStatus.COMPLETED && (
          <Menu.Item className="ph-15 pv-10">
            <div onClick={() => navigate(`/contract/preview/${item.event_contract_id}`)}>
              <EyeOutlined style={{ marginRight: 8}}/> View / Download Contract
            </div>
          </Menu.Item>
        )}
        { item.status == ContractStatus.DRAFT && (
          <Menu.Item className="ph-15 pv-10">
            <div onClick={() => navigate(`/admin/events/${event.event_id}/contracts/${item.event_contract_id}/preview`)}>
              <EyeOutlined style={{ marginRight: 8}}/> Preview / Sign Contract
            </div>
          </Menu.Item>
        )}
        { item.status == ContractStatus.COUNTERSIGN && (
          <Menu.Item className="ph-15 pv-10">
            <div onClick={() => navigate(`/admin/events/${event.event_id}/contracts/${item.event_contract_id}/preview`)}>
              <MdOutlineEdit style={{ marginRight: 8}}/> Sign Contract
            </div>
          </Menu.Item>
        )}
        { item.status == ContractStatus.AWAITING_SEND && (
          <Menu.Item className="ph-15 pv-10">
            <div onClick={() => navigate(`/admin/events/${event.event_id}/contracts/${item.event_contract_id}/send`)}>
              <FiSend style={{ marginRight: 8}}/> Send Contract
            </div>
          </Menu.Item>
        )}
        <Menu.Item className="ph-15 pv-10">
          <div onClick={() => handleRemoveContract(item)}>
            <DeleteOutlined style={{ marginRight: 8}}/> Remove Contract
          </div>
        </Menu.Item>
      </Menu>
    )
  };

  const eventInfoMenu = (url) => {
    return (
      <Menu>
        <Menu.Item className="ph-15 pv-10">
          <div onClick={() => onPrintEventInfo(url)}>
            <FiExternalLink style={{ marginRight: 8}}/> View / Download PDF
          </div>
        </Menu.Item>
      </Menu>
    )
  };

  const menuItem = (text, icon, action = () => {}) => {
    return (
      <Menu.Item className="ph-15 pv-10">
        <div onClick={action}>
          {icon}<span style={{ marginLeft: 12}}>{text}</span>
        </div>
      </Menu.Item>
    )
  }

  const invoiceMenu = (item) => {
    return (
      <Menu>
        { item.status != InvoiceStatus.PAID && (
          <Menu.Item className="ph-15 pv-10">
            <div onClick={() => navigate(`/admin/events/${event.event_id}/invoices/${item.invoice_id}/edit`)}>
              <MdOutlineEdit style={{ marginRight: 8}}/> Edit Invoice
            </div>
          </Menu.Item>
        )}
        { item.status != InvoiceStatus.PAID && (
          <Menu.Item className="ph-15 pv-10">
            <div onClick={() => navigate(`/admin/events/${event.event_id}/invoices/${item.invoice_id}/preview`)}>
              <EyeOutlined style={{ marginRight: 8}}/> Preview Invoice
            </div>
          </Menu.Item>
        )}
        { item.status == InvoiceStatus.DRAFT && (
          <Menu.Item className="ph-15 pv-10">
            <div onClick={() => navigate(`/admin/events/${event.event_id}/invoices/${item.invoice_id}/send`)}>
              <FiSend style={{ marginRight: 8}}/> Send Invoice
            </div>
          </Menu.Item>
        )}
        { item.status == InvoiceStatus.SENT && (
          <Menu.Item className="ph-15 pv-10">
            <div onClick={() => navigate(`/admin/events/${event.event_id}/invoices/${item.invoice_id}/send`)}>
              <FiSend style={{ marginRight: 8}}/> Re-send Invoice
            </div>
          </Menu.Item>
        )}
        { item.status == InvoiceStatus.DRAFT && (
          <Menu.Item className="ph-15 pv-10">
            <div onClick={() => onUpdateInvoiceStatus(item.invoice_id, InvoiceStatus.SENT)}>
              <FiCheck style={{ marginRight: 8}}/> Mark as Sent
            </div>
          </Menu.Item>
        )}
        { item.status != InvoiceStatus.DRAFT && (
          <Menu.Item className="ph-15 pv-10">
            <div onClick={() => onUpdateInvoiceStatus(item.invoice_id, InvoiceStatus.DRAFT)}>
              <MdOutlineUndo style={{ marginRight: 8}}/> Revert to Draft
            </div>
          </Menu.Item>
        )}
        { item.status != InvoiceStatus.PAID && (
          <Menu.Item className="ph-15 pv-10">
            <div onClick={() => onUpdateInvoiceStatus(item.invoice_id, InvoiceStatus.PAID)}>
              <FiDollarSign style={{ marginRight: 8}}/> Mark as Paid
            </div>
          </Menu.Item>
        )}
        { item.status != InvoiceStatus.DRAFT && (
          <Menu.Item className="ph-15 pv-10">
            <div onClick={() => handleCopyInvoiceLink(item)}>
              <FiLink style={{ marginRight: 8}}/> Copy Invoice Link
            </div>
          </Menu.Item>
        )}
        { item.status != InvoiceStatus.DRAFT && (
          <Menu.Item className="ph-15 pv-10">
            <div onClick={() => onViewInvoicePDF(item)}>
              <FiExternalLink style={{ marginRight: 8}}/> View / Download PDF
            </div>
          </Menu.Item>
        )}
        <Menu.Item className="ph-15 pv-10">
          <div onClick={() => handleRemoveInvoice(item)}>
            <DeleteOutlined style={{ marginRight: 8}}/> Remove Invoice
          </div>
        </Menu.Item>
      </Menu>
    )
  };

  const invoiceMenuV2 = (item) => {
    const invoiceLink = `${BASE_URL}/v/invoice/${item.invoice_id}`
    if (item.status == InvoiceStatus.DRAFT) {
      return (
        <Menu>
          { menuItem("Edit Invoice", <EditOutlined/>, () => handleEditInvoice(item))}
          { menuItem("Preview Invoice", <EyeOutlined/>, () => handlePreviewInvoice(item))}
          { menuItem("Send Invoice", <FiSend/>, () => handleSendInvoice(item))}
          { menuItem("Mark as Sent", <FiCheck/>, () => handleUpdateInvoiceStatus(item.invoice_id, InvoiceStatus.SENT))}
          { menuItem("View / Download PDF", <FiExternalLink/>, () => navigateToInvoicePDF(item))}
          { menuItem("Delete Invoice", <FiTrash/>, () => handleRemoveInvoiceV2(item))}
        </Menu>
      )
    } else if (item.status == InvoiceStatus.SENT) {
      return (
        <Menu>
          { menuItem("View Invoice", <EyeOutlined/>, () => handleViewInvoice(item))}
          { menuItem("Edit Invoice", <EditOutlined/>, () => handleEditInvoice(item))}
          { menuItem("Re-Send Invoice", <FiSend/>, () => handleSendInvoice(item))}
          { menuItem("Revert to Draft", <MdOutlineUndo/>, () => handleUpdateInvoiceStatus(item.invoice_id, InvoiceStatus.DRAFT))}
          <CopyToClipboard text={invoiceLink} onCopy={() => copyLink()}>
            { menuItem("Copy Invoice Link", <FiLink/>, () => {})}
          </CopyToClipboard>
          { menuItem("View / Download PDF", <FiExternalLink/>, () => navigateToInvoicePDF(item))}
          { menuItem("Cancel Invoice", <FiTrash/>, () => handleCancelInvoice(item))}
        </Menu>
      )
    } else if (item.status == InvoiceStatus.PARTIALLY_PAID) {
      return (
        <Menu>
          { menuItem("View Invoice", <EyeOutlined/>, () => handleViewInvoice(item))}
          { menuItem("Re-Send Invoice", <FiSend/>, () => handleSendInvoice(item))}
          <CopyToClipboard text={invoiceLink} onCopy={() => copyLink()}>
            { menuItem("Copy Invoice Link", <FiLink/>, () => {})}
          </CopyToClipboard>
          { menuItem("View / Download PDF", <FiExternalLink/>, () => navigateToInvoicePDF(item))}
          { menuItem("Cancel Invoice", <FiTrash/>, () => handleCancelInvoice(item))}
        </Menu>
      )
    } else if (item.status == InvoiceStatus.PAID) {
      return (
        <Menu>
          { menuItem("View Invoice", <EyeOutlined/>, () => handleViewInvoice(item))}
          <CopyToClipboard text={invoiceLink} onCopy={() => copyLink()}>
            { menuItem("Copy Invoice Link", <FiLink/>, () => {})}
          </CopyToClipboard>
          { menuItem("View / Download PDF", <FiExternalLink/>, () => navigateToInvoicePDF(item))}
        </Menu>
      )
    }
    return (
      <Menu>
        { menuItem("View Invoice", <EyeOutlined/>, () => handleViewInvoice(item))}
      </Menu>
    )
  };

  const renderNewContractModal = () => {
    const otherStaffMembers = cloneDeep(employees).filter(x => x.user_id != auth.user.user_id)
    return (
      <Modal visible={isNewContractModalVisible} footer={null} closable={false} wrapClassName="rounded-modal">
        <Row align="middle">
          <Col flex={1}>
            <div className="fw-700 fs-18">New Contract</div>
          </Col>
          <Col>
            <div className="display-flex" onClick={() => setNewContractModalVisible(false)}><MdOutlineClose size={30} color={"#CCC"}/></div>
          </Col>
        </Row>
        <Form form={newContractForm} layout="vertical" name="new-questionnaire" onFinish={onNewContractSubmit}>
          <Row gutter={[10,10]} className="mt-15">
            <Col xs={24}>
              {renderInputField("Contract Name", "event_contract_name", true)}
            </Col>
            <Col xs={24}>
              { renderSearchSelectField("Contract Template (optional)", "contract_template_id", "Select template...", contractTemplates, false) }
            </Col>
            <Col xs={24}>
              { renderFormLabel("Primary Signer") }
              <Form.Item 
                name={"user_id"}
                rules={[{ required: true, message: `Signer is required!` }]}
              >
                <Select
                  style={{ width: '100%'}}
                  allowClear
                  placeholder={"Select a signer..."}
                  size='large'
                  onChange={(value) => {}}
                >
                  <Select.OptGroup label="Clients">
                    {clients.map((client) => (
                      <Select.Option value={client.user_id} key={client.user_id}>{client.first_name} {client.last_name}</Select.Option>
                    ))}
                  </Select.OptGroup>
                  { otherStaffMembers.length > 0 && (
                    <Select.OptGroup label="Staff">
                      {otherStaffMembers.map((employee) => (
                        <Select.Option value={employee.user_id} key={employee.user_id}>{employee.first_name} {employee.last_name}</Select.Option>
                      ))}
                    </Select.OptGroup>
                  )}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <div className="admin-modal-footer">
            <button className="primary-button" type="submit">Continue</button>
            <div className="text-center mt-15">
              <div className="blue-link" onClick={() => setNewContractModalVisible(false)}>Cancel</div>
            </div>
          </div>
        </Form>
      </Modal>
    )
  }

  const renderNewInvoiceModal = () => {
    var clientData = clients.map((client) => {
      return {
        value: client.user_id,
        text: client.first_name + " " + client.last_name
      }
    })
    clientData.push({ value: 1, text: "Other" })
    return (
      <Modal visible={isNewInvoiceModalVisible} footer={null} closable={false} wrapClassName="rounded-modal">
        <Row align="middle">
          <Col flex={1}>
            <div className="fw-700 fs-18">New Invoice</div>
          </Col>
          <Col>
            <div className="display-flex" onClick={() => setNewInvoiceModalVisible(false)}><MdOutlineClose size={30} color={"#CCC"}/></div>
          </Col>
        </Row>
        <Form form={newInvoiceForm} layout="vertical" name="new-invoice" onFinish={onNewInvoiceSubmit}>
          <Row gutter={[10,10]} className="mt-15">
            <Col xs={24}>
              { renderSearchSelectField("Bill To", "bill_to_user_id", "Select client...", clientData, true, onBillToChange) }
            </Col>
            { selectedBillTo == 1 && (
              <>
              <Col xs={24} md={12}>
                {renderInputField("First Name", "bill_to_first_name", true)}
              </Col>
              <Col xs={24} md={12}>
                {renderInputField("Last Name", "bill_to_last_name", true)}
              </Col>
              <Col xs={24}>
                {renderEmailField("Email", "bill_to_email", true)}
              </Col>
              </>
            )}
          </Row>
          <div className="admin-modal-footer">
            <button className="primary-button" type="submit">Continue</button>
            <div className="text-center mt-15">
              <div className="blue-link" onClick={() => setNewInvoiceModalVisible(false)}>Cancel</div>
            </div>
          </div>
        </Form>
      </Modal>
    )
  }

  const renderNewInvoiceModalV2 = () => {
    var clientData = clients.map((client) => {
      return {
        value: client.user_id,
        text: client.first_name + " " + client.last_name
      }
    })
    clientData.push({ value: 1, text: "Other" })
    var templateData = invoiceTemplates.map((template) => {
      return {
        value: template.invoice_template_id,
        text: template.invoice_template_name
      }
    })
    return (
      <Modal visible={isNewInvoiceV2ModalVisible} footer={null} closable={false} wrapClassName="rounded-modal">
        <Row align="middle">
          <Col flex={1}>
            <div className="fw-700 fs-18">New Invoice</div>
          </Col>
          <Col>
            <div className="display-flex" onClick={() => setNewInvoiceV2ModalVisible(false)}><MdOutlineClose size={30} color={"#CCC"}/></div>
          </Col>
        </Row>
        <Form form={newInvoiceForm} layout="vertical" name="new-invoice" onFinish={onNewInvoiceSubmitV2}>
          <Row gutter={[10,10]} className="mt-15">
            <Col xs={24}>
              { renderSearchSelectField("Invoice Template (optional)", "invoice_template_id", "Select a template...", templateData, false) }
            </Col>
            <Col xs={24}>
              { renderSearchSelectField("Bill To", "bill_to_user_id", "Select client...", clientData, true, onBillToChange) }
            </Col>
            { selectedBillTo == 1 && (
              <>
              <Col xs={24} md={12}>
                {renderInputField("First Name", "bill_to_first_name", true)}
              </Col>
              <Col xs={24} md={12}>
                {renderInputField("Last Name", "bill_to_last_name", true)}
              </Col>
              <Col xs={24}>
                {renderEmailField("Email", "bill_to_email", true)}
              </Col>
              </>
            )}
            { invoiceError && (
              <Col xs={24}>
                <div className="c-red text-center">{ invoiceError }</div>
              </Col>
            )}
          </Row>
          <div className="admin-modal-footer">
            <button className="primary-button" type="submit">Continue</button>
            <div className="text-center mt-15">
              <div className="blue-link" onClick={() => setNewInvoiceV2ModalVisible(false)}>Cancel</div>
            </div>
          </div>
        </Form>
      </Modal>
    )
  }

  const renderConfirmRemoveContractModal = () => {
    return (
      <Modal visible={isConfirmRemoveContractModalVisible} closable={false} footer={null} width={400} wrapClassName="rounded-modal">
        <div className="text-right" onClick={() => setConfirmRemoveContractModalVisible(false)}><MdOutlineClose size={30} color={"#CCC"}/></div>
        <div className="mt-10">
          <div className="fw-700 fs-20 text-center">Remove Contract</div>
          <div className="fw-500 fs-14 mt-20 mb-20 text-center">Are you sure you would like to remove <span className="fw-700">{selectedContract.event_contract_name}</span> from this event? This action cannot be undone.</div>
          <button className="primary-button warning" type="button" onClick={() => deleteContract()}>Remove Contract</button>
          <div className="text-center mt-15">
            <div className="blue-link" onClick={() => setConfirmRemoveContractModalVisible(false)}>Cancel</div>
          </div>
        </div>
      </Modal>
    )
  }

  const renderConfirmRemoveInvoiceModal = () => {
    return (
      <Modal visible={isConfirmRemoveInvoiceModalVisible} closable={false} footer={null} width={400} wrapClassName="rounded-modal">
        <div className="text-right" onClick={() => setConfirmRemoveInvoiceModalVisible(false)}><MdOutlineClose size={30} color={"#CCC"}/></div>
        <div className="mt-10">
          <div className="fw-700 fs-20 text-center">Remove Invoice</div>
          <div className="fw-500 fs-14 mt-20 mb-20 text-center">Are you sure you would like to remove invoice #{selectedInvoice.invoice_number} from this event? This action cannot be undone.</div>
          <button className="primary-button warning" type="button" onClick={() => deleteInvoiceConfirm()}>Remove Invoice</button>
          <div className="text-center mt-15">
            <div className="blue-link" onClick={() => setConfirmRemoveInvoiceModalVisible(false)}>Cancel</div>
          </div>
        </div>
      </Modal>
    )
  }

  const renderConfirmRemoveInvoiceV2Modal = () => {
    return (
      <Modal visible={isConfirmRemoveInvoiceV2ModalVisible} closable={false} footer={null} width={400} wrapClassName="rounded-modal">
        <div className="text-right" onClick={() => setConfirmRemoveInvoiceV2ModalVisible(false)}><MdOutlineClose size={30} color={"#CCC"}/></div>
        <div className="mt-10">
          <div className="fw-700 fs-20 text-center">Remove Invoice</div>
          <div className="fw-500 fs-14 mt-20 mb-20 text-center">Are you sure you would like to remove invoice #{selectedInvoice.invoice_number} from this event? This action cannot be undone.</div>
          <button className="primary-button warning" type="button" onClick={() => deleteInvoiceV2()}>Remove Invoice</button>
          <div className="text-center mt-15">
            <div className="blue-link" onClick={() => setConfirmRemoveInvoiceV2ModalVisible(false)}>Cancel</div>
          </div>
        </div>
      </Modal>
    )
  }

  const renderConfirmCancelModal = () => {
    return (
      <Modal visible={isConfirmCancelVisible} closable={false} footer={null} width={400} wrapClassName="rounded-modal">
        <div className="text-right" onClick={() => setConfirmCancelVisible(false)}><MdOutlineClose size={30} color={"#CCC"}/></div>
        <div className="mt-10">
          <div className="fw-700 fs-20 text-center">Cancel Invoice</div>
          <div className="fw-500 fs-14 mt-20 mb-20 text-center">Are you sure you would like to cancel <span className="fw-700">Invoice #{selectedInvoice.invoice_number}</span>? Clients will no longer be able to make payments towards this invoice.</div>
          <button className="primary-button warning" type="button" onClick={() => confirmCancelInvoice()}>Cancel Invoice</button>
          <div className="text-center mt-15">
            <div className="blue-link" onClick={() => setConfirmCancelVisible(false)}>Cancel</div>
          </div>
        </div>
      </Modal>
    )
  }

  const renderConfirmRevertContractModal = () => {
    return (
      <Modal visible={isConfirmRevertContractModalVisible} closable={false} footer={null} width={400} wrapClassName="rounded-modal">
        <div className="text-right" onClick={() => setConfirmRevertContractModalVisible(false)}><MdOutlineClose size={30} color={"#CCC"}/></div>
        <div className="mt-10">
          <div className="fw-700 fs-20 text-center">Revert Contract</div>
          <div className="fw-500 fs-14 mt-20 mb-20 text-center">Are you sure you would like to revert <span className="fw-700">{selectedContract.event_contract_name}</span> to a draft status? This will remove all signatures and allow you to edit the document.</div>
          <button className="primary-button warning" type="button" onClick={() => revertContract()}>Revert to Draft</button>
          <div className="text-center mt-15">
            <div className="blue-link" onClick={() => setConfirmRevertContractModalVisible(false)}>Cancel</div>
          </div>
        </div>
      </Modal>
    )
  }

  const renderContract = (contract, index) => {
    return (
      <div key={index} className="b-border p-20">
        <div className="flex-row">
          <div>
            <div className="display-flex">
              <div className="user-profile-circle"><FaSignature/></div>
            </div>
          </div>
          <div className="flex-col justify-content-center flex-1 mh-15">
            <div className="fs-14 fw-700 line-1-2">{contract.event_contract_name }</div>
            <div className="mt-5">
              <StatusTag status={contract.status} size="small"/>
            </div>
          </div>
          <div className="display-flex flex-middle mr-5">
            <Dropdown overlay={contractMenu(contract, index)} placement="bottomRight" trigger="click">
              <div className="dots-container">
                <MdOutlineMoreHoriz style={{ fontSize: 24, color: '#999'}}/>
              </div>
            </Dropdown>
          </div>
        </div>
        <div className="bg-gray radius-8 mt-10 pv-15 ph-20">
          <Row align="middle" className="">
            <Col xs={12}>
              <div className="fs-14 fw-700">Created on</div>
              <div className="fs-14 fw-500">{!isEmpty(contract.create_datetime) ? formatDateShort(contract.create_datetime, accountSettings) : "N/A"}</div>
            </Col>
            <Col xs={12}>
              <div className="fs-14 fw-700">Expires on</div>
              <div className="fs-14 fw-500">{!isEmpty(contract.expiration_datetime) ? formatDateShort(contract.expiration_datetime, accountSettings, true) : "N/A"}</div>
            </Col>
          </Row>
        </div>
      </div>
    )
  }

  const renderPDF = (name, description, url) => {
    return (
      <div className="ph-20">
        <div className="flex-row b-border pv-15">
          <div>
            <div className="display-flex" onClick={() => onPrintEventInfo(url)}>
              <div className="user-profile-circle"><FaRegFilePdf/></div>
            </div>
          </div>
          <div className="flex-col justify-content-center flex-1 mh-10" onClick={() => onPrintEventInfo(url)}>
            <div className="fs-14 fw-700 line-1-2">{ name }</div>
            <div className="fs-14 line-1-2 mt-2 c-text-gray">{ description }</div>
          </div>
          <div className="display-flex flex-middle mr-5">
            <Dropdown overlay={eventInfoMenu(url)} placement="bottomRight" trigger="click">
              <div className="dots-container">
                <MdOutlineMoreHoriz style={{ fontSize: 24, color: '#999'}}/>
              </div>
            </Dropdown>
          </div>
        </div>
      </div>
    )
  }

  const renderInvoice = (invoice, index) => {
    return (
      <div key={index} className="b-border p-20">
        <div className="flex-row">
          <div>
            <div className="display-flex">
              <div className="user-profile-circle"><MdOutlineAttachMoney/></div>
            </div>
          </div>
          <div className="flex-col justify-content-center flex-1 mh-15">
            <div className="fs-14 fw-700 line-1-2">Invoice #{invoice.invoice_number} <div className="status-tag ml-10">{!isEmpty(invoice.status) ? invoice.status.replace("_", " ") : ""}</div></div>
            <div className="mt-5">
              <div className="fs-14 line-1-2">{ formatCurrencyString(invoice.amount_due, currency.code) }</div>
            </div>
          </div>
          <div className="display-flex flex-middle mr-5">
            <Dropdown overlay={invoiceMenu(invoice, index)} placement="bottomRight" trigger="click">
              <div className="dots-container">
                <MdOutlineMoreHoriz style={{ fontSize: 24, color: '#999'}}/>
              </div>
            </Dropdown>
          </div>
        </div>
        <div className="bg-gray radius-8 mt-10 pv-15 ph-20">
          <Row align="middle" className="">
            <Col xs={12}>
              <div className="fs-14 fw-700">Invoice Date</div>
              <div className="fs-14 fw-500">{!isEmpty(invoice.invoice_date) ? formatDateShort(invoice.invoice_date, accountSettings, true) : "N/A"}</div>
            </Col>
            <Col xs={12}>
              <div className="fs-14 fw-700">Due Date</div>
              <div className="fs-14 fw-500">{!isEmpty(invoice.due_date) ? formatDateShort(invoice.due_date, accountSettings, true)  : "N/A"}</div>
            </Col>
          </Row>
        </div>
      </div>
    )
  }

  const renderInvoiceV2 = (invoice, index) => {
    return (
      <div key={index} className="b-border p-20">
        <div className="flex-row">
          <div>
            <div className="display-flex">
              <div className="user-profile-circle"><MdOutlineAttachMoney/></div>
            </div>
          </div>
          <div className="flex-col justify-content-center flex-1 mh-15">
            <div className="fs-14 fw-700 line-1-2">Invoice #{invoice.invoice_number} <StatusTag status={invoice.status} className="ml-10"/></div>
            <div className="mt-5">
              <div className="fs-14 line-1-2">{ formatCurrencyString(invoice.total, currency.code) }</div>
            </div>
          </div>
          <div className="display-flex flex-middle mr-5">
            <Dropdown overlay={invoiceMenuV2(invoice, index)} placement="bottomRight" trigger="click">
              <div className="dots-container">
                <MdOutlineMoreHoriz style={{ fontSize: 24, color: '#999'}}/>
              </div>
            </Dropdown>
          </div>
        </div>
        <div className="bg-gray radius-8 mt-10 pv-15 ph-20">
          <Row align="middle" className="">
            <Col xs={12}>
              <div className="fs-14 fw-700">Invoice Date</div>
              <div className="fs-14 fw-500">{!isEmpty(invoice.create_datetime) ? formatDateShort(invoice.create_datetime, accountSettings, true) : "N/A"}</div>
            </Col>
            <Col xs={12}>
              <div className="fs-14 fw-700">Due Date</div>
              <div className="fs-14 fw-500">{!isEmpty(invoice.due_date) ? formatDateShort(invoice.due_date, accountSettings, true)  : "N/A"}</div>
            </Col>
          </Row>
        </div>
      </div>
    )
  }

  const renderContractsSection = () => {
    if (!canViewFinancials) {
      return null
    }
    return (
      <div className="shadow-card mb-20">
        <Row align="middle" className="mh-20 pv-15 b-border">
          <Col flex={1}>
            <div className="fs-18 fw-700">Contracts</div>
          </Col>
          <Col>
            <div className="plus-container mr-5" onClick={() => onAddContract()}>
              <FiPlus style={{ fontSize: 20, color: '#536DFE'}}/>
            </div>
          </Col>
        </Row>
        { eventContracts.map((x,i) => renderContract(x,i)) }
        { eventContracts.length == 0 && (
          <div className="p-15 text-center">
            <div className="bg-gray radius-8 p-15 text-center">
              <div>No contracts have been created for this event.</div>
              <button className="small-primary-button mt-15" onClick={() => onAddContract()}>Create Contract</button>
            </div>
          </div>
        )}
      </div>
    )
  }

  const renderLegacyInvoicesSection = () => {
    if (!canViewFinancials) {
      return null
    }
    return (
      <div className="shadow-card mb-20">
        <Row align="middle" className="mh-20 pv-15 b-border">
          <Col flex={1}>
            <div className="fs-18 fw-700">Invoices</div>
          </Col>
          <Col>
            <div className="plus-container mr-5" onClick={() => onAddInvoice()}>
              <FiPlus style={{ fontSize: 20, color: '#536DFE'}}/>
            </div>
          </Col>
        </Row>
        { eventInvoices.map((x,i) => renderInvoice(x,i)) }
        { eventInvoices.length == 0 && (
          <div className="p-15 text-center">
            <div className="bg-gray radius-8 p-15 text-center">
              <div>No invoices have been created for this event.</div>
              <button className="small-primary-button mt-15" onClick={() => onAddInvoice()}>Create Invoice</button>
            </div>
          </div>
        )}
      </div>
    )
  }

  const renderInvoicesSection = () => {
    if (!canViewFinancials) {
      return null
    }
    return (
      <div className="shadow-card mb-20">
        <Row align="middle" className="mh-20 pv-15 b-border">
          <Col flex={1}>
            <div className="fs-18 fw-700">Invoices<div className="status-tag yellow medium ml-10">LEGACY</div></div>
          </Col>
          <Col>
          </Col>
          <Col xs={24}>
            <div className="bg-light-blue mt-10 p-10">An all new invoice experience is available for events that do not have legacy invoices. If you would like to take advantage of this new functionality, please remove all legacy invoices from this event and recreate them using the new invoice feature. (Please note that all existing payments will need to be re-added to the new invoices.)</div>
          </Col>
        </Row>
        { eventInvoices.map((x,i) => renderInvoice(x,i)) }
      </div>
    )
  }

  const renderInvoicesV2Section = () => {
    if (!canViewFinancials) {
      return null
    }
    return (
      <div className="shadow-card mb-20">
        <Row align="middle" className="mh-20 pv-15 b-border">
          <Col flex={1}>
            <div className="fs-18 fw-700">Invoices<div className="status-tag green medium ml-10">NEW!</div></div>
          </Col>
          <Col>
            { invoices.length != 0 && (
              <div className="plus-container mr-5" onClick={() => onAddInvoiceV2()}>
                <FiPlus style={{ fontSize: 20, color: '#536DFE'}}/>
              </div>
            )}
          </Col>
        </Row>
        { invoices.map((x,i) => renderInvoiceV2(x,i)) }
        { invoices.length == 0 && (
          <div className="p-15 text-center">
            <div className="bg-gray radius-8 p-15 text-center">
              <div>No invoices have been created for this event.</div>
              <button className="small-primary-button mt-15" onClick={() => onAddInvoiceV2()}>Create Invoice</button>
            </div>
          </div>
        )}
      </div>
    )
  }

  const renderShareModal = () => {
    return (
      <ShareModal
        isVisible={isShareModalVisible}
        setVisible={setShareModalVisible}
        title="Copy Invoice Link"
        link={`/invoice/${selectedInvoice.invoice_id}`}
      />
    )
  }

  const renderDocumentsTab = () => {
    return (
      <FloatingContainer className="ph-20 mb-20" verticalPadding={20} maxWidth={600}>
        <div className="shadow-card mb-20">
          <Row align="middle" className="mh-20 pv-15 b-border">
            <Col flex={1}>
              <div className="fs-18 fw-700">Event PDFs</div>
            </Col>
          </Row>
          { renderPDF("Event Sheet", "Details of the event", `${BASE_URL}/pdfs/event/${event.event_id}/details`) }
          { renderPDF("Music Selections", "Special event songs, playlists, & favorite genres", `${BASE_URL}/pdfs/event/${event.event_id}/music`) }
          { renderPDF("Timeline", "Schedule of events", `${BASE_URL}/pdfs/event/${event.event_id}/timeline`) }
          { renderPDF("Questionnaires", "Information gathered from your clients", `${BASE_URL}/pdfs/event/${event.event_id}/questionnaires`) }
        </div>
        { renderContractsSection() }
        { isNewInvoicesEnabled ? (
          <>
            { !isNewInvoicesDisplayed && renderInvoicesSection() }
            { isNewInvoicesDisplayed && renderInvoicesV2Section() }
          </>
        ) : (
          <>
            { renderLegacyInvoicesSection() }
          </>
        )}
        { renderNewContractModal() }
        { renderNewInvoiceModal() }
        { renderNewInvoiceModalV2() }
        { renderShareModal() }
        { renderConfirmRemoveContractModal() }
        { renderConfirmRevertContractModal() }
        { renderConfirmRemoveInvoiceModal() }
        { renderConfirmRemoveInvoiceV2Modal() }
        { renderConfirmCancelModal() }
      </FloatingContainer>
    )
  }

  return renderDocumentsTab();
}

export default EventDocumentsTab;

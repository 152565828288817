import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from 'react-router-dom';
import useApi from '../../hooks/useApi';
import AppContext from '../../app/context';
import LoadingSpinner from '../../components/loading';
import FloatingContainer from '../../components/floatingContainer'
import FadeDownWhileInView from '../../components/fadeDownWhileInView'
import { getDeviceId } from '../../helpers/deviceHelper';
import { refreshToken } from '../../api';

const SubscribeConfirmationPage = () => {

  const [isLoading, setLoading] = useState(true);

  const { auth, setAuth } = useContext(AppContext);
  const navigate = useNavigate();
  
  const [sendRequest] = useApi()

  useEffect(() => {
    window.scrollTo(0, 0);
    refreshPage()
  }, []);

  const refreshPage = async () => {
    try {
      const response = await sendRequest(refreshToken({ user_id: auth.user.user_id, refresh_token: auth.refresh_token, source: "web", device_id: getDeviceId() }))
      setAuth(response)
    } finally {
      setLoading(false)
    }
  }

  const renderPage = () => {
    return (
      <FloatingContainer className="ph-20" verticalPadding={40} maxWidth={600}>
        <FadeDownWhileInView className="shadow-card p-30">
          <div className="fs-24 fw-700 text-center mb-30">Success!</div>
          <div className="text-center">You are now officially subscribed to DJ Planning Center.</div>
          <div className="text-center mt-20">
            <button className="primary-button" style={{ maxWidth: 300 }} onClick={() => navigate("/admin/dashboard")}>Go to Dashboard</button>
          </div>
        </FadeDownWhileInView>
      </FloatingContainer>
    )
  }

  if (isLoading) {
    return <LoadingSpinner/>;
  }

  return renderPage();
}

export default SubscribeConfirmationPage;

import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { isArray, isEmpty, cloneDeep, sortBy, isUndefined } from "lodash";
import { Row, Col, Modal, Grid, Menu, Dropdown, Form, notification, Input, Radio, Space, Select} from 'antd';
import { getPlaylistLengthAndTime } from '../../../../helpers/musicHelper';
import FloatingContainer from '../../../../components/floatingContainer'
import useApi from '../../../../hooks/useApi';
import ShareModal from '../../../../modals/shareModal';
import { 
  updateEventGenres,
  updateEventPlaylist,
  createEventPlaylist,
  deleteEventPlaylist
} from '../../../../api';
import { 
  renderFormLabel,
  renderInputField,
  renderNumberField
} from '../../../../components/formFields'
import { MdOutlineClose, MdOutlineEdit, MdOutlineMoreHoriz, MdCheckBox, MdCheckBoxOutlineBlank, MdOutlineKeyboardArrowDown, MdOutlineKeyboardArrowRight, MdOutlineRemoveRedEye, MdQueueMusic, MdOutlineStickyNote2, MdRemoveCircleOutline, MdOutlineShare } from "react-icons/md";
import { FiMusic, FiStar, FiDownload, FiEye, FiPlus, FiTrash } from "react-icons/fi";
import { BiExport } from "react-icons/bi";
import { TbBrandSpotify } from "react-icons/tb";
import { DeleteOutlined } from '@ant-design/icons';
import AppContext from '../../../../app/context';
import useBetaFeatures from '../../../../hooks/useBetaFeatures';
import FeatureFlags from '../../../../constants/featureFlags';
import BetaFeatures from '../../../../constants/betaFeatures';

const BASE_URL = process.env.REACT_APP_BASE_URL;

const EventPlanningTabMusic = (props) => {

  const { specialEventSections = [], playlists = [], event, eventGenres = [], systemGenres = [], refreshEventGenres = () => {}, refreshPage = () => {}, onSelectPlanningTab = () => {} } = props;

  const [selectedSpecialEvent, setSelectedSpecialEvent] = useState({});
  const [selectedPlaylist, setSelectedPlaylist] = useState({});
  const [doNotPlaySongs, setDoNotPlaySongs] = useState([]);
  const [selectedGenre, setSelectedGenre] = useState({});
  const [selectedGenreNote, setSelectedGenreNote] = useState("");
  const [exportFileExt, setExportFileExt] = useState("CSV");
  const [exportedSongs, setExportedSongs] = useState([]);
  const [isSpotifyExportEnabled, setSpotifyExportEnabled] = useState(true);

  // Modals
  const [isShareModalVisible, setShareModalVisible] = useState(false);
  const [isPlaylistPreviewModalVisible, setPlaylistPreviewModalVisible] = useState(false);
  const [isEventGenresModalVisible, setEventGenresModalVisible] = useState(false);
  const [isOtherPlaylistPreviewModalVisible, setOtherPlaylistPreviewModalVisible] = useState(false);
  const [isUpdatePlaylistodalVisible, setUpdatePlaylistModalVisible] = useState(false);
  const [isDeletePlaylistModalVisible, setDeletePlaylistModalVisible] = useState(false);
  const [isGenreNotesModalVisible, setGenreNotesModalVisible] = useState(false);
  const [isExportModalVisible, setExportModalVisible] = useState(false);
  const [isTempModalVisible, setTempModalVisible] = useState(false);

  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();
  const { auth, _ } = useContext(AppContext);

  const navigate = useNavigate();
  const [sendRequest] = useApi()
  const [hasBetaFeature] = useBetaFeatures()
  const [playlistForm] = Form.useForm();
  const [exportPlaylistForm] = Form.useForm();

  useEffect(() => {
    loadPage()
  }, [])

  const loadPage = async () => {
    try {
      const spotifyExportbetaResults = await hasBetaFeature(BetaFeatures.SPOTIFY_EXPORT)
      setSpotifyExportEnabled(FeatureFlags.SPOTIFY_EXPORT || spotifyExportbetaResults)
    } catch { }
  }

  const handlePreviewPlaylist = (specialEvent) => {
    setSelectedSpecialEvent(specialEvent)
    setPlaylistPreviewModalVisible(true)
  }

  const handlePreviewOtherPlaylist = (playlist) => {
    setSelectedPlaylist(playlist)
    setOtherPlaylistPreviewModalVisible(true)
  }

  const handleEditOtherPlaylist = (playlist) => {
    navigate(`/admin/events/${event.event_id}/playlists/${playlist.event_playlist_id}`)
  }

  const handleAddPlaylist = () => {
    playlistForm.resetFields()
    playlistForm.setFieldsValue({ song_limit: 30 })
    setSelectedPlaylist({})
    setUpdatePlaylistModalVisible(true)
  };

  const handleUpdatePlaylist = (playlist) => {
    const formData = {
      playlist_name: playlist.playlist_name,
      song_limit: playlist.song_limit
    }
    playlistForm.setFieldsValue(formData)
    setSelectedPlaylist(playlist)
    setUpdatePlaylistModalVisible(true)
  };

  const handleDeletePlaylist = (playlist) => {
    setSelectedPlaylist(playlist)
    setDeletePlaylistModalVisible(true)
  };

  const confirmDeletePlaylist = async () => {
    try {
      await sendRequest(deleteEventPlaylist(event.event_id, selectedPlaylist.event_playlist_id));
      notification.success({
        message: 'Success!',
        description: 'Your playlist has been removed.',
        duration: 3
      });
      setDeletePlaylistModalVisible(false)
      await refreshPage()
    } catch {
      notification.error({
        message: 'Error!',
        description: 'Something went wrong. Please try again',
        duration: 3
      });
    }
  };

  const onPlaylistSubmit = async (values) => {
    try {
      var newPlaylists = cloneDeep(playlists)

      if (isEmpty(selectedPlaylist)) {
        // Add new playlist
        const playlist = newPlaylists.find((x) => x.playlist_name == values.playlist_name)
        if (isUndefined(playlist)) {
          // Playlist doesn't exist, so add it...
          const data = {
            playlist_name: values.playlist_name,
            song_limit: values.song_limit
          }
          await sendRequest(createEventPlaylist(event.event_id, data));
          notification.success({
            message: 'Success!',
            description: 'Your playlist has been added.',
            duration: 3
          });
          await refreshPage()
          setUpdatePlaylistModalVisible(false)
        } else {
          // Playlist exists, display error
          notification.error({
            message: 'Playlist Exists',
            description: 'A playlist with this name already exists.',
            duration: 3
          });
        }
      } else {
        // Edit playlist
        const playlist = newPlaylists.find((x) => x.playlist_name == values.playlist_name && x.event_playlist_id != selectedPlaylist.event_playlist_id)
        if (isUndefined(playlist)) {
          const data = {
            playlist_name: values.playlist_name,
            song_limit: values.song_limit
          }
          await sendRequest(updateEventPlaylist(event.event_id, selectedPlaylist.event_playlist_id, data));
          notification.success({
            message: 'Success!',
            description: 'Your playlist have been updated.',
            duration: 3
          });
          await refreshPage()
          setUpdatePlaylistModalVisible(false)
        } else {
          // Playlist exists, display error
          notification.error({
            message: 'Playlist Exists',
            description: 'A playlist with this name already exists.',
            duration: 3
          });
        }
      }
    } catch {
      notification.error({
        message: 'Error!',
        description: 'Something went wrong. Please try again',
        duration: 3
      });
    }
  }

  const onSelectGenre = async (genre) => {
    try {
      var newEventGenres = cloneDeep(eventGenres)
      const isSelected = !isEmpty(newEventGenres.find(x => x.system_genre_id == genre.system_genre_id))
      if (isSelected) {
        newEventGenres = newEventGenres.filter(x => x.system_genre_id != genre.system_genre_id)
      } else {
        const newGenre = {
          ...genre,
          notes: ""
        }
        newEventGenres.push(newGenre)
      }
      newEventGenres = sortBy(newEventGenres, "genre_name");

      await sendRequest(updateEventGenres(event.event_id, { genres: newEventGenres }))
      await refreshEventGenres()
    } catch {}
  }

  const onAddEditGenreNote = (genre) => {
    setSelectedGenre(genre)
    setSelectedGenreNote(genre.notes)
    setGenreNotesModalVisible(true)
  }

  const onClearGenreNote = async (genre) => {
    try {
      var newEventGenres = cloneDeep(eventGenres);
      for (var eventGenre of newEventGenres) {
        if (eventGenre.system_genre_id == genre.system_genre_id) {
          eventGenre.notes = ""
        }
      }
      await sendRequest(updateEventGenres(event.event_id, { genres: newEventGenres }))
      await refreshEventGenres()
    } catch {}
  }

  const saveGenreNote = async () => {
    try {
      var newEventGenres = cloneDeep(eventGenres);
      for (var genre of newEventGenres) {
        if (genre.system_genre_id == selectedGenre.system_genre_id) {
          genre.notes = selectedGenreNote
        }
      }
      await sendRequest(updateEventGenres(event.event_id, { genres: newEventGenres }))
      await refreshEventGenres()
      setGenreNotesModalVisible(false)
    } catch {}
  }

  const onRemoveGenre = async (genre) => {
    try {
      var newEventGenres = cloneDeep(eventGenres);
      var filteredEventGenres = newEventGenres.filter(x => x.system_genre_id != genre.system_genre_id)
      await sendRequest(updateEventGenres(event.event_id, { genres: filteredEventGenres }))
      await refreshEventGenres()
    } catch {}
  }

  const onExportPlaylist = (playlist) => {
    var songList = []
    if (isArray(playlist.songs)) {
      for (const song of playlist.songs) {
        songList.push({ title: song.title, artist: song.artist })
      }
    }
    setExportedSongs(songList)
    setExportModalVisible(true)
    exportPlaylistForm.setFieldsValue({ filename: null, format: null })
    setExportFileExt("CSV")
  }

  const onExportMusic = () => {
    var songList = []
    // Loop through special event sections sections
    for (const section of specialEventSections) {
      if (isArray(section.special_events)) {
        // Loop through special events
        for (const special_event of section.special_events) {
          if (isArray(special_event.songs)) {
            // Loop through songs
            for (const song of special_event.songs) {
              songList.push({ title: song.title, artist: song.artist })
            }
          }
        }
      }
    }
    // Add playlist songs
    for (const playlist of playlists) {
      if (isArray(playlist.songs)) {
        for (const song of playlist.songs) {
          songList.push({ title: song.title, artist: song.artist })
        }
      }
    }
    setExportedSongs(songList)
    setExportModalVisible(true)
    exportPlaylistForm.setFieldsValue({ filename: null, format: null })
    setExportFileExt("CSV")
  }

  const onExportToSpotify = () => {
    if (isSpotifyExportEnabled) {
      navigate(`/admin/events/${event.event_id}/spotify-export`)
    } else {
      setTempModalVisible(true)
    }
  }

  const onExportFileExtChange = (value) => {
    setExportFileExt(value)
    exportPlaylistForm.setFieldsValue({ format: null })
  }

  const onExport = (values) => {
    var fileData = ""
    if (isArray(exportedSongs)) {
      for (const song of exportedSongs) {
        if (values.format == "title-artist") {
          fileData += `${song.title} - ${song.artist}\n`
        } else if (values.format == "artist-title") {
          fileData += `${song.artist} - ${song.title}\n`
        } else if (values.format == "title-artist-pipe") {
          fileData += `${song.title} | ${song.artist}\n`
        } else if (values.format == "artist-title-pipe") {
          fileData += `${song.artist} | ${song.title}\n`
        } else if (values.format == "title-artist-comma") {
          fileData += `${song.title},${song.artist}\n`
        } else if (values.format == "artist-title-comma") {
          fileData += `${song.artist},${song.title}\n`
        }
      }
    }
    const blob = new Blob([fileData], { type: "text/plain" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    const ext = exportFileExt == "CSV" ? "csv" : "txt"
    link.download = `${values.filename}.${ext}`;
    link.href = url;
    link.click();
    setExportModalVisible(false)
  }

  const renderActiveSpecialEventSection = (section, index) => {
    const sectionSpecialEvents = isArray(section.special_events) ? section.special_events : []
    const sectionActiveSpecialEvents = sectionSpecialEvents.filter(x => x.active && x.songs_allowed);
    return (
      <div className="ph-20 pb-20" key={index}>
        <div className="mb-10 ml-5">
          <div className="fs-18 fw-700">{ section.section_name }</div>
          { section.description && (
            <div className="c-text-gray line-breaks">{ section.description }</div>
          )}
        </div>
        <div className="bg-gray radius-8 p-10">
          { sectionActiveSpecialEvents.map((x,i) => renderSpecialEvent(x,i))}
          { sectionActiveSpecialEvents.length == 0 && (
            <div className="bg-white border radius-8 ph-20 pv-30 text-center c-text-gray">The client has not selected any special events yet.</div>
          )}
        </div>
      </div>
    )
  }

  const downloadPDF = async () => {
    window.open(`${BASE_URL}/pdfs/event/${event.event_id}/music`, "_blank")
  }

  const renderMenuItem = (icon, title, action = () => {}) => {
    return (
      <Menu.Item>
        <Row className="pv-5" gutter={[10,10]}align="middle" onClick={() => action()}>
          <Col flex={0}>
            <div className="display-flex">{icon}</div>
          </Col>
          <Col flex={1}>{title}</Col>
        </Row>
      </Menu.Item>
    )
  }

  const actionMenu = () => {
    return (
      <Menu>
        { renderMenuItem(<FiEye/>, "Preview / Print", () => navigate(`/event/${event.event_id}/music`)) }
        { renderMenuItem(<MdOutlineShare/>, "Share", () => setShareModalVisible(true)) }
        { renderMenuItem(<FiDownload/>, "View / Download PDF", () => downloadPDF()) }
        { renderMenuItem(<BiExport/>, "Export to File", () => onExportMusic()) }
        { isSpotifyExportEnabled && renderMenuItem(<TbBrandSpotify/>, "Export to Spotify", () => onExportToSpotify()) }
      </Menu>
    )
  };

  const playlistMenu = (item, index) => {
    return (
      <Menu>
        { renderMenuItem(<MdOutlineRemoveRedEye/>, "Preview", () => handlePreviewOtherPlaylist(item)) }
        { renderMenuItem(<MdQueueMusic/>, "Edit Playlist", () => handleUpdatePlaylist(item)) }
        { renderMenuItem(<MdOutlineEdit/>, "Edit Songs", () => handleEditOtherPlaylist(item)) }
        { renderMenuItem(<BiExport/>, "Export to File", () => onExportPlaylist(item)) }
        { isSpotifyExportEnabled && renderMenuItem(<TbBrandSpotify/>, "Export to Spotify", () => onExportToSpotify()) }
        { renderMenuItem(<FiTrash/>, "Remove Playlist", () => handleDeletePlaylist(item)) }
      </Menu>
    )
  };

  const specialEventPlaylistMenu = (item, index) => {
    return (
      <Menu>
        { renderMenuItem(<MdOutlineRemoveRedEye/>, "Preview", () => handlePreviewPlaylist(item)) }
        { renderMenuItem(<BiExport/>, "Export to File", () => onExportPlaylist(item)) }
        { isSpotifyExportEnabled && renderMenuItem(<TbBrandSpotify/>, "Export to Spotify", () => onExportToSpotify()) }
      </Menu>
    )
  };

  const genreMenu = (genre) => {
    return (
      <Menu>
        <Menu.Item>
          <div onClick={() => onAddEditGenreNote(genre)}>
            <MdOutlineStickyNote2 style={{ marginRight: 8}}/> Add / Edit Note
          </div>
        </Menu.Item>
        { !isEmpty(genre.notes) && (
          <Menu.Item>
            <div onClick={() => onClearGenreNote(genre)}>
              <MdRemoveCircleOutline style={{ marginRight: 8}}/> Clear Note
            </div>
          </Menu.Item>
        )}
        <Menu.Item>
          <div onClick={() => onRemoveGenre(genre)}>
            <DeleteOutlined style={{ marginRight: 8}}/> Remove
          </div>
        </Menu.Item>
      </Menu>
    )
  };

  const renderPlaylist = (specialEvent, songCount) => {
    return (
      <div className={`ml-10`}>
        <Row align="middle" gutter={[15,15]} className="pv-10 pr-15">
          <Col>
            <div className="card-icon"><FiMusic/></div>
          </Col>
          <Col flex={1}>
            <div className="fw-700">Playlist</div>
            <div className="c-text-gray">{songCount} {songCount == 1 ? "Song" : "Songs"}</div>
          </Col>
          <Col>
            <div className="display-flex">
              <Dropdown overlay={specialEventPlaylistMenu(specialEvent)} placement="bottomRight" trigger="click">
                <MdOutlineMoreHoriz style={{ fontSize: 24, color: '#999'}}/>
              </Dropdown>
            </div>
          </Col>
        </Row>
      </div>
    )
  }

  const renderSpecialEventPlaylistOrSongs = (specialEvent) => {
    const songCount = isArray(specialEvent.songs) ? specialEvent.songs.length : 0
    if (songCount == 0) {
      return (
        <div className="ph-20 pv-30 text-center c-text-gray">The client has not selected any songs yet.</div>
      )
    }
    if (specialEvent.is_playlist) {
      return renderPlaylist(specialEvent, songCount)
    } else {
      return (
        <>
          { isArray(specialEvent.songs) && specialEvent.songs.map((x,i) => renderSpecialEventSong(x,i,specialEvent))}
        </>
      )
    }
  }

  const renderSpecialEvent = (event, index) => {
    const tag = event.is_playlist ? "PLAYLIST" : (event.songs_allowed ? "SPECIAL SONG" : "NO SONGS")
    return (
      <div className="bg-white border radius-8 mb-10" key={index}>
        <Row align="middle" gutter={[15,15]} className="b-border mh-10">
          <Col flex={1}>
            <div className="fw-700 fs-16 pv-10">{event.special_event_name}</div>
          </Col>
        </Row>
        { renderSpecialEventPlaylistOrSongs(event) }
      </div>
    )
  }

  const renderSpecialEventSong = (item, index, event) => {
    const borderClass = (index + 1) < event.song_limit ? "b-border" : ""
    return (
      <div className={`song-card song-card--music-planning-page no-shadow ph-15 ${borderClass}`} key={index}>
        <div className="song-card--row-1">
          <div className="song-card--image">
            { item.image ? (
              <img src={item.image} style={{ width: 50 }}/>
            ) : (
              <div className="song-image-icon"><FiMusic/></div>
            )}
          </div>
          <div className="song-card--title-artist">
            <div className="song-card--text fs-14 fw-700 line-1-5">{item.title}</div>
            <div className="song-card--text fs-14 c-text-gray line-1-5">{item.artist}</div>
          </div>
        </div>
        { !isEmpty(item.notes) && (
          <div className="song-card--row-2">
            { item.notes }
          </div>
        )}
      </div>
    )
  }

  const renderEventGenre = (genre, index) => {
    const borderClass = (index + 1) < eventGenres.length ? "b-border" : ""
    return (
      <div key={index} className={`mh-20 pv-10 ${borderClass}`}>
        <div className="fw-600 display-flex flex-middle">
          <div className="display-flex radius-8 flex-0 bg-light-blue p-10 flex-middle mr-10">
            <FiStar style={{ fontSize: 20, color: '#536DFE' }}/>
          </div>
          <div className="flex-1">
            { genre.genre_name }
          </div>
          <div className="flex-0">
            <Dropdown overlay={genreMenu(genre, index)} placement="bottomRight" trigger="click">
              <div className="song-card--dots-container">
                <MdOutlineMoreHoriz style={{ fontSize: 24, color: '#999'}}/>
              </div>
            </Dropdown>
          </div>
        </div>
        { !isEmpty(genre.notes) && (
          <div className="bg-gray radius-8 pv-10 ph-15 fs-12 mt-5">{ genre.notes }</div>
        )}
      </div>
    )
  }

  const renderPlaylistSong = (item, index) => {
    const borderClass = index < doNotPlaySongs.length - 1 ? "b-border" : "";
    return (
      <div className={`song-card song-card--playlist-page no-shadow ${borderClass}`} key={index}>
        <div className="song-card--row-1">
          <div className="song-card--image" onClick={() => {}}>
            { item.image ? (
              <img src={item.image} style={{ width: 50 }}/>
            ) : (
              <div className="song-image-icon"><FiMusic/></div>
            )}
          </div>
          <div className="song-card--title-artist" onClick={() => {}}>
            <div className="song-card--text fs-14 fw-700 line-1-5">{item.title}</div>
            <div className="song-card--text fs-14 c-text-gray line-1-5">{item.artist}</div>
          </div>
        </div>
        { !isEmpty(item.notes) && (
          <div className="song-card--row-2">
            { item.notes }
          </div>
        )}
      </div>
    )
  }

  const renderOtherPlaylist = (playlist, index) => {
    const borderClass = index < playlists.length - 1 ? "b-border" : ""
      return (
        <div key={index} className={`p-10 ${borderClass}`}>
          <Row gutter={[15,15]} align="middle">
            <Col onClick={() => handleEditOtherPlaylist(playlist)}>
              <div className="card-icon"><FiMusic/></div>
            </Col>
            <Col flex={1} onClick={() => handleEditOtherPlaylist(playlist)}>
              <div className="fs-14 fw-700">{playlist.playlist_name}</div>
              <div className="fs-14 fw-500 c-text-gray line-1-1">{playlist.song_count} {playlist.song_count == 1 ? "Song" : "Songs"} | Limit: {playlist.song_limit == 0 ? "None" : playlist.song_limit}</div>
            </Col>
            <Col flex={0}>
              <div className="display-flex mr-5">
                <Dropdown overlay={playlistMenu(playlist, index)} placement="bottomRight" trigger="click">
                  <div className="dots-container">
                    <MdOutlineMoreHoriz style={{ fontSize: 24, color: '#999'}}/>
                  </div>
                </Dropdown>
              </div>
            </Col>
          </Row>
        </div>
    )
  }

  const renderPlanningMusicTab = () => {
    return (
      <>
        <div className="shadow-card mb-20">
          <Row align="middle" className="mh-20 pv-15 mb-15 b-border">
            <Col flex={1}>
              <div className="fs-18 fw-700">Special Events</div>
            </Col>
            <Col>
              <div className="plus-container mr-5" onClick={() => navigate(`/admin/events/${event.event_id}/special-events`)}>
                <MdOutlineEdit style={{ fontSize: 20, color: '#536DFE'}}/>
              </div>
            </Col>
          </Row>
          { specialEventSections.map((specialEvent,index) => renderActiveSpecialEventSection(specialEvent,index))}
        </div>
        <div className="shadow-card mb-20 pb-5">
          <Row align="middle" className="mh-20 pv-15 b-border">
            <Col flex={1}>
              <div className="fs-18 fw-700">Favorite Genres</div>
            </Col>
            <Col>
              <div className="plus-container mr-5" onClick={() => setEventGenresModalVisible(true)}>
                <MdOutlineEdit style={{ fontSize: 20, color: '#536DFE'}}/>
              </div>
            </Col>
          </Row>
          { eventGenres.map((genre,index) => renderEventGenre(genre,index))}
          { eventGenres.length == 0 && (
            <div className="ph-20 pv-30 text-center c-text-gray">The client has not selected any genres yet.</div>
          )}
        </div>
        <div className="shadow-card mb-20">
          <Row align="middle" className="mh-20 pv-15 b-border">
            <Col flex={1}>
              <div className="fs-18 fw-700">Playlists</div>
            </Col>
            <Col>
              <div className="plus-container mr-5" onClick={() => handleAddPlaylist()}>
                <FiPlus style={{ fontSize: 20, color: '#536DFE'}}/>
              </div>
            </Col>
          </Row>
          <div className="mh-5">
            { playlists.map((playlist,index) => renderOtherPlaylist(playlist,index))}
          </div>
          { playlists.length == 0 && (
            <div className="ph-20 pv-30 text-center c-text-gray">No playlists have been created for this event.</div>
          )}
        </div>
      </>
    )
  }

  const renderShareModal = () => {
    return (
      <ShareModal
        isVisible={isShareModalVisible}
        setVisible={setShareModalVisible}
        title="Share Music Selections"
        link={`/event/${event.event_id}/music`}
      />
    )
  }

  const renderPlaylistPreviewModal = () => {
    const playlistSongs = isArray(selectedSpecialEvent.songs) ? selectedSpecialEvent.songs : []
    return (
      <Modal visible={isPlaylistPreviewModalVisible} closable={false} footer={null} width={600} wrapClassName="rounded-modal">
        <Row align="middle" className="b-border pb-10">
          <Col flex={1}>
            <div className="fw-700 fs-18">{ selectedSpecialEvent.special_event_name }</div>
          </Col>
          <Col>
            <div className="display-flex" onClick={() => setPlaylistPreviewModalVisible(false)}><MdOutlineClose size={30} color={"#CCC"}/></div>
          </Col>
        </Row>
        <div className="mt-10">
          { playlistSongs.map((x,i) => renderPlaylistSong(x,i))}
          { playlistSongs.length == 0 && (
            <div className="p-30 text-center c-text-gray">No songs yet</div>
          )}
          <div className="t-border pt-10">
            <div className="c-text-gray text-center">{ getPlaylistLengthAndTime(playlistSongs) }</div>
          </div>
        </div>
      </Modal>
    )
  }

  const renderOtherPlaylistPreviewModal = () => {
    const playlistSongs = isArray(selectedPlaylist.songs) ? selectedPlaylist.songs : []
    return (
      <Modal visible={isOtherPlaylistPreviewModalVisible} closable={false} footer={null} width={600} wrapClassName="rounded-modal">
        <Row align="middle" className="b-border pb-10">
          <Col flex={1}>
            <div className="fw-700 fs-18">{ selectedPlaylist.playlist_name }</div>
          </Col>
          <Col>
            <div className="display-flex" onClick={() => setOtherPlaylistPreviewModalVisible(false)}><MdOutlineClose size={30} color={"#CCC"}/></div>
          </Col>
        </Row>
        <div className="mt-10">
          { playlistSongs.map((x,i) => renderPlaylistSong(x,i))}
          { playlistSongs.length == 0 && (
            <div className="p-30 text-center c-text-gray">No songs yet</div>
          )}
          <div className="t-border pt-10">
            <div className="c-text-gray text-center">{ getPlaylistLengthAndTime(playlistSongs) }</div>
          </div>
        </div>
      </Modal>
    )
  }

  const renderSystemGenre = (genre, index) => {
    const isSelected = !isEmpty(eventGenres.find(x => x.system_genre_id == genre.system_genre_id))
    return (
      <div className="flex-row flex-middle b-border p-15" onClick={() => onSelectGenre(genre)} key={index}>
        <div className="flex-1">
          <div className="fw-600">{genre.genre_name}</div>
        </div>
        <div className="flex-0">
          <div className="display-flex">
            { isSelected ? <MdCheckBox size={24} color={"#536DFE"}/> : <MdCheckBoxOutlineBlank size={24} color={"#CCC"}/>}
          </div>
        </div>
      </div>
    )
  }

  const renderEventGenresModal = () => {
    return (
      <Modal visible={isEventGenresModalVisible} footer={null} onCancel={() => setEventGenresModalVisible(false)} width={500} closable={false} maskClosable={false} wrapClassName="rounded-modal">
        <Row align="middle">
          <Col flex={1}>
            <div className="fw-700 fs-18">Favorite Genres</div>
          </Col>
          <Col>
            <div className="display-flex" onClick={() => setEventGenresModalVisible(false)}><MdOutlineClose size={30} color={"#CCC"}/></div>
          </Col>
        </Row>
        <div className="">
          <div className="pt-10 pb-10">
            <div className="bg-gray pv-20 ph-20 radius-8 fs-12 text-center"><b>IMPORTANT:</b> The client should be in charge of selecting their favorite genres from this list, however you are able to override their selection by making modifications below.</div>
            <div className="shadow-card mb-5 mt-15">
              { systemGenres.map((x,i) => renderSystemGenre(x,i))}
            </div>
          </div>
          <button className="primary-button" type="button" onClick={() => setEventGenresModalVisible(false)}>Done</button>
        </div>
      </Modal>
    )
  }

  const renderAddEditGenreNotesModal = () => {
    return (
      <Modal visible={isGenreNotesModalVisible} footer={null} onCancel={() => setGenreNotesModalVisible(false)} width={400} closable={false} maskClosable={false} wrapClassName="rounded-modal">
        <Row align="middle">
          <Col flex={1}>
            <div className="fw-700 fs-18">Add / Edit Note</div>
          </Col>
          <Col>
            <div className="display-flex" onClick={() => setGenreNotesModalVisible(false)}><MdOutlineClose size={30} color={"#CCC"}/></div>
          </Col>
        </Row>
        <div className="">
          <div className="pt-10 pb-10">
            <Input.TextArea 
              rows={3}
              value={selectedGenreNote}
              onChange={(e) => setSelectedGenreNote(e.target.value)}
              size="large"/>
          </div>
          <button className="primary-button" type="button" onClick={() => saveGenreNote()}>Save</button>
          <div className="text-center mt-15">
            <div className="blue-link" onClick={() => setGenreNotesModalVisible(false)}>Cancel</div>
          </div>
        </div>
      </Modal>
    )
  }

  const renderUpdatePlaylistModal = () => {
    return (
      <Modal visible={isUpdatePlaylistodalVisible} closable={false} footer={null} width={600} wrapClassName="rounded-modal">
        <Row align="middle" className="b-border pb-10">
          <Col flex={1}>
            <div className="fw-700 fs-18">{isEmpty(selectedPlaylist) ? "Add Playlist" : "Edit Playlist"}</div>
          </Col>
          <Col>
            <div className="display-flex" onClick={() => setUpdatePlaylistModalVisible(false)}><MdOutlineClose size={30} color={"#CCC"}/></div>
          </Col>
        </Row>
        <div className="mt-10">
          <Form form={playlistForm} layout="vertical" name="edit-event" onFinish={onPlaylistSubmit}>
            <Row gutter={[20,10]}>
            <Col xs={24} md={24}>
                { renderInputField("Playlist Name", "playlist_name", true, false, "Enter a playlist name...") }
              </Col>
              <Col xs={24} md={24}>
                { renderNumberField("Song Limit", "song_limit", 0, true) }
                <div className="bg-gray p-10 mt-10 radius-8"><b>Note:</b> Entering a value of "0" will remove the song limit.</div>
              </Col>
            </Row>
            <div className="admin-modal-footer">
              <button className="page-title-button" type="submit">Save</button>
            </div>
          </Form>
        </div>
      </Modal>
    )
  }

  const renderConfirmRemovePlaylistModal = () => {
    return (
      <Modal visible={isDeletePlaylistModalVisible} closable={false} footer={null} width={400} wrapClassName="rounded-modal">
        <div className="text-right" onClick={() => setDeletePlaylistModalVisible(false)}><MdOutlineClose size={30} color={"#CCC"}/></div>
        <div className="mt-10">
          <div className="fw-700 fs-20 text-center">Remove Playlist</div>
          <div className="fw-500 fs-14 mt-20 mb-20 text-center">Are you sure you would like to remove <span className="fw-700">{selectedPlaylist.playlist_name}</span>? Any songs that have been added to this playlist will also be deleted.</div>
          <button className="primary-button warning" type="button" onClick={() => confirmDeletePlaylist()}>Remove Playlist</button>
          <div className="text-center mt-15">
            <div className="blue-link" onClick={() => setDeletePlaylistModalVisible(false)}>Cancel</div>
          </div>
        </div>
      </Modal>
    )
  }

  const renderExportToFileModal = () => {
    return (
      <Modal visible={isExportModalVisible} closable={false} footer={null} width={600} wrapClassName="rounded-modal">
        <Row align="middle" className="b-border pb-10">
          <Col flex={1}>
            <div className="fw-700 fs-18">Export Playlist</div>
          </Col>
          <Col>
            <div className="display-flex" onClick={() => setExportModalVisible(false)}><MdOutlineClose size={30} color={"#CCC"}/></div>
          </Col>
        </Row>
        <Form form={exportPlaylistForm} layout="vertical" name="edit-event" onFinish={onExport}>
          <Row gutter={[20,10]} className="mt-20">
            <Col xs={24} md={24}>
              { renderInputField("Filename", "filename", true) }
            </Col>
            <Col xs={24} md={24}>
              { renderFormLabel("File Type")}
              <Radio.Group value={exportFileExt} onChange={(e) => onExportFileExtChange(e.target.value)}>
                <Space direction="vertical">
                  <Radio value="CSV">CSV (Comma-Separated Values)</Radio>
                  <Radio value="TXT">TXT (Text File)</Radio>
                </Space>
              </Radio.Group>
            </Col>
            <Col xs={24} md={24}>
              { renderFormLabel("Format")}
              <Form.Item
                name={"format"}
                rules={[{ required: true, message: `Format is required!`, validateTrigger: "onBlur" }]}
              >
                <Select
                  placeholder={"Format"}
                  size='large'
                  style={{ width: '100%'}}
                >
                  { exportFileExt == "CSV" ? (
                    <>
                      <Select.Option value={"title-artist-comma"}>Title,Artist</Select.Option>
                      <Select.Option value={"artist-title-comma"}>Artist,Title</Select.Option>
                    </>
                  ) : (
                    <>
                      <Select.Option value={"title-artist"}>Title - Artist</Select.Option>
                      <Select.Option value={"artist-title"}>Artist - Title</Select.Option>
                      <Select.Option value={"title-artist-pipe"}>Title | Artist</Select.Option>
                      <Select.Option value={"artist-title-pipe"}>Artist | Title</Select.Option>
                    </>
                  )}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <button className="primary-button" type="submit">Export Playlist</button>
        </Form>
      </Modal>
    )
  }

  // TODO: Remove after Spotify approval
  const renderTempSpotifyMessage = () => {
    return (
      <Modal visible={isTempModalVisible} closable={false} footer={null} width={400} wrapClassName="rounded-modal">
        <div className="text-right" onClick={() => setTempModalVisible(false)}><MdOutlineClose size={30} color={"#CCC"}/></div>
        <div className="mt-10">
          <div className="fw-700 fs-20 text-center">Export to Spotify</div>
          <div className="fw-500 fs-14 mt-20 mb-20 text-center">We recently launched this feature, however an issue was uncovered that requires us to disable it temporarily. We apologize for this inconvience, but please know we are working hard to resolve the issue.</div>
          <button className="primary-button" type="button" onClick={() => setTempModalVisible(false)}>OK</button>
        </div>
      </Modal>
    )
  }

  return (
    <>
      <div className="toolbar-container floating">
        <div className="flex-row flex-middle">
          <div className="blue-link" onClick={() => onSelectPlanningTab(null)}>Planning</div>
          <div className="mh-5 display-flex"><MdOutlineKeyboardArrowRight/></div>
          <div className="fw-600">Music</div>
        </div>
        <div className="flex-1"></div>
        <Dropdown overlay={actionMenu()} placement="bottomRight" trigger="click">
          <div className="toolbar-button">
            Actions <MdOutlineKeyboardArrowDown style={{ fontSize: 20, color: '#536DFE', marginLeft: 8}}/>
          </div>
        </Dropdown>
      </div>
      <FloatingContainer className="ph-20 mb-20" verticalPadding={20} maxWidth={600}>
        { renderPlanningMusicTab() }
      </FloatingContainer>
      { renderShareModal() }
      { renderPlaylistPreviewModal() }
      { renderOtherPlaylistPreviewModal() }
      { renderEventGenresModal() }
      { renderAddEditGenreNotesModal() }
      { renderUpdatePlaylistModal() }
      { renderExportToFileModal() }
      { renderTempSpotifyMessage() }
      { renderConfirmRemovePlaylistModal() }
    </>
  )
}

export default EventPlanningTabMusic;

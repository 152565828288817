import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { isUndefined, cloneDeep, isArray, chain, isNull, isEmpty } from "lodash";
import arrayMove from 'array-move';
import {SortableContainer, sortableElement} from 'react-sortable-hoc';
import useApi from '../../../hooks/useApi';
import useCurrency from '../../../hooks/useCurrency';
import {
  renderFormLabel,
  renderInputField,
  renderCheckboxField,
  renderSearchSelectField
} from '../../../components/formFields'
import { Row, Col, notification, Form, Modal, Input, DatePicker, Button, Select, Radio, Checkbox, Space } from 'antd';
import { updatePricingGuide, getPackages, getAddOns, getAdminUsers, getPricingGuide } from '../../../api';
import LoadingSpinner from '../../../components/loading';
import AdminContent from '../../../components/adminContent';
import FloatingContainer from '../../../components/floatingContainer'
import { MdArrowForwardIos, MdOutlineClose, MdDragIndicator } from "react-icons/md";
import { FiTrash } from "react-icons/fi";
import { LoadingOutlined } from '@ant-design/icons';
import useDocumentTitle from '../../../hooks/useDocumentTitle';
import { formatCurrencyString } from '../../../helpers/contractHelper';
import { BsCheckSquare, BsInputCursorText, BsTextareaResize, BsRecordCircle, BsCaretDownSquare, BsCalendar } from "react-icons/bs";
const BASE_URL = process.env.REACT_APP_BASE_URL;

const EditPricingGuidePage = () => {

  const [isLoading, setLoading] = useState(true);
  const [isSaving, setSaving] = useState(false);
  const [pricingGuide, setPricingGuide] = useState({});
  const [packages, setPackages] = useState([]);
  const [addOns, setAddOns] = useState([]);
  const [adminUsers, setAdminUsers] = useState([]);
  const [selectedPackages, setSelectedPackages] = useState([]);
  const [selectedAddOns, setSelectedAddOns] = useState([]);
  const [isAddPackageModalVisible, setAddPackageModalVisible] = useState(false);
  const [isAddAddOnModalVisible, setAddAddOnModalVisible] = useState(false);
  const [isDisplayContactForm, setDisplayContactForm] = useState(true);
  const [contactFormFields, setContactFormFields] = useState([]);
  const [isFormFieldModalVisible, setFormFieldModalVisible] = useState(false);
  const [selectedField, setSelectedField] = useState({});
  const [isNewField, setIsNewField] = useState(false);
  const [newFieldError, setNewFieldError] = useState("");
  const [optionsText, setOptionsText] = useState("");
  const [selectedFieldIndex, setSelectedFieldIndex] = useState(null);
  const [emptyValue, setEmptyValue] = useState("");
  const [systemFields, setSystemFields] = useState([]);

  useDocumentTitle("Edit Pricing Guide")
  const navigate = useNavigate();
  const params = useParams();
  const [form] = Form.useForm();
  const [sendRequest] = useApi()
  const [currency, _] = useCurrency()

  const pricingGuideId = params.id;

  var isPreviewSubmit = false

  useEffect(() => {
    window.scrollTo(0, 0);
    if (pricingGuideId) {
      refreshPage();
    } else {
      setLoading(false)
    }
  }, []);

  const systemFieldOptions = [
    { name: "Event Type", type: "event_type" },
    { name: "Start & End Time", type: "start_end_time" },
    { name: "Address", type: "address" },
    { name: "Phone Number", type: "phone" },
    { name: "Venue", type: "venue" }
  ]

  const refreshPage = async () => {
    try {

      const pricingGuideResults = await sendRequest(getPricingGuide(pricingGuideId))
      setPricingGuide(pricingGuideResults)
      setDisplayContactForm(pricingGuideResults.display_contact_form)
      setContactFormFields(isArray(pricingGuideResults.custom_fields) ? pricingGuideResults.custom_fields : [])
      setSystemFields(isArray(pricingGuideResults.system_fields) ? pricingGuideResults.system_fields : [])

      // Get company packages
      const packageResults = await sendRequest(getPackages());
      setPackages(packageResults);

      // Get company add ons
      const addOnResults = await sendRequest(getAddOns());
      setAddOns(addOnResults);

      // Get pricing guide packages and add ons
      var pricingGuidePackages = isArray(pricingGuideResults.services.packages) ? pricingGuideResults.services.packages : []
      var pricingGuideAddOns = isArray(pricingGuideResults.services.add_ons) ? pricingGuideResults.services.add_ons : []

      // Get array of ids
      var companyPackageIds = packageResults.map(x => x.package_id)
      var companyAddOnIds = addOnResults.map(x => x.add_on_id)

      // Filter out the packages that don't exist anymore
      const filteredPackages = cloneDeep(pricingGuidePackages).filter(x => companyPackageIds.includes(x))
      pricingGuidePackages = filteredPackages
      setSelectedPackages(pricingGuidePackages)

      // Filter out the add ons that don't exist anymore
      for (var category of pricingGuideAddOns) {
        const filteredAddOns = cloneDeep(category.add_ons).filter(x => companyAddOnIds.includes(x))
        category.add_ons = filteredAddOns
      }
      setSelectedAddOns(pricingGuideAddOns)

      const adminUserResults = await sendRequest(getAdminUsers());
      const adminUserData = adminUserResults.map((user) => {
        return {
          value: user.email,
          text: user.first_name + " " + user.last_name
        }
      })
      setAdminUsers(adminUserData);

      const formValues = {
        display_name: pricingGuideResults.display_name,
        internal_name: pricingGuideResults.internal_name,
        to_email: pricingGuideResults.to_email,
        display_contact_form: pricingGuideResults.display_contact_form,
        active: pricingGuideResults.active
      }
      form.setFieldsValue(formValues)

      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  const onSubmit = async (values) => {
    try {
      setSaving(true)

      const services = {
        packages: selectedPackages,
        add_ons: selectedAddOns
      }

      var body = {
        ...values,
        services: services,
        to_email: !isUndefined(values["to_email"]) ? values["to_email"] : null,
        display_contact_form: isUndefined(values["display_contact_form"]) || isNull(values["display_contact_form"]) ? true : values["display_contact_form"],
        active: !isUndefined(values["active"]) ? values["active"] : false,
        custom_fields: contactFormFields,
        system_fields: systemFields
      }

      await sendRequest(updatePricingGuide(pricingGuideId, body))
      if (isPreviewSubmit) {
        setSaving(false)
        window.open(`${BASE_URL}/pricing-guide/${pricingGuideId}`)
      } else {
        displaySuccess()
      }
    } catch (error) {
      Modal.error({
        title: "Oops!",
        content: 'Something went wrong. Please try again.',
        onOk: () => {}
      });
      setSaving(false)
    }
  }

  const displaySuccess = () => {
    notification.success({
      message: 'Success!',
      description: 'Your changes have been saved.',
      duration: 3
    });
    navigate("/admin/pricing-guides");
  }

  const getAddOnsByCategory = () => {
    const addOnByCategoryResults = chain(cloneDeep(addOns)).groupBy("category_name").map((value, key) => ({ category_name: key, add_ons: value})).value()
    return addOnByCategoryResults
  }

  const isPackageSelected = (package_id) => {
    return selectedPackages.includes(package_id)
  }

  const isAddOnSelected = (add_on_id) => {
    var isSelected = false
    for (const addOn of selectedAddOns) {
      if (addOn.add_ons.includes(add_on_id)) {
        isSelected = true
      }
    }
    return isSelected
  }

  const onPackageSelect = (package_id) => {
    var selectedPackagesClone = cloneDeep(selectedPackages)
    if (!selectedPackagesClone.includes(package_id)) {
      selectedPackagesClone.push(package_id)
    }
    setSelectedPackages(selectedPackagesClone)
  }

  const onAddOnSelect = (add_on) => {
    var selectedAddOnsClone = cloneDeep(selectedAddOns)
    var categoryFound = false
    for (var addOn of selectedAddOnsClone) {
      if (addOn.category_name == add_on.category_name) {
        categoryFound = true
        if (!addOn.add_ons.includes(add_on.add_on_id)) {
          addOn.add_ons.push(add_on.add_on_id)
        }
      }
    }
    if (!categoryFound) {
      const record = { category_name: add_on.category_name, add_ons: [add_on.add_on_id]}
      selectedAddOnsClone.push(record)
    }
    setSelectedAddOns(selectedAddOnsClone)
  }

  const onPackageSortEnd = (oldIndex, newIndex) => {
    var newSelectedPackages = cloneDeep(selectedPackages)
    if (oldIndex !== newIndex) {
      const sortedPackages = arrayMove(newSelectedPackages, oldIndex, newIndex).filter(el => !!el);
      setSelectedPackages(sortedPackages)
    }
  };

  const onAddOnSortEnd = (oldIndex, newIndex, categoryName) => {
    var newAddOns = cloneDeep(selectedAddOns)
    if (oldIndex !== newIndex) {
      for (var category of newAddOns) {
        if (category.category_name == categoryName) {
          const sortedAddOns = arrayMove(category.add_ons, oldIndex, newIndex).filter(el => !!el);
          category.add_ons = sortedAddOns
        }
      }
      setSelectedAddOns(newAddOns)
    }
  };

  const onContactFormSortEnd = (oldIndex, newIndex) => {
    var newContactFormFields = cloneDeep(contactFormFields)
    if (oldIndex !== newIndex) {
      const sortedFields = arrayMove(newContactFormFields, oldIndex, newIndex).filter(el => !!el);
      setContactFormFields(sortedFields)
    }
  };

  const removePackage = (package_id) => {
    var selectedPackagesClone = cloneDeep(selectedPackages)
    selectedPackagesClone = selectedPackagesClone.filter(x => x != package_id)
    setSelectedPackages(selectedPackagesClone)
  }

  const removeAddOn = (add_on_id) => {
    var newAddOns = cloneDeep(selectedAddOns)
    var removeCategoryName = null;
    for (var category of newAddOns) {
      category.add_ons = category.add_ons.filter(x => x != add_on_id)
      if (category.add_ons.length == 0) {
        removeCategoryName = category.category_name
      }
    }
    if (!isNull(removeCategoryName)) {
      newAddOns = newAddOns.filter(x => x.category_name != removeCategoryName)
    }
    setSelectedAddOns(newAddOns)
  }

  const addContactFormField = () => {
    setNewFieldError("")
    setIsNewField(true)
    const newField = {
      label: "",
      answer: "",
      type: "text",
      options: [],
      required: false
    }
    setSelectedField(newField)
    setFormFieldModalVisible(true)
    setOptionsText("")
  }

  const onContactFormChecked = (e) => {
    setDisplayContactForm(e.target.checked)
  }

  const onSave = () => {
    isPreviewSubmit = false;
    form.submit()
  }

  const onPreview = () => {
    isPreviewSubmit = true;
    form.submit()
  }

  const handleRemoveField = () => {
    setFormFieldModalVisible(false);
    var newContactFormFields = cloneDeep(contactFormFields)
    newContactFormFields.splice(selectedFieldIndex, 1);
    setContactFormFields(newContactFormFields);
    setSelectedFieldIndex(null)
  }

  const handleCancel = () => {
    setFormFieldModalVisible(false);
    setSelectedFieldIndex(null)
  };

  const handleRowClick = (value, index) => {
    setNewFieldError("")
    setFormFieldModalVisible(true)
    setIsNewField(false)
    setSelectedFieldIndex(index)
    setSelectedField(value)
    setOptionsText(value ? value.options.join("\n") : "")
  };

  const handleSave = () => {
    if (!selectedField.label) {
      setNewFieldError("Please enter a label.")
      return
    } else if (!selectedField.type) {
      setNewFieldError("Please select an input type.")
      return
    } else if (selectedField.type === "select" || selectedField.type === "checkbox" || selectedField.type === "radio") {
      if (selectedField.options.length == 0) {
        setNewFieldError("Please add at least one option.")
        return
      }
    }

    var newContactFormFields = cloneDeep(contactFormFields)
    var newSelectedField = Object.assign({}, selectedField);
    newSelectedField.options = newSelectedField.options.filter(x => x != "")
    if (isNewField) {
      newContactFormFields.push(newSelectedField)
    } else {
      newContactFormFields[selectedFieldIndex] = newSelectedField;
    }
    setContactFormFields(newContactFormFields)
    setFormFieldModalVisible(false);
    setSelectedFieldIndex(null)
  };

  const onOptionChange = (value) => {
    const valueArray = value.split(/\r?\n/)
    const newOptions = valueArray.filter(x => x != "")
    setSelectedField({ ...selectedField, options: newOptions})
    setOptionsText(value)
  }

  const getSystemFieldOption = (type) => {
    var newSystemFields = cloneDeep(systemFields)
    return !isEmpty(newSystemFields.find(x => x.type == type));
  }

  const onSystemFieldChange = (checked, option) => {
    var newSystemFields = cloneDeep(systemFields)
    const included = !isEmpty(newSystemFields.find(x => x.type == option.type));
    if (checked && !included) {
      newSystemFields.push({ type: option.type, required: false })
    } else {
      newSystemFields = newSystemFields.filter(x => x.type != option.type)
    }
    setSystemFields(newSystemFields)
  }

  const getSystemRequiredFieldOption = (type) => {
    var newSystemFields = cloneDeep(systemFields)
    const option = newSystemFields.find(x => x.type == type)
    return !isEmpty(option) ? option.required : false
  }

  const onSystemRequiredFieldChange = (checked, option) => {
    var newSystemFields = cloneDeep(systemFields)
    for (var field of newSystemFields) {
      if (field.type == option.type) {
        field.required = checked
      }
    }
    setSystemFields(newSystemFields)
  }

  const renderInput = (field) => {
    if (field.type == "text") {
      return <Input value={emptyValue} size="large"/>;
    } else if (field.type == "textarea") {
      return <Input.TextArea value={emptyValue} rows={3} size="large"/>;
    } else if (field.type == "select") {
      return (
        <Select
          allowClear
          disabled={false}
          style={{ width: '100%'}}
          placeholder={"Select an option..."}
          optionFilterProp="children"
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          size='large'
        >
          {field.options.map((option, index) => (
            <Select.Option value={option} key={index}>{option}</Select.Option>
          ))}
        </Select>
      )
    } else if (field.type == "checkbox") {
      return field.options.map((option, index) => (
        <div className="mb-10" key={index}>
          <Checkbox checked={false}>{option}</Checkbox>
        </div>
      ))
    } else if (field.type == "radio") {
      return (
        <Radio.Group>
          <Space direction="vertical">
            {field.options.map((option, index) => (
              <Radio key={index} value={option} disabled={false}>{option}</Radio>
            ))}
          </Space>
        </Radio.Group>
      );
    } else if (field.type == "date") {
      return <DatePicker size="large" format="MM/DD/YYYY" value={emptyValue}/>
    }

    return <Input size="large"/>;
    
  }
  
  const renderSaveButton = () => {
    if (isSaving) {
      return (
        <button className="page-title-button small-100" type="button"><LoadingOutlined/></button>
      )
    } else {
      return (
        <button className="page-title-button small-100" type="button" onClick={() => onSave()}>Save</button>
      )
    }
  }

  const renderHeader = () => {
    return (
      <div className="p-20">
        <div>
          <span className="c-blue fw-700 cursor-default" onClick={() => navigate("/admin/pricing-guides")}>
            Pricing Guides
          </span>
          <span className="fs-10 mh-5"><MdArrowForwardIos/></span>
          <span className="cursor-default c-text-gray">
            Edit
          </span>
        </div>
        <div className="fw-700 fs-24 mt-5">{pricingGuide.internal_name}</div>
      </div>
    )
  }

  const renderPackage = (item, index) => {
    return (
      <div className="border-sortable bg-white p-15 mt-10" key={index}>
        <Row gutter={[10,10]} align="middle">
          <Col flex={1}>
            <div className="fw-700">{ item.package_name }</div>
            <div className="c-text-gray">
              { formatCurrencyString(item.price, currency.code) }
            </div>
          </Col>
          <Col flex={0}>
            <div className="display-flex" onClick={() => removePackage(item.package_id)}>
              <FiTrash style={{ fontSize: 18, color: '#CCC'}}/>
            </div>
          </Col>
          <Col flex={0}>
            <div className="display-flex">
              <MdDragIndicator style={{ fontSize: 24, color: '#CCC'}}/>
            </div>
          </Col>
        </Row>
      </div>
    )
  }

  const renderAddOn = (item, index) => {
    return (
      <div className="border-sortable bg-white p-15 mt-10" key={index}>
        <Row gutter={[10,10]} align="middle">
          <Col flex={1}>
            <div className="fw-700">{ item.add_on_name }</div>
            <div className="c-text-gray">
              { formatCurrencyString(item.price, currency.code) }
            </div>
          </Col>
          <Col flex={0}>
            <div className="display-flex" onClick={() => removeAddOn(item.add_on_id)}>
              <FiTrash style={{ fontSize: 18, color: '#CCC'}}/>
            </div>
          </Col>
          <Col flex={0}>
            <div className="display-flex">
              <MdDragIndicator style={{ fontSize: 24, color: '#CCC'}}/>
            </div>
          </Col>
        </Row>
      </div>
    )
  }

  const renderContactFormField = (item, index) => {
    const label = item.required ? `${item.label} (required)` : item.label
    return (
      <div onClick={() => handleRowClick(item, index)} className="border-sortable bg-white p-15 mt-10" key={index}>
        <Row gutter={[10,10]} align="middle">
          <Col flex={1}>
            { renderFormLabel(label)}
            <div className="c-text-gray">
              { renderInput(item) }
            </div>
          </Col>
          <Col flex={0}>
            <div className="display-flex">
              <MdDragIndicator style={{ fontSize: 24, color: '#CCC'}}/>
            </div>
          </Col>
        </Row>
      </div>
    )
  }

  const SortableItem = sortableElement(({value, itemIndex}) => (
    <li className="">
      {renderPackage(value, itemIndex)}
    </li>
  ));

  const AddOnSortableItem = sortableElement(({value, itemIndex}) => (
    <li className="">
      {renderAddOn(value, itemIndex)}
    </li>
  ));

  const ContactFormSortableItem = sortableElement(({value, itemIndex}) => (
    <li className="">
      {renderContactFormField(value, itemIndex)}
    </li>
  ));

  const SortableList = SortableContainer(({items, sectionIndex}) => {
    return (
      <ul className="question-row-container">
        {items.map((value, index) => (
          <SortableItem key={`item-${index}`} index={index} itemIndex={index} value={value} />
        ))}
      </ul>
    );
  });

  const AddOnSortableList = SortableContainer(({items, sectionIndex}) => {
    return (
      <ul className="question-row-container">
        {items.map((value, index) => (
          <AddOnSortableItem key={`item-${index}`} index={index} itemIndex={index} value={value} />
        ))}
      </ul>
    );
  });

  const ContactFormSortableList = SortableContainer(({items, sectionIndex}) => {
    return (
      <ul className="question-row-container">
        {items.map((value, index) => (
          <ContactFormSortableItem key={`item-${index}`} index={index} itemIndex={index} value={value} />
        ))}
      </ul>
    );
  });

  const renderAddPackageModal = () => {
    return (
      <Modal visible={isAddPackageModalVisible} footer={null} closable={false} wrapClassName="rounded-modal">
        <Row align="middle">
          <Col flex={1}>
            <div className="fw-700 fs-18">Add Packages</div>
          </Col>
          <Col>
            <div className="display-flex" onClick={() => setAddPackageModalVisible(false)}><MdOutlineClose size={30} color={"#CCC"}/></div>
          </Col>
        </Row>
        <div className="mt-20 border-sortable radius-8 ph-10">
          { packages.map((x,i) => (
            <Row gutter={[10,10]} className={`ph-10 pv-15 ${i < packages.length - 1 ? "b-border" : ""}`} key={i}>
              <Col flex={1}>
                { x.package_name }
              </Col>
              { !isPackageSelected(x.package_id) && (
                <Col flex={0}>
                  <div className="blue-link" onClick={() => onPackageSelect(x.package_id)}>Add</div>
                </Col>
              )}
            </Row>
          ))}
        </div>
        <div className="admin-modal-footer">
          <button className="primary-button" onClick={() => setAddPackageModalVisible(false)}>Done</button>
        </div>
      </Modal>
    )
  }

  const renderAddOnCategory = (category, index) => {
    return (
      <div key={index}>
        <div className="fw-700 mb-5 mt-15 ml-5">{ category.category_name }</div>
        <div className="border-sortable radius-8 ph-10">
          { category.add_ons.map((x,i) => (
              <Row gutter={[10,10]} className={`ph-10 pv-15 ${i < category.add_ons.length - 1 ? "b-border" : ""}`} key={i}>
                <Col flex={1}>
                  { x.add_on_name }
                </Col>
                { !isAddOnSelected(x.add_on_id) && (
                  <Col flex={0}>
                    <div className="blue-link" onClick={() => onAddOnSelect(x)}>Add</div>
                  </Col>
                )}
              </Row>
            ))}
          </div>
      </div>
    )
  }

  const renderAddAddOnModal = () => {
    return (
      <Modal visible={isAddAddOnModalVisible} footer={null} closable={false} wrapClassName="rounded-modal">
        <Row align="middle">
          <Col flex={1}>
            <div className="fw-700 fs-18">Add Add-Ons</div>
          </Col>
          <Col>
            <div className="display-flex" onClick={() => setAddAddOnModalVisible(false)}><MdOutlineClose size={30} color={"#CCC"}/></div>
          </Col>
        </Row>
        <div className="mt-20">
          { getAddOnsByCategory().map((x,i) => renderAddOnCategory(x,i))}
        </div>
        <div className="admin-modal-footer">
          <button className="primary-button" onClick={() => setAddAddOnModalVisible(false)}>Done</button>
        </div>
      </Modal>
    )
  }

  const renderSelectedAddOnCategory = (category, index) => {
    const selectedAddOnData = cloneDeep(category.add_ons).map((addOnId,i) => {
      return addOns.find(x => x.add_on_id == addOnId)
    })
    return (
      <div key={index}>
        <div className="fw-700 fs-16 mb-5 mt-15 ml-5">{ category.category_name }</div>
        <AddOnSortableList items={selectedAddOnData} onSortEnd={({ oldIndex, newIndex }) => onAddOnSortEnd(oldIndex, newIndex, category.category_name)} helperClass="pricing-guide-row-dragging" pressDelay={100} lockAxis="y"/>
      </div>
    )
  }

  const renderContactFormContent = () => {
    return (
      <div>
        <div className="fs-14 mb-15 bg-gray radius-8 p-10 mt-15">Checking the box below will display a "Next Steps" section at the bottom of your pricing guide that allows clients to fill out a contact form and request a booking.</div>
        { renderCheckboxField("Display contact form", "display_contact_form", onContactFormChecked) }
        { isDisplayContactForm && (
            <div className="mt-10">
              { renderFormLabel("Send booking requests to", "This is the person who will receive a booking request if the client decides to take next steps and fill out the contact form.") }
              { renderSearchSelectField("", "to_email", "Select staff member", adminUsers, true) }
              <div className="fw-700 fs-16 mb-5 mt-20 t-border pt-20">System Fields</div>
              <div className="fs-14 mb-20 bg-gray radius-8 p-10 mt-5">Select the fields below that you would like to show on your contact form and whether or not they should be required. These will automatically populate when you create an event.</div>
              <div className="mb-10 p-10 border-sortable">
                <Row>
                  <Col xs={12}>
                    <Checkbox checked={true} disabled={true}>Name</Checkbox>
                  </Col>
                  <Col xs={12}>
                    <Checkbox checked={true} disabled={true}>Required</Checkbox>
                  </Col>
                </Row>
              </div>
              <div className="mb-10 p-10 border-sortable">
                <Row>
                  <Col xs={12}>
                    <Checkbox checked={true} disabled={true}>Email</Checkbox>
                  </Col>
                  <Col xs={12}>
                    <Checkbox checked={true} disabled={true}>Required</Checkbox>
                  </Col>
                </Row>
              </div>
              <div className="mb-10 p-10 border-sortable">
                <Row>
                  <Col xs={12}>
                    <Checkbox checked={true} disabled={true}>Event Date</Checkbox>
                  </Col>
                  <Col xs={12}>
                    <Checkbox checked={true} disabled={true}>Required</Checkbox>
                  </Col>
                </Row>
              </div>
              { systemFieldOptions.map((x,i) => (
                <div className="mb-10 p-10 border-sortable" key={i}>
                  <Row>
                    <Col xs={12}>
                      <Checkbox checked={getSystemFieldOption(x.type)} onChange={(e) => onSystemFieldChange(e.target.checked, x)}>{x.name}</Checkbox>
                    </Col>
                    <Col xs={12}>
                      { getSystemFieldOption(x.type) && (
                        <Checkbox checked={getSystemRequiredFieldOption(x.type)} onChange={(e) => onSystemRequiredFieldChange(e.target.checked, x)}>Required</Checkbox>
                      )}
                    </Col>
                  </Row>
                </div>
              ))}
              <div className="fw-700 fs-16 mb-5 mt-20 t-border pt-20">Custom Fields</div>
              <div className="fs-14 mb-20 bg-gray radius-8 p-10 mt-5">Add any custom fields you would like to include on your contact form.</div>
              <ContactFormSortableList items={contactFormFields} onSortEnd={({ oldIndex, newIndex }) => onContactFormSortEnd(oldIndex, newIndex)} helperClass="pricing-guide-row-dragging" pressDelay={150} lockAxis="y"/>
              { contactFormFields.length == 0 ? (
                <div className="text-center p-20 border-sortable mb-15 mt-15">
                  <div className="blue-link" onClick={() => addContactFormField()}>+ Add custom field</div>
                </div>
              ) : (
                <div className="p-15">
                  <div className="blue-link" onClick={() => addContactFormField()}>+ Add custom field</div>
                </div>
              )}
            </div>
          )}
      </div>
    )
  }

  const renderIcon = (type) => {
    if (type === "text") {
      return <BsInputCursorText/>;
    } else if (type === "textarea") {
      return <BsTextareaResize/>
    } else if (type === "checkbox") {
      return <BsCheckSquare/>
    } else if (type === "radio") {
      return <BsRecordCircle/>
    } else if (type === "select") {
      return <BsCaretDownSquare/>
    } else if (type === "date") {
      return <BsCalendar/>
    }
    return null;
  }

  const renderInputType = (value, text) => {
    const styleClass = selectedField.type == value ? "bg-blue c-white" : "bg-gray"
    return (
      <Col xs={12} sm={8}>
        <div className={`p-15 text-center radius-8 cursor-default ${styleClass}`} onClick={() => setSelectedField({ ...selectedField, type: value})}>
          <div className="fs-24">
            { renderIcon(value) }
          </div>
          {text}
        </div>
      </Col>
    )
  }

  const renderInputTypes = () => {
    return (
      <Row gutter={[10,10]}>
        { renderInputType("text", "Text Box") }
        { renderInputType("textarea", "Text Area") }
        { renderInputType("checkbox", "Checkboxes") }
        { renderInputType("radio", "Radio Buttons") }
        { renderInputType("select", "Dropdown") }
        { renderInputType("date", "Date") }
      </Row>
    )
  }

  const renderModalOptions = () => {
    if (selectedField.type === "select" || selectedField.type === "checkbox" || selectedField.type === "radio") {
      return (
        <Col xs={24}>
          { renderFormLabel("Options (one per line)") }
          <Input.TextArea 
            rows={3}
            defaultValue={selectedField ? selectedField.options.join("\n") : ""}
            value={optionsText}
            onChange={(e) => onOptionChange(e.target.value)}
            size="large"/>
        </Col>
      )
    }
  }

  const renderAddEditFieldModal = () => {
    return (
      <Modal visible={isFormFieldModalVisible} footer={null} onCancel={() => setFormFieldModalVisible(false)} width={500} closable={false} maskClosable={false} wrapClassName="rounded-modal">
        <Row align="middle">
          <Col flex={1}>
            <div className="fw-700 fs-18">{isNewField ? "New Field" : "Edit Field"}</div>
          </Col>
          <Col>
            <div className="display-flex" onClick={() => setFormFieldModalVisible(false)}><MdOutlineClose size={30} color={"#CCC"}/></div>
          </Col>
        </Row>
        <Row gutter={[10,10]} className="mt-20">
          <Col xs={24}>
            { renderFormLabel("Label") }
            <Input size="large" value={selectedField.label} onChange={(e) => setSelectedField({ ...selectedField, label: e.target.value})}/>
          </Col>
          <Col xs={24}>
            { renderFormLabel("Input Type") }
            { renderInputTypes() }
          </Col>
          { renderModalOptions() }
          <div className="ml-5 mt-5">
            <Checkbox checked={selectedField.required} onChange={(e) => setSelectedField({ ...selectedField, required: e.target.checked})}>Required field</Checkbox>
          </div>
          { newFieldError && (
            <Col xs={24}>
              <div className="text-center c-red mt-5">{newFieldError}</div>
            </Col>
          )}
          <Col xs={24}>
            <Row>
              <Col flex="1">
                { !isNewField && (
                  <Button className="admin-small-button secondary mt-20" onClick={handleRemoveField}>Remove</Button>
                )}
              </Col>
              <Col>
                <div className="mt-20 text-right">
                  <Button className="admin-small-button secondary" onClick={handleCancel}>Cancel</Button>
                  <Button className="admin-small-button ml-10" onClick={handleSave}>
                    Save
                  </Button>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Modal>
    )
  }

  const renderContent = () => {
    const selectedPackageData = cloneDeep(selectedPackages).map((packageId,i) => {
      return packages.find(x => x.package_id == packageId)
    })
    return (
      <FloatingContainer className="ph-20 mb-80" verticalPadding={20} maxWidth={800}>
        <Form form={form} layout="vertical" name="employee" onFinish={onSubmit}>
          <div className="shadow-card p-20 mb-15">
            <div className="fs-20 fw-700 mb-20 line-1">Details</div>
              <Row gutter={[10,10]}>
                <Col xs={24} md={18}>
                  { renderFormLabel("Internal Name", "This name is used for internal purposes only. It is not visible to the client.") }
                  { renderInputField("", "internal_name", true, false, "Ex. Pricing Guide, Sales Guide, etc.") }
                </Col>
                <Col xs={24} md={18}>
                  { renderFormLabel("Display Name", 'This name will be visible to the client. (Ex. "Pricing Guide", "Sales Guide", etc.)') }
                  { renderInputField("", "display_name", true, false, "Ex. Pricing Guide, Sales Guide, etc.") }
                </Col>
              </Row>
          </div>
          <div className="shadow-card p-20 mb-15">
            <div className="fs-20 fw-700 mb-20 line-1">Packages</div>
            <div className="fs-14 mb-20 bg-gray radius-8 p-10">Select the packages you would like to include in this pricing guide and reorder them if needed.</div>
            <SortableList items={selectedPackageData} onSortEnd={({ oldIndex, newIndex }) => onPackageSortEnd(oldIndex, newIndex)} helperClass="pricing-guide-row-dragging" pressDelay={100} lockAxis="y"/>
            <div className="blue-link mt-15" onClick={() => setAddPackageModalVisible(true)}>+ Add Packages</div>
          </div>
          <div className="shadow-card p-20 mb-15">
            <div className="fs-20 fw-700 mb-20 line-1">Add-Ons</div>
            <div className="fs-14 mb-20 bg-gray radius-8 p-10">Select the add-ons you would like to include in this pricing guide and reorder them if needed.</div>
            { selectedAddOns.map((x,i) => renderSelectedAddOnCategory(x,i))}
            <div className="blue-link mt-15" onClick={() => setAddAddOnModalVisible(true)}>+ Add Add-Ons</div>
          </div>
          <div className="shadow-card p-20 mb-15">
            <div className="fs-20 fw-700 mb-20 line-1">Contact Form</div>
            { renderContactFormContent() }
          </div>
          <div className="sticky-footer p-0">
            <div className="p-15 bg-white t-border">
              { renderSaveButton() }
              <div className="blue-link ml-20" onClick={() => onPreview()}>Preview</div>
            </div>
          </div>
        </Form>
        { renderAddPackageModal() }
        { renderAddAddOnModal() }
        { renderAddEditFieldModal() }
      </FloatingContainer>
    )
  }

  if (isLoading) {
    return <LoadingSpinner/>
  }

  return (
    <AdminContent header={renderHeader()} body={renderContent()} headerHeight={96}/>
  );
}

export default EditPricingGuidePage;

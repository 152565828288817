import React, { useEffect, useState, useContext } from "react";
import { Row, Col, Menu, Dropdown } from 'antd';
import moment from "moment";
import { useNavigate } from 'react-router-dom';
import { cloneDeep, isArray, isEmpty } from 'lodash';
import AdminContent from '../../components/adminContent';
import AppContext from '../../app/context';
import LoadingSpinner from '../../components/loading';
import StatusTag from '../../components/statusTag';
import useApi from '../../hooks/useApi';
import InvoiceStatus from '../../constants/invoiceStatus';
import { formatCurrencyString } from '../../helpers/contractHelper';
import { formatDateShort } from '../../helpers/dateHelper';
import { getEventContracts, getEventInvoices, getAccountSettingsForEvent, getEventInvoicesV2 } from '../../api';
import { MdOutlineMoreHoriz, MdOutlineAttachMoney } from "react-icons/md";
import { FaSignature } from "react-icons/fa";
import { FiExternalLink } from "react-icons/fi";
import { EyeOutlined } from '@ant-design/icons';
import FloatingContainer from '../../components/floatingContainer'
import useDocumentTitle from '../../hooks/useDocumentTitle';
const BASE_URL = process.env.REACT_APP_BASE_URL;

const DocumentsPage = () => {

  const [isLoading, setLoading] = useState(true);
  const [eventContracts, setEventContracts] = useState([]);
  const [eventInvoices, setEventInvoices] = useState([]);
  const [invoices, setInvoices] = useState([]);
  const [accountSettings, setAccountSettings] = useState({});

  useDocumentTitle("Documents")
  const { auth } = useContext(AppContext);
  const [sendRequest] = useApi()

  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
    refreshPage()
  }, []);

  const refreshPage = async () => {
    try {
      const eventContractResults = await sendRequest(getEventContracts(auth.user.event_id));
      setEventContracts(eventContractResults)

      const eventInvoicesResults = await sendRequest(getEventInvoices(auth.user.event_id));
      setEventInvoices(eventInvoicesResults)

      const invoicesResults = await sendRequest(getEventInvoicesV2(auth.user.event_id));
      setInvoices(invoicesResults)

      const accountSettingsResults = await sendRequest(getAccountSettingsForEvent(auth.user.event_id));
      setAccountSettings(accountSettingsResults)
    } finally {
      setLoading(false)
    }
  }

  const renderHeader = () => {
    return (
      <div className="fs-24 fw-700 p-20">Documents</div>
    )
  }

  const contractMenu = (item) => {
    const signersClone = cloneDeep(item.signers)
    const currentSigner = isArray(signersClone) ? signersClone.find(x => x.user_id == auth.user.user_id) : {}
    return (
      <Menu>
        <Menu.Item className="ph-15 pv-10">
          <div onClick={() => navigate(`/contract/sign/${currentSigner.event_contract_signer_id}`)}>
            <EyeOutlined style={{ marginRight: 8}}/> { currentSigner.signed ? "View Contract" : "View / Sign Contract"}
          </div>
        </Menu.Item>
      </Menu>
    )
  };

  const invoiceMenu = (item) => {
    return (
      <Menu>
        <Menu.Item className="ph-15 pv-10">
          <div onClick={() => onOpenInvoice(item)}>
            <EyeOutlined style={{ marginRight: 8}}/> { item.status == InvoiceStatus.PAID ? "View Invoice" : "View / Pay Invoice"}
          </div>
        </Menu.Item>
      </Menu>
    )
  };

  const invoiceMenuV2 = (item) => {
    return (
      <Menu>
        <Menu.Item className="ph-15 pv-10">
          <div onClick={() => onViewInvoice(item)}>
            <EyeOutlined style={{ marginRight: 8}}/> { item.status == InvoiceStatus.PAID ? "View Invoice" : "View / Pay Invoice"}
          </div>
        </Menu.Item>
        <Menu.Item className="ph-15 pv-10">
          <div onClick={() => onViewInvoicePDF(item)}>
            <FiExternalLink style={{ marginRight: 8}}/> View / Download PDF
          </div>
        </Menu.Item>
      </Menu>
    )
  };

  const onOpenInvoice = (invoice) => {
    window.open(`${BASE_URL}/invoice/${invoice.invoice_id}`, "_blank")
  }

  const onViewInvoice = (invoice) => {
    window.open(`${BASE_URL}/v/invoice/${invoice.invoice_id}`, "_blank")
  }

  const onViewInvoicePDF = (invoice) => {
    window.open(`${BASE_URL}/pdfs/v2/invoice/${invoice.invoice_id}`, "_blank")
  }

  const renderContract = (contract, index) => {
    return (
      <div className="b-border p-20" key={index}>
        <Row  gutter={[15,15]} align="middle">
          <Col>
            <div className="display-flex">
              <div className="user-profile-circle"><FaSignature/></div>
            </div>
          </Col>
          <Col flex={1}>
            <div className="fs-14 fw-700 mb-2">{ contract.event_contract_name }</div>
            <span className="bg-light-blue c-blue pv-5 ph-10 fs-10 fw-600">{!isEmpty(contract.status) ? contract.status.replace("_", " ") : ""}</span>
          </Col>
          <Col flex={0}>
            <div className="display-flex mr-5">
              <Dropdown overlay={contractMenu(contract, index)} placement="bottomRight" trigger="click">
                <div className="dots-container">
                  <MdOutlineMoreHoriz style={{ fontSize: 24, color: '#999'}}/>
                </div>
              </Dropdown>
            </div>
          </Col>
        </Row>
        <div className="bg-gray radius-8 mt-10 pv-15 ph-20">
          <Row align="middle" className="">
            <Col xs={12}>
              <div className="fs-14 fw-700">Created on</div>
              <div className="fs-14 fw-500">{!isEmpty(contract.create_datetime) ? formatDateShort(contract.create_datetime, accountSettings) : "N/A"}</div>
            </Col>
            <Col xs={12}>
              <div className="fs-14 fw-700">Expires on</div>
              <div className="fs-14 fw-500">{!isEmpty(contract.expiration_datetime) ? formatDateShort(contract.expiration_datetime, accountSettings, true) : "N/A"}</div>
            </Col>
          </Row>
        </div>
      </div>
    )
  }

  const renderStatus = (invoice) => {
    if (invoice.status == InvoiceStatus.PAID) {
      return (
        <span className="bg-light-blue c-blue pv-5 ph-10 fs-10 fw-600 ml-10">{!isEmpty(invoice.status) ? invoice.status.replace("_", " ") : ""}</span>
      )
    }
    return null
  }

  const renderInvoice = (invoice, index) => {
    return (
      <div className="b-border p-20" key={index}>
        <Row  gutter={[15,15]} align="middle">
          <Col>
            <div className="display-flex">
              <div className="user-profile-circle"><MdOutlineAttachMoney/></div>
            </div>
          </Col>
          <Col flex={1}>
            <div className="fs-14 fw-700 mb-2">Invoice #{invoice.invoice_number} {renderStatus(invoice)}</div>
            <div className="fs-14 line-1-2">{formatCurrencyString(invoice.amount_due, invoice.currency)}</div>
          </Col>
          <Col flex={0}>
            <div className="display-flex mr-5">
              <Dropdown overlay={invoiceMenu(invoice, index)} placement="bottomRight" trigger="click">
                <div className="dots-container">
                  <MdOutlineMoreHoriz style={{ fontSize: 24, color: '#999'}}/>
                </div>
              </Dropdown>
            </div>
          </Col>
        </Row>
        <div className="bg-gray radius-8 mt-10 pv-15 ph-20">
          <Row align="middle" className="">
            <Col xs={12}>
              <div className="fs-14 fw-700">Invoice Date</div>
              <div className="fs-14 fw-500">{!isEmpty(invoice.invoice_date) ? formatDateShort(invoice.invoice_date, accountSettings) : "N/A"}</div>
            </Col>
            <Col xs={12}>
              <div className="fs-14 fw-700">Due Date</div>
              <div className="fs-14 fw-500">{!isEmpty(invoice.due_date) ? formatDateShort(invoice.due_date, accountSettings) : "N/A"}</div>
            </Col>
          </Row>
        </div>
      </div>
    )
  }

  const renderInvoiceV2 = (invoice, index) => {
    return (
      <div className="b-border p-20" key={index}>
        <Row  gutter={[15,15]} align="middle">
          <Col>
            <div className="display-flex">
              <div className="user-profile-circle"><MdOutlineAttachMoney/></div>
            </div>
          </Col>
          <Col flex={1}>
            <div className="fs-14 fw-700 mb-2">Invoice #{invoice.invoice_number} <StatusTag status={invoice.status} className="ml-5"/></div>
            <div className="fs-14 line-1-2">{formatCurrencyString(invoice.total, invoice.currency)}</div>
          </Col>
          <Col flex={0}>
            <div className="display-flex mr-5">
              <Dropdown overlay={invoiceMenuV2(invoice, index)} placement="bottomRight" trigger="click">
                <div className="dots-container">
                  <MdOutlineMoreHoriz style={{ fontSize: 24, color: '#999'}}/>
                </div>
              </Dropdown>
            </div>
          </Col>
        </Row>
        <div className="bg-gray radius-8 mt-10 pv-15 ph-20">
          <Row align="middle" className="">
            <Col xs={12}>
              <div className="fs-14 fw-700">Invoice Date</div>
              <div className="fs-14 fw-500">{!isEmpty(invoice.create_datetime) ? formatDateShort(invoice.create_datetime, accountSettings, true) : "N/A"}</div>
            </Col>
            <Col xs={12}>
              <div className="fs-14 fw-700">Due Date</div>
              <div className="fs-14 fw-500">{!isEmpty(invoice.due_date) ? formatDateShort(invoice.due_date, accountSettings, true) : "N/A"}</div>
            </Col>
          </Row>
        </div>
      </div>
    )
  }

  const renderInvoices = () => {
    if (eventInvoices.length > 0) {
      return eventInvoices.map((x,i) => renderInvoice(x,i)) 
    } else if (invoices.length > 0) {
      return invoices.map((x,i) => renderInvoiceV2(x,i)) 
    } else {
      return (
        <div className="p-15 text-center">
          <div className="bg-gray radius-8 p-20 text-center">
            <div>No invoices have been created for this event.</div>
          </div>
        </div>
      )
    }
  }

  const renderContent = () => {
    if (isLoading) {
      return <LoadingSpinner/>
    }
    return (
      <FloatingContainer className="ph-20 mb-20" verticalPadding={20} maxWidth={600}>
        <div className="shadow-card mb-20">
          <Row align="middle" className="mh-20 pv-15 b-border">
            <Col flex={1}>
              <div className="fs-18 fw-700">Contracts</div>
            </Col>
          </Row>
          { eventContracts.map((x,i) => renderContract(x,i)) }
          { eventContracts.length == 0 && (
            <div className="p-15 text-center">
              <div className="bg-gray radius-8 p-20 text-center">
                <div>No contracts have been created for this event.</div>
              </div>
            </div>
          )}
        </div>
        <div className="shadow-card mb-20">
          <Row align="middle" className="mh-20 pv-15 b-border">
            <Col flex={1}>
              <div className="fs-18 fw-700">Invoices</div>
            </Col>
          </Row>
          { renderInvoices() }
        </div>
      </FloatingContainer>
    )
  }

  return (
    <AdminContent header={renderHeader()} body={renderContent()}/>
  );
}

export default DocumentsPage;

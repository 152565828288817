import React, { useEffect, useState, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import moment from 'moment';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { FiDownload, FiPrinter } from "react-icons/fi";
import LoadingSpinner from '../../components/loading';
import QuestionnairePDF from '../../pdfs/questionnairePDF';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import { formatEventDateLong, getDatePickerFormat } from '../../helpers/dateHelper';

import { getQuestionnairePreview } from '../../api';

const QuestionnairePage = () => {

  const [isLoading, setLoading] = useState(true);
  const [questionnaireName, setQuestionnaireName] = useState("");
  const [eventName, setEventName] = useState("");
  const [eventDate, setEventDate] = useState("");
  const [sections, setSections] = useState([]);
  const [accountSettings, setAccountSettings] = useState({});

  useDocumentTitle("Questionnaire")
  const navigate = useNavigate();
  const params = useParams();

  useEffect(() => {
    window.scrollTo(0, 0)
    const questionnaireId = params.id;
    if (!questionnaireId) {
      navigate("/", { replace: true })
    } else {
      loadPage()
    }
  }, [])

  const loadPage = async () => {
    try {
      const response = await getQuestionnairePreview(params.id);
      setEventName(response.data.event_name)
      setQuestionnaireName(response.data.questionnaire_name)
      setEventDate(response.data.event_date)
      setSections(response.data.sections);
      setAccountSettings(response.data.account_settings)
      setLoading(false)
    } catch {}
  }

  const renderQuestionHelperText = (question) => {
    if (question.description) {
      return (
        <div className="line-breaks fs-12 bg-gray p-10 radius-8 mt-5 mb-10">{question.description}</div>
      )
    }
    return null
  }

  const renderQuestion = (question, index) => {
    if (question.type == "checkbox") {
      var answerArray = question.answer.split("::")
      answerArray = answerArray.filter(x => x != "");
      return (
        <div className="pt-10" key={index}>
          <div className="fw-700">{question.label}</div>
          { renderQuestionHelperText(question) }
          { answerArray.map((x,i) => (
            <div key={i} className="fw-500">{x}</div>
          ))}
          { answerArray.length == 0 && (
            <div className="fw-500">--</div>
          )}
        </div>
      )
    } else if (question.type == "date") {
      if (moment(question.answer, "YYYY-MM-DD", true).isValid()) {
        answer = moment(question.answer, "YYYY-MM-DD").format(getDatePickerFormat(accountSettings))
      } else {
        answer = "--"
      }
      return (
        <div className="pt-10" key={index}>
          <div className="fw-700">{question.label}</div>
          { renderQuestionHelperText(question) }
          <div className="fw-500 line-breaks">{answer}</div>
        </div>
      )
    } else {
      var answer = ""
      if (!question.answer) {
        answer = "--"
      } else {
        answer = question.answer
      }  
      return (
        <div className="pt-10" key={index}>
          <div className="fw-700">{question.label}</div>
          { renderQuestionHelperText(question) }
          <div className="fw-500 line-breaks">{answer}</div>
        </div>
      )
    }
  }

  const renderSection = (section, index) => {
    return (
      <div key={index}>
        <div className="mt-30 mb-10 pb-5 pl-5" style={{ borderBottom: "3px solid #e8e8e8"}}>
          <div className="fs-18 fw-700">{ section.name }</div>
          { section.description && (
            <div className="c-text-gray line-breaks">{ section.description }</div>
          )}
        </div>
        <div className="pl-10">
          { section.questions.map((x,i) => renderQuestion(x,i))}
        </div>
      </div>
    )
  }

  const renderContent = () => {
    return (
      <div>
        <div className="toolbar-container hide-print">
          <PDFDownloadLink document={ renderDocument() } fileName={`questionnaire-${moment(eventDate, "YYYY-MM-DD").format("YYYYMMDD")}.pdf`}>
            <div className="toolbar-button" onClick={() => {}}>
              <FiDownload style={{ fontSize: 20, color: '#536DFE', marginRight: 8}}/> Download PDF
            </div>
          </PDFDownloadLink>
          <div className="toolbar-button" onClick={() => window.print()}>
            <FiPrinter style={{ fontSize: 20, color: '#536DFE', marginRight: 8}}/> Print
          </div>
        </div>
        <div style={{ margin: "0 auto", paddingTop: 30, paddingBottom: 30, maxWidth: 1000}}>
          <div className="print-card">
            <div className="text-center fs-24 fw-700">{ eventName }</div>
            <div className="text-center fs-16 c-text-gray">{formatEventDateLong(eventDate, accountSettings)}</div>
            <div className="text-center fs-16 fw-700 mt-10">Questionnaire: {questionnaireName}</div>
            <div className="mt-30 mb-10">
              { sections.map((x,i) => renderSection(x,i))}
            </div>
          </div>
        </div>
      </div>
    )
  }

  const renderDocument = () => {
    return <QuestionnairePDF questionnaireName={questionnaireName} sections={sections} eventName={eventName} eventDate={eventDate} accountSettings={accountSettings}/>
  }

  if (isLoading) {
    return (
      <div className="mt-80">
        <LoadingSpinner/>
      </div>
    )
  }

  return (
    <div className="bg-gray" style={{ minHeight: '100vh'}}>
      { renderContent() }
    </div>
  );
}

export default QuestionnairePage;

import React, { useEffect, useState, useRef } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {SortableContainer, sortableElement} from 'react-sortable-hoc';
import arrayMove from 'array-move';
import { isUndefined, cloneDeep, isEmpty } from "lodash";
import qs from "qs";
import useApi from '../../../hooks/useApi';
import useAccountSettings from '../../../hooks/useAccountSettings';
import { 
  renderFormLabel,
} from '../../../components/formFields'
import { Row, Col, Input, Select, Modal, Button, Dropdown, Menu, Checkbox, Radio, DatePicker, Space } from 'antd';
import { getQuestionnaire, updateQuestionnaire } from '../../../api';
import LoadingSpinner from '../../../components/loading';
import AdminContent from '../../../components/adminContent';
import FloatingContainer from '../../../components/floatingContainer'
import { PlusOutlined, EditOutlined, EllipsisOutlined, DeleteOutlined, UpOutlined, DownOutlined } from '@ant-design/icons';
import { MdDragIndicator, MdOutlineClose, MdArrowForwardIos } from "react-icons/md";
import { BsCheckSquare, BsInputCursorText, BsTextareaResize, BsRecordCircle, BsCaretDownSquare, BsCalendar } from "react-icons/bs";
import { FiRefreshCw, FiCheckCircle, FiEye } from "react-icons/fi";
import emptyStateImage from '../../../images/empty-state-icon.png';
import useDocumentTitle from '../../../hooks/useDocumentTitle';
import { getDatePickerFormat } from '../../../helpers/dateHelper';

const NewQuestionnairePage = () => {

  const [isLoading, setLoading] = useState(true);
  const [isSaving, setSaving] = useState(false);
  const [isNewSectionModalVisible, setIsNewSectionModalVisible] = useState(false);
  const [isAddEditModalVisible, setAddEditModalVisible] = useState(false);
  const [newSectionName, setNewSectionName] = useState(null);
  const [newSectionDescription, setNewSectionDescription] = useState(null);
  const [isNewQuestion, setIsNewQuestion] = useState(false);
  const [selectedSectionIndex, setSelectedSectionIndex] = useState(null);
  const [selectedQuestionIndex, setSelectedQuestionIndex] = useState(null);
  const [selectedQuestion, setSelectedQuestion] = useState({});
  const [sections, setSections] = useState([]);
  const [questionnaireName, setQuestionnaireName] = useState("");
  const [internalQuestionnaireName, setInternalQuestionnaireName] = useState("");
  const [selectedTab, setSelectedTab] = useState("questions");
  const [newQuestionError, setNewQuestionError] = useState("");
  const [optionsText, setOptionsText] = useState("");
  const [isEditingTitle, setEditingTitle] = useState(false);

  useDocumentTitle("Questionnaire Details")
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const inputRef = useRef(null);

  const [sendRequest] = useApi()
  const [accountSettings] = useAccountSettings()

  const questionnaireId = params.id;

  useEffect(() => {
    window.scrollTo(0, 0);
    const queryStrings = qs.parse(location.search, { ignoreQueryPrefix: true })
    if (queryStrings.tab) {
      setSelectedTab(queryStrings.tab)
    }
    if (!isUndefined(questionnaireId)) {
      refreshPage();
    } else {
      setLoading(false)
    }
  }, []);

  const refreshPage = async () => {
    try {
      const questionResults = await sendRequest(getQuestionnaire(questionnaireId));
      setQuestionnaireName(questionResults.questionnaire_name)
      setInternalQuestionnaireName(questionResults.internal_questionnaire_name)
      setSections(questionResults.sections);

      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  const saveQuestionnaire = async (newSections) => {
    setSaving(true)
    setSections(newSections);
    try {
      var body = {
        questionnaire_name: questionnaireName,
        internal_questionnaire_name: internalQuestionnaireName,
        sections: newSections
      }
      await sendRequest(updateQuestionnaire(questionnaireId, body))
      setSaving(false)
    } catch {
      setSaving(false)
    }
  }

  const menu = (section, index) => {
    return (
      <Menu>
        <Menu.Item>
          <div onClick={() => handleEditSection(section, index)}>
            <EditOutlined style={{ marginRight: 8}}/> Edit Section
          </div>
        </Menu.Item>
        { index > 0 && (
          <Menu.Item>
            <div onClick={() => moveSection("up", index)}>
              <UpOutlined style={{ marginRight: 8}}/> Move Section Up
            </div>
          </Menu.Item>
        )}
        { index < sections.length - 1 && (
          <Menu.Item>
            <div onClick={() => moveSection("down", index)}>
              <DownOutlined style={{ marginRight: 8}}/> Move Section Down
            </div>
          </Menu.Item>
        )}
        <Menu.Item>
          <div onClick={() => handleSectionDelete(index)}>
            <DeleteOutlined style={{ marginRight: 8}}/> Delete Section
          </div>
        </Menu.Item>
      </Menu>
    )
  };

  const handleCancel = () => {
    setAddEditModalVisible(false);
    setSelectedQuestionIndex(null)
    setSelectedSectionIndex(null)
  };

  const handleNewSectionCancel = () => {
    setNewSectionName("")
    setNewSectionDescription("")
    setIsNewSectionModalVisible(false);
  };

  const handleNewSectionSave = () => {
    setIsNewSectionModalVisible(false);
    var newSections = cloneDeep(sections)
    const newDescription = !isEmpty(newSectionDescription) ? newSectionDescription : null
    if (selectedSectionIndex == null) {
      newSections.push({ name: newSectionName, description: newDescription, questions: [] })
    } else {
      newSections[selectedSectionIndex].name = newSectionName;
      newSections[selectedSectionIndex].description = newDescription;
    }
    saveQuestionnaire(newSections);
  };

  const handleSectionDelete = (sectionIndex) => {
    var newSections = cloneDeep(sections)
    newSections.splice(sectionIndex, 1);
    saveQuestionnaire(newSections);
  }

  const handleSave = () => {
    if (!selectedQuestion.label) {
      setNewQuestionError("Please enter a label.")
      return
    } else if (!selectedQuestion.type) {
      setNewQuestionError("Please select an input type.")
      return
    } else if (selectedQuestion.type === "select" || selectedQuestion.type === "checkbox" || selectedQuestion.type === "radio") {
      if (selectedQuestion.options.length == 0) {
        setNewQuestionError("Please add at least one option.")
        return
      }
    }
    var newSections = cloneDeep(sections)
    var newSelectedQuestion = Object.assign({}, selectedQuestion);
    newSelectedQuestion.options = newSelectedQuestion.options.filter(x => x != "")
    newSelectedQuestion.description = !isEmpty(newSelectedQuestion.description) ? newSelectedQuestion.description : null
    if (isNewQuestion) {
      newSections[selectedSectionIndex].questions.push(newSelectedQuestion)
    } else {
      newSections[selectedSectionIndex].questions[selectedQuestionIndex] = newSelectedQuestion;
    }
    saveQuestionnaire(newSections);
    setAddEditModalVisible(false);
    setSelectedQuestionIndex(null)
    setSelectedSectionIndex(null)
  };

  const handleRemoveQuestion = () => {
    setAddEditModalVisible(false);
    var newSections = cloneDeep(sections)
    newSections[selectedSectionIndex].questions.splice(selectedQuestionIndex, 1);
    saveQuestionnaire(newSections);
    setSelectedQuestionIndex(null)
    setSelectedSectionIndex(null)
  }

  const handleRowClick = (value, questionIndex, sectionIndex) => {
    setNewQuestionError("")
    setAddEditModalVisible(true)
    setIsNewQuestion(false)
    setSelectedSectionIndex(sectionIndex)
    setSelectedQuestionIndex(questionIndex)
    setSelectedQuestion(value)
    setOptionsText(value ? value.options.join("\n") : "")
  };

  const handleAddSection = () => {
    setSelectedSectionIndex(null)
    setNewSectionName("")
    setIsNewSectionModalVisible(true)
  }

  const handleEditSection = (section, index) => {
    setSelectedSectionIndex(index)
    setNewSectionName(section.name)
    setNewSectionDescription(section.description ?? null)
    setIsNewSectionModalVisible(true)
  }

  const handleAddNewQuestion = (index) => {
    setNewQuestionError("")
    setIsNewQuestion(true)
    setSelectedSectionIndex(index)
    const newQuestion = {
      label: "",
      answer: "",
      type: "text",
      options: []
    }
    setSelectedQuestion(newQuestion)
    setAddEditModalVisible(true)
    setOptionsText("")
  }

  const onOptionChange = (value) => {
    const valueArray = value.split(/\r?\n/)
    const newOptions = valueArray.filter(x => x != "")
    setSelectedQuestion({ ...selectedQuestion, options: newOptions})
    setOptionsText(value)
  }

  const handlePreviewQuestionnaire = async () => {
    navigate(`/admin/questionnaires/${questionnaireId}/preview`)
  }

  const startEditingTitle = () => {
    setEditingTitle(true)
    setTimeout(() => {
      inputRef.current.focus()
    }, 100)
  }

  const saveName = async () => {
    setEditingTitle(false)
    saveQuestionnaire(sections)
  }

  const onKeyPress = (e) => {
    if (e.keyCode == 13) {
     saveName()
    }
  }

  const renderIcon = (type) => {
    if (type === "text") {
      return <BsInputCursorText/>;
    } else if (type === "textarea") {
      return <BsTextareaResize/>
    } else if (type === "checkbox") {
      return <BsCheckSquare/>
    } else if (type === "radio") {
      return <BsRecordCircle/>
    } else if (type === "select") {
      return <BsCaretDownSquare/>
    } else if (type === "date") {
      return <BsCalendar/>
    }
    return null;
  }

  const renderInput = (question) => {
    if (question.type == "text") {
      return <Input disabled={true} size="large"/>;
    } else if (question.type == "textarea") {
      return <Input.TextArea disabled={true} rows={3} size="large"/>;
    } else if (question.type == "select") {
      return (
        <Select
          allowClear
          disabled={true}
          style={{ width: '100%'}}
          placeholder={"Select"}
          optionFilterProp="children"
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          size='large'
        >
          {question.options.map((option, index) => (
            <Select.Option value={option} key={index}>{option}</Select.Option>
          ))}
        </Select>
      )
    } else if (question.type == "checkbox") {
      return question.options.map((option, index) => (
        <div className="mb-10" key={index}>
          <Checkbox disabled={true}>{option}</Checkbox>
        </div>
      ))
    } else if (question.type == "radio") {
      return (
        <Radio.Group>
          <Space direction="vertical">
            {question.options.map((option, index) => (
              <Radio key={index} value={option} disabled={true}>{option}</Radio>
            ))}
          </Space>
        </Radio.Group>
      );
    } else if (question.type == "date") {
      return <DatePicker size="large" format={getDatePickerFormat(accountSettings)} disabled={true}/>
    }

    return <Input size="large"/>;
    
  }

  const renderRow = (value, questionIndex, sectionIndex) => {
    return (
      <div className={`question-row`} onClick={() => handleRowClick(value, questionIndex, sectionIndex)}>
        <Row align="middle">
          <Col flex={1} className="p-5" style={{ maxWidth: "calc(100% - 24px)"}}>
            <div className="mb-10">
              <div className="question-row--label">{ value.label }</div>
              { value.description && (
                <div className="question-row--description">{ value.description }</div>
              )}
            </div>
            { renderInput(value) }
          </Col>
          <Col>
            <div className="display-flex">
              <MdDragIndicator style={{ fontSize: 24, color: "#CCC" }}/>
            </div>
          </Col>
        </Row>
      </div>
    )
  }

  const onSortEnd = (oldIndex, newIndex, sectionIndex) => {
    var newSections = cloneDeep(sections)
    var newQuestions = cloneDeep(sections[sectionIndex].questions);
    if (oldIndex !== newIndex) {
      const sortedQuestions = arrayMove(newQuestions, oldIndex, newIndex).filter(el => !!el);
      newSections[sectionIndex].questions = sortedQuestions
      saveQuestionnaire(newSections)
      if (sectionIndex == selectedSectionIndex) {
        setSelectedQuestionIndex(newIndex)
      }
    }
  };

  const moveSection = (direction, index) => {
    var newSections = cloneDeep(sections)
    var newIndex = direction == "up" ? index - 1 : index + 1
    const sortedSections = arrayMove(newSections, index, newIndex).filter(el => !!el);
    saveQuestionnaire(sortedSections)
  }

  const onSelectTab = (tab) => {
    navigate(`/admin/questionnaires/${questionnaireId}/edit?tab=${tab}`, { replace: true })
    setSelectedTab(tab)
  }

  const SortableItem = sortableElement(({value, questionIndex, sectionIndex}) => (
    <li className="">
      {renderRow(value, questionIndex, sectionIndex)}
    </li>
  ));

  const SortableList = SortableContainer(({items, sectionIndex}) => {
    return (
      <ul className="question-row-container">
        {items.map((value, index) => (
          <SortableItem key={`item-${index}`} index={index} questionIndex={index} sectionIndex={sectionIndex} value={value} />
        ))}
      </ul>
    );
  });

  const renderSection = (section, index) => {
    return (
      <div key={index} className="mb-20">
        <div className="shadow-card p-20">
          <Row align="middle" gutter={[15]} className="content-box-section-title-container">
            <Col xs={20} lg={22}>
              <div className="content-box-section-title">{ section.name }</div>
              { section.description && (
                <div className="c-text-gray mt-5 line-breaks">{ section.description }</div>
              )}
            </Col>
            <Col xs={4} lg={2} className="text-right">
              <div style={{ display: 'inline-block', marginRight: 5}}>
                <Dropdown overlay={menu(section, index)} placement="bottomRight" trigger="click">
                  <div className="admin-icon-circle"><EllipsisOutlined/></div>
                </Dropdown>
              </div>
            </Col>
          </Row>
          <SortableList items={section.questions} sectionIndex={index} onSortEnd={({ oldIndex, newIndex }) => onSortEnd(oldIndex, newIndex, index )} helperClass="question-row-dragging" pressDelay={150} lockAxis="y" />
          <div className="admin-link" onClick={() => handleAddNewQuestion(index)}><PlusOutlined/> Add Question</div>
        </div>
      </div>
    )
  }

  const renderModalOptions = () => {
    if (selectedQuestion.type === "select" || selectedQuestion.type === "checkbox" || selectedQuestion.type === "radio") {
      return (
        <Col xs={24}>
          { renderFormLabel("Options (one per line)") }
          <Input.TextArea 
            rows={3}
            defaultValue={selectedQuestion ? selectedQuestion.options.join("\n") : ""}
            value={optionsText}
            onChange={(e) => onOptionChange(e.target.value)}
            size="large"/>
        </Col>
      )
    }
  }

  const renderNewSectionModal = () => {
    return (
      <Modal visible={isNewSectionModalVisible} footer={null} onCancel={handleNewSectionCancel} width={600} closable={false} maskClosable={false} wrapClassName="rounded-modal">
        <Row align="middle">
          <Col flex={1}>
            <div className="fw-700 fs-18">{selectedSectionIndex == null ? "New Section" : "Edit Section"} </div>
          </Col>
          <Col>
            <div className="display-flex" onClick={handleNewSectionCancel}><MdOutlineClose size={30} color={"#CCC"}/></div>
          </Col>
        </Row>
        <Row gutter={[10,10]} className="mt-20">
          <Col xs={24}>
            { renderFormLabel("Section Name") }
            <Input size="large" value={newSectionName} onChange={(e) => setNewSectionName(e.target.value)}/>
          </Col>
          <Col xs={24}>
            { renderFormLabel("Description (optional)") }
            <Input.TextArea rows={3} size="large" value={newSectionDescription} onChange={(e) => setNewSectionDescription(e.target.value)}/>
          </Col>
        </Row>
        <div className="admin-modal-footer">
          <Button className="admin-small-button secondary" onClick={handleNewSectionCancel}>Cancel</Button>
          <Button className="admin-small-button ml-10" onClick={handleNewSectionSave}>
            {selectedSectionIndex == null ? "Add Section" : "Save"}
          </Button>
        </div>
      </Modal>
    )
  }

  const renderHeader = () => {
    return (
      <div className="p-20">
        <div>
          <span className="c-blue fw-700 cursor-default" onClick={() => navigate("/admin/questionnaires")}>
            Questionnaires
          </span>
          <span className="fs-10 mh-5"><MdArrowForwardIos/></span>
          <span className="cursor-default c-text-gray">
            Edit
          </span>
        </div>
        <div className={`header-editable-title mt-5 ${isEditingTitle ? "editing" : ""}`}>
          <div className="header-editable-title--label" onClick={() => startEditingTitle()}>{ questionnaireName }</div>
          <div className="header-editable-title--input">
            <Input 
              size="large" 
              placeholder="Name" 
              ref={inputRef}
              onBlur={() => saveName()} 
              value={questionnaireName} 
              onChange={(e) => setQuestionnaireName(e.target.value)} 
              onKeyUp={onKeyPress}
            />
          </div>
        </div>
        { renderSubHeader() }
      </div>
    )
  }

  const renderSubHeader = () => {
    return (
      <div className="flex-row flex-middle c-text-gray flex-1 mt-5">
        { isSaving ? (
          <div className="flex-row flex-middle c-text-gray flex-1">
            <FiRefreshCw style={{ marginRight: 5}}/> Saving changes...
          </div>
        ) : (
          <div className="flex-row flex-middle c-text-gray flex-1">
            <FiCheckCircle style={{ marginRight: 5}}/> All changes saved!
          </div>
        )}
        { sections.length > 0 && (
          <div className="flex-row flex-middle c-blue flex-0 bg-gray pv-5 ph-10 radius-8 cursor-default" onClick={() => handlePreviewQuestionnaire()}>
            <FiEye style={{ fontSize: 20, color: '#536DFE', marginRight: 8}}/> Preview
          </div>
        )}
      </div>
    )
  }

  const renderInputType = (value, text) => {
    const styleClass = selectedQuestion.type == value ? "bg-blue c-white" : "bg-gray"
    return (
      <Col xs={12} sm={8}>
        <div className={`p-15 text-center radius-8 cursor-default ${styleClass}`} onClick={() => setSelectedQuestion({ ...selectedQuestion, type: value})}>
          <div className="fs-24">
            { renderIcon(value) }
          </div>
          {text}
        </div>
      </Col>
    )
  }

  const renderInputTypes = () => {
    return (
      <Row gutter={[10,10]}>
        { renderInputType("text", "Text Box") }
        { renderInputType("textarea", "Text Area") }
        { renderInputType("checkbox", "Checkboxes") }
        { renderInputType("radio", "Radio Buttons") }
        { renderInputType("select", "Dropdown") }
        { renderInputType("date", "Date") }
      </Row>
    )
  }

  const renderAddEditModal = () => {
    return (
      <Modal visible={isAddEditModalVisible} footer={null} onCancel={() => setAddEditModalVisible(false)} width={500} closable={false} maskClosable={false} wrapClassName="rounded-modal">
        <Row align="middle">
          <Col flex={1}>
            <div className="fw-700 fs-18">{isNewQuestion ? "New Question" : "Edit Question"}</div>
          </Col>
          <Col>
            <div className="display-flex" onClick={() => setAddEditModalVisible(false)}><MdOutlineClose size={30} color={"#CCC"}/></div>
          </Col>
        </Row>
        <Row gutter={[10,10]} className="mt-20">
          <Col xs={24}>
            { renderFormLabel("Label") }
            <Input size="large" value={selectedQuestion.label} onChange={(e) => setSelectedQuestion({ ...selectedQuestion, label: e.target.value})}/>
          </Col>
          <Col xs={24}>
            { renderFormLabel("Helper Text (optional)") }
            <Input.TextArea rows={3} size="large" value={selectedQuestion.description} onChange={(e) => setSelectedQuestion({ ...selectedQuestion, description: e.target.value})}/>
          </Col>
          <Col xs={24}>
            { renderFormLabel("Input Type") }
            { renderInputTypes() }
          </Col>
          { renderModalOptions() }
          { newQuestionError && (
            <Col xs={24}>
              <div className="text-center c-red mt-5">{newQuestionError}</div>
            </Col>
          )}
          <Col xs={24}>
            <Row>
              <Col flex="1">
                { !isNewQuestion && (
                  <Button className="admin-small-button secondary mt-20" onClick={handleRemoveQuestion}>Remove</Button>
                )}
              </Col>
              <Col>
                <div className="mt-20 text-right">
                  <Button className="admin-small-button secondary" onClick={handleCancel}>Cancel</Button>
                  <Button className="admin-small-button ml-10" onClick={handleSave}>
                    Save
                  </Button>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Modal>
    )
  }

  const renderNewSelection = () => {
    return (
      <div className="shadow-card ph-20 pv-50 text-center">
        <img src={emptyStateImage} width="250"/>
        <div className="fs-14 c-text-gray mt-30">Get started by creating your first section.</div>
        <button className="page-title-button mt-20" onClick={handleAddSection}>Add Section</button>
      </div>
    )
  }

  const renderTab = () => {
    if (selectedTab === "questions") {
      return renderQuestionsTab()
    } else {
      return renderSettingsTab()
    }
  }

  const renderQuestionsTab = () => {
    if (sections && sections.length == 0) {
      return renderNewSelection()
    }

    return (
      <>
        { sections && sections.map((x, i) => renderSection(x, i))}
        <div className="text-center">
          <button className="page-title-button" onClick={handleAddSection}>Add Section</button>
        </div>
      </>
    )
  }

  const renderSettingsTab = () => {
    return (
      <>
        <div className="shadow-card p-20">
          <div className="c-text-gray mb-5">Internal Name (optional)</div>
          <Input 
            placeholder="Internal Name" 
            size="large" 
            value={internalQuestionnaireName} 
            onChange={(e) => setInternalQuestionnaireName(e.target.value)}
            onBlur={() => saveQuestionnaire(sections)} 
          /> 
          <div className="bg-gray p-10 radius-8 mt-10">This name is used for internal purposes only to distinguish between questionnaires that might have the same display name to the user.</div>
        </div>
      </>
    )
  }

  const renderContent = () => {
    return (
      <>
        <div className="toolbar-container">
          <div className={`toolbar-tab ${selectedTab == "questions" ? "selected" : ""}`} onClick={() => onSelectTab("questions")}>Questions</div>
          <div className={`toolbar-tab ${selectedTab == "settings" ? "selected" : ""}`} onClick={() => onSelectTab("settings")}>Settings</div>
          <div className="flex-1"></div>
        </div>
        <FloatingContainer className="ph-20 mb-80" verticalPadding={20} maxWidth={800}>
          { renderTab() }
          { renderNewSectionModal() }
          { renderAddEditModal() }
        </FloatingContainer>
      </>
    )
  }

  if (isLoading) {
    return <LoadingSpinner/>
  }

  return (
    <AdminContent header={renderHeader()} body={renderContent()}/>
  );
}

export default NewQuestionnairePage;

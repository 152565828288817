import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import useApi from '../../../hooks/useApi';
import moment from 'moment';
import { Row, Col, message, Switch } from 'antd';
import AdminContent from '../../../components/adminContent';
import FloatingContainer from '../../../components/floatingContainer'
import { getWorkflowTasks, updateEventTaskCompleted } from '../../../api';
import { cloneDeep } from 'lodash';
import { MdCheck } from "react-icons/md";
import { getActionTypeDescription } from '../../../helpers/workflowHelper';
import emptyStateImage from '../../../images/empty-state-icon.png';
import LoadingSpinner from "../../../components/loading";
import useDocumentTitle from '../../../hooks/useDocumentTitle';
import useAccountSettings from '../../../hooks/useAccountSettings';
import { formatEventDateLong } from '../../../helpers/dateHelper';
import { getShowAllCompanyTasks, storeShowAllCompanyTasks } from '../../../helpers/taskHelper';
import AppContext from '../../../app/context';
import { MdOutlineRefresh } from "react-icons/md";
import Roles from '../../../constants/roles';

const TasksPage = () => {

  const [isLoading, setLoading] = useState(true);
  const [tasks, setTasks] = useState([]);
  const [showAllTasks, setShowAllTasks] = useState(false);

  useDocumentTitle("Tasks")
  const navigate = useNavigate();
  const [sendRequest] = useApi()
  const [accountSettings] = useAccountSettings()

  const { auth, setAuth } = useContext(AppContext);
  const user = auth.user ? auth.user : {}

  useEffect(() => {
    window.scrollTo(0, 0);

    const shouldShowAllCompanyEvents = getShowAllCompanyTasks()
    setShowAllTasks(shouldShowAllCompanyEvents)

    var filter = shouldShowAllCompanyEvents ? "all" : "mine"
    refreshPage(filter)
  }, []);

  const refreshPage = async (filter = "mine", successMessage = "") => {
    try {

      const taskResults = await sendRequest(getWorkflowTasks(filter));
      setTasks(taskResults)

      if (successMessage != "") {
        message.success(successMessage);
      }

      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  const toggleCompleted = (task) => {
    sendRequest(updateEventTaskCompleted(task.event_id, task.event_workflow_step_id, { completed: !task.completed })).then(response => {
      var newTasks = cloneDeep(tasks)
      for (var x = 0; x < newTasks.length; x++) {
        if (newTasks[x].event_id == task.event_id) {
          for (var i = 0; i < newTasks[x].tasks.length; i++) {
            if (newTasks[x].tasks[i].event_workflow_step_id == task.event_workflow_step_id) {
              newTasks[x].tasks[i].completed = !task.completed
            }
          }
        }
      }
      setTasks(newTasks)
    })
  }

  const onShowAllCompanyTasks = (value) => {
    setShowAllTasks(value)
    storeShowAllCompanyTasks(value)
    var filter = value ? "all" : "mine"
    var message = value ? "Now showing all company tasks" : "Now showing tasks assigned to you"
    refreshPage(filter, message)
  }

  const renderCheckbox = (task) => {
    if (task.completed) {
      return (
        <div className="workflow-step-checkbox selected">
          <MdCheck/>
        </div>
      )
    } else {
      return (
        <div className="workflow-step-checkbox"></div>
      )
    }
  }

  const renderDueText = (task) => {
    const overdueDays = moment.utc().startOf('day').diff(moment.utc(task.due_date), 'days')
    if (overdueDays == -1) {
      return <div className="fs-12 c-blue line-1-4">Due tomorrow</div>
    } else if (overdueDays < 0) {
      return <div className="fs-12 c-blue line-1-4">Due in {-overdueDays} days</div>
    } else if (overdueDays == 0) {
      return <div className="fs-12 c-blue line-1-4">Due today</div>
    }
    return <div className="fs-12 c-red line-1-4">{overdueDays} {overdueDays == 1 ? "day" : "days"} overdue</div>
  }

  const renderTask = (task, index, users) => {
    return (
      <div className="b-border flex-row flex-middle" key={index}>
        <div className="flex-0 p-15" onClick={() => toggleCompleted(task)}>
          { renderCheckbox(task) }
        </div>
        <div className="flex-1 pv-15 pr-15">
          <div className="fs-14"><span className="fw-700">{getActionTypeDescription(task.workflow_step_type)}:</span> {task.description}</div>
          { renderDueText(task) }
        </div>
      </div>
    )
  }

  const renderTaskGroup = (taskGroup, index) => {
    return (
      <div key={index}>
        <div className="fs-20 fw-700 ml-5">{taskGroup.event_name}</div>
        <div className="display-flex">
          <div className="flex-1">
            <div className="fs-14 line-1-2 c-text-gray ml-5">{formatEventDateLong(taskGroup.event_date, accountSettings)}</div>
          </div>
          <div className="flex-0 line-1-2 mr-10">
            <div className="blue-link" onClick={() => navigate(`/admin/events/${taskGroup.event_id}`)}>Details</div>
          </div>
        </div>
        
        <div className="shadow-card mt-10 mb-20">
          { taskGroup.tasks.map((x,i) => renderTask(x,i,taskGroup.users))}
        </div>
      </div>
    )
  }

  const renderHeader = () => {
    return (
      <Row align="middle" className="p-20">
        <Col flex={1}>
          <div className="fs-24 fw-700">My Tasks</div>
          { user.role == Roles.OWNER && (
            <div className="mt-5">
              <div className="flex-row flex-middle">
                <Switch size="small" checked={showAllTasks} onChange={() => onShowAllCompanyTasks(!showAllTasks)} style={{ marginRight: 8 }}/> Show all company tasks
              </div>
            </div>
          )}
        </Col>
        <Col flex={0}>
          <div className="display-flex">
            <MdOutlineRefresh style={{ fontSize: 24, color: "#536DFE"}} onClick={() => refreshPage(showAllTasks ? "all" : "mine", "Tasks refreshed successfully!")}/>
          </div>
        </Col>
      </Row>
    )
  }

  const renderEmptyState = () => {
    return (
      <div className="shadow-card ph-20 pv-50 text-center">
        <img src={emptyStateImage} width="200"/>
        <div className="fs-18 fw-700 mt-30">
          You're all caught up!
        </div>
        <div className="fs-14 fw-500 c-text-gray mt-5">
          You don't have any outstanding tasks.
        </div>
      </div>
    )
  }

  const renderContent = () => {
    if (isLoading) {
      return <LoadingSpinner/>
    }
    if (tasks.length == 0) {
      return (
        <FloatingContainer className="ph-20" verticalPadding={20} maxWidth={600}>
          { renderEmptyState() }
        </FloatingContainer>
      )
    }
    return (
      <FloatingContainer className="ph-20" verticalPadding={20} maxWidth={600}>
        { tasks.map((x,i) => renderTaskGroup(x,i)) }
      </FloatingContainer>
    )
  }

  return (
    <AdminContent body={renderContent()} header={renderHeader()}/>
  );
}

export default TasksPage;

import React, { useEffect } from "react";
import EventPlanningTabMusic from './eventPlanningTabMusic';
import EventPlanningTabTimelines from '../eventSections/eventPlanningTabTimelines';
import EventPlanningTabQuestionnaires from '../eventSections/eventPlanningTabQuestionnaires';
import EventPlanningTabEventTemplate from '../eventSections/eventPlanningTabEventTemplate';
import FloatingContainer from '../../../../components/floatingContainer'
import { Row, Col } from "antd";
import { FiMusic, FiList, FiFileText } from "react-icons/fi";
import { HiOutlineTemplate } from "react-icons/hi";
import { isNull } from "lodash";

const EventPlanningTab = (props) => {

  const { specialEventSections, event, selectedPlanningTab, onSelectPlanningTab = () => {}, refreshEventGenres = () => {}, refreshPage = () => {}, playlists = [], eventTimeline = {}, questionnaires = [], eventQuestionnaires = [], eventGenres = [], systemGenres = [] } = props;

  useEffect(() => {
  }, [])

  const renderPlanningSecondTab = () => {
    if (isNull(selectedPlanningTab)) {
      return (
        <FloatingContainer className="ph-20 mb-20" verticalPadding={20} maxWidth={600}>
          <Row gutter={[15,15]} align="middle">
            <Col xs={24} lg={12}>
              <div className="shadow-card planning-tab-card" onClick={() => onSelectPlanningTab("music")}>
                <div className="planning-tab-card--icon"><FiMusic/></div>
                <div className="fw-700 fs-18 mt-10">Music</div>
              </div>
            </Col>
            <Col xs={24} lg={12}>
              <div className="shadow-card planning-tab-card" onClick={() => onSelectPlanningTab("timeline")}>
                <div className="planning-tab-card--icon"><FiList/></div>
                <div className="fw-700 fs-18 mt-10">Timeline</div>
              </div>
            </Col>
            <Col xs={24} lg={12}>
              <div className="shadow-card planning-tab-card" onClick={() => onSelectPlanningTab("questionnaires")}>
                <div className="planning-tab-card--icon"><FiFileText/></div>
                <div className="fw-700 fs-18 mt-10">Questionnaires</div>
              </div>
            </Col>
            <Col xs={24} lg={12}>
              <div className="shadow-card planning-tab-card" onClick={() => onSelectPlanningTab("event-template")}>
                <div className="planning-tab-card--icon"><HiOutlineTemplate/></div>
                <div className="fw-700 fs-18 mt-10 text-center">Special Event Template</div>
              </div>
            </Col>
          </Row>
        </FloatingContainer>
      )
    } else if (selectedPlanningTab == "music") {
      return <EventPlanningTabMusic specialEventSections={specialEventSections} event={event} playlists={playlists} eventGenres={eventGenres} systemGenres={systemGenres} refreshEventGenres={refreshEventGenres} onSelectPlanningTab={onSelectPlanningTab} refreshPage={refreshPage}/>
    } else if (selectedPlanningTab == "timeline") {
      return <EventPlanningTabTimelines event={event} eventTimeline={eventTimeline} specialEventSections={specialEventSections} onSelectPlanningTab={onSelectPlanningTab}/>
    } else if (selectedPlanningTab == "questionnaires") {
      return <EventPlanningTabQuestionnaires event={event} questionnaires={questionnaires} refreshPage={refreshPage} eventQuestionnaires={eventQuestionnaires} onSelectPlanningTab={onSelectPlanningTab}/>
    } else if (selectedPlanningTab == "event-template") {
      return <EventPlanningTabEventTemplate event={event} specialEventSections={specialEventSections} onSelectPlanningTab={onSelectPlanningTab}/>
    }
  }

  return renderPlanningSecondTab();
}

export default EventPlanningTab;

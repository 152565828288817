import React, { useEffect, useState } from "react";
import { Row, Col, Menu, Dropdown, Modal, Form } from 'antd';
import { useNavigate } from 'react-router-dom';
import useApi from '../../../hooks/useApi';
import { deleteRecommendedPlaylist, getRecommendedPlaylists, createRecommendedPlaylist } from '../../../api';
import LoadingSpinner from '../../../components/loading';
import AdminContent from '../../../components/adminContent';
import FloatingContainer from '../../../components/floatingContainer'
import emptyStateImage from '../../../images/empty-state-icon.png';
import { FiList } from "react-icons/fi";
import { BsArrowRight } from "react-icons/bs";
import { MdOutlineClose, MdOutlineMoreHoriz } from "react-icons/md";
import { 
  renderInputField
} from '../../../components/formFields'
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import useDocumentTitle from '../../../hooks/useDocumentTitle';

const RecommendedPlaylistsPage = (props) => {

  const [isLoading, setLoading] = useState(true);
  const [playlists, setPlaylists] = useState([]);
  const [isNewModalVisible, setNewModalVisible] = useState(false);
  const [isConfirmRemoveVisible, setConfirmRemoveVisible] = useState(false);
  const [isRemoveErrorModalVisible, setRemoveErrorModalVisible] = useState(false);
  const [selectedPlaylist, setSelectedPlaylist] = useState({});

  useDocumentTitle("Recommended Playlists")
  const [sendRequest] = useApi()
  const navigate = useNavigate();

  const [form] = Form.useForm();

  useEffect(() => {
    window.scrollTo(0, 0);
    refreshPage()
  }, []);

  const refreshPage = () => {
    sendRequest(getRecommendedPlaylists()).then(response => {
      setPlaylists(response);
      setLoading(false)
    }).catch((e) => setLoading(false))
  }

  const menu = (item) => {
    return (
      <Menu>
        <Menu.Item>
          <div onClick={() => handleEdit(item)}>
            <EditOutlined style={{ marginRight: 8}}/> Edit
          </div>
        </Menu.Item>
        <Menu.Item>
          <div onClick={() => handleDelete(item)}>
            <DeleteOutlined style={{ marginRight: 8}}/> Remove
          </div>
        </Menu.Item>
      </Menu>
    )
  };

  const onNewClick = () => {
    form.setFieldsValue({ name: "" })
    setNewModalVisible(true);
  }

  const handleCancel = () => {
    setNewModalVisible(false);
  };

  const handleEdit = (playlist) => {
    navigate(`/admin/recommended-playlists/${playlist.recommended_playlist_id}/edit`)
  };

  const handleDelete = (playlist) => {
    setSelectedPlaylist(playlist)
    setConfirmRemoveVisible(true)
  };

  const confirmDeletePlaylist = async () => {
    try {
      const deleteResponse = await sendRequest(deleteRecommendedPlaylist(selectedPlaylist.recommended_playlist_id));
      if (deleteResponse.status == "in-use") {
        setConfirmRemoveVisible(false)
        setRemoveErrorModalVisible(true)
      } else {
        await refreshPage()
        setConfirmRemoveVisible(false)
      }
    } catch(error) {
      setConfirmRemoveVisible(false)
    }
  }

  const onSubmit = async (values) => {
    try {
      const body = {
        playlist_name: values.name,
        songs: []
      }
      const results = await sendRequest(createRecommendedPlaylist(body))
      navigate(`/admin/recommended-playlists/${results.recommended_playlist_id}/edit`)
    } catch {
      setNewModalVisible(false);
    }
  }
  
  const renderHeader = () => {
    return (
      <Row align="middle" className="p-20">
        <Col flex={1}>
          <div className="fs-24 fw-700">Recommended Playlists</div>
          <div className="blue-link flex-row flex-1 flex-middle" onClick={() => navigate("/admin/docs/recommended-playlists")}>Learn more <BsArrowRight style={{ marginLeft: 5 }}/></div>
        </Col>
        <Col>
          { playlists.length > 0 && (
            <button className="page-title-button" onClick={onNewClick}>New Playlist</button>
          )}
        </Col>
      </Row>
    )
  }

  const renderPlaylist = (playlist, index) => {
    return (
      <div key={index} className="flex-row p-10 b-border">
        <div onClick={() => handleEdit(playlist)}>
          <div className="card-icon"><FiList/></div>
        </div>
        <div className="flex-col justify-content-center flex-1 mh-15" onClick={() => handleEdit(playlist)}>
          <div className="fs-14 fw-700 line-1-2">{playlist.playlist_name}</div>
          <div className="fs-14 fw-500 c-text-gray line-1-1 mt-2">{playlist.songs.length} { playlist.songs.length == 1 ? "song" : "songs" }</div>
        </div>
        <div className="display-flex flex-middle mr-5">
          <Dropdown overlay={menu(playlist)} placement="bottomRight" trigger="click">
            <div className="dots-container">
              <MdOutlineMoreHoriz style={{ fontSize: 24, color: '#999'}}/>
            </div>
          </Dropdown>
        </div>
      </div>
    )
  }

  const renderPlaylistBox = () => {
    return (
      <div className="shadow-card">
        { playlists.map((x,i) => renderPlaylist(x,i)) }
      </div>
    )
  }

  const renderPlaylists = () => {

    if (playlists.length == 0) {
      return (
        <div className="shadow-card ph-20 pv-50 text-center">
          <div>
            <img src={emptyStateImage} width={250}/>
          </div>
          <div className="fs-18 fw-700 mt-30">
            No recommended playlists yet
          </div>
          <div className="fs-14 fw-500 c-text-gray">
            Create a playlist to get started!
          </div>
          <button className="primary-button mt-30" style={{ width: 250 }} onClick={onNewClick}>Create Playlist</button>
        </div>
      )
    } else {
      return renderPlaylistBox()
    }
  }

  const renderConfirmRemoveModal = () => {
    return (
      <Modal visible={isConfirmRemoveVisible} closable={false} footer={null} width={400} wrapClassName="rounded-modal">
        <div className="text-right" onClick={() => setConfirmRemoveVisible(false)}><MdOutlineClose size={30} color={"#CCC"}/></div>
        <div className="mt-10">
          <div className="fw-700 fs-20 text-center">Remove Playlist</div>
          <div className="fw-500 fs-14 mt-20 mb-20 text-center">Are you sure you would like to remove <span className="fw-700">{selectedPlaylist.playlist_name}</span>? This action cannot be undone.</div>
          <button className="primary-button warning" type="button" onClick={() => confirmDeletePlaylist()}>Remove Playlist</button>
          <div className="text-center mt-15">
            <div className="blue-link" onClick={() => setConfirmRemoveVisible(false)}>Cancel</div>
          </div>
        </div>
      </Modal>
    )
  }

  const renderRemoveErrorModal = () => {
    return (
      <Modal visible={isRemoveErrorModalVisible} closable={false} footer={null} width={400} wrapClassName="rounded-modal">
        <div className="text-right" onClick={() => setRemoveErrorModalVisible(false)}><MdOutlineClose size={30} color={"#CCC"}/></div>
        <div className="mt-10">
          <div className="fw-700 fs-20 text-center">Cannot Remove Playlist</div>
          <div className="fw-500 fs-14 mt-20 mb-20 text-center">The playlist "<span className="fw-700">{selectedPlaylist.playlist_name}</span>" is currently being used and cannot be removed.</div>
          <button className="primary-button" type="button" onClick={() => setRemoveErrorModalVisible()}>OK</button>
        </div>
      </Modal>
    )
  }

  const renderNewPlaylistModal = () => {
    return (
      <Modal visible={isNewModalVisible} footer={null} closable={false} wrapClassName="rounded-modal">
        <Row align="middle">
          <Col flex={1}>
            <div className="fw-700 fs-18">New Recommended Playlist</div>
          </Col>
          <Col>
            <div className="display-flex" onClick={handleCancel}><MdOutlineClose size={30} color={"#CCC"}/></div>
          </Col>
        </Row>
        <Form form={form} layout="vertical" name="new-workflow" onFinish={onSubmit}>
          <Row gutter={[10,10]} className="mt-15">
            <Col xs={24}>
              {renderInputField("Name", "name", true)}
            </Col>
          </Row>
          <div className="admin-modal-footer">
            <button className="primary-button" type="submit" onClick={() => {}}>Continue</button>
            <div className="text-center mt-15">
              <div className="blue-link" onClick={handleCancel}>Cancel</div>
            </div>
          </div>
        </Form>
      </Modal>
    )
  }

  const renderContent = () => {
    if (isLoading) {
      return <LoadingSpinner/>
    }
    return (
      <FloatingContainer className="ph-20" verticalPadding={20} maxWidth={800}>
        { renderPlaylists() }
        { renderConfirmRemoveModal() }
        { renderRemoveErrorModal() }
        { renderNewPlaylistModal() }
      </FloatingContainer>
    )
  }

  return (
    <AdminContent header={renderHeader()} body={renderContent()}/>
  );
}

export default RecommendedPlaylistsPage;

import React, { useEffect, useState } from "react";
import { Row, Col, Modal, Form, Button, Dropdown, Menu } from 'antd';
import { useNavigate } from 'react-router-dom';
import { getSystemSpecialEventTemplates, createSystemSpecialEventTemplate, deleteSystemSpecialEventTemplate } from '../../../api';
import LoadingSpinner from '../../../components/loading';
import AdminContent from '../../../components/adminContent';
import FloatingContainer from '../../../components/floatingContainer'
import useApi from '../../../hooks/useApi';
import { 
  renderInputField
} from '../../../components/formFields'
import { MdOutlineClose, MdOutlineMoreHoriz } from "react-icons/md";
import { FiFlag } from "react-icons/fi";
import { EditOutlined, DeleteOutlined, CopyOutlined } from '@ant-design/icons';
import emptyStateImage from '../../../images/empty-state-icon.png';

const SystemSpecialEventsTemplatesPage = () => {

  const [isLoading, setLoading] = useState(true);
  const [selectedEventType, setSelectedEventType] = useState();
  const [specialEventTemplates, setSpecialEventTemplates] = useState([]);
  const [isNewTemplateModalVisible, setNewTemplateModalVisible] = useState(false);
  const [isConfirmRemoveVisible, setConfirmRemoveVisible] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState({});

  const [form] = Form.useForm();

  const [sendRequest] = useApi()
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
    refreshPage()
  }, []);

  const refreshPage = async () => {
    try {
      const results = await sendRequest(getSystemSpecialEventTemplates());
      setSpecialEventTemplates(results)

      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  const menu = (item) => {
    return (
      <Menu>
        <Menu.Item>
          <div onClick={() => onSelectRow(item)}>
            <EditOutlined style={{ marginRight: 8}}/> Edit
          </div>
        </Menu.Item>
        <Menu.Item>
          <div onClick={() => handleDuplicate(item)}>
            <CopyOutlined style={{ marginRight: 8}}/> Duplicate
          </div>
        </Menu.Item>
        <Menu.Item>
          <div onClick={() => handleDelete(item)}>
            <DeleteOutlined style={{ marginRight: 8}}/> Delete
          </div>
        </Menu.Item>
      </Menu>
    )
  };

  const handleDelete = async (template) => {
    setSelectedTemplate(template)
    setConfirmRemoveVisible(true)
  }

  const handleDuplicate = async (template) => {
    // TODO
  }

  const confirmDeleteTemplate = async () => {
    try {
      await sendRequest(deleteSystemSpecialEventTemplate(selectedTemplate.system_special_event_template_id));
      await refreshPage()
    } finally {
      setConfirmRemoveVisible(false)
    }
  }

  const onSelectRow = template => {
    navigate(`/system/special-event-templates/${template.system_special_event_template_id}`)
  }

  const onSubmitNewTemplate = async (values) => {
    const body = {
      ...values,
      event_type_id: selectedEventType
    }
    try {
      const newTemplateResults = await sendRequest(createSystemSpecialEventTemplate(body))
      setNewTemplateModalVisible(false)
      navigate(`/system/special-event-templates/${newTemplateResults.system_special_event_template_id}`)
    } catch {}
  }

  const renderNewTemplateModal = () => {
    return (
      <Modal visible={isNewTemplateModalVisible} footer={null} closable={false} wrapClassName="rounded-modal">
        <Form form={form} layout="vertical" name="client" onFinish={onSubmitNewTemplate}>
          <Row align="middle">
            <Col flex={1}>
              <div className="fw-700 fs-18">New Template</div>
            </Col>
            <Col>
              <div className="display-flex" onClick={() => setNewTemplateModalVisible(false)}><MdOutlineClose size={30} color={"#CCC"}/></div>
            </Col>
          </Row>
          <Row gutter={[10,10]} className="mt-15">
            <Col xs={24}>
              {renderInputField("Template Name", "template_name", true)}
            </Col>
          </Row>
          <div className="admin-modal-footer">
            <Button className="admin-small-button secondary" onClick={() => setNewTemplateModalVisible(false) }>Cancel</Button>
            <Button className="admin-small-button ml-10" htmlType="submit">
              Continue
            </Button>
          </div>
        </Form>
      </Modal>
    )
  }
  
  const renderHeader = () => {
    return (
      <Row align="middle" className="p-20">
        <Col flex={1}>
          <div className="fs-24 fw-700">Special Event Templates</div>
        </Col>
        <Col>
          { specialEventTemplates.length > 0 && (
            <button className="page-title-button" onClick={() => setNewTemplateModalVisible(true)}>+ Create</button>
          )}
        </Col>
      </Row>
    )
  }

  const renderTemplateRow = (template, index) => {
    return (
      <div key={index} className="p-10 b-border">
        <Row gutter={[15,15]} align="middle">
          <Col onClick={() => onSelectRow(template)}>
            <div className="card-icon"><FiFlag/></div>
          </Col>
          <Col flex={1} onClick={() => onSelectRow(template)}>
            <div className="fs-14 fw-700">{template.template_name}</div>
          </Col>
          <Col flex={0}>
            <div className="display-flex mr-5">
              <Dropdown overlay={menu(template)} placement="bottomRight" trigger="click">
                <div className="dots-container">
                  <MdOutlineMoreHoriz style={{ fontSize: 24, color: '#999'}}/>
                </div>
              </Dropdown>
            </div>
          </Col>
        </Row>
      </div>
    )
  }

  const renderTable = () => {
    if (specialEventTemplates && specialEventTemplates.length > 0) {
      return (
        <div className="">
          { specialEventTemplates.map((x, i) => renderTemplateRow(x,i)) }
        </div>
      )
    } else {
      return (
        <div className="ph-15 pt-50 pb-40 text-center">
          <img src={emptyStateImage} width="200"/>
          <div className="mt-30">
            No templates have been created yet.
          </div>
          <button className="primary-button mt-20" style={{ width: 200 }} onClick={() => setNewTemplateModalVisible(true)}>Create Template</button>
        </div>
      )
    }
  }

  const renderConfirmRemoveModal = () => {
    return (
      <Modal visible={isConfirmRemoveVisible} closable={false} footer={null} width={400} wrapClassName="rounded-modal">
        <div className="text-right" onClick={() => setConfirmRemoveVisible(false)}><MdOutlineClose size={30} color={"#CCC"}/></div>
        <div className="mt-10">
          <div className="fw-700 fs-20 text-center">Remove Template</div>
          <div className="fw-500 fs-14 mt-20 mb-20 text-center">Are you sure you would like to remove <span className="fw-700">{selectedTemplate.template_name}</span>? This action cannot be undone.</div>
          <button className="primary-button warning" type="button" onClick={() => confirmDeleteTemplate()}>Remove Template</button>
          <div className="text-center mt-15">
            <div className="blue-link" onClick={() => setConfirmRemoveVisible(false)}>Cancel</div>
          </div>
        </div>
      </Modal>
    )
  }

  const renderContent = () => {
    return (
      <FloatingContainer className="ph-20" verticalPadding={20} maxWidth={800}>
        <div className="shadow-card">
          { renderTable() }
        </div>
        { renderNewTemplateModal() }
        { renderConfirmRemoveModal() }
      </FloatingContainer>
    )
  }

  if (isLoading) {
    return <LoadingSpinner/>
  }

  return (
    <AdminContent header={renderHeader()} body={renderContent()}/>
  );
}

export default SystemSpecialEventsTemplatesPage;

export default {
  CREATE_EVENTS: "CREATE_EVENTS",
  VIEW_ALL_EVENTS: "VIEW_ALL_EVENTS",
  MANAGE_CLIENTS: "MANAGE_CLIENTS",
  MANAGE_EMPLOYEES: "MANAGE_EMPLOYEES",
  MANAGE_QUESTIONNAIRES: "MANAGE_QUESTIONNAIRES",
  MANAGE_REC_PLAYLISTS: "MANAGE_REC_PLAYLISTS",
  MANAGE_SPECIAL_EVENTS: "MANAGE_SPECIAL_EVENTS",
  MANAGE_WORKFLOWS: "MANAGE_WORKFLOWS",
  MANAGE_VENUES: "MANAGE_VENUES",
  MANAGE_CONTRACTS: "MANAGE_CONTRACTS",
  MANAGE_CONTRACT_TEMPLATES: "MANAGE_CONTRACT_TEMPLATES",
  MANAGE_PACKAGES: "MANAGE_PACKAGES",
  MANAGE_ADD_ONS: "MANAGE_ADD_ONS",
  VIEW_EVENT_FINANCIALS: "VIEW_EVENT_FINANCIALS",
  EDIT_EVENT_FINANCIALS: "EDIT_EVENT_FINANCIALS",
  MANAGE_PRICING_GUIDES: "MANAGE_PRICING_GUIDES",
  VIEW_BOOKING_REQUESTS: "VIEW_BOOKING_REQUESTS",
  MANAGE_INVOICES: "MANAGE_INVOICES",
  MANAGE_INVOICE_TEMPLATES: "MANAGE_INVOICE_TEMPLATES",
  MANAGE_EVENT_TEMPLATES: "MANAGE_EVENT_TEMPLATES",
  VIEW_TIME_OFF: "VIEW_TIME_OFF",
  MANAGE_TIME_OFF: "MANAGE_TIME_OFF"
};
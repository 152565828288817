import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useApi from '../../../hooks/useApi';
import { Form, Row, Col, Modal, notification, Checkbox } from 'antd';
import { 
  getEventsExport,
  getClientsForExport,
  getVenues
} from '../../../api';
import LoadingSpinner from '../../../components/loading';
import AdminContent from '../../../components/adminContent';
import FloatingContainer from '../../../components/floatingContainer'
import { MdArrowForwardIos, MdOutlineClose } from "react-icons/md";
import {
  renderInputField,
  renderFormLabel
} from '../../../components/formFields'
import { formatDateTimeLong, formatEventDateShort, formatTime } from '../../../helpers/dateHelper';
import { 
  formatCurrencyString,
   getItemDiscountAmount, 
   getItemTotalPrice, 
   getAddOnTotal,
   getAddOnItemDiscountTotal,
   getAddOnSubTotal
} from '../../../helpers/contractHelper';
import { getFormattedPhoneNumber } from '../../../helpers/addressHelper';
import useDocumentTitle from '../../../hooks/useDocumentTitle';
import useAccountSettings from '../../../hooks/useAccountSettings';
import useCurrency from '../../../hooks/useCurrency';
import { cloneDeep, isNull, isEmpty } from "lodash";
import { LoadingOutlined } from "@ant-design/icons";


const ExportDataPage = () => {

  const [isLoading, setLoading] = useState(true);
  const [isExporting, setExporting] = useState(false);
  const [isExportModalVisible, setExportModalVisible] = useState(false);
  const [selectedExportType, setSelectedExportType] = useState(false);
  const [exportFields, setExportFields] = useState([]);
  const [selectedExportFields, setSelectedExportFields] = useState([]);

  useDocumentTitle("Export Data")
  const navigate = useNavigate();
  const [exportForm] = Form.useForm();
  const [sendRequest] = useApi()
  const [accountSettings] = useAccountSettings()
  const [currency] = useCurrency()

  // Export Types
  const EXPORT_TYPE_EVENTS = "Events"
  const EXPORT_TYPE_CLIENTS = "Clients"
  const EXPORT_TYPE_VENUES = "Venues"

  // Event Fields
  const EVENT_ID = "Event ID"
  const EVENT_NAME = "Event Name"
  const EVENT_DATE = "Event Date"
  const EVENT_TYPE = "Event Type"
  const ARRIVAL_TIME = "Arrival Time"
  const START_TIME = "Start Time"
  const END_TIME = "End Time"
  const LOCKDOWN_DAYS = "Lockdown Days"
  const INTERNAL_NOTES = "Internal Notes"
  const EVENT_VENUE_NAME = "Venue Name"
  const EVENT_VENUE_ADDRESS = "Venue Address"
  const CLIENTS = "Clients"
  const ASSIGNED_STAFF = "Assigned Staff"
  const PACKAGES = "Packages"
  const ADD_ONS = "Add-Ons"

  const eventFields = [
    EVENT_ID,
    EVENT_NAME,
    EVENT_DATE,
    EVENT_TYPE,
    ARRIVAL_TIME,
    START_TIME,
    END_TIME,
    LOCKDOWN_DAYS,
    INTERNAL_NOTES,
    EVENT_VENUE_NAME,
    EVENT_VENUE_ADDRESS,
    CLIENTS,
    ASSIGNED_STAFF,
    PACKAGES,
    ADD_ONS
  ]

  // Client Fields
  const FIRST_NAME = "First Name"
  const LAST_NAME = "Last Name"
  const CLIENT_EMAIL = "Email"
  const CLIENT_PHONE = "Phone Number"
  const CLIENT_ADDRESS_COUNTRY = "Country/Region"
  const CLIENT_ADDRESS_LINE_1 = "Address Line 1"
  const CLIENT_ADDRESS_LINE_2 = "Address Line 2"
  const CLIENT_ADDRESS_CITY = "Town/City"
  const CLIENT_ADDRESS_STATE = "State/County"
  const CLIENT_ADDRESS_ZIP = "Zip/Postcode"
  const CLIENT_INVITATION_STATUS = "Invitation Status"
  const CLIENT_DOWNLOAD_STATUS = "App Download Status"
  const CLIENT_LAST_LOGIN = "Last Login"
  
  const clientFields = [
    FIRST_NAME,
    LAST_NAME,
    CLIENT_EMAIL,
    CLIENT_PHONE,
    CLIENT_ADDRESS_COUNTRY,
    CLIENT_ADDRESS_LINE_1,
    CLIENT_ADDRESS_LINE_2,
    CLIENT_ADDRESS_CITY,
    CLIENT_ADDRESS_STATE,
    CLIENT_ADDRESS_ZIP,
    CLIENT_INVITATION_STATUS,
    CLIENT_DOWNLOAD_STATUS,
    CLIENT_LAST_LOGIN
  ]

    // Venue Fields
    const VENUE_NAME = "Venue Name"
    const VENUE_ADDRESS_COUNTRY = "Country/Region"
    const VENUE_ADDRESS_LINE_1 = "Address Line 1"
    const VENUE_ADDRESS_LINE_2 = "Address Line 2"
    const VENUE_ADDRESS_CITY = "Town/City"
    const VENUE_ADDRESS_STATE = "State/County"
    const VENUE_ADDRESS_ZIP = "Zip/Postcode"
    const ONE_TIME_VENUE = "One Time Venue?"
    const VENUE_WEBSITE = "Website"
    const VENUE_WIFI = "WiFi"
    const VENUE_CONTACT_NAME = "Venue Contact Name"
    const VENUE_CONTACT_EMAIL = "Email"
    const VENUE_CONTACT_PHONE = "Phone"
    const VENUE_NOTES = "Notes"
    
    const venueFields = [
      VENUE_NAME,
      VENUE_ADDRESS_COUNTRY,
      VENUE_ADDRESS_LINE_1,
      VENUE_ADDRESS_LINE_2,
      VENUE_ADDRESS_CITY,
      VENUE_ADDRESS_STATE,
      VENUE_ADDRESS_ZIP,
      ONE_TIME_VENUE,
      VENUE_WEBSITE,
      VENUE_WIFI,
      VENUE_CONTACT_NAME,
      VENUE_CONTACT_EMAIL,
      VENUE_CONTACT_PHONE,
      VENUE_NOTES
    ]

  useEffect(() => {
    window.scrollTo(0, 0);
    refreshPage();
  }, []);

  const refreshPage = async () => {
    try {
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  const onExportData = (type) => {
    setSelectedExportType(type)
    if (type == EXPORT_TYPE_EVENTS) {
      setExportFields(eventFields)
      setSelectedExportFields(eventFields)
    } else if (type == EXPORT_TYPE_CLIENTS) {
      setExportFields(clientFields)
      setSelectedExportFields(clientFields)
    } else if (type == EXPORT_TYPE_VENUES) {
      setExportFields(venueFields)
      setSelectedExportFields(venueFields)
    } else {
      setExportFields([])
      setSelectedExportFields([])
    }
    exportForm.resetFields()
    setExportModalVisible(true)
  }

  const csvData = (text,lastRecord = false) => {
    const newText = !isNull(text) ? text.replace(/\"/g, "\"\"") : "";
    if (lastRecord) {
      return `"${newText}"\n`
    } else {
      return `"${newText}",`
    }
  }

  const headerRow = () => {
    var fileData = ""
    for (const [i, field] of selectedExportFields.entries()) {
      const lastRecord = i == selectedExportFields.length - 1
      fileData += csvData(field,lastRecord)
    }
    return fileData
  }

  const exportEvents = async () => {
    try {
      const eventResults = await sendRequest(getEventsExport());

      var fileData = ""

      // Loop through events
      var headerRendered = false
      for (const event of eventResults) {

        // Render header
        if (!headerRendered) {
          fileData += headerRow()
          headerRendered = true
        }

        // Loop through selected fields
        for (const field of selectedExportFields) {
          const lastRecord = field == selectedExportFields[selectedExportFields.length - 1]
          switch (field) {
            case EVENT_ID:
              fileData += csvData(event.event_id, lastRecord)
              break
            case EVENT_NAME:
              fileData += csvData(event.event_name, lastRecord)
              break
            case EVENT_DATE:
              fileData += csvData(formatEventDateShort(event.event_date, accountSettings), lastRecord)
              break;
            case EVENT_TYPE:
              fileData += csvData(event.account_event_type_name, lastRecord)
              break;
            case ARRIVAL_TIME:
              const arrivalTime = !isNull(event.arrival_time) ? formatTime(event.arrival_time, accountSettings) : ""
              fileData += csvData(arrivalTime, lastRecord)
              break;
            case START_TIME:
              const startTime = formatTime(event.start_time, accountSettings)
              fileData += csvData(startTime, lastRecord)
              break;
            case END_TIME:
              const endTime = formatTime(event.end_time, accountSettings)
              fileData += csvData(endTime, lastRecord)
              break;
            case LOCKDOWN_DAYS:
              fileData += csvData(String(event.lockdown_days), lastRecord)
              break;
            case INTERNAL_NOTES:
              fileData += csvData(event.internal_notes, lastRecord)
              break;
            case EVENT_VENUE_NAME:
              var venueName = ""
              if (event.venues.length > 0) {
                const venue = event.venues[0]
                venueName = `${venue.venue_name}`
              }
              fileData += csvData(venueName, lastRecord)
              break;
            case EVENT_VENUE_ADDRESS:
              var venueData = ""
              if (event.venues.length > 0) {
                const venue = event.venues[0]
                const addressLine = isEmpty(venue.address_line_2) || isNull(venue.address_line_2) ? `${venue.address_line_1}` : `${venue.address_line_1} ${venue.address_line_2}`
                const cityStateZip = `${venue.city}, ${venue.state} ${venue.zip}`
                venueData = `${addressLine} \n${cityStateZip}`
              }
              fileData += csvData(venueData, lastRecord)
              break;
            case CLIENTS:
              var clientData = ""
              const clientCount = event.clients.length
              for (const [i, client] of event.clients.entries()) {
                clientData += `${client.first_name} ${client.last_name}\n(${client.email})`
                if (i < clientCount - 1 && clientCount > 1) {
                  clientData += `\n`
                }
              }
              fileData += csvData(clientData, lastRecord)
              break;
            case ASSIGNED_STAFF:
              var staffData = ""
              const staffCount = event.employees.length
              for (const [i, employee] of event.employees.entries()) {
                staffData += `${employee.first_name} ${employee.last_name}\n(${employee.email})`
                if (i < staffCount - 1 && staffCount > 1) {
                  staffData += `\n`
                }
              }
              fileData += csvData(staffData, lastRecord)
              break;
            case PACKAGES:
              var packageData = ""
              const packageCount = event.packages.length
              for (const [i, eventPackage] of event.packages.entries()) {
                packageData += `${eventPackage.package_name}\n`
                const packagePrice = formatCurrencyString(eventPackage.price, currency.code)
                if (eventPackage.discount) {
                  const discountAmount = getItemDiscountAmount(eventPackage, true, currency.code)
                  const totalAmount = getItemTotalPrice(eventPackage, true, currency.code)
                  packageData += `(Subtotal: ${packagePrice}, Discount: ${discountAmount}, Total: ${totalAmount})`
                } else {
                  packageData += `(Total: ${packagePrice})`
                }
                if (i < packageCount - 1 && packageCount > 1) {
                  packageData += `\n`
                }
              }
              fileData += csvData(packageData, lastRecord)
              break;
            case ADD_ONS:
              var addOnData = ""
              const addOnCount = event.add_ons.length
              for (const [i, addOn] of event.add_ons.entries()) {
                addOnData += `${addOn.add_on_name}\n`
                const addOnSubTotal = formatCurrencyString(getAddOnSubTotal(addOn), currency.code)
                const addOnTotalPrice = formatCurrencyString(getAddOnTotal(addOn), currency.code)
                if (addOn.discount) {
                  const discountAmount = getAddOnItemDiscountTotal(addOn, true, currency.code)
                  addOnData += `(Qty: ${addOn.quantity}, Subtotal: ${addOnSubTotal}, Discount: ${discountAmount}, Total: ${addOnTotalPrice})`
                } else {
                  addOnData += `(Qty: ${addOn.quantity}, Total: ${addOnSubTotal})`
                }
                if (i < addOnCount - 1 && addOnCount > 1) {
                  addOnData += `\n`
                }
              }
              fileData += csvData(addOnData, lastRecord)
              break;
            default:
              break;
          }
        }
      }
      return fileData
    } catch (error) {
      console.log(error)
      return ""
    }
  }

  const getUserPhoneNumber = (user) => {
    if (user.phone_numbers && user.phone_numbers.length > 0) {
      return user.phone_numbers[0].phone_number
    }
    return "";
  }

  const getUserAddressField = (user, field) => {
    if (user.addresses && user.addresses.length > 0) {
      return user.addresses[0][field]
    }
    return "";
  }

  const exportClients = async () => {
    try {
      const clientResults = await sendRequest(getClientsForExport());

      var fileData = ""

      // Loop through events
      var headerRendered = false
      for (const client of clientResults) {

        // Render header
        if (!headerRendered) {
          fileData += headerRow()
          headerRendered = true
        }

        // Loop through selected fields
        for (const field of selectedExportFields) {
          const lastRecord = field == selectedExportFields[selectedExportFields.length - 1]
          switch (field) {
            case FIRST_NAME:
              fileData += csvData(client.first_name, lastRecord)
              break
            case LAST_NAME:
              fileData += csvData(client.last_name, lastRecord)
              break
            case CLIENT_EMAIL:
              fileData += csvData(client.email, lastRecord)
              break;
            case CLIENT_PHONE:
              const phoneNumber = getUserPhoneNumber(client)
              fileData += csvData(getFormattedPhoneNumber(phoneNumber), lastRecord)
              break;
            case CLIENT_ADDRESS_COUNTRY:
              fileData += csvData(getUserAddressField(client, "country"), lastRecord)
              break;
            case CLIENT_ADDRESS_LINE_1:
              fileData += csvData(getUserAddressField(client, "line_1"), lastRecord)
              break;
            case CLIENT_ADDRESS_LINE_2:
              fileData += csvData(getUserAddressField(client, "line_2"), lastRecord)
              break;
            case CLIENT_ADDRESS_CITY:
              fileData += csvData(getUserAddressField(client, "city"), lastRecord)
              break;
            case CLIENT_ADDRESS_STATE:
              fileData += csvData(getUserAddressField(client, "state"), lastRecord)
              break;
            case CLIENT_ADDRESS_ZIP:
              fileData += csvData(getUserAddressField(client, "zip"), lastRecord)
              break;
            case CLIENT_INVITATION_STATUS:
              var invitationStatus = ""
              if (client.last_login_datetime) {
                invitationStatus = "Accepted"
              } else if (client.last_invitation_datetime) {
                invitationStatus = "Pending"
              } else {
                invitationStatus = "Not Sent"
              }
              fileData += csvData(invitationStatus, lastRecord)
              break;
            case CLIENT_DOWNLOAD_STATUS:
              const downloadStatus = client.login_with_app ? "Yes" : "No"
              fileData += csvData(downloadStatus, lastRecord)
              break;
            case CLIENT_LAST_LOGIN:
              var lastLogin = "N/A"
              if (client.last_login_datetime) {
                lastLogin = formatDateTimeLong(client.last_login_datetime, accountSettings)
              }
              fileData += csvData(lastLogin, lastRecord)
              break;
            default:
              break;
          }
        }
      }
      return fileData
    } catch (error) {
      console.log(error)
      return ""
    }
  }

  const exportVenues = async () => {
    try {
      const venueResults = await sendRequest(getVenues());

      var fileData = ""

      // Loop through events
      var headerRendered = false
      for (const venue of venueResults) {

        // Render header
        if (!headerRendered) {
          fileData += headerRow()
          headerRendered = true
        }

        // Loop through selected fields
        for (const field of selectedExportFields) {
          const lastRecord = field == selectedExportFields[selectedExportFields.length - 1]
          switch (field) {
            case VENUE_NAME:
              fileData += csvData(venue.venue_name, lastRecord)
              break
            case VENUE_ADDRESS_COUNTRY:
              fileData += csvData(venue.country, lastRecord)
              break
            case VENUE_ADDRESS_LINE_1:
              fileData += csvData(venue.address_line_1, lastRecord)
              break
            case VENUE_ADDRESS_LINE_2:
              fileData += csvData(venue.address_line_2, lastRecord)
              break
            case VENUE_ADDRESS_CITY:
              fileData += csvData(venue.city, lastRecord)
              break
            case VENUE_ADDRESS_STATE:
              fileData += csvData(venue.state, lastRecord)
              break
            case VENUE_ADDRESS_ZIP:
              fileData += csvData(venue.zip, lastRecord)
              break
            case ONE_TIME_VENUE:
              const oneTime = venue.one_time ? "Yes" : "No"
              fileData += csvData(oneTime, lastRecord)
              break
            case VENUE_WEBSITE:
              fileData += csvData(venue.website, lastRecord)
              break
            case VENUE_WIFI:
              fileData += csvData(venue.wifi, lastRecord)
              break
            case VENUE_CONTACT_NAME:
              fileData += csvData(venue.contact_name, lastRecord)
              break
            case VENUE_CONTACT_EMAIL:
              fileData += csvData(venue.contact_email, lastRecord)
              break
            case VENUE_CONTACT_PHONE:
              fileData += csvData(getFormattedPhoneNumber(venue.contact_phone), lastRecord)
              break
            case VENUE_NOTES:
              fileData += csvData(venue.notes, lastRecord)
              break
            default:
              break;
          }
        }
      }
      return fileData
    } catch (error) {
      console.log(error)
      return ""
    }
  }

  const confirmExport = async (values) => {
    try {
      setExporting(true)
      
      // Get CSV data based on type
      var fileData = ""
      if (selectedExportType == EXPORT_TYPE_EVENTS) {
        fileData = await exportEvents()
      } else if (selectedExportType == EXPORT_TYPE_CLIENTS) {
        fileData = await exportClients()
      } else if (selectedExportType == EXPORT_TYPE_VENUES) {
        fileData = await exportVenues()
      }

      // Create CSV file
      const blob = new Blob([fileData], { type: "text/plain" });
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.download = `${values.filename}.csv`;
      link.href = url;
      link.click();

      setExporting(false)
      setExportModalVisible(false)
      notification.success({
        message: 'Success!',
        description: 'Your data has been exported. Please check your downloads.',
        duration: 3
      });
    } catch {
      notification.error({
        message: 'Error!',
        description: 'There was an issue exporting your data.',
        duration: 3
      });
    }
  }

  const toggleSelectedField = (field) => {
    var newSelectedExportFields = cloneDeep(selectedExportFields)
    if (newSelectedExportFields.includes(field)) {
      newSelectedExportFields = newSelectedExportFields.filter(x => x != field)
    } else {
      newSelectedExportFields.push(field)
    }
    setSelectedExportFields(newSelectedExportFields)
  }

  const getModalTitle = () => {
    if (selectedExportType == EXPORT_TYPE_EVENTS) {
      return "Export Events"
    } else if (selectedExportType == EXPORT_TYPE_CLIENTS) {
      return "Export Clients"
    } else if (selectedExportType == EXPORT_TYPE_VENUES) {
      return "Export Venues"
    }
  }

  const renderHeader = () => {
    return (
      <div className="p-20">
        <div>
          <span className="c-blue fw-700 cursor-default" onClick={() => navigate("/admin/setup")}>
            Setup
          </span>
          <span className="fs-10 mh-5"><MdArrowForwardIos/></span>
          <span className="cursor-default c-text-gray">
            Export Data
          </span>
        </div>
        <div className="fw-700 fs-24 mt-5">Export Data</div>
      </div>
    )
  }

  const renderFieldCheckbox = (field, index) => {
    const isSelected = selectedExportFields.includes(field)
    return (
      <div className="mt-10" key={index}>
        <Checkbox checked={isSelected} onClick={() => toggleSelectedField(field)}>
          <div className="">{field}</div>
        </Checkbox>
      </div>
    )
  }

  const renderDataRow = (type) => {
    return (
      <Row className={`t-border pv-15`} gutter={[10,10]} align="middle" wrap={false}>
        <Col flex={1}>
          <div className="fw-700">
            { type }
          </div>
        </Col>
        <Col flex={0}>
          <div className="blue-link" onClick={() => onExportData(type)}>Export</div>
        </Col>
      </Row>
    )
  }

  const renderExportToFileModal = () => {
    return (
      <Modal visible={isExportModalVisible} closable={false} footer={null} width={600} wrapClassName="rounded-modal">
        <Row align="middle" className="b-border pb-10">
          <Col flex={1}>
            <div className="fw-700 fs-18">{ getModalTitle() }</div>
          </Col>
          <Col>
            <div className="display-flex" onClick={() => setExportModalVisible(false)}><MdOutlineClose size={30} color={"#CCC"}/></div>
          </Col>
        </Row>
        <Form form={exportForm} layout="vertical" name="edit-event" onFinish={confirmExport}>
          <Row gutter={[20,10]} className="mt-20">
            <Col xs={24} md={24}>
              { renderInputField("Filename", "filename", true, false, "Enter a filename...") }
            </Col>
            <Col xs={24} md={24}>
              { renderFormLabel("Fields")}
              { exportFields.map((x,i) => renderFieldCheckbox(x,i))}
            </Col>
          </Row>
          { isExporting ? (
            <button className="primary-button mt-30"><LoadingOutlined/></button>
          ) : (
            <button className="primary-button mt-30" type="submit">Export</button>
          )}
        </Form>
      </Modal>
    )
  }

  const renderContent = () => {
    return (
      <>
        <FloatingContainer className="ph-20 mb-80" verticalPadding={20} maxWidth={800}>
          <div className="shadow-card p-20">
            <Row className="b-border pb-15">
              <Col flex={1}>
                <div className="fs-20 fw-700 line-1">Company Data</div>
                <div className="bg-gray p-10 mt-10">Select an item below to start exporting your company's data to a CSV file.</div>
              </Col>
            </Row>
            { renderDataRow(EXPORT_TYPE_EVENTS) }
            { renderDataRow(EXPORT_TYPE_CLIENTS) }
            { renderDataRow(EXPORT_TYPE_VENUES) }
          </div>
        </FloatingContainer>
        { renderExportToFileModal() }
      </>
    )
  }

  if (isLoading) {
    return <LoadingSpinner/>
  }

  return (
    <AdminContent header={renderHeader()} body={renderContent()}/>
  );
}

export default ExportDataPage;
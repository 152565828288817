import React, { useEffect, useState, useContext } from "react";
import { Row, Col } from 'antd';
import { useNavigate } from 'react-router-dom';
import AdminContent from '../../components/adminContent';
import useApi from '../../hooks/useApi';
import AppContext from '../../app/context';
import LoadingSpinner from '../../components/loading';
import { FiFileText } from "react-icons/fi";
import { getEventQuestionnaires, getClientEvent } from '../../api';
import FloatingContainer from '../../components/floatingContainer'
import emptyStateIcon from '../../images/empty-state-icon.png';
import useDocumentTitle from '../../hooks/useDocumentTitle';

const QuestionnairesPage = () => {

  const [isLoading, setLoading] = useState(true);
  const [event, setEvent] = useState({})
  const [questionnaires, setQuestionnaires] = useState([]);

  useDocumentTitle("Questionnaries")
  const { auth } = useContext(AppContext);
  const [sendRequest] = useApi()
  
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
    refreshPage()
  }, []);

  const refreshPage = async () => {
    try {
      const eventResults = await sendRequest(getClientEvent(auth.user.user_id, auth.user.event_id))
      setEvent(eventResults)

      const eventQuestionnairesResults = await sendRequest(getEventQuestionnaires(eventResults.event_id))
      setQuestionnaires(eventQuestionnairesResults)

      setLoading(false)
    } catch {
      setLoading(false)
    }
  }

  const getAnsweredQuestions = (questionnaire) => {
    return questionnaire.sections.reduce((x,y) => {
      const numberAnswered = y.questions.filter(x => x.answer != "").length;
      return x + numberAnswered;
    }, 0)
  }

  const renderQuestionnaire = (questionnaire, index) => {
    const borderClass = index < questionnaires.length - 1 ? "b-border" : ""
    return (
      <div className={`ml-10 ${borderClass}`} key={index}>
        <Row key={index} align="middle" gutter={[15,15]} className="pv-10 pr-15" onClick={() => navigate(`/client/events/${event.event_id}/questionnaires/${questionnaire.event_questionnaire_id}`)}>
          <Col>
            <div className="card-icon"><FiFileText/></div>
          </Col>
          <Col flex={1}>
            <div className="fw-700">{questionnaire.questionnaire_name}</div>
            <div className="c-text-gray">{getAnsweredQuestions(questionnaire)} / {questionnaire.sections.reduce((x,y) => x + y.questions.length, 0)} questions answered</div>
          </Col>
        </Row>
      </div>
    )
  }

  const renderHeader = () => {
    return (
      <div className="fs-24 fw-700 p-20">Questionnaires</div>
    )
  }

  const renderQuestionnaires = () => {
    if (questionnaires.length == 0) {
      return (
        <div className="shadow-card ph-20 pv-50 text-center">
          <div>
            <img src={emptyStateIcon} width={200}/>
          </div>
          <div className="fs-18 fw-700 mt-30">
            No questionnaires yet
          </div>
          <div className="fs-14 fw-500 c-text-gray mt-10">
            You have not been assigned any questionnaires!
          </div>
        </div>
      )
    }
    
    return (
      <div className="shadow-card">
        { questionnaires.map((x,i) => renderQuestionnaire(x,i))}
      </div>
    )
  }

  const renderContent = () => {
    if (isLoading) {
      return <LoadingSpinner/>
    }
    return (
      <FloatingContainer className="ph-20" verticalPadding={20} maxWidth={600}>
        { renderQuestionnaires() }
      </FloatingContainer>
    )
  }

  return (
    <AdminContent header={renderHeader()} body={renderContent()}/>
  );
}

export default QuestionnairesPage;

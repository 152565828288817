import React, { useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { BackToHomeLink, DocLink } from '../../../components/docComponents'
import AdminContent from '../../../components/adminContent';
import FloatingContainer from '../../../components/floatingContainer'
import useDocumentTitle from '../../../hooks/useDocumentTitle';

const DocsQuestionnairesPage = () => {

  useDocumentTitle("Questionnaires | Documentation")
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const renderContent = () => {
    return (
      <FloatingContainer className="ph-20 bg-white" verticalPadding={20} maxWidth={1000}>
        <BackToHomeLink/>
        <div className="fw-700 fs-24 mt-20">Questionnaires</div>
        <div className="fw-500 fs-16 c-text-gray">The best way to gather information from your client.</div>
        <div className="b-border mv-20"></div>
        <div className="fw-700 fs-18">Creating a questionnaire</div>
        <div className="fw-500 mb-15 line-1-4 mt-15 bg-gray p-15 radius-8">
          Use our form builder to create questionnaires for different event types or service offerings. Once they are setup, simply assign one or more to a client when creating an event. 
        </div>
        <ol>
          <li className="mb-15">
            Go to the <DocLink to="/admin/questionnaires">Questionnaires</DocLink> page and create a new questionnaire.
          </li>
          <li className="mb-15">
            In the <b>“New Questionnaire”</b> modal, enter a name and select a template. You can choose an existing template we have created to give you a starting point or "Blank Questionnaire" to create one from scratch.
          </li>
          <li className="mb-15">
            Create sections and add questions. Drag and drop the questions within a section to reorder them if needed.
          </li>
          <li className="mb-15">
            Select the <b>"Save"</b> button.
          </li>
        </ol>
        <div className="ml-20 mt-20">
          <button className="small-primary-button mb-5" onClick={() => navigate("/admin/questionnaires")}>Go to questionnaires</button>
        </div>
        <div className="b-border mv-20"></div>
        <div className="fw-700 fs-18">Assigning a questionnaire</div>
        <ol>
          <li className="mb-15 mt-15">
            Go to the <DocLink to="/admin/events">Events</DocLink> page and create a <b>New Event</b>.
          </li>
          <li className="mb-15">
            Enter the details of the event.
          </li>
          <li className="mb-15">
            In the <b>"Questionnaires"</b> section, select one or more questionnaires from the dropdown to assign it to the event.
          </li>
          <li className="mb-15">
            Select the <b>"Save"</b> button.
          </li>
        </ol>
        <div className="b-border mv-50"></div>
      </FloatingContainer>
    )
  }

  return (
    <AdminContent body={renderContent()}/>
  );
}

export default DocsQuestionnairesPage;

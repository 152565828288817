import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Form, Input } from 'antd';
import { sendResetPasswordEmail } from '../../api';
import useDocumentTitle from '../../hooks/useDocumentTitle';

const ForgotPasswordPage = () => {
  useDocumentTitle("Forgot Password")
  const navigate = useNavigate();

  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [email, setEmail] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const onSubmit = values => {
    setEmail(values.email)
    sendResetPasswordEmail(values).then(response => {
      setSubmitSuccess(true)
    }).catch(() => setErrorMessage("Something went wrong. Please try again."))
  }

  const renderSuccess = () => {
    return (
      <div className="center-content-box">
        <div className="center-content-box--title">Email Sent!</div>
        <div className="center-content-box--subtitle">We have sent a link to <b>{email}</b>. Please follow the instructions in the email to reset your password.</div>
        <div className="center-content-box--form">
          <button className="primary-button" onClick={() => navigate("/login")}>Sign In</button>
        </div>
      </div>
    )
  }

  const renderErrorMessage = () => {
    if (errorMessage) {
      return (
        <div className="text-center c-red mt-20 mb-10">{errorMessage}</div>
      )
    }
    return null;
  }

  const renderForm = () => {
    return (
      <div className="center-content-box w-400">
        {/* <div className="text-center mt-30">
          <img src={djpcLogo1} style={{ width: 220 }}/>
        </div> */}
        <div className="center-content-box--title">Forgot password?</div>
        <div className="center-content-box--subtitle">No worries! Enter your email address below and we'll send you a link to reset your password.</div>
        <div className="center-content-box--form">
          <Form layout="vertical" name="admin-login" onFinish={onSubmit}>
            <div className="mb-10">
              <Form.Item
                name={"email"}
                rules={[
                  { required: true, message: `Email is required!`, validateTrigger: "onBlur"  },
                  { type: "email", message: `Please enter a valid email address`, validateTrigger: "onBlur" }
                ]}
              >
                <Input size="large" placeholder="Email" onChange={() => setErrorMessage("")}/>
              </Form.Item>
            </div>
            { renderErrorMessage() }
            <button className="primary-button" type="submit">Submit</button>
            <div className="center-content-box--link-container">
              <Link to="/login" className="center-content-box--link">Sign In</Link>
            </div>
          </Form>
        </div>
      </div>
    )
  }

  const renderContent = () => {
    if (submitSuccess) {
      return renderSuccess();
    } else {
      return renderForm();
    }
  }

  return (
    <div className="center-content-page">
      {/* <div className="login-form-logo">
        <img src={djpcLogo1} style={{ width: 220 }}/>
      </div> */}
      { renderContent() }
    </div>
  );
}

ForgotPasswordPage.propTypes = {};

export default ForgotPasswordPage;

import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {SortableContainer, sortableElement} from 'react-sortable-hoc';
import { isEmpty, isNull, cloneDeep } from "lodash";
import useApi from '../../../hooks/useApi';
import { getRank, getNewRank, sortByRank } from '../../../helpers/rankHelper';
import { 
  renderFormLabel,
  renderInputField,
  renderNumberField,
  renderSearchSelectField
} from '../../../components/formFields'
import { Row, Col, Input, Select, Modal, Button, Dropdown, Menu, Form } from 'antd';
import {
  getSystemRecommendedPlaylists, 
  getSystemSpecialEventTemplate, 
  createSystemSpecialEventSection, 
  updateSystemSpecialEventSection, 
  getSystemSpecialEventSections, 
  createSystemSpecialEventRecord, 
  updateSystemSpecialEventRank,
  updateSystemSpecialEventRecord,
  deleteSystemSpecialEventRecord,
  deleteSystemSpecialEventSection
} from '../../../api';
import LoadingSpinner from '../../../components/loading';
import AdminContent from '../../../components/adminContent';
import FloatingContainer from '../../../components/floatingContainer'
import { PlusOutlined, EditOutlined, EllipsisOutlined, DeleteOutlined, UpOutlined, DownOutlined } from '@ant-design/icons';
import { MdOutlineClose, MdArrowForwardIos } from "react-icons/md";
import { BsThreeDotsVertical } from "react-icons/bs";
import emptyStateImage from '../../../images/empty-document-icon.png';

const SystemSpecialEventsTemplatePage = () => {

  const [isLoading, setLoading] = useState(true);
  const [isAddEditSectionModalVisible, setAddEditSectionModalVisible] = useState(false);
  const [isAddEditModalVisible, setAddEditModalVisible] = useState(false);
  const [sectionName, setSectionName] = useState(null);
  const [isNewSpecialEvent, setNewSpecialEvent] = useState(false);
  const [sections, setSections] = useState([]);
  const [specialEventTemplate, setSpecialEventTemplate] = useState({});
  const [selectedSection, setSelectedSection] = useState({});
  const [selectedSpecialEventType, setSelectedSpecialEventType] = useState("");
  const [selectedSpecialEvent, setSelectedSpecialEvent] = useState({});
  const [recommendedPlaylists, setRecommendedPlaylists] = useState([]);

  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();

  const [form] = Form.useForm();
  const [sendRequest] = useApi()

  const templateId = params.id;

  useEffect(() => {
    window.scrollTo(0, 0);
    refreshPage()
  }, []);

  const refreshPage = async () => {
    try {
      const results = await sendRequest(getSystemSpecialEventTemplate(templateId));
      setSpecialEventTemplate(results)

      const sectionResults = await sendRequest(getSystemSpecialEventSections(templateId));
      setSections(sectionResults)

      const recPlaylistResults = await sendRequest(getSystemRecommendedPlaylists());
      setRecommendedPlaylists(recPlaylistResults)

      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  const onSubmitSpecialEvent = async (values) => {
    var songLimit = 0;
    if (selectedSpecialEventType == "playlist") {
      songLimit = values["song_limit"]
    } else if (selectedSpecialEventType == "single") {
      songLimit = 1;
    }

    try {
      if (isNewSpecialEvent) {
        const body = {
          special_event_name: values["special_event_name"],
          song_limit: songLimit,
          songs_allowed: selectedSpecialEventType == "playlist" || selectedSpecialEventType == "single",
          is_playlist: selectedSpecialEventType == "playlist",
          recommended_playlist_id: values["recommended_playlist_id"],
          rank: getRank(selectedSection.special_events)
        }
        await sendRequest(createSystemSpecialEventRecord(templateId, selectedSection.system_special_event_section_id, body));
        await refreshPage()
        setAddEditModalVisible(false)
      } else {
        const body = {
          special_event_name: values["special_event_name"],
          song_limit: songLimit,
          songs_allowed: selectedSpecialEventType == "playlist" || selectedSpecialEventType == "single",
          is_playlist: selectedSpecialEventType == "playlist",
          recommended_playlist_id: values["recommended_playlist_id"],
          rank: selectedSpecialEvent.rank
        }
        await sendRequest(updateSystemSpecialEventRecord(selectedSpecialEvent.system_special_event_id, body));
        await refreshPage()
        setAddEditModalVisible(false)
      }
    } catch {}
  }

  const onSpecialEventTypeChange = (value) => {
    setSelectedSpecialEventType(value)
    form.setFieldsValue({ "song_limit": null, "recommended_playlist_id": null })
  }

  const menu = (section, index) => {
    return (
      <Menu>
        <Menu.Item>
          <div onClick={() => handleEditSectionName(section, index)}>
            <EditOutlined style={{ marginRight: 8}}/> Edit Section Name
          </div>
        </Menu.Item>
        { index > 0 && (
          <Menu.Item>
            <div onClick={() => moveSection("up", index)}>
              <UpOutlined style={{ marginRight: 8}}/> Move Section Up
            </div>
          </Menu.Item>
        )}
        { index < sections.length - 1 && (
          <Menu.Item>
            <div onClick={() => moveSection("down", index)}>
              <DownOutlined style={{ marginRight: 8}}/> Move Section Down
            </div>
          </Menu.Item>
        )}
        <Menu.Item>
          <div onClick={() => handleSectionDelete(section)}>
            <DeleteOutlined style={{ marginRight: 8}}/> Delete Section
          </div>
        </Menu.Item>
      </Menu>
    )
  };

  const specialEventMenu = (event, index) => {
    return (
      <Menu>
        <Menu.Item>
          <div onClick={() => handleEditSpecialEvent(event)}>
            <EditOutlined style={{ marginRight: 8}}/> Edit
          </div>
        </Menu.Item>
        <Menu.Item>
          <div onClick={() => handleDeleteSpecialEvent(event)}>
            <DeleteOutlined style={{ marginRight: 8}}/> Delete
          </div>
        </Menu.Item>
      </Menu>
    )
  };
  const handleNewSectionCancel = () => {
    setSectionName("")
    setAddEditSectionModalVisible(false);
  };

  const handleAddEditSectionSave = async () => {
    try {
      if (isEmpty(selectedSection)) {
        const body = {
          section_name: sectionName,
          rank: getRank(sections)
        }
        await sendRequest(createSystemSpecialEventSection(templateId, body));
      } else {
        const body = {
          section_name: sectionName,
          rank: selectedSection.rank
        }
        await sendRequest(updateSystemSpecialEventSection(templateId, selectedSection.system_special_event_section_id, body));
      }
      await refreshPage()
    } finally {
      setAddEditSectionModalVisible(false);
    }
  };

  const handleSectionDelete = async (section) => {
    try {
      await sendRequest(deleteSystemSpecialEventSection(templateId, section.system_special_event_section_id));
      await refreshPage()
    } catch {}
  }

  const handleAddSection = () => {
    setSelectedSection({})
    setSectionName("")
    setAddEditSectionModalVisible(true)
  }

  const handleEditSectionName = (section, index) => {
    setSelectedSection(section)
    setSectionName(section.section_name)
    setAddEditSectionModalVisible(true)
  }

  const handleAddNewSpecialEvent = (section) => {
    form.resetFields()
    setSelectedSpecialEventType("single")
    setNewSpecialEvent(true)
    setSelectedSection(section)
    setAddEditModalVisible(true)
  }

  const handleEditSpecialEvent = (event) => {
    form.setFieldsValue(event)
    setSelectedSpecialEvent(event)
    if (event.is_playlist) {
      setSelectedSpecialEventType("playlist")
    } else if (event.songs_allowed) {
      setSelectedSpecialEventType("single")
    } else {
      setSelectedSpecialEventType("none")
    }
    setNewSpecialEvent(false)
    setAddEditModalVisible(true)
  }

  const handleDeleteSpecialEvent = async (event) => {
    try {
      await sendRequest(deleteSystemSpecialEventRecord(event.system_special_event_id));
      await refreshPage()
    } catch {}
  }

  const onSortEnd = async (oldIndex, newIndex, sectionIndex) => {
    if (oldIndex !== newIndex) {
      var newSections = cloneDeep(sections)
      var newSpecialEvents = cloneDeep(sections[sectionIndex].special_events);
      const rank = getNewRank(newSpecialEvents, oldIndex, newIndex)
      const specialEventId = newSpecialEvents[oldIndex].system_special_event_id
      newSpecialEvents[oldIndex].rank = rank;
      const sortedSpecialEvents = sortByRank(newSpecialEvents);
      newSections[sectionIndex].special_events = sortedSpecialEvents;
      setSections(newSections)
      try {
        await sendRequest(updateSystemSpecialEventRank(specialEventId, { rank: rank }))
      } catch {}
    }
  };

  const moveSection = async (direction, index) => {
    var newSections = cloneDeep(sections);
    var newIndex = direction == "up" ? index - 1 : index + 1
    const rank = getNewRank(newSections, index, newIndex)
    const sectionId = newSections[index].system_special_event_section_id
    newSections[index].rank = rank;
    const sortedSections = sortByRank(newSections);
    setSections(sortedSections)
    try {
      const body = {
        section_name: newSections[index].section_name,
        rank: rank
      }
      await sendRequest(updateSystemSpecialEventSection(templateId, sectionId, body));
    } catch {}
  }

  const getSpecialEventType = (event) => {
    if (event.is_playlist) {
      return "PLAYLIST";
    } else if (event.songs_allowed) {
      return "SPECIAL SONG"
    } else {
      return "NO SONGS"
    }
  }

  const getSpecialEventSubText = (event) => {
    return `Song Limit: ${event.song_limit == 0 ? "N/A" : event.song_limit}`
  }

  const SortableItem = sortableElement(({value, index}) => (
    <li className="">
      <div className="border radius-8 mb-10">
        <Row align="middle" gutter={[15]} className="p-20">
          <Col flex={1} onClick={() => handleEditSpecialEvent(value)}>
            <div className="fw-600 fs-16">{ value.special_event_name }</div>
            <div className="c-text-gray fs-12 mt-2"> <span className="bg-light-blue ph-5 c-blue fw-700 fs-10 mr-5" style={{ paddingTop: 2, paddingBottom: 2}}>{getSpecialEventType(value)}</span>{getSpecialEventSubText(value)}</div>
          </Col>
          <Col flex={0}>
            <Dropdown overlay={specialEventMenu(value, index)} placement="bottomRight" trigger="click">
              <div className="admin-icon-circle"><EllipsisOutlined/></div>
            </Dropdown>
          </Col>
          <Col flex={0}>
            <div>
              <BsThreeDotsVertical/>
            </div>
          </Col>
        </Row>
      </div>
    </li>
  ));

  const SortableList = SortableContainer(({items, sectionIndex}) => {
    return (
      <div className="">
        <ul className="special-event-row-container">
          {items.map((value, index) => (
            <SortableItem key={`item-${index}`} index={index} questionIndex={index} sectionIndex={sectionIndex} value={value} />
          ))}
        </ul>
      </div>
    );
  });

  const renderSection = (section, index) => {
    const specialEventRecords = !isNull(section.special_events) ? section.special_events : [];
    return (
      <div key={index} className="mb-20">
        <div className="shadow-card ph-20 pv-15">
          <Row align="middle" gutter={[15]} className="mb-15">
            <Col flex={1}>
              <div className="fw-700 fs-18">{ section.section_name }</div>
            </Col>
            <Col>
              <Dropdown overlay={menu(section, index)} placement="bottomRight" trigger="click">
                <div className="admin-icon-circle"><EllipsisOutlined/></div>
              </Dropdown>
            </Col>
          </Row>
          <SortableList items={specialEventRecords} sectionIndex={index} onSortEnd={({ oldIndex, newIndex }) => onSortEnd(oldIndex, newIndex, index)} helperClass="special-event-row-dragging" pressDelay={100} lockAxis="y" />
          <div className="admin-link mt-10" onClick={() => handleAddNewSpecialEvent(section)}><PlusOutlined/> Add Special Event</div>
        </div>
      </div>
    )
  }

  const renderAddEditSectionModal = () => {
    return (
      <Modal visible={isAddEditSectionModalVisible} footer={null} onCancel={handleNewSectionCancel} width={400} closable={false} maskClosable={false} wrapClassName="rounded-modal">
        <Row align="middle">
          <Col flex={1}>
            <div className="fw-700 fs-18">{isEmpty(selectedSection) ? "New Section" : "Edit Section"} </div>
          </Col>
          <Col>
            <div className="display-flex" onClick={handleNewSectionCancel}><MdOutlineClose size={30} color={"#CCC"}/></div>
          </Col>
        </Row>
        <Row gutter={[10,10]} className="mt-20">
          <Col xs={24}>
            { renderFormLabel("Section Name") }
            <Input size="large" value={sectionName} onChange={(e) => setSectionName(e.target.value)}/>
          </Col>
        </Row>
        <div className="admin-modal-footer">
          <Button className="admin-small-button secondary" onClick={handleNewSectionCancel}>Cancel</Button>
          <Button className="admin-small-button ml-10" onClick={handleAddEditSectionSave}>
            { isEmpty(selectedSection) ? "Add Section" : "Save" }
          </Button>
        </div>
      </Modal>
    )
  }

  const renderHeader = () => {
    return (
      <div className="p-20">
        <div>
          <span className="c-blue fw-700 cursor-default" onClick={() => navigate("/system/special-event-templates")}>
            Special Event Templates
          </span>
          <span className="fs-10 mh-5"><MdArrowForwardIos/></span>
          <span className="cursor-default c-text-gray">
            Edit
          </span>
        </div>
        <div className="fw-700 fs-24 mt-5">{ specialEventTemplate.template_name }</div>
      </div>
    )
  }

  const renderEmptyState = () => {
    return (
      <div className="shadow-card ph-20 pv-50 text-center">
        <img src={emptyStateImage} width="200"/>
        <div className="fs-14 c-text-gray mt-30">Get started by creating your first section.</div>
        <button className="page-title-button mt-20" onClick={handleAddSection}>Add Section</button>
      </div>
    )
  }

  const renderSections = () => {
    if (sections && sections.length == 0) {
      return renderEmptyState()
    }
    return (
      <>
        { sections && sections.map((x, i) => renderSection(x, i))}
        <div className="text-center">
          <button className="page-title-button" onClick={handleAddSection}>Add Section</button>
        </div>
      </>
    )
  }

  const getRecommendedPlaylistOptions = () => {
    return recommendedPlaylists.map(playlist => {
      return {
        value: playlist.system_recommended_playlist_id,
        text: playlist.playlist_name
      }
    })
  }

  const renderAddEditSpecialEvent = () => {
    return (
      <Modal visible={isAddEditModalVisible} footer={null} closable={false} wrapClassName="rounded-modal">
        <Form form={form} layout="vertical" name="client" onFinish={onSubmitSpecialEvent}>
          <Row align="middle">
            <Col flex={1}>
              <div className="fw-700 fs-18">{ isNewSpecialEvent ? "New Special Event" : "Edit Special Event" }</div>
            </Col>
            <Col>
              <div className="display-flex" onClick={() => setAddEditModalVisible(false)}><MdOutlineClose size={30} color={"#CCC"}/></div>
            </Col>
          </Row>
          <Row gutter={[10,10]} className="mt-15">
            <Col xs={24}>
              {renderInputField("Special Event Name", "special_event_name", true)}
            </Col>
            <Col xs={24}>
              { renderFormLabel("Type") }
              <Select
                placeholder={"Select Type"}
                value={selectedSpecialEventType}
                size='large'
                onChange={onSpecialEventTypeChange}
                style={{ width: '100%'}}
              >
                 <Select.Option value={"single"}>Special Song</Select.Option>
                 <Select.Option value={"playlist"}>Playlist</Select.Option>
                 <Select.Option value={"none"}>No Songs</Select.Option>
              </Select>
            </Col>
            { (selectedSpecialEventType == "playlist") && (
              <Col xs={24}>
                {renderNumberField("Song Limit", "song_limit", 0, true, "", false)}
              </Col>
            )}
            { (selectedSpecialEventType == "single" || selectedSpecialEventType == "playlist") && (
              <Col xs={24}>
                {renderSearchSelectField("Recommended Playlist", "recommended_playlist_id", "Select Playlist", getRecommendedPlaylistOptions(), false, null, false)}
              </Col>
            )}
          </Row>
          <div className="admin-modal-footer">
            <Button className="admin-small-button secondary" onClick={() => setAddEditModalVisible(false)}>Cancel</Button>
            <Button className="admin-small-button ml-10" htmlType="submit">
              Save
            </Button>
          </div>
        </Form>
      </Modal>
    )
  }

  const renderContent = () => {
    return (
      <FloatingContainer className="ph-20 mb-80" verticalPadding={20} maxWidth={800}>
        { renderSections() }
        { renderAddEditSectionModal() }
        { renderAddEditSpecialEvent() }
      </FloatingContainer>
    )
  }

  if (isLoading) {
    return <LoadingSpinner/>
  }

  return (
    <AdminContent header={renderHeader()} body={renderContent()}/>
  );
}

export default SystemSpecialEventsTemplatePage;

import { isArray } from "lodash";
import moment from "moment";
import StorageKeys from '../constants/storageKeys';
import { formatDateLong } from './dateHelper';

export const getEventFilters = () => {
  return JSON.parse(localStorage.getItem(StorageKeys.EVENT_FILTERS)) || {}
}

export const setEventFilters = (filter, assigned) => {
  const values = {
    filter,
    assigned
  }
  localStorage.setItem(StorageKeys.EVENT_FILTERS, JSON.stringify(values));
}

export const getShowAllEventsSummary = () => {
  return JSON.parse(localStorage.getItem(StorageKeys.SHOW_ALL_EVENTS_SUMMARY)) || false
}

export const setShowAllEventsSummary = (value) => {
  localStorage.setItem(StorageKeys.SHOW_ALL_EVENTS_SUMMARY, value);
}

export const getShowAllEventsCalendar = () => {
  return  JSON.parse(localStorage.getItem(StorageKeys.SHOW_ALL_EVENTS_CALENDAR)) || false
}

export const setShowAllEventsCalendar = (value) => {
  localStorage.setItem(StorageKeys.SHOW_ALL_EVENTS_CALENDAR, value);
}

export const isEventLocked = (event, user) => {
  if (user && user.user_type_id == 1) {
    // Do not lock event for admins
    return false
  } else if (isArray(event.account_details) && event.account_details.length > 0) {
    // If lockdown days exist, check if the event should be locked for the client
    const lockdownDays = event.account_details[0].lockdown_days
    const lockdownDate = moment(event.event_date, "YYYY-MM-DD").subtract(lockdownDays, 'days').startOf("day")
    return moment().isAfter(lockdownDate)
  }
  return false;
}

export const getLockdownDate = (event_date, lockdown_days = 0, settings) => {
  const lockdownDate = moment(event_date, "YYYY-MM-DD").subtract(lockdown_days, 'days').startOf("day")
  return formatDateLong(lockdownDate, settings)
}
import React, { useEffect, useState, useContext } from "react";
import { useNavigate, useLocation, Navigate } from "react-router-dom";
import qs from "qs";
import moment from "moment-timezone";
import { Row, Col, Form, Input, Modal, Checkbox, Select } from 'antd';
import { Country }  from 'country-state-city';
import { get, isEmpty } from 'lodash';
import jwt_decode from "jwt-decode";
import { EyeInvisibleOutlined, EyeTwoTone, LoadingOutlined, CheckCircleOutlined } from '@ant-design/icons';
import './signupPage.style.scss';
import AppContext from '../../../app/context';
import UserTypes from '../../../constants/userTypes';
import { signup, submitInterest } from '../../../api';
import FadeDownWhileInView from '../../../components/fadeDownWhileInView'
import FloatingContainer from '../../../components/floatingContainer'
import { validatePassword } from '../../../helpers/passwordHelper'
import { startTrialEvent, pageViewSignUpEvent } from '../../../helpers/facebookHelper'
import useDocumentTitle from '../../../hooks/useDocumentTitle';

const SignupPage = () => {

  const [isSubmitting, setSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [showPromoCode, setShowPromoCode] = useState(false);
  const [promoCode, setPromoCode] = useState("");
  const [token, setToken] = useState("");
  const [signupType, setSignupType] = useState("");

  useDocumentTitle("Sign Up")
  const navigate = useNavigate();
  const location = useLocation();
  const [signupForm] = Form.useForm();

  const { auth, setAuth } = useContext(AppContext);

  const promoActivated = false
  const appliedPromoCode = "WELCOME"

  useEffect(() => {
    window.scrollTo(0, 0)
    pageViewSignUpEvent()
    const queryStrings = qs.parse(location.search, { ignoreQueryPrefix: true })
    if (queryStrings.token) {
      setToken(queryStrings.token)
      try {
        const jwt = jwt_decode(queryStrings.token);
        if (jwt) {
          setSignupType(jwt.type)
        }
      } catch { }
    }
    signupForm.setFieldsValue({ country: "US" })
  }, [])

  const onSubmit = values => {

    // Get country name
    var countryName = ""
    const countries = Country.getAllCountries()
    const foundCountry = countries.find(x => x.isoCode == values["country"])
    if (!isEmpty(foundCountry)) {
      countryName = foundCountry.name
    }

    const body = {
      ...values,
      country_name: countryName,
      timezone: moment.tz.guess(),
      token: token,
      promo_code: isPromoActive() ? appliedPromoCode : null
    }

    if (isSubmitting) { return }
    setSubmitting(true)
    setErrorMessage("");
    signup(body).then(response => {
      setSubmitting(false)
      const status = get(response, 'data.status', "");
      const accessToken = get(response, 'data.access_token', "");
      if (status === "invalid-token") {
        setErrorMessage("This link is invalid. Please request a new one.")
      } else if (status === "token-expired") {
        setErrorMessage("This link is expired. Please request a new one.")
      } else if (status === "incorrect-email") {
        setErrorMessage("Please use the same email address that your invitation was sent to.")
      } else if (status === "user-exists") {
        setErrorMessage("There is an account already associated with this email address.")
      } else if (!isEmpty(accessToken)) {
        startTrialEvent()
        setAuth(response.data)
      }
    }).catch(error => {
      const errorCode = get(error, 'response.data.error', "");
      setErrorMessage("Something went wrong. Please try again.")
      setSubmitting(false)
    })
  }

  const isPromoActive = () => {
    const isSpecialSignup = signupType == "BETA" || signupType == "TEST" || signupType == "FREE"
    return !isEmpty(appliedPromoCode) && promoActivated && !isSpecialSignup
  }

  const submitInterestForm = (body) => {
    submitInterest(body).then(response => {
      showThankYou()
    }).catch((error) => console.log(error))
  }

  const showThankYou = () => {
    Modal.success({
      title: 'Thank you!',
      icon: <CheckCircleOutlined />,
      content: 'Thank you for your interest in DJ Planning Center! We are still in beta testing, but we will send you an email as soon as we are open to the public.',
      okText: 'OK',
      onOk: () => navigate("/")
    });
  }

  const renderError = () => {
    if (!isEmpty(errorMessage)) {
      return <div className="login-error mt-20">{errorMessage}</div>;
    }
    return null;
  }

  const renderSubmitButton = () => {
    var buttonText = "Start your free trial"
    if (signupType == "BETA") {
      buttonText = "Sign up for beta" 
    } else if (signupType == "FREE") {
      buttonText = "Create your free account"
    } else if (signupType == "TEST") {
      buttonText = "Create your test account"
    }
    if (isSubmitting) {
      return (
        <button className="primary-button" type="submit"><LoadingOutlined/></button>
      )
    } else {
      return (
        <button className="primary-button" type="submit">{buttonText}</button>
      )
    }
  }

  // const renderPromoCode = () => {
  //   if (!promoActivated) {
  //     return null;
  //   }
  //   if (!isEmpty(appliedPromoCode)) {
  //     return (
  //       <>
  //         <Col xs={24}>
  //           <Row align="middle" gutter={[10]} className="t-border b-border pv-15">
  //             <Col flex={0}>
  //               <BsCheckCircle style={{ fontSize: 30, color: "#27ae60"}}/>
  //             </Col>
  //             <Col flex={1}>
  //               <div className="fs-14"><span className="fw-700">{appliedPromoCode}</span> applied</div>
  //               <div className="fs-12 c-text-gray">$4.99 / month for 6 months</div>
  //             </Col>
  //             <Col flex={0}>
  //               { !promoActivated && (
  //                 <div className="blue-link" onClick={() => setAppliedPromoCode("")}>Remove</div>
  //               )}
  //             </Col>
  //           </Row>
  //         </Col>
  //       </>
  //     )
  //   } else if (showPromoCode) {
  //     return (
  //       <>
  //         <Col xs={14} sm={10}>
  //           <Input size="large" placeholder="Enter promo code" value={promoCode} onChange={(e) => setPromoCode(e.target.value)}/>
  //           <div className="blue-link mt-10" onClick={() => setShowPromoCode(false)}>- Hide Promo Code</div>
  //         </Col>
  //         <Col xs={10} sm={14}>
  //          <button className="page-title-button" type="button" style={{height: 50}} onClick={() => {setAppliedPromoCode(promoCode)}}>Apply</button>
  //         </Col>
  //       </>
  //     )
  //   } else {
  //     return (
  //       <Col xs={8}>
  //        <div className="blue-link" onClick={() => setShowPromoCode(true)}>+ Add Promo Code</div>
  //       </Col>
  //     )
  //   }
  // }

  const renderTitle = () => {
    var headerText = "Free 30-Day Trial"
    if (signupType == "BETA") {
      headerText = "Free 6-Month Trial" 
    } else if (signupType == "FREE") {
      headerText = "Free Account"
    } else if (signupType == "TEST") {
      headerText = "Test Account"
    }

    return <div className="fs-24 fw-700 text-center">{headerText}</div>;
  }

  const renderSubHeader = () => {
    if (signupType == "BETA") {
      return (
        <div className="bg-gray mb-30 p-15 radius-8 text-center fw-600">
          Thank you for volunteering to be a beta tester!
        </div>
      )
    } else if (signupType == "TEST") {
      return (
        <div className="bg-gray mb-30 p-15 radius-8 text-center fw-600">
          You are about to create a test account.
        </div>
      )
    } else if (signupType == "FREE") {
      return (
        <div className="bg-gray mb-30 p-15 radius-8 text-center fw-600">
          You are about to create a free account.
        </div>
      )
    }
    return null;
  }

  const renderContent = () => {
    const countries = Country.getAllCountries()
    return (
      <FloatingContainer verticalPadding={30} maxWidth={600}>
        <FadeDownWhileInView className="shadow-card p-30">
          { renderTitle() }
          <div className="fs-14 fw-500 c-text-gray text-center mb-30">No credit card required.</div>
          { isPromoActive() && (
            <div className="bg-gray radius-8 p-15 mb-30">
              <div className="fs-14 fw-700 line-1-2 text-center c-blue mb-10">Limited time only!</div>
              <div className="fs-18 fw-700 line-1-2 text-center mb-10">$4.99 per month for your first 6 months</div>
              <div className="fs-12 fw-500 line-1-2 text-center">(After 30-day free trial ends)</div>
            </div>
          )}
          { renderSubHeader() }
          <Form form={signupForm} layout="vertical" name="signup" onFinish={onSubmit}>
            <Row gutter={[20,20]}>
              <Col xs={24}>
                  <Form.Item
                  name={"country"}
                  rules={[
                    { required: true, message: `Country/Region is required!`, validateTrigger: 'onBlur' }
                  ]}
                >
                  <Select
                    showSearch
                    allowClear
                    placeholder={"Country/Region"}
                    size='large'
                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                  >
                    { countries.map(country => (
                      <Select.Option value={country.isoCode}>{country.name}</Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24}>
                  <Form.Item
                  name={"company_name"}
                  rules={[
                    { required: true, message: `Company Name is required!`, validateTrigger: 'onBlur' }
                  ]}
                >
                  <Input size="large" placeholder="Company Name"/>
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                  <Form.Item
                  name={"first_name"}
                  rules={[
                    { required: true, message: `First Name is required!`, validateTrigger: 'onBlur' }
                  ]}
                >
                  <Input size="large" placeholder="First Name"/>
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                  <Form.Item
                  name={"last_name"}
                  rules={[
                    { required: true, message: `Last Name is required!`, validateTrigger: 'onBlur' }
                  ]}
                >
                  <Input size="large" placeholder="Last Name"/>
                </Form.Item>
              </Col>
              <Col xs={24}>
                <Form.Item
                  name={"email"}
                  rules={[
                    { required: true, message: `Email is required!`, validateTrigger: 'onBlur' },
                    { type: "email", message: `Please enter a valid email address`, validateTrigger: 'onBlur' }
                  ]}
                >
                  <Input size="large" placeholder="Email"/>
                </Form.Item>
              </Col>
              <Col xs={24}>
                <Form.Item
                  name={"password"}
                  rules={[
                    { required: true, message: `Password is required!`, validateTrigger: 'onBlur' },
                    { validator: validatePassword, validateTrigger: 'onBlur'  }
                  ]}
                >
                  <Input.Password
                    size="large"
                    placeholder="Password"
                    iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                  />
                </Form.Item>
              </Col>
              <Col xs={24}>
                <Form.Item
                  name={"referral_source"}
                  rules={[
                    { required: true, message: `Referral source is required!`, validateTrigger: 'onBlur' }
                  ]}
                >
                  <Input size="large" placeholder="How did you hear about us?"/>
                </Form.Item>
              </Col>
              {/* { renderPromoCode() } */}
              <Col xs={24}>
                <Form.Item
                  name={"terms_accepted"}
                  valuePropName="checked"
                  rules={[
                    {
                      validator: (_, value) =>
                        value ? Promise.resolve() : Promise.reject(new Error('Please agree to the Terms & Conditions before proceeding')),
                    },
                  ]}
                >
                  <Checkbox>I agree to the <a href="/terms" target="_blank" className="blue-link">Terms of Service</a> and <a href="/privacy" target="_blank" className="blue-link">Privacy Policy</a></Checkbox>
                </Form.Item>
              </Col>
            </Row>
            { renderError() }
            { renderSubmitButton() }
          </Form>
        </FadeDownWhileInView>
      </FloatingContainer>
    )
  }

  if (auth.isAuthenticated && auth.user.user_type_id === UserTypes.ADMIN) {
    return <Navigate to="/admin/dashboard?type=welcome"/>;
  } else if (auth.isAuthenticated) {
    return <Navigate to="/"/>;
  }

  return (
    <div className="signup-page">
      { renderContent() }
    </div>
  );
}

SignupPage.propTypes = {};

export default SignupPage;

import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { Row, Col, Form, Modal, Dropdown, Menu } from 'antd';
import { isArray, isEmpty } from 'lodash';
import { getPricingGuides, createPricingGuide, deletePricingGuide, duplicatePricingGuide } from '../../../api';
import LoadingSpinner from '../../../components/loading';
import AdminContent from '../../../components/adminContent';
import useApi from '../../../hooks/useApi';
import { EditOutlined, DeleteOutlined, CopyOutlined } from '@ant-design/icons';
import { 
  renderInputField
} from '../../../components/formFields'
import FloatingContainer from '../../../components/floatingContainer'
import ErrorCard from '../../../components/errorCard';
import ShareModal from '../../../modals/shareModal';
import { MdOutlineClose, MdOutlineMoreHoriz, MdOutlineIosShare } from "react-icons/md";
import { AiOutlineTag } from "react-icons/ai";
import { FiEye } from "react-icons/fi";
import { BsArrowRight } from "react-icons/bs";
import emptyStateImage from '../../../images/empty-state-icon.png';
import useDocumentTitle from '../../../hooks/useDocumentTitle';

const PricingGuidesPage = () => {

  const [isLoading, setLoading] = useState(true);
  const [isError, setError] = useState(false);
  const [isNewModalVisible, setNewModalVisible] = useState(false);
  const [isDuplicateModalVisible, setDuplicateModalVisible] = useState(false);
  const [isConfirmRemoveVisible, setConfirmRemoveVisible] = useState(false);
  const [isShareModalVisible, setShareModalVisible] = useState(false);
  const [shareLink, setShareLink] = useState("");

  const [pricingGuides, setPricingGuides] = useState([]);
  const [selectedPricingGuide, setSelectedPricingGuide] = useState({});

  useDocumentTitle("Pricing Guides")
  const [sendRequest] = useApi()
  const navigate = useNavigate();

  const [form] = Form.useForm();
  const [duplicateForm] = Form.useForm();

  useEffect(() => {
    window.scrollTo(0, 0);
    refreshPage()
  }, []);

  const refreshPage = async () => {
    try {
      const guideResults = await sendRequest(getPricingGuides());
      setPricingGuides(guideResults);
    } catch {
      setError(true)
    } finally {
      setLoading(false)
    }
  }

  const menu = (item) => {
    return (
      <Menu>
        <Menu.Item>
          <div onClick={() => handleEdit(item)}>
            <EditOutlined style={{ marginRight: 8}}/> Edit
          </div>
        </Menu.Item>
        <Menu.Item>
          <div onClick={() => handleDuplicate(item)}>
            <CopyOutlined style={{ marginRight: 8}}/> Duplicate
          </div>
        </Menu.Item>
        <Menu.Item>
          <div onClick={() => navigate(`/pricing-guide/${item.pricing_guide_id}`)}>
            <FiEye style={{ marginRight: 8}}/> Preview
          </div>
        </Menu.Item>
        <Menu.Item>
          <div onClick={() => handleShare(item)}>
            <MdOutlineIosShare style={{ marginRight: 8}}/> Share Link
          </div>
        </Menu.Item>
        <Menu.Item>
          <div onClick={() => handleDelete(item)}>
            <DeleteOutlined style={{ marginRight: 8}}/> Delete
          </div>
        </Menu.Item>
      </Menu>
    )
  };
  
  const onNewClick = () => {
    form.setFieldsValue({ package_name: "" })
    setNewModalVisible(true);
  }

  const handleCancel = () => {
    setNewModalVisible(false);
  };

  const handleDuplicateCancel = () => {
    setDuplicateModalVisible(false);
  };

  const handleEdit = (guide) => {
    navigate(`/admin/pricing-guides/${guide.pricing_guide_id}/edit`)
  };

  const handleDuplicate = (guide) => {
    setSelectedPricingGuide(guide)
    const newName = `Copy of ${guide.internal_name}`
    duplicateForm.setFieldsValue({ internal_name: newName })
    setDuplicateModalVisible(true);
  };

  const handleDelete = (guide) => {
    setSelectedPricingGuide(guide)
    setConfirmRemoveVisible(true)
  };

  const handleShare = (guide) => {
    setShareModalVisible(true);
    setShareLink(`/pricing-guide/${guide.pricing_guide_id}`)
  };

  const confirmDelete = async () => {
    try {
      await sendRequest(deletePricingGuide(selectedPricingGuide.pricing_guide_id))
      await refreshPage()
    } finally {
      setConfirmRemoveVisible(false)
    }
  }

  const onSubmit = async (values) => {
    try {
      const body = {
        display_name: "Pricing Guide",
        internal_name: values["internal_name"],
        display_contact_form: true,
        active: true
      }
      const results = await sendRequest(createPricingGuide(body))
      navigate(`/admin/pricing-guides/${results.pricing_guide_id}/edit`)
    } catch {}
  }

  const onDuplicateSubmit = async (values) => {
    try {
      const body = {
        internal_name: values["internal_name"],
      }
      const results = await sendRequest(duplicatePricingGuide(selectedPricingGuide.pricing_guide_id, body))
      navigate(`/admin/pricing-guides/${results.pricing_guide_id}/edit`)
    } catch {
      
    }
  }

  const getSubText = (request) => {
    if (isEmpty(request)) {
      return ""
    }
    const packages = isArray(request.services.packages) ? request.services.packages : []
    const addOnCategories = isArray(request.services.add_ons) ? request.services.add_ons : []
    const addOns = addOnCategories.reduce((x,y) => {
      const newArray = x.concat(y.add_ons)
      return newArray;
    }, [])
    return `${packages.length} ${packages.length == 1 ? "package" : "packages"}, ${addOns.length} ${addOns.length == 1 ? "add-on" : "add-ons"}`
  }
  
  const renderHeader = () => {
    return (
      <Row align="middle" className="p-20">
        <Col flex={1}>
          <div className="fs-24 fw-700">Pricing Guides</div>
          <div className="blue-link flex-row flex-1 flex-middle" onClick={() => navigate("/admin/docs/pricing-guides")}>Learn more <BsArrowRight style={{ marginLeft: 5 }}/></div>
        </Col>
        <Col>
          { pricingGuides.length > 0 && (
            <button className="page-title-button" onClick={onNewClick}>New Guide</button>
          )}
        </Col>
      </Row>
    )
  }

  const renderPricingGuide = (guide, index) => {
    return (
      <div key={index} className="flex-row p-10 b-border">
        <div onClick={() => handleShare(guide)}>
          <div className="card-icon"><AiOutlineTag/></div>
        </div>
        <div className="flex-col justify-content-center flex-1 mh-15" onClick={() => handleShare(guide)}>
          <div className="fs-14 fw-700 line-1-2">{guide.internal_name}</div>
          <div className="fs-14 fw-500 c-text-gray mt-2">{getSubText(guide)}</div>
        </div>
        <div className="display-flex flex-middle mr-5">
          <Dropdown overlay={menu(guide)} placement="bottomRight" trigger="click">
            <div className="dots-container">
              <MdOutlineMoreHoriz style={{ fontSize: 24, color: '#999'}}/>
            </div>
          </Dropdown>
        </div>
      </div>
    )
  }

  const renderPricingGuidesBox = () => {
    return (
      <div className="shadow-card">
        { pricingGuides.map((x,i) => renderPricingGuide(x,i)) }
      </div>
    )
  }

  const renderPricingGuides = () => {
    if (pricingGuides.length == 0) {
      return (
        <div className="shadow-card ph-20 pv-50 text-center">
          <div>
            <img src={emptyStateImage} width={250}/>
          </div>
          <div className="fs-18 fw-700 mt-30">
            No pricing guides yet
          </div>
          <div className="fs-14 fw-500 c-text-gray">
            Create a guide to get started!
          </div>
          <button className="primary-button mt-30" style={{ width: 250 }} onClick={onNewClick}>Create Pricing Guide</button>
          <div className="mt-10">
            <div className="blue-link" onClick={() => navigate("/admin/docs/pricing-guides")}>Learn More</div>
          </div>
        </div>
      )
    } else {
      return renderPricingGuidesBox()
    }
  }

  const renderContent = () => {
    if (isLoading) {
      return <LoadingSpinner/>
    }
    if (isError) {
      return <ErrorCard/>
    }
    return (
      <FloatingContainer className="ph-20" verticalPadding={20} maxWidth={800}>
        { renderPricingGuides() }
        { renderModal() }
        { renderConfirmRemoveModal() }
        { renderShareModal() }
        { renderDuplicateModal() }
      </FloatingContainer>
    )
  }

  const renderModal = () => {
    return (
      <Modal visible={isNewModalVisible} footer={null} closable={false} wrapClassName="rounded-modal">
        <Row align="middle">
          <Col flex={1}>
            <div className="fw-700 fs-18">New Pricing Guide</div>
          </Col>
          <Col>
            <div className="display-flex" onClick={handleCancel}><MdOutlineClose size={30} color={"#CCC"}/></div>
          </Col>
        </Row>
        <Form form={form} layout="vertical" name="new-pricing-guide" onFinish={onSubmit}>
          <Row gutter={[10,10]} className="mt-15">
            <Col xs={24}>
              {renderInputField("Internal Name", "internal_name", true)}
            </Col>
          </Row>
          <div className="admin-modal-footer">
            <button className="primary-button" type="submit" onClick={() => {}}>Continue</button>
            <div className="text-center mt-15">
              <div className="blue-link" onClick={handleCancel}>Cancel</div>
            </div>
          </div>
        </Form>
      </Modal>
    )
  }

  const renderDuplicateModal = () => {
    return (
      <Modal visible={isDuplicateModalVisible} footer={null} closable={false} wrapClassName="rounded-modal">
        <Row align="middle">
          <Col flex={1}>
            <div className="fw-700 fs-18">Duplicate Pricing Guide</div>
          </Col>
          <Col>
            <div className="display-flex" onClick={handleDuplicateCancel}><MdOutlineClose size={30} color={"#CCC"}/></div>
          </Col>
        </Row>
        <Form form={duplicateForm} layout="vertical" name="duplicate-pricing-guide" onFinish={onDuplicateSubmit}>
          <Row gutter={[10,10]} className="mt-15">
            <Col xs={24}>
              {renderInputField("Internal Name", "internal_name", true)}
            </Col>
          </Row>
          <div className="admin-modal-footer">
            <button className="primary-button" type="submit" onClick={() => {}}>Save</button>
            <div className="text-center mt-15">
              <div className="blue-link" onClick={handleDuplicateCancel}>Cancel</div>
            </div>
          </div>
        </Form>
      </Modal>
    )
  }

  const renderConfirmRemoveModal = () => {
    return (
      <Modal visible={isConfirmRemoveVisible} closable={false} footer={null} width={400} wrapClassName="rounded-modal">
        <div className="text-right" onClick={() => setConfirmRemoveVisible(false)}><MdOutlineClose size={30} color={"#CCC"}/></div>
        <div className="mt-10">
          <div className="fw-700 fs-20 text-center">Remove Pricing Guide</div>
          <div className="fw-500 fs-14 mt-20 mb-20 text-center">Are you sure you would like to remove <span className="fw-700">{selectedPricingGuide.internal_name}</span>? This action cannot be undone.</div>
          <button className="primary-button warning" type="button" onClick={() => confirmDelete()}>Remove Pricing Guide</button>
          <div className="text-center mt-15">
            <div className="blue-link" onClick={() => setConfirmRemoveVisible(false)}>Cancel</div>
          </div>
        </div>
      </Modal>
    )
  }

  const renderShareModal = () => {
    return (
      <ShareModal
        isVisible={isShareModalVisible}
        setVisible={setShareModalVisible}
        title="Share Pricing Guide"
        link={shareLink}
      />
    )
  }

  return (
    <AdminContent header={renderHeader()} body={renderContent()}/>
  );
}

export default PricingGuidesPage;

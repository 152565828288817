import React, { useEffect, useState, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { isUndefined } from "lodash";
import moment from "moment-timezone";
import useApi from '../../hooks/useApi';
import { 
  renderEmailField,
  renderInputField,
  renderTextAreaField
} from '../../components/formFields'
import { Row, Col, Form, Modal } from 'antd';
import { getSystemLead, createSystemLead, updateSystemLead } from '../../api';
import LoadingSpinner from '../../components/loading';
import AdminContent from '../../components/adminContent';
import FloatingContainer from '../../components/floatingContainer'
import { MdArrowForwardIos } from "react-icons/md";
import { LoadingOutlined } from '@ant-design/icons';
import AppContext from '../../app/context';

const NewLeadPage = () => {

  const [isLoading, setLoading] = useState(true);
  const [lead, setLead] = useState({});
  const [isSaving, setSaving] = useState(false);

  const { auth, setAuth } = useContext(AppContext);
  const navigate = useNavigate();
  const params = useParams();
  const [form] = Form.useForm();
  const [sendRequest] = useApi()

  const leadId = params.id;
  const isNew = isUndefined(leadId);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (!isNew) {
      refreshPage();
    } else {
      setLoading(false)
    }
  }, []);

  const refreshPage = async () => {
    try {
      const leadResults = await sendRequest(getSystemLead(leadId))
      const formValues = {
        first_name: leadResults.first_name,
        last_name: leadResults.last_name,
        email: leadResults.email,
        company_name: leadResults.company_name,
        notes: leadResults.notes
      }
      setLead(leadResults)
      form.setFieldsValue(formValues)
    } catch (error) {
    } finally {
      setLoading(false)
    }
  }

  const onSubmit = async (values) => {
    try {
      setSaving(true)
      if (isNew) {
       await sendRequest(createSystemLead(values))
      } else {
        await sendRequest(updateSystemLead(leadId, values))
      }
      navigate("/system/leads");
    } catch {
      Modal.error({
        title: "Oops!",
        content: 'Something went wrong. Please try again.',
        onOk: () => {}
      });
      setSaving(false)
    }
  }

  const renderSaveButton = () => {
    if (isSaving) {
      return (
        <button className="page-title-button small-100"><LoadingOutlined/></button>
      )
    } else {
      return (
        <button className="page-title-button small-100" onClick={() => form.submit()}>Save</button>
      )
    }
  }

  const renderHeader = () => {
    return (
      <div className="p-20">
        <div>
          <span className="c-blue fw-700 cursor-default" onClick={() => navigate(-1)}>
            Leads
          </span>
          <span className="fs-10 mh-5"><MdArrowForwardIos/></span>
          <span className="cursor-default c-text-gray">
            Detail
          </span>
        </div>
        <div className="fw-700 fs-24 mt-5">{isNew ? "New Lead" : lead.first_name + " " + lead.last_name}</div>
      </div>
    )
  }

  const renderContent = () => {
    return (
      <FloatingContainer className="ph-20 mb-80" verticalPadding={20} maxWidth={800}>
        <div className="shadow-card p-20 mb-15">
          <div className="fs-20 fw-700 mb-20 line-1">Lead Details</div>
          <Form form={form} layout="vertical" name="lead" onFinish={onSubmit}>
            <Row gutter={[10,10]}>
              <Col xs={24} md={12}>
                {renderInputField("First Name", "first_name", true)}
              </Col>
              <Col xs={24} md={12}>
                {renderInputField("Last Name", "last_name", true)}
              </Col>
              <Col xs={24}>
                {renderEmailField("Email", "email", true)}
              </Col>
              <Col xs={24}>
                {renderInputField("Company Name", "company_name", false)}
              </Col>
              <Col xs={24}>
                {renderTextAreaField("Notes", "notes", false)}
              </Col>
            </Row>
          </Form>
        </div>
        <div className="sticky-footer p-0">
          <div className="p-15 bg-white t-border">
            { renderSaveButton() }
          </div>
        </div>
      </FloatingContainer>
    )
  }

  if (isLoading) {
    return <LoadingSpinner/>
  }

  return (
    <AdminContent header={renderHeader()} body={renderContent()} headerHeight={96}/>
  );
}

export default NewLeadPage;

import React, { useEffect, useState, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import useApi from '../../../../hooks/useApi';
import useCurrency from '../../../../hooks/useCurrency';
import useAccountSettings from '../../../../hooks/useAccountSettings';
import { Row, Col, Input, Checkbox, notification, Grid } from 'antd';
import { getEventInvoice, sendEventInvoice } from '../../../../api';
import LoadingSpinner from '../../../../components/loading';
import FloatingContainer from '../../../../components/floatingContainer'
import { HiOutlineArrowLeft } from "react-icons/hi";
import { UserOutlined, LoadingOutlined } from '@ant-design/icons';
import { isEmpty, isNull } from "lodash";
import { formatCurrencyString } from '../../../../helpers/contractHelper';
import { formatDateMedium } from '../../../../helpers/dateHelper';
import AppContext from '../../../../app/context';
const BASE_URL = process.env.REACT_APP_BASE_URL;

const SendInvoicePage = () => {

  const [isLoading, setLoading] = useState(true);
  const [isSending, setSending] = useState(false);
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [sendMeCopy, setSendMeCopy] = useState(false);

  const [eventInvoice, setEventInvoice] = useState({});
  const [billToFirstName, setBillToFirstName] = useState("");
  const [billToLastName, setBillToLastName] = useState("");
  const [billToEmail, setBillToEmail] = useState("");
  const [dueDate, setDueDate] = useState("");
  const [amountDue, setAmountDue] = useState("Payment");

  const navigate = useNavigate();
  const params = useParams();
  const [sendRequest] = useApi()
  const [_c, _s, getCurrency] = useCurrency()
  const [_d, _sa, getAccountSettings] = useAccountSettings()
  const { auth, _ } = useContext(AppContext);
  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();

  const eventId = params.event_id;
  const invoiceId = params.id;

  useEffect(() => {
    window.scrollTo(0, 0);
    refreshPage()
  }, []);

  const refreshPage = async () => {
    try {
      const results = await sendRequest(getEventInvoice(eventId, invoiceId))
      const accountCurrency = await getCurrency()
      const accountSettings = await getAccountSettings()
      setEventInvoice(results)
      var firstName = ""
      if (results.bill_to_user && !isEmpty(results.bill_to_user)) {
        const user = results.bill_to_user
        setBillToFirstName(user.first_name)
        setBillToLastName(user.last_name)
        setBillToEmail(user.email)
        firstName = ` ${user.first_name}`
      }
      const invoiceDueDate = results.due_date ? formatDateMedium(results.due_date, accountSettings, true) : ""
      const invoiceAmountDue = results.amount_due ? formatCurrencyString(results.amount_due, accountCurrency.code) : formatCurrencyString(0, accountCurrency.code)
      const invoiceSubject = results.company_name ? `Invoice #${results.invoice_number} from ${results.company_name}` : "Invoice"
      setDueDate(invoiceDueDate)
      setAmountDue(invoiceAmountDue)
      setSubject(invoiceSubject)
      setMessage(`Hi${firstName}, \n\nHere is your invoice for ${invoiceAmountDue} that's due by ${invoiceDueDate}. Click the button below for details.\n\nThanks,\n${auth.user.first_name} ${auth.user.last_name}`)
    } finally {
      setLoading(false)
    }
  }

  const sendInvoice = async () => {
    try {
      setSending(true)
      const body = {
        subject: subject,
        message: message,
        send_copy: sendMeCopy
      }
      await sendRequest(sendEventInvoice(eventId, invoiceId, body))
      navigate(`/admin/events/${eventId}/invoices/${invoiceId}/confirmation`)
    } catch {
      setSending(false)
    }
  }

  const copyLink = async (signer) => {
    notification.success({
      message: 'Success!',
      description: 'The link has been copied to your clipboard.',
      duration: 3
    });
  }

  const renderHeader = () => {
    if (screens.md) {
      return (
        <div className="event-contract--header p-20">
          <div className="flex-row flex-middle">
            <div className="fs-20 display-flex mr-10" onClick={() => navigate(`/admin/events/${eventId}/invoices/${invoiceId}/preview`)}><HiOutlineArrowLeft/></div>
            <div className="fs-18 fw-700 mr-15 flex-ellipsis">
              Invoice #{ eventInvoice.invoice_number }
            </div>
            <span className="bg-light-blue c-blue pv-5 ph-10 fs-10 fw-600 nowrap">{!isEmpty(eventInvoice.status) ? eventInvoice.status.replace("_", " ") : ""}</span>
            <div className="flex-1"></div>
            <div className="blue-link mr-10" onClick={() => navigate(`/admin/events/${eventId}?tab=documents`)}>Back to event</div>
          </div>
        </div>
      )
    } else {
      return (
        <div className="event-contract--header p-20">
          <div className="flex-row flex-middle">
            <div className="fs-20 display-flex mr-10" onClick={() => navigate(`/admin/events/${eventId}/invoices/${invoiceId}/preview`)}><HiOutlineArrowLeft/></div>
            <div className="fs-18 fw-700 mr-15 flex-ellipsis">
              Invoice #{ eventInvoice.invoice_number }
            </div>
            <span className="bg-light-blue c-blue pv-5 ph-10 fs-10 fw-600 nowrap">{!isEmpty(eventInvoice.status) ? eventInvoice.status.replace("_", " ") : ""}</span>
            <div className="flex-1"></div>
          </div>
          <div className="text-center mt-10">
            <div className="blue-link" onClick={() => navigate(`/admin/events/${eventId}?tab=documents`)}>Back to event</div>
          </div>
        </div>
      )
    }
  }

  const renderBillToUser = () => {
    var clientPhoto = null;
    return (
      <Row gutter={[15,15]} align="middle" className="mb-20">
        <Col>
          {/* { clientPhoto ? (
            <div className="small-profile-photo size-60" style={{ backgroundImage: `url(${clientPhoto})`}} onClick={() => displayPhoto(clientPhoto)}></div>
          ) : ( */}
            <div className="display-flex">
              <div className="user-profile-circle small"><UserOutlined/></div>
            </div>
          {/* )} */}
        </Col>
        <Col flex={1}>
          <div className="fs-14 fw-700">{billToFirstName} {billToLastName}</div>
          <div className="fs-14 fw-500 c-text-gray line-1-1">{billToEmail}</div>
        </Col>
      </Row>
    )
  }

  const renderContent = () => {
    return (
      <>
        <FloatingContainer className="ph-20 mb-20 bg-gray" verticalPadding={20} maxWidth={screens.lg ? 1200 : 700}>
          <Row gutter={[20,20]}>
            <Col xs={24} lg={12}>
              <div className="bg-white border p-20">
                <Row gutter={[20,20]}>
                  <Col flex={0}>
                    <div className="fw-700">To</div>
                  </Col>
                  <Col flex={1}>
                    { renderBillToUser() }
                  </Col>
                </Row>
                <Row gutter={[20,20]} align="middle" className="mt-20">
                  <Col xs={24}>
                    <div className="fw-700 mb-5">Subject</div>
                    <Input size="large" value={subject} onChange={(e) => setSubject(e.target.value)}/>
                  </Col>
                  <Col xs={24}>
                    <div className="fw-700 mb-5">Message</div>
                    <Input.TextArea rows={8} size="large" value={message} onChange={(e) => setMessage(e.target.value)}/>
                  </Col>
                  <Col xs={12}>
                    <Checkbox value={true} onChange={(e) => setSendMeCopy(e.target.checked)}>{ "Send me a copy" }</Checkbox>
                  </Col>
                  <Col xs={12}>
                    <div className="text-right">
                      { isSending ? (
                        <button className="small-primary-button" style={{ width: 150 }}><LoadingOutlined/></button>
                      ) : (
                        <button className="small-primary-button" style={{ width: 150 }} onClick={() => sendInvoice()}>Send Invoice</button>
                      )}
                    </div>
                  </Col>
                  <Col xs={24}>
                    <div className="t-border text-center pt-15">
                      <div className="bg-white p-15 radius-8">
                        <div className="fw-700">Prefer to copy a link and send in your own text or email?</div>
                        { renderInvoiceLink() }
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col xs={24} lg={12}>
              { !screens.lg ? (
                <div className="fw-700 fs-18 mb-10 text-center t-border pt-20 mt-10">Email Preview</div>
              ) : (
                <div className="fw-700 fs-18 mb-10 text-center">Email Preview</div>
              )}
              <div className="bg-white border ph-20 pv-50">
                { !isNull(eventInvoice.logo) && !isEmpty(eventInvoice.logo) ? (
                  <div className="text-center mb-30">
                    <img src={eventInvoice.logo} style={{ maxWidth: 200 }}/>
                  </div>
                ) : (
                  <div className="text-center bg-gray mh-20 mb-30 p-20 radius-8" style={{ border: "1px dashed #CCC"}}>
                    <div className="fw-700 mb-5">Company Logo</div>
                    <div className="fs-12">Upload a logo on the <span className="blue-link fs-12" onClick={() => navigate("/admin/setup/branding")}>Branding</span> page to display it here.</div>
                  </div>
                )}
                <div className="bg-gray p-20 text-center">
                  <div className="mb-5">Invoice #{eventInvoice.invoice_number}</div>
                  <div className="fw-700 fs-20 mb-5">{amountDue}</div>
                  <div className="">Due by {dueDate}</div>
                </div>
                <div className="mt-30" style={{ whiteSpace: "pre-line" }}>{ message }</div>
                <div className="text-center mt-30">
                  <button className="primary-button" style={{ width: 250 }} onClick={() => {}}>View Invoice</button>
                </div>
              </div>
            </Col>
          </Row>
        </FloatingContainer>
      </>
    )
  }

  const renderInvoiceLink = () => {
    const link = `${BASE_URL}/invoice/${invoiceId}`
    return (
      <div className="mt-15">
        <div className="display-flex flex-row flex-middle bg-gray p-15 mt-5 radius-4">
          <div className="flex-1" style={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'pre', userSelect: 'none' }}>{link}</div>
          <CopyToClipboard text={link} onCopy={() => copyLink()}>
            <div className="ml-5 blue-link">Copy link</div>
          </CopyToClipboard>
        </div>
      </div>
    )
  }

  if (isLoading) {
    return (
      <div className="event-contract--container">
        <LoadingSpinner/>
      </div>
    )
  }

  return (
    <div className="event-contract--container">
      { renderHeader()}
      <div className="event-contract--body">
        <div className="event-contract--content">
          { renderContent() }
        </div>
      </div>
    </div>
  );
}

export default SendInvoicePage;

import moment from 'moment-timezone';
import { isUndefined, isNull } from "lodash";
import DateFormatTypes from '../constants/dateFormatTypes';
import TimeFormatTypes from '../constants/timeFormatTypes';

const isValid = (value) => {
  return !isUndefined(value) && !isNull(value) && value != ""
}

export const formatDateTimeLong = (date, settings) => {
  if (isValid(date) && !isUndefined(settings)) {
    if (settings.date_format == DateFormatTypes.MDY && settings.time_format == TimeFormatTypes.HOUR_12) {
      return moment(date).format("MMM D, YYYY @ h:mma")
    } else if (settings.date_format == DateFormatTypes.MDY && settings.time_format == TimeFormatTypes.HOUR_24) {
      return moment(date).format("MMM D, YYYY @ HH:mm")
    } else if (settings.date_format == DateFormatTypes.DMY && settings.time_format == TimeFormatTypes.HOUR_12) {
      return moment(date).format("D MMM YYYY @ h:mma")
    } else if (settings.date_format == DateFormatTypes.DMY && settings.time_format == TimeFormatTypes.HOUR_24) {
      return moment(date).format("D MMM YYYY @ HH:mm")
    }
  }
  return "";
}

export const formatDateTimeShort = (date, settings, utc = false) => {
  if (isValid(date) && !isUndefined(settings)) {
    var dateFormat = ""
    if (settings.date_format == DateFormatTypes.MDY && settings.time_format == TimeFormatTypes.HOUR_12) {
      dateFormat = "MMM D, YYYY @ h:mma"
    } else if (settings.date_format == DateFormatTypes.MDY && settings.time_format == TimeFormatTypes.HOUR_24) {
      dateFormat = "MMM D, YYYY @ HH:mm"
    } else if (settings.date_format == DateFormatTypes.DMY && settings.time_format == TimeFormatTypes.HOUR_12) {
      dateFormat = "D MMM YYYY @ h:mma"
    } else if (settings.date_format == DateFormatTypes.DMY && settings.time_format == TimeFormatTypes.HOUR_24) {
      dateFormat = "D MMM YYYY @ HH:mm"
    }
    if (utc) {
      return moment.utc(date).format(dateFormat)
    } else {
      return moment(date).format(dateFormat)
    }
  }
  return "";
}

export const formatDateLong = (date, settings) => {
  if (isValid(date) && !isUndefined(settings)) {
    if (settings.date_format == DateFormatTypes.MDY) {
      return moment(date).format("dddd, MMMM D, YYYY")
    } else if (settings.date_format == DateFormatTypes.DMY) {
      return moment(date).format("dddd, D MMMM YYYY")
    }
  }
  return "";
}

export const formatDateMedium = (date, settings, utc = false) => {
  if (isValid(date) && !isUndefined(settings)) {
    var dateFormat = ""
    if (settings.date_format == DateFormatTypes.MDY) {
      dateFormat = "MMMM D, YYYY"
    } else if (settings.date_format == DateFormatTypes.DMY) {
      dateFormat = "D MMMM, YYYY"
    }
    if (utc) {
      return moment.utc(date).format(dateFormat)
    } else {
      return moment(date).format(dateFormat)
    }
  }
  return "";
 }

 export const formatDateShort = (date, settings, utc = false) => {
  if (isValid(date) && !isUndefined(settings)) {
    var dateFormat = ""
    if (settings.date_format == DateFormatTypes.MDY) {
      dateFormat = "MMM D, YYYY"
    } else if (settings.date_format == DateFormatTypes.DMY) {
      dateFormat = "D MMM, YYYY"
    }
    if (utc) {
      return moment.utc(date).format(dateFormat)
    } else {
      return moment(date).format(dateFormat)
    }
  }
  return "";
 }

 export const formatDate = (date, settings, utc = false) => {
  if (isValid(date) && !isUndefined(settings)) {
    var dateFormat = ""
    if (settings.date_format == DateFormatTypes.MDY) {
      dateFormat = "MM/DD/YYYY"
    } else if (settings.date_format == DateFormatTypes.DMY) {
      dateFormat = "DD/MM/YYYY"
    }
    if (utc) {
      return moment.utc(date).format(dateFormat)
    } else {
      return moment(date).format(dateFormat)
    }
  }
  return "";
 }

export const formatEventDateLong = (date, settings) => {
  if (isValid(date) && !isUndefined(settings)) {
    if (settings.date_format == DateFormatTypes.MDY) {
      return moment(date, "YYYY-MM-DD").format("dddd, MMMM D, YYYY")
    } else if (settings.date_format == DateFormatTypes.DMY) {
      return moment(date, "YYYY-MM-DD").format("dddd, D MMMM, YYYY")
    }
  }
  return moment(date, "YYYY-MM-DD").format("dddd, MMMM D, YYYY");
}

export const formatEventDateMedium = (date, settings) => {
  if (isValid(date) && !isUndefined(settings)) {
     if (settings.date_format == DateFormatTypes.MDY) {
       return moment(date, "YYYY-MM-DD").format("MMMM D, YYYY")
     } else if (settings.date_format == DateFormatTypes.DMY) {
       return moment(date, "YYYY-MM-DD").format("D MMMM, YYYY")
     }
   }
   return "";
 }

export const formatEventDateShort = (date, settings) => {
  if (isValid(date) && !isUndefined(settings)) {
    if (settings.date_format == DateFormatTypes.MDY) {
      return moment(date, "YYYY-MM-DD").format("MMM D, YYYY")
    } else if (settings.date_format == DateFormatTypes.DMY) {
      return moment(date, "YYYY-MM-DD").format("D MMM, YYYY")
    }
  }
  return "";
 }

 export const formatEventDate = (date, settings) => {
  if (isValid(date) && !isUndefined(settings)) {
     if (settings.date_format == DateFormatTypes.MDY) {
       return moment(date, "YYYY-MM-DD").format("MM/DD/YYYY")
     } else if (settings.date_format == DateFormatTypes.DMY) {
       return moment(date, "YYYY-MM-DD").format("DD/MM/YYYY")
     }
   }
   return "";
 }

export const formatEventDayMonth = (date, settings) => {
  if (isValid(date) && !isUndefined(settings)) {
    if (settings.date_format == DateFormatTypes.MDY) {
      return moment(date, "YYYY-MM-DD").format("MMM D").toUpperCase()
    } else if (settings.date_format == DateFormatTypes.DMY) {
      return moment(date, "YYYY-MM-DD").format("D MMM").toUpperCase()
    }
  }
  return "";
 }

export const formatTime = (time, settings) => {
  if (isValid(time) && !isUndefined(settings)) {
    if (settings.time_format == TimeFormatTypes.HOUR_12) {
      return moment(time, "H:mm").format("h:mm A")
    } else if (settings.time_format == TimeFormatTypes.HOUR_24) {
      return moment(time, "H:mm").format("HH:mm")
    }
  }
  return moment(time, "H:mm").format("h:mm A")
}

export const getDatePickerFormat = (settings) => {
  if (!isUndefined(settings)) {
    if (settings.date_format == DateFormatTypes.MDY) {
      return "MM/DD/YYYY"
    } else if (settings.date_format == DateFormatTypes.DMY) {
      return "DD/MM/YYYY"
    }
  }
  return "MM/DD/YYYY"
 }

 export const getDatePickerFormatMedium = (settings) => {
  if (!isUndefined(settings)) {
    if (settings.date_format == DateFormatTypes.MDY) {
      return "MMM D, YYYY"
    } else if (settings.date_format == DateFormatTypes.DMY) {
      return "D MMM, YYYY"
    }
  }
  return "MMM D, YYYY"
 }
import React, { useEffect, useState } from "react";
import { Row, Col, Table } from 'antd';
import { useNavigate } from 'react-router-dom';
import { getSystemRecommendedPlaylists } from '../../api';
import useApi from '../../hooks/useApi';
import LoadingSpinner from '../../components/loading';
import AdminContent from '../../components/adminContent';

const SystemRecommendedPlaylistsPage = (props) => {

  const [isLoading, setLoading] = useState(true);
  const [playlists, setPlaylists] = useState([]);

  const navigate = useNavigate();
  const [sendRequest] = useApi()

  const columns = [
    {
      title: 'Playlist Name',
      dataIndex: 'playlist_name',
      key: 'playlist_name',
      render: (text) => text
    },
    {
      title: 'Songs',
      dataIndex: 'songs',
      key: 'songs',
      render: (text, record) => record.songs.length
    }
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
    refreshPage()
  }, []);

  const refreshPage = () => {
    sendRequest(getSystemRecommendedPlaylists()).then(response => {
      setPlaylists(response);
      setLoading(false)
    }).catch((e) => setLoading(false))
  }

  const renderHeader = () => {
    return (
      <Row align="middle" className="p-20">
        <Col flex={1}>
          <div className="admin-page-title">Recommended Playlists</div>
        </Col>
        <Col>
          <button className="page-title-button" onClick={() => navigate("/system/recommended-playlists/new")}>New</button>
        </Col>
      </Row>
    )
  }

  const renderContent = () => {

    if (!playlists) {
      return null;
    }

    const data = playlists.map((x, i) => {
      return {
        ...x,
        key: i
      }
    });

    return (
      <div className="p-15" style={{ maxWidth: 800 }}>
        <div className="admin-page-content-box">
          <Table 
            columns={columns} 
            dataSource={data}
            pagination={false}
            onRow={(record, rowIndex) => {
              return {
                onClick: e => navigate(`/system/recommended-playlists/${record.system_recommended_playlist_id}/edit`)
              };
            }}
           />
        </div>
      </div>
    )
  }

  if (isLoading) {
    return <LoadingSpinner/>
  }

  return (
    <AdminContent header={renderHeader()} body={renderContent()}/>
  );
}

export default SystemRecommendedPlaylistsPage;

import React from 'react';
import { motion } from 'framer-motion';

const FadeWhileInView = props => {
  const { children, className, delay, duration } = props;

  return (
    <motion.div 
      className={className ?? ""}
      initial="hidden"
      whileInView="visible"
      viewport={{ once: true }}
      transition={{ duration: duration ?? 1, delay: delay ?? 0.1 }}
      variants={{
        visible: { opacity: 1, y: 0 },
        hidden: { opacity: 0, y: 0 }
      }}
    >
      { children }
    </motion.div>
  );
};

export default FadeWhileInView;

import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import moment from 'moment';
import { Grid, Row, Col } from 'antd';
import { Document, Page, Text, View, StyleSheet, PDFDownloadLink, Font } from '@react-pdf/renderer';
import { FiDownload} from "react-icons/fi";
import { cloneDeep, isArray, isEmpty, isNull, isUndefined } from "lodash";
import useApi from '../../hooks/useApi';

import { getAllEventSpecialEvents } from '../../api';

const SpecialEventsPreviewPage = (props) => {

  const [isLoading, setLoading] = useState(true);
  const [playlists, setPlaylists] = useState([]);
  const [specialEventSections, setSpecialEventSections] = useState([]);
  const [eventName, setEventName] = useState("");
  const [eventDate, setEventDate] = useState(moment());

  const navigate = useNavigate();
  const params = useParams();
  const [sendRequest] = useApi()

  useEffect(() => {
    window.scrollTo(0, 0)
    const eventId = params.id;
    if (!eventId) {
      navigate("/", { replace: true })
    } else {
      sendRequest(getAllEventSpecialEvents(eventId)).then(response => {
        const sectionResults = isArray(response.special_event_sections) ? response.special_event_sections : []
        const filteredSpecialEvents = sectionResults.map((x,i) => {
          const specialEvents = isArray(x.special_events) ? x.special_events : []
          return {
            ...x,
            special_events: specialEvents.filter(x => x.active)
          }
        })
        setSpecialEventSections(filteredSpecialEvents)
        setEventName(response.event_name)
        setEventDate(moment(response.event_date))
      }).catch((e) => setLoading(false))
    }
  }, [])

  const styles = StyleSheet.create({
    page: {
      paddingTop: 35,
      paddingBottom: 65,
      paddingHorizontal: 35
    },
    row: {
      flexDirection: 'row',
      borderBottom: "1px solid #e8e8e8"
    },
    col: {
      flex: 1,
      paddingVertical: 5
    },
    section: {
      marginBottom: 20
    },
    header: {
      marginBottom: 20
    },
    headerRow: {
      flexDirection: 'row',
      borderTop: "1px solid #CCC",
      borderBottom: "1px solid #CCC",
      backgroundColor: "#F7F7F7"
    },
    headerRowText: {
      fontSize: 10,
      fontFamily: "Helvetica",
      paddingHorizontal: 5
    },
    headerText: {
      fontSize: 16,
      fontFamily: "Helvetica-Bold",
      textAlign: "center"
    },
    dateText: {
      fontSize: 12,
      fontFamily: "Helvetica",
      textAlign: "center"
    },
    text: {
      fontSize: 12,
      fontFamily: "Helvetica",
      paddingHorizontal: 5
    },
    playlistHeader: {
      marginBottom: 10
    },
    playlistName: {
      fontSize: 14,
      fontFamily: "Helvetica-Bold",
      paddingHorizontal: 5
    }
  });

  const renderDocumentSong = (song, index) => {
    return (
      <View style={styles.row} key={index}>
        <View style={styles.col}>
          <Text style={styles.text}>{song.title}</Text>
        </View>
        <View style={styles.col}>
          <Text style={styles.text}>{song.artist}</Text>
        </View>
      </View>
    )
  }

  const renderDocumentPlaylist = (playlist, index) => {
    if (playlist.songs.length == 0) {
      return null;
    }
    return (
      <View style={styles.section} key={index}>
        <View style={styles.playlistHeader}>
          <Text style={styles.playlistName}>{playlist.playlist_name}</Text>
        </View>
        <View style={styles.headerRow}>
          <View style={styles.col}>
            <Text style={styles.headerRowText}>TITLE</Text>
          </View>
          <View style={styles.col}>
            <Text style={styles.headerRowText}>ARTIST</Text>
          </View>
        </View>
        { playlist.songs.map((x,i) => renderDocumentSong(x,i))}
      </View>
    )
  }

  const renderDocument = () => {
    return (
      <Document>
        <Page size="A4" style={styles.page}>
          <View style={styles.header}>
            <Text style={styles.headerText}>{ eventName }</Text>
            <Text style={styles.dateText}>{ eventDate.format("dddd, MMMM D, YYYY") }</Text>
          </View>
          { playlists.map((x,i) => renderDocumentPlaylist(x,i))}
        </Page>
      </Document>
    )
  }

  const renderSong = (song, index) => {
    return (
      <div key={index} className="fw-500">
        { song.title } - { song.artist }
      </div>
    )
  }

  const renderSpecialEvent = (event, index) => {
    const eventSongs = isArray(event.songs) ? event.songs : []
    return (
      <div key={index}>
        <div className="fw-700 b-border pv-15 mh-15">
          { event.special_event_name }
          { eventSongs.map((x,i) => renderSong(x,i))}
          { eventSongs.length == 0 && (
            <div>--</div>
          )}
        </div>
      </div>
    )
  }

  const renderSection = (section, index) => {
    const specialEventsArray = isArray(section.special_events) ? section.special_events : []
    if (specialEventsArray.length == 0) {
      return null;
    }
    return (
      <div key={index}>
        <div className="fs-20 fw-700 mt-30 mb-10 ml-5">{ section.section_name }</div>
        <div className="border radius-8">
          { specialEventsArray.map((x,i) => renderSpecialEvent(x,i))}
        </div>
      </div>
    )
  }

  const renderContent = () => {
    return (
      <div>
        <div style={{ margin: "0 auto", paddingTop: 30, paddingBottom: 30, maxWidth: 1000}}>
          {/* <div className="text-right pb-10 ph-20">
            <PDFDownloadLink document={ renderDocument() } fileName={`event_${eventDate.format("MM.DD.YYYY")}.pdf`}>
              <button className="small-primary-button" style={{ width: 170 }}><FiDownload size="14" style={{ marginRight: 10}}/>Download PDF</button>
            </PDFDownloadLink>
          </div> */}
          <div className="print-card">
            <div className="text-center fs-24 fw-700">{ eventName }</div>
            <div className="text-center fs-16 c-text-gray">{eventDate.format("dddd, MMMM D, YYYY")}</div>
            <div className="mt-10">
              { specialEventSections.map((x,i) => renderSection(x,i))}
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="bg-gray" style={{ minHeight: '100vh'}}>
      { renderContent() }
    </div>
  );
}

export default SpecialEventsPreviewPage;

import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { isUndefined } from 'lodash';
import LoadingSpinner from '../../components/loading';
import { IoIosCloseCircleOutline } from "react-icons/io";

import { getShortLink } from '../../api';

const ShortLinkPage = () => {

  const [isLoading, setLoading] = useState(true);

  const navigate = useNavigate();
  const params = useParams();

  useEffect(() => {
    window.scrollTo(0, 0)
    getLink()
  }, [])

  const getLink = async () => {
    try {
      // Show error if short code does not exist in the params 
      if (!params.code) {
        setLoading(false)
        return
      }
      // Fetch the short link using the code
      const results = await getShortLink(params.code)
      // Check if a link was found
      const link = results.data.url
      if (isUndefined(link)) {
        // Show error if link is not found
        setLoading(false)
      } else {
        // Navigate to the link
        window.location.replace(link)
      }
    } catch {
      setLoading(false)
    }
  }

  const renderContent = () => {
    if (isLoading) {
      return <LoadingSpinner/>
    }
    return renderInvalidMessage();
  }

  const renderInvalidMessage = () => {
    return (
      <div className="center-content-box text-center">
        <div className="mt-40">
          <IoIosCloseCircleOutline size={100} color={"#e74c3c"}/>
        </div>
        <div className="fw-700 fs-20 mt-10 ph-20">Invalid Link</div>
        <div className="fw-500 c-text-gray ph-20">This link is either incorrect or expired. <br/> Please reach out to your DJ for assistance.</div>
        <div className="center-content-box--form">
          <button className="primary-button" onClick={() => navigate("/", { replace: true })}>OK</button>
        </div>
      </div>
    )
  }

  return (
    <div className="blank-layout">
      <div className="center-content-page">
        { renderContent() }
      </div>
    </div>
  );
}

export default ShortLinkPage;

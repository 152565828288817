import React, { useEffect, useState } from "react";
import { Row, Col, Table, Modal, Input } from 'antd';
import { cloneDeep, isNull } from 'lodash';
import moment from "moment";
import { useNavigate } from 'react-router';
import useApi from '../../hooks/useApi';
import { getSystemPaymentLogs } from '../../api';
import LoadingSpinner from '../../components/loading';
import AdminContent from '../../components/adminContent';
import FloatingContainer from '../../components/floatingContainer'
import { MdOutlineClose } from "react-icons/md";

const PaymentsPage = () => {

  const [isLoading, setLoading] = useState(true);
  const [accountId, setAccountId] = useState("");
  const [logs, setLogs] = useState([]);
  const [isLogModalVisible, setLogModalVisible] = useState(false);
  const [selectedLog, setSelectedLog] = useState({});
  const [isMoreAvailable, setIsMoreAvailable] = useState(true);

  const navigate = useNavigate();
  const [sendRequest] = useApi()

  const activeColumns = [
    {
      title: 'ID',
      dataIndex: 'log_id',
      key: 'log_id',
      render: (text, record) => text
    },
    {
      title: 'Account',
      dataIndex: 'account_id',
      key: 'account_id',
      render: (text, record) => text
    },
    {
      title: 'Platform',
      dataIndex: 'platform',
      key: 'platform',
      render: (text, record) => text
    },
    {
      title: 'Message',
      dataIndex: 'message',
      key: 'message',
      render: (text, record) => text
    },
    {
      title: 'Date',
      dataIndex: 'create_datetime',
      key: 'create_datetime',
      width: "180px",
      render: (text) => !isNull(text) ? moment(text).format("YYYY-MM-DD h:mmA") : "--"
    }
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
    refreshPage()
  }, []);

  const refreshPage = async (forceRefresh) => {
    try {
      const lastId = logs.length > 0 && !forceRefresh ? logs[logs.length - 1].log_id : 0
      const accountIdFilter = accountId ? accountId : 0
      const limit = 100
      const paymentLogsResults = await sendRequest(getSystemPaymentLogs(lastId, limit, accountIdFilter));

      if (forceRefresh) {
        setLogs(paymentLogsResults)
      } else {
        var newLogs = cloneDeep(logs)
        newLogs = newLogs.concat(paymentLogsResults)
        setLogs(newLogs)
      }

      setIsMoreAvailable(paymentLogsResults.length == limit)
    } finally {
      setLoading(false)
    }
  }

  const onSelectLog = (log) => {
    setSelectedLog(log)
    setLogModalVisible(true)
  }

  const renderHeader = () => {
    return (
      <Row align="middle" className="p-20">
        <Col flex={1}>
          <div className="admin-page-title">Payment Logs</div>
        </Col>
      </Row>
    )
  }

  const renderDate = (date) => {
    if (date) {
      return moment.utc(date).format("MM/DD/YYYY @ hh:mma") 
    } else {
      return "--"
    }
  }

  const renderLogModal = () => {
    return (
      <Modal visible={isLogModalVisible} closable={false} footer={null} width={800} wrapClassName="rounded-modal">
        <div className="text-right" onClick={() => setLogModalVisible(false)}><MdOutlineClose size={30} color={"#CCC"}/></div>
        <div className="mt-10">
          <div className="fw-700 fs-20 ml-10">Log Info</div>
          <Row align="middle" className="p-20" gutter={[20,20]}>
            <Col xs={12}>
              <div className="fw-700">Account ID</div>
              <div className="">{ selectedLog.account_id }</div>
            </Col>
            <Col xs={12}>
              <div className="fw-700">Company Name</div>
              <div className="">{ selectedLog.company_name }</div>
            </Col>
            <Col xs={12}>
              <div className="fw-700">Platform</div>
              <div className="">{ selectedLog.platform }</div>
            </Col>
            <Col xs={12}>
              <div className="fw-700">Date</div>
              <div className="">{ renderDate(selectedLog.create_datetime) }</div>
            </Col>
            <Col xs={24}>
              <div className="fw-700">Message</div>
              <div className="">{ selectedLog.message }</div>
            </Col>
            <Col xs={24}>
              <div className="fw-700">Metadata</div>
              <div className="">{ JSON.stringify(selectedLog.metadata)}</div>
            </Col>
          </Row>
          <div className="t-border mv-30"></div>
          <button className="primary-button" type="button" onClick={() => navigate(`/system/accounts/${selectedLog.account_id}`)}>View Account Details</button>
        </div>
      </Modal>
    )
  }

  const renderContent = () => {
    return (
      <FloatingContainer className="ph-20" verticalPadding={20} maxWidth={1000}>
        <Row align="middle" gutter={[15,15]}>
          <Col xs={24}>
            <div className="shadow-card p-30 text-center">
              <div className="fs-40 fw-700">
                <Input placeholder="Account ID" size="large" value={accountId} onChange={(e) => setAccountId(e.target.value)}/>
              </div>
              <button className="small-primary-button mt-20" onClick={() => refreshPage(true)}>SEARCH</button>
            </div>
          </Col>
        </Row>
        <div className="t-border mv-30"></div>
        <div className="shadow-card">
          <Table 
            columns={activeColumns} 
            dataSource={logs}
            pagination={false}
            onRow={(record, rowIndex) => {
              return {
                onClick: e => onSelectLog(record)
              };
            }}
          />
          { isMoreAvailable && (
            <div className="text-center">
              <div className="blue-link p-15" onClick={() => refreshPage()}>Load More</div>
            </div>
          )}
        </div>
        {renderLogModal()}
      </FloatingContainer>
    )
  }

  if (isLoading) {
    return <LoadingSpinner/>
  }

  return (
    <AdminContent header={renderHeader()} body={renderContent()}/>
  );
}

export default PaymentsPage;

import React from 'react';
import { Row, Col } from 'antd';
import clientHomeImage from '../images/client-home.png';
import djHomeImage from '../images/dj-events-event-details-1.png';
import FloatingContainer from './floatingContainer'
import FadeDownWhileInView from './fadeDownWhileInView'

const FeatureSections = props => {
  return (
    <>
      <FloatingContainer className="bg-white" verticalPadding={50} maxWidth={1000}>
        <FadeDownWhileInView delay={1.0} className="fs-30 fw-700 text-center mb-10">Two apps in one.</FadeDownWhileInView>
        <FadeDownWhileInView delay={1.0} className="fs-16 c-text-gray text-center mb-30 ph-20">A single app that displays a different experience based on who logs in.</FadeDownWhileInView>
        <Row gutter={[30,30]} className="p-20">
          <Col xs={24} md={12}>
            <FadeDownWhileInView delay={1.5} className="text-center">
              <img src={clientHomeImage} className="app-image"/>
              <div className="fs-24 fw-700 line-1-2 mt-30">The Client</div>
              <div className="fs-16 c-text-gray mt-10 line-1-4">As a client, you'll be able to view a countdown to your event, create playlists, view reccommended songs, build a timeline, and answer questionnaires that are created by your DJ. You can also share links with other vendors so that everyone can stay on the same page.</div>
            </FadeDownWhileInView>
          </Col>
          <Col xs={24} md={12}>
            <FadeDownWhileInView delay={1.5} className="text-center">
              <img src={djHomeImage} className="app-image"/>
              <div className="fs-24 fw-700 line-1-2 mt-30">The DJ</div>
              <div className="fs-16 c-text-gray mt-10 line-1-4">As a DJ, you'll have real-time access to your client's information as they are planning their event. Collaborate on playlists and timelines and initiate an email or text message with the tap of a button. You can also quickly check your availability and complete tasks based on the workflows you setup.</div>
            </FadeDownWhileInView>
          </Col>
        </Row>
      </FloatingContainer>
    </>
  );
};

export default FeatureSections;

import React, { useEffect, useState, useRef } from "react";
import { Row, Col, Input, Modal, Grid, Tooltip } from 'antd';
import { isEmpty, isUndefined, isNull } from 'lodash';
import useApi from '../hooks/useApi';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { MdOutlineSearch, MdOutlineClose, MdCheck, MdOutlineFileDownload, MdPlayArrow, MdPause } from "react-icons/md";
import { FiPlus } from "react-icons/fi";
import { searchMusic, getV2EventSpecialEventRecommendedPlaylist } from '../api';
import spotifyLogo from '../images/spotify-logo.png';
import appleMusicLogo from '../images/apple-music-logo.png';
import cardBackgroundImage from '../images/card-background-3.jpg';
import emptyMusicIcon from '../images/empty-search-icon.png';
import LoadingSpinner from '../components/loading';

const MusicModal = props => {
  const { title = "Select a Song", isVisible = false, onSelect, onCancel, selectedEvent = {}, eventName = "", eventId = "", selectedSongs = [], recommendedPlaylistGroupList= [], selectedPlatform = "spotify", startImportPlaylist } = props;

  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();
  const [sendRequest] = useApi()

  // Dynamic modal styles
  const modalStyle = screens.md ? {} : { top: 0 }
  const modalBodyStyle = screens.md ? {minHeight: 500, padding: 0 } : { minHeight: 'calc(100vh - 16px)', padding: 0 }

  // State
  const [searchText, setSearchText] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [recommendedSongs, setRecommendedSongs] = useState([]);
  const [selectedRecommendedPlaylist, setSelectedRecommendedPlaylist] = useState({});
  const [currentSong, setCurrentSong] = useState(null);
  const [isPlaying, setPlaying] = useState(false);
  const [isSearching, setSearching] = useState(false);

  const timerRef = useRef(null);
  const audioRef = useRef(null);

  useEffect(() => {
    if (selectedEvent.event_special_event_id && selectedEvent.recommended_playlist_id) {
      sendRequest(getV2EventSpecialEventRecommendedPlaylist(eventId, selectedEvent.event_special_event_id, selectedEvent.recommended_playlist_id)).then((response) => {
        setRecommendedSongs(response.songs ?? [])
      })
    } else {
      setRecommendedSongs([])
    }
  }, [selectedEvent]);

  useEffect(() => {
    if (isVisible) {
      setSearching(false)
      setSearchText("")
      setSearchResults([]);
    } else {
      if (!isNull(audioRef.current)) {
        audioRef.current.pause()
        audioRef.current.removeEventListener('ended', () => setPlaying(false));
      }
    }
  }, [isVisible]);

  const handleCancel = () => {
    onCancel();
  };

  const onSearchChange = (e) => {
    if (e.target.value == "") {
      setSearchResults([]);
    }
    setSearchText(e.target.value)
  }

  const onKeyPress = (e) => {
    setSearching(true)
    if (timerRef.current) {
      clearTimeout(timerRef.current)
    }
    timerRef.current = setTimeout(onSearch, 500)
  }

  const onSearch = async () => {
    if (searchText) {
      try {
        const results = await sendRequest(searchMusic(selectedPlatform, searchText))
        setSearchResults(results)
      } finally {
        setSearching(false)
      }
    } else {
      setSearching(false)
    }
  }

  const playPauseSong = (song) => {
    if (!song.preview_url) {
      return
    }
    if (isNull(audioRef.current)) {
      // No song has been played yet, so set the song and start playing
      setCurrentSong(song)
      var audio = new Audio(song.preview_url)
      audio.addEventListener('ended', () => setPlaying(false));
      audio.play();
      audioRef.current = audio
      setPlaying(true)
    } else if (isPlaying && song.song_id == currentSong.song_id) {
      // Current song is playing, so pause it
      audioRef.current.pause()
      setPlaying(false)
    } else if (!isPlaying  && song.song_id == currentSong.song_id) {
      // Current song was paused, so play it
      audioRef.current.play()
      setPlaying(true)
    } else if (isPlaying && song.song_id != currentSong.song_id) {
      // New song is selected while another one is playing, so pause song, replace it, and play it
      audioRef.current.pause()
      audioRef.current.removeEventListener('ended', () => setPlaying(false));
      var audio = new Audio(song.preview_url)
      audio.addEventListener('ended', () => setPlaying(false));
      audio.play();
      audioRef.current = audio
      setCurrentSong(song)
      setPlaying(true)
    } else if (!isPlaying && song.song_id != currentSong.song_id) {
      // New song is selected while another one is playing, so pause song, replace it, and play it
      audioRef.current.removeEventListener('ended', () => setPlaying(false));
      var audio = new Audio(song.preview_url)
      audio.addEventListener('ended', () => setPlaying(false));
      audio.play();
      audioRef.current = audio
      setCurrentSong(song)
      setPlaying(true)
    }
  }

  const searchPrefix = (
    <MdOutlineSearch
      style={{
        fontSize: 18,
        color: '#CCC',
      }}
    />
  );

  const renderPlayButton = (song) => {
    if (!isNull(song.preview_url)) {
      return (
        <div className="song-card--dots-container" onClick={() => playPauseSong(song)}>
          { isPlaying && song.song_id == currentSong.song_id ? (
            <MdPause style={{ fontSize: 24, color: "#536DFE"}}/>
          ) : (
            <MdPlayArrow style={{ fontSize: 24, color: "#536DFE"}}/>
          )}
        </div>
      )
    } else {
      return (
        <Tooltip title="A preview of this song is not available.">
          <div className="song-card--dots-container">
            <MdPlayArrow style={{ fontSize: 24, color: "#CCC"}}/>
          </div>
        </Tooltip>
      )
    }
  }

  const renderSearchResults = (items, item, index) => {
    const alreadyAdded = !isEmpty(selectedSongs.find(x => x.song_id == item.song_id));
    const borderClass = (index + 1) < items.length ? "b-border" : ""
    return (
      <div className={`song-card no-shadow ${borderClass}`} key={index}>
        <div className="song-card--row-1">
          <div className="song-card--image" onClick={() => playPauseSong(item)}>
            <img src={item.image} style={{ width: 50 }}/>
          </div>
          <div className="song-card--title-artist" onClick={() => playPauseSong(item)}>
            <div className="song-card--text fs-14 fw-700 line-1-5">{item.title}</div>
            <div className="song-card--text fs-14 c-text-gray line-1-5">{item.artist}</div>
          </div>
          <div className="song-card--icon" style={{ marginRight: 0 }}>
            {renderPlayButton(item)}
          </div>
          <div className="song-card--icon">
            { alreadyAdded ? (
              <div className="display-flex mr-10">
                <MdCheck style={{ fontSize: 24, color: '#00b894'}}/>
              </div>
            ) : (
              <div className="plus-container mr-5" onClick={() => onSelect(item)}>
                <FiPlus style={{ fontSize: 20, color: '#536DFE'}}/>
              </div>
            )}
          </div>
        </div>
      </div>
    )
  }

  const renderPlaylistGroupList = (playlist, index) => {
    return (
      <Col xs={12} sm={8} key={index}>
        <div className="bg-blue radius-8" style={{ position: "relative"}} onClick={() => setSelectedRecommendedPlaylist(playlist)}>
          <img src={cardBackgroundImage} style={{ width: "100%", borderRadius: 8 }}/>
          <div className="fs-16 fw-600 c-white line-1-2 ph-20 pv-15" style={{ position: "absolute", bottom: 0, left: 0, right: 0 }}>{playlist.playlist_name}</div>
        </div>
      </Col>
    )
  }

  const renderPlaylistGroupLists = (group, index) => {
    return (
      <div key={index} className="mb-15">
        <div className="fs-18 fw-700 mb-10">{group.group_name}</div>
        <Row gutter={[15,15]}>
          { group.playlists.map((x,i) => renderPlaylistGroupList(x,i)) }
        </Row>
      </div>
    )
  }

  const renderRecommendedPlaylist = () => {
    return (
      <div className="p-15">
        <Row className="pb-15" align="middle">
          <Col>
            <span className="c-blue cursor-default bg-gray ph-10 pv-5 fs-16 radius-8" onClick={() => setSelectedRecommendedPlaylist({})}>
              <ArrowLeftOutlined/>
            </span>
          </Col>
          <Col>
            <span className="fs-16 fw-700 ml-10">{ selectedRecommendedPlaylist.playlist_name }</span>
          </Col>
        </Row>
        <div className="shadow-card">
          { selectedRecommendedPlaylist.songs.map((x,i) => renderSearchResults(selectedRecommendedPlaylist.songs, x,i))}
        </div>
      </div>
    )
  }

  const renderImportPlaylistBanner = () => {
    if (isUndefined(startImportPlaylist)) {
      return null
    }
    return (
      <div className="bg-white b-border p-10 cursor-default" onClick={() => startImportPlaylist()}>
        <div className="display-flex flex-middle" style={{ justifyContent: 'center' }}>
          <div className="plus-container">
            <MdOutlineFileDownload style={{ fontSize: 20, color: '#536DFE'}}/>
          </div>
          <div className="fw-600 ml-10">
            Import playlist from { selectedPlatform == "apple" ? "Apple Music" : "Spotify"}
          </div>
        </div>
      </div>
    )
  }

  const renderSearchContent = () => {
    if (isSearching) {
      return (
        <div className="mt-80">
          <LoadingSpinner/>
        </div>
      )
    } else if (searchResults.length > 0) {
      return (
        <div className="music-modal-search-results-container p-15">
          <div className="shadow-card">
            { searchResults.map((x,i) => renderSearchResults(searchResults, x,i))}
          </div>
        </div>
      );
    } else if (recommendedSongs.length > 0) {
      return (
        <div>
          { renderImportPlaylistBanner() }
          <div className="p-15">
            <div className="fw-700 fs-18">Recommended Songs</div>
            <div className="c-text-gray fs-14 mb-15">for "{eventName}"</div>
            <div className="shadow-card">
              { recommendedSongs.map((x,i) => renderSearchResults(recommendedSongs, x,i))}
            </div>
          </div>
        </div>
      )
    } else if (recommendedPlaylistGroupList.length > 0) {
      if (!isEmpty(selectedRecommendedPlaylist)) {
        return renderRecommendedPlaylist()
      } else {
        return (
          <div>
            { renderImportPlaylistBanner() }
            <div className="ph-20 pb-10 pt-15">
              { recommendedPlaylistGroupList.map((x,i) => renderPlaylistGroupLists(x,i)) }
            </div>
          </div>
        )
      }
    } else {
      return (
        <div>
          { renderImportPlaylistBanner() }
          <div className="text-center mt-80">
            <img src={emptyMusicIcon} width={150}/>
          </div>
        </div>
      )
    }
  }

  return (
    <Modal visible={isVisible} footer={null} onCancel={handleCancel} width={600} closable={false} maskClosable={false} style={modalStyle} bodyStyle={modalBodyStyle} wrapClassName="rounded-modal modal-bg-gray">
      <div className="ph-25 pv-20 b-border bg-white" style={{ borderTopLeftRadius: 8, borderTopRightRadius: 8 }}>
        <Row align="middle">
          <Col>
            { selectedPlatform == "apple" && (
              <img src={appleMusicLogo} style={{ height: 20 }}/>
            )}
            { selectedPlatform != "apple" && (
              <img src={spotifyLogo} style={{ height: 30}}/>
            )}
          </Col>
          <Col flex={1}></Col>
          <Col>
            <div className="display-flex" onClick={handleCancel}><MdOutlineClose size={30} color={"#CCC"}/></div>
          </Col>
        </Row>
        <div className="music-modal-search-field-container">
          <Input 
            allowClear
            placeholder="Search tracks, artists, etc."
            value={searchText}
            onChange={onSearchChange}
            onKeyUp={onKeyPress}
            prefix={searchPrefix}
            className="music-modal-search-field"
          />
        </div>
      </div>
      <div className="">
        { renderSearchContent() }
      </div>
    </Modal>
  );
};

export default MusicModal;

import React, { useEffect, useState } from "react";
import { Row, Col, Table, Modal, Select, Input } from 'antd';
import moment from "moment";
import { useNavigate } from 'react-router';
import useApi from '../../hooks/useApi';
import { getSystemLeads, getSystemLead, sendSystemLeadEmail } from '../../api';
import LoadingSpinner from '../../components/loading';
import AdminContent from '../../components/adminContent';
import FloatingContainer from '../../components/floatingContainer'
import { MdOutlineClose } from "react-icons/md";


const LeadsPage = () => {

  const emailTemplates = [
    { 
      template_name: "dj_welcome",
      subject: "Welcome to DJ Planning Center!",
      data: {
        link: "https://djplanningcenter.com"
      }
    },
    { 
      template_name: "promo_invitation",
      subject: "The wait is over. Start your free trial!",
      data: {
        link: "https://djplanningcenter.com/signup"
      }
    }
  ]

  const [isLoading, setLoading] = useState(true);
  const [leads, setLeads] = useState([]);
  const [isLeadModalVisible, setLeadModalVisible] = useState(false);
  const [selectedLead, setSelectedLead] = useState({});
  const [selectedEmailTemplate, setSelectedEmailTemplate] = useState("");
  const [selectedSubject, setSelectedSubject] = useState("");

  const navigate = useNavigate();
  const [sendRequest] = useApi()

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (text, record) => record.first_name + " " + record.last_name
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      render: (text) => text
    },
    {
      title: 'Account Status',
      dataIndex: 'status',
      key: 'status',
      render: (text, record) => text
    },
    {
      title: 'Notes',
      dataIndex: 'notes',
      key: 'notes',
      render: (text, record) => text
    }
  ];

  const emailColumns = [
    {
      title: 'Subject',
      dataIndex: 'subject',
      key: 'subject',
      render: (text, record) => text
    },
    {
      title: 'Template Name',
      dataIndex: 'template_name',
      key: 'template_name',
      render: (text) => text
    },
    {
      title: 'Sent',
      dataIndex: 'sent_datetime',
      key: 'sent_datetime',
      render: (text, record) => moment(text).format("MM/DD/YYYY @ hh:mma")
    }
  ];


  useEffect(() => {
    window.scrollTo(0, 0);
    refreshPage()
  }, []);

  const refreshPage = async () => {
    try {
      const leadResults = await sendRequest(getSystemLeads());
      setLeads(leadResults)
    } finally {
      setLoading(false)
    }
  }

  const selectLead = async (lead) => {
    try {
      const leadResults = await sendRequest(getSystemLead(lead.lead_id))
      setSelectedEmailTemplate("")
      setSelectedSubject("")
      setSelectedLead(leadResults)
      setLeadModalVisible(true)
    } catch {}
  }

  const onChangeEmailTemplate = (template_name) => {
    const foundTemplate = emailTemplates.filter(x => x.template_name == template_name)
    if (foundTemplate.length > 0) {
      setSelectedSubject(foundTemplate[0].subject)
    }
    setSelectedEmailTemplate(template_name)
  }

  const editLead = (record) => {
    navigate(`/system/leads/${record.lead_id}/edit`)
  }

  const sendEmail = async () => {
    if (!selectedSubject) {
      return
    }
    if (!selectedEmailTemplate) {
      return
    }
    try {
      const foundTemplate = emailTemplates.filter(x => x.template_name == selectedEmailTemplate)
      var template = {}
      if (foundTemplate.length > 0) {
        var template = foundTemplate[0]
        const body = {
          lead_id: selectedLead.lead_id,
          email: selectedLead.email,
          template_name: template.template_name,
          subject: selectedSubject,
          data: {
            ...template.data,
            name: selectedLead.first_name,
            full_name: selectedLead.first_name + " " + selectedLead.last_name
          }
        }
        await sendRequest(sendSystemLeadEmail(selectedLead.lead_id, body))
        setLeadModalVisible(false)
      }
    } catch {}
  }

  const renderHeader = () => {
    return (
      <Row align="middle" className="p-20">
        <Col flex={1}>
          <div className="admin-page-title">Leads</div>
        </Col>
        <Col>
          <button className="page-title-button" onClick={() => navigate("/system/leads/new")}>New</button>
        </Col>
      </Row>
    )
  }

  const renderLeadModal = () => {
    var data = []
    if (selectedLead && selectedLead.emails) {
      data = selectedLead.emails.map((x, i) => {
        return {
          ...x,
          key: i
        }
      });
    }

    return (
      <Modal visible={isLeadModalVisible} closable={false} footer={null} width={800} wrapClassName="rounded-modal">
        <div className="text-right" onClick={() => setLeadModalVisible(false)}><MdOutlineClose size={30} color={"#CCC"}/></div>
        <div className="mt-10">
          <Row align="middle" className="p-20" gutter={[20,20]}>
            <Col xs={12}>
              <div className="fw-700">Name</div>
              <div className="">{ selectedLead.first_name } { selectedLead.last_name }</div>
            </Col>
            <Col xs={12}>
              <div className="fw-700">Email</div>
              <div className="">{ selectedLead.email }</div>
            </Col>
            <Col xs={24}>
              <div className="fw-700 mb-5">Email Template</div>
              <Select
                style={{ width: "100%" }}
                placeholder={"Select email template"}
                size='large'
                value={selectedEmailTemplate}
                onChange={(value) => onChangeEmailTemplate(value)}
              >
                { emailTemplates.map((x,i) => (
                  <Select.Option value={x.template_name}>{x.template_name}</Select.Option>
                ))}
              </Select>
            </Col>
            <Col xs={24}>
              <div className="fw-700 mb-5">Subject</div>
              <Input 
                value={selectedSubject}
                onChange={(e) => setSelectedSubject(e.target.value)}
                placeholder="Subject"
                size="large"/>
            </Col>
          </Row>
          <button className="primary-button" type="button" onClick={() => sendEmail()}>Send Email</button>
          <div className="text-center mt-15">
            <div className="blue-link" onClick={() => editLead(selectedLead)}>Edit</div>
          </div>
          <div className="t-border mv-30"></div>
          <div className="fw-700 fs-20 mb-10 ml-10">Sent Emails</div>
          <Table 
            columns={emailColumns} 
            dataSource={data}
            pagination={false}
            onRow={(record, rowIndex) => {
              return {
                onClick: e => {}
              };
            }}
           />
        </div>
      </Modal>
    )
  }

  const renderContent = () => {

    const data = leads.map((x, i) => {
      return {
        ...x,
        key: i
      }
    });

    return (
      <FloatingContainer className="ph-20" verticalPadding={20} maxWidth={1000}>
        <div className="admin-page-content-box">
          <Table 
            columns={columns} 
            dataSource={data}
            pagination={false}
            onRow={(record, rowIndex) => {
              return {
                onClick: e => selectLead(record)
              };
            }}
           />
        </div>
        {renderLeadModal()}
      </FloatingContainer>
    )
  }

  if (isLoading) {
    return <LoadingSpinner/>
  }

  return (
    <AdminContent header={renderHeader()} body={renderContent()}/>
  );
}

export default LeadsPage;

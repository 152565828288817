import React from "react";
import { isEmpty } from "lodash";
import { Row, Col, Grid } from 'antd';
import InvoiceSummaryV2 from './invoiceSummaryV2';
import { formatCurrencyString } from '../helpers/contractHelper';
import { formatDateMedium } from '../helpers/dateHelper';

const InvoicePreviewV2 = props => {
  const { invoice = {}, ghost = false } = props;

  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();

  const renderNotes = () => {
    if (!isEmpty(invoice.notes)) {
      return (
        <>
          <div className="fs-14 fw-700 mb-5">Notes</div>
          <div className="line-breaks">{invoice.notes}</div>
        </>
      )
    }
    return null;
  }

  const renderDueTodayBox = () => {
    return (
      <div className="pv-15 ph-15 bg-gray radius-8 text-center" style={{ display: "inline-block", width: 250 }}>
        <div className="fs-14 fw-700 mb-5">Amount Due</div>
        <div className="fs-24 fw-500 mb-10 c-blue">{formatCurrencyString(invoice.balance, invoice.currency)}</div>
      </div>
    )
  }

  const renderContent = () => {
    const paddingClass = screens.md ? "p-30" : "p-20"
    const containerClass = ghost ? "" : `shadow-card-square ${paddingClass} mb-20`
    return (
      <>
        <div className={containerClass}>
          <div className="fw-700 fs-18 mb-10">Items</div>
          <InvoiceSummaryV2 invoice={invoice}/>
          { screens.md ? (
            <Row className="mt-30" gutter={[15,15]}>
              <Col xs={14}>
                { renderNotes() }
              </Col>
              <Col xs={10} className="text-right">
                { renderDueTodayBox() }
              </Col>
            </Row>
          ) : (
            <Row className="mt-30" gutter={[30,30]}>
              <Col xs={24}>
                { renderNotes() }
              </Col>
              <Col flex={1}></Col>
              <Col flex={0}>
                { renderDueTodayBox() }
              </Col>
            </Row>
          )}
        </div>
      </>
    )
  }

  return renderContent()
};

export default InvoicePreviewV2;

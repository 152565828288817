import { NodeViewWrapper } from '@tiptap/react'
import React, { useState, useEffect } from 'react'
import { Dropdown, Menu, Input } from 'antd';
import { cloneDeep, isArray } from 'lodash';

export default props => {

  const currentDateTags = {
    category: "Current Date",
    items: [
      { tag: "current_date_short", displayName: "Current Date (Short)" },
      { tag: "current_date_medium", displayName: "Current Date (Medium)" },
      { tag: "current_date_long", displayName: "Current Date (Long)" }
    ]
  }

  const companyTags = {
    category: "Company",
    items: [
      { tag: "company_name", displayName: "Company Name" },
      { tag: "company_website", displayName: "Company Website" }
    ]
  }

  const eventTags = {
    category: "Event",
    items: [
      { tag: "event_name", displayName: "Event Name" },
      { tag: "event_type", displayName: "Event Type" },
      { tag: "event_date_short", displayName: "Event Date (Short)" },
      { tag: "event_date_medium", displayName: "Event Date (Medium)" },
      { tag: "event_date_long", displayName: "Event Date (Long)" },
      { tag: "event_day", displayName: "Event Day" },
      { tag: "event_month", displayName: "Event Month" },
      { tag: "event_year", displayName: "Event Year" },
      { tag: "event_start_time", displayName: "Event Start Time" },
      { tag: "event_end_time", displayName: "Event End Time" }
    ]
  }

  const venueTags = {
    category: "Venue",
    items: [
      { tag: "venue_name", displayName: "Venue Name" },
      { tag: "venue_address_line_1", displayName: "Venue Address Line 1" },
      { tag: "venue_address_line_2", displayName: "Venue Address Line 2" },
      { tag: "venue_city", displayName: "Venue City" },
      { tag: "venue_state", displayName: "Venue State" },
      { tag: "venue_zip", displayName: "Venue Zip" },
      { tag: "venue_contact", displayName: "Venue Contact Name" },
      { tag: "venue_email", displayName: "Venue Email" },
      { tag: "venue_phone", displayName: "Venue Phone" }
    ]
  }

  const financialTags = {
    category: "Financials",
    items: [
      { tag: "deposit", displayName: "Deposit Amount" },
      { tag: "discount", displayName: "Discount Amount" },
      { tag: "package_total", displayName: "Package Total" },
      { tag: "add_ons_total", displayName: "Add-Ons Total" },
      { tag: "subtotal_price", displayName: "Subtotal Price" },
      { tag: "tax_rate", displayName: "Tax Rate" },
      { tag: "tax_total", displayName: "Tax Total" },
      { tag: "total_price", displayName: "Total Price" },
      { tag: "total_payments", displayName: "Total Payments" },
      { tag: "balance", displayName: "Balance" }
    ]
  }

  const [searchText, setSearchText] = useState("");
  const [mergeTags, setMergeTags] = useState([]);
  const [filteredMergeTags, setFilteredMergeTags] = useState([]);

  useEffect(() => {
    loadData()
  }, []);

  const loadData = () => {
    const clientEventRoles = isArray(props.extension.options.clientEventRoles) ? props.extension.options.clientEventRoles : []
    const adminEventRoles = isArray(props.extension.options.adminEventRoles) ? props.extension.options.adminEventRoles : []

    // Dynamically create variables for client roles
    var clientRoleItems = []
    clientEventRoles.forEach(x => {
      clientRoleItems.push({ tag: `client_role_full_name_${x.client_event_role_id}`, displayName: `${x.role_name} - Full Name` })
      clientRoleItems.push({ tag: `client_role_first_name_${x.client_event_role_id}`, displayName: `${x.role_name} - First Name` })
      clientRoleItems.push({ tag: `client_role_last_name_${x.client_event_role_id}`, displayName: `${x.role_name} - Last Name` })
      clientRoleItems.push({ tag: `client_role_email_${x.client_event_role_id}`, displayName: `${x.role_name} - Email` })
      clientRoleItems.push({ tag: `client_role_phone_${x.client_event_role_id}`, displayName: `${x.role_name} - Phone` })
      clientRoleItems.push({ tag: `client_role_address_${x.client_event_role_id}`, displayName: `${x.role_name} - Address` })
    })
    const clientRoleTags = {
      category: "Client",
      items: clientRoleItems
    }

    // Dynamically create variables for admin roles
    var adminRoleItems = []
    adminEventRoles.forEach(x => {
      adminRoleItems.push({ tag: `admin_role_full_name_${x.admin_event_role_id}`, displayName: `${x.role_name} - Full Name` })
      adminRoleItems.push({ tag: `admin_role_first_name_${x.admin_event_role_id}`, displayName: `${x.role_name} - First Name` })
      adminRoleItems.push({ tag: `admin_role_last_name_${x.admin_event_role_id}`, displayName: `${x.role_name} - Last Name` })
      adminRoleItems.push({ tag: `admin_role_email_${x.admin_event_role_id}`, displayName: `${x.role_name} - Email` })
      adminRoleItems.push({ tag: `admin_role_phone_${x.admin_event_role_id}`, displayName: `${x.role_name} - Phone` })
    })
    const adminRoleTags = {
      category: "Assigned Staff",
      items: adminRoleItems
    }

    // Populate tags
    var tagArray = []
    tagArray.push(currentDateTags)
    tagArray.push(companyTags)
    tagArray.push(clientRoleTags)
    tagArray.push(adminRoleTags)
    tagArray.push(eventTags)
    tagArray.push(venueTags)
    tagArray.push(financialTags)


    setMergeTags(tagArray)
    setFilteredMergeTags(tagArray)
  }

  const searchOnChange = (e) => {
    const searchValue = e.target.value.toLowerCase()
    setSearchText(searchValue)
    var tagCategories = cloneDeep(mergeTags)
    for (var category of tagCategories) {
      const filteredItems = category.items.filter(x => x.displayName.toLowerCase().includes(searchValue))
      category.items = filteredItems
    }
    tagCategories = tagCategories.filter(x => x.items.length > 0)
    setFilteredMergeTags(tagCategories)
  }

  const onVisibleChange = (open) => {
    if (open) {
      setSearchText("")
      setFilteredMergeTags(mergeTags)
    }
  }

  const updateMergeTag = (displayName, tag) => {
    props.updateAttributes({
      displayName: displayName,
      tag: tag
    })
  }

  const renderMenuGroupItem = (item, index) => {
    return (
      <Menu.Item key={item.tag}>
        <div onClick={() => updateMergeTag(item.displayName, item.tag)}>
          { item.displayName }
        </div>
      </Menu.Item>
    )
  }

  const renderMenuGroup = (group, index) => {
    return (
      <Menu.ItemGroup title={group.category} key={index}>
        { group.items.map((x,i) => renderMenuGroupItem(x,i))}
      </Menu.ItemGroup>
    )
  }

  const menu = () => {
    return (
      <>
        <Menu>
          <div className="p-10"><Input value={searchText} onChange={searchOnChange} placeholder='Search...'/></div>
          { filteredMergeTags.map((x,i) => renderMenuGroup(x,i))}
        </Menu>
      </>
    )
  };

  return (
    <NodeViewWrapper className="merge-tag" as="span">
      <Dropdown 
        overlay={menu()} 
        placement="bottomLeft" 
        trigger="click" 
        overlayClassName="scrollable-dropdown" 
        disabled={!props.editor.view.editable}
        onVisibleChange={onVisibleChange}>
        <button className={`editor-variable-field ${props.editor.view.editable ? "" : "disabled"}`}>{props.node.attrs.displayName}</button>
      </Dropdown>
    </NodeViewWrapper>
  )
}
import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { isEmpty, isNull } from "lodash";
import useApi from '../../../hooks/useApi';
import { Form, Row, Col, Image, Upload, Modal, notification } from 'antd';
import { getAccountDetails, uploadAccountLogo, updateAccount, updateAccountLogo, getAccountImages, uploadAccountImage, updateAccountImage } from '../../../api';
import LoadingSpinner from '../../../components/loading';
import AdminContent from '../../../components/adminContent';
import FloatingContainer from '../../../components/floatingContainer'
import AccountImageKeys from '../../../constants/accountImageKeys';
import AppContext from '../../../app/context';
import useDocumentTitle from '../../../hooks/useDocumentTitle';
import { resizeFile, isValidFileType } from '../../../helpers/imageHelper';
import { MdArrowForwardIos, MdOutlineInsertPhoto, MdOutlineClose } from "react-icons/md";
import { LoadingOutlined } from '@ant-design/icons';
import { 
  renderInputField
} from '../../../components/formFields'

const CompanyBrandingPage = () => {

  const [isLoading, setLoading] = useState(true);
  const [accountDetails, setAccountDetails] = useState({});
  const [logoUrl, setLogoUrl] = useState(null);
  const [clientPortalLogoUrl, setClientPortalLogoUrl] = useState(null);
  const [isLogoSaving, setLogoSaving] = useState(false);
  const [isClientPortalLogoSaving, setClientPortalLogoSaving] = useState(false);
  const [isUpdateCompanyDetailsModalVisible, setUpdateCompanyDetailsModalVisible] = useState(false);

  useDocumentTitle("Branding")
  const { auth, setAuth } = useContext(AppContext);
  const navigate = useNavigate();

  const [updateCompanyDetailsForm] = Form.useForm();

  const [sendRequest] = useApi()

  useEffect(() => {
    window.scrollTo(0, 0);
    refreshPage();
  }, []);

  const refreshPage = async () => {
    try {
      const accountDetailsResults = await sendRequest(getAccountDetails())
      const accountImageResults = await sendRequest(getAccountImages(auth.user.account_id))
      setAccountDetails(accountDetailsResults)
      setLogoUrl(accountDetailsResults.logo)
      setClientPortalLogoUrl(getAccountImageByKey(accountImageResults, AccountImageKeys.CLIENT_PORTAL_LOGO))

      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  const saveCompanyDetails= async (values) => {
    try {
      await sendRequest(updateAccount(values))
      refreshPage()
      setUpdateCompanyDetailsModalVisible(false)
    } catch (error) {
      setUpdateCompanyDetailsModalVisible(false)
      console.log(error)
    }
  }

  const clearPrimaryLogo= async () => {
    try {
      await sendRequest(updateAccountLogo({ logo: "" }))
      await refreshPage()
    } catch (error) {
      console.log(error)
    }
  }

  const handleBeforeUpload = () => {
    return false
  };

  const handleLogoChange = async (info) => {
    try {
      if (!isValidFileType(info.file)) {
        notification.error({
          message: 'Error',
          description: 'Only file types of JPG or PNG are supported.',
          duration: 3
        });
        return
      }

      setLogoSaving(true)

      // Resize photo
      const newFile = await resizeFile(info.file)
      setLogoUrl(newFile)

      // Get blob from URI
      let response = await fetch(newFile);
      let blob = await response.blob()

      // Upload photo
      const formData = new FormData();
      formData.append('photo', blob);
      formData.append('account_id', auth.user.account_id);
      await sendRequest(uploadAccountLogo(auth.user.account_id, formData))
    
    } catch(error) { 
      notification.error({
        message: 'Error',
        description: 'Something went wrong! Please try again later.',
        duration: 3
      });
    }
    finally {
      setLogoSaving(false)
    }
  };

  const startUpdateCompanyDetails = () => {
    updateCompanyDetailsForm.setFieldsValue( { company_name: accountDetails.company_name, website: accountDetails.website })
    setUpdateCompanyDetailsModalVisible(true)
  }

  const getAccountImageByKey = (images, key) => {
    const foundImage = images.find(x => x.image_key == key)
    if (!isEmpty(foundImage)) {
      return foundImage.image_url
    }
    return null
  }

  const handleClientPortalLogoChange = async (info) => {
    try {
      if (!isValidFileType(info.file)) {
        notification.error({
          message: 'Error',
          description: 'Only file types of JPG or PNG are supported.',
          duration: 3
        });
        return
      }

      setClientPortalLogoSaving(true)

      // Resize photo
      const newFile = await resizeFile(info.file)
      setClientPortalLogoUrl(newFile)

      // Get blob from URI
      let response = await fetch(newFile);
      let blob = await response.blob()

      // Upload photo
      const formData = new FormData();
      formData.append('photo', blob);
      formData.append('account_id', auth.user.account_id);
      formData.append('image_key', AccountImageKeys.CLIENT_PORTAL_LOGO);
      await sendRequest(uploadAccountImage(auth.user.account_id, AccountImageKeys.CLIENT_PORTAL_LOGO, formData))
    
    } catch (error) { 
      notification.error({
        message: 'Error',
        description: 'Something went wrong! Please try again later.',
        duration: 3
      });
    }
    finally {
      setClientPortalLogoSaving(false)
    }
  };

  const clearClientPortalLogo= async () => {
    try {
      await sendRequest(updateAccountImage(auth.user.account_id, AccountImageKeys.CLIENT_PORTAL_LOGO, { image_url: "" }))
      await refreshPage()
    } catch (error) {
      console.log(error)
    }
  }

  const renderHeader = () => {
    return (
      <div className="p-20">
        <div>
          <span className="c-blue fw-700 cursor-default" onClick={() => navigate("/admin/setup")}>
            Setup
          </span>
          <span className="fs-10 mh-5"><MdArrowForwardIos/></span>
          <span className="cursor-default c-text-gray">
            Branding
          </span>
        </div>
        <div className="fw-700 fs-24 mt-5">Branding</div>
      </div>
    )
  }

  const renderLogo = () => {
    if (!isEmpty(logoUrl)) {
      return (
        <>
          <div>
            <Image src={logoUrl} style={{ width: '100%', maxWidth: 400 }}/>
          </div>
          { isLogoSaving ? (
            <div className="blue-link mt-5"><LoadingOutlined/> Uploading...</div>
          ) : (
            <>
              <Row gutter={10} className="mt-5">
                <Col>
                  <Upload
                    showUploadList={false}
                    beforeUpload={handleBeforeUpload}
                    onChange={handleLogoChange}
                    maxCount={1}
                  >
                    <div className="blue-link">Update Photo</div>
                  </Upload>
                </Col>
                <Col>
                  <div className="c-text-gray">|</div>
                </Col>
                <Col>
                 <div className="blue-link" onClick={() => clearPrimaryLogo()}>Clear Photo</div>
                </Col>
              </Row>
            </>
          )}
        </>
      )
    } else {
      return (
        <Upload
          showUploadList={false}
          beforeUpload={handleBeforeUpload}
          onChange={handleLogoChange}
          maxCount={1}
        >
          <div className="fs-50 border c-text-gray radius-4 bg-gray display-flex flex-col flex-middle justify-content-center" style={{ height: 200, width: 200 }}>
            { isLogoSaving ? (
              <LoadingOutlined style={{ fontSize: 30 }}/>
            ) : (
              <>
                <MdOutlineInsertPhoto/>
                <div className="fs-12 mt-5">Upload Image</div>
              </>
            )}
          </div>
        </Upload>
      )
    }
  }

  const renderClientPortalLogo = () => {
    if (!isEmpty(clientPortalLogoUrl) && !isNull(clientPortalLogoUrl)) {
      return (
        <>
          <div>
            <Image src={clientPortalLogoUrl} style={{ width: '100%', maxWidth: 400 }}/>
          </div>
          { isClientPortalLogoSaving ? (
            <div className="blue-link mt-5"><LoadingOutlined/> Uploading...</div>
          ) : (
            <>
              <Row gutter={10} className="mt-5">
                <Col>
                  <Upload
                    showUploadList={false}
                    beforeUpload={handleBeforeUpload}
                    onChange={handleClientPortalLogoChange}
                    maxCount={1}
                  >
                    <div className="blue-link">Update Photo</div>
                  </Upload>
                </Col>
                <Col>
                  <div className="c-text-gray">|</div>
                </Col>
                <Col>
                 <div className="blue-link" onClick={() => clearClientPortalLogo()}>Clear Photo</div>
                </Col>
              </Row>
            </>
          )}
        </>
      )
    } else {
      return (
        <Upload
          showUploadList={false}
          beforeUpload={handleBeforeUpload}
          onChange={handleClientPortalLogoChange}
          maxCount={1}
        >
          <div className="fs-50 border c-text-gray radius-4 bg-gray display-flex flex-col flex-middle justify-content-center" style={{ height: 200, width: 200 }}>
            { isClientPortalLogoSaving ? (
              <LoadingOutlined style={{ fontSize: 30 }}/>
            ) : (
              <>
                <MdOutlineInsertPhoto/>
                <div className="fs-12 mt-5">Upload Image</div>
              </>
            )}
          </div>
        </Upload>
      )
    }
  }

  const renderUpdateCompanyDetailsModal = () => {
    return (
      <Modal visible={isUpdateCompanyDetailsModalVisible} footer={null} onCancel={() => setUpdateCompanyDetailsModalVisible(false)} width={500} closable={false} maskClosable={false} wrapClassName="rounded-modal">
        <Row align="middle">
          <Col flex={1}>
            <div className="fw-700 fs-18">Update Company Details</div>
          </Col>
          <Col>
            <div className="display-flex" onClick={() => setUpdateCompanyDetailsModalVisible(false)}><MdOutlineClose size={30} color={"#CCC"}/></div>
          </Col>
        </Row>
        <div className="">
          <Form form={updateCompanyDetailsForm} layout="vertical" name="verify" onFinish={saveCompanyDetails}>
            <div className="mt-10">
              { renderInputField("Company Name", "company_name", true) }
            </div>
            <div className="mt-10 mb-10">
              { renderInputField("Website", "website", false, false, "https://www.mycompany.com") }
            </div>
            <button className="primary-button" type="submit">Save</button>
            <div className="text-center mt-15">
              <div className="blue-link" onClick={() => setUpdateCompanyDetailsModalVisible(false)}>Cancel</div>
            </div>
          </Form>
        </div>
      </Modal>
    )
  }

  const renderContent = () => {
    return (
      <>
        <FloatingContainer className="ph-20 mb-80" verticalPadding={20} maxWidth={800}>
          <div className="shadow-card p-20">
            <Row className="b-border pb-15 mb-15">
              <Col flex={1}>
                <div className="fs-20 fw-700 line-1">Company Details</div>
              </Col>
              <Col>
                <div className="blue-link" onClick={() => startUpdateCompanyDetails()}>Edit</div>
              </Col>
            </Row>
            <div className="fs-14 fw-700">Company Name</div>
            <div className="fs-14 mb-15">{ accountDetails.company_name}</div>
            <div className="mv-5"></div>
            <div className="fs-14 fw-700">Website</div>
            <div className="fs-14 mb-15">{ accountDetails.website ? accountDetails.website : "--" }</div>
          </div>
          <div className="shadow-card p-20 mt-15">
            <Row className="b-border pb-15 mb-15">
              <Col flex={1}>
                <div className="fs-20 fw-700 line-1">Images</div>
              </Col>
            </Row>
            <div className="fs-14 fw-700">Primary Logo</div>
            <div className="mt-5 mb-10 bg-gray p-10 radius-8">This image is used as the default logo for emails, pricing guides, and more.</div>
            <div className="">
              { renderLogo() }
            </div>
            <div className="t-border mv-30"></div>
            <div className="fs-14 fw-700">Client Portal Logo</div>
            <div className="mt-5 mb-10 bg-gray p-10 radius-8">This logo is displayed in the top header of the client portal. It is recommended to use a version of your logo that has a square or horizontal layout with a white background.</div>
            <div className="">
              { renderClientPortalLogo() }
            </div>
          </div>
        </FloatingContainer>
        { renderUpdateCompanyDetailsModal() }
      </>
    )
  }

  if (isLoading) {
    return <LoadingSpinner/>
  }

  return (
    <AdminContent header={renderHeader()} body={renderContent()}/>
  );
}

export default CompanyBrandingPage;

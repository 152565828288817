import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from 'react-router-dom';
import qs from "qs";
import moment from "moment";
import { Row, Col, Select, Collapse, Grid } from 'antd';
import AdminContent from '../../../components/adminContent';
import FloatingContainer from '../../../components/floatingContainer'
import { FiDownload } from "react-icons/fi";
import { MdArrowForwardIos } from "react-icons/md";
import LoadingSpinner from '../../../components/loading';
import useApi from '../../../hooks/useApi';
import { getMonthlyIncomeReport, getAdminPdfToken } from '../../../api';
import useDocumentTitle from '../../../hooks/useDocumentTitle';
import { isArray } from "lodash";
import emptyStateImage from '../../../images/empty-state-icon.png';
import FadeWhileInView from '../../../components/fadeWhileInView'
import useAccountSettings from '../../../hooks/useAccountSettings';
import { formatDateShort, formatEventDateShort } from '../../../helpers/dateHelper';
const PDF_URL = process.env.REACT_APP_PDF_URL;

const IncomeMonthlySummaryReportPage = () => {

  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();

  const [isLoading, setLoading] = useState(true);
  const [filterKey, setFilterKey] = useState("all");
  const [reportData, setReportData] = useState({});
  const [yearList, setYearList] = useState([]);

  useDocumentTitle("Income Summary by Month | Report")
  const navigate = useNavigate();
  const location = useLocation();
  const [sendRequest] = useApi()
  const [accountSettings] = useAccountSettings()

  useEffect(() => {
    window.scrollTo(0, 0);
    setLoading(false)
    
    // Populate year options
    var yearArray = []
    for (let i = 2022; i <= 2050; i++) {
      yearArray.push(i)
    }
    setYearList(yearArray)

    // Get filter from query string or default
    const queryStrings = qs.parse(location.search, { ignoreQueryPrefix: true })
    if (queryStrings.filter) {
      setFilterKey(queryStrings.filter)
      loadPage(queryStrings.filter)
    } else {
      onChangeFilter(moment().year())
    }
  }, []);

  const loadPage = async (filter) => {
    try {
      setLoading(true)
      const results = await sendRequest(getMonthlyIncomeReport(filter))
      setReportData(results)
    } finally {
      setLoading(false)
    }
  }

  const onChangeFilter = (value) => {
    setFilterKey(value)
    navigate(`/admin/reports/income/monthly-summary?filter=${value}`, { replace: true })
    loadPage(value)
  }

  const onDownloadPDF = async () => {
    try {
      const results = await sendRequest(getAdminPdfToken())
      if (results.token) {
        window.open(`${PDF_URL}/reports/income/monthly-summary/${filterKey}/${results.token}/monthly-income-summary.pdf`, "_blank")
      }
    } catch {}
  }

  const renderHeader = () => {
    return (
      <Row align="middle" className="p-20">
        <Col flex={1}>
          <div>
            <span className="c-blue fw-700 cursor-default" onClick={() => navigate("/admin/reports")}>
              Reports
            </span>
            <span className="fs-10 mh-5"><MdArrowForwardIos/></span>
            <span className="cursor-default c-text-gray">
              Income Summary by Month
            </span>
          </div>
        </Col>
      </Row>
    )
  }

  const renderTotalLine = () => {
    if (screens.sm) {
      return (
        <Row className="bg-gray pl-20">
          <Col xs={4}>
            <div className="fs-14 fw-700 pv-10">Total</div>
          </Col>
          <Col xs={5}>
            <div className="fs-14 fw-700 pv-10 pr-10 text-right">{reportData.event_count}</div>
          </Col>
          <Col xs={5}>
            <div className="fs-14 fw-700 pv-10 pr-20 text-right">{reportData.total_income}</div>
          </Col>
          <Col xs={5}>
            <div className="fs-14 fw-700 pv-10 pr-20 text-right">{reportData.total_wages}</div>
          </Col>
          <Col xs={5}>
            <div className="fs-14 fw-700 pv-10 pr-20 text-right">{reportData.total_profit}</div>
          </Col>
        </Row>
      )
    } else {
      return (
        <Row className="bg-gray pl-20 pv-10">
          <Col xs={12}>
            <div className="fs-14 fw-700">Total</div>
          </Col>
          <Col xs={12}>
            <div className="fs-14 fw-700">Events: {reportData.event_count}</div>
            <div className="fs-14 fw-700">Income: {reportData.total_income}</div>
            <div className="fs-14 fw-700">Wages: {reportData.total_wages}</div>
            <div className="fs-14 fw-700">Profit: {reportData.total_profit}</div>
          </Col>
        </Row>
      )
    }
  }

  const renderDateRange = () => {
    if (reportData.start_date == "2020-01-01") {
      return formatDateShort(moment().format(), accountSettings)
    }
    const startDate = reportData.start_date ? formatEventDateShort(reportData.start_date, accountSettings): "N/A"
    const endDate = reportData.end_date ? formatEventDateShort(reportData.end_date, accountSettings) : "N/A"
    return `${startDate} - ${endDate}`
  }

  const renderCollapseHeader = (x) => {
    const filterYear = filterKey ? filterKey : moment().format("YYYY")
    const rowMonth = moment(x.month, "MMMM").format("MM")
    const rowDate = moment(`${filterYear}-${rowMonth}-01`, "YYYY-MM-DD")
    const extraText = moment().subtract(1,"month").isBefore(rowDate) ? " (Projected)" : ""
    if (screens.sm) {
      return (
        <Row>
          <Col xs={4}>
            <div className="fw-600">{x.month}<span className="fw-500 c-text-gray">{extraText}</span></div>
          </Col>
          <Col xs={5}>
            <div className="text-right pr-20">{x.event_count}</div>
          </Col>
          <Col xs={5}>
            <div className="text-right pr-20">{x.total_income}</div>
          </Col>
          <Col xs={5}>
            <div className="text-right pr-20">{x.total_wages}</div>
          </Col>
          <Col xs={5}>
            <div className="text-right fw-600">{x.total_profit}</div>
          </Col>
        </Row>
      )
    } else {
      return (
        <Row>
          <Col xs={12}>
            <div className="fw-600">{x.month}<span className="fw-500 c-text-gray">{extraText}</span></div>
          </Col>
          <Col xs={12}>
            <div className="pr-20">Events: {x.event_count}</div>
            <div className="pr-20">Income: {x.total_income}</div>
            <div className="pr-20">Wages: {x.total_wages}</div>
            <div className="fw-600 pr-20">Profit: {x.total_profit}</div>
          </Col>
        </Row>
      )
    }
  }

  const renderEvent = (event, index, recordEvents) => {
    const borderClass = index < recordEvents.length - 1 ? "b-border" : ""
    if (screens.sm) {
      return (
        <Row className={`${borderClass} ph-20 pv-10 fs-12`} key={index}>
          <Col xs={4}>
            <div>{formatEventDateShort(event.event_date, accountSettings)}</div>
          </Col>
          <Col xs={6}>
            <div>{event.event_name}</div>
          </Col>
          <Col xs={4}>
            <div className="fs-12 text-right">{event.total_income}</div>
          </Col>
          <Col xs={4}>
            <div className="fs-12 text-right">{event.total_wages}</div>
          </Col>
          <Col xs={4}>
            <div className="fs-12 fw-600 text-right">{event.total_profit}</div>
          </Col>
          <Col xs={2} className="text-right">
            <div className="blue-link fs-12" onClick={() => navigate(`/admin/events/${event.event_id}`)}>View</div>
          </Col>
        </Row>
      )
    } else {
      return (
        <Row className={`${borderClass} ph-20 pv-10 fs-12`} key={index}>
          <Col xs={12}>
            <div>{formatEventDateShort(event.event_date, accountSettings)}</div>
            <div>{event.event_name}</div>
            <div className="blue-link fs-12" onClick={() => navigate(`/admin/events/${event.event_id}`)}>View</div>
          </Col>
          <Col xs={12}>
            <div className="fs-12">Income: {event.total_income}</div>
            <div className="fs-12">Wages: {event.total_wages}</div>
            <div className="fs-12 fw-600">Profit: {event.total_profit}</div>
          </Col>
        </Row>
      )
    }
  }

  const renderTableHeader = () => {
    if (screens.sm) {
      return (
        <Row className="bg-gray pl-20 b-border pv-15">
          <Col xs={4}>
            <div className="fs-12 fw-700">MONTH</div>
          </Col>
          <Col xs={5}>
            <div className="fs-12 text-right fw-700 pr-20">EVENTS</div>
          </Col>
          <Col xs={5}>
            <div className="fs-12 text-right fw-700 pr-20">INCOME</div>
          </Col>
          <Col xs={5}>
            <div className="fs-12 text-right fw-700 pr-20">WAGES</div>
          </Col>
          <Col xs={5}>
            <div className="fs-12 text-right fw-700 pr-20">PROFIT</div>
          </Col>
        </Row>
      )
    } else {
      return (
        <Row className="bg-gray pl-20 b-border">
          <Col xs={12}>
            <div className="fs-12 fw-700 pv-15">MONTH</div>
          </Col>
          <Col xs={12}>
            <div className="fs-12 fw-700 pv-15 pr-20">DETAILS</div>
          </Col>
        </Row>
      )
    }
  }

  const renderEventTableHeader = () => {
    if (screens.sm) {
      return (
        <Row className="b-border pv-5 ph-20 bg-gray c-text-gray">
          <Col xs={4}>
            <div className="fs-10 fw-700">EVENT DATE</div>
          </Col>
          <Col xs={6}>
            <div className="fs-10 fw-700">EVENT NAME</div>
          </Col>
          <Col xs={4}>
            <div className="fs-10 fw-700 text-right">INCOME</div>
          </Col>
          <Col xs={4}>
            <div className="fs-10 fw-700 text-right">WAGES</div>
          </Col>
          <Col xs={4}>
            <div className="fs-10 fw-700 text-right">PROFIT</div>
          </Col>
          <Col xs={2}>
          </Col>
        </Row>
      )
    } else {
      return (
        <Row className="b-border pv-5 ph-20 bg-gray c-text-gray">
          <Col xs={24}>
            <div className="fs-10 fw-700">EVENTS</div>
          </Col>
        </Row>
      )
    }
  }

  const renderEventTable = (x) => {
    if (isArray(x.events) && x.events.length > 0) {
      return (
        <div className="border radius-8" style={{ marginTop: -12 }}>
          { renderEventTableHeader() }
          { isArray(x.events) && x.events.map((event,i) => renderEvent(event,i,x.events))}
        </div>
      )
    } else {
      return (
        <div className="border radius-8 text-center p-20 c-text-gray" style={{ marginTop: -12 }}>
          No events this month
        </div>
      )
    }
  }

  const renderLineItem = (x,i) => {
    return (
      <Collapse ghost key={i}>
        <Collapse.Panel header={renderCollapseHeader(x)} key="1" style={{ borderBottom: '1px solid #e8e8e8'}}>
          { renderEventTable(x) }
        </Collapse.Panel>
      </Collapse>
    )
  }

  const renderLineItems = () => {
    if (isArray(reportData.data)) {
      return (
        <>
          { renderTableHeader() }
          { reportData.data.map((item, index) => renderLineItem(item, index)) }
          { renderTotalLine() }
        </>
        
      )
    }
    return (
      <FadeWhileInView duration={0.5} className="ph-20 pv-40 text-center border">
        <div>
          <img src={emptyStateImage} width={200}/>
        </div>
        <div className="fs-18 fw-700 mt-30">
          No data found
        </div>
        <div className="fs-14 fw-500 c-text-gray">
          Adjust your filter and try again!
        </div>
      </FadeWhileInView>
    )
  }

  const renderReport = () => {
    if (isLoading) {
      return <LoadingSpinner/>
    }

    return (
      <FloatingContainer className="ph-20 bg-white" verticalPadding={30} maxWidth={1000}>
        <div className="pb-50">
          <div className="fw-700 fs-24 c-blue">Income Summary by Month</div>
          <div className="fs-14 fw-700">{reportData.company_name}</div>
          <div className="fs-14 c-text-gray mb-30">{renderDateRange()}</div>
          {/* <Row className="mv-10">
            <Col flex={1}></Col>
            <Col flex={0}>
              <div className="toolbar-button" onClick={() => onDownloadPDF()}>
                <FiDownload style={{ fontSize: 20, color: '#536DFE', marginRight: 8}}/> Download PDF
              </div>
            </Col>
          </Row> */}
          { renderLineItems() }
        </div>
      </FloatingContainer>
    )
  }

  const renderFilterOption = (year) => {
    return (
      <Select.Option value={year} key={year}>{year}</Select.Option>
    )
  }

  const renderContent = () => {
    return (
      <>
        <FloatingContainer className="ph-20 b-border bg-gray" verticalPadding={30} maxWidth={1000}>
          <div className="fw-700 mb-5">Year</div>
          <Row>
            <Col flex={1}>
              <div>
                <Select
                  style={{ width: 200 }}
                  placeholder={"Select"}
                  value={filterKey}
                  onChange={(value) => onChangeFilter(value)}
                >
                  { yearList.map(i => renderFilterOption(i)) }
                </Select>
              </div>
            </Col>
          </Row>
        </FloatingContainer>
        { renderReport() }
      </>
    )
  }

  return (
    <AdminContent body={renderContent()} header={renderHeader()} whiteBackground={true} />
  );
}

export default IncomeMonthlySummaryReportPage;

import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { isUndefined, cloneDeep, isEmpty } from "lodash";
import useApi from '../../../hooks/useApi';
import useCurrency from '../../../hooks/useCurrency';
import { 
  renderFormLabel,
  renderInputField,
  renderTextAreaField,
  renderCurrencyField,
  renderCheckboxField
} from '../../../components/formFields'
import { Row, Col, notification, Form, Modal, Upload, Input, Image } from 'antd';
import { getPackage, updatePackage, uploadPackagePhoto } from '../../../api';
import LoadingSpinner from '../../../components/loading';
import AdminContent from '../../../components/adminContent';
import FloatingContainer from '../../../components/floatingContainer'
import { getNumericCurrency } from '../../../helpers/contractHelper';
import { resizeFile, isValidFileType } from '../../../helpers/imageHelper';
import { MdArrowForwardIos, MdOutlineInsertPhoto } from "react-icons/md";
import { LoadingOutlined, DeleteOutlined } from '@ant-design/icons';
import useDocumentTitle from '../../../hooks/useDocumentTitle';

const EditPackagePage = () => {

  const [isLoading, setLoading] = useState(true);
  const [isSaving, setSaving] = useState(false);
  const [packageDetails, setPackageDetails] = useState({});
  const [includedItems, setIncludedItems] = useState([]);
  const [imageUrl, setImageUrl] = useState(null);
  const [isPhotoSaving, setPhotoSaving] = useState(false);
  const [isTaxable, setTaxable] = useState(false);

  useDocumentTitle("Package Details")
  const navigate = useNavigate();
  const params = useParams();
  const [form] = Form.useForm();
  const [sendRequest] = useApi()
  const [currency, _] = useCurrency()

  const packageId = params.id;

  useEffect(() => {
    window.scrollTo(0, 0);
    if (packageId) {
      refreshPage();
    } else {
      setLoading(false)
    }
  }, []);

  const refreshPage = async () => {
    try {

      const packageResults = await sendRequest(getPackage(packageId))
      setPackageDetails(packageResults)
      setIncludedItems(packageResults.included_items ?? [])
      setImageUrl(packageResults.photo ?? "")
      setTaxable(packageResults.taxable)

      const formValues = {
        package_name: packageResults.package_name,
        description: packageResults.description,
        price: packageResults.price,
        active: packageResults.active,
        taxable: packageResults.taxable
      }
      form.setFieldsValue(formValues)

      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  const onSubmit = async (values) => {
    try {
      setSaving(true)

      const includedItemsCopy = cloneDeep(includedItems)
      const finalIncludedItems = includedItemsCopy.filter(x => !isEmpty(x.name))

      var body = {
        ...values,
        price: getNumericCurrency(values["price"]),
        included_items: finalIncludedItems,
        active: !isUndefined(values["active"]) ? values["active"] : false,
        taxable: !isUndefined(values["taxable"]) ? values["taxable"] : false
      }
      await sendRequest(updatePackage(packageId, body))
      displaySuccess()
    } catch (error) {
      Modal.error({
        title: "Oops!",
        content: 'Something went wrong. Please try again.',
        onOk: () => {}
      });
      setSaving(false)
    }
  }

  const displaySuccess = () => {
    notification.success({
      message: 'Success!',
      description: 'Your changes have been saved.',
      duration: 3
    });
    navigate("/admin/packages");
  }

  const handleImageChange = async (info) => {
    try {
      if (!isValidFileType(info.file)) {
        notification.error({
          message: 'Error',
          description: 'Only file types of JPG or PNG are supported.',
          duration: 3
        });
        return
      }

      setPhotoSaving(true)

      // Resize photo
      const newFile = await resizeFile(info.file)

      // Get blob from URI
      let response = await fetch(newFile);
      let blob = await response.blob()

      // Upload photo
      const formData = new FormData();
      formData.append('photo', blob);
      const newPhoto = await sendRequest(uploadPackagePhoto(packageId, formData))
      setImageUrl(newPhoto.photo)
    } catch(error) { 
      notification.error({
        message: 'Error',
        description: 'Something went wrong! Please try again later.',
        duration: 3
      });
    }
    finally {
      setPhotoSaving(false)
    }
  };

  const handleBeforeUpload = () => {
    return false
  };

  const addIncludedItem = () => {
    var newIncludedItems = cloneDeep(includedItems);
    const newItem = {
      name: "",
      description: ""
    }
    newIncludedItems.push(newItem)
    setIncludedItems(newIncludedItems)
  }

  const removeIncludedItem = (index) => {
    var newIncludedItems = cloneDeep(includedItems);
    newIncludedItems.splice(index, 1);
    setIncludedItems(newIncludedItems)
  }

  const setIncludedItemName = (value, index) => {
    var newIncludedItems = cloneDeep(includedItems);
    newIncludedItems[index].name = value
    setIncludedItems(newIncludedItems)
  }

  const setIncludedItemDescription = (value, index) => {
    var newIncludedItems = cloneDeep(includedItems);
    newIncludedItems[index].description = value
    setIncludedItems(newIncludedItems)
  }

  const onTaxableToggle = (e) => {
    setTaxable(e.target.checked)
  }

  const renderSaveButton = () => {
    if (isSaving) {
      return (
        <button className="page-title-button small-100" type="button"><LoadingOutlined/></button>
      )
    } else {
      return (
        <button className="page-title-button small-100" type="button" onClick={() => form.submit()}>Save</button>
      )
    }
  }

  const renderHeader = () => {
    return (
      <div className="p-20">
        <div>
          <span className="c-blue fw-700 cursor-default" onClick={() => navigate("/admin/packages")}>
            Packages
          </span>
          <span className="fs-10 mh-5"><MdArrowForwardIos/></span>
          <span className="cursor-default c-text-gray">
            Edit
          </span>
        </div>
        <div className="fw-700 fs-24 mt-5">{packageDetails.package_name}</div>
      </div>
    )
  }

  const renderIncludedItem = (item, index) => {
    return (
      <div className="bg-gray radius-8 p-15 mt-15" key={index}>
        <Row gutter={[10,10]}>
          <Col xs={12}>
            <div className="fw-700">Item #{index + 1}</div>
          </Col>
          <Col xs={12}>
            <div className="c-text-gray text-right" onClick={() => removeIncludedItem(index)}><DeleteOutlined/></div>
          </Col>
          <Col xs={24}>
            { renderFormLabel("Name") }
            <Input size="large" value={item.name} onChange={(e) => setIncludedItemName(e.target.value, index)}/>
          </Col>
          <Col xs={24}>
            { renderFormLabel("Description") }
            <Input.TextArea rows={3} size="large" value={item.description} onChange={(e) => setIncludedItemDescription(e.target.value, index)}/>
          </Col>
        </Row>
      </div>
    )
  }

  const renderPhoto = () => {
    if (!isEmpty(imageUrl)) {
      return (
        <>
          <div>
            <Image src={imageUrl} style={{ width: '100%', maxWidth: 400 }}/>
          </div>
          { isPhotoSaving ? (
            <div className="blue-link mt-5"><LoadingOutlined/> Uploading...</div>
          ) : (
            <Upload
              showUploadList={false}
              beforeUpload={handleBeforeUpload}
              onChange={handleImageChange}
              maxCount={1}
            >
              <div className="blue-link mt-5">Change Photo</div>
            </Upload>
          )}
        </>
      )
    } else {
      return (
        <Upload
          showUploadList={false}
          beforeUpload={handleBeforeUpload}
          onChange={handleImageChange}
          maxCount={1}
        >
          <div className="fs-50 border c-text-gray radius-4 bg-gray display-flex flex-col flex-middle justify-content-center" style={{ height: 200, width: 200 }}>
            { isPhotoSaving ? (
              <LoadingOutlined style={{ fontSize: 30 }}/>
            ) : (
              <>
                <MdOutlineInsertPhoto/>
                <div className="fs-12 mt-5">Upload Image</div>
              </>
            )}
          </div>
        </Upload>
      )
    }
  }

  const renderContent = () => {
    return (
      <FloatingContainer className="ph-20 mb-80" verticalPadding={20} maxWidth={800}>
        <Form form={form} layout="vertical" name="employee" onFinish={onSubmit}>
          <div className="shadow-card p-20 mb-15">
            <div className="fs-20 fw-700 mb-20 line-1">Package Details</div>
              <Row gutter={[10,10]}>
                <Col xs={24} md={12}>
                  {renderInputField("Package Name", "package_name", true)}
                </Col>
                <Col xs={24} md={24}>
                  {renderTextAreaField("Description", "description", 5, false)}
                </Col>
                <Col xs={24}>
                  {renderCurrencyField("Price", "price", true, currency.code)}
                </Col>
                <Col xs={24}>
                  {renderCheckboxField("Taxable", "taxable", onTaxableToggle)}
                </Col>
                { isTaxable && (
                  <Col xs={24}>
                    <div className="bg-gray radius-8 p-10 fs-12 c-text-gray">Your tax rate is configured on the Setup page under General Settings.</div>
                  </Col>
                )}
                <Col>
                  { renderFormLabel("Image") }
                  { renderPhoto() }
                </Col>
              </Row>
          </div>
          <div className="shadow-card p-20 mb-15">
            <div className="fs-20 fw-700 mb-20 line-1">Included Items</div>
            <div className="fs-14 mb-20">Describe the items included in this package. This will be used to further describe the package contents on promotional material.</div>
            { includedItems.map((x,i) => renderIncludedItem(x,i)) }
            <div className="blue-link mt-10" onClick={() => addIncludedItem()}>+ Add Item</div>
          </div>
          <div className="shadow-card p-20 mb-15">
            <div className="fs-20 fw-700 mb-20 line-1">Display Options</div>
            <Row gutter={[10,10]}>
              <Col xs={24}>
                {renderCheckboxField("Active", "active")}
              </Col>
            </Row>
          </div>
          <div className="sticky-footer p-0">
            <div className="p-15 bg-white t-border">
              { renderSaveButton() }
            </div>
          </div>
        </Form>
      </FloatingContainer>
    )
  }

  if (isLoading) {
    return <LoadingSpinner/>
  }

  return (
    <AdminContent header={renderHeader()} body={renderContent()} headerHeight={96}/>
  );
}

export default EditPackagePage;

import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useApi from '../../../hooks/useApi';
import { Dropdown, Menu, Input } from 'antd';
import { 
  getContractTemplate, 
  updateContractTemplateContent, 
  updateContractTemplateName,
  getAccountAdminEventRoles,
  getAccountClientEventRoles
} from '../../../api';
import LoadingSpinner from '../../../components/loading';
import FloatingContainer from '../../../components/floatingContainer'
import useDocumentTitle from '../../../hooks/useDocumentTitle';
import { HiOutlineChevronDown } from "react-icons/hi";
import { FiRefreshCw, FiCheckCircle } from "react-icons/fi";
import { AiOutlineBold, AiOutlineItalic, AiOutlineUnderline, AiOutlineStrikethrough, AiOutlineAlignLeft, AiOutlineAlignRight, AiOutlineAlignCenter, AiOutlineUnorderedList, AiOutlineOrderedList, AiOutlineUndo, AiOutlineRedo, AiOutlineGroup } from "react-icons/ai";
import { useEditor, EditorContent } from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit'
import TextAlign from '@tiptap/extension-text-align'
import Placeholder from '@tiptap/extension-placeholder'
import Underline from '@tiptap/extension-underline'
import MergeTagExtension from '../../../components/tiptap/mergeTagExtension';
import EventServicesExtension from '../../../components/tiptap/eventServicesExtension';

const MenuBar = ({ editor }) => {
  if (!editor) {
    return null
  }

  const menuClass = "display-flex flex-middle justify-content-center fs-18 mr-5"
  
  const menu = () => {
    return (
      <Menu>
        <Menu.Item>
          <div onClick={() => editor.chain().focus().setParagraph().run()}>
            Paragraph
          </div>
        </Menu.Item>
        <Menu.Item>
          <div onClick={() => editor.chain().focus().setHeading({ level: 1 }).run()}>
            Heading 1
          </div>
        </Menu.Item>
        <Menu.Item>
          <div onClick={() => editor.chain().focus().setHeading({ level: 2 }).run()}>
            Heading 2
          </div>
        </Menu.Item>
        <Menu.Item>
          <div onClick={() => editor.chain().focus().setHeading({ level: 3 }).run()}>
            Heading 3
          </div>
        </Menu.Item>
        <Menu.Item>
          <div onClick={() => editor.chain().focus().setHeading({ level: 4 }).run()}>
            Heading 4
          </div>
        </Menu.Item>
      </Menu>
    )
  };

  const insertMenu = () => {
    return (
      <Menu>
        <Menu.Item>
          <div onClick={() => editor.chain().insertContent({ type: "merge-tag"}).focus().run()}>
            Contract Variable
          </div>
        </Menu.Item>
        <Menu.Item>
          <div onClick={() => editor.chain().insertContent({ type: "event-services"}).focus().run()}>
            Services
          </div>
        </Menu.Item>
      </Menu>
    )
  };

  const getActiveTextType = () => {
    if (editor.isActive('paragraph')) {
      return "Paragraph"
    } else if (editor.isActive('heading', { level: 1 })) {
      return "Heading 1"
    } else if (editor.isActive('heading', { level: 2 })) {
      return "Heading 2"
    } else if (editor.isActive('heading', { level: 3 })) {
      return "Heading 3"
    } else if (editor.isActive('heading', { level: 4 })) {
      return "Heading 4"
    } else {
      return "Paragraph"
    }
  }

  return (
    <FloatingContainer className="" verticalPadding={0} maxWidth={850}>
      <div className="flex-row">
        <div className="display-flex flex-middle mh-10 cursor-pointer">
          <Dropdown overlay={menu()} placement="bottomRight" trigger="click">
            <div className="display-flex flex-middle">{getActiveTextType()} <HiOutlineChevronDown style={{ marginLeft: 5}}/></div>
          </Dropdown>
        </div>
        <div style={{ borderRight: "1px solid #CCC", marginLeft: 5, marginRight: 10 }}></div>
        <div style={{ height: 30, width: 30 }} className={`${menuClass} ${editor.isActive('bold') ? 'bg-gray' : ''}`} onClick={() => editor.chain().focus().toggleBold().run()}><AiOutlineBold/></div>
        <div style={{ height: 30, width: 30 }} className={`${menuClass}  ${editor.isActive('italic') ? 'bg-gray' : ''}`} onClick={() => editor.chain().focus().toggleItalic().run()}><AiOutlineItalic/></div>
        <div style={{ height: 30, width: 30 }} className={`${menuClass}  ${editor.isActive('underline') ? 'bg-gray' : ''}`} onClick={() => editor.chain().focus().toggleUnderline().run()}><AiOutlineUnderline/></div>
        <div style={{ height: 30, width: 30 }} className={`${menuClass}  ${editor.isActive('strike') ? 'bg-gray' : ''}`} onClick={() => editor.chain().focus().toggleStrike().run()}><AiOutlineStrikethrough/></div>
        <div style={{ borderRight: "1px solid #CCC", marginLeft: 5, marginRight: 10 }}></div>
        <div style={{ height: 30, width: 30 }} className={`${menuClass}  ${editor.isActive({ textAlign: 'left' })? 'bg-gray' : ''}`} onClick={() => editor.chain().focus().setTextAlign("left").run()}><AiOutlineAlignLeft/></div>
        <div style={{ height: 30, width: 30 }} className={`${menuClass}  ${editor.isActive({ textAlign: 'center' })? 'bg-gray' : ''}`} onClick={() => editor.chain().focus().setTextAlign("center").run()}><AiOutlineAlignCenter/></div>
        <div style={{ height: 30, width: 30 }} className={`${menuClass}  ${editor.isActive({ textAlign: 'right' })? 'bg-gray' : ''}`} onClick={() => editor.chain().focus().setTextAlign("right").run()}><AiOutlineAlignRight/></div>
        <div style={{ borderRight: "1px solid #CCC", marginLeft: 5, marginRight: 10 }}></div>
        <div style={{ height: 30, width: 30 }} className={`${menuClass}  ${editor.isActive('bulletList')? 'bg-gray' : ''}`} onClick={() => editor.chain().focus().toggleBulletList().run()}><AiOutlineUnorderedList/></div>
        <div style={{ height: 30, width: 30 }} className={`${menuClass}  ${editor.isActive('orderedList')? 'bg-gray' : ''}`} onClick={() => editor.chain().focus().toggleOrderedList().run()}><AiOutlineOrderedList/></div>
        <div style={{ borderRight: "1px solid #CCC", marginLeft: 5, marginRight: 10 }}></div>
        <div style={{ height: 30, width: 30 }} className={`${menuClass}  ${editor.isActive('bulletList')? 'bg-gray' : ''}`} onClick={() => editor.chain().focus().undo().run()}><AiOutlineUndo/></div>
        <div style={{ height: 30, width: 30 }} className={`${menuClass}  ${editor.isActive('orderedList')? 'bg-gray' : ''}`} onClick={() => editor.chain().focus().redo().run()}><AiOutlineRedo/></div>
        <div style={{ borderRight: "1px solid #CCC", marginLeft: 5, marginRight: 10 }}></div>
        <div style={{ height: 30, width: 30 }} className={`${menuClass}`} onClick={() => editor.chain().insertContent({ type: "merge-tag"}).focus().run()}><AiOutlineGroup/></div>
        <div style={{ borderRight: "1px solid #CCC", marginLeft: 5, marginRight: 10 }}></div>
        <div className="display-flex flex-middle mh-10 cursor-pointer">
          <Dropdown overlay={insertMenu()} placement="bottomRight" trigger="click">
            <div className="display-flex flex-middle">Insert <HiOutlineChevronDown style={{ marginLeft: 5}}/></div>
          </Dropdown>
        </div>
      </div>
    </FloatingContainer>
  )
}

const NewContractPage = () => {
  const [isLoading, setLoading] = useState(true);
  const [clientEventRoles, setClientEventRoles] = useState([]);
  const [adminEventRoles, setAdminEventRoles] = useState([]);

  const [sendRequest] = useApi()

  useEffect(() => {
    loadPage()
  }, []);

  const loadPage = async () => {
    try {
      const clientEventRoleResults = await sendRequest(getAccountClientEventRoles())
      setClientEventRoles(clientEventRoleResults)

      const adminEventRoleResults = await sendRequest(getAccountAdminEventRoles())
      setAdminEventRoles(adminEventRoleResults)

      setLoading(false)
    } catch (error) {
      console.log(error)
    }
  }

  if (isLoading) {
    return (
      <div className="event-contract--container">
        <LoadingSpinner/>
      </div>
    )
  }

  return <NewContractPageContent clientEventRoles={clientEventRoles} adminEventRoles={adminEventRoles}/>
}

const NewContractPageContent = (props) => {

  const [isLoading, setLoading] = useState(true);
  const [isSaving, setSaving] = useState(false);
  const [isEditingTitle, setEditingTitle] = useState(false);
  const [templateName, setTemplateName] = useState("");

  useDocumentTitle("Contract Template")
  const navigate = useNavigate();
  const params = useParams();
  const [sendRequest] = useApi()

  const contractTemplateId = params.id;

  const saveTimerRef = useRef(null);
  const titleInputRef = useRef(null);

  const editor = useEditor({
    extensions: [
      StarterKit,
      Underline,
      TextAlign.configure({
        types: ['heading', 'paragraph'],
      }),
      Placeholder.configure({
        placeholder: "Start typing..."
      }),
      MergeTagExtension.configure({ 
        clientEventRoles: props.clientEventRoles,
        adminEventRoles: props.adminEventRoles
      }),
      EventServicesExtension
    ],
    content: '',
    onUpdate({ editor }) {
      if (saveTimerRef.current) {
        clearTimeout(saveTimerRef.current)
      }
      setSaving(true)
      saveTimerRef.current = setTimeout(() => saveChanges(editor.getJSON()), 1500)
    },
    onCreate({ editor }) {
      refreshPage(editor)
    }
  })

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const saveChanges = async (json) => {
    try {
      const body = {
        content: json
      }
      await sendRequest(updateContractTemplateContent(contractTemplateId, body))
    } catch (error) {
      console.log(error)
    } finally {
      setSaving(false)
    }
  }

  const saveTemplateName = async () => {
    try {
      setSaving(true)
      const body = {
        contract_template_name: templateName
      }
      await sendRequest(updateContractTemplateName(contractTemplateId, body))
    } catch (error) {
      console.log(error)
    } finally {
      setSaving(false)
      setEditingTitle(false)
    }
  }

  const refreshPage = async (editorInstance) => {
    try {
      const contractTemplateResults = await sendRequest(getContractTemplate(contractTemplateId))
      setTemplateName(contractTemplateResults.contract_template_name)
      editorInstance.commands.setContent(contractTemplateResults.content)
    } finally {
      setLoading(false)
    }
  }

  const startEditingTitle = () => {
    setEditingTitle(true)
    setTimeout(() => {
      titleInputRef.current.focus()
    }, 100)
  }

  const onTitleKeyPress = (e) => {
    if (e.keyCode == 13) {
      saveTemplateName()
    }
  }

  const onSave = () => {
    if (isSaving) { return }
    navigate("/admin/contract-templates")
  }

  const renderSubHeader = () => {
    if (isSaving) {
      return (
        <div className="flex-row flex-middle c-text-gray ml-15 flex-1">
          <FiRefreshCw style={{ marginRight: 5}}/> Saving changes...
        </div>
      )
    } else {
      return (
        <div className="flex-row flex-middle c-text-gray ml-15 flex-1">
          <FiCheckCircle style={{ marginRight: 5}}/> All changes saved!
        </div>
      )
    }
  }

  const renderHeader = () => {
    return (
      <>
        <div className="event-contract--header p-20">
          <div className="flex-row flex-middle">
            <div className={`header-editable-title small ${isEditingTitle ? "editing" : ""}`}>
              <div className="header-editable-title--label" onClick={() => startEditingTitle()}>{ templateName }</div>
              <div className="header-editable-title--input">
                <Input 
                  size="medium" 
                  placeholder="Template Name" 
                  ref={titleInputRef}
                  onBlur={() => saveTemplateName()} 
                  value={templateName} 
                  onChange={(e) => setTemplateName(e.target.value)} 
                  onKeyUp={onTitleKeyPress}
                />
              </div>
            </div>
            { renderSubHeader() }
            <div className="flex-0">
              <button className="small-primary-button" onClick={() => onSave()}>Done</button>
            </div>
          </div>
        </div>
        <div className="bg-white b-border p-10">
          <MenuBar editor={editor} />
        </div>
      </>
    )
  }

  const renderContent = () => {
    return (
      <div className="event-contract--body">
        <div className="event-contract--content">
          <FloatingContainer className="ph-20 mb-20 bg-gray" verticalPadding={20} maxWidth={850}>
            <div className="shadow-card p-30 mt-15">
              <div style={{ minHeight: 400 }}>
                <EditorContent editor={editor} />
              </div>
              <div className="bg-gray radius-4 p-20 mt-20">
                <div className="c-text-gray">
                  <i>Signature lines will automatically be added at the end of the document.</i>
                </div>
                <div className="b-border mt-50"></div>
                <div className="mt-5 ml-5">
                  Signer #1
                </div>
                <div className="b-border mt-50"></div>
                <div className="mt-5 ml-5">
                  Signer #2
                </div>
              </div>
            </div>
          </FloatingContainer>
        </div>
      </div>
    )
  }

  if (isLoading) {
    return <LoadingSpinner/>
  }

  return (
    <div className="event-contract--container">
      { renderHeader() }
      { renderContent() }
    </div>
  );
}

export default NewContractPage;

import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {SortableContainer, sortableElement} from 'react-sortable-hoc';
import arrayMove from 'array-move';
import { isUndefined, cloneDeep } from "lodash";
import { Row, Col, Input, notification } from 'antd';
import useApi from '../../hooks/useApi';
import { getSystemRecommendedPlaylists, createSystemRecommendedPlaylistGroup, updateSystemRecommendedPlaylistGroup, getSystemRecommendedPlaylistGroup } from '../../api';
import LoadingSpinner from '../../components/loading';
import AdminContent from '../../components/adminContent';
import { ArrowLeftOutlined} from '@ant-design/icons';
import { MdAddCircleOutline, MdRemoveCircleOutline, MdCheck } from "react-icons/md";

const NewSystemRecommendedPlaylistGroupPage = (props) => {

  const [isLoading, setLoading] = useState(true);
  const [groupName, setGroupName] = useState("");
  const navigate = useNavigate();
  const params = useParams();
  const [playlists, setPlaylists] = useState([]);
  const [selectedPlaylists, setSelectedPlaylists] = useState([]);

  const [sendRequest] = useApi()

  const groupId = params.id;
  const isNewGroup = isUndefined(groupId);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (!isNewGroup) {
      refreshPage();
    } else {
      setLoading(false)
    }

    sendRequest(getSystemRecommendedPlaylists()).then(response => {
      setPlaylists(response);
      setLoading(false)
    }).catch((e) => setLoading(false))
  }, []);

  const refreshPage = () => {
    sendRequest(getSystemRecommendedPlaylistGroup(groupId)).then(response => {
      setGroupName(response.group_name)
      setSelectedPlaylists(response.system_recommended_playlist_ids)
      setLoading(false)
    }).catch((e) => setLoading(false))
  }

  const onSubmit = () => {

    var body = {
      group_name: groupName,
      system_recommended_playlist_ids: selectedPlaylists
    }

    if (isNewGroup) {
      sendRequest(createSystemRecommendedPlaylistGroup(body)).then(response => {
        displaySuccess()
      })
    } else {
      sendRequest(updateSystemRecommendedPlaylistGroup(groupId, body)).then(response => {
        displaySuccess()
      })
    }
  }

  const displaySuccess = () => {
    notification.success({
      message: 'Success!',
      description: 'Your playlist group has been saved.',
      duration: 3
    });
    navigate("/system/recommended-playlist-groups");
  }

  const onSortEnd = (oldIndex, newIndex) => {
    var newSelectedPlaylists = cloneDeep(selectedPlaylists)
    if (oldIndex !== newIndex) {
      const sortedSelectedPlaylists = arrayMove(newSelectedPlaylists, oldIndex, newIndex).filter(el => !!el);
      setSelectedPlaylists(sortedSelectedPlaylists)
    }
  };

  const renderSelectedPlaylist = (playlistId, index) => {
    const playlist = playlists.find((x) => x.system_recommended_playlist_id == playlistId)
    return (
      <div key={index} className="shadow-card p-20">
        <Row align="middle">
          <Col flex={1}>
            <div className="fw-700 fs-14">{playlist && playlist.playlist_name}</div>
          </Col>
          <Col>
            <div className="display-flex">
              <MdRemoveCircleOutline style={{ fontSize: 24, color: '#CCC'}} onClick={() => onRemove(playlistId, index)}/>
            </div>
          </Col>
        </Row>
      </div>
    )
  }

  const SortableItem = sortableElement(({value, itemIndex}) => (
    <li className="mb-10">
      {renderSelectedPlaylist(value, itemIndex)}
    </li>
  ));

  const SortableList = SortableContainer(({items, sectionIndex}) => {
    return (
      <ul className="question-row-container">
        {items.map((value, index) => (
          <SortableItem key={`item-${index}`} index={index} itemIndex={index} value={value}/>
        ))}
      </ul>
    );
  });

  const onSelect = (id) => {
    var newSelectedPlaylists = cloneDeep(selectedPlaylists);
    newSelectedPlaylists.push(id)
    setSelectedPlaylists(newSelectedPlaylists)
  }

  const onRemove = (id) => {
    var newSelectedPlaylists = cloneDeep(selectedPlaylists);
    newSelectedPlaylists = newSelectedPlaylists.filter(x => x != id)
    setSelectedPlaylists(newSelectedPlaylists)
  }

  const renderHeader = () => {
    return (
      <Row align="middle" gutter={[15,15]} className="ph-20" style={{ height: 72 }}>
        <Col>
          <div className="admin-page-back-arrow" onClick={() => navigate(-1)}> <ArrowLeftOutlined/></div>
        </Col>
        <Col flex={1}>
          <Input placeholder="Group Name" size="large" value={groupName} onChange={(e) => setGroupName(e.target.value)} style={{ fontSize: 18, fontWeight: 700, maxWidth: 500}}/>
        </Col>
        <Col>
          <button className="page-title-button ml-10" onClick={onSubmit}>Save</button>
        </Col>
      </Row>
    )
  }

  const renderContent = () => {
    if (selectedPlaylists.length == 0) {
      return (
        <div className="c-text-ccc text-center fs-16 mt-30">No playlists yet</div>
      )
    }
    return (
      <div className="p-15" style={{ maxWidth: 800 }}>
        <SortableList items={selectedPlaylists} onSortEnd={({ oldIndex, newIndex }) => onSortEnd(oldIndex, newIndex)} helperClass="question-row-dragging" pressDelay={100} lockAxis="y"/>
      </div>
    )
  }

  const renderPlaylist = (playlist, index) => {
    const alreadyAdded = selectedPlaylists.includes(playlist.system_recommended_playlist_id);
    return (
      <Row  align="middle" key={index} className="b-border ph-15 pv-10">
        <Col flex={1}>
          <div className="fs-16 fw-700">{playlist.playlist_name}</div>
          <div className="fs-14 fw-500 c-text-gray">{playlist.songs.length} Songs</div>
        </Col>
        <Col>
          { alreadyAdded ? <MdCheck style={{ fontSize: 24, color: '#00b894'}}/> : <MdAddCircleOutline style={{ fontSize: 24, color: '#536DFE'}} onClick={() => onSelect(playlist.system_recommended_playlist_id)}/>}
        </Col>
      </Row>
    )
  }

  const renderPanel = () => {
    return (
      <div className="shadow-card m-20">
        { playlists.map((x,i) => renderPlaylist(x,i)) }
      </div>
    )
  }

  if (isLoading) {
    return <LoadingSpinner/>
  }

  return (
    <AdminContent header={renderHeader()} body={renderContent()} panel={renderPanel()} showPanel={true}/>
  );
}

export default NewSystemRecommendedPlaylistGroupPage;

import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Grid, Row, Col, Dropdown, Menu, notification, Form, Modal, Switch } from 'antd';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import useApi from '../../../hooks/useApi';
import useAccountSettings from '../../../hooks/useAccountSettings';
import useCurrency from '../../../hooks/useCurrency';
import useDocumentTitle from '../../../hooks/useDocumentTitle';
import { getInvoice, updateInvoiceStatus, createInvoicePayment, updateInvoicePayment, deleteInvoicePayment, deleteInvoice, confirmPendingInvoicePayment, deletePendingInvoicePayment } from '../../../api';
import LoadingSpinner from '../../../components/loading';
import PrimaryButton from '../../../components/primaryButton';
import FloatingContainer from '../../../components/floatingContainer'
import StatusTag from '../../../components/statusTag'
import { MdArrowForwardIos, MdKeyboardArrowDown, MdOutlineUndo, MdOutlineClose, MdOutlineMoreHoriz, MdOutlineEdit } from "react-icons/md";
import { formatCurrencyString, getNumericCurrency } from '../../../helpers/contractHelper';
import { formatDateShort, formatEventDateLong, formatDateTimeShort, formatDateMedium } from '../../../helpers/dateHelper';
import { isEmpty,isNull, get, isArray, startCase, cloneDeep } from "lodash";
import moment from "moment";
import AdminContent from '../../../components/adminContent';
import InvoiceSummaryV2 from '../../../components/invoiceSummaryV2';
import { EditOutlined, EyeOutlined, DeleteOutlined } from '@ant-design/icons';
import { FiExternalLink, FiSend, FiCheck, FiMail, FiDollarSign, FiLink } from "react-icons/fi";
import invoiceStatus from "../../../constants/invoiceStatus";
import InvoicePaymentStatus from "../../../constants/invoicePaymentStatus";
import PaymentScheduleStatus from "../../../constants/paymentScheduleStatus";
import { FaCheckCircle } from "react-icons/fa";
import {
  renderInputField,
  renderCurrencyField,
  renderCurrencyFieldWithValidator,
  renderMediumDateField,
  renderTextAreaField,
  renderFormLabel
} from '../../../components/formFields'

const BASE_URL = process.env.REACT_APP_BASE_URL;

const EventInvoicePage = () => {

  const [isLoading, setLoading] = useState(true);
  const [isProcessing, setProcessing] = useState(false);
  const [eventId, setEventId] = useState("");
  const [notes, setNotes] = useState("");
  const [dueDate, setDueDate] = useState(moment());
  const [eventInvoice, setEventInvoice] = useState({});
  const [selectedBillTo, setSelectedBillTo] = useState("");
  const [selectedBillToFirstName, setSelectedBillToFirstName] = useState("");
  const [selectedBillToLastName, setSelectedBillToLastName] = useState("");
  const [selectedBillToEmail, setSelectedBillToEmail] = useState("");
  const [payments, setPayments] = useState([]);
  const [nextPayment, setNextPayment] = useState({});
  const [lastPayment, setLastPayment] = useState({});
  const [isPaymentModalVisible, setPaymentModalVisible] = useState(false);
  const [isEditPaymentModalVisible, setEditPaymentModalVisible] = useState(false);
  const [isConfirmPendingPaymentModalVisible, setConfirmPendingPaymentModalVisible] = useState(false);
  const [isViewPaymentModalVisible, setViewPaymentModalVisible] = useState(false);
  const [isPaymentErrorModalVisible, setPaymentErrorModalVisible] = useState(false);
  const [isAddPaymentErrorModalVisible, setAddPaymentErrorModalVisible] = useState(false);
  const [isConfirmRemovePaymentModalVisible, setConfirmRemovePaymentModalVisible] = useState(false);
  const [isConfirmRemovePendingPaymentModalVisible, setConfirmRemovePendingPaymentModalVisible] = useState(false);
  const [isInvoiceEmailsModalVisible, setInvoiceEmailsModalVisible] = useState(false);
  const [isConfirmRemoveVisible, setConfirmRemoveVisible] = useState(false);
  const [isConfirmCancelVisible, setConfirmCancelVisible] = useState(false);
  const [isNewPayment, setNewPayment] = useState(false);
  const [selectedPayment, setSelectedPayment] = useState({});
  const [shouldSendPaymentEmail, setShouldSendPaymentEmail] = useState(false);
  const [shouldSendConfirmPaymentEmail, setShouldSendConfirmPaymentEmail] = useState(false);
  const [shouldIncludeTip, setShouldIncludeTip] = useState(false);
  const [hasCustomPayment, setHasCustomPayment] = useState(false);
  const [hasPendingPayment, setHasPendingPayment] = useState(false);
  const [pendingPayment, setPendingPayment] = useState({});
  const [numberOfUnpaidSchedules, setNumberOfUnpaidSchedules] = useState(0);
  const [unpaidSchedules, setUnpaidSchedules] = useState([]);
  const [invoiceEmails, setInvoiceEmails] = useState([]);
  const [selectedPaymentTab, setSelectedPaymentTab] = useState("schedule");
  const [shouldShowPaymentTabs, setShouldShowPaymentTabs] = useState(true);
  const [selectedPaymentIds, setSelectedPaymentIds] = useState([]);

  useDocumentTitle("Invoice Details")
  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();

  const navigate = useNavigate();
  const params = useParams();
  const [sendRequest] = useApi()
  const [accountSettings] = useAccountSettings()
  const [currency] = useCurrency()
  const [paymentForm] = Form.useForm();
  const [editPaymentForm] = Form.useForm();
  const [confirmPaymentForm] = Form.useForm();

  const invoiceId = params.id;

  useEffect(() => {
    window.scrollTo(0, 0);
    refreshPage()
  }, []);

  const refreshPage = async () => {
    try {
      const results = await sendRequest(getInvoice(invoiceId))
      const allPayments = results.payments ?? []
      const completePayments = allPayments.filter(x => x.status == InvoicePaymentStatus.COMPLETE)

      setEventInvoice(results)
      setEventId(results.event_id)
      setSelectedBillTo(results.bill_to_user_id ? results.bill_to_user_id : 1)
      if (!isEmpty(results.bill_to_user)) {
        setSelectedBillToFirstName(results.bill_to_user.first_name)
        setSelectedBillToLastName(results.bill_to_user.last_name)
        setSelectedBillToEmail(results.bill_to_user.email)
      }
      setDueDate(moment.utc(results.due_date))
      setNotes(results.notes)
      setPayments(completePayments)
      setInvoiceEmails(results.invoice_emails ?? [])

      const paymentSchedules = results.payment_schedules ?? []
      const unpaidSchedulesResults = cloneDeep(paymentSchedules).filter(x => x.status == PaymentScheduleStatus.UNPAID)
      setUnpaidSchedules(unpaidSchedulesResults)
      setNumberOfUnpaidSchedules(unpaidSchedulesResults.length)
      if (unpaidSchedulesResults.length > 0) {
        setNextPayment(unpaidSchedulesResults[0])
      } else {
        setNextPayment({})
      }

      const lastPaymentResult = allPayments.findLast(x => x.status == InvoicePaymentStatus.COMPLETE)
      if (lastPaymentResult) {
        setLastPayment(lastPaymentResult)
      } else {
        setLastPayment({})
      }

      setHasCustomPayment(results.has_custom_payment)

      // Check if there is a pending manual payment
      setHasPendingPayment(results.has_pending_manual_payment)
      setPendingPayment(results.pending_manual_payment)

    } finally {
      setLoading(false)
    }
  }

  const navigateToPDF = () => {
    window.open(`${BASE_URL}/pdfs/v2/invoice/${eventInvoice.invoice_id}`, "_blank")
  }

  const navigateToViewInvoice = () => {
    window.open(`${BASE_URL}/v/invoice/${invoiceId}`, "_blank")
  }

  const navigateToEditInvoice = () => {
    navigate(`/admin/invoices/${invoiceId}/edit?from=invoice-details`)
  }
  
  const navigateToPreviewInvoice = () => {
    navigate(`/admin/invoices/${invoiceId}/preview?from=invoice-details`)
  }

  const navigateToSendInvoice = () => {
    navigate(`/admin/invoices/${invoiceId}/send?from=invoice-details`)
  }

  const handleDeleteInvoice = () => {
    setConfirmRemoveVisible(true)
  };

  const handleCancelInvoice = () => {
    setConfirmCancelVisible(true)
  };

  const confirmDeleteInvoice = async () => {
    try {
      await sendRequest(deleteInvoice(invoiceId))
      navigate(`/admin/invoices`)
      notification.success({
        message: 'Success!',
        description: 'Your invoice has been deleted.',
        duration: 3
      });
    } catch {
      notification.error({
        message: 'Error!',
        description: 'Something went wrong.',
        duration: 3
      });
      setConfirmRemoveVisible(false)
    }
  }

  const confirmCancelInvoice = async () => {
    try {
      await updateStatus(invoiceStatus.CANCELLED)
    } finally {
      setConfirmCancelVisible(false)
    }
  }

  const onViewEmailHistory = async () => {
    setInvoiceEmailsModalVisible(true)
    try {
      await refreshPage()
    } catch {}
  }

  const updateStatus = async (status) => {
    try {
      await sendRequest(updateInvoiceStatus(invoiceId, { status: status }))
      await refreshPage()
      notification.success({
        message: 'Success!',
        description: 'The invoice status has been updated successfully.',
        duration: 3
      });
    } catch {
      notification.error({
        message: 'Error!',
        description: 'Something went wrong.',
        duration: 3
      });
    }
  }

  const onAddPayment = () => {
    if (hasPendingPayment) {
      setAddPaymentErrorModalVisible(true)
      return
    }
    if (unpaidSchedules.length > 0) {
      // Select the first unpaid schedule
      setSelectedPaymentIds([unpaidSchedules[0].payment_schedule_id])
      setShouldShowPaymentTabs(true)
      setSelectedPaymentTab("schedule")
    } else if (isArray(eventInvoice.payment_schedules) && eventInvoice.payment_schedules.length == 1) {
      // Otherwise, hide tabs and force custom amount
      setShouldShowPaymentTabs(false)
      setSelectedPaymentTab("custom")
    }
    setNewPayment(true)
    setSelectedPayment({})
    setShouldIncludeTip(false)
    setShouldSendPaymentEmail(false)
    paymentForm.resetFields()
    paymentForm.setFieldsValue({ payment_date: moment().startOf("day"), amount: eventInvoice.balance })
    setPaymentModalVisible(true)
  }

  const onPaymentSubmit = async (values) => {
    if (isProcessing) { return }
    try {
      setProcessing(true)

      var amount = 0
      var selectedScheduleIds = []

      if (selectedPaymentTab == "schedule") {
        // If payment schedules were selected...
        selectedScheduleIds = selectedPaymentIds
        amount = unpaidSchedules.reduce((x,y) => {
          if (selectedPaymentIds.includes(y.payment_schedule_id)) {
            return x + Number(y.amount)
          }
          return x;
        }, 0)
      } else {
        // If custom payment was selected...
        amount = getNumericCurrency(values["amount"])
      }
      const tipAmount = shouldIncludeTip ? getNumericCurrency(values["tip_amount"]) : 0
      const totalAmount = amount + tipAmount

      const body = {
        payment_method_type: values["payment_method_type"],
        amount: totalAmount,
        tip_amount: tipAmount,
        payment_date: values["payment_date"].format(),
        payment_schedule_ids: selectedScheduleIds,
        notes: values["notes"],
        send_email: shouldSendPaymentEmail
      }
      await sendRequest(createInvoicePayment(invoiceId, body))
      setPaymentModalVisible(false)
      await refreshPage()
      notification.success({
        message: 'Success!',
        description: 'A payment has been recorded on the invoice.',
        duration: 3
      });
    } catch {
      notification.error({
        message: 'Error!',
        description: 'Something went wrong.',
        duration: 3
      });
      setPaymentModalVisible(false)
    } finally {
      setProcessing(false)
    }
  }

  const onEditPaymentSubmit = async (values) => {
    if (isProcessing) { return }
    try {
      setProcessing(true)

      var amount = 0
      const hasScheduleIds = isArray(selectedPayment.payment_schedule_ids) && selectedPayment.payment_schedule_ids.length > 0

      if (hasScheduleIds) {
        // Find original amount before tip
        const initialAmount = Number(selectedPayment.amount)
        const initialTipAmount = Number(selectedPayment.tip_amount)
        amount = initialAmount - initialTipAmount
      } else {
        // Get entered amount
        amount = getNumericCurrency(values["amount"])
      }

      // Calculate totals
      const tipAmount = shouldIncludeTip ? getNumericCurrency(values["tip_amount"]) : 0
      const totalAmount = amount + tipAmount

      // Save changes
      const body = {
        payment_method_type: values["payment_method_type"],
        amount: totalAmount,
        tip_amount: tipAmount,
        payment_date: values["payment_date"].format(),
        notes: values["notes"]
      }
      await sendRequest(updateInvoicePayment(selectedPayment.invoice_payment_id, body))
      setEditPaymentModalVisible(false)

      // Refresh data
      await refreshPage()
      notification.success({
        message: 'Success!',
        description: 'The payment has been updated.',
        duration: 3
      });
    } catch {
      notification.error({
        message: 'Error!',
        description: 'Something went wrong.',
        duration: 3
      });
      setEditPaymentModalVisible(false)
    } finally {
      setProcessing(false)
    }
  }

  const handleViewPayment = async (payment) => {
    setSelectedPayment(payment)
    setViewPaymentModalVisible(true)
  }

  const handleEditPayment = async (payment) => {
    if (payment.platform != "manual" && payment.platform != "custom") {
      setPaymentErrorModalVisible(true)
      return
    }
    setNewPayment(false)
    setSelectedPayment(payment)
    setShouldIncludeTip(payment.tip_amount > 0)
    const totalAmount = Number(payment.amount)
    const tipAmount = Number(payment.tip_amount)
    const amount = totalAmount - tipAmount
    const fields = {
      payment_method_type: payment.payment_method_type,
      amount: amount,
      tip_amount: tipAmount,
      payment_date: moment.utc(payment.payment_date),
      notes: payment.notes
    }
    editPaymentForm.setFieldsValue(fields)
    setEditPaymentModalVisible(true)
  }

  const handleRemovePayment = async (payment) => {
    setSelectedPayment(payment)
    setConfirmRemovePaymentModalVisible(true)
  }

  const removePayment = async () => {
    try {
      await sendRequest(deleteInvoicePayment(selectedPayment.invoice_payment_id))
      setConfirmRemovePaymentModalVisible(false)
      await refreshPage()
    } catch {
      setConfirmRemovePaymentModalVisible(false)
    }
  }

  const getPendingPaymentAmount = () => {
    const totalAmount = Number(pendingPayment.amount)
    const tipAmount = Number(pendingPayment.tip_amount)
    return totalAmount - tipAmount
  }

  const onConfirmPendingPayment = async () => {
    const body = {
      payment_method_type: pendingPayment.payment_method_type,
      payment_date: moment.utc(pendingPayment.payment_date), 
      notes: pendingPayment.notes, 
      tip_amount: pendingPayment.tip_amount
    }
    confirmPaymentForm.resetFields()
    confirmPaymentForm.setFieldsValue(body)
    setShouldSendConfirmPaymentEmail(true)
    setConfirmPendingPaymentModalVisible(true)
  }

  const onConfirmPaymentSubmit = async (values) => {
    try {
      const tipAmount = getNumericCurrency(values["tip_amount"])
      const body = {
        payment_method_type: values["payment_method_type"],
        tip_amount: tipAmount,
        payment_date: values["payment_date"].format(),
        notes: values["notes"],
        send_email: shouldSendConfirmPaymentEmail
      }
      await sendRequest(confirmPendingInvoicePayment(pendingPayment.invoice_payment_id, body))
      setConfirmPendingPaymentModalVisible(false)
      await refreshPage()
      notification.success({
        message: 'Success!',
        description: 'The payment has been confirmed.',
        duration: 3
      });
    } catch {
      setConfirmPendingPaymentModalVisible(false)
      notification.error({
        message: 'Error!',
        description: 'Something went wrong.',
        duration: 3
      });
    }
  }

  const onPaymentOptionClick = (id) => {
    var newSelectedPaymentIds = cloneDeep(selectedPaymentIds)

    // Do nothing if it's the first payment
    if (newSelectedPaymentIds.length > 0 && newSelectedPaymentIds[0] == id) {
      return
    }

    // Toggle selected
    if (newSelectedPaymentIds.includes(id)) {
      newSelectedPaymentIds = newSelectedPaymentIds.filter(x => x != id)
    } else {
      newSelectedPaymentIds.push(id)
    }

    setSelectedPaymentIds(newSelectedPaymentIds)
  }

  const onRemovePendingPayment = () => {
    setConfirmPendingPaymentModalVisible(false)
    setConfirmRemovePendingPaymentModalVisible(true)
  }

  const confirmRemovePendingPayment = async () => {
    try {
      if (pendingPayment.invoice_payment_id) {
        await deletePendingInvoicePayment(pendingPayment.invoice_payment_id)
        await refreshPage()
        notification.success({
          message: 'Success!',
          description: 'The pending payment has been removed.',
          duration: 3
        });
      } else {
        notification.error({
          message: 'Error!',
          description: 'There was a problem removing your pending payment.',
          duration: 3
        });
      }
    } catch {
      notification.error({
        message: 'Error!',
        description: 'There was a problem removing your pending payment.',
        duration: 3
      });
    } finally {
      setConfirmRemovePendingPaymentModalVisible(false)
    }
  }

  const paymentAmountValidator = (rule, value, cb) => {
    const valueNumber = getNumericCurrency(value)
    const balanceNumber = Number(eventInvoice.balance)
    const currentPaymentNumber = isNewPayment ? 0 : Number(selectedPayment.amount)
    const validationNumber = balanceNumber + currentPaymentNumber
    if (valueNumber > validationNumber && validationNumber > 0) {
      cb(`The amount cannot be over the balance of ${formatCurrencyString(validationNumber, eventInvoice.currency)}`)
    } else {
      cb()
    }
  }

  const getDueDateAndDescription = (date) => {
    const startOfToday = moment.utc(moment().format("YYYY-MM-DD"))
    const dueDays = moment.utc(date).diff(startOfToday, 'days')
    var description = ""
    if (dueDays == 1) {
      description = `(tomorrow)`
    } else if (dueDays > 0) {
      description = `(${dueDays} days)`
    } else if (dueDays == 0) {
      description = `(today)`
    } else if (dueDays == -1) {
      description = `(yesterday)`
    } else if (dueDays < 0) {
      description = `(${-dueDays} days ago)`
    }
    return `${formatDateShort(date, accountSettings, true)} ${description}`
  }

  const copyLink = () => {
    notification.success({
      message: 'Success!',
      description: 'The link has been copied to your clipboard.',
      duration: 3
    });
  }

  const paymentMenu = (item, index) => {
    return (
      <Menu>
        { menuItem("View Details", <EyeOutlined/>, () => handleViewPayment(item))}
        { (item.platform == "custom" || item.platform == "manual") && menuItem("Edit Payment", <MdOutlineEdit/>, () => handleEditPayment(item))}
        { (item.platform == "custom" || item.platform == "manual") && menuItem("Remove Payment", <DeleteOutlined/>, () => handleRemovePayment(item))}
      </Menu>
    )
  };

  const menuItem = (text, icon, action = () => {}) => {
    return (
      <Menu.Item className="ph-15 pv-10">
        <div onClick={action}>
          {icon}<span style={{ marginLeft: 12}}>{text}</span>
        </div>
      </Menu.Item>
    )
  }

  const menu = () => {
    const invoiceLink = `${BASE_URL}/v/invoice/${eventInvoice.invoice_id}`
    if (eventInvoice.status == invoiceStatus.DRAFT) {
      return (
        <Menu>
          { menuItem("Edit Invoice", <EditOutlined/>, () => navigateToEditInvoice())}
          { menuItem("Preview Invoice", <EyeOutlined/>, () => navigateToPreviewInvoice())}
          { menuItem("Send Invoice", <FiSend/>, () => navigateToSendInvoice())}
          { menuItem("Mark as Sent", <FiCheck/>, () => updateStatus(invoiceStatus.SENT)) }
          { menuItem("View / Download PDF", <FiExternalLink/>, () => navigateToPDF())}
          { menuItem("Delete Invoice", <DeleteOutlined/>, () => handleDeleteInvoice()) }
        </Menu>
      )
    } else if (eventInvoice.status == invoiceStatus.UNPAID || eventInvoice.status == invoiceStatus.SENT || eventInvoice.status == invoiceStatus.PENDING) {
      return (
        <Menu>
          { menuItem("View Invoice", <EyeOutlined/>, () => navigateToViewInvoice())}
          { menuItem("Edit Invoice", <EditOutlined/>, () => navigateToEditInvoice())}
          { menuItem("Preview Invoice", <EyeOutlined/>, () => navigateToPreviewInvoice())}
          { menuItem("Re-Send Invoice", <FiSend/>, () => navigateToSendInvoice())}
          { menuItem("Revert to Draft", <MdOutlineUndo/>, () => updateStatus(invoiceStatus.DRAFT))}
          { menuItem("Add Payment", <FiDollarSign/>, () => onAddPayment())}
          { menuItem("View Email History", <FiMail/>, () => onViewEmailHistory())}
          <CopyToClipboard text={invoiceLink} onCopy={() => copyLink()}>
            { menuItem("Copy Invoice Link", <FiLink/>, () => {})}
          </CopyToClipboard>
          { menuItem("View / Download PDF", <FiExternalLink/>, () => navigateToPDF())}
          { menuItem("Cancel Invoice", <DeleteOutlined/>, () => handleCancelInvoice()) }
        </Menu>
      )
    } else if (eventInvoice.status == invoiceStatus.PARTIALLY_PAID) {
      return (
        <Menu>
          { menuItem("View Invoice", <EyeOutlined/>, () => navigateToViewInvoice())}
          { menuItem("Re-Send Invoice", <FiSend/>, () => navigateToSendInvoice())}
          { menuItem("Add Payment", <FiDollarSign/>, () => onAddPayment())}
          { menuItem("View Email History", <FiMail/>, () => onViewEmailHistory())}
          <CopyToClipboard text={invoiceLink} onCopy={() => copyLink()}>
            { menuItem("Copy Invoice Link", <FiLink/>, () => {})}
          </CopyToClipboard>
          { menuItem("View / Download PDF", <FiExternalLink/>, () => navigateToPDF())}
          { menuItem("Cancel Invoice", <DeleteOutlined/>, () => handleCancelInvoice()) }
        </Menu>
      )
    } else if (eventInvoice.status == invoiceStatus.PAID) {
      return (
        <Menu>
          { menuItem("View Invoice", <EyeOutlined/>, () => navigateToViewInvoice())}
          { menuItem("View Email History", <FiMail/>, () => onViewEmailHistory())}
          <CopyToClipboard text={invoiceLink} onCopy={() => copyLink()}>
            { menuItem("Copy Invoice Link", <FiLink/>, () => {})}
          </CopyToClipboard>
          { menuItem("View / Download PDF", <FiExternalLink/>, () => navigateToPDF())}
        </Menu>
      )
    }
    return (
      <Menu>
        { menuItem("View Invoice", <EyeOutlined/>, () => navigateToViewInvoice())}
      </Menu>
    )
  };

  const renderHeader = () => {
    return (
      <div className="p-20">
        <div>
          <span className="c-blue fw-700 cursor-default" onClick={() => navigate("/admin/invoices")}>
            Invoices
          </span>
          <span className="fs-10 mh-5"><MdArrowForwardIos/></span>
          <span className="cursor-default c-text-gray">
            Details
          </span>
        </div>
        <Row align="middle">
          <Col flex={1}>
            <div className="fw-700 fs-24 mt-5">Invoice #{eventInvoice.invoice_number}</div>
          </Col>
          <Col flex={0}>
            <Dropdown overlay={menu()} placement="bottomRight" trigger="click">
              <button className="action-button">
                Actions
                <MdKeyboardArrowDown style={{ marginLeft: 10}} />
              </button>
            </Dropdown>
          </Col>
        </Row>
        
      </div>
    )
  }

  const renderSchedulePaymentOption = (schedule,index) => {
    const selected = selectedPaymentIds.includes(schedule.payment_schedule_id)
    const selectedClass = selected ? { border: '1px solid #536DFE'} : { border: '1px solid #CCC'}
    const marginTopClass = index == 0 ? "" : "mt-10"
    return (
      <Row key={index} gutter={[15,15]} align="middle" className={`${marginTopClass} pv-10 ph-5 cursor-default mh-0`} style={selectedClass} onClick={() => onPaymentOptionClick(schedule.payment_schedule_id)}>
        <Col flex={0}>
          { selected ? (
            <FaCheckCircle style={{ fontSize: 20, color: '#536DFE' }}/>
          ): (
            <FaCheckCircle style={{ fontSize: 20, color: '#dcdcdc' }}/>
          )}
        </Col>
        <Col flex={1}>
            <div className="fw-700 fs-16">{formatCurrencyString(schedule.amount, eventInvoice.currency)}</div>
            <div>Due on {formatDateShort(schedule.due_date, accountSettings, true)}</div>
        </Col>
      </Row>
    )
  }

  const renderPaymentModal = () => {
    return (
      <Modal visible={isPaymentModalVisible} footer={null} closable={false} wrapClassName="rounded-modal">
        <Row align="middle">
          <Col flex={1}>
            <div className="fw-700 fs-18">{"Add Payment"}</div>
          </Col>
          <Col>
            <div className="display-flex" onClick={() => setPaymentModalVisible(false)}><MdOutlineClose size={30} color={"#CCC"}/></div>
          </Col>
        </Row>
        <Form form={paymentForm} layout="vertical" name="payments" onFinish={onPaymentSubmit}>
          <Row gutter={[10,10]} className="mt-15">
            <Col xs={24}>
              { shouldShowPaymentTabs && (
                <div className="tab-slider mt-10">
                  <div className={`tab-slider--tab ${selectedPaymentTab == "schedule" ? "selected" : ""}`} onClick={() => setSelectedPaymentTab("schedule")}>
                    Scheduled Payment
                  </div>
                  <div className={`tab-slider--tab ${selectedPaymentTab == "custom" ? "selected" : ""}`} onClick={() => setSelectedPaymentTab("custom")}>
                    Custom Payment
                  </div>
                </div>
              )}
            </Col>
            <Col xs={24}>
              { renderInputField("Payment Method", "payment_method_type", true, false, "Ex. Cash, Check, etc.") }
            </Col>
            { selectedPaymentTab == "custom" && (
              <>
                <Col xs={24} sm={12}>
                  { renderCurrencyFieldWithValidator("Amount", "amount", true, currency.code, paymentAmountValidator) }
                </Col>
                <Col xs={24} sm={12}>
                  { renderMediumDateField("Payment Date", "payment_date", accountSettings) }
                </Col>
                </>
            )}
            { selectedPaymentTab == "schedule" && (
              <>
              <Col xs={24}>
                { renderFormLabel("Select one or more scheduled payments")}
                { unpaidSchedules.map((x,i) => renderSchedulePaymentOption(x,i))}
              </Col>
              <Col xs={24}>
                { renderMediumDateField("Payment Date", "payment_date", accountSettings) }
              </Col>
              </>
            )}
            <Col xs={24}>
              <div className="mt-10">
                <Switch checked={shouldIncludeTip} onChange={(value) => setShouldIncludeTip(value)} />
                <span className="ml-10 fw-600">Add Tip</span>
              </div>
            </Col>
            <Col xs={24}>
              { shouldIncludeTip && renderCurrencyField("Tip Amount", "tip_amount", true, currency.code)}
            </Col>
            <Col xs={24}>
              {renderTextAreaField("Notes", "notes", 3, false, "Optional notes about this payment (only visible to you)")}
            </Col>
            <Col xs={24}>
              <div className="mt-10">
                <Switch checked={shouldSendPaymentEmail} onChange={(value) => setShouldSendPaymentEmail(value)} />
                <span className="ml-10 fw-600">Send client a payment confirmation email</span>
              </div>
            </Col>
          </Row>
          <div className="admin-modal-footer">
            <PrimaryButton title={"Add Payment"} isProcessing={isProcessing} type="submit"/>
            <div className="text-center mt-15">
              <div className="blue-link" onClick={() => setPaymentModalVisible(false)}>Cancel</div>
            </div>
          </div>
        </Form>
      </Modal>
    )
  }


  const renderEditPaymentModal = () => {
    const hasScheduleIds = isArray(selectedPayment.payment_schedule_ids) && selectedPayment.payment_schedule_ids.length > 0
    const amount = Number(selectedPayment.amount) - Number(selectedPayment.tip_amount)
    return (
      <Modal visible={isEditPaymentModalVisible} footer={null} closable={false} wrapClassName="rounded-modal">
        <Row align="middle">
          <Col flex={1}>
            <div className="fw-700 fs-18">{ "Edit Payment"}</div>
          </Col>
          <Col>
            <div className="display-flex" onClick={() => setEditPaymentModalVisible(false)}><MdOutlineClose size={30} color={"#CCC"}/></div>
          </Col>
        </Row>
        <Form form={editPaymentForm} layout="vertical" name="edit_payment" onFinish={onEditPaymentSubmit}>
          <Row gutter={[10,10]} className="mt-15">
            <Col xs={24}>
              { renderInputField("Payment Method", "payment_method_type", true, false, "Ex. Cash, Check, etc.") }
            </Col>
            { hasScheduleIds ? (
              <>
                <Col xs={24}>
                  { renderFormLabel("Amount") }
                  <div className="fs-16">
                    {formatCurrencyString(amount, eventInvoice.currency)}
                  </div>
                  <div className="bg-gray p-10 mt-5">This payment is associated with a payment schedule, therefore the amount cannot be modified. If this payment was added by error, please remove this record and add a custom payment.</div>
                </Col>
                <Col xs={24}>
                  { renderMediumDateField("Payment Date", "payment_date", accountSettings) }
              </Col>
              </>
            ) : (
              <>
                <Col xs={24} sm={12}>
                  { renderCurrencyFieldWithValidator("Amount", "amount", true, currency.code, paymentAmountValidator) }
                </Col>
                <Col xs={24} sm={12}>
                  { renderMediumDateField("Payment Date", "payment_date", accountSettings) }
                </Col>
              </>
            )}
            <Col xs={24}>
              <div className="mt-10">
                <Switch checked={shouldIncludeTip} onChange={(value) => setShouldIncludeTip(value)} />
                <span className="ml-10 fw-600">Add Tip</span>
              </div>
            </Col>
            <Col xs={24}>
              { shouldIncludeTip && renderCurrencyField("Tip Amount", "tip_amount", true, currency.code)}
            </Col>
            <Col xs={24}>
              {renderTextAreaField("Notes", "notes", 3, false, "Optional notes about this payment (only visible to you)")}
            </Col>
          </Row>
          <div className="admin-modal-footer">
            <PrimaryButton title={"Update Payment"} isProcessing={isProcessing} type="submit"/>
            <div className="text-center mt-15">
              <div className="blue-link" onClick={() => setEditPaymentModalVisible(false)}>Cancel</div>
            </div>
          </div>
        </Form>
      </Modal>
    )
  }

  const renderConfirmPendingPaymentModal = () => {
    return (
      <Modal visible={isConfirmPendingPaymentModalVisible} footer={null} closable={false} wrapClassName="rounded-modal">
        <Row align="middle">
          <Col flex={1}>
            <div className="fw-700 fs-18">{ "Confirm Payment"}</div>
          </Col>
          <Col>
            <div className="display-flex" onClick={() => setConfirmPendingPaymentModalVisible(false)}><MdOutlineClose size={30} color={"#CCC"}/></div>
          </Col>
        </Row>
        <Form form={confirmPaymentForm} layout="vertical" name="payments" onFinish={onConfirmPaymentSubmit}>
          <Row gutter={[10,10]} className="" align="middle">
            <div className="p-15 bg-gray mt-10">{pendingPayment.full_name} has indicated that a manual payment is on its way. Once you have received this payment, confirm the details below to mark it as complete.</div>
            <Col xs={24}>
              { renderInputField("Payment Method", "payment_method_type", true, false, "Ex. Cash, Check, etc.") }
            </Col>
            <Col xs={24}>
              { renderFormLabel("Amount")}
              {formatCurrencyString(getPendingPaymentAmount(), eventInvoice.currency)}
            </Col>
            <Col xs={24} sm={12}>
              { renderCurrencyField("Tip Amount", "tip_amount", true, currency.code)}
            </Col>
            <Col xs={24} sm={12}>
              { renderMediumDateField("Payment Date", "payment_date", accountSettings) }
            </Col>
            <Col xs={24}>
              {renderTextAreaField("Notes", "notes", 3, false, "Optional notes about this payment (only visible to you)")}
            </Col>
            <Col xs={24}>
              <div className="mt-10">
                <Switch checked={shouldSendConfirmPaymentEmail} onChange={(value) => setShouldSendConfirmPaymentEmail(value)} />
                <span className="ml-10 fw-600">Send client a payment confirmation email</span>
              </div>
            </Col>
          </Row>
          <div className="admin-modal-footer">
            <PrimaryButton title={"Confirm Payment"} isProcessing={isProcessing} type="submit"/>
            <div className="text-center mt-15">
              <div className="red-link" onClick={() => onRemovePendingPayment()}>Remove</div>
            </div>
          </div>
        </Form>
      </Modal>
    )
  }

  const renderViewPaymentModal = () => {
    var paymentMethod = selectedPayment.payment_method_type
    if (selectedPayment.platform != "manual") {
      const brand = get(selectedPayment, "payment_method_details.brand", null)
      const last4 = get(selectedPayment, "payment_method_details.last4", null)
      if (!isNull(brand) && !isNull(last4)) {
        paymentMethod = `${startCase(brand)} ending in ${last4}`
      }
    }
    return (
      <Modal visible={isViewPaymentModalVisible} footer={null} closable={false} wrapClassName="rounded-modal">
        <Row align="middle">
          <Col flex={1}>
            <div className="fw-700 fs-18">Payment Details</div>
          </Col>
          <Col>
            <div className="display-flex" onClick={() => setViewPaymentModalVisible(false)}><MdOutlineClose size={30} color={"#CCC"}/></div>
          </Col>
        </Row>
        <Row gutter={[15,15]} className="mt-15">
          <Col xs={24}>
            { selectedPayment.platform == "custom" && (
              <div className="p-10 bg-gray text-center">This payment was manually added.</div>
            )}
            { selectedPayment.platform == "manual" && (
              <div className="p-10 bg-gray text-center">This payment was automatically added when the client initiated a manual payment.</div>
            )}
            { selectedPayment.platform == "stripe" && (
              <div className="p-10 bg-gray text-center">This payment was automatically added by Stripe.</div>
            )}
            { selectedPayment.platform == "paypal" && (
              <div className="p-10 bg-gray text-center">This payment was automatically added by PayPal.</div>
            )}
          </Col>
          <Col xs={12}>
            <div className="fw-700">Payment Method</div>
            <div>{paymentMethod}</div>
          </Col>
          <Col xs={12}>
            <div className="fw-700">Payment Date</div>
            <div>{formatDateShort(selectedPayment.payment_date, accountSettings, true)}</div>
          </Col>
          <Col xs={12}>
            <div className="fw-700">Amount</div>
            <div>{formatCurrencyString(selectedPayment.amount, eventInvoice.currency)} { selectedPayment.tip_amount > 0 && <span className="c-text-gray">(including tip)</span>}</div>
          </Col>
          <Col xs={12}>
            <div className="fw-700">Tip</div>
            <div>{formatCurrencyString(selectedPayment.tip_amount, eventInvoice.currency)}</div>
          </Col>
          <Col xs={24}>
            <div className="fw-700">Notes</div>
            <div className="line-breaks">{!isEmpty(selectedPayment.notes) ? selectedPayment.notes : "--"}</div>
          </Col>
        </Row>
        <div className="admin-modal-footer">
          <PrimaryButton title="Close" onClick={() => setViewPaymentModalVisible(false)}/>
        </div>
      </Modal>
    )
  }

  const renderInvoiceEmailHeader = () => {
    if (screens.sm) {
      return (
        <Row gutter={[15,15]} className="ph-10 pv-10 b-border bg-gray mt-15">
          <Col xs={7}>
            <div className="fw-700 fs-12 c-text-gray">RECIPIENTS</div>
          </Col>
          <Col xs={7}>
            <div className="fw-700 fs-12 c-text-gray">SUBJECT</div>
          </Col>
          <Col xs={3}>
            <div className="fw-700 fs-12 c-text-gray">STATUS</div>
          </Col>
          <Col xs={7}>
            <div className="fw-700 fs-12 c-text-gray">SENT ON</div>
          </Col>
        </Row>
      )
    } else {
      return (
        <Row gutter={[15,15]} className="ph-10 pv-10 b-border bg-gray mt-15">
          <Col xs={12}>
            <div className="fw-700 fs-12 c-text-gray">EMAILS</div>
          </Col>
        </Row>
      )
    }
  }

  const renderInvoiceEmail = (email, index) => {
    const recipients = isArray(email.recipients) ? email.recipients.join(", ") : ""
    if (screens.sm) {
      return (
        <Row gutter={[15,15]} className="ph-10 pv-15 b-border" key={index}>
          <Col xs={7}>
            <div>{recipients}</div>
          </Col>
          <Col xs={7}>
            <div>{email.subject}</div>
          </Col>
          <Col xs={3}>
            <StatusTag status={email.status}/>
          </Col>
          <Col xs={7}>
            <div>{formatDateTimeShort(email.sent_datetime, accountSettings)}</div>
          </Col>
        </Row>
      )
    } else {
      return (
        <Row gutter={[15,15]} className="ph-10 pv-15 b-border" key={index}>
          <Col xs={24}>
            <div className="fw-700">Recipients</div>
            <div>{recipients}</div>
            <div className="fw-700 mt-5">Subject</div>
            <div>{email.subject}</div>
            <div className="fw-700 mt-5">Status</div>
            <div><StatusTag status={email.status}/></div>
            <div className="fw-700 mt-5">Sent On</div>
            <div>{formatDateTimeShort(email.sent_datetime, accountSettings)}</div>
          </Col>
        </Row>
      )
    }
  }

  const renderViewInvoiceEmailsModal = () => {
    return (
      <Modal visible={isInvoiceEmailsModalVisible} footer={null} width={800} closable={false} wrapClassName="rounded-modal">
        <Row align="middle">
          <Col flex={1}>
            <div className="fw-700 fs-18">Invoice Email History</div>
          </Col>
          <Col>
            <div className="display-flex" onClick={() => setInvoiceEmailsModalVisible(false)}><MdOutlineClose size={30} color={"#CCC"}/></div>
          </Col>
        </Row>
        { invoiceEmails.length == 0 && (
          <div className="bg-gray ph-15 pv-30 text-center mt-15">
            No emails have been sent for this invoice yet.
          </div>
        )}
        { invoiceEmails.length > 0 && (
          <>
            { renderInvoiceEmailHeader() }
            {invoiceEmails.map((x,i) => renderInvoiceEmail(x,i))}
          </>
        )}
        <div className="admin-modal-footer">
          <PrimaryButton title="Close" onClick={() => setInvoiceEmailsModalVisible(false)}/>
        </div>
      </Modal>
    )
  }

  const renderPaymentErrorModal = () => {
    return (
      <Modal visible={isPaymentErrorModalVisible} closable={false} footer={null} width={400} wrapClassName="rounded-modal">
        <div className="text-right" onClick={() => setPaymentErrorModalVisible(false)}><MdOutlineClose size={30} color={"#CCC"}/></div>
        <div className="mt-10">
          <div className="fw-700 fs-20 text-center">Cannot Modify Payment</div>
          <div className="fw-500 fs-14 mt-20 mb-20 text-center">This payment cannot be modified since it was created through a payment processor.</div>
          <button className="primary-button" type="button" onClick={() => setPaymentErrorModalVisible()}>OK</button>
        </div>
      </Modal>
    )
  }

  const renderAddPaymentErrorModal = () => {
    return (
      <Modal visible={isAddPaymentErrorModalVisible} closable={false} footer={null} width={400} wrapClassName="rounded-modal">
        <div className="text-right" onClick={() => setAddPaymentErrorModalVisible(false)}><MdOutlineClose size={30} color={"#CCC"}/></div>
        <div className="mt-10">
          <div className="fw-700 fs-20 text-center">Pending Payment</div>
          <div className="fw-500 fs-14 mt-20 mb-20 text-center">All pending payments must be confirmed or removed before adding additional payments.</div>
          <button className="primary-button" type="button" onClick={() => setAddPaymentErrorModalVisible()}>OK</button>
        </div>
      </Modal>
    )
  }

  const renderConfirmRemovePaymentModal = () => {
    return (
      <Modal visible={isConfirmRemovePaymentModalVisible} closable={false} footer={null} width={400} wrapClassName="rounded-modal">
        <div className="text-right" onClick={() => setConfirmRemovePaymentModalVisible(false)}><MdOutlineClose size={30} color={"#CCC"}/></div>
        <div className="mt-10">
          <div className="fw-700 fs-20 text-center">Remove Payment</div>
          <div className="fw-500 fs-14 mt-20 mb-20 text-center">Are you sure you would like to remove the payment made on <span className="fw-700">{formatDateShort(selectedPayment.payment_date, accountSettings, true)}</span> from this invoice?</div>
          <button className="primary-button warning" type="button" onClick={() => removePayment()}>Remove Payment</button>
          <div className="text-center mt-15">
            <div className="blue-link" onClick={() => setConfirmRemovePaymentModalVisible(false)}>Cancel</div>
          </div>
        </div>
      </Modal>
    )
  }

  const renderConfirmRemovePendingPaymentModal = () => {
    return (
      <Modal visible={isConfirmRemovePendingPaymentModalVisible} closable={false} footer={null} width={400} wrapClassName="rounded-modal">
        <div className="text-right" onClick={() => setConfirmRemovePendingPaymentModalVisible(false)}><MdOutlineClose size={30} color={"#CCC"}/></div>
        <div className="mt-10">
          <div className="fw-700 fs-20 text-center">Remove Pending Payment</div>
          <div className="fw-500 fs-14 mt-20 mb-20 text-center">Are you sure you would like to remove the pending payment from this invoice?</div>
          <button className="primary-button warning" type="button" onClick={() => confirmRemovePendingPayment()}>Remove Payment</button>
          <div className="text-center mt-15">
            <div className="blue-link" onClick={() => setConfirmRemovePendingPaymentModalVisible(false)}>Cancel</div>
          </div>
        </div>
      </Modal>
    )
  }

  const renderConfirmRemoveModal = () => {
    return (
      <Modal visible={isConfirmRemoveVisible} closable={false} footer={null} width={400} wrapClassName="rounded-modal">
        <div className="text-right" onClick={() => setConfirmRemoveVisible(false)}><MdOutlineClose size={30} color={"#CCC"}/></div>
        <div className="mt-10">
          <div className="fw-700 fs-20 text-center">Delete Invoice</div>
          <div className="fw-500 fs-14 mt-20 mb-20 text-center">Are you sure you would like to delete this invoice? This action cannot be undone.</div>
          <button className="primary-button warning" type="button" onClick={() => confirmDeleteInvoice()}>Delete Invoice</button>
          <div className="text-center mt-15">
            <div className="blue-link" onClick={() => setConfirmRemoveVisible(false)}>Cancel</div>
          </div>
        </div>
      </Modal>
    )
  }

  const renderConfirmCancelModal = () => {
    return (
      <Modal visible={isConfirmCancelVisible} closable={false} footer={null} width={400} wrapClassName="rounded-modal">
        <div className="text-right" onClick={() => setConfirmCancelVisible(false)}><MdOutlineClose size={30} color={"#CCC"}/></div>
        <div className="mt-10">
          <div className="fw-700 fs-20 text-center">Cancel Invoice</div>
          <div className="fw-500 fs-14 mt-20 mb-20 text-center">Are you sure you would like to cancel this invoice? Clients will no longer be able to make payments towards this invoice.</div>
          <button className="primary-button warning" type="button" onClick={() => confirmCancelInvoice()}>Cancel Invoice</button>
          <div className="text-center mt-15">
            <div className="blue-link" onClick={() => setConfirmCancelVisible(false)}>Cancel</div>
          </div>
        </div>
      </Modal>
    )
  }

  const renderInvoiceHeaderContent = () => {
    if (eventInvoice.status == invoiceStatus.PAID) {
      return (
        <>
          <Col xs={24} md={6}>
            <div className="fs-14 fw-700 mb-5">Amount</div>
            <div className="fs-18">{formatCurrencyString(eventInvoice.total, eventInvoice.currency)}</div>
          </Col>
          <Col xs={24} md={10}>
            <div className="fs-14 fw-700 mb-5">Paid on</div>
            <div className="fs-18">{ getDueDateAndDescription(lastPayment.payment_date) }</div>
          </Col>
        </>
      )
    } else if (eventInvoice.status == invoiceStatus.CANCELLED) {
      return (
        <>
          <Col xs={24} md={6}>
            <div className="fs-14 fw-700 mb-5">Amount</div>
            <div className="fs-18">{formatCurrencyString(eventInvoice.total, eventInvoice.currency)}</div>
          </Col>
          <Col xs={24} md={10}>
            <div className="fs-14 fw-700 mb-5">Total Payments</div>
            <div className="fs-18">{formatCurrencyString(eventInvoice.payment_total, eventInvoice.currency)}</div>
          </Col>
        </>
      )
    } else if (eventInvoice.status != invoiceStatus.PAID && hasCustomPayment) {
      return (
        <>
          <Col xs={24} md={6}>
            <div className="fs-14 fw-700 mb-5">Balance</div>
            <div className="fs-18">{formatCurrencyString(eventInvoice.balance, eventInvoice.currency)}</div>
          </Col>
          <Col xs={24} md={10}>
            <div className="fs-14 fw-700 mb-5">Due on</div>
            <div className="fs-18">{ getDueDateAndDescription(eventInvoice.due_date) }</div>
          </Col>
        </>
      )
    } else if (eventInvoice.status != invoiceStatus.PAID && numberOfUnpaidSchedules > 1) {
      return (
        <>
          <Col xs={24} md={6}>
            <div className="fs-14 fw-700 mb-5">Next payment</div>
            <div className="fs-18">{formatCurrencyString(nextPayment.amount, eventInvoice.currency)}</div>
          </Col>
          <Col xs={24} md={10}>
            <div className="fs-14 fw-700 mb-5">Due on</div>
            <div className="fs-18">{ getDueDateAndDescription(nextPayment.due_date) }</div>
          </Col>
        </>
      )
    } else if (eventInvoice.status != invoiceStatus.PAID && numberOfUnpaidSchedules == 1) {
      return (
        <>
          <Col xs={24} md={6}>
            <div className="fs-14 fw-700 mb-5">Final payment</div>
            <div className="fs-18">{formatCurrencyString(nextPayment.amount, eventInvoice.currency)}</div>
          </Col>
          <Col xs={24} md={10}>
            <div className="fs-14 fw-700 mb-5">Due on</div>
            <div className="fs-18">{ getDueDateAndDescription(nextPayment.due_date) }</div>
          </Col>
        </>
      )
    }
    return null
  }

  const renderPaymentRecord = (payment, index) => {
    return (
      <Row className="pv-15 b-border" gutter={[15,15]} align="middle" key={index} wrap={false}>
        <Col flex={1}>
          <div className=""><span className="fw-700">{formatCurrencyString(payment.amount, currency.code)}</span> paid with {startCase(payment.payment_method_type)} on {formatDateShort(payment.payment_date, accountSettings, true)}</div>
          { payment.tip_amount > 0 && (
            <div className="fs-12 c-text-gray">(Includes a {formatCurrencyString(payment.tip_amount, eventInvoice.currency)} tip)</div>
          )}
        </Col>
        <Col flex={0}>
          <div className="display-flex mr-5">
            <Dropdown overlay={paymentMenu(payment, index)} placement="bottomRight" trigger="click">
              <div className="ph-10 display-flex">
                <MdOutlineMoreHoriz style={{ fontSize: 24, color: '#999'}}/>
              </div>
            </Dropdown>
          </div>
        </Col>
      </Row>
    )
  }

  const renderPendingPaymentRecord = () => {
    if (!hasPendingPayment) { return null }
    return (
      <Row className="pv-15 b-border" gutter={[15,15]} align="middle" wrap={false}>
        <Col flex={1}>
          <div>{pendingPayment.full_name} has initiated a manual payment of <b>{formatCurrencyString(pendingPayment.amount, eventInvoice.currency)}</b> on <b>{ formatDateMedium(pendingPayment.payment_date, eventInvoice.account_settings, true) }</b>.</div>
          { pendingPayment.tip_amount > 0 && (
            <div className="fs-12 c-text-gray">(Includes a {formatCurrencyString(pendingPayment.tip_amount, eventInvoice.currency)} tip)</div>
          )}
        </Col>
        <Col flex={0}>
          <button className="small-primary-button" onClick={() => onConfirmPendingPayment()}>Confirm</button>
        </Col>
      </Row>
    )
  }

  const renderContent = () => {
    const paddingClass = screens.md ? "p-30" : "p-20"
    const canAddPayment = eventInvoice.status != invoiceStatus.PAID && eventInvoice.status != invoiceStatus.DRAFT && eventInvoice.status != invoiceStatus.CANCELLED
    return (
      <>
        <FloatingContainer className="ph-20 mb-20 bg-gray" verticalPadding={20} maxWidth={850}>
          { hasPendingPayment && (
            <div className={`shadow-card-square ${paddingClass} mb-15 text-center`}>
              <div className="fw-700 fs-20 mb-15">Pending Payment</div>
              <div>{pendingPayment.full_name} has initiated a manual payment of <b>{formatCurrencyString(pendingPayment.amount, eventInvoice.currency)}</b> on <b>{ formatDateMedium(pendingPayment.payment_date, eventInvoice.account_settings, true) }</b>.</div>
              <button className="small-primary-button mt-15" onClick={() => onConfirmPendingPayment()}>Confirm Payment</button>
              <div>
                <div className="blue-link mt-15" onClick={() => onRemovePendingPayment()}>Remove</div>
              </div>
            </div>
          )}

          <div className={`shadow-card-square ${paddingClass}`}>
            <div className="fw-700 fs-20 mb-20">Invoice #{eventInvoice.invoice_number}<StatusTag status={eventInvoice.status} size="large" className="mt-5 ml-15"/></div>
            <Row className="" gutter={[15,20]}>
              <Col xs={24} md={8}>
                <div className="fs-14 fw-700 mb-5">Bill To</div>
                <div className="fs-18">{selectedBillToFirstName} {selectedBillToLastName}</div>
              </Col>
              { renderInvoiceHeaderContent() }
            </Row>
          </div>

          <div className={`shadow-card-square ${paddingClass} mt-15`}>
            <div className="fs-20 fw-700 pb-10 mb-15 b-border">Invoice Details</div>
            <Row align="middle" gutter={[15,20]} className="pv-20 ph-10">
              <Col xs={24} sm={12}>
                <div className="fw-700">Event</div>
                <div className="blue-link" onClick={() => navigate(`/admin/events/${eventInvoice.event_id}`)}>{eventInvoice.event_name}</div>
              </Col>
              <Col xs={24} sm={12}>
                <div className="fw-700">Event Date</div>
                <div className="">{ formatEventDateLong(eventInvoice.event_date, accountSettings)}</div>
              </Col>
              <Col xs={12}>
                <div className="fw-700">Invoice Date</div>
                <div className="">Mar 2, 2024</div>
              </Col>
              <Col xs={12}>
                <div className="fw-700">Due Date</div>
                <div className="">{ formatDateShort(eventInvoice.due_date, accountSettings, true) }</div>
              </Col>
              <Col xs={12}>
                <div className="fw-700">Payment Reminders</div>
                <div className="">{isArray(eventInvoice.payment_reminders) && eventInvoice.payment_reminders.length > 0  ? "On" : "None"}</div>
              </Col>
              <Col xs={12}>
                <div className="fw-700">Tips</div>
                <div className="">{eventInvoice.tips ? "On" : "Off"}</div>
              </Col>
            </Row> 
            </div>
            <div className={`shadow-card-square ${paddingClass} mt-15`}>
            <div className="fs-20 fw-700 pb-10 mb-15 b-border">Items</div>
            <InvoiceSummaryV2 invoice={eventInvoice}/>
            { !isEmpty(notes) && (
              <>
                <div className="fw-700 mt-30">Notes</div>
                <div className="line-breaks">{notes}</div>
              </>
            )}
          </div>

          <div className={`shadow-card-square ${paddingClass} mt-15`}>
            <div className="fs-20 fw-700 pb-10 b-border">Payments</div>
            { payments.map((x,i) => renderPaymentRecord(x,i))}
            { renderPendingPaymentRecord() }
            { payments.length == 0 && !hasPendingPayment && (
              <div className="p-15 bg-gray mt-10">
                No payments have been made or recorded yet.
                <div className="c-text-gray fs-12">{ !canAddPayment ? "A payment can only be added if the invoice is in a Sent or Partially Paid status." : ""}</div>
              </div>
            )}
            { canAddPayment && (
              <div className="blue-link mt-10" onClick={() => onAddPayment()}>+ Add Payment</div>
            )}
          </div>
        </FloatingContainer>
        { renderPaymentModal() }
        { renderEditPaymentModal() }
        { renderConfirmPendingPaymentModal() }
        { renderAddPaymentErrorModal() }
        { renderPaymentErrorModal() }
        { renderConfirmRemovePendingPaymentModal() }
        { renderConfirmRemovePaymentModal() }
        { renderViewPaymentModal() }
        { renderViewInvoiceEmailsModal() }
        { renderConfirmRemoveModal() }
        { renderConfirmCancelModal() }
      </>
    )
  }

  if (isLoading) {
    return (
      <LoadingSpinner/>
    )
  }

  return (
    <AdminContent header={renderHeader()} body={renderContent()}/>
  );
}

export default EventInvoicePage;

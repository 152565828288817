import React, { useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { Image } from 'antd';
import { BackToHomeLink, DocLink } from '../../../components/docComponents'
import AdminContent from '../../../components/adminContent';
import FloatingContainer from '../../../components/floatingContainer'
import contractTemplateExampleImage from '../../../images/contract-template-example.png';
import useDocumentTitle from '../../../hooks/useDocumentTitle';

const DocsContractsPage = () => {

  useDocumentTitle("Contracts | Documentation")
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const renderContent = () => {
    return (
      <FloatingContainer className="ph-20 bg-white" verticalPadding={20} maxWidth={1000}>
        <BackToHomeLink/>
        <div className="fw-700 fs-24 mt-20">Contracts</div>
        <div className="fw-500 fs-16 c-text-gray">Create and send contracts for your clients to sign electronically.</div>
        <div className="b-border mv-20"></div>
        <div className="video-responsive mh-20">
          <iframe
            width="560"
            height="315"
            src={`https://www.youtube.com/embed/um5FZo23fvE`}
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
            title="How to Create and Send Contracts | DJ Planning Center"
          />
        </div>
        <div className="b-border mv-20"></div>
        <div className="fw-700 fs-18 mb-15">Creating a Contract Template</div>
        <ol>
          <li className="mb-15">
            Go to the <DocLink to="/admin/contract-templates">Contract Templates</DocLink> page and select <b>New Template</b>.
          </li>
          <li className="mb-15">
            In the <b>New Contract Template</b> modal, enter a name for the template and select <b>Continue</b>.
          </li>
          <li className="mb-15">
            Use the editor to create your contract. The formatting tools in the toolbar can be used to customize the look and feel of the document.
            <div className="bg-gray pv-10 ph-15 radius-8 mt-10">Note: If you have an existing contract, simply copy and paste the contents into the editor and update the formatting if needed.</div>
          </li>
          <li className="mb-15">
            Place your cursor at a specific location in the editor and select the <b>Insert</b> option in the toolbar to add <b>Contract Variables</b> or <b>Services</b>.
            <ul className="mt-10">
              <li>
                <b>Contract Variables.</b> This allows you to add a placeholder within the text that will automatically populate information based on the event. You can add placeholders for things like the client's name and venue information.
              </li>
              <li className="mt-10">
                <b>Services.</b> This creates a pricing table within the contract that atuomatically populates any packages and/or add-ons that have been assigned to the event. It will include line items for each service, the subtotal, any discounts applied, and the overall total.
              </li>
            </ul>
          </li>
          <Image src={contractTemplateExampleImage} style={{ width: '100%', maxWidth: 600, border: '1px solid #CCC' }}/>
        </ol>
        <div className="ml-20 mt-20">
          <button className="small-primary-button mb-5" onClick={() => navigate("/admin/contract-templates")}>Go to Contract Templates</button>
        </div>
        <div className="b-border mv-20"></div>
        <div className="fw-700 fs-18">Creating and Sending a Contract</div>

        <div className="fw-500 mb-15 line-1-4 mt-15 bg-gray p-15 radius-8">
          After a contract template is created, you can simply select that template when creating a contract for an event. You will have the option to make adjustments if needed and preview the contract before it's sent.
        </div>
        <ol>
          <li className="mb-15">
            Go to the <DocLink to="/admin/events">Events</DocLink> page and select an event (or create a new one). Add any packages and/or add-ons on the <b>Services</b> tab.
          </li>
          <li className="mb-15">
           Select the <b>Documents</b> tab and click the <b>"+"</b> icon in the <b>Contracts</b> section.
          </li>
          <li className="mb-15">
            In the <b>New Contract</b> modal, enter the following and select <b>Continue</b>:
            <ul>
              <li className="mt-15">A <b>Contract Name</b></li>
              <li className="mt-15">
                An optional <b>Contract Template</b>
                <div className="bg-gray pv-10 ph-15 radius-8 mt-10">Note: contract templates are managed on the <DocLink to="/admin/contract-templates">Contract Templates</DocLink> page.</div>
              </li>
              <li className="mt-15">
                A <b>Primary Signer</b>
                <div className="bg-gray pv-10 ph-15 radius-8 mt-10">Note: you are automatically added as a signer to the document when it's created. Signers can be added or updated on the next screen.</div>
              </li>
            </ul>
          </li>
          <li className="mb-15">
            The contract template you selected will automatically populate in the editor to give you a starting point. You can make adjustments to the content, switch to a different <b>contract template</b>, modify <b>signers</b>, or set a contract <b>expiration date</b> on this page as well.
          </li>
          <li className="mb-15">
            Once you are happy with your changes, select the <b>Next: Preview & Sign</b> button.
          </li>
          <li className="mb-15">
            Review the document to make sure all contract variables are populated correctly. If you would like to sign the document before it's sent to your client, you can do that here. Once you are done, select the <b>Next: Send Contract</b> button.
          </li>
          <li className="mb-15">
            Update the <b>Subject</b> and <b>Message</b> of the email if needed, select "Send me a copy" (optional), and click <b>Send Contract</b>. An email with a link to the contract will be sent to your client for them to review and sign.
            <div className="bg-gray pv-10 ph-15 radius-8 mt-10">Note: If you prefer to copy a link to the contract to send in your own email or text message, you can select <b>Get contract links</b>. It's important that you send each client their specific link, otherwise someone else could sign on their behalf.</div>
          </li>
        </ol>
        <div className="ml-20 mt-20">
          <button className="small-primary-button mb-5" onClick={() => navigate("/admin/events")}>Go to Events</button>
        </div>
        <div className="b-border mv-20"></div>
        <div className="fw-700 fs-18 mb-15">Contract Statuses</div>
        Each contract will be assigned a status depending on what stage of the process it is in.
        <ul>
          <li className="mb-15 mt-15">
            <b>Draft</b> 
            <br/>
            The contract is in the editing stage, has not been signed by anyone, and has not been sent to the client yet.
          </li>
          <li className="mb-15 mt-15">
            <b>Awaiting Send</b> 
            <br/>
            You have signed the contract, but it has not been sent to the client yet.
          </li>
          <li className="mb-15 mt-15">
            <b>Awaiting Signature</b> 
            <br/>
            The contract has been sent to the client, but they have not added their signature yet.
          </li>
          <li className="mb-15 mt-15">
            <b>Countersign</b> 
            <br/>
            The contract has been signed by the client and is waiting for your signature.
          </li>
          <li className="mb-15 mt-15">
            <b>Completed</b> 
            <br/>
            All parties have signed the document, therefore it is complete.
          </li>
        </ul>
        <div className="b-border mv-50"></div>
      </FloatingContainer>
    )
  }

  return (
    <AdminContent body={renderContent()}/>
  );
}

export default DocsContractsPage;

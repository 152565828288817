import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import NumberFormat from 'react-number-format';
import moment from 'moment-timezone';
import useApi from '../../../hooks/useApi';
import useCurrency from '../../../hooks/useCurrency';
import useAccountSettings from '../../../hooks/useAccountSettings';
import { Form, Row, Col, Modal, Tooltip } from 'antd';
import { 
  getAccountDetails, 
  updateAccountTaxRate, 
  getCurrencies, 
  updateAccountCurrency, 
  updateAccountDateAndTimeFormat, 
  getAccountSettings, 
  updateAccountHoldingFeeTerm,
  updateAccountCountry
} from '../../../api';
import LoadingSpinner from '../../../components/loading';
import AdminContent from '../../../components/adminContent';
import FloatingContainer from '../../../components/floatingContainer'
import { getTimezoneOptions } from '../../../helpers/timezoneHelper';
import { MdArrowForwardIos, MdOutlineClose, MdInfoOutline } from "react-icons/md";
import {
  renderFormLabel,
  renderInputField,
  renderSearchSelectField
} from '../../../components/formFields'
import useDocumentTitle from '../../../hooks/useDocumentTitle';
import { isString, isUndefined } from "lodash";
import { getCountryOptions } from '../../../helpers/addressHelper';

const GeneralSettingsPage = () => {

  const [isLoading, setLoading] = useState(true);
  const [accountDetails, setAccountDetails] = useState({});
  const [accountSettings, setAccountSettings] = useState({});
  const [currencies, setCurrencies] = useState([]);
  const [countries, setCountries] = useState([]);
  const [isUpdateFinancialDetailsModalVisible, setUpdateFinancialDetailsModalVisible] = useState(false);
  const [isUpdateDisplayOptionsModalVisible, setUpdateDisplayOptionsModalVisible] = useState(false);
  const [isUpdateLocationModalVisible, setUpdateLocationModalVisible] = useState(false);

  useDocumentTitle("General Settings")
  const navigate = useNavigate();
  const [updateFinancialDetailsForm] = Form.useForm();
  const [updateDisplayOptionsForm] = Form.useForm();
  const [updateLocationForm] = Form.useForm();

  const [sendRequest] = useApi()
  const [_, setCurrency] = useCurrency()
  const [_d, setPersistedAccountSettings] = useAccountSettings()

  const dateFormats = [
    { text: "MM / DD / YYYY", value: "MDY"},
    { text: "DD / MM / YYYY", value: "DMY"},
  ]

  const timeFormats = [
    { text: "12 Hour", value: "12-HOUR"},
    { text: "24 hour", value: "24-HOUR"},
  ]

  useEffect(() => {
    window.scrollTo(0, 0);
    refreshPage();
  }, []);

  const refreshPage = async () => {
    try {
      const accountDetailsResults = await sendRequest(getAccountDetails())
      setAccountDetails(accountDetailsResults)

      const accountSettingsResults = await sendRequest(getAccountSettings())
      setAccountSettings(accountSettingsResults)

      const currencyResults = await sendRequest(getCurrencies())
      const items = currencyResults.map((x,i) => {
        return {
          text: `${x.name} (${x.symbol})`,
          value: x.code
        }
      })
      setCurrencies(items)

      const data = getCountryOptions()
      setCountries(data);

      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  const saveFinancialDetails = async (values) => {
    try {
      var taxRate = 0.0
      if (values["tax_rate"]) {
        const taxRateString = String(values["tax_rate"]).replace("%","")
        const taxRateDecimal = Number(taxRateString) / 100
        taxRate = Number(taxRateDecimal.toFixed(5))  
      }
      await sendRequest(updateAccountTaxRate({ tax_rate: taxRate }))
      await sendRequest(updateAccountCurrency({ currency: values["currency"] }))
      await sendRequest(updateAccountHoldingFeeTerm({ holding_fee_term: values["holding_fee_term"] }))
      await refreshPage()
      setCurrency({})
      setPersistedAccountSettings({})
      setUpdateFinancialDetailsModalVisible(false)
    } catch (error) {
      setUpdateFinancialDetailsModalVisible(false)
    }
  }

  const startUpdateFinancialDetails = () => {
    const taxRate = Number(accountDetails.tax_rate) * 100
    updateFinancialDetailsForm.setFieldsValue( { tax_rate: taxRate, currency: accountDetails.currency, holding_fee_term: accountSettings.holding_fee_term })
    setUpdateFinancialDetailsModalVisible(true)
  }

  const getAccountCurrencyName = () => {
    if (currencies.length > 0) {
      const currency = currencies.find(x => x.value == accountDetails.currency)
      return !isUndefined(currency) ? currency.text : "--"
    }
    return ""
  }

  const startUpdateDisplayOptions = () => {
    const fields = {
      date_format: accountSettings.date_format,
      time_format: accountSettings.time_format,
      timezone: accountSettings.timezone ?? moment.tz.guess()
    }
    updateDisplayOptionsForm.setFieldsValue(fields)
    setUpdateDisplayOptionsModalVisible(true)
  }

  const saveDisplayOptions = async (values) => {
    try {
      const body = {
        date_format: values["date_format"],
        time_format: values["time_format"],
        timezone: values["timezone"],
      }
      await sendRequest(updateAccountDateAndTimeFormat(body))
      await refreshPage()
      setPersistedAccountSettings({})
      setUpdateDisplayOptionsModalVisible(false)
    } catch (error) {
      setUpdateDisplayOptionsModalVisible(false)
    }
  }

  const startUpdateLocation = () => {
    const fields = {
      country: accountSettings.country
    }
    updateLocationForm.setFieldsValue(fields)
    setUpdateLocationModalVisible(true)
  }

  const saveLocation = async (values) => {
    try {
      const body = {
        country: values["country"]
      }
      await sendRequest(updateAccountCountry(body))
      await refreshPage()
      setPersistedAccountSettings({})
      setUpdateLocationModalVisible(false)
    } catch (error) {
      setUpdateLocationModalVisible(false)
    }
  }

  const getAccountDateFormatName = () => {
    if (dateFormats.length > 0) {
      const format = dateFormats.find(x => x.value == accountSettings.date_format)
      return !isUndefined(format) ? format.text : "--"
    }
    return ""
  }

  const getAccountTimeFormatName = () => {
    if (timeFormats.length > 0) {
      const format = timeFormats.find(x => x.value == accountSettings.time_format)
      return !isUndefined(format) ? format.text : "--"
    }
    return ""
  }

  const getCountryName = () => {
    if (countries.length > 0) {
      const country = countries.find(x => x.value == accountSettings.country)
      return !isUndefined(country) ? country.text : "--"
    }
    return ""
  }
  
  const getTimezoneName = () => {
    const timezones = getTimezoneOptions()
    if (timezones.length > 0) {
      const timezone = timezones.find(x => x.value == accountSettings.timezone)
      return !isUndefined(timezone) ? timezone.text : "--"
    }
    return ""
  }

  const renderHeader = () => {
    return (
      <div className="p-20">
        <div>
          <span className="c-blue fw-700 cursor-default" onClick={() => navigate("/admin/setup")}>
            Setup
          </span>
          <span className="fs-10 mh-5"><MdArrowForwardIos/></span>
          <span className="cursor-default c-text-gray">
            General Settings
          </span>
        </div>
        <div className="fw-700 fs-24 mt-5">General Settings</div>
      </div>
    )
  }

  const renderUpdateFinancialDetailsModal = () => {
    return (
      <Modal visible={isUpdateFinancialDetailsModalVisible} footer={null} onCancel={() => setUpdateFinancialDetailsModalVisible(false)} width={500} closable={false} maskClosable={false} wrapClassName="rounded-modal">
        <Row align="middle">
          <Col flex={1}>
            <div className="fw-700 fs-18">Update Financial Details</div>
          </Col>
          <Col>
            <div className="display-flex" onClick={() => setUpdateFinancialDetailsModalVisible(false)}><MdOutlineClose size={30} color={"#CCC"}/></div>
          </Col>
        </Row>
        <div className="">
          <Form form={updateFinancialDetailsForm} layout="vertical" name="verify" onFinish={saveFinancialDetails}>
            <div className="mt-10 mb-1">
              { renderFormLabel("Tax Rate") }
              <Form.Item
                name={"tax_rate"}
                className="mb-10"
              >
                <NumberFormat 
                  className="ant-input ant-input-lg" 
                  displayType={'input'}
                  suffix="%"
                  decimalScale={3}
                  fixedDecimalScale={true}
                  allowLeadingZeros={false}/>
              </Form.Item>
            </div>
            <div className="mt-10 mb-1">
              {renderSearchSelectField("Currency", "currency", "Select a currency...", currencies, true)}
            </div>
            <div className="mt-10 mb-1">
              {renderInputField("Holding Fee Term", "holding_fee_term", true, false, "Booking Fee, Deposit, Retainer, etc.")}
            </div>
            <button className="primary-button" type="submit">Save</button>
            <div className="text-center mt-15">
              <div className="blue-link" onClick={() => setUpdateFinancialDetailsModalVisible(false)}>Cancel</div>
            </div>
          </Form>
        </div>
      </Modal>
    )
  }

  const renderUpdateDisplayOptionsModal = () => {
    return (
      <Modal visible={isUpdateDisplayOptionsModalVisible} footer={null} onCancel={() => setUpdateDisplayOptionsModalVisible(false)} width={500} closable={false} maskClosable={false} wrapClassName="rounded-modal">
        <Row align="middle">
          <Col flex={1}>
            <div className="fw-700 fs-18">Update Date / Time Options</div>
          </Col>
          <Col>
            <div className="display-flex" onClick={() => setUpdateDisplayOptionsModalVisible(false)}><MdOutlineClose size={30} color={"#CCC"}/></div>
          </Col>
        </Row>
        <div className="">
          <Form form={updateDisplayOptionsForm} layout="vertical" name="verify" onFinish={saveDisplayOptions}>
            <div className="mt-10 mb-1">
              {renderSearchSelectField("Date Format", "date_format", "Select a format...", dateFormats, true)}
            </div>
            <div className="mt-10 mb-1">
              {renderSearchSelectField("Time Format", "time_format", "Select a format...", timeFormats, true)}
            </div>
            <div className="mt-10 mb-1">
              {renderSearchSelectField("Timezone", "timezone", "Select Timezone", getTimezoneOptions(), true)}
            </div>
            <button className="primary-button" type="submit">Save</button>
            <div className="text-center mt-15">
              <div className="blue-link" onClick={() => setUpdateDisplayOptionsModalVisible(false)}>Cancel</div>
            </div>
          </Form>
        </div>
      </Modal>
    )
  }

  const renderUpdateLocationModal = () => {
    return (
      <Modal visible={isUpdateLocationModalVisible} footer={null} onCancel={() => setUpdateLocationModalVisible(false)} width={500} closable={false} maskClosable={false} wrapClassName="rounded-modal">
        <Row align="middle">
          <Col flex={1}>
            <div className="fw-700 fs-18">Update Location</div>
          </Col>
          <Col>
            <div className="display-flex" onClick={() => setUpdateLocationModalVisible(false)}><MdOutlineClose size={30} color={"#CCC"}/></div>
          </Col>
        </Row>
        <div className="">
          <Form form={updateLocationForm} layout="vertical" name="verify" onFinish={saveLocation}>
            <div className="mt-10 mb-10">
              { renderSearchSelectField("Country/Region", "country", "Select a country/region...", countries, false) }
            </div>
            <button className="primary-button" type="submit">Save</button>
            <div className="text-center mt-15">
              <div className="blue-link" onClick={() => setUpdateLocationModalVisible(false)}>Cancel</div>
            </div>
          </Form>
        </div>
      </Modal>
    )
  }

  const renderLabelWithTooltip = (label, tooltip) => {
    return (
      <div className="display-flex flex-middle">
        <div className="fs-14 fw-700 display-flex">{label}</div>
        <Tooltip title={tooltip}>
          <MdInfoOutline style={{ fontSize: 14, marginLeft: 6, color: '#777'}}/>
        </Tooltip>
      </div>
    )
  }

  const renderContent = () => {
    const taxRate = Number(accountDetails.tax_rate) * 100
    return (
      <>
        <FloatingContainer className="ph-20 mb-80" verticalPadding={20} maxWidth={800}>
          <div className="shadow-card p-20">
            <Row className="b-border pb-15 mb-15">
              <Col flex={1}>
                <div className="fs-20 fw-700 line-1">Location</div>
              </Col>
              <Col>
                <div className="blue-link" onClick={() => startUpdateLocation()}>Edit</div>
              </Col>
            </Row>
            { renderLabelWithTooltip("Country / Region", "The country or region your company is located.")}
            <div className="fs-14 mb-15">{ getCountryName() }</div>
          </div>
          <div className="shadow-card p-20 mt-20">
            <Row className="b-border pb-15 mb-15">
              <Col flex={1}>
                <div className="fs-20 fw-700 line-1">Date / Time</div>
              </Col>
              <Col>
                <div className="blue-link" onClick={() => startUpdateDisplayOptions()}>Edit</div>
              </Col>
            </Row>
            { renderLabelWithTooltip("Date Format", "This setting will determine how dates are formatted throughout the application, including what the client sees.")}
            <div className="fs-14 mb-15">{ getAccountDateFormatName() }</div>
            <div className="b-border mb-15"></div>
            { renderLabelWithTooltip("Time Format", "This setting will determine how times are formatted throughout the application, including what the client sees.")}
            <div className="fs-14 mb-15">{ getAccountTimeFormatName() }</div>
            <div className="b-border mb-15"></div>
            { renderLabelWithTooltip("Timezone", "This setting will determine what timezone date and times will be in.")}
            <div className="fs-14 mb-15">{ getTimezoneName() }</div>
          </div>
          <div className="shadow-card p-20 mt-20">
            <Row className="b-border pb-15 mb-15">
              <Col flex={1}>
                <div className="fs-20 fw-700 line-1">Financial</div>
              </Col>
              <Col>
                <div className="blue-link" onClick={() => startUpdateFinancialDetails()}>Edit</div>
              </Col>
            </Row>
            { renderLabelWithTooltip("Tax Rate", "Setting a tax rate will calculate tax for each package and add-on that is marked Taxable.")}
            <div className="fs-14 mb-15">
              <NumberFormat
                displayType="text"
                value={taxRate}
                suffix="%"
                decimalScale={3}
                fixedDecimalScale={true}
                allowLeadingZeros={false}/>
            </div>
            <div className="b-border mb-15"></div>
            { renderLabelWithTooltip("Currency", "This setting will determine how prices are formatted throughout the application.")}
            <div className="fs-14 mb-15">{getAccountCurrencyName()}</div>
            <div className="b-border mb-15"></div>
            { renderLabelWithTooltip("Holding Fee Term", "This term will be used throughout the application for any holding fees your company collects to book your services.")}
            <div className="fs-14 mb-15">{accountSettings.holding_fee_term}</div>
          </div>
        </FloatingContainer>
        { renderUpdateFinancialDetailsModal() }
        { renderUpdateDisplayOptionsModal() }
        { renderUpdateLocationModal() }
      </>
    )
  }

  if (isLoading) {
    return <LoadingSpinner/>
  }

  return (
    <AdminContent header={renderHeader()} body={renderContent()}/>
  );
}

export default GeneralSettingsPage;
import React, { useEffect, useState } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import qs from "qs";
import { MdCheckCircleOutline } from "react-icons/md";
import LoadingSpinner from '../../components/loading';
import useDocumentTitle from '../../hooks/useDocumentTitle';

const InvoicePaymentConfirmationPage = () => {

  const [isLoading, setLoading] = useState(true);
  const [isManual, setManual] = useState(false);

  useDocumentTitle("Payment Confirmation")
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0)
    loadPage()
  }, [])

  const loadPage = async () => {
    try {
      const queryStrings = qs.parse(location.search, { ignoreQueryPrefix: true })
      if (!queryStrings.payment_intent) {
        // Error
      }
      if (queryStrings.type == "manual") {
        setManual(true)
      }
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  const renderContent = () => {
    if (isManual) {
      return (
        <div className="text-center ph-20 mt-80">
          <MdCheckCircleOutline size={60} color={"#00b894"}/>
          <div className="fs-20 fw-700 mt-10">
            Thank you for sending a payment!
          </div>
          <div className="mb-30 mt-5">
            Once we have received your payment, this invoice will be updated.
          </div>
          <button className="small-primary-button" onClick={() => navigate(`/v/invoice/${params.id}`)}>Back to Invoice</button>
        </div>
      )
    }

    return (
      <div className="text-center ph-20 mt-80">
        <MdCheckCircleOutline size={60} color={"#00b894"}/>
        <div className="fs-20 fw-700 mt-10">
          Thank you for your payment!
        </div>
        <div className="mb-30 mt-5">
          A receipt for this transaction has been sent to your email for your records.
        </div>
        <button className="small-primary-button" onClick={() => navigate(`/v/invoice/${params.id}`)}>Back to Invoice</button>
      </div>
    )
  }

  if (isLoading) {
    return (
      <div className="mt-80">
        <LoadingSpinner/>
      </div>
    )
  }

  return (
    <div className="event-contract--container" style={{ minHeight: '100vh', backgroundColor: "#FFF" }}>
      { renderContent() }
    </div>
  );
}

export default InvoicePaymentConfirmationPage;

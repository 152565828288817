import React, { useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import AdminContent from '../../../components/adminContent';
import FloatingContainer from '../../../components/floatingContainer'
import { BackToHomeLink, DocLink } from '../../../components/docComponents'
import { Image } from 'antd';
import clientInvitationImage from '../../../images/client-invitation.png';
import clientSetupPasswordImage from '../../../images/client-setup-password.png';
import clientWelcomeScreen from '../../../images/client-welcome-screen.png';
import clientHomePageImage from '../../../images/client-home-page.png';
import useDocumentTitle from '../../../hooks/useDocumentTitle';

const DocsWhatClientsSeePage = () => {

  useDocumentTitle("What will my client see?")
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const renderContent = () => {
    return (
      <FloatingContainer className="ph-20 bg-white" verticalPadding={20} maxWidth={1000}>
        <BackToHomeLink/>
        <div className="fw-700 fs-24 mt-20">What will my client see?</div>
        <div className="fw-500 fs-16 c-text-gray">Put yourself in your client's shoes.</div>
        <div className="b-border mv-20"></div>
        <div className="fw-700 fs-18">Accepting the invitation</div>
        <ol>
          <li className="mb-15 mt-15">
            After you setup an event, your client will receive an <b>invitation</b> to DJ Planning Center via email.
            <div className="mt-15">
              <Image src={clientInvitationImage} style={{ width: '100%', maxWidth: 400 }}/>
            </div>
          </li>
          <li className="mb-15">
            After clicking the <b>Accept Invitation</b> button, they will be taken to the website where they can setup a password. 
            <div className="mt-15">
              <Image src={clientSetupPasswordImage} style={{ width: '100%', maxWidth: 400 }}/>
            </div>
          </li>
          <li className="mb-15">
            Once their password is set, they will be greeted with an opportunity to download the <b>mobile app</b>.
            <div className="mt-15">
              <Image src={clientWelcomeScreen} style={{ width: '100%', maxWidth: 400 }}/>
            </div>
          </li>
          <li className="mb-15">
            They will then be taken to their home page where they can see a countdown to their event, upload an event photo, and start planning.
            <div className="mt-15">
              <Image src={clientHomePageImage} style={{ width: '100%', maxWidth: 400 }}/>
            </div>
          </li>
          <li className="mb-15">
           Once they download the mobile app, they will see the experience showcased on our <DocLink to="/features">Features</DocLink> page.
          </li>
        </ol>
      </FloatingContainer>
    )
  }
  return (
    <AdminContent body={renderContent()}/>
  );
}

export default DocsWhatClientsSeePage;

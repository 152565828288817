import { useState, useEffect } from "react";
import { isEmpty } from "lodash";
import jwt_decode from "jwt-decode";
import StorageKeys from '../constants/storageKeys';

export default function useAuth() {

  const getDecodedAuthValue = (auth) => {
    if (!isEmpty(auth)) {
      const jwt = jwt_decode(auth.access_token);
      const isAuthenticated = true;
      const decodedAuth = {
        ...auth,
        isAuthenticated,
        user: jwt
      }
      return decodedAuth;
    }
    return auth;
  }

  const [state, setActualState] = useState(
    () => {
      const auth = JSON.parse(localStorage.getItem(StorageKeys.AUTH)) || {}
      const decodedAuth = getDecodedAuthValue(auth);
      return decodedAuth;
    }
  );

  const setState = (auth) => {
    if (isEmpty(auth)) {
      localStorage.removeItem(StorageKeys.AUTH);
      setActualState({})
    } else {
      const decodedAuth = getDecodedAuthValue(auth)
      localStorage.setItem(StorageKeys.AUTH, JSON.stringify(auth));
      setActualState(decodedAuth)
    }
  }

  return [state, setState];
}
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Row, Col, Menu, Dropdown, Switch, Tooltip } from 'antd';
import { isArray, isEmpty } from 'lodash';
import FloatingContainer from '../../../../components/floatingContainer'
import { getFormattedTime } from '../../../../helpers/timelineHelper';
import useAccountSettings from '../../../../hooks/useAccountSettings';
import { 
  timelineHasSongs, 
  getSpecialEventSongSummary
} from '../../../../helpers/specialEventHelper';
import ShareModal from '../../../../modals/shareModal';
import { MdOutlineIosShare, MdOutlineEdit, MdOutlineKeyboardArrowDown, MdOutlineKeyboardArrowRight } from "react-icons/md";
import { FiDownload, FiEye } from "react-icons/fi";
import emptyStateImage from '../../../../images/empty-document-icon.png';

const BASE_URL = process.env.REACT_APP_BASE_URL;

const EventPlanningTabTimelines = (props) => {

  const { event, specialEventSections = [], eventTimeline = {}, onSelectPlanningTab = () => {}} = props;

  const [isShareModalVisible, setShareModalVisible] = useState(false);
  const [showMusic, setShowMusic] = useState(true);

  const navigate = useNavigate();
  const [accountSettings] = useAccountSettings()

  useEffect(() => {
  }, [])

  const downloadPDF = async () => {
    window.open(`${BASE_URL}/pdfs/event/${event.event_id}/timeline`, "_blank")
  }

  const actionMenu = () => {
    return (
      <Menu>
        <Menu.Item>
          <div onClick={() => navigate(`/admin/events/${event.event_id}/timeline`)}>
            <MdOutlineEdit style={{ marginRight: 8}}/> Edit
          </div>
        </Menu.Item>
        <Menu.Item>
          <div onClick={() => navigate(`/event/${event.event_id}/timeline`)}>
            <FiEye style={{ marginRight: 8}}/> Preview / Print
          </div>
        </Menu.Item>
        <Menu.Item>
          <div onClick={() => setShareModalVisible(true)}>
            <MdOutlineIosShare style={{ marginRight: 8}}/> Share
          </div>
        </Menu.Item>
        <Menu.Item>
          <div onClick={() => downloadPDF()}>
            <FiDownload style={{ marginRight: 8}}/> View / Download PDF
          </div>
        </Menu.Item>
      </Menu>
    )
  };

  const renderSection = (section, index) => {
    const timelineEvents = section.items ?? []

    return (
      <div className="shadow-card mb-20 pb-10" key={index}>
        <Row align="middle" className="mh-20 pv-15 b-border">
          <Col flex={1}>
            <div className="fs-18 fw-700">{section.name}</div>
          </Col>
        </Row>
        <div className="ph-20">
          { timelineEvents.map((x,i) => renderTimelineItem(x,i,timelineEvents))}
          { timelineEvents.length == 0 && (
            <div className="p-30 text-center c-text-gray">No items have been added to this section yet.</div>
          )}
        </div>
      </div>
    )
  }

  const renderEventSong = (song, index) => {
    return (
      <div key={index}>
        <div>
          <span className="fs-12 fw-600 line-1-2">"{song.title}"</span>
          <span className="fs-12 fw-400 line-1-2"> - {song.artist}</span>
        </div>
        { !isEmpty(song.notes) && (
          <div className="fs-12 ml-20 c-text-gray">Note: {song.notes}</div>
        )}
      </div>
    )
  }

  const renderSongsOrPlaylist = (item) => {
    const songSummary = getSpecialEventSongSummary(item.event_special_event_id, specialEventSections)

    if (songSummary.found && songSummary.songs.length > 0) {
      if (songSummary.is_playlist) {
          return (
            <div className="bg-gray pv-5 ph-15 radius-8 mt-5">
              <div className="b-border pb-2 mb-5">
                <span className="fs-12 fw-600 line-1-2">Playlist</span>
                <span className="fs-12 fw-300 line-1-2"> ({songSummary.songs.length} {songSummary.songs.length == 1 ? "song" : "songs"})</span>
              </div>
              <div className="">
                { songSummary.songs.map((x,i) => renderEventSong(x,i)) }
              </div>
            </div>
          )
        } else {
          return (
            <div className="bg-gray pv-5 ph-15 radius-8 mt-5">
              { songSummary.songs.map((x,i) => renderEventSong(x,i)) }
            </div>
          )
        }
    }
  }

  const renderTimelineItem = (event, index, timelineEvents) => {
    const borderClass = (index + 1) < timelineEvents.length ? "b-border" : ""
    return (
      <div className={`${borderClass} pv-10`} key={index}>
        <div className={`display-flex`}>
          <div className="timeline-preview-row--time">
            <div className="fw-700">{ getFormattedTime(event.time, accountSettings) }</div>
          </div>
          <div className="timeline-preview-row--event">
            <div className="fw-600">{ event.special_event_name }</div>
            <div className="fw-500 c-text-gray line-1-2 mt-5 line-breaks">{ event.notes }</div>
          </div>
        </div>
        { showMusic && renderSongsOrPlaylist(event) }
      </div>
    )
  }

  const renderPlanningTimelineTab = () => {
    if (!isArray(eventTimeline) || eventTimeline.length == 0) {
      return (
        <div className="shadow-card ph-20 pv-50 text-center">
          <img src={emptyStateImage} width="200"/>
          <div className="fs-14 c-text-gray mt-30">No items have been added to this timeline yet.</div>
          <button className="page-title-button mt-20" onClick={() => navigate(`/admin/events/${event.event_id}/timeline`)}>Edit Timeline</button>
        </div>
      )
    }
    return (
      <div className="">
        { isArray(eventTimeline) && eventTimeline.map((x,i) => renderSection(x,i))}
      </div>
    )
  }

  const renderShareModal = () => {
    return (
      <ShareModal
        isVisible={isShareModalVisible}
        setVisible={setShareModalVisible}
        title="Share Timeline"
        link={`/event/${event.event_id}/timeline`}
      />
    )
  }

  return (
    <>
      <div className="toolbar-container floating">
        <div className="flex-row flex-middle">
          <div className="blue-link" onClick={() => onSelectPlanningTab(null)}>Planning</div>
          <div className="mh-5 display-flex"><MdOutlineKeyboardArrowRight/></div>
          <div className="fw-600">Timeline</div>
        </div>
        <div className="flex-1"></div>
        <Dropdown overlay={actionMenu()} placement="bottomRight" trigger="click">
          <div className="toolbar-button">
            Actions <MdOutlineKeyboardArrowDown style={{ fontSize: 20, color: '#536DFE', marginLeft: 8}}/>
          </div>
        </Dropdown>
      </div>
      <FloatingContainer className="ph-20 mb-20" verticalPadding={20} maxWidth={600}>
        { timelineHasSongs(eventTimeline, specialEventSections) && (
          <div className="text-right mb-10">
            <Switch checked={showMusic} onChange={(value) => setShowMusic(value)} />
            <Tooltip title="Music is automatically populated from the music section.">
              <span className="ml-10 mr-5 fw-600 cursor-default">Show music</span>
            </Tooltip>
          </div>
        )}
        { renderPlanningTimelineTab() }
      </FloatingContainer>
      { renderShareModal() }
    </>
  )
}

export default EventPlanningTabTimelines;

import React, { useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { Row, Col, Image } from 'antd';
import { BackToHomeLink, DocLink } from '../../../components/docComponents'
import AdminContent from '../../../components/adminContent';
import FloatingContainer from '../../../components/floatingContainer'
import specialEventsTemplateImage from '../../../images/special-events-template-example.png';
import clientSpecialEventTemplateImage from '../../../images/client-special-event-template.png';
import clientSpecialEventsImage from '../../../images/client-special-events.png';
import useDocumentTitle from '../../../hooks/useDocumentTitle';

const DocsEventTemplatesPage = () => {

  useDocumentTitle("Special Event Templates | Documentation")
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const renderContent = () => {
    return (
      <FloatingContainer className="ph-20 bg-white" verticalPadding={20} maxWidth={1000}>
        <BackToHomeLink/>
        <div className="fw-700 fs-24 mt-20">Special Event Templates</div>
        <div className="fw-500 fs-16 c-text-gray">Create a custom list of special events for each event type.</div>
        <div className="b-border mv-20"></div>
        <div className="fw-700 fs-18">Overview</div>
        <div className="fw-500 mb-15 line-1-4 mt-15 bg-gray p-15 radius-8">
          A special event template is a way for you to create a list of all possible special events a client can choose from. We recommend creating one for each type of event you might perform. This is the best way to gather music from your clients. They can either create a playlist if the special event is something like "Cocktail Hour" or select a specific song for things like "First Dance". It all depends on how you setup your template. After creating a template, you can simply apply it the next time you create an event.
        </div>
        <div className="b-border mv-20"></div>
        <div className="fw-700 fs-18 mb-15">How it works</div>
        <div className="mb-15">
          <ol>
            <li className="mb-15">
              <b>Create a template.</b> Go to the <DocLink to="/admin/special-event-templates">Special Event Templates</DocLink> page and select <b>"New Template"</b>. Enter a name and select an optional pre-made template provided by DJ Planning Center if you would like a starting point.
              <br/><br/>
              Create as many sections and special events as you would like. Each special event can be assigned a type of <b>Special Song</b>, <b>Playlist</b>, or <b>No Songs</b>. This determines the way clients will select their music. (See the <b>"Special Event Types"</b> sections below for more details.)
              <br/><br/>
            <div className="bg-gray pv-10 ph-15 radius-8">Note: Think of "sections" as things like "Ceremony" and "Reception" if the template is for a wedding. This is a way to logically group special events together.</div>
              <div className="mt-15 fw-600">Example:</div>
              <div className="mt-15">
                <Image src={specialEventsTemplateImage} style={{ width: '100%', maxWidth: 400 }}/>
              </div>
            </li>
            <li className="mb-15">
              <b>Assign the template.</b> Select the template when creating an event. You can also choose from some pre-made templates that DJ Planning Center has created.
            </li>
            <li className="mb-15">
              <b>Let the client choose.</b> Before selecting their music, the client will be presented with the full list of special events they have to choose from. From this list, they will will select which ones they would like to include in their event. This will keep their music page and timeline organized with only the items that apply to them. They can always add or remove special events later if they decide to change their initial selection.
              <div className="mt-15">
                <Row gutter={[20,20]}>
                  <Col>
                    <Image src={clientSpecialEventTemplateImage} style={{ width: '100%', maxWidth: 200 }}/>
                  </Col>
                  <Col>
                    <Image src={clientSpecialEventsImage} style={{ width: '100%', maxWidth: 200 }}/>
                  </Col>
                </Row>
              </div> 
            </li>
            <li className="mb-15">
              <b>Monitor progress.</b> As the client starts selecting music, you will be able to see their progress in the app or on the website by going to the the event details page.
            </li>
          </ol>
        </div>

        <div className="b-border mv-20"></div>
        <div className="fw-700 fs-18 mb-15">Special Event Types</div>
        You can assign one of three types to a special event. The way clients select music for each special event will vary depending on what type is assigned.
        <ul>
          <li className="mb-15 mt-15">
            <b>Playlist</b> 
            <br/>
            This type allows clients to select a list of songs. This is great for things like "Cocktail Hour" or "Dinner" if the event is a wedding.
          </li>
          <li className="mb-15 mt-15">
            <b>Special Song</b> 
            <br/>
            This type allows clients to select a specific song for a special occasion. This is great for things like "First Dance" or "Last Dance" if the event is a wedding. Although the default song limit is "1" for a special song, you can technically increase the number if you would like the client to be able to select more than one song for things like an extended "Grand Entrance". For anything over 2-3 songs, it is recommended to use the "Playlist" type since it provides a better user experience when selecting multiple songs.
          </li>
          <li className="mb-15 mt-15">
            <b>No Songs</b> 
            <br/>
            This type does not allow clients to select songs and will not display as an option on the music screen. This type will, however, show up as an option when creating a timeline.
          </li>
        </ul>
      </FloatingContainer>
    )
  }

  return (
    <AdminContent body={renderContent()}/>
  );
}

export default DocsEventTemplatesPage;

import StorageKeys from '../constants/storageKeys';
import uniqid from 'uniqid';

export const getDeviceId = () => {
  const device_id = localStorage.getItem(StorageKeys.DEVICE_ID);
  if (device_id) {
    return device_id;
  } else {
    const deviceId = uniqid()
    localStorage.setItem(StorageKeys.DEVICE_ID, deviceId);
    return deviceId;
  }
}
import React, { useEffect, useContext, useState } from "react";
import { useNavigate, useLocation } from 'react-router-dom';
import qs from "qs";
import moment from "moment";
import { Row, Col, Select } from 'antd';
import AdminContent from '../../../components/adminContent';
import FloatingContainer from '../../../components/floatingContainer'
import { DocArrowLink } from '../../../components/docComponents'
import { FiMusic, FiFileText, FiCheckCircle, FiMail, FiUser, FiGift, FiPlusSquare, FiDownload } from "react-icons/fi";
import { FaSignature } from "react-icons/fa";
import { HiOutlineTemplate, HiOutlineClipboardList } from "react-icons/hi";
import { MdArrowForwardIos } from "react-icons/md";
import { AiOutlineTag } from "react-icons/ai";
import Permissions from '../../../constants/permissions';
import { hasPermission } from '../../../helpers/permissionHelper';
import LoadingSpinner from '../../../components/loading';
import AppContext from '../../../app/context';
import useApi from '../../../hooks/useApi';
import { getProfitAndLossStatement } from '../../../api';
import useDocumentTitle from '../../../hooks/useDocumentTitle';

const ProfitAndLossReportPage = () => {

  const [isLoading, setLoading] = useState(true);
  const [filterKey, setFilterKey] = useState("all");
  const [reportData, setReportData] = useState({});

  const { auth, setAuth } = useContext(AppContext);
  const user = auth.user ? auth.user : {}

  useDocumentTitle("Profit & Loss | Report")
  const navigate = useNavigate();
  const location = useLocation();
  const [sendRequest] = useApi()

  useEffect(() => {
    window.scrollTo(0, 0);

    const queryStrings = qs.parse(location.search, { ignoreQueryPrefix: true })
    if (queryStrings.filter) {
      setFilterKey(queryStrings.filter)
      loadPage(queryStrings.filter)
    } else {
      onChangeFilter("this-year-to-date")
    }
  }, []);

  const loadPage = async (filter) => {
    try {
      setLoading(true)
      const results = await sendRequest(getProfitAndLossStatement(filter))
      setReportData(results)
    } finally {
      setLoading(false)
    }
  }

  const onChangeFilter = (value) => {
    setFilterKey(value)
    navigate(`/admin/reports/profit-and-loss?filter=${value}`, { replace: true })
    loadPage(value)
  }

  const renderQuickLink = (icon, subtitle, title, link) => {
    return (
      <Col xs={24} md={24} lg={12} xl={8}>
        <div className="shadow-card p-20" onClick={() => navigate(link)}>
          <div className="quick-link-row">
            <div className="quick-link-col-1">
              <div className="quick-link-icon">{icon}</div>
            </div>
            <div className="quick-link-col-2">
              <div className="fs-16 fw-700">{title}</div>
              <div className="fs-10 fw-600 c-text-gray">{subtitle}</div>
            </div>
          </div>
        </div>
      </Col>
    )
  }

  const renderHeader = () => {
    return (
      <Row align="middle" className="p-20">
        <Col flex={1}>
          <div>
            <span className="c-blue fw-700 cursor-default" onClick={() => navigate("/admin/reports")}>
              Reports
            </span>
            <span className="fs-10 mh-5"><MdArrowForwardIos/></span>
            <span className="cursor-default c-text-gray">
              Profit & Loss
            </span>
          </div>
          {/* <div className="fs-24 fw-700">Reports</div> */}
          {/* <div className="blue-link flex-row flex-1 flex-middle" onClick={() => navigate("/admin/docs/questionnaires")}>Learn more <BsArrowRight style={{ marginLeft: 5 }}/></div> */}
        </Col>
        <Col>
          {/* { questionnaires.length > 0 && (
            <button className="page-title-button" onClick={onNewClick}>New Questionnaire</button>
          )} */}
        </Col>
      </Row>
    )
  }

  const renderLevelHeader = (label) => {
    return (
      <div className="fs-14 fw-700 pv-5 pl-5">{label}</div>
    )
  }

  const renderLevelOne = (label, amount) => {
    return (
      <Row className="bg-gray">
        <Col xs={12}>
          <div className="fs-14 fw-700 pv-10 pl-20">{label}</div>
        </Col>
        <Col xs={12}>
          <div className="fs-14 fw-700 pv-10 pl-10 text-right pr-10">{amount}</div>
        </Col>
      </Row>
    )
  }

  const renderLevelTwo = (label, amount) => {
    return (
      <Row>
        <Col xs={12}>
          <div className="fs-14 fw-500 pv-10 pl-20">{label}</div>
        </Col>
        <Col xs={12}>
          <div className="fs-14 fw-500 pv-10 pl-10 text-right pr-10">{amount}</div>
        </Col>
      </Row>
    )
  }

  const renderBlueLine = () => {
    return (
      <div style={{ borderBottom: "2px solid #536DFE"}}></div>
    )
  }

  const renderBoldLine = () => {
    return (
      <div style={{ borderBottom: "2px solid #999"}}></div>
    )
  }

  const renderDoubleLine = () => {
    return (
      <div style={{ borderBottom: "4px double #999"}}></div>
    )
  }

  const renderDateRange = () => {
    if (reportData.start_date == "2020-01-01") {
      return moment().format("MMM D, YYYY")
    }
    const startDate = reportData.start_date ? moment(reportData.start_date, "YYYY-MM-DD").format("MMM D, YYYY") : "N/A"
    const endDate = reportData.end_date ? moment(reportData.end_date, "YYYY-MM-DD").format("MMM D, YYYY") : "N/A"
    return `${startDate} - ${endDate}`
  }

  const renderReport = () => {
    if (isLoading) {
      return <LoadingSpinner/>
    }

    return (
      <FloatingContainer className="ph-20 bg-white" verticalPadding={30} maxWidth={1000}>
        <div className="pb-50">
          <div className="fw-700 fs-24 c-blue">Profit & Loss</div>
          <div className="fs-14 fw-700">{reportData.company_name}</div>
          <div className="fs-14 c-text-gray mb-30">{renderDateRange()}</div>

          { renderLevelHeader("INCOME") }
          { renderBlueLine() }
          { renderLevelTwo("Sales", reportData.gross_profit) }
          <div className="b-border ml-15"></div>
          { renderLevelTwo("Cost of Goods Sold", "($0.00)") }
          <div className="b-border"></div>
          { renderLevelOne("Gross Profit", reportData.gross_profit) }

          <div className="mv-50"></div>

          { renderLevelHeader("EXPENSES", "") }
          { renderBlueLine() }
          { renderLevelTwo("Wages", reportData.wages_total) }
          <div className="b-border"></div>
          { renderLevelOne("Total Expenses", reportData.total_expenses) }

          <div className="mv-50"></div>

          {/* { renderLevelHeader("SUMMARY", "") } */}
          { renderBoldLine() }
          {/* <div style={{ borderBottom: "2px solid #999", marginLeft: 15}}></div> */}
          { renderLevelTwo("Net Operating Income", reportData.net_operating_income) }
          {/* <Row className="">
            <Col xs={12}>
              <div className="fs-14 fw-700 pv-10 pl-20 fw-700">Net Operating Income</div>
            </Col>
            <Col xs={12}>
              <div className="fs-14 fw-700 pv-10 pl-10 text-right pr-10">{reportData.net_operating_income}</div>
            </Col>
          </Row> */}
          <div className="b-border ml-15"></div>
          { renderLevelTwo("Taxes", reportData.tax_total) }
          {/* <Row className="">
            <Col xs={12}>
              <div className="fs-14 fw-700 pv-10 pl-20 fw-700">Taxes</div>
            </Col>
            <Col xs={12}>
              <div className="fs-14 fw-700 pv-10 pl-10 text-right pr-10">{reportData.tax_total}</div>
            </Col>
          </Row> */}
          { renderDoubleLine() }
          <Row className="bg-gray">
            <Col xs={12}>
              <div className="fs-14 fw-700 pv-10 pl-20">NET PROFIT</div>
            </Col>
            <Col xs={12}>
              <div className="fs-14 fw-700 pv-10 pl-10 text-right pr-10">{reportData.net_profit}</div>
            </Col>
          </Row>
        </div>
      </FloatingContainer>
    )
  }

  const renderContent = () => {
    return (
      <>
        <FloatingContainer className="ph-20 b-border" verticalPadding={30} maxWidth={1000}>
          <div className="fw-700 mb-5">Date Range</div>
          <Row>
            <Col xs={12}>
              <Select
                style={{ width: 200 }}
                placeholder={"Select"}
                value={filterKey}
                onChange={(value) => onChangeFilter(value)}
              >
                <Select.Option value="all">All</Select.Option>
                <Select.Option value="this-month">This Month</Select.Option>
                <Select.Option value="last-month">Last Month</Select.Option>
                <Select.Option value="this-year">This Year</Select.Option>
                <Select.Option value="this-year-to-date">This Year-to-Date</Select.Option>
                <Select.Option value="last-year">Last Year</Select.Option>
                <Select.Option value="last-year-to-date">Last Year-to-Date</Select.Option>
              </Select>
            </Col>
            <Col xs={12}>
              <div className="toolbar-button" onClick={() => {}}>
                <FiDownload style={{ fontSize: 20, color: '#536DFE', marginRight: 8}}/> Download PDF
              </div>
            </Col>
          </Row>
        </FloatingContainer>
        { renderReport() }
      </>
    )
  }

  return (
    <AdminContent body={renderContent()} header={renderHeader()}/>
  );
}

export default ProfitAndLossReportPage;

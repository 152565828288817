import { LexoRank } from "lexorank";
import { cloneDeep } from "lodash";

export const getRank = (records) => {
  const array = records ? records : []
  try {
    if (array.length == 0) {
      return LexoRank.middle().toString();
    } else {
      const lastRank = LexoRank.parse(array[array.length - 1]["rank"])
      return lastRank.genNext().toString()
    }
  } catch {
    return LexoRank.middle().toString();
  }
}

export const getNewRank = (array, oldIndex, newIndex) => {
  var rank = "";
  if (oldIndex == newIndex) {
    return array[oldIndex]["rank"]
  } else if (newIndex == 0 && array.length > 0) {
    // Insert at the beginning of the list
    const rankAfter = LexoRank.parse(array[0]["rank"])
    rank = rankAfter.genPrev().toString()
  } else if (newIndex == array.length - 1) {
    // Insert at the end of the list
    const rankBefore = LexoRank.parse(array[array.length - 1]["rank"])
    rank = rankBefore.genNext().toString()
  } else if (newIndex > oldIndex) {
    // Insert in the middle going down
    const rankBefore = LexoRank.parse(array[newIndex]["rank"])
    const rankAfter = LexoRank.parse(array[newIndex + 1]["rank"])
    rank = rankBefore.between(rankAfter).toString()
  } else {
    // Insert in the middle going up
    const rankBefore = LexoRank.parse(array[newIndex - 1]["rank"])
    const rankAfter = LexoRank.parse(array[newIndex]["rank"])
    rank = rankBefore.between(rankAfter).toString()
  }
  return rank
}

export const getNewTimelineRank = (array, oldIndex, newIndex) => {
  var rank = "";
  if (oldIndex == newIndex) {
    return array[oldIndex]["timeline_rank"]
  } else if (newIndex == 0 && array.length > 0) {
    // Insert at the beginning of the list
    const rankAfter = LexoRank.parse(array[0]["timeline_rank"])
    rank = rankAfter.genPrev().toString()
  } else if (newIndex == array.length - 1) {
    // Insert at the end of the list
    const rankBefore = LexoRank.parse(array[array.length - 1]["timeline_rank"])
    rank = rankBefore.genNext().toString()
  } else if (newIndex > oldIndex) {
    // Insert in the middle going down
    const rankBefore = LexoRank.parse(array[newIndex]["timeline_rank"])
    const rankAfter = LexoRank.parse(array[newIndex + 1]["timeline_rank"])
    rank = rankBefore.between(rankAfter).toString()
  } else {
    // Insert in the middle going up
    const rankBefore = LexoRank.parse(array[newIndex - 1]["timeline_rank"])
    const rankAfter = LexoRank.parse(array[newIndex]["timeline_rank"])
    rank = rankBefore.between(rankAfter).toString()
  }
  return rank
}

export const sortByRank = (array) => {
  const sortedArray = cloneDeep(array)
  sortedArray.sort((a, b) => {
    let fa = a.rank,
        fb = b.rank;

    if (fa < fb) {
        return -1;
    }
    if (fa > fb) {
        return 1;
    }
    return 0;
  });
  return sortedArray;
}
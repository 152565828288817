import React, { useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { BackToHomeLink, DocLink } from '../../../components/docComponents'
import AdminContent from '../../../components/adminContent';
import FloatingContainer from '../../../components/floatingContainer'
import useDocumentTitle from '../../../hooks/useDocumentTitle';

const DocsWorkflowsPage = () => {

  useDocumentTitle("Workflows | Documentation")
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const renderContent = () => {
    return (
      <FloatingContainer className="ph-20 bg-white" verticalPadding={20} maxWidth={1000}>
        <BackToHomeLink/>
        <div className="fw-700 fs-24 mt-20">Workflows</div>
        <div className="fw-500 fs-16 c-text-gray">Set reminders so you don't forget.</div>
        <div className="b-border mv-20"></div>
        <div className="fw-700 fs-18">Building a workflow</div>

        <div className="fw-500 mb-15 line-1-4 mt-15 bg-gray p-15 radius-8">
          Create a series of tasks that will help you remember important touch points leading up to an event. For example, create one that says “send an email to initiate a planning meeting 30 days before the event.” 
          <br/><br/>
          After your tasks are created, the workflow can be applied to an event, and the tasks will appear under <span className="blue-link" onClick={() => navigate("/admin/tasks")}>My Tasks</span> when they are due. 
        </div>
        <ol>
          <li className="mb-15">
            Go to the <DocLink to="/admin/workflows">Workflows</DocLink> page and create a new workflow.
          </li>
          <li className="mb-15">
            In the <b>New Workflow</b> modal, enter a name and select a template. You can choose an existing template we have created to give you a starting point or "Blank Workflow" to create one from scratch.
          </li>
          <li className="mb-15">
            Add / edit as many steps as you would like. If <b>"Send Email"</b> or <b>"Send Text"</b> is selected for the action type, you can create an optional message that will populate automatically in a text message or email if completing the task from the mobile app.
            <div className="bg-gray pv-10 ph-15 radius-8 mt-10"><b>Important Note:</b> The system will not automatically send emails or text messages on your behalf. You will need to tap on the task within the app to initiate a message through your phone.</div>
          </li>
        </ol>
        <div className="ml-20 mt-20">
          <button className="small-primary-button mb-5" onClick={() => navigate("/admin/workflows")}>Go to workflows</button>
        </div>
        <div className="b-border mv-50"></div>
      </FloatingContainer>
    )
  }

  return (
    <AdminContent body={renderContent()}/>
  );
}

export default DocsWorkflowsPage;

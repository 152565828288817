import React from 'react';
import { Row, Col, Form, Input, Modal, Table, Dropdown, Button, Menu, Upload, Image } from 'antd';
import { isNull } from 'lodash';

const SelectionModal = props => {
  const { title = "Select Item", isVisible = false, data, onSelect, onCancel } = props;

  const columns = [
    {
      title: 'Name',
      dataIndex: 'text',
      key: 'text',
      render: (text) => text
    }
  ];

  return (
    <Modal title={title} visible={isVisible} footer={null} onCancel={onCancel} wrapClassName="rounded-modal">
      <Table 
        columns={columns} 
        dataSource={data}
        pagination={false}
        showHeader={false}
        onRow={(record, rowIndex) => {
          return {
            onClick: event => onSelect(record), // click row
            onDoubleClick: event => {}, // double click row
            onContextMenu: event => {}, // right button click row
            onMouseEnter: event => {}, // mouse enter row
            onMouseLeave: event => {}, // mouse leave row
          };
        }}
        />
        <div className="admin-modal-footer">
          <Button className="admin-small-button secondary" onClick={onCancel}>Cancel</Button>
        </div>
    </Modal>
  );
};

export default SelectionModal;

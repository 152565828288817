import React, { useEffect, useState, useContext } from "react";
import { Row, Col, Modal, Input, Form, notification, Checkbox, Upload } from 'antd';
import { useNavigate } from 'react-router-dom';
import { isEmpty, isNull, startCase } from 'lodash';
import moment from 'moment';
import { 
  getAccountDetails, 
  logout, 
  changePassword, 
  getUserNotificationPreferences, 
  updateUserNotificationPreferences,
  cancelSubscription, 
  cancelTrial,
  uploadUserPhoto, 
  refreshToken,
  updateAdminProfile
} from '../../../api';
import {
  renderTextAreaField,
  renderInputField,
  renderEmailField
} from '../../../components/formFields'
import LoadingSpinner from '../../../components/loading';
import AdminContent from '../../../components/adminContent';
import FloatingContainer from '../../../components/floatingContainer'
import ErrorCard from '../../../components/errorCard';
import useApi from '../../../hooks/useApi';
import useCurrency from '../../../hooks/useCurrency';
import useAccountSettings from '../../../hooks/useAccountSettings';
import useDocumentTitle from '../../../hooks/useDocumentTitle';
import AppContext from '../../../app/context';
import Roles from '../../../constants/roles';
import { EyeInvisibleOutlined, EyeTwoTone, LoadingOutlined } from '@ant-design/icons';
import { MdOutlineClose, MdPhotoCamera } from "react-icons/md";
import { FiTag } from "react-icons/fi";
import { getDeviceId } from '../../../helpers/deviceHelper';
import { removeBetaFeatures } from '../../../helpers/betaFeatureHelper';
import { validatePassword } from '../../../helpers/passwordHelper'
import { formatCurrencyString } from '../../../helpers/contractHelper'
import { resizeFile, isValidFileType } from '../../../helpers/imageHelper';
import { formatDateMedium } from '../../../helpers/dateHelper'
import djPlaceholderImage from '../../../images/dj-placeholder.png';

const AccountPage = () => {

  const [isLoading, setLoading] = useState(true);
  const [isError, setError] = useState(false);
  const [isSaving, setSaving] = useState(false);
  const [accountDetails, setAccountDetails] = useState({});
  const [userNotificationPreferences, setUserNotificationPreferences] = useState([]);
  const [selectedTimezone, setSelectedTimezone] = useState({})
  const [isChangePasswordModalVisible, setChangePasswordModalVisible] = useState(false);
  const [isUpdateProfileModalVisible, setUpdateProfileModalVisible] = useState(false);
  const [isUpdateEmailErrorModalVisible, setUpdateEmailErrorModalVisible] = useState(false);
  const [isUpdateEmailConfirmationModalVisible, setUpdateEmailConfirmationModalVisible] = useState(false);
  const [isConfirmCancelModalVisible, setConfirmCancelModalVisible] = useState(false);
  const [isConfirmCancelTrialModalVisible, setConfirmCancelTrialModalVisible] = useState(false);
  const [changePasswordError, setChangePasswordError] = useState("")
  const [isSubmitting, setSubmitting] = useState(false);
  const [isCancelScheduled, setCancelScheduled] = useState(false);
  const [imageUrl, setImageUrl] = useState(null);
  const [logoUrl, setLogoUrl] = useState(null);
  const [isPhotoSaving, setPhotoSaving] = useState(false);
  const [profileRequestBody, setProfileRequestBody] = useState(false);

  useDocumentTitle("Account")
  const { auth, setAuth } = useContext(AppContext);
  const navigate = useNavigate();

  const [changePasswordForm] = Form.useForm();
  const [updateProfileForm] = Form.useForm();
  const [cancelTrialForm] = Form.useForm();

  const [sendRequest] = useApi()
  const [currency] = useCurrency()
  const [accountSettings] = useAccountSettings()

  useEffect(() => {
    window.scrollTo(0, 0);
    refreshPage()
  }, []);

  const refreshPage = async () => {
    try {
      const notifResults = await sendRequest(getUserNotificationPreferences())
      const filteredNotifResults = notifResults.filter(x => x.notification_preference_type == "EMAIL")
      setUserNotificationPreferences(filteredNotifResults)

      const accountDetailsResults = await sendRequest(getAccountDetails())
      setAccountDetails(accountDetailsResults)
      setCancelScheduled(!isNull(accountDetailsResults.cancellation_datetime))
      setLogoUrl(accountDetailsResults.logo)

    } catch {
      setError(true)
    } finally {
      setLoading(false)
    }
  }

  const onLogout = async () => {
    try {
      if (auth.user) {
        await logout(auth.user.user_id, getDeviceId())
      }
      setAuth({})
      removeBetaFeatures()
    } catch (error) {
      console.log(error)
    }
  }

  const startChangePassword = () => {
    setChangePasswordError("")
    changePasswordForm.resetFields()
    setChangePasswordModalVisible(true)
  }

  const savePassword = async (values) => {
    setChangePasswordError("")
    try {
      const passwordResponse = await sendRequest(changePassword(values))
      if (passwordResponse.status == "invalid-password") {
        setChangePasswordError("The current password you entered is incorrect.")
      } else if (passwordResponse.status == "success") {
        setAuth(passwordResponse.data)
        setChangePasswordModalVisible(false)
        notification.success({
          message: 'Success!',
          description: 'Your password has been changed successfully.',
          duration: 3
        });
      } else {
        setChangePasswordModalVisible(false)
      }
    } catch (error) {
      setChangePasswordModalVisible(false)
      console.log(error)
    }
  }

  const startUpdateProfile = () => {
    updateProfileForm.setFieldsValue({ first_name: auth.user.first_name, last_name: auth.user.last_name, email: auth.user.email, alias: auth.user.alias })
    setUpdateProfileModalVisible(true)
  }

  const saveProfile = async (values) => {
    try {
      setProfileRequestBody(values)
      if (auth.user.email != values.email) {
        setUpdateEmailConfirmationModalVisible(true)
      } else {
        confirmProfileUpdate(values)
      }
    } catch (error) {
      setUpdateProfileModalVisible(false)
      console.log(error)
    }
  }

  const confirmEmailUpdate = () => {
    setUpdateEmailConfirmationModalVisible(false)
    confirmProfileUpdate(profileRequestBody)
  }

  const confirmProfileUpdate = async (body) => {
    try {
      setSaving(true)
      const profileResponse = await sendRequest(updateAdminProfile(auth.user.user_id, body))
      if (profileResponse.status == "email-in-use") {
        setUpdateEmailErrorModalVisible(true)
        setSaving(false)
        return
      } else if (profileResponse.status == "restricted") {
        notification.error({
          message: 'Error!',
          description: 'There was a problem saving your profile.',
          duration: 3
        });
        setSaving(false)
        return;
      } else if (profileResponse.status == "success") {
        // Refresh token
        const authResponse = await sendRequest(refreshToken({ user_id: auth.user.user_id, refresh_token: auth.refresh_token, source: "web", device_id: getDeviceId() }))
        setAuth(authResponse)
        notification.success({
          message: 'Success!',
          description: 'Your profile has been updated successfully.',
          duration: 3
        });
      }
      setSaving(false)
      setUpdateProfileModalVisible(false)
    } catch {
      notification.error({
        message: 'Error!',
        description: 'There was a problem saving your profile.',
        duration: 3
      });
      setSaving(false)
    }
  }

  const togglePreference = (preference) => {
    sendRequest(updateUserNotificationPreferences(preference.notification_preference_id, { value: !preference.value })).then(response => {
      refreshPage()
    })
  }

  const onCancelSubscription = async () => {
    try {
      setSubmitting(true)
      const body = {
        cancellation_reason: "",
        cancel_at_period_end: !isCancelScheduled
      }
      await sendRequest(cancelSubscription(body))
      await refreshPage()
    } finally {
      setConfirmCancelModalVisible(false)
      setSubmitting(false)
    }
  }

  const onCancelTrial = async (values) => {
    try {
      setSubmitting(true)
      const body = {
        cancellation_reason: values["cancellation_reason"]
      }
      const results = await sendRequest(cancelTrial(auth.user.account_id, body))
      if (results.success) {
        await onLogout()
      } else {
        setConfirmCancelTrialModalVisible(false)
        setSubmitting(false)
      }
    } catch {
      setConfirmCancelTrialModalVisible(false)
      setSubmitting(false)
    }
  }

  const onCancelTrialStart = () => {
    cancelTrialForm.resetFields()
    setConfirmCancelTrialModalVisible(true)
  }

  const handleImageChange = async (info) => {
    try {
      if (!isValidFileType(info.file)) {
        notification.error({
          message: 'Error',
          description: 'Only file types of JPG or PNG are supported.',
          duration: 3
        });
        return
      }
      
      setPhotoSaving(true)

      // Resize photo
      const newFile = await resizeFile(info.file)
      setImageUrl(newFile)

      // Get blob from URI
      let response = await fetch(newFile);
      let blob = await response.blob()

      // Upload photo
      const formData = new FormData();
      formData.append('photo', blob);
      formData.append('user_id', auth.user.user_id);
      await sendRequest(uploadUserPhoto(formData))
  
      // Refresh token
      const authResponse = await sendRequest(refreshToken({ user_id: auth.user.user_id, refresh_token: auth.refresh_token, source: "web", device_id: getDeviceId() }))
      setAuth(authResponse)
    } catch (error) { 
      notification.error({
        message: 'Error',
        description: 'Something went wrong! Please try again later.',
        duration: 3
      });
    }
    finally {
      setPhotoSaving(false)
    }
  };

  const handleBeforeUpload = () => {
    return false
  };

  const getPreferenceById = (id) => {
    const preference = userNotificationPreferences.find(x => x.notification_preference_id == id)
    return !isEmpty(preference) ? preference : {}
  }

  const renderHeader = () => {
    return (
      <div className="fs-24 fw-700 p-20">Account</div>
    )
  }

  const renderTestAccountSubscriptionBox = () => {
    return (
      <>
        <div className="shadow-card p-20 mb-15">
          <div className="fs-20 fw-700 line-1 mb-15">Subscription</div>
          <Row>
            <Col flex={1}>
              <div className="fs-14 fw-700">Current Plan</div>
            </Col>
          </Row>
          <div className="fs-14 fw-300 pb-10">Test Account</div>
          <div className="fs-14 fw-300">
            <div className="bg-gray p-10 radius-8">
              This test account does not have a subscription.
            </div>
          </div>
        </div>
      </>
    )
  }

  const renderFreeAccountSubscriptionBox = () => {
    return (
      <>
        <div className="shadow-card p-20 mb-15">
          <div className="fs-20 fw-700 line-1 mb-15">Subscription</div>
          <Row>
            <Col flex={1}>
              <div className="fs-14 fw-700">Current Plan</div>
            </Col>
          </Row>
          <div className="fs-14 fw-300 pb-10">Free Account</div>
          <div className="fs-14 fw-300">
            <div className="bg-gray p-10 radius-8">
              This free account does not have a subscription.
            </div>
          </div>
        </div>
      </>
    )
  }

  const renderFreeTrialSubscriptionBox = () => {
    const expDate = accountDetails.trial_expiration_datetime ? formatDateMedium(accountDetails.trial_expiration_datetime, accountSettings) : "None"
    return (
      <>
        <div className="shadow-card p-20 mb-15">
          <div className="fs-20 fw-700 line-1 mb-15">Subscription</div>
          <Row>
            <Col flex={1}>
              <div className="fs-14 fw-700">Current Plan</div>
            </Col>
          </Row>
          <div className="fs-14 fw-300 pb-10">Free Trial - {formatCurrencyString(0, currency.code)} / month</div>
          <div className="fs-14 fw-300">
            <div className="bg-gray p-10 radius-8">
              Select a paid plan to continue using your account after your trial ends on <span className="fw-600">{expDate}</span>.
            </div>
            <button className="small-primary-button mt-20" style={{ width: '100%' }} onClick={() => navigate("/admin/select-plan")}>Select Plan</button>
          </div>
          <div className="text-center mt-10">
            <div className="blue-link" onClick={() => onCancelTrialStart(true)}>Cancel My Trial</div>
          </div>
        </div>
      </>
    )
  }

  const renderPlanSubscriptionBox = () => {
    const cardType = startCase(accountDetails.card_type)
    const plan = accountDetails.plan ?? {}
    const paymentMethod = accountDetails.last_4 ? `${cardType} ending in ${accountDetails.last_4}` : "None"
    const nextBillingDate = accountDetails.next_billing_datetime ? formatDateMedium(accountDetails.next_billing_datetime, accountSettings) : "N/A"
    const cancellationDate = accountDetails.cancellation_datetime ? formatDateMedium(accountDetails.cancellation_datetime, accountSettings): null
    var promoExpireDate = null
    var isPromoActive = false;
    var discountPrice = ""
    if (accountDetails.promo_expiration_datetime) {
      promoExpireDate = formatDateMedium(accountDetails.promo_expiration_datetime, accountSettings);
      isPromoActive = moment(accountDetails.promo_expiration_datetime).isAfter(moment())
      discountPrice = accountDetails.promo.discount_price
    }
    return (
      <>
        <div className="shadow-card p-20 mb-15">
          <div className="fs-20 fw-700 line-1 mb-15">Subscription</div>
          <Row>
            <Col flex={1}>
              <div className="fs-14 fw-700">Current Plan</div>
            </Col>
            { !isCancelScheduled && (
              <Col>
                <div className="blue-link" onClick={() => navigate("/admin/account/change-plan")}>Change</div>
              </Col>
            )}
          </Row>
          { isPromoActive ? (
            <>
              <div className="fs-12 c-blue"><FiTag style={{ fontSize: 12, color: "#536DFE"}}/> Promo applied: <b>{accountDetails.promo_code}</b></div>
              <div className="fs-14">{plan.plan_name} - <span className="strikethrough">${plan.price}</span> ${discountPrice} / month</div>
              { !isCancelScheduled && (
                <div className="fs-12 c-text-gray">Regular price will resume on {promoExpireDate}</div>
              )}
            </>
          ) : (
            <div className="fs-14">{plan.plan_name} - ${plan.price} / month</div>
          )}
          { isCancelScheduled && (
            <div className="fs-12 c-red">Scheduled to cancel on {cancellationDate}</div>
          )}
          <div className="b-border mt-20 mb-20"></div>
          <Row>
            <Col flex={1}>
              <div className="fs-14 fw-700">Payment Method</div>
            </Col>
            { !isCancelScheduled && (
              <Col>
                <div className="blue-link" onClick={() => navigate("/admin/account/payment-method")}>Update</div>
              </Col>
            )}
          </Row>
          <div className="fs-14 b-border pb-20 mb-20">{paymentMethod}</div>
          { !isCancelScheduled && (
            <>
              <div className="fs-14 fw-700">Next Billing Date</div>
              <div className="fs-14 b-border pb-20 mb-20">{nextBillingDate}</div>
            </>
          )}
          <div className="blue-link" onClick={() => setConfirmCancelModalVisible(true)}>{isCancelScheduled ? "Reactivate Subscription" : "Cancel Your Subscription"}</div>
        </div>
      </>
    )
  }

  const renderSubscription = () => {
    if (accountDetails.status == "TEST") {
      return renderTestAccountSubscriptionBox()
    } else if (accountDetails.status == "FREE") {
      return renderFreeAccountSubscriptionBox()
    }  else if (accountDetails.status == "TRIALING") {
      return renderFreeTrialSubscriptionBox()
    } else {
      return renderPlanSubscriptionBox()
    }
  }

  const renderEmailPreference = (title, preferenceId, disabled = false) => {
    const preference = getPreferenceById(preferenceId)
    return (
      <div className="mt-5 ml-10">
        <Row>
          <Col xs={20} sm={16}>
            { title }
          </Col>
          <Col xs={4} sm={8}>
            <Checkbox checked={preference.value} onChange={() => togglePreference(preference)} disabled={disabled}></Checkbox>
          </Col>
        </Row>
      </div>
    )
  }

  const renderNotificationsSection = () => {
    return (
      <div className="shadow-card p-20 mb-15">
        <div className="fs-20 fw-700 line-1 mb-15">Notifications</div>
        <Row align="middle">
          <Col xs={20} sm={16}>
            <div className="fs-14 fw-700">Client Activity</div>
          </Col>
          <Col xs={4} sm={8}>
            <div className="fs-12 fw-700">Email</div>
          </Col>
        </Row>
        { renderEmailPreference("Music Selection Updates", "CLIENT_MUSIC_SELECTION_EMAIL") }
        { renderEmailPreference("Questionnaire Updates", "CLIENT_QUESTIONNAIRE_EMAIL") }
        { renderEmailPreference("Timeline Updates", "CLIENT_TIMELINE_EMAIL") }
        <div className="fs-14 fw-700 mt-15">Contracts</div>
        { renderEmailPreference("Contract Signed", "CONTRACT_SIGNED_EMAIL", true) }
        {/* <div className="fs-14 fw-700 mt-15">Tasks</div>
        { renderEmailPreference("Tasks Due", "TASK_DUE_EMAIL") } */}
        <div className="fs-14 fw-700 mt-15">Booking Requests</div>
        { renderEmailPreference("Booking Request Received", "BOOKING_REQUEST_EMAIL", true) }
        <div className="mt-20 f-italic c-text-gray">* Preferences for push notifications can be updated in the app</div>
      </div>
    )
  }

  const renderProfileHeader = () => {
    var image = djPlaceholderImage
    var borderClass = ""
    if (imageUrl) {
      image = imageUrl
      borderClass = "gray-border"
    } else if (auth.user && auth.user.photo) {
      image = auth.user.photo
      borderClass = "gray-border"
    }
    return (
      <div className="shadow-card ph-20 pv-30 mb-15">
        <div className="text-center">
          <div className={`profile-photo-container ${borderClass}`}>
            <Upload
              showUploadList={false}
              beforeUpload={handleBeforeUpload}
              onChange={handleImageChange}
              maxCount={1}
            >
              <div className="profile-photo" style={{ backgroundImage: `url(${image})`}}>
                <div className="photo-overlay">
                  <MdPhotoCamera/>
                </div>
                { isPhotoSaving && (
                  <div className="photo-spinner">
                    <LoadingOutlined/>
                  </div>
                )}
              </div>
            </Upload>
          </div>
          { !isEmpty(auth.user.alias) ? (
            <>
              <div className="fs-18 fw-700 mt-5">{ auth.user.alias }</div>
              <div className="c-text-gray">({ auth.user.first_name } {auth.user.last_name })</div>
            </>
          ) : (
            <>
              <div className="fs-18 fw-700 mt-5">{ auth.user.first_name } {auth.user.last_name }</div>
              <div className="c-text-gray">{ auth.user && auth.user.email }</div>
            </>
          )}
        </div>
      </div>
    )
  }

  const renderProfileSection = () => {
    return (
      <div className="shadow-card p-20 mb-15">
        <Row className="b-border pb-15 mb-15">
          <Col flex={1}>
            <div className="fs-20 fw-700 line-1">Profile</div>
          </Col>
          <Col>
            <div className="blue-link" onClick={() => startUpdateProfile()}>Edit</div>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <div className="fs-14 fw-700">Name</div>
            <div className="mb-15">{ auth.user && auth.user.first_name } {auth.user && auth.user.last_name }</div>
          </Col>
          <Col xs={12}>
            <div className="fs-14 fw-700">Alias</div>
            <div className="mb-15">{ (auth.user && !isEmpty(auth.user.alias)) ? auth.user.alias : "--" }</div>
          </Col>
        </Row>
        <div className="fs-14 fw-700">Email</div>
        <div className="mb-15">{ auth.user && auth.user.email }</div>
        <div className="b-border mv-15"></div>
        <Row className="">
          <Col flex={1}>
            <div className="fs-14 fw-700">Password</div>
          </Col>
          <Col>
            <div className="blue-link" onClick={() => startChangePassword()}>Change</div>
          </Col>
        </Row>
        <div className="">******</div>
        {/* <Row>
          <Col flex={1}>
            <div className="fs-14 fw-700">Timezone</div>
          </Col>
          <Col>
            <div className="admin-link">Change</div>
          </Col>
        </Row>
        <div className="fs-14 fw-300">(GMT-7:00) Arizona</div> */}
      </div>
    )
  }

  const renderChangePasswordModal = () => {
    return (
      <Modal visible={isChangePasswordModalVisible} footer={null} onCancel={() => setChangePasswordModalVisible(false)} width={400} closable={false} maskClosable={false} wrapClassName="rounded-modal">
        <Row align="middle">
          <Col flex={1}>
            <div className="fw-700 fs-18">Change Password</div>
          </Col>
          <Col>
            <div className="display-flex" onClick={() => setChangePasswordModalVisible(false)}><MdOutlineClose size={30} color={"#CCC"}/></div>
          </Col>
        </Row>
        <div className="">
          <Form form={changePasswordForm} layout="vertical" name="verify" onFinish={savePassword}>
            <div className="mb-10 mt-20">
              <Form.Item
                name={"password"}
                className="mt-20 mb-10"
                rules={[
                  { required: true, message: `Current Password is required!`, validateTrigger: 'onBlur'  }
                ]}
              >
                <Input.Password
                  size="large"
                  placeholder="Current Password"
                  iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                />
              </Form.Item>
              <Form.Item
                name={"new_password"}
                className="mb-10"
                rules={[
                  { required: true, message: `New Password is required!`, validateTrigger: 'onBlur'  },
                  { validator: validatePassword, validateTrigger: 'onBlur' }
                ]}
              >
                <Input.Password
                  size="large"
                  placeholder="New Password"
                  iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                />
              </Form.Item>
            </div>
            { !isEmpty(changePasswordError) && (
              <div className="c-red text-center">{changePasswordError}</div>
            )}
            <button className="primary-button" type="submit">Change Password</button>
            <div className="text-center mt-15">
              <div className="blue-link" onClick={() => setChangePasswordModalVisible(false)}>Cancel</div>
            </div>
          </Form>
        </div>
      </Modal>
    )
  }

  const renderProfileSaveButton = () => {
    if (isSaving) {
      return (
        <button className="primary-button"><LoadingOutlined/></button>
      )
    } else {
      return (
        <button className="primary-button"  type="submit">Save</button>
      )
    }
  }

  const renderUpdateProfileModal = () => {
    return (
      <Modal visible={isUpdateProfileModalVisible} footer={null} onCancel={() => setUpdateProfileModalVisible(false)} width={500} closable={false} maskClosable={false} wrapClassName="rounded-modal">
        <Row align="middle">
          <Col flex={1}>
            <div className="fw-700 fs-18">Update Profile</div>
          </Col>
          <Col>
            <div className="display-flex" onClick={() => setUpdateProfileModalVisible(false)}><MdOutlineClose size={30} color={"#CCC"}/></div>
          </Col>
        </Row>
        <div className="mt-10">
          <Form form={updateProfileForm} layout="vertical" name="verify" onFinish={saveProfile}>
            <Row gutter={[15,15]}>
              <Col xs={24} sm={12}>
                { renderInputField("First Name", "first_name", true) }
              </Col>
              <Col xs={24} sm={12}>
                { renderInputField("Last Name", "last_name", true) }
              </Col>
              <Col xs={24}>
                { renderInputField("Alias", "alias", false, false, "DJ Name") }
              </Col>
              <Col xs={24}>
                { renderEmailField("Email", "email", true) }
              </Col>
            </Row>
            <div className="bg-gray radius-8 p-10 fs-12 mt-15"><b>Important Note:</b> After updating your profile information, you will need to either sign out of all your devices and sign back in or wait an hour for your information to automatically refresh.</div>
            { renderProfileSaveButton() }
            <div className="text-center mt-15">
              <div className="blue-link" onClick={() => setUpdateProfileModalVisible(false)}>Cancel</div>
            </div>
          </Form>
        </div>
      </Modal>
    )
  }

  const renderConfirmButton = (text) => {
    if (isSubmitting) {
      return (
        <button className="primary-button"><LoadingOutlined/></button>
      )
    } else {
      return (
        <button className="primary-button" type="button" onClick={() => onCancelSubscription()}>{text}</button>
      )
    }
  }

  const renderConfirmCancelTrialButton = (text) => {
    if (isSubmitting) {
      return (
        <button className="primary-button"><LoadingOutlined/></button>
      )
    } else {
      return (
        <button className="primary-button" type="submit">Cancel Trial</button>
      )
    }
  }

  const renderConfirmCancelModalContents = () => {
    const nextBillingDate = accountDetails.next_billing_datetime ? formatDateMedium(accountDetails.next_billing_datetime, accountSettings) : ""
    const cancellationDate = accountDetails.cancellation_datetime ? formatDateMedium(accountDetails.cancellation_datetime, accountSettings) : null
    if (isCancelScheduled) {
      return (
        <div className="mt-10">
          <div className="fw-700 fs-20 text-center">Welcome back!</div>
          <div className="fw-500 fs-14 mt-20 mb-20 text-center">Your subscription is scheduled to be cancelled on <b>{cancellationDate}</b>. If you would like to reactivate your subscription, please select the button below.</div>
          { renderConfirmButton("Reactivate Subscription") }
          <div className="text-center mt-15">
            <div className="blue-link" onClick={() => setConfirmCancelModalVisible(false)}>Keep my cancellation</div>
          </div>
        </div>
      )
    } else {
      return (
        <div className="mt-10">
          <div className="fw-700 fs-20 text-center">We're sorry to see you go!</div>
          <div className="fw-500 fs-14 mt-20 mb-20 text-center">Your monthly subscription is paid until <b>{nextBillingDate}</b>, so you will still be able to use DJ Planning Center until then. If you would like to proceed with cancelling your subscription, please select the button below.</div>
          { renderConfirmButton("Cancel Subscription") }
          <div className="text-center mt-15">
            <div className="blue-link" onClick={() => setConfirmCancelModalVisible(false)}>Keep my subscription</div>
          </div>
        </div>
      )
    }
  }

  const renderConfirmCancelModal = () => {
    return (
      <Modal visible={isConfirmCancelModalVisible} footer={null} onCancel={() => setConfirmCancelModalVisible(false)} width={500} closable={false} maskClosable={false} wrapClassName="rounded-modal">
        { renderConfirmCancelModalContents() }
      </Modal>
    )
  }

  const renderConfirmCancelTrialModal = () => {
    return (
      <Modal visible={isConfirmCancelTrialModalVisible} footer={null} onCancel={() => setConfirmCancelTrialModalVisible(false)} width={500} closable={false} maskClosable={false} wrapClassName="rounded-modal">
        <Form form={cancelTrialForm} layout="vertical" name="verify" onFinish={onCancelTrial}>
          <div className="mt-10">
            <div className="fw-700 fs-20 text-center">We're sorry to see you go!</div>
            <div className="fw-500 fs-14 mt-20 mb-20 text-center">We are working hard to offer the best quality service, so we're really interested to know why you would like to cancel. Please let us know your reason below, along with any other feedback you have for us!</div>
            { renderTextAreaField("", "cancellation_reason", 3, true, "Reason / feedback")}
            { renderConfirmCancelTrialButton() }
            <div className="text-center mt-15">
              <div className="blue-link" onClick={() => setConfirmCancelTrialModalVisible(false)}>Keep my trial</div>
            </div>
          </div>
        </Form>
      </Modal>
    )
  }

  const renderUpdateEmailConfirmation = () => {
    return (
      <Modal visible={isUpdateEmailConfirmationModalVisible} closable={false} footer={null} width={400} wrapClassName="rounded-modal">
        <div className="text-right" onClick={() => setUpdateEmailConfirmationModalVisible(false)}><MdOutlineClose size={30} color={"#CCC"}/></div>
        <div className="mt-10">
          <div className="fw-700 fs-20 text-center">Email Update</div>
          <div className="fw-500 fs-14 mt-20 mb-20 text-center">Are you sure you would like to update your email address to <span className="fw-700">{profileRequestBody.email}</span>? You will need to re-verify your email before signing in again.</div>
          <button className="primary-button" type="button" onClick={() => confirmEmailUpdate()}>Continue</button>
          <div className="text-center mt-15">
            <div className="blue-link" onClick={() => setUpdateEmailConfirmationModalVisible(false)}>Cancel</div>
          </div>
        </div>
      </Modal>
    )
  }

  const renderUpdateEmailError = () => {
    return (
      <Modal visible={isUpdateEmailErrorModalVisible} closable={false} footer={null} width={400} wrapClassName="rounded-modal">
        <div className="text-right" onClick={() => setUpdateEmailErrorModalVisible(false)}><MdOutlineClose size={30} color={"#CCC"}/></div>
        <div className="mt-10">
          <div className="fw-700 fs-20 text-center">Email Already Exists</div>
          <div className="fw-500 fs-14 mt-20 mb-20 text-center">A user with the email address <span className="fw-700">{profileRequestBody.email}</span> already exists. Please enter a different email address and try again.</div>
          <button className="primary-button" type="button" onClick={() => setUpdateEmailErrorModalVisible()}>OK</button>
        </div>
      </Modal>
    )
  }

  const renderSignOutButton = () => {
    return (
      <div className="text-center">
        <button className="small-primary-button" style={{ width: 150 }} onClick={onLogout}>Sign Out</button>
      </div>
    )
  }

  const renderSections = () => {
    if (auth.user && auth.user.role == Roles.OWNER) {
      return (
        <>
          { renderProfileHeader() }
          { renderProfileSection() }
          { renderSubscription() }
          { renderNotificationsSection() }
          { renderSignOutButton() }
        </>
      )
    } else {
      return (
        <>
          { renderProfileHeader() }
          { renderProfileSection() }
          { renderNotificationsSection() }
          { renderSignOutButton() }
        </>
      )
    }
  }

  const renderContent = () => {
    if (isError) {
      return <ErrorCard/>
    }
    return (
      <FloatingContainer className="ph-20" verticalPadding={20} maxWidth={600}>
        { renderSections() }
        { renderChangePasswordModal() }
        { renderConfirmCancelModal() }
        { renderConfirmCancelTrialModal() }
        { renderUpdateProfileModal() }
        { renderUpdateEmailConfirmation() }
        { renderUpdateEmailError() }
      </FloatingContainer>
    )
  }

  if (isLoading) {
    return <LoadingSpinner/>
  }

  return (
    <AdminContent body={renderContent()} header={renderHeader()}/>
  );
}

export default AccountPage;

import React, { useEffect, useState } from "react";
import { Row, Col, Table, Tag, notification } from 'antd';
import { isNull, isArray } from 'lodash';
import moment from "moment";
import { useNavigate, useParams } from 'react-router';
import useApi from '../../hooks/useApi';
import { getSystemAccountInvoices, convertInvoice } from '../../api';
import LoadingSpinner from '../../components/loading';
import AdminContent from '../../components/adminContent';
import FloatingContainer from '../../components/floatingContainer'
const BASE_URL = process.env.REACT_APP_BASE_URL;

const AccountInvoicesPage = () => {

  const [isLoading, setLoading] = useState(true);
  const [events, setEvents] = useState([]);
  const [selectedInvoice, setSelectedInvoice] = useState({});

  const navigate = useNavigate();
  const params = useParams();
  const [sendRequest] = useApi()

  const accountId = params.account_id;

  const activeColumns = [
    {
      title: 'Invoice ID',
      dataIndex: 'invoice_id',
      key: 'invoice_id',
      render: (text, record) => text
    },
    {
      title: 'Invoice #',
      dataIndex: 'invoice_number',
      key: 'invoice_number',
      render: (text) => text
    },
    {
      title: 'Amount Due',
      dataIndex: 'amount_due',
      key: 'amount_due',
      render: (text) => text
    },
    {
      title: 'Total',
      dataIndex: 'total',
      key: 'total',
      render: (text, record) => record.summary.total_price
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (text, record) => <Tag color={text == "PAID" ? "green" : "red"}>{text}</Tag>,
    },
    {
      title: 'Created Date',
      dataIndex: 'create_datetime',
      key: 'create_datetime',
      render: (text) => !isNull(text) ? moment(text).format("MMM D, YYYY") : "--"
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      render: (text, record) => <button className="small-primary-button" onClick={() => onConvertInvoice(record.invoice_id)}>Convert</button>
    },
  ];

  const newInvoiceColumns = [
    {
      title: 'Invoice ID',
      dataIndex: 'invoice_id',
      key: 'invoice_id',
      render: (text, record) => text
    },
    {
      title: 'Invoice #',
      dataIndex: 'invoice_number',
      key: 'invoice_number',
      render: (text) => text
    },
    {
      title: 'Total',
      dataIndex: 'total',
      key: 'total',
      render: (text) => text
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (text, record) => <Tag color={text == "PAID" ? "green" : "red"}>{text}</Tag>,
    },
    {
      title: 'Created Date',
      dataIndex: 'create_datetime',
      key: 'create_datetime',
      render: (text) => !isNull(text) ? moment(text).format("MMM D, YYYY") : "--"
    },
    {
      title: 'Payments',
      dataIndex: 'payments',
      key: 'payments',
      render: (text, record) => renderPayments(record)
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      render: (text, record) => <button className="small-primary-button" onClick={() => onViewNewInvoice(record.invoice_id)}>View</button>
    },
  ];

  const paymentColumns = [
    {
      title: 'Payment ID',
      dataIndex: 'payment_id',
      key: 'payment_id',
      render: (text, record) => text
    },
    {
      title: 'Payment Type',
      dataIndex: 'payment_type',
      key: 'payment_type',
      render: (text) => text
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
      render: (text) => text
    },
    {
      title: 'Payment Date',
      dataIndex: 'payment_datetime',
      key: 'payment_datetime',
      render: (text, record) => moment(text).format()
    },
    {
      title: 'Created Date',
      dataIndex: 'create_datetime',
      key: 'create_datetime',
      render: (text) => !isNull(text) ? moment(text).format("MMM D, YYYY") : "--"
    }
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
    refreshPage()
  }, []);

  const refreshPage = async () => {
    try {
      const results = await sendRequest(getSystemAccountInvoices(accountId));
      setEvents(results)
    } finally {
      setLoading(false)
    }
  }

  const onConvertInvoice = async (invoice_id) => {
    try {
      await sendRequest(convertInvoice(invoice_id));
      await refreshPage()
      notification.success({
        message: 'Success!',
        description: 'Invoice has been converted',
        duration: 3
      });
    } catch {
      notification.error({
        message: 'Error!',
        description: 'Conversion failed',
        duration: 3
      });
    }
  }

  const onViewNewInvoice = async (invoice_id) => {
    window.open(`${BASE_URL}/v/invoice/${invoice_id}`, "_blank")
  }

  const renderHeader = () => {
    return (
      <Row align="middle" className="p-20">
        <Col flex={1}>
          <div className="admin-page-title">Account Invoices ({params.account_id})</div>
        </Col>
      </Row>
    )
  }

  const renderPayments = (record) => {
    if (isArray(record.payments)) {
      return record.payments.map((x,i) => (
        <div>{x.amount} ({x.status})</div>
      ))
    }
    return null
  }

  const renderContent = () => {
    return (
      <FloatingContainer className="ph-20" verticalPadding={20} maxWidth={1000}>
        <Row align="middle" gutter={[15,15]}>
          <Col xs={24}>
            <div className="shadow-card p-30 text-center">
              <div className="fs-40 fw-700">{events.length}</div>
              <div className="fs-16 fw-400">EVENTS WITH INVOICES</div>
            </div>
          </Col>
        </Row>

        <div className="t-border mv-30"></div>

        { events.map((x,i) => (
          <div key={i}>
            <div className="fs-20 fw-700 mb-20 ml-5 c-blue">{x.event_name}</div>
            <div className="fs-18 fw-700 mb-10 ml-5">Event Invoices</div>
            <div className="shadow-card mb-20">
              <Table 
                columns={activeColumns} 
                dataSource={x.event_invoices}
                pagination={false}
              />
            </div>
            <div className="fs-18 fw-700 mb-10 ml-5">Payments</div>
            <div className="shadow-card mb-20">
              <Table 
                columns={paymentColumns} 
                dataSource={x.payments}
                pagination={false}
              />
            </div>
            <div className="fs-18 fw-700 mb-10 ml-5">Invoices</div>
            <div className="shadow-card">
              <Table 
                columns={newInvoiceColumns} 
                dataSource={x.invoices}
                pagination={false}
              />
            </div>
            <div className="t-border mv-30"></div>
          </div>
        ))}
      </FloatingContainer>
    )
  }

  if (isLoading) {
    return <LoadingSpinner/>
  }

  return (
    <AdminContent header={renderHeader()} body={renderContent()}/>
  );
}

export default AccountInvoicesPage;

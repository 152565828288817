import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import FloatingContainer from '../../components/floatingContainer'
import AdminContent from '../../components/adminContent';

const SupportThankYouPage = () => {

  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const renderContent = () => {
      return (
      <div>
        <FloatingContainer className="bg-white text-center p-20" verticalPadding={60} maxWidth={600}>
          <div className="fs-30 fw-700 line-1-2 text-center">Thank you for your message!</div>
          <div className="fs-16 c-text-gray mt-20 line-1-3">We will get back to you as soon as we can.</div>
          <button className="primary-button mt-30" style={{ width: 300 }} onClick={() => navigate("/admin/dashboard")}>Got it!</button>
        </FloatingContainer>
      </div>
      )
  }

  return (
    <AdminContent body={renderContent()}/>
  );
}

export default SupportThankYouPage;

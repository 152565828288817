import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { FiDownload } from "react-icons/fi";
import FloatingContainer from '../../components/floatingContainer'
import LoadingSpinner from '../../components/loading';
import InvoicePreview from '../../components/invoicePreview';
import { get } from "lodash";
import { getInvoicePreview } from '../../api';
import useDocumentTitle from '../../hooks/useDocumentTitle';
const BASE_URL = process.env.REACT_APP_BASE_URL;

const InvoicePage = () => {

  const [isLoading, setLoading] = useState(true);
  const [invoiceStatus, setInvoiceStatus] = useState("");
  const [eventInvoice, setEventInvoice] = useState({});

  useDocumentTitle("Invoice")
  const params = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0)
    if (params.id) {
      loadPage()
    } else {
      setInvoiceStatus("INVALID")
      setLoading(false)
    }
  }, [])

  const loadPage = async () => {
    try {
      const results = await getInvoicePreview(params.id)

      // Navigate to new invoice if it exists
      const newInvoiceAvailable = get(results, "data.new_invoice_available", false)
      if (newInvoiceAvailable) {
        navigate(`/v/invoice/${params.id}`)
        return
      }

      // Check status
      const status = get(results, "data.status", null)
      if (status == "not-found" || status == "failed") {
        setInvoiceStatus("INVALID")
      } else if (results.data) {
        setEventInvoice(results.data)
      }
      
      setLoading(false)
    } catch {
      setInvoiceStatus("INVALID")
      setLoading(false)
    }
  }

  const navigateToPDF = () => {
    window.open(`${BASE_URL}/pdfs/invoice/${params.id}`, "_blank")
  }

  const renderContent = () => {
    if (invoiceStatus == "INVALID") {
      return (
        <FloatingContainer className="ph-15" verticalPadding={50} maxWidth={800}>
          <div className="bg-white border radius-8 mb-20 p-30 text-center">
            <div className="fw-700">This link is invalid.</div>
            <div className="mt-5 c-text-gray">Please check the link and try again.</div>
          </div>
        </FloatingContainer>
      )
    }

    return (
      <div>
        <div className="toolbar-container hide-print">
          <div className="toolbar-button" onClick={() => navigateToPDF()}>
            <FiDownload style={{ fontSize: 20, color: '#536DFE', marginRight: 8}}/> Download PDF
          </div>
        </div>
        <FloatingContainer verticalPadding={30} maxWidth={1000}>
          <div className="print-card">
            <InvoicePreview 
              invoice={eventInvoice}
              ghost={true}
              accountSettings={eventInvoice.account_settings}
            />
          </div>
        </FloatingContainer>
      </div>
    )
  }

  if (isLoading) {
    return (
      <div className="mt-80">
        <LoadingSpinner/>
      </div>
    )
  }

  return (
    <div className="event-contract--container" style={{ minHeight: '100vh' }}>
      { renderContent() }
    </div>
  );
}

export default InvoicePage;

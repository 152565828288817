import React, { useMemo } from 'react';
import { BrowserRouter } from 'react-router-dom';
import ReactGA from "react-ga4";
import Routes from './routes';
import history from './history';
import AppInit from './init';
import AppContext from './context';
import useAuth from '../hooks/useAuth';
import useEvent from '../hooks/useEvent';
import RouteChangeTracker from '../components/routeChangeTracker'

// Initialize Google Analytics
if (process.env.NODE_ENV !== "development") {
  ReactGA.initialize(process.env.REACT_APP_GA_MEASUREMENT_ID);
}

const App = () => {

  const [auth, setAuth] = useAuth();
  const [currentEvent, setCurrentEvent] = useEvent();

  const value = useMemo(() => ({
    auth,
    setAuth,
    currentEvent,
    setCurrentEvent
  }), [auth, currentEvent])

  return (
    <AppContext.Provider value={value}>
      <BrowserRouter history={history}>
        <Routes/>
        <AppInit/>
        <RouteChangeTracker/>
      </BrowserRouter>
    </AppContext.Provider>
  );
};

export default App;

import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { cloneDeep, isArray, uniq } from "lodash";
import useApi from '../../../hooks/useApi';
import useAccountSettings from '../../../hooks/useAccountSettings';
import { formatEventDateLong } from '../../../helpers/dateHelper';
import { 
  renderFormLabel,
  renderInputField,
  renderTextAreaField
} from '../../../components/formFields'
import { Row, Col, Modal, Form, notification, Radio, Space, Checkbox, Grid, Alert } from 'antd';
import {
  getEvent,
  createSpotifyPlaylist,
  getSpotifyAuthStatus
} from '../../../api';
import LoadingSpinner from '../../../components/loading';
import AdminContent from '../../../components/adminContent';
import FloatingContainer from '../../../components/floatingContainer'
import { LoadingOutlined } from '@ant-design/icons';
import { MdOutlineClose, MdArrowForwardIos } from "react-icons/md";
import useDocumentTitle from '../../../hooks/useDocumentTitle';
import spotifyLogo from '../../../images/spotify-logo-bw.png';

const EventSpotifyExportPage = () => {

  const [isLoading, setLoading] = useState(true);
  const [event, setEvent] = useState({});
  const [playlistType, setPlaylistType] = useState("PRIVATE");
  const [isLoggedIn, setLoggedIn] = useState(false);
  const [isSuccessModalVisible, setSuccessModalVisible] = useState(false);
  const [isErrorModalVisible, setErrorModalVisible] = useState(false);
  const [playlistLink, setPlaylistLink] = useState("");
  const [isSaving, setSaving] = useState(false);
  const [playlists, setPlaylists] = useState([]);
  const [specialEventSections, setSpecialEventSections] = useState([]);
  const [includesNonSpotifyMusic, setIncludesNonSpotifyMusic] = useState(false);

  useDocumentTitle("Export to Spotify")
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();

  const [exportSpotifyForm] = Form.useForm();
  const [sendRequest] = useApi()
  const [accountSettings] = useAccountSettings()

  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();

  const eventId = params.event_id;

  useEffect(() => {
    window.scrollTo(0, 0);
    refreshPage()
  }, []);

  const refreshPage = async () => {
    try {

      const eventResults = await sendRequest(getEvent(eventId, "detail"));
      setEvent(eventResults);

      // Check if user is logged in to Spotify
      const authStatusResults = await sendRequest(getSpotifyAuthStatus())
      if (authStatusResults.status == "logged-in") {
        setLoggedIn(true)
      } else {
        setLoggedIn(false)
        setLoading(false)
        return
      }

      const specialEventSectionsResults = eventResults.special_event_sections ?? []
      const eventPlaylists = eventResults.playlists ?? []
      var includesOtherMusic = false

      // Get playlists
      const playlistArray = eventPlaylists.map(playlist => {
        var songs = []
        if (isArray(playlist.songs)) {
          if (cloneDeep(playlist.songs).filter(x => x.source != "spotify").length > 0) {
            includesOtherMusic = true
          }
          songs = playlist.songs.map(x => {
            return {
              ...x,
              selected: false
            }
          })
        }
        return {
          ...playlist,
          songs: songs,
          selected: false
        }
      })
      setPlaylists(playlistArray)

      // Get special events
      const specialEventSectionArray = specialEventSectionsResults.map(section => {
        var specialEvents = []
        if (isArray(section.special_events)) {
          specialEvents = section.special_events.filter(x => x.active).map(se => {
            var songs = []
            if (isArray(se.songs)) {
              if (cloneDeep(se.songs).filter(x => x.source != "spotify").length > 0) {
                includesOtherMusic = true
              }
              songs = se.songs.map(s => {
                return {
                  ...s,
                  selected: false
                }
              })
            }
            return {
              special_event_name: se.special_event_name,
              special_event_id: se.event_special_event_id,
              songs: songs,
              selected: false
            }
          })
        }

        return {
          section_name: section.section_name,
          section_id: section.event_special_event_section_id,
          special_events: specialEvents,
          selected: false
        }
      })
      setSpecialEventSections(specialEventSectionArray)
      setIncludesNonSpotifyMusic(includesOtherMusic)

      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  const submitForm = () => {
    window.scrollTo(0, 0);
    exportSpotifyForm.submit()
  }

  const onConfirmExportToSpotify = async (values) => {
    try {
      setSaving(true)
      var tracks = []

      // Get all special event tracks
      for (const section of specialEventSections) {
        for (const se of section.special_events) {
          for (const song of se.songs) {
            if (song.selected) {
              tracks.push(song.song_id)
            }
          }
        }
      }

      // Get all playlist songs
      for (const playlist of playlists) {
        for (const song of playlist.songs) {
          if (song.selected) {
            tracks.push(song.song_id)
          }
        }
      }

      // Make sure more than one song is selected
      if (tracks.length == 0) {
        setErrorModalVisible(true)
        setSaving(false)
        return
      }

      // Create playlist
      const uniqueTracks = uniq(tracks)
      const body = {
        ...values,
        tracks: uniqueTracks,
        is_public: playlistType == "PUBLIC"
      }
      const response = await sendRequest(createSpotifyPlaylist(body))
      setSuccessModalVisible(true)
      setPlaylistLink(response.playlist_link)
      setSaving(false)
    } catch {
      setSaving(false)
      notification.error({
        message: 'Error',
        description: 'There was an error creating your Spotify playlist.',
        duration: 3
      });
    }
  }

  const getTotalSelectedSongs = () => {
    const totalSpecialEventSongs = specialEventSections.reduce((x,y) => {
      var count = y.special_events.reduce((x,y) => {
        return x + y.songs.filter(x => x.selected).length
      },0)
      return x + count
    },0)
    const totalPlaylistSongs = playlists.reduce((x,y) => {
      return x + y.songs.filter(x => x.selected).length
    },0)
    return totalSpecialEventSongs + totalPlaylistSongs
  }

  const toggleSelectedPlaylist = (event_playlist_id) => {
    var newPlaylists = cloneDeep(playlists)
    for (const playlist of newPlaylists) {
      // Find playlist
      if (playlist.event_playlist_id == event_playlist_id) {
        // Toggle selected
        const newValue = !playlist.selected
        playlist.selected = newValue
        // Set all songs to selected value
        for (const song of playlist.songs) {
          song.selected = song.source == "spotify" ? newValue : false
        }
      }
    }
    setPlaylists(newPlaylists)
  }

  const toggleSelectedPlaylistSong = (event_playlist_id, song_id) => {
    var newPlaylists = cloneDeep(playlists)
    for (const playlist of newPlaylists) {
      if (playlist.event_playlist_id == event_playlist_id) {
        for (const song of playlist.songs) {
          if (song.song_id == song_id) {
            song.selected = song.source == "spotify" ? !song.selected : false
          }
        }
      }
    }
    setPlaylists(newPlaylists)
  }

  const toggleSelectedSpecialEventSection = (section_id) => {
    var newSections = cloneDeep(specialEventSections)
    for (const section of newSections) {
      // Find section
      if (section.section_id == section_id) {
        // Toggle selected
        const newValue = !section.selected
        section.selected = newValue
        // Set all songs to selected value
        for (const se of section.special_events) {
          se.selected = newValue
          for (const song of se.songs) {
            song.selected = song.source == "spotify" ? newValue : false
          }
        }
      }
    }
    setSpecialEventSections(newSections)
  }

  const toggleSelectedSpecialEvent = (section_id, special_event_id) => {
    var newSections = cloneDeep(specialEventSections)
    for (const section of newSections) {
      if (section.section_id == section_id) {
        for (const se of section.special_events) {
          if (se.special_event_id == special_event_id) {
            const newValue = !se.selected
            se.selected = newValue
            for (const song of se.songs) {
              song.selected = song.source == "spotify" ? newValue : false
            }
          }
        }
      }
    }
    setSpecialEventSections(newSections)
  }

  const toggleSelectedSpecialEventSong = (section_id, special_event_id, song_id) => {
    var newSections = cloneDeep(specialEventSections)
    for (const section of newSections) {
      if (section.section_id == section_id) {
        for (const se of section.special_events) {
          if (se.special_event_id == special_event_id) {
            for (const song of se.songs) {
              if (song.song_id == song_id) {
                song.selected = song.source == "spotify" ? !song.selected : false
              }
            }
          }
        }
      }
    }
    setSpecialEventSections(newSections)
  }

  const onNavigateToPlaylist = () => {
    window.open(playlistLink, "_blank")
  }

  const renderHeader = () => {
    return (
      <div className="p-20">
        <div>
          <span className="c-blue fw-700 cursor-default" onClick={() => navigate(`/admin/events`)}>
            Events
          </span>
          <span className="fs-10 mh-5"><MdArrowForwardIos/></span>
          <span className="c-blue fw-700 cursor-default" onClick={() => navigate(`/admin/events/${eventId}?tab=planning&section=music`)}>
            Event Details
          </span>
          <span className="fs-10 mh-5"><MdArrowForwardIos/></span>
          <span className="cursor-default c-text-gray">
            Export to Spotify
          </span>
        </div>
        <Row style={{ alignItems: 'flex-end'}}>
          <Col xs={24}>
            <div className="fw-700 fs-24 mt-5">{event.event_name}</div>
            <div className="fs-16 c-text-gray fw-500">{ formatEventDateLong(event.event_date, accountSettings) }</div>
          </Col>
        </Row>
      </div>
    )
  }

  const renderSuccessModal = () => {
    return (
      <Modal visible={isSuccessModalVisible} closable={false} footer={null} width={400} wrapClassName="rounded-modal">
        <div className="text-right" onClick={() => setSuccessModalVisible(false)}><MdOutlineClose size={30} color={"#CCC"}/></div>
        <div className="mt-10">
          <div className="fw-700 fs-20 text-center">Success!</div>
          <div className="fw-500 fs-14 mt-20 mb-20 text-center">A playlist has been created successfully on your Spotify account.</div>
          <button className="primary-button" type="button" onClick={() => onNavigateToPlaylist()}>Open Playlist in Spotify</button>
          <div className="text-center mt-15">
            <div className="blue-link" onClick={() => setSuccessModalVisible(false)}>Close</div>
          </div>
        </div>
      </Modal>
    )
  }

  const renderErrorModal = () => {
    return (
      <Modal visible={isErrorModalVisible} closable={false} footer={null} width={400} wrapClassName="rounded-modal">
        <div className="text-right" onClick={() => setErrorModalVisible(false)}><MdOutlineClose size={30} color={"#CCC"}/></div>
        <div className="mt-10">
          <div className="fw-700 fs-20 text-center">Songs Required</div>
          <div className="fw-500 fs-14 mt-20 mb-20 text-center">You must selected at least one song to export.</div>
          <button className="primary-button" type="button" onClick={() => setErrorModalVisible()}>Got it!</button>
        </div>
      </Modal>
    )
  }

  const renderExportButton = (small = false) => {
    if (isSaving) {
      return (
        <button className="page-title-button" style={{ minWidth: small ? "100%" : 180 }}><LoadingOutlined/></button>
      )
    } else {
      return (
        <button className="page-title-button" style={{ minWidth: small ? "100%" : 180 }} onClick={() => submitForm()}>Export to Spotify</button>
      )
    }
  }

  const renderSpecialEventSong = (item, special_event, section, index) => {
    var suffix = ""
    if (item.source == "apple") {
      suffix = " (Apple Music)"
    } else if (item.source == "custom") {
      suffix = " (Custom)"
    }
    return (
      <div className="mb-5" key={index}>
        <Checkbox checked={item.selected} onClick={() => toggleSelectedSpecialEventSong(section.section_id, special_event.special_event_id, item.song_id)} disabled={item.source != "spotify"}>
          { screens.sm ? (
            <div className="fs-14 fw-600">{item.title} - <span className="fs-14 c-text-gray fw-500">{item.artist}{suffix}</span></div>
          ) : (
            <div className="fs-14 fw-600">{item.title}<div className="fs-14 c-text-gray fw-500">{item.artist}{suffix}</div></div>
          )}
        </Checkbox>
      </div>
    )
  }

  const renderSpecialEvent = (item, section, index) => {
    const selectedSongs = item.songs.filter(x => x.selected).length
    const totalSongs = item.songs.filter(x => x.source == "spotify").length
    return (
      <div key={index}>
        <div className="b-border pv-10 ph-10 mb-10">
          <Row>
            <Col flex={1}>
              <Checkbox checked={item.selected} onClick={() => toggleSelectedSpecialEvent(section.section_id, item.special_event_id)} disabled={item.songs.length == 0}>
                <div className="fw-700">{item.special_event_name}</div>
              </Checkbox>
            </Col>
            <Col flex={0}>
              <span className="fw-500 c-text-gray mr-10">{selectedSongs}/{totalSongs}</span>
            </Col>
          </Row>
        </div>
        { item.selected && (
          <div className="mt-10 ml-20 mb-10">
            { item.songs.map((x,i) => renderSpecialEventSong(x,item,section,i))}
          </div>
        )}
      </div>
    )
  }

  const renderPlaylistSong = (item, playlist, index) => {
    const suffix = item.source == "apple" ? " (Apple Music)" : ""
    return (
      <div className="mb-5" key={index}>
        <Checkbox checked={item.selected} onClick={() => toggleSelectedPlaylistSong(playlist.event_playlist_id, item.song_id)} disabled={item.source != "spotify"}>
          { screens.sm ? (
            <div className="fs-14 fw-600">{item.title} - <span className="fs-14 c-text-gray fw-500">{item.artist}{suffix}</span></div>
          ) : (
            <div className="fs-14 fw-600">{item.title}<div className="fs-14 c-text-gray fw-500">{item.artist}{suffix}</div></div>
          )}
        </Checkbox>
      </div>
    )
  }

  const renderPlaylistSection = (playlist, index) => {
    const selectedSongs = playlist.songs.filter(x => x.selected).length
    const totalSongs = playlist.songs.filter(x => x.source == "spotify").length
    return (
      <div key={index} className="mt-10">
        <div className="bg-gray border pv-15 ph-15 pl-10">
          <Row>
            <Col flex={1}>
              <Checkbox checked={playlist.selected} onClick={() => toggleSelectedPlaylist(playlist.event_playlist_id)} disabled={playlist.songs.length == 0}>
                <div className="fw-700">{playlist.playlist_name}</div>
              </Checkbox>
            </Col>
            <Col flex={0}>
              <span className="fw-500 c-text-gray">{selectedSongs}/{totalSongs}</span>
            </Col>
          </Row>
        </div>
        { playlist.selected && (
          <div className="mt-10 ml-20">
            { playlist.songs.map((x,i) => renderPlaylistSong(x,playlist,i))}
          </div>
        )}
      </div>
    )
  }

  const renderSpecialEventSection = (ses, index) => {
    const totalSongs = ses.special_events.reduce((x,y) => {
      return x + y.songs.filter(x => x.source == "spotify").length
    },0)
    const selectedSongs = ses.special_events.reduce((x,y) => {
      const filteredSongsCount = y.songs.filter(x => x.selected).length
      return x + filteredSongsCount
    },0)
    return (
      <div key={index} className="mt-10">
        <div className="bg-gray border pv-15 ph-15 pl-10">
          <Row>
            <Col flex={1}>
              <Checkbox checked={ses.selected} onClick={() => toggleSelectedSpecialEventSection(ses.section_id)}>
                <div className="fw-700">{ses.section_name}</div>
              </Checkbox>
            </Col>
            <Col flex={0}>
              <span className="fw-500 c-text-gray">{selectedSongs}/{totalSongs}</span>
            </Col>
          </Row>
        </div>
        { ses.selected && (
          <div className="mt-10 ml-10">
            { ses.special_events.map((x,i) => renderSpecialEvent(x,ses,i))}
          </div>
        )}
      </div>
    )
  }

  const renderContent = () => {
    if (!isLoggedIn) {
      return (
        <FloatingContainer className="ph-20 mb-80" verticalPadding={20} maxWidth={800}>
          <div className="shadow-card ph-20 pv-40 text-center">
            <img src={spotifyLogo} style={{ height: 50}}/>
            <div className="c-text-gray mt-10 mb-15">You must login to your Spotify account before continuing</div>
            <button className="small-primary-button" onClick={() => navigate("/admin/setup/music-integration")}>Go to Settings</button>
          </div>
        </FloatingContainer>
      )
    }

    return (
      <>
        <FloatingContainer className="ph-20 mb-80" verticalPadding={20} maxWidth={800}>
          <div className="shadow-card ph-20 pv-30 text-center mb-20">
            <img src={spotifyLogo} style={{ height: 40}}/>
            <div className="fs-20 fw-700 mt-10">Export Music to Spotify</div>
            <div className="fs-14 c-text-gray">Enter the details below to create a new playlist on your connected Spotify account.</div>
          </div>
          <Form form={exportSpotifyForm} layout="vertical" name="edit-event" onFinish={onConfirmExportToSpotify} scrollToFirstError={true}>
            <div className="shadow-card mb-20 ph-20 pb-20">
              <Row align="middle" className="pv-15 mb-15 b-border">
                <Col flex={1}>
                  <div className="fs-18 fw-700">Playlist Details</div>
                </Col>
              </Row>
              <Row gutter={[20,20]} className="">
                <Col xs={24} md={24}>
                  { renderInputField("Playlist Name", "name", true) }
                </Col>
                <Col xs={24} md={24}>
                  { renderTextAreaField("Description (optional)", "description", 3, false) }
                </Col>
                <Col xs={24} md={24}>
                  { renderFormLabel("Playlist Type")}
                  <Radio.Group value={playlistType} onChange={(e) => setPlaylistType(e.target.value)}>
                    <Space direction="vertical">
                      <Radio value="PRIVATE">Private</Radio>
                      <Radio value="PUBLIC">Public</Radio>
                    </Space>
                  </Radio.Group>
                </Col>
              </Row>
            </div>
            <div className="shadow-card mb-20 p-20">
              <Row align="middle" className="pb-15 b-border">
                <Col flex={1}>
                  <div className="fs-18 fw-700">Songs</div>
                </Col>
                <Col flex={0}>
                  <div className="text-right pr-10 c-text-gray">
                    {getTotalSelectedSongs()} songs slected
                  </div>
                </Col>
              </Row>
              <div className="mt-10 mb-10">Select the songs you would like to include in your export.</div>
              { includesNonSpotifyMusic && (
                <div className="mb-15">
                  <Alert message="Some of the songs below were added through Apple Music and will not be available for Spotify export." type="info" showIcon />
                </div>
              )}
              <div className="fw-700 fs-18">Special Events</div>
              { specialEventSections.map((x,i) => renderSpecialEventSection(x,i))}
              <div className="t-border mv-20"></div>
              <div className="fw-700 fs-18">Playlists</div>
              { playlists.map((x,i) => renderPlaylistSection(x,i))}
            </div>
          </Form>
          { renderSuccessModal() }
          { renderErrorModal() }
        </FloatingContainer>
        <div className="sticky-footer p-0">
          <div className="p-15 bg-white t-border">
            { screens.sm ? (
              <Row align="middle" gutter={[10,10]}>
                <Col flex={0}>
                  { renderExportButton() }
                </Col>
                <Col flex={0}>
                  <div className="c-text-gray">{getTotalSelectedSongs()} songs slected</div>
                </Col>
              </Row>
            ) : (
              <Row align="middle" gutter={[10,10]}>
                <Col xs={24}>
                  <div className="c-text-gray text-center">{getTotalSelectedSongs()} songs slected</div>
                </Col>
                <Col xs={24}>
                  { renderExportButton(true) }
                </Col>
              </Row>
            )}
          </div>
        </div>
      </>
    )
  }

  if (isLoading) {
    return <LoadingSpinner/>
  }

  return (
    <AdminContent header={renderHeader()} body={renderContent()}/>
  );
}

export default EventSpotifyExportPage;
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Row, Col, Modal } from 'antd';
import './homePage.style.scss';
import FloatingContainer from '../../../components/floatingContainer'
import appHeroImage from '../../../images/app-hero.png';
import iosDownloadButton from '../../../images/ios-download-button.png';
import androidDownloadButton from '../../../images/android-download-button.png';
import { submitInterest } from '../../../api';
import FadeDownWhileInView from '../../../components/fadeDownWhileInView'
import FeatureSections from '../../../components/featureSections'
import ClientFeatures from '../../../components/clientFeatures'
import DJFeatures from '../../../components/djFeatures'
import { pageViewHomeEvent } from '../../../helpers/facebookHelper'
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

const HomePage = () => {

  const navigate = useNavigate();

  useEffect(() => {
    document.title = "DJ Planning Center | A modern app for DJs and their clients"
    pageViewHomeEvent()
  }, [])

  const onSubmit = values => {

    const body = {
      first_name: "",
      last_name: "",
      email: values.email,
      company_name: "Wait List"
    }

    submitInterest(body).then(response => {
      navigate("/thank-you")
    }).catch((error) => console.log(error))
  }

  const showAndroidAlert = () => {
    Modal.info({
      title: 'Coming soon!',
      content: 'The Android app will be available in the Google Play Store soon!',
      okText: 'OK',
      onOk: () => {}
    });
  }

  return (
    <div className="site-layout--main app-carousels">
       <FloatingContainer className="bg-blue-gradient" verticalPadding={50} maxWidth={1000}>
        <Row gutter={[30,30]} align="middle" className="ph-20 medium-center">
          <Col xs={24} md={12}>
            <FadeDownWhileInView className="ph-20">
              <div className="fs-32 fw-700 line-1-2 c-white">Finally, a modern app for DJs and their clients.</div>
              <div className="fs-18 c-text-gray fw-400 mt-20 line-1-3 c-white">Offer a new way for your clients to plan their events. Collaborate on playlists, timelines, and much more.</div>
              <button className="primary-button mt-30" style={{ maxWidth: 300, backgroundColor: "#4b62e5" }} onClick={() => navigate("/signup")}>Start Free Trial</button>
              {/* <div className="hide-medium p-20 mt-50 radius-8" style={{ backgroundColor: "#4b62e5" }}>
                <div className="fs-14 fw-600 mb-10 line-1-4 c-white">Coming soon! Get on the wait list.</div>
                <Form  layout="vertical" name="interest-form" onFinish={onSubmit}>
                  <Row>
                    <Col flex={1}>
                      <Form.Item
                        name={"email"}
                        rules={[
                          { required: true, message: `Email is required!`, validateTrigger: 'onBlur' },
                          { type: "email", message: `Please enter a valid email address`, validateTrigger: 'onBlur' }
                        ]}
                      >
                        <Input size="large" placeholder="Email address" style={{borderRadius: 4 }}/>
                      </Form.Item>
                    </Col>
                    <Col>
                      <button className="page-title-button" type="submit" style={{ height: 50, width: "100%", backgroundColor: "#4257cb" }}>Sign Up</button>
                    </Col>
                  </Row>
                </Form>
              </div> */}
            </FadeDownWhileInView>
          </Col>
          <Col xs={24} md={12}>
            <FadeDownWhileInView delay={1.0}>
              <img src={appHeroImage} width={"100%"}/>
            </FadeDownWhileInView>
          </Col>
        </Row>
        {/* <div className="show-medium medium-center mh-30">
          <button className="primary-button mt-30" style={{ backgroundColor: "#4b62e5" }}>Start Free Trial</button>
        </div> */}
        {/* <div className="show-medium mh-30 p-15 mt-50 radius-8 medium-center" style={{ backgroundColor: "#4b62e5" }}>
          <div className="fs-14 fw-600 mb-10 line-1-4 c-white">Coming soon! Get on the wait list.</div>
          <Form  layout="vertical" name="interest-form" onFinish={onSubmit}>
            <Row>
              <Col xs={24}>
                <Form.Item
                  name={"email"}
                  rules={[
                    { required: true, message: `Email is required!`, validateTrigger: 'onBlur' },
                    { type: "email", message: `Please enter a valid email address`, validateTrigger: 'onBlur' }
                  ]}
                >
                  <Input size="large" placeholder="Email address" style={{borderRadius: 4 }}/>
                </Form.Item>
              </Col>
              <Col xs={24}>
                <button className="page-title-button mt-10" type="submit" style={{ height: 50, width: "100%", backgroundColor: "#4257cb" }}>Sign Up</button>
              </Col>
            </Row>
          </Form>
        </div> */}
      </FloatingContainer>

      <FeatureSections/>

      <FloatingContainer className="bg-gray" verticalPadding={60} maxWidth={1000}>
        <div className="video-responsive mh-20">
          <iframe
            width="853"
            height="480"
            src={`https://www.youtube.com/embed/Nf8KhL8cstM`}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="DJ Planning Center"
          />
        </div>
      </FloatingContainer>

      <ClientFeatures/>
      <DJFeatures/>

      <FloatingContainer className="bg-gray" verticalPadding={80}>
        <FadeDownWhileInView className="text-center">
          <div className="fs-30 fw-700 line-1-2">Download the app.</div>
          <div className="mt-30">
            <a href="https://play.google.com/store/apps/details?id=com.djpcapp" target="_blank">
              <img src={androidDownloadButton} height={50}/>
            </a>
            <a href="https://apps.apple.com/us/app/dj-planning-center/id1638751912" target="_blank">
              <img src={iosDownloadButton} height={50} className="ml-10"/>
            </a>
          </div>
        </FadeDownWhileInView>
      </FloatingContainer>
    </div>
  );
}

export default HomePage;

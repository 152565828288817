import StorageKeys from '../constants/storageKeys';
import { isNull } from 'lodash';
import moment from 'moment';
import CalendarRepeatOptions from '../constants/calendarRepeatOptions';

const calendarFiltersStorageKey = (user_id) => {
  return `${StorageKeys.CALENDAR_FILTERS}_${user_id}`
}

export const getCalendarFilters = (user_id) => {
  const key = calendarFiltersStorageKey(user_id)
  return JSON.parse(localStorage.getItem(key)) || {}
}

export const setCalendarFilters = (user_id, filters) => {
  const key = calendarFiltersStorageKey(user_id)
  localStorage.setItem(key, JSON.stringify(filters));
}

export const isDateWithinTimeOff = (date, timeOff) => {
  const currentDate = moment(date, "YYYY-MM-DD")

  // If start and end dates are different, we don't allow repeating
  if (timeOff.start_date != timeOff.end_date) {
    // Check if current date is between the two dates (including start and end dates)
    const startDate = moment(timeOff.start_date, "YYYY-MM-DD")
    const endDate = moment(timeOff.end_date, "YYYY-MM-DD")
    return currentDate.isBetween(startDate, endDate, undefined, '[]')
  }
  
  // If there is no recurrence type, check if the current date is the same as the start date
  if (timeOff.recurrence_type == CalendarRepeatOptions.NONE || isNull(timeOff.recurrence_type)) {
    return date == timeOff.start_date
  }

  const startDate = moment(timeOff.start_date, "YYYY-MM-DD")
  const recurrenceEndDate = !isNull(timeOff.recurrence_end_date) ? moment(timeOff.recurrence_end_date, "YYYY-MM-DD") : null;
  const isBetween = !isNull(recurrenceEndDate) ? currentDate.isBetween(startDate, recurrenceEndDate, undefined, '[]') : currentDate.isSameOrAfter(startDate)

  // Check for recurrences
  if (timeOff.recurrence_type == CalendarRepeatOptions.DAILY) {
    // Daily
    return isBetween
  } else if (timeOff.recurrence_type == CalendarRepeatOptions.WEEKLY) {
    // Weekly
    const isSameWeekDay = startDate.isoWeekday() == currentDate.isoWeekday()
    return isBetween && isSameWeekDay
  } else if (timeOff.recurrence_type == CalendarRepeatOptions.MONTHLY) {
    // Monthly
    const isSameMonthDay = startDate.date() == currentDate.date()
    return isBetween && isSameMonthDay
  } else if (timeOff.recurrence_type == CalendarRepeatOptions.YEARLY) {
    // Monthly
    const isSameMonthDay = startDate.date() == currentDate.date()
    const isSameYearDay = startDate.month() == currentDate.month()
    return isBetween && isSameMonthDay && isSameYearDay
  }

  return false
}
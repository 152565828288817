import React, {useState} from 'react';
import { Row, Col } from 'antd';
import { useNavigate } from 'react-router-dom';
import { isEmpty } from 'lodash';
import useApi from '../hooks/useApi';
import { activateAccount } from '../api';
import {useStripe, useElements, CardElement} from '@stripe/react-stripe-js';
import { LoadingOutlined } from '@ant-design/icons';
import { BsCheckCircle } from "react-icons/bs";

const SubscribeForm = (props) => {
  const { clientSecret, name, accountDetails } = props;
  const stripe = useStripe();
  const elements = useElements();

  const navigate = useNavigate();

  const [sendRequest] = useApi()

  const [isSubmitting, setSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const handleSubmit = async (event) => {

    event.preventDefault();

    setSubmitting(true)
    setErrorMessage("")

    if (!stripe || !elements) {
      setSubmitting(false)
      return;
    }

    const cardElement = elements.getElement(CardElement);

    // Use card Element to tokenize payment details
    let { error, paymentIntent } = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: cardElement,
        billing_details: {
          name: name,
        }
      }
    });

    if (paymentIntent && paymentIntent.status === 'succeeded') {
      try {
        await sendRequest(activateAccount())
        navigate("/admin/subscribe/confirmation")
      } catch {
        setSubmitting(false)
        setErrorMessage("An error has occured. Please try again.");
      }
      return
    }

    if (error) {
      setErrorMessage(error.message);
      setSubmitting(false)
    } else {
      setSubmitting(false)
    }
  };

  const getSubscriptionString = () => {
    if (accountDetails && accountDetails.plan) {
      return `${accountDetails.plan.plan_name} - $${accountDetails.plan.price} / month`
    }
    return ""
  }

  const renderSubmitButton = () => {
    if (isSubmitting) {
      return (
        <button className="primary-button" style={{ maxWidth: 300 }} type="submit"><LoadingOutlined/></button>
      )
    } else {
      return (
        <button className="primary-button" style={{ maxWidth: 300 }} type="submit">Subscribe</button>
      )
    }
  }

  const renderPromoSection = () => {
    if (accountDetails && !isEmpty(accountDetails.promo)) {
      const promo = accountDetails.promo
      return (
        <>
          <Row align="middle" gutter={[10]}>
            <Col flex={0}>
              <BsCheckCircle style={{ fontSize: 30, color: "#27ae60"}}/>
            </Col>
            <Col flex={1}>
              <div className="fs-14"><span className="fw-700">{promo.promo_code}</span> applied</div>
              <div className="fs-12 c-text-gray">{promo.description}</div>
            </Col>
          </Row>
          <div className="b-border mv-20"></div>
          <div className="fw-700 fs-16 mb-20">Due Today: ${promo.discount_price}</div>
        </>
      )
    } else if (accountDetails && !isEmpty(accountDetails.plan)) {
      const plan = accountDetails.plan
      return (
        <div className="fw-700 fs-16 mb-20">Due Today: ${plan.price}</div>
      )
    }
    return null;
  }

  return (
    <form onSubmit={handleSubmit}>
      <div className="fw-700 fs-16 mb-5">Card Details</div>
      <div className="">
        <CardElement options={ { terms: { card: "never" }} } />
      </div>
      <div className="b-border mv-20"></div>
      <Row gutter={[20,0]} align="middle">
        <Col flex={1}>
          <div className="fw-700 fs-16">Your Subscription</div>
        </Col>
        <Col>
          {/* <div className="blue-link" onClick={() => navigate("admin/select-plan")}>Change</div> */}
        </Col>
        <Col xs={24}>
          <div>{getSubscriptionString()}</div>
        </Col>
      </Row>
      <div className="b-border mv-20"></div>
      { renderPromoSection() }
      <div className="mb-10 fs-12">
        By clicking on the "Subscribe" button, you agree that your card will be charged now and your subscription will automatically renew each month. You are free to cancel at any time.
      </div>
      <div className="text-center">
        { renderSubmitButton() }
      </div>
      {errorMessage && <div className="c-red mt-10 text-center">{errorMessage}</div>}
    </form>
  )
};

export default SubscribeForm;
import React, { useEffect, useState } from "react";
import { Row, Col, Table, Modal, Select, Input, Tag } from 'antd';
import { cloneDeep, isNull, sortBy, isArray } from 'lodash';
import moment from "moment";
import { useNavigate } from 'react-router';
import useApi from '../../hooks/useApi';
import { getSystemAccounts } from '../../api';
import LoadingSpinner from '../../components/loading';
import AdminContent from '../../components/adminContent';
import FloatingContainer from '../../components/floatingContainer'
import { MdOutlineClose } from "react-icons/md";


const AccountsPage = () => {

  const [isLoading, setLoading] = useState(true);
  const [accounts, setAccounts] = useState([]);
  const [isAccountModalVisible, setAccountModalVisible] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState({});

  const navigate = useNavigate();
  const [sendRequest] = useApi()

  const renderOwnerColumn = (record) => {
    const owner = isArray(record.account_owners) && record.account_owners.length > 0 ? record.account_owners[0] : {}
    const ownerName = `${owner.first_name} ${owner.last_name}`
    return (
      <div>
        <div>{ownerName}</div>
        <div className="c-text-gray">{owner.email}</div>
      </div>
    )
  }

  const columns = [
    {
      title: 'Account ID',
      dataIndex: 'account_id',
      key: 'account_id',
      render: (text, record) => text
    },
    {
      title: 'Company Name',
      dataIndex: 'company_name',
      key: 'company_name',
      render: (text) => text
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (text, record) => text,
      filters: [
        {
          text: 'TEST',
          value: 'TEST',
        },
        {
          text: 'TRIALING',
          value: 'TRIALING',
        },
        {
          text: 'TRIAL_ENDED',
          value: 'TRIAL_ENDED',
        },
        {
          text: 'ACTIVE',
          value: 'ACTIVE',
        },
        {
          text: 'CANCELLED',
          value: 'CANCELLED',
        }
      ],
      onFilter: (value, record) => record.status.includes(value)
    }
  ];

  const activeColumns = [
    {
      title: 'Account ID',
      dataIndex: 'account_id',
      key: 'account_id',
      render: (text, record) => text
    },
    {
      title: 'Company',
      dataIndex: 'company_name',
      key: 'company_name',
      render: (text, record) => text
    },
    {
      title: 'Owner',
      dataIndex: 'owner',
      key: 'owner',
      render: (text, record) => renderOwnerColumn(record)
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (text, record) => <Tag color={text == "ACTIVE" ? "green" : "red"}>{text}</Tag>,
    },
    {
      title: 'Created Date',
      dataIndex: 'create_datetime',
      key: 'create_datetime',
      render: (text) => !isNull(text) ? moment(text).format("MMM D, YYYY") : "--"
    },
    {
      title: 'Next Billing Date',
      dataIndex: 'next_billing_datetime',
      key: 'next_billing_datetime',
      render: (text) => !isNull(text) ? moment(text).format("MMM D, YYYY") : "--"
    },
    {
      title: 'Events',
      dataIndex: 'event_count',
      key: 'event_count',
      render: (text) => text
    },
  ];
  
  const trialingColumns = [
    {
      title: 'Account ID',
      dataIndex: 'account_id',
      key: 'account_id',
      render: (text, record) => text
    },
    {
      title: 'Company',
      dataIndex: 'company_name',
      key: 'company_name',
      render: (text, record) => text
    },
    {
      title: 'Owner',
      dataIndex: 'owner',
      key: 'owner',
      render: (text, record) => renderOwnerColumn(record)
    },
    {
      title: 'Created Date',
      dataIndex: 'create_datetime',
      key: 'create_datetime',
      render: (text) => !isNull(text) ? moment(text).format("MMM D, YYYY") : "--"
    },
    {
      title: 'Trial Ends',
      dataIndex: 'trial_expiration_datetime',
      key: 'trial_expiration_datetime',
      render: (text) => !isNull(text) ? moment(text).format("MMM D, YYYY") : "--"
    },
    {
      title: 'Events',
      dataIndex: 'event_count',
      key: 'event_count',
      render: (text) => text
    },
  ];


  const trialEndedColumns = [
    {
      title: 'Account ID',
      dataIndex: 'account_id',
      key: 'account_id',
      render: (text, record) => text
    },
    {
      title: 'Company',
      dataIndex: 'company_name',
      key: 'company_name',
      render: (text, record) => text
    },
    {
      title: 'Owner',
      dataIndex: 'owner',
      key: 'owner',
      render: (text, record) => renderOwnerColumn(record)
    },
    {
      title: 'Trial Ended',
      dataIndex: 'trial_expiration_datetime',
      key: 'trial_expiration_datetime',
      render: (text) => !isNull(text) ? moment(text).format("MMM D, YYYY") : "--"
    },
    {
      title: 'Events',
      dataIndex: 'event_count',
      key: 'event_count',
      render: (text) => text
    },
  ];

  const cancelledColumns = [
    {
      title: 'Account ID',
      dataIndex: 'account_id',
      key: 'account_id',
      render: (text, record) => text
    },
    {
      title: 'Company Name',
      dataIndex: 'company_name',
      key: 'company_name',
      render: (text) => text
    },
    {
      title: 'Owner',
      dataIndex: 'owner',
      key: 'owner',
      render: (text, record) => renderOwnerColumn(record)
    },
    {
      title: 'Created Date',
      dataIndex: 'create_datetime',
      key: 'create_datetime',
      render: (text) => !isNull(text) ? moment(text).format("MMM D, YYYY") : "--"
    },
  ];

  const testColumns = [
    {
      title: 'Account ID',
      dataIndex: 'account_id',
      key: 'account_id',
      render: (text, record) => text
    },
    {
      title: 'Company Name',
      dataIndex: 'company_name',
      key: 'company_name',
      render: (text) => text
    },
    {
      title: 'Owner',
      dataIndex: 'owner',
      key: 'owner',
      render: (text, record) => renderOwnerColumn(record)
    },
    {
      title: 'Created Date',
      dataIndex: 'create_datetime',
      key: 'create_datetime',
      render: (text) => !isNull(text) ? moment(text).format("MMM D, YYYY") : "--"
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
    refreshPage()
  }, []);

  const refreshPage = async () => {
    try {
      const accountResults = await sendRequest(getSystemAccounts());
      setAccounts(accountResults)
    } finally {
      setLoading(false)
    }
  }

  const selectAccount = (account) => {
    navigate(`/system/accounts/${account.account_id}`)
  }

  const renderHeader = () => {
    return (
      <Row align="middle" className="p-20">
        <Col flex={1}>
          <div className="admin-page-title">Accounts</div>
        </Col>
      </Row>
    )
  }

  const renderDate = (date) => {
    if (date) {
      return moment(date).format("MM/DD/YYYY @ hh:mma") 
    } else {
      return "--"
    }
  }

  const renderAccountModal = () => {
    return (
      <Modal visible={isAccountModalVisible} closable={false} footer={null} width={800} wrapClassName="rounded-modal">
        <div className="text-right" onClick={() => setAccountModalVisible(false)}><MdOutlineClose size={30} color={"#CCC"}/></div>
        <div className="mt-10">
          <div className="fw-700 fs-20 ml-10">Account Info</div>
          <Row align="middle" className="p-20" gutter={[20,20]}>
            <Col xs={12}>
              <div className="fw-700">Account ID</div>
              <div className="">{ selectedAccount.account_id }</div>
            </Col>
            <Col xs={12}>
              <div className="fw-700">Company Name</div>
              <div className="">{ selectedAccount.company_name }</div>
            </Col>
            <Col xs={12}>
              <div className="fw-700">Status</div>
              <div className="">{ selectedAccount.status }</div>
            </Col>
            <Col xs={12}>
              <div className="fw-700">Active</div>
              <div className="">{ selectedAccount.active ? "Yes" : "No" }</div>
            </Col>
            <Col xs={12}>
              <div className="fw-700">Created Date</div>
              <div className="">{ renderDate(selectedAccount.create_datetime) }</div>
            </Col>
            <Col xs={12}>
              <div className="fw-700">Trial Expiration Date</div>
              <div className="">{ renderDate(selectedAccount.trial_expiration_datetime) }</div>
            </Col>
          </Row>
          <div className="t-border mv-30"></div>
          <div className="fw-700 fs-20 ml-10">Subscription Info</div>
          <Row align="middle" className="p-20" gutter={[20,20]}>
            <Col xs={12}>
              <div className="fw-700">Subscription ID</div>
              <div className="">{ selectedAccount.subscription_id }</div>
            </Col>
            <Col xs={12}>
              <div className="fw-700">Price ID</div>
              <div className="">{ selectedAccount.price_id }</div>
            </Col>
            <Col xs={12}>
              <div className="fw-700">Customer ID</div>
              <div className="">{ selectedAccount.payment_customer_id }</div>
            </Col>
            <Col xs={12}>
              <div className="fw-700">Promo Code</div>
              <div className="">{ selectedAccount.promo_code }</div>
            </Col>
            <Col xs={12}>
              <div className="fw-700">Promo Expiration Date</div>
              <div className="">{ renderDate(selectedAccount.promo_expiration_datetime)}</div>
            </Col>
            <Col xs={12}>
              <div className="fw-700">Next Billing Date</div>
              <div className="">{ renderDate(selectedAccount.next_billing_datetime)}</div>
            </Col>
            <Col xs={12}>
              <div className="fw-700">Cancellation Date</div>
              <div className="">{ renderDate(selectedAccount.cancellation_datetime)}</div>
            </Col>
            <Col xs={12}>
              <div className="fw-700">Cancelled At Date</div>
              <div className="">{ renderDate(selectedAccount.cancelled_at_datetime)}</div>
            </Col>
          </Row>
          <div className="t-border mv-30"></div>
          <div className="fw-700 fs-20 ml-10">Payment Method</div>
          <Row align="middle" className="p-20" gutter={[20,20]}>
            <Col xs={12}>
              <div className="fw-700">Payment Method ID</div>
              <div className="">{ selectedAccount.payment_method_id }</div>
            </Col>
            <Col xs={12}>
              <div className="fw-700">Card Type</div>
              <div className="">{ selectedAccount.card_type }</div>
            </Col>
            <Col xs={12}>
              <div className="fw-700">Last 4</div>
              <div className="">{ selectedAccount.last_4 }</div>
            </Col>
            <Col xs={12}>
              <div className="fw-700">Expiration Date</div>
              <div className="">{ selectedAccount.exp_month } / { selectedAccount.exp_year }</div>
            </Col>
            <Col xs={12}>
              <div className="fw-700">Zip Code</div>
              <div className="">{ selectedAccount.zip_code }</div>
            </Col>
          </Row>
          <button className="primary-button" type="button" onClick={() => navigate(`/system/accounts/${selectedAccount.account_id}`)}>View Account Details</button>
        </div>
      </Modal>
    )
  }

  const getPayingAccounts = () => {
    const filteredAccounts = cloneDeep(accounts).filter(x => x.status == "ACTIVE" || x.status == "PAST_DUE").map((x, i) => {
      return {
        ...x,
        key: i
      }
    });
    return filteredAccounts
  }

  const getActiveAccounts = () => {
    const filteredAccounts = cloneDeep(accounts).filter(x => x.status == "ACTIVE").map((x, i) => {
      return {
        ...x,
        key: i
      }
    });
    return filteredAccounts
  }

  const getPastDueAccounts = () => {
    const filteredAccounts = cloneDeep(accounts).filter(x => x.status == "PAST_DUE").map((x, i) => {
      return {
        ...x,
        key: i
      }
    });
    return filteredAccounts
  }

  const getTrialingAccounts = () => {
    const filteredAccounts = cloneDeep(accounts).filter(x => x.status == "TRIALING").map((x, i) => {
      return {
        ...x,
        key: i
      }
    });
    return sortBy(filteredAccounts, "trial_expiration_datetime")
  }

  const getTrialEndedAccounts = () => {
    const filteredAccounts = cloneDeep(accounts).filter(x => x.status == "TRIAL_ENDED").map((x, i) => {
      return {
        ...x,
        key: i
      }
    });
    return sortBy(filteredAccounts, "trial_expiration_datetime")
  }

  const getCancelledAccounts = () => {
    const filteredAccounts = cloneDeep(accounts).filter(x => x.status == "CANCELLED").map((x, i) => {
      return {
        ...x,
        key: i
      }
    });
    return filteredAccounts
  }

  const getTestAccounts = () => {
    const filteredAccounts = cloneDeep(accounts).filter(x => x.status == "TEST").map((x, i) => {
      return {
        ...x,
        key: i
      }
    });
    return filteredAccounts
  }

  const renderContent = () => {

    const data = accounts.map((x, i) => {
      return {
        ...x,
        key: i
      }
    });

    return (
      <FloatingContainer className="ph-20" verticalPadding={20} maxWidth={1000}>
        <Row align="middle" gutter={[15,15]}>
          <Col xs={24}>
            <div className="shadow-card p-30 text-center">
              <div className="fs-40 fw-700">{getPayingAccounts().length}</div>
              <div className="fs-16 fw-400">SUBSCRIPTIONS</div>
            </div>
          </Col>
          <Col xs={24} lg={12}>
            <div className="shadow-card p-30 text-center">
              <div className="fs-40 fw-700">{getActiveAccounts().length}</div>
              <div className="fs-16 fw-400">ACTIVE</div>
            </div>
          </Col>
          <Col xs={24} lg={12}>
            <div className="shadow-card p-30 text-center">
              <div className="fs-40 fw-700">{getPastDueAccounts().length}</div>
              <div className="fs-16 fw-400">PAST DUE</div>
            </div>
          </Col>
          <Col xs={24} lg={8}>
            <div className="shadow-card p-30 text-center">
              <div className="fs-40 fw-700">{getTrialingAccounts().length}</div>
              <div className="fs-16 fw-400">ACTIVE TRIALS</div>
            </div>
          </Col>
          <Col xs={24} lg={8}>
            <div className="shadow-card p-30 text-center">
              <div className="fs-40 fw-700">{getTrialEndedAccounts().length}</div>
              <div className="fs-16 fw-400">ENDED TRIALS</div>
            </div>
          </Col>
          <Col xs={24} lg={8}>
            <div className="shadow-card p-30 text-center">
              <div className="fs-40 fw-700">{getCancelledAccounts().length}</div>
              <div className="fs-16 fw-400">CANCELLED</div>
            </div>
          </Col>
        </Row>
        <div className="t-border mv-30"></div>
        <div className="fs-18 fw-700 mb-10 ml-5">Subscribers</div>
        <div className="shadow-card">
          <Table 
            columns={activeColumns} 
            dataSource={getPayingAccounts()}
            pagination={false}
            onRow={(record, rowIndex) => {
              return {
                onClick: e => selectAccount(record)
              };
            }}
          />
        </div>

        <div className="t-border mv-30"></div>
        <div className="fs-18 fw-700 mb-10 ml-5">Trialing</div>
        <div className="shadow-card">
          <Table 
            columns={trialingColumns} 
            dataSource={getTrialingAccounts()}
            pagination={false}
            onRow={(record, rowIndex) => {
              return {
                onClick: e => selectAccount(record)
              };
            }}
          />
        </div>

        <div className="t-border mv-30"></div>
        <div className="fs-18 fw-700 mb-10 ml-5">Trial Ended</div>
        <div className="shadow-card">
          <Table 
            columns={trialEndedColumns} 
            dataSource={getTrialEndedAccounts()}
            pagination={false}
            onRow={(record, rowIndex) => {
              return {
                onClick: e => selectAccount(record)
              };
            }}
          />
        </div>

        <div className="t-border mv-30"></div>
        <div className="fs-18 fw-700 mb-10 ml-5">Cancelled</div>
        <div className="shadow-card">
          <Table 
            columns={cancelledColumns} 
            dataSource={getCancelledAccounts()}
            pagination={false}
            onRow={(record, rowIndex) => {
              return {
                onClick: e => selectAccount(record)
              };
            }}
          />
        </div>

        <div className="t-border mv-30"></div>
        <div className="fs-18 fw-700 mb-10 ml-5">Test Accounts</div>
        <div className="shadow-card">
          <Table 
            columns={testColumns} 
            dataSource={getTestAccounts()}
            pagination={false}
            onRow={(record, rowIndex) => {
              return {
                onClick: e => selectAccount(record)
              };
            }}
          />
        </div>

        {/* <div className="t-border mv-30"></div>
        <div className="fs-18 fw-700 mb-10 ml-5">All Accounts</div>
        <div className="shadow-card">
          <Table 
            columns={columns} 
            dataSource={data}
            pagination={false}
            onRow={(record, rowIndex) => {
              return {
                onClick: e => selectAccount(record)
              };
            }}
          />
        </div> */}
        {renderAccountModal()}
      </FloatingContainer>
    )
  }

  if (isLoading) {
    return <LoadingSpinner/>
  }

  return (
    <AdminContent header={renderHeader()} body={renderContent()}/>
  );
}

export default AccountsPage;

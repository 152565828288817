import { isNull, isUndefined, sortBy } from 'lodash';
import StorageKeys from '../constants/storageKeys';
import { Country, State }  from 'country-state-city';
import { phone } from 'phone';
import { parsePhoneNumber } from 'libphonenumber-js'

export const getDefaultCountryCode = () => {
  const country_code = localStorage.getItem(StorageKeys.DEFAULT_COUNTRY);
  return country_code ? country_code : ""
}

export const setDefaultCountryCode = (code) => {
    const sanitizedCode = !isUndefined(code) ? code : ""
    localStorage.setItem(StorageKeys.DEFAULT_COUNTRY, sanitizedCode);
}

export const getDefaultPhoneCountryCode = () => {
    const country_code = localStorage.getItem(StorageKeys.DEFAULT_PHONE_COUNTRY);
    return country_code ? country_code : "US"
  }
  
  export const setDefaultPhoneCountryCode = (code) => {
      const sanitizedCode = !isUndefined(code) ? code : ""
      localStorage.setItem(StorageKeys.DEFAULT_PHONE_COUNTRY, sanitizedCode);
  }

export const getDefaultState = () => {
    const state = localStorage.getItem(StorageKeys.DEFAULT_STATE);
    return state ? state : ""
}
  
export const setDefaultState = (state) => {
    const sanitizedState = !isUndefined(state) ? state : ""
    localStorage.setItem(StorageKeys.DEFAULT_STATE, sanitizedState);
}

export const getCountryOptions = () => {
    const countryResults = Country.getAllCountries()
    const sortedResults = sortBy(countryResults, ["name"])
    const data = sortedResults.map((country) => {
    return {
        value: country.isoCode,
        text: country.name
    }
    })
    return data
}

export const getStateOptions = () => {
    const stateResults = State.getStatesOfCountry("US")
    const sortedResults = sortBy(stateResults, ["name"])
    const data = sortedResults.map((country) => {
    return {
        value: country.isoCode,
        text: country.name
    }
    })
    return data
}

export const getFormattedPhoneNumber = (value) => {
    if (!isNull(value) && !isUndefined(value) && value != "") {
        // Use the "phone" library to check validity and get country code
        const phoneNumber = phone(value, { validateMobilePrefix: false })
        if (phoneNumber.isValid) {
            // Use the "libphonenumber-js" library for formatting
            const parsed = parsePhoneNumber(value, phoneNumber.countryIso2)
            return parsed.formatNational()
        }
        return value
    }
    return ""
}

export const getPhoneNumber = (value, country) => {
    if (country) {
        const number = phone(value, { country: country, validateMobilePrefix: false })
        return number.phoneNumber
    }
    return ""
}

export const getCountryCodeForPhoneNumber = (value) => {
    if (value) {
        const number = phone(value, { validateMobilePrefix: false })
        return !isNull(number.countryIso2) ? number.countryIso2 : "US"
    }
    return "US"
}
  
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import LoadingSpinner from '../../../../components/loading';
import FloatingContainer from '../../../../components/floatingContainer'

const SendInvoiceConfirmPage = () => {

  const [isLoading, setLoading] = useState(true);

  const navigate = useNavigate();
  const params = useParams();

  const eventId = params.event_id;
  const invoiceId = params.id;

  useEffect(() => {
    window.scrollTo(0, 0);
    setLoading(false)
  }, []);

  const renderContent = () => {
    return (
      <>
        <FloatingContainer className="ph-20 mb-20 bg-gray" verticalPadding={50} maxWidth={500}>
          <div className="shadow-card pv-30 ph-20 text-center">
            <div className="fs-24 fw-700 mb-10">Success!</div>
            <div className="fs-14 mb-20">Your invoice has been sent.</div>
            <button className="small-primary-button" style={{ width: 150 }} onClick={() => navigate(`/admin/events/${eventId}?tab=documents`)}>Back to Event</button>
          </div>
        </FloatingContainer>
      </>
    )
  }

  if (isLoading) {
    return (
      <div className="event-contract--container">
        <LoadingSpinner/>
      </div>
    )
  }

  return (
    <div className="event-contract--container">
      <div className="event-contract--body">
        <div className="event-contract--content">
          { renderContent() }
        </div>
      </div>
    </div>
  );
}

export default SendInvoiceConfirmPage;

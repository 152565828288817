export default {
  VIEW_MUSIC: "VIEW_MUSIC",
  EDIT_MUSIC: "EDIT_MUSIC",
  VIEW_QUESTIONNAIRES: "VIEW_QUESTIONNAIRES",
  EDIT_QUESTIONNAIRES: "EDIT_QUESTIONNAIRES",
  VIEW_TIMELINE: "VIEW_TIMELINE",
  EDIT_TIMELINE: "EDIT_TIMELINE",
  VIEW_FINANCIALS: "VIEW_FINANCIALS",
  VIEW_SERVICES: "VIEW_SERVICES",
  VIEW_DOCUMENTS: "VIEW_DOCUMENTS"
};
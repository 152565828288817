import React, { useEffect, useState } from "react";
import { getStripeSetupIntent } from '../../api';
import { isNull } from 'lodash';
import LoadingSpinner from '../../components/loading';
import AdminContent from '../../components/adminContent';
import SetupForm from '../../components/setupForm';
import FloatingContainer from '../../components/floatingContainer'
import useApi from '../../hooks/useApi';
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const PaymentMethodPage = () => {

  const [isLoading, setLoading] = useState(true);
  const [clientSecret, setClientSecret] = useState(null);

  const [sendRequest] = useApi()

  useEffect(() => {
    window.scrollTo(0, 0);
    refreshPage()
  }, []);

  const refreshPage = async () => {
    try {
      const setupIntentResults = await  sendRequest(getStripeSetupIntent());
      setClientSecret(setupIntentResults.client_secret)
    } finally {
      setLoading(false)
    }
  }

  const renderHeader = () => {
    return (
      <div className="fs-24 fw-700 p-20">Update Payment Method</div>
    )
  }

  const renderElements = () => {
    if (isNull(clientSecret)) {
      return null
    }

    const options = {
      clientSecret: clientSecret,
    };
  
    return (
      <Elements stripe={stripePromise} options={options}>
        <SetupForm returnPath="/admin/account/payment-method/complete"/>
      </Elements>
    );
  }

  const renderContent = () => {
    return (
      <FloatingContainer className="ph-20" verticalPadding={20} maxWidth={600}>
        <div className="shadow-card p-20">
          { renderElements() }
        </div>
      </FloatingContainer>
    )
  }

  if (isLoading) {
    return <LoadingSpinner/>
  }

  return (
    <AdminContent header={renderHeader()} body={renderContent()}/>
  );
}

export default PaymentMethodPage;

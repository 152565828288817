import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {SortableContainer, sortableElement} from 'react-sortable-hoc';
import arrayMove from 'array-move';
import { isUndefined, cloneDeep } from "lodash";
import { Row, Col, Input, notification } from 'antd';
import useApi from '../../hooks/useApi';
import { updateSystemRecommendedPlaylistGroupList, createSystemRecommendedPlaylistGroupList, getSystemRecommendedPlaylistGroups, getSystemRecommendedPlaylistGroupList } from '../../api';
import LoadingSpinner from '../../components/loading';
import AdminContent from '../../components/adminContent';
import { ArrowLeftOutlined} from '@ant-design/icons';
import { MdAddCircleOutline, MdRemoveCircleOutline, MdCheck } from "react-icons/md";

const NewSystemRecommendedPlaylistGroupListPage = (props) => {

  const [isLoading, setLoading] = useState(true);
  const [groupListKey, setGroupListKey] = useState("");
  const navigate = useNavigate();
  const params = useParams();
  const [groups, setGroups] = useState([]);
  const [selectedPlaylistGroups, setSelectedPlaylistGroups] = useState([]);

  const [sendRequest] = useApi()

  const groupListId = params.id;
  const isNewGroupList = isUndefined(groupListId);

  useEffect(() => {
    window.scrollTo(0, 0);
    refreshPage();
  }, []);

  const refreshPage = async () => {

    try {
      const playlistGroups = await sendRequest(getSystemRecommendedPlaylistGroups());
      setGroups(playlistGroups);

      if (!isNewGroupList) {
        const groupList = await sendRequest(getSystemRecommendedPlaylistGroupList(groupListId))
        setGroupListKey(groupList.group_list_key)
        setSelectedPlaylistGroups(groupList.system_recommended_playlist_group_ids)
      }
      setLoading(false)
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }

  const onSubmit = () => {

    var body = {
      group_list_key: groupListKey,
      system_recommended_playlist_group_ids: selectedPlaylistGroups
    }

    if (isNewGroupList) {
      sendRequest(createSystemRecommendedPlaylistGroupList(body)).then(response => {
        displaySuccess()
      })
    } else {
      sendRequest(updateSystemRecommendedPlaylistGroupList(groupListId, body)).then(response => {
        displaySuccess()
      })
    }
  }

  const displaySuccess = () => {
    notification.success({
      message: 'Success!',
      description: 'Your playlist group list has been saved.',
      duration: 3
    });
    navigate("/system/recommended-playlist-group-lists");
  }

  const onSortEnd = (oldIndex, newIndex) => {
    var newSelectedPlaylistGroups = cloneDeep(selectedPlaylistGroups)
    if (oldIndex !== newIndex) {
      const sortedSelectedPlaylistGroups = arrayMove(newSelectedPlaylistGroups, oldIndex, newIndex).filter(el => !!el);
      setSelectedPlaylistGroups(sortedSelectedPlaylistGroups)
    }
  };

  const renderSelectedPlaylistGroup = (groupListId, index) => {
    const group = groups.find((x) => x.system_recommended_playlist_group_id == groupListId)
    return (
      <div key={index} className="shadow-card p-20">
        <Row align="middle">
          <Col flex={1}>
            <div className="fw-700 fs-14">{group && group.group_name}</div>
          </Col>
          <Col>
            <div className="display-flex">
              <MdRemoveCircleOutline style={{ fontSize: 24, color: '#CCC'}} onClick={() => onRemove(groupListId, index)}/>
            </div>
          </Col>
        </Row>
      </div>
    )
  }

  const SortableItem = sortableElement(({value, itemIndex}) => (
    <li className="mb-10">
      {renderSelectedPlaylistGroup(value, itemIndex)}
    </li>
  ));

  const SortableList = SortableContainer(({items, sectionIndex}) => {
    return (
      <ul className="question-row-container">
        {items.map((value, index) => (
          <SortableItem key={`item-${index}`} index={index} itemIndex={index} value={value}/>
        ))}
      </ul>
    );
  });

  const onSelect = (id) => {
    var newSelectedPlaylistGroups = cloneDeep(selectedPlaylistGroups);
    newSelectedPlaylistGroups.push(id)
    setSelectedPlaylistGroups(newSelectedPlaylistGroups)
  }

  const onRemove = (id) => {
    var newSelectedPlaylistGroups = cloneDeep(selectedPlaylistGroups);
    newSelectedPlaylistGroups = newSelectedPlaylistGroups.filter(x => x != id)
    setSelectedPlaylistGroups(newSelectedPlaylistGroups)
  }

  const renderHeader = () => {
    return (
      <Row align="middle" gutter={[15,15]} className="ph-20" style={{ height: 72 }}>
        <Col>
          <div className="admin-page-back-arrow" onClick={() => navigate(-1)}> <ArrowLeftOutlined/></div>
        </Col>
        <Col flex={1}>
          <Input placeholder="Group List Key" size="large" value={groupListKey} onChange={(e) => setGroupListKey(e.target.value)} style={{ fontSize: 18, fontWeight: 700, maxWidth: 500}}/>
        </Col>
        <Col>
          <button className="page-title-button ml-10" onClick={onSubmit}>Save</button>
        </Col>
      </Row>
    )
  }

  const renderContent = () => {
    if (selectedPlaylistGroups.length == 0) {
      return (
        <div className="c-text-ccc text-center fs-16 mt-30">No groups yet</div>
      )
    }
    return (
      <div className="p-15" style={{ maxWidth: 800 }}>
        <SortableList items={selectedPlaylistGroups} onSortEnd={({ oldIndex, newIndex }) => onSortEnd(oldIndex, newIndex)} helperClass="question-row-dragging" pressDelay={100} lockAxis="y"/>
      </div>
    )
  }

  const renderPlaylistGroup = (group, index) => {
    const alreadyAdded = selectedPlaylistGroups.includes(group.system_recommended_playlist_group_id);
    return (
      <Row  align="middle" key={index} className="b-border ph-15 pv-10">
        <Col flex={1}>
          <div className="fs-16 fw-700">{group.group_name}</div>
          <div className="fs-14 fw-500 c-text-gray">{group.system_recommended_playlist_ids.length} Playlists</div>
        </Col>
        <Col>
          { alreadyAdded ? <MdCheck style={{ fontSize: 24, color: '#00b894'}}/> : <MdAddCircleOutline style={{ fontSize: 24, color: '#536DFE'}} onClick={() => onSelect(group.system_recommended_playlist_group_id)}/>}
        </Col>
      </Row>
    )
  }

  const renderPanel = () => {
    return (
      <div className="shadow-card m-20">
        { groups.map((x,i) => renderPlaylistGroup(x,i)) }
      </div>
    )
  }

  if (isLoading) {
    return <LoadingSpinner/>
  }

  return (
    <AdminContent header={renderHeader()} body={renderContent()} panel={renderPanel()} showPanel={true}/>
  );
}

export default NewSystemRecommendedPlaylistGroupListPage;

import { mergeAttributes, Node } from '@tiptap/core'
import { ReactNodeViewRenderer } from '@tiptap/react'

import MergeTagComponent from './mergeTagComponent'

export default Node.create({
  name: 'merge-tag',

  group: 'inline',

  inline: true,

  addAttributes() {
    return {
      tag: {
        default: "",
      },
      displayName: {
        default: "Select variable...",
      }
    }
  },

  parseHTML() {
    return [
      {
        tag: 'merge-tag',
      },
    ]
  },

  renderHTML({ HTMLAttributes }) {
    return ['merge-tag', mergeAttributes(HTMLAttributes)]
  },

  addNodeView() {
    return ReactNodeViewRenderer(MergeTagComponent, { as: "span" })
  },
})
import React, { useEffect, useState } from "react";
import { Row, Col } from 'antd';
import moment from 'moment';
import useApi from '../../hooks/useApi';
import { 
  createContactsSendgrid,
  getContactImportStatus
} from '../../api';
import AdminContent from '../../components/adminContent';
import FloatingContainer from '../../components/floatingContainer'

const SendgridPage = () => {

  const [createContactResponse, setCreateContactResponse] = useState({});
  const [contactStatusResponse, setContactStatusResponse] = useState({});
  const [statusCheckDate, setStatusCheckDate] = useState(moment());

  const [sendRequest] = useApi()

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const onCreateContacts = async () => {
    try {
      const response = await sendRequest(createContactsSendgrid())
      setCreateContactResponse(response)
    } catch {}
  }

  const onGetImportStatus = async () => {
    try {
      const response = await sendRequest(getContactImportStatus(createContactResponse.job_id))
      setContactStatusResponse(response)
      setStatusCheckDate(moment())
    } catch {}
  }

  const renderHeader = () => {
    return (
      <Row align="middle" className="p-20">
        <Col flex={1}>
          <div className="admin-page-title">SendGrid</div>
        </Col>
      </Row>
    )
  }

  const renderContent = () => {
    return (
      <FloatingContainer className="ph-20" verticalPadding={20} maxWidth={1000}>
        <div className="shadow-card p-15 text-center">
          <button className="small-primary-button" onClick={() => onCreateContacts()}>Create Contacts</button>
          <div className="fw-700 mt-10">Job ID:</div>
          <div>{createContactResponse.job_id ?? "--"}</div>
        </div>
        <div className="shadow-card p-15 text-center mt-20">
          <button className="small-primary-button mt-10" onClick={() => onGetImportStatus()}>Get Import Status</button>
          <div className="fw-700 mt-10">Job ID: <span className="fw-500">{contactStatusResponse.id ?? "--"}</span></div>
          <div className="fw-700 mt-10">Status: <span className="fw-500">{contactStatusResponse.status ?? "--"}</span></div>
          <div className="fw-700 mt-10">Job Type: <span className="fw-500">{contactStatusResponse.job_type ?? "--"}</span></div>
          <div className="fw-700 mt-10">Started At: <span className="fw-500">{contactStatusResponse.started_at ?? "--"}</span></div>
          <div className="fw-700 mt-10">Requested Count: <span className="fw-500">{contactStatusResponse.results && contactStatusResponse.results.requested_count ? contactStatusResponse.results.requested_count : "--"}</span></div>
          <div className="fw-700 mt-10">Last Checked: <span className="fw-500">{statusCheckDate.format() ?? "--"}</span></div>
        </div>
      </FloatingContainer>
    )
  }

  return (
    <AdminContent header={renderHeader()} body={renderContent()}/>
  );
}

export default SendgridPage;

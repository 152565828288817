import React, { useEffect, useState } from "react";
import { Row, Col, Modal, Dropdown, Menu, Input, notification } from 'antd';
import { toLower, cloneDeep } from "lodash";
import { getAdminUsers, getPlanLimit, deleteAdminUser } from '../../../api';
import { useNavigate } from 'react-router-dom';
import LoadingSpinner from '../../../components/loading';
import AdminContent from '../../../components/adminContent';
import FloatingContainer from '../../../components/floatingContainer'
import ErrorCard from '../../../components/errorCard';
import useApi from '../../../hooks/useApi';
import useDocumentTitle from '../../../hooks/useDocumentTitle';
import { EditOutlined, DeleteOutlined, UserOutlined, SearchOutlined } from '@ant-design/icons';
import { MdOutlineMoreHoriz, MdOutlineClose } from "react-icons/md";
import emptyStateImage from '../../../images/empty-state-icon.png';
import emptySearchImage from '../../../images/empty-search-icon.png';

const EmployeesPage = () => {

  const [isLoading, setLoading] = useState(true);
  const [isError, setError] = useState(false);
  const [employees, setEmployees] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [planLimitDetails, setPlanLimitDetails] = useState({});
  const [isEmployeeLimitModalVisible, setEmployeeLimitModalVisible] = useState(false);
  const [isConfirmRemoveModalVisible, setConfirmRemoveModalVisible] = useState(false);
  const [isRemoveErrorModalVisible, setRemoveErrorModalVisible] = useState(false);
  const [removeErrorMessage, setRemoveErrorMessage] = useState("");
  const [selectedEmployee, setSelectedEmployee] = useState({});

  useDocumentTitle("Staff")
  const [sendRequest] = useApi()
  const navigate = useNavigate();

  const menu = (employee) => {
    return (
      <Menu>
        <Menu.Item>
          <div onClick={() => handleEdit(employee)}>
            <EditOutlined style={{ marginRight: 8}}/> Edit
          </div>
        </Menu.Item>
        { employee.admin_user_role_id != "OWNER" && (
          <Menu.Item>
            <div onClick={() => handleDelete(employee)}>
              <DeleteOutlined style={{ marginRight: 8}}/> Delete
            </div>
          </Menu.Item>
        )}
      </Menu>
    )
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    refreshPage()
  }, []);

  const refreshPage = async () => {
    try {
      const employeeResults = await sendRequest(getAdminUsers())
      setEmployees(employeeResults)

      const planLimitResults = await sendRequest(getPlanLimit())
      setPlanLimitDetails(planLimitResults)
    } catch (error) {
      setError(true)
    } finally {
      setLoading(false)
    }
  }

  const onNewClick = () => {
    if (!planLimitDetails.create_allowed) {
      setEmployeeLimitModalVisible(true)
      return
    }
    
    navigate("/admin/staff/new")
  }

  const handleEdit = (employee) => {
    navigate(`/admin/staff/${employee.user_id}/edit`)
  };

  const handleDelete = (employee) => {
    setSelectedEmployee(employee)
    setConfirmRemoveModalVisible(true)
  };

  const confirmDelete = async () => {
    try {
      setConfirmRemoveModalVisible(false)
      const response = await sendRequest(deleteAdminUser(selectedEmployee.user_id));
      if (response.status == "owner") {
        setRemoveErrorMessage("The owner of the account cannot be deleted.")
        setRemoveErrorModalVisible(true)
      } else if (response.status == "in-use") {
        setRemoveErrorMessage("This staff member is assigned to one or more events. These events must be removed before this user can be removed.")
        setRemoveErrorModalVisible(true)
      } else {
        await refreshPage()
        displayRemoveSuccess()
      }
    } catch {
      displayRemoveFailed()
    }
  }

  const displayRemoveSuccess = () => {
    notification.success({
      message: 'Success!',
      description: 'The staff member has been deleted.',
      duration: 3
    });
  }

  const displayRemoveFailed = () => {
    notification.error({
      message: 'Error',
      description: 'There was an issue deleting the staff member. Please contact support.',
      duration: 3
    });
  }

  const onSearchTextChange = (text) => {
    setSearchText(text)
  }

  const getFilteredEployeess = () => {
    const allEmployees = cloneDeep(employees)
    var filteredEmployees = allEmployees;
    if (searchText != "") {
      filteredEmployees = filteredEmployees.filter(x => {
        const lowerEmployeeFirstName = toLower(x.first_name)
        const lowerEmployeeLastName = toLower(x.last_name)
        const lowerEmployeeFullName = `${lowerEmployeeFirstName} ${lowerEmployeeLastName}`

        const matchFirst = lowerEmployeeFirstName.includes(toLower(searchText))
        const matchLast = lowerEmployeeLastName.includes(toLower(searchText))
        const matchFull = lowerEmployeeFullName.includes(toLower(searchText))

        return matchFirst || matchLast || matchFull
      })
    }

    return filteredEmployees;
  }

  const renderEmployee = (employee, index) => {
    const isOwner = employee.admin_user_role_id == "OWNER"
    return (
      <div key={index} className="p-10 b-border">
        <Row gutter={[15,15]} align="middle">
          <Col onClick={() => handleEdit(employee)}>
            { employee.photo ? (
              <div className="small-profile-photo size-60" style={{ backgroundImage: `url(${employee.photo})`}}></div>
            ) : (
              <div className="display-flex">
                <div className="user-profile-circle"><UserOutlined/></div>
              </div>
            )}
          </Col>
          <Col flex={1} onClick={() => handleEdit(employee)}>
            <div className="fs-14 fw-700">{employee.first_name} {employee.last_name} {isOwner ? "(Owner)" : ""}</div>
            <div className="fs-14 fw-500 c-text-gray line-1-1">{employee.email}</div>
          </Col>
          <Col flex={0}>
            <div className="display-flex mr-5">
              <Dropdown overlay={menu(employee)} placement="bottomRight" trigger="click">
                <div className="dots-container">
                  <MdOutlineMoreHoriz style={{ fontSize: 24, color: '#999'}}/>
                </div>
              </Dropdown>
            </div>
          </Col>
        </Row>
      </div>
    )
  }

  const renderEmployeeResults = () => {
    if (getFilteredEployeess().length > 0) {
      return getFilteredEployeess().map((x,i) => renderEmployee(x,i));
    } else {
      return (
        <div className="ph-20 pv-40 text-center">
          <div>
            <img src={emptySearchImage} width={200}/>
          </div>
          <div className="fs-18 fw-700 mt-30">
            No staff members found
          </div>
          <div className="fs-14 fw-500 c-text-gray">
            Adjust your filter or search again!
          </div>
        </div>
      )
    }
  }

  const renderEmployeeBox = () => {
    return (
      <div className="shadow-card">
        <div className="p-15 b-border display-flex flex-row">
          <div className="flex-1">
            <Input 
              className="list-search-input-square" 
              size="large" 
              placeholder="Search staff" 
              value={searchText} 
              onChange={(e) => onSearchTextChange(e.target.value)} 
              allowClear={true} 
              prefix={<SearchOutlined color="#e4e4e4" style={{ marginRight: 6 }}/>} 
            />
          </div>
        </div>
        { renderEmployeeResults() }
      </div>
    )
  }

  const renderEmployees = () => {
    if (employees.length == 0) {
      return (
        <div className="shadow-card ph-20 pv-50 text-center">
          <div>
            <img src={emptyStateImage} width={300}/>
          </div>
          <div className="fs-18 fw-700 mt-30">
            No staff yet
          </div>
          <div className="fs-14 fw-500 c-text-gray">
            Create an staff member to get started!
          </div>
          <button className="primary-button mt-30" style={{ width: 200 }} onClick={onNewClick}>New Staff</button>
        </div>
      )
    } else {
      return renderEmployeeBox()
    }
  }

  const renderHeader = () => {
    return (
      <Row align="middle" className="p-20">
        <Col flex={1}>
          <div className="fs-24 fw-700">Staff</div>
        </Col>
        <Col>
          { employees.length > 0 && (
            <button className="page-title-button" onClick={onNewClick}>New Staff</button>
          )}
        </Col>
      </Row>
    )
  }

  const renderConfirmRemoveEmployeeModal = () => {
    return (
      <Modal visible={isConfirmRemoveModalVisible} closable={false} footer={null} width={400} wrapClassName="rounded-modal">
        <div className="text-right" onClick={() => setConfirmRemoveModalVisible(false)}><MdOutlineClose size={30} color={"#CCC"}/></div>
        <div className="mt-10">
          <div className="fw-700 fs-20 text-center">Are you sure?</div>
          <div className="fw-500 fs-14 mt-20 mb-20 text-center">Are you sure you would like to delete <span className="fw-700">{selectedEmployee.first_name} {selectedEmployee.last_name}</span>? This action cannot be undone.</div>
          <button className="primary-button warning" type="button" onClick={() => confirmDelete()}>Delete Staff</button>
          <div className="text-center mt-15">
            <div className="blue-link" onClick={() => setConfirmRemoveModalVisible(false)}>Cancel</div>
          </div>
        </div>
      </Modal>
    )
  }

  const renderRemoveErrorModal = () => {
    return (
      <Modal visible={isRemoveErrorModalVisible} closable={false} footer={null} width={400} wrapClassName="rounded-modal">
        <div className="text-right" onClick={() => setRemoveErrorModalVisible(false)}><MdOutlineClose size={30} color={"#CCC"}/></div>
        <div className="mt-10">
          <div className="fw-700 fs-20 text-center">Cannot Remove Staff</div>
          <div className="fw-500 fs-14 mt-20 mb-20 text-center">{removeErrorMessage}.</div>
          <button className="primary-button" type="button" onClick={() => setRemoveErrorModalVisible()}>OK</button>
        </div>
      </Modal>
    )
  }

  const renderEmployeeLimitModal = () => {
    return (
      <Modal visible={isEmployeeLimitModalVisible} closable={false} footer={null} width={400} wrapClassName="rounded-modal">
        <div className="text-right" onClick={() => setEmployeeLimitModalVisible(false)}><MdOutlineClose size={30} color={"#CCC"}/></div>
        <div className="mt-10">
          <div className="fw-700 fs-20 text-center">Staff Limit Reached</div>
          <div className="fw-500 fs-14 mt-20 mb-20 text-center">Your current plan only allows for <span className="fw-700">{planLimitDetails.employee_limit}</span> staff members. If you would like to add additional staff, please visit the Account page and upgrade your plan.</div>
          <button className="primary-button" type="button" onClick={() => setEmployeeLimitModalVisible()}>Got it!</button>
        </div>
      </Modal>
    )
  }

  const renderContent = () => {
    if (isLoading) {
      return <LoadingSpinner/>
    }
    if (isError) {
      return <ErrorCard/>
    }
    return (
      <>
        <FloatingContainer className="ph-20" verticalPadding={20} maxWidth={800}>
          { renderEmployees() }
        </FloatingContainer>
        { renderEmployeeLimitModal() }
        { renderConfirmRemoveEmployeeModal() }
        { renderRemoveErrorModal() }
      </>
    )
  }

  return (
    <AdminContent body={renderContent()} header={renderHeader()}/>
  );
}

export default EmployeesPage;

import React, { useEffect, useState } from "react";
import { Navigate, Link, useNavigate, useLocation } from "react-router-dom";
import { isUndefined, isEmpty } from 'lodash';
import { Form, Input, Row, Col } from 'antd';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import './setPassword.style.scss';
import useDocumentTitle from '../../../hooks/useDocumentTitle';

import { setPassword } from '../../../api';

const SetPasswordPage = () => {
  useDocumentTitle("Set Password")
  const navigate = useNavigate();
  const location = useLocation();

  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [email, setEmail] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0)

    const historyState = location.state;
    if (!isUndefined(historyState) && !isUndefined(historyState.email)) {
      setEmail(location.state.email)
    }
    setLoading(false)
  }, [])

  const onSubmit = values => {
    const body = {
      email: email,
      password: values.password
    }
    setErrorMessage("");
    setPassword(body).then(response => {
      navigate("/verify/email", { state: { email: email } })
    }).catch(error => {
      console.log(error)
      setErrorMessage("Something went wrong. Please try again.");
    })
  }

  const renderContent = () => {
    if (!loading && isEmpty(email)) {
      return <Navigate to="/login"/>
    } else {
      return renderPage()
    }
  }

  const renderError = () => {
    if (!isEmpty(errorMessage)) {
      return <div className="login-error">{errorMessage}</div>;
    }
    return null;
  }

  const renderPage = () => {
    return (
      <div className="center-content-box">
        <div className="center-content-box--title">Welcome!</div>
        <div className="center-content-box--subtitle">It looks like this is the first time you are signing in with <b>{email}</b>. Create a password below to continue!</div>
        <div className="center-content-box--form">
          <Form layout="vertical" name="admin-login" onFinish={onSubmit}>
            <Row gutter={[20,20]}>
              <Col xs={24}>
                <Form.Item
                  name={"password"}
                  rules={[
                    { required: true, message: `Password is required!` }
                  ]}
                >
                  <Input.Password
                    size="large"
                    placeholder="Enter Password"
                    iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                  />
                </Form.Item>
              </Col>
              <Col xs={24}>
                <Form.Item
                  name={"confirm_password"}
                  dependencies={['password']}
                  rules={[
                    { required: true, message: `Confirm Password is required!` },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue('password') === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(new Error('The two passwords that you entered do not match!'));
                      },
                    })
                  ]}
                >
                  <Input.Password
                    size="large"
                    placeholder="Confirm Password"
                    iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                  />
                </Form.Item>
              </Col>
            </Row>
            { renderError() }
            <button className="primary-button" type="submit">Continue</button>
            <div className="center-content-box--link-container">
              <Link to="/login" className="center-content-box--link">Sign In</Link>
            </div>
          </Form>
        </div>
      </div>
    )
  }

  return (
    <div className="center-content-page">
      { renderContent() }
    </div>
  );
}

SetPasswordPage.propTypes = {};

export default SetPasswordPage;

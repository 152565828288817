import { useContext } from "react";
import StorageKeys from '../constants/storageKeys';
import AppContext from '../app/context';
import { getDeviceId } from '../helpers/deviceHelper';
import { removeBetaFeatures } from '../helpers/betaFeatureHelper';
import { logout } from '../api';
import axios from "axios";
const API_URL = process.env.REACT_APP_API_URL;

export default function useApi() {
  const { auth, setAuth, setCurrentEvent } = useContext(AppContext);

  const handleLogout = async () => {
    try {
      if (auth.user) {
        await logout(auth.user.user_id, getDeviceId())
      }
      setAuth({})
      setCurrentEvent({})
      removeBetaFeatures()
    } catch (error) {
      console.log(error)
    }
  }

  const handleRequest = config => {
    const localStorageAuth = JSON.parse(localStorage.getItem(StorageKeys.AUTH)) || {}
    const deviceId = getDeviceId()
    if (localStorageAuth.access_token) {
      config.headers.Authorization = `Bearer ${localStorageAuth.access_token}`;
      config.headers["source"] = "web"
      config.headers["deviceid"] = deviceId
    }
    return Promise.resolve(config);
  };

  const handleSuccess = response => {
    return response.data;
  };

  const handleError = async (error) => {
    var originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry && error.response.data.name == "TokenExpiredError") {
      originalRequest._retry = true;
      await refreshAccessToken();     
      return authenticatedService(originalRequest);
    } else if (error.response.status === 401) {
      await handleLogout()
    } else if (error.response.status === 403 && error.response.data.name == "InvalidTokenError") {
      await handleLogout()
    }
    return Promise.reject(error.response.data);
  };

  const refreshAccessToken = async () => {
    try {
      const localStorageAuth = JSON.parse(localStorage.getItem(StorageKeys.AUTH)) || {}
      const deviceId = getDeviceId()
      const response = await axios.post(`${API_URL}/auth/token`, { user_id: auth.user.user_id, refresh_token: localStorageAuth.refresh_token, source: "web", device_id: deviceId });
      setAuth(response.data)
    } catch(error) {
      if (error.response.status === 403 && error.response.data.name == "InvalidTokenError") {
        await handleLogout()
      }
    }
  }

  let authenticatedService = axios.create({
    baseURL: API_URL
  });
  authenticatedService.interceptors.request.use(handleRequest);
  authenticatedService.interceptors.response.use(handleSuccess, handleError);

  const sendRequest = (options) => {
    return authenticatedService(options);
  }

  return [sendRequest, refreshAccessToken];
}
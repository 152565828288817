import React, { useEffect, useState } from "react";
import { Row, Col } from 'antd';
import useApi from '../../hooks/useApi';
import { getDashboardInfo } from '../../api';
import AdminContent from '../../components/adminContent';
import FloatingContainer from '../../components/floatingContainer'

const SystemDashboardPage = () => {

  const [sendRequest] = useApi()
  const [dashboardData, setDashboardData] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
    loadPage();
  }, []);

  const loadPage = async () => {
    try {
      const dashboardResults = await sendRequest(getDashboardInfo());
      setDashboardData(dashboardResults)

    } catch (error) {
      console.log(error)
    }
  }

  const getConversionRate = () => {
    const totalPayingUsers = Number(dashboardData.total_paying_accounts)
    const totalUsers = Number(dashboardData.total_trial_ended_accounts) + Number(dashboardData.total_paying_accounts)
    const percentage = (totalPayingUsers / totalUsers) * 100
    return percentage.toFixed(0) + "%"
  }

  const renderHeader = () => {
    return (
      <Row align="middle" className="p-20">
        <Col flex={1}>
          <div className="admin-page-title">System Dashboard</div>
        </Col>
      </Row>
    )
  }

  const renderContent = () => {
    return (
      <FloatingContainer className="ph-20" verticalPadding={20} maxWidth={800}>
        <div className="fs-24 fw-700 pl-10 pb-10">Accounts</div>
        <Row align="middle" gutter={[15,15]}>
          <Col xs={24}>
            <div className="shadow-card p-30 text-center">
              <div className="fs-40 fw-700">{dashboardData.total_accounts}</div>
              <div className="fs-16 fw-400">TOTAL ACCOUNTS</div>
            </div>
          </Col>
          <Col xs={24} sm={12}>
            <div className="shadow-card p-30 text-center">
              <div className="fs-40 fw-700">{dashboardData.total_paying_accounts}</div>
              <div className="fs-16 fw-400">ACTIVE / PAYING</div>
            </div>
          </Col>
          <Col xs={24} sm={12}>
            <div className="shadow-card p-30 text-center">
              <div className="fs-40 fw-700">{dashboardData.total_past_due_accounts}</div>
              <div className="fs-16 fw-400">PAST DUE</div>
            </div>
          </Col>
          <Col xs={24} lg={8}>
            <div className="shadow-card p-30 text-center">
              <div className="fs-40 fw-700">{dashboardData.total_trialing_accounts}</div>
              <div className="fs-16 fw-400">TRIALING</div>
            </div>
          </Col>
          <Col xs={24} lg={8}>
            <div className="shadow-card p-30 text-center">
              <div className="fs-40 fw-700">{dashboardData.total_trial_ended_accounts}</div>
              <div className="fs-16 fw-400">TRIAL ENDED</div>
            </div>
          </Col>
          <Col xs={24} lg={8}>
            <div className="shadow-card p-30 text-center">
              <div className="fs-40 fw-700">{dashboardData.total_cancelled_accounts}</div>
              <div className="fs-16 fw-400">CANCELLED</div>
            </div>
          </Col>
        </Row>
        <div className="fs-24 fw-700 pl-10 pb-10 mt-20">Users</div>
        <Row align="middle" gutter={[15,15]}>
          <Col xs={24}>
            <div className="shadow-card p-30 text-center">
              <div className="fs-40 fw-700">{dashboardData.total_users}</div>
              <div className="fs-16 fw-400">TOTAL USERS</div>
            </div>
          </Col>
          <Col xs={24} lg={8}>
            <div className="shadow-card p-30 text-center">
              <div className="fs-40 fw-700">{dashboardData.total_system_admin_users}</div>
              <div className="fs-16 fw-400">SYSTEM ADMINS</div>
            </div>
          </Col>
          <Col xs={24} lg={8}>
            <div className="shadow-card p-30 text-center">
              <div className="fs-40 fw-700">{dashboardData.total_admin_users}</div>
              <div className="fs-16 fw-400">DJS</div>
            </div>
          </Col>
          <Col xs={24} lg={8}>
            <div className="shadow-card p-30 text-center">
              <div className="fs-40 fw-700">{dashboardData.total_client_users}</div>
              <div className="fs-16 fw-400">CLIENTS</div>
            </div>
          </Col>
        </Row>
        <div className="fs-24 fw-700 pl-10 pb-10 mt-20">Events</div>
        <Row align="middle" gutter={[15,15]}>
          <Col xs={24}>
            <div className="shadow-card p-30 text-center">
              <div className="fs-40 fw-700">{dashboardData.total_events}</div>
              <div className="fs-16 fw-400">TOTAL EVENTS</div>
            </div>
          </Col>
        </Row>
        <div className="fs-24 fw-700 pl-10 pb-10 mt-20">Metrics</div>
        <Row align="middle" gutter={[15,15]}>
          <Col xs={24}>
            <div className="shadow-card p-30 text-center">
              <div className="fs-40 fw-700">{getConversionRate()}</div>
              <div className="fs-16 fw-400">CONVERSION RATE</div>
            </div>
          </Col>
        </Row>
      </FloatingContainer>
    )
  }

  return (
    <AdminContent header={renderHeader()} body={renderContent()} headerHeight={72}/>
  );
}

export default SystemDashboardPage;

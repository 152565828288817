import React, { useEffect, useState, useContext } from "react";
import { Navigate, useLocation } from 'react-router-dom';
import AppContext from '../../app/context';
import useApi from '../../hooks/useApi';
import { getClientEvents, logout, refreshToken } from '../../api';
import { isEmpty, isUndefined, isArray } from "lodash";
import LoadingSpinner from "../../components/loading";
import emptyStateImage from '../../images/empty-state-icon.png';
import { getDeviceId } from '../../helpers/deviceHelper';
import useDocumentTitle from '../../hooks/useDocumentTitle';

const EventSelectionPage = () => {

  const [isLoading, setLoading] = useState(true)

  useDocumentTitle("Select Event")
  const { setCurrentEvent, auth, setAuth } = useContext(AppContext);
  const [sendRequest] = useApi()
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
    loadPage()
  }, []);

  const loadPage = async () => {
    try {

      // Temporary code until all tokens have event ids
      if (isUndefined(auth.user.event_id)) {
        // Check if the user has any events
        const clientEventsResults = await sendRequest(getClientEvents(auth.user.user_id))
        if (isArray(clientEventsResults) && clientEventsResults.length > 0) {
          // If so, refresh the token to populate the event id
          const response = await sendRequest(refreshToken({ user_id: auth.user.user_id, refresh_token: auth.refresh_token, source: "web", device_id: getDeviceId() }))
          setAuth(response)
        }
      }

      setLoading(false)
    } catch(error) {
      setLoading(false)
    }
  }

  const onLogout = async () => {
    try {
      if (auth.user) {
        await logout(auth.user.user_id, getDeviceId())
      }
      setAuth({})
      setCurrentEvent({})
    } catch (error) {
      console.log(error)
    }
  }

  if (!isUndefined(auth.user.event_id)) {
    const queryString = !isEmpty(location.search) ? location.search : ""
    return <Navigate replace to={`/client/home${queryString}`} />
  }

  if (isLoading) {
    return (
      <div className="mt-80">
        <LoadingSpinner/>
      </div>
    )
  }

  return (
    <div className="blank-layout">
      <div className="center-content-page">
        <div className="shadow-card p-30 text-center" style={{maxWidth: 500 }}>
          <img src={emptyStateImage} width="200" className="mt-20"/>
          <div className="fs-16 fw-700 mt-20">No events found!</div>
          <div className="fs-14 fw-500 mt-15 mb-10">It doesn't look like there are any events associated with your account. Please check with your DJ to make sure everything is setup correctly.</div>
          <button className="primary-button" onClick={() => onLogout()}>Got it!</button>
        </div>
      </div>
    </div>
  )
}

export default EventSelectionPage
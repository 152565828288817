import React, { useEffect, useContext, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { Row, Col, Collapse, Checkbox, Select } from 'antd';
import moment from "moment";
import AdminContent from '../../../components/adminContent';
import FloatingContainer from '../../../components/floatingContainer'
import { MdArrowForwardIos, MdOutlineInfo } from "react-icons/md";
import Permissions from '../../../constants/permissions';
import { hasPermission } from '../../../helpers/permissionHelper';
import AppContext from '../../../app/context';
import useApi from '../../../hooks/useApi';
import { getSpecialEventSongsReport } from '../../../api';
import useDocumentTitle from '../../../hooks/useDocumentTitle';
import useAccountSettings from '../../../hooks/useAccountSettings';
import { formatDateShort } from '../../../helpers/dateHelper';

const SpecialEventSongsReportPage = () => {

  const [specialEvents, setSpecialEvents] = useState([]);
  const [shouldHideEmpty, setShouldHideEmpty] = useState(true);
  const [filterKey, setFilterKey] = useState("all");

  useDocumentTitle("Special Event Songs | Report")
  const navigate = useNavigate();
  const [sendRequest] = useApi()
  const [accountSettings] = useAccountSettings()

  useEffect(() => {
    window.scrollTo(0, 0);
    loadPage()
  }, []);

  const loadPage = async () => {
    try {
      const results = await sendRequest(getSpecialEventSongsReport())
      setSpecialEvents(results)
    } catch {}
  }

  const renderHeader = () => {
    return (
      <Row align="middle" className="p-20">
        <Col flex={1}>
          <div>
            <span className="c-blue fw-700 cursor-default" onClick={() => navigate("/admin/reports")}>
              Reports
            </span>
            <span className="fs-10 mh-5"><MdArrowForwardIos/></span>
            <span className="cursor-default c-text-gray">
              Special Event Songs
            </span>
          </div>
        </Col>
      </Row>
    )
  }

  const renderSpecialEvent = (se,index) => {
    if (se.songs.length > 0 || (!shouldHideEmpty && se.songs.length == 0)) {
      return (
        <div className="b-border pb-15 mb-15" key={index}>
          <div className="fw-700 fs-16 pv-10 b-border pl-10">{se.special_event_name}</div>
          <Row className="b-border pv-5 ph-20 bg-gray">
            <Col xs={24} md={10}>
              <div className="fs-10 fw-700 c-text-gray">TITLE</div>
            </Col>
            <Col xs={24} md={10}>
              <div className="fs-10 fw-700 c-text-gray">ARTIST</div>
            </Col>
            <Col xs={24} md={4}>
              <div className="fs-10 fw-700 c-text-gray text-right">COUNT</div>
            </Col>
          </Row>
          { se.songs.map((x,i) => (
            <Row key={i} className={`ml-10 mr-15 p-10 flex-row ${i < se.songs.length - 1 ? "b-border" : ""}`}>
              <Col xs={24} md={10}>
                <div className="">{x.title}</div>
              </Col>
              <Col xs={24} md={10}>
                <div className="">{x.artist}</div>
              </Col>
              <Col xs={24} md={4}>
                <div className="text-right">{x.count}</div>
              </Col>
            </Row>
          ))}
          { se.songs.length == 0 && (
            <div className="f-italic ml-10 p-10 c-text-gray">No song selections</div>
          )}
        </div>
      )
    }
  }

  const renderContent = () => {
    return (
      <>
        <FloatingContainer className="ph-20 b-border" verticalPadding={30} maxWidth={1000}>
          {/* <div className="fw-700 mb-10">Filters</div> */}
          <Row gutter={[15,15]} align="bottom">
            {/* <Col xs={24} md={12}>
              <div className="fw-700 mb-5">Event Date Range</div>
              <div className="">
                <Select
                  style={{ width: 200 }}
                  placeholder={"Select"}
                  value={filterKey}
                  onChange={(value) => setFilterKey(value)}
                >
                  <Select.Option value="all">All</Select.Option>
                  <Select.Option value="this-month">This Month</Select.Option>
                  <Select.Option value="last-month">Last Month</Select.Option>
                  <Select.Option value="this-year">This Year</Select.Option>
                  <Select.Option value="last-year">Last Year</Select.Option>
                </Select>
              </div>
            </Col> */}
            <Col xs={24} md={12}>
              <Checkbox checked={shouldHideEmpty} onChange={(e) => setShouldHideEmpty(e.target.checked)}>Hide empty sections</Checkbox>
            </Col>
          </Row>
        </FloatingContainer>
        <FloatingContainer className="ph-20 bg-white" verticalPadding={30} maxWidth={1000}>
          <div className="fw-700 fs-24 c-blue">Special Event Songs</div>
          <div className="fs-14 c-text-gray mb-20">{formatDateShort(moment().format(), accountSettings)}</div>
          <div className="b-border pb-30 mb-20">This report shows a summary of all the songs your clients have selected for each special event across all events. This information can be useful to see if there are any trends in the type of music people are selecting.</div>
          { specialEvents.map((se,i) => renderSpecialEvent(se,i))}
        </FloatingContainer>
      </>
    )
  }

  return (
    <AdminContent body={renderContent()} header={renderHeader()}/>
  );
}

export default SpecialEventSongsReportPage;

import React, {useState, useEffect} from 'react';
import { useNavigate } from "react-router-dom";
import {useStripe} from '@stripe/react-stripe-js';
import checkmarkIcon from '../images/checkmark-icon.png';


const PaymentStatus = () => {
  const stripe = useStripe();
  const [title, setTitle] = useState(null);
  const [message, setMessage] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    if (!stripe) {
      return;
    }

    // Retrieve the "setup_intent_client_secret" query parameter appended to
    // your return_url by Stripe.js
    const clientSecret = new URLSearchParams(window.location.search).get(
      'setup_intent_client_secret'
    );

    // Retrieve the SetupIntent
    stripe
      .retrieveSetupIntent(clientSecret)
      .then(({setupIntent}) => {
        // Inspect the SetupIntent `status` to indicate the status of the payment
        // to your customer.
        //
        // Some payment methods will [immediately succeed or fail][0] upon
        // confirmation, while others will first enter a `processing` state.
        //
        // [0]: https://stripe.com/docs/payments/payment-methods#payment-notification
        switch (setupIntent.status) {
          case 'succeeded':
            setTitle("Success!")
            setMessage('Your payment method has been saved.');
            break;

          case 'processing':
            setTitle("Processing!")
            setMessage("Your payment method is processing. We'll update you when it is complete.");
            break;

          case 'requires_payment_method':
            // Redirect your user back to your payment page to attempt collecting
            // payment again
            setTitle("Error!")
            setMessage("Failed to process payment details. Please try another payment method.");
            break;
        }
      });
  }, [stripe]);


  return (
    <div className="shadow-card ph-20 pv-50 text-center">
      <div>
        <img src={checkmarkIcon} width={300}/>
      </div>
      <div className="fs-18 fw-700 mt-30">
        {title}
      </div>
      <div className="fs-14 fw-500 c-text-gray">
        {message}
      </div>
      <button className="primary-button mt-30" style={{ width: 200 }} onClick={() => navigate("/admin/account")}>Go to Account</button>
    </div>
  )
};

export default PaymentStatus;
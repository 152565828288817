import React, { useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import AdminContent from '../../components/adminContent';
import FloatingContainer from '../../components/floatingContainer'
import { MdOutlineLock} from "react-icons/md";
import useDocumentTitle from '../../hooks/useDocumentTitle';

const AdminRestrictedPage = () => {

  useDocumentTitle("Restricted")
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const renderContent = () => {
    return (
      <FloatingContainer className="ph-20 bg-white text-center" verticalPadding={100} maxWidth={800}>
        <MdOutlineLock size={60} color={"#CCC"}/>
        <div className="fw-700 fs-20 mt-20">You don't have permission to view this page</div>
        <div className="fw-500 c-text-gray">Please reach out to the account owner to request permission.</div>
        <button className="small-primary-button mt-20" onClick={() => navigate("/admin/dashboard")}>Go to Dashboard</button>
      </FloatingContainer>
    )
  }

  return (
    <AdminContent body={renderContent()}/>
  );
}

export default AdminRestrictedPage;

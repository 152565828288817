import React from "react";
import { Document, Page, Text, View, StyleSheet, Font } from '@react-pdf/renderer';
import { isArray, isEmpty } from "lodash";
import openSansRegular from '../fonts/OpenSans/OpenSans-Regular.ttf'
import openSansItalic from '../fonts/OpenSans/OpenSans-Italic.ttf'
import openSansBold from '../fonts/OpenSans/OpenSans-Bold.ttf'
import openSansBoldItalic from '../fonts/OpenSans/OpenSans-BoldItalic.ttf'
import { formatEventDateLong, formatTime } from '../helpers/dateHelper';
import { getFormattedPhoneNumber } from '../helpers/addressHelper';

Font.register({ family: 'OpenSans', fonts: [
  { src: openSansRegular },
  { src: openSansItalic, fontStyle: 'italic' },
  { src: openSansBold, fontWeight: 700 },
  { src: openSansBoldItalic, fontStyle: 'italic', fontWeight: 700 },
 ]});

const EventsByYearReportPDF = (props) => {
  const { data = [] } = props
  console.log(data)

  const styles = StyleSheet.create({
    page: {
      paddingTop: 35,
      paddingBottom: 65,
      paddingHorizontal: 35
    },
    row: {
      flexDirection: 'row'
    },
    col: {
      flex: 1
    },
    section: {
      marginBottom: 20
    },
    sectionContainer: {
      marginBottom: 20
    },
    sectionHeader: {
      borderBottom: "3px solid #CCC",
      marginBottom: 10
    },
    sectionHeaderText: {
      fontSize: 12,
      fontFamily: "OpenSans",
      fontWeight: 700,
      paddingHorizontal: 5,
      paddingBottom: 5
    },
    sectionSubHeader: {
      marginBottom: 10,
      backgroundColor: "#F7F7F7"
    },
    sectionSubHeaderText: {
      fontSize: 10,
      fontFamily: "OpenSans",
      fontWeight: 700,
      paddingHorizontal: 10,
      paddingVertical: 5
    },
    header: {
      marginBottom: 20
    },
    headerText: {
      fontSize: 12,
      fontFamily: "OpenSans",
      fontWeight: 700,
      textAlign: "center"
    },
    subTitleText: {
      fontSize: 12,
      fontFamily: "OpenSans",
      fontWeight: 700,
      textAlign: "center",
      marginTop: 10
    },
    dateText: {
      fontSize: 10,
      fontFamily: "OpenSans",
      textAlign: "center"
    },
    specialEventRow: {
      borderBottom: "1px solid #e8e8e8",
      marginLeft: 15,
      paddingVertical: 10
    },
    specialEventName: {
      fontSize: 10,
      fontFamily: "OpenSans",
      fontWeight: 700,
      paddingHorizontal: 5,
      marginBottom: 2
    },
    songText: {
      fontSize: 10,
      fontFamily: "OpenSans",
      paddingHorizontal: 5,
      paddingTop: 2
    },
    songTitle: {
      fontSize: 10,
      fontFamily: "OpenSans",
      color: "#777777"
    },
    notesText: {
      fontSize: 8,
      fontFamily: "OpenSans",
      backgroundColor: "#F7F7F7",
      paddingVertical: 4,
      paddingHorizontal: 5,
      marginLeft: 5,
      marginTop: 3,
      borderRadius: 4
    },
    genreText: {
      fontSize: 10,
      fontFamily: "OpenSans",
      paddingHorizontal: 5,
      marginTop: 4
    },
    emptyText: {
      fontSize: 10,
      fontFamily: "OpenSans",
      color: "#777777",
      paddingHorizontal: 5,
      paddingBottom: 15
    },
    labelText: {
      fontSize: 10,
      fontFamily: "OpenSans",
      fontWeight: 700
    },
    dataText: {
      fontSize: 10,
      fontFamily: "OpenSans",
    },
  });

  const renderLabelItem = (label, data) => {
    return (
      <View style={{ paddingLeft: 10, marginBottom: 10 }}>
        <Text style={styles.labelText}>{label}</Text>
        <Text style={styles.dataText}>{data}</Text>
      </View>
    )
  }

  const renderData = () => {
    // const internalNotes = event.account_details ? event.account_details.internal_notes : ""
    // const lockdownDays = event.account_details ? event.account_details.lockdown_days : ""
    return data.map(x => (
      <View style={styles.section}>
        <View style={styles.sectionHeader}>
          <Text style={styles.sectionHeaderText}>{x.label}</Text>
        </View>
        { x.events.map(e => (
          <View style={styles.row}>
            <View style={styles.col}>
              <Text style={styles.dataText}>{e.event_date}</Text>
              {/* { renderLabelItem("Event Name", event.event_name) }
              { renderLabelItem("Event Date", getEventDate()) }
              { renderLabelItem("Lockdown Days", `${lockdownDays} days before the event`) } */}
            </View>
            <View style={styles.col}>
              <Text style={styles.dataText}>{e.event_type_name}</Text>
              {/* { renderLabelItem("Event Type", event.account_event_type_name) }
              { renderLabelItem("Start / End Time", getStartEndTime()) } */}
            </View>
            <View style={styles.col}>
              <Text style={styles.dataText}>{e.event_name}</Text>
              {/* { renderLabelItem("Event Type", event.account_event_type_name) }
              { renderLabelItem("Start / End Time", getStartEndTime()) } */}
            </View>
        </View>
        ))}
        <View style={styles.row}>
          <View style={styles.col}>
            {/* { renderLabelItem("Event Name", event.event_name) }
            { renderLabelItem("Event Date", getEventDate()) }
            { renderLabelItem("Lockdown Days", `${lockdownDays} days before the event`) } */}
          </View>
          <View style={styles.col}>
            {/* { renderLabelItem("Event Type", event.account_event_type_name) }
            { renderLabelItem("Start / End Time", getStartEndTime()) } */}
          </View>
        </View>
        {/* { renderLabelItem("Internal Notes", internalNotes) } */}
      </View>
    ))
    return (
      <View style={styles.section}>
        <View style={styles.sectionHeader}>
          <Text style={styles.sectionHeaderText}>Overview</Text>
        </View>
        <View style={styles.row}>
          <View style={styles.col}>
            {/* { renderLabelItem("Event Name", event.event_name) }
            { renderLabelItem("Event Date", getEventDate()) }
            { renderLabelItem("Lockdown Days", `${lockdownDays} days before the event`) } */}
          </View>
          <View style={styles.col}>
            {/* { renderLabelItem("Event Type", event.account_event_type_name) }
            { renderLabelItem("Start / End Time", getStartEndTime()) } */}
          </View>
        </View>
        {/* { renderLabelItem("Internal Notes", internalNotes) } */}
      </View>
    )
  }

  const renderDocument = () => {
    return (
      <Document>
        <Page size="A4" style={styles.page}>
          <View style={styles.header}>
            <Text style={styles.headerText}>Events by Year</Text>
            <Text style={styles.dateText}>Monday</Text>
            <Text style={styles.subTitleText}>Event by Year</Text>
          </View>
          { renderData() }
        </Page>
      </Document>
    )
  }

  return renderDocument()
}

export default EventsByYearReportPDF;

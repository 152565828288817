import { useState, useEffect } from "react";
import StorageKeys from '../constants/storageKeys';
import DateFormatTypes from '../constants/dateFormatTypes';
import TimeFormatTypes from '../constants/timeFormatTypes';
import { isEmpty } from "lodash";
import useApi from './useApi'
import { getAccountSettings } from '../api'

export default function useAccountSettings() {

  const defaultState = { date: DateFormatTypes.MDY, time: TimeFormatTypes.HOUR_12, holding_fee_term: "Deposit", calendar_start_day: "Sunday", time_off_approval: "MANUAL" }
  const [accountSettings, setActualAccountSettings] = useState(defaultState)

  const [sendRequest] = useApi()

  useEffect(() => {
    const settings = JSON.parse(localStorage.getItem(StorageKeys.ACCOUNT_SETTINGS)) || {}
    if (isEmpty(settings)) {
      populateAccountSettings()
    } else {
      setActualAccountSettings(settings)
    }
  }, []);

  const populateAccountSettings = async () => {
    try {
      const response = await sendRequest(getAccountSettings())
      setAccountSettings(response)
    } catch {}
  }

  const setAccountSettings = (settings) => {
    if (isEmpty(settings)) {
      localStorage.removeItem(StorageKeys.ACCOUNT_SETTINGS);
    } else {
      localStorage.setItem(StorageKeys.ACCOUNT_SETTINGS, JSON.stringify(settings));
    }
    setActualAccountSettings(settings)
  }

  const retrieveAccountSettings = async () => {
    try {
      const settings = JSON.parse(localStorage.getItem(StorageKeys.ACCOUNT_SETTINGS)) || {}
      if (isEmpty(settings)) {
        const response = await sendRequest(getAccountSettings())
        localStorage.setItem(StorageKeys.ACCOUNT_SETTINGS, JSON.stringify(response));
        return response
      } else {
        return settings
      }
    } catch {
      return {}
    }
  }

  return [accountSettings, setAccountSettings, retrieveAccountSettings];
}
import React, { useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { Row, Col, Image } from 'antd';
import { BackToHomeLink, DocLink } from '../../../components/docComponents'
import AdminContent from '../../../components/adminContent';
import FloatingContainer from '../../../components/floatingContainer'
import specialEventsTemplateImage from '../../../images/special-events-template-example.png';
import clientSpecialEventTemplateImage from '../../../images/client-special-event-template.png';
import clientSpecialEventsImage from '../../../images/client-special-events.png';
import useDocumentTitle from '../../../hooks/useDocumentTitle';

const DocsEventTemplatesPage = () => {

  useDocumentTitle("Event Templates | Documentation")
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const renderContent = () => {
    return (
      <FloatingContainer className="ph-20 bg-white" verticalPadding={20} maxWidth={1000}>
        <BackToHomeLink/>
        <div className="fw-700 fs-24 mt-20">Event Templates</div>
        <div className="fw-500 fs-16 c-text-gray">Create templates to automatically populate default values for new events.</div>
        <div className="b-border mv-20"></div>
        <div className="fw-700 fs-18">Overview</div>
        <div className="fw-500 mb-15 line-1-4 mt-15 bg-gray p-15 radius-8">
          An event template is a way for you to streamline the process for creating events. If there is specific information you find yourself entering every time you create an event, you can save time by creating a template and using it to populate default values for new events. You have the ability to create as many templates as you need based on the types of events your company offers.
        </div>
        <div className="b-border mv-20"></div>
        <div className="fw-700 fs-18 mb-15">Creating a template</div>
        <div className="mb-15">
          <ol>
            <li className="mb-15">
              Go to the <DocLink to="/admin/event-templates">Event Templates</DocLink> page and select <b>"New Template"</b>.
            </li>
            <li className="mb-15">
              Enter a <b>Template Name</b>.
            </li>
            <li className="mb-15">
              Start entering default values for your template.
              <div className="bg-gray pv-10 ph-15 radius-8 mt-10"><b>Note:</b> All of these fields are <b>OPTIONAL</b>, therefore you are not required to fill in every detail. Only the information you enter will automatically populate when creating a new event.</div>
              <ul>
                <li className="mb-15 mt-15">
                  <b>Event Details</b> 
                  <br/>
                  Enter specific details about the event such as event type and times.
                </li>
                <li className="mb-15 mt-15">
                  <b>Venue</b> 
                  <br/>
                  Select an option here if you have a reoccuring event at the same venue.
                </li>
                <li className="mb-15 mt-15">
                  <b>Assigned Staff</b> 
                  <br/>
                  Add any assigned staff members along with their role and wage for the event.
                </li>
                <li className="mb-15 mt-15">
                  <b>Packages & Add-Ons</b> 
                  <br/>
                  Select any packages and/or add-ons you might normally add to a specific type of event.
                </li>
                <li className="mb-15 mt-15">
                  <b>Financials</b> 
                  <br/>
                  Enter a booking fee that might coorespond to the packages and add-ons you selected.
                </li>
                <li className="mb-15 mt-15">
                  <b>Special Event Template</b> 
                  <br/>
                  Select a special event template you would typically assign to this type of event.
                </li>
                <li className="mb-15 mt-15">
                  <b>Playlists</b> 
                  <br/>
                  Define a list of generic playlists the client will see as options when they select music. These will typically be items like "Must Play", "Play if Possible", and "Do Not Play".
                </li>
                <li className="mb-15 mt-15">
                  <b>Workflow</b> 
                  <br/>
                  Assign an optional workflow to the event.
                </li>
                <li className="mb-15 mt-15">
                  <b>Questionnaires</b> 
                  <br/>
                  Select one or more questionnaires you have previously setup.
                </li>
              </ul>
            </li>
            <li className="mb-15">
              Once you are finished, click the <b>Save</b> button.
            </li>
          </ol>
          <div className="ml-20 mt-20">
            <button className="small-primary-button mb-5" onClick={() => navigate("/admin/event-templates")}>Go to Event Templates</button>
          </div>
        </div>

        <div className="b-border mv-20"></div>
        <div className="fw-700 fs-18 mb-15">Assigning a template</div>
        <div className="mb-15">
          <ol>
            <li className="mb-15">
              Go to the <DocLink to="/admin/events">Events</DocLink> page and select <b>"New Event"</b>.
            </li>
            <li className="mb-15">
              Under the <b>Event Template</b> section at the top of the page, select a template from the list.
            </li>
            <li className="mb-15">
              Select the <b>"Apply Template"</b> button and confirm you would like to proceed.
            </li>
            <li className="mb-15">
              Verify all the information that was automatically populated is correct and fill in the remaining fields.
            </li>
            <li className="mb-15">
              Once you are finished, click the <b>Save</b> button.
            </li>
          </ol>
          <div className="ml-20 mt-20">
            <button className="small-primary-button mb-5" onClick={() => navigate("/admin/events")}>Go to Events</button>
          </div>
        </div>
      </FloatingContainer>
    )
  }

  return (
    <AdminContent body={renderContent()}/>
  );
}

export default DocsEventTemplatesPage;

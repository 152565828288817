import React, { useEffect, useState, useContext } from "react";
import { Form } from 'antd';
import useApi from '../../hooks/useApi';
import { useNavigate } from 'react-router-dom';
import AppContext from '../../app/context';

const PastDuePage = (props) => {

  const [isLoading, setLoading] = useState(false);

  const { auth, setAuth } = useContext(AppContext);
  const navigate = useNavigate();

  const [sendRequest] = useApi()

  useEffect(() => {
    window.scrollTo(0, 0);
    refreshPage()
  }, []);

  const refreshPage = () => {

  }

  const renderPage = () => {
    return (
      <div className="center-content-box">
        <div className="center-content-box--title">Account Past Due!</div>
        <div className="center-content-box--subtitle">Your recent subscription payment was not successful. Please update your payment method to continue using DJ Planning Center.</div>
        <div className="center-content-box--form">
          <Form layout="vertical" name="admin-login" onFinish={() => {}}>
            <button className="primary-button" onClick={() => navigate("/admin/payment-method?type=past-due")}>Update Payment Method</button>
            {/* <div className="center-content-box--link-container">
              <Link to="/login" className="center-content-box--link">Sign In</Link>
            </div> */}
          </Form>
        </div>
      </div>
    )
  }

  const renderContent = () => {
    // if (!loading && isEmpty(email)) {
    //   return <Navigate to="/login"/>
    // } else {
      return renderPage()
    // }
  }

  return (
    <div className="center-content-page">
      { renderContent() }
    </div>
  );
}

export default PastDuePage;

import React, { useEffect, useState } from "react";
import { Row, Col, Table } from 'antd';
import { useNavigate, useLocation, Outlet } from 'react-router';
import useApi from '../../hooks/useApi';
import { getQuestionnaireTemplates } from '../../api';
import LoadingSpinner from '../../components/loading';
import AdminContent from '../../components/adminContent';
import { FiCheck } from "react-icons/fi";

const QuestionnaireTemplatePage = (props) => {

  const [isLoading, setLoading] = useState(true);
  const [templates, setTemplates] = useState([]);

  const navigate = useNavigate();
  const [sendRequest] = useApi()

  const columns = [
    {
      title: 'Template Name',
      dataIndex: 'questionnaire_name',
      key: 'questionnaire_name',
      render: (text) => text
    },
    {
      title: 'Sections',
      dataIndex: 'sections',
      key: 'sections',
      render: (text, record) => record.sections.length
    },
    {
      title: 'Active',
      dataIndex: 'active',
      key: 'active',
      width: "100px",
      render: (text, record) => text ? <div className="text-center"><FiCheck/></div> : ""
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
    refreshPage()
  }, []);

  const refreshPage = () => {
    sendRequest(getQuestionnaireTemplates()).then(response => {
      setTemplates(response);
      setLoading(false)
    }).catch((e) => setLoading(false))
  }

  const renderHeader = () => {
    return (
      <Row align="middle" className="p-20">
        <Col flex={1}>
          <div className="admin-page-title">Questionnaire Templates</div>
        </Col>
        <Col>
          <button className="page-title-button" onClick={() => navigate("/system/questionnaire-templates/new")}>New</button>
        </Col>
      </Row>
    )
  }

  const renderContent = () => {

    const data = templates.map((x, i) => {
      return {
        ...x,
        key: i
      }
    });

    return (
      <div className="p-15" style={{ maxWidth: 800 }}>
        <div className="admin-page-content-box">
          <Table 
            columns={columns} 
            dataSource={data}
            pagination={false}
            onRow={(record, rowIndex) => {
              return {
                onClick: e => navigate(`/system/questionnaire-templates/${record.questionnaire_template_id}/edit`)
              };
            }}
           />
        </div>
      </div>
    )
  }

  if (isLoading) {
    return <LoadingSpinner/>
  }

  return (
    <AdminContent header={renderHeader()} body={renderContent()}/>
  );
}

export default QuestionnaireTemplatePage;

import React, { useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { BackToHomeLink, DocLink } from '../../../components/docComponents'
import AdminContent from '../../../components/adminContent';
import FloatingContainer from '../../../components/floatingContainer'
import useDocumentTitle from '../../../hooks/useDocumentTitle';

const DocsBookingRequestsPage = () => {

  useDocumentTitle("Booking Requests | Documentation")
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const renderContent = () => {
    return (
      <FloatingContainer className="ph-20 bg-white" verticalPadding={20} maxWidth={1000}>
        <BackToHomeLink/>
        <div className="fw-700 fs-24 mt-20">Booking Requests</div>
        <div className="fw-500 fs-16 c-text-gray">View responses to your pricing guides.</div>
        <div className="b-border mv-20"></div>
        <div className="fw-700 fs-18">Overview</div>
        <div className="fw-500 mb-15 line-1-4 mt-15">
          A booking request is created when someone submits the contact form on one of your pricing guides. In addition to an email, you will also see the booking request populate in the app.
        </div>
        <div className="b-border mv-20"></div>
        <div className="fw-700 fs-18 mb-15">Sending a pricing guide</div>
        <ol>
          <li className="mb-15">
            View the docs for <DocLink to="/admin/docs/pricing-guides">Pricing Guides</DocLink> to learn how to create and send a pricing guide.
            <div className="bg-gray pv-10 ph-15 radius-8 mt-10"><b>Note:</b> Make sure "Display contact form" is selected on the pricing guide in order for potential clients to submit a booking request.</div>
          </li>
        </ol>
        <div className="b-border mv-20"></div>
        <div className="fw-700 fs-18 mb-15">Viewing a booking request</div>
        <ol>
          <li className="mb-15">
            Go to the <DocLink to="/admin/booking-requests">Booking Requests</DocLink> page to view any incoming requests.
          </li>
          <li className="mb-15">
            Select a request from the list to see it's details.
          </li>
          <li className="mb-15">
            On the details page, you will see the client's name, email, and any packages or add-ons they are interested in booking.
          </li>
          <li className="mb-15">
            You can select the <b>Create Event</b> button to automatically populate the details on the <b>New Event</b> page.
          </li>
        </ol>
        <div className="ml-20 mt-20">
          <button className="small-primary-button mb-5" onClick={() => navigate("/admin/booking-requests")}>Go to booking requests</button>
        </div>
        <div className="b-border mv-50"></div>
      </FloatingContainer>
    )
  }

  return (
    <AdminContent body={renderContent()}/>
  );
}

export default DocsBookingRequestsPage;

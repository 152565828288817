import React from 'react';
import { Row, Col, Input, Modal, Button, Select } from 'antd';
import { isUndefined, cloneDeep } from 'lodash';
import { MdOutlineClose } from "react-icons/md";
import { FiMail, FiMessageCircle } from "react-icons/fi";
import { BsCheckSquare } from "react-icons/bs";
import { 
  renderFormLabel,
} from './formFields'
import { getActionTypeDescription, getTimePeriodDescription } from '../helpers/workflowHelper';

const WorkflowTaskModal = props => {
  const { title = "New Step", isVisible = false, setVisible = () => {}, selectedStep, setSelectedStep = () => {}, handleSave = () => {}, employees = []} = props;

  const getMetadata = (key) => {
    if (!isUndefined(selectedStep.metadata) && !isUndefined(selectedStep.metadata[key])) {
      return selectedStep.metadata[key]
    }
    return "";
  }

  const setMetadata = (key, value) => {
    if (!isUndefined(selectedStep.metadata)) {
      const newSelectedStep = cloneDeep(selectedStep)
      newSelectedStep.metadata[key] = value
      setSelectedStep(newSelectedStep)
    }
  }

  const renderIcon = (type) => {
    if (type === "email") {
      return <FiMail/>;
    } else if (type === "text") {
      return <FiMessageCircle/>
    } else if (type === "todo") {
      return <BsCheckSquare/>
    }
    return null;
  }

  const renderActionType = (value) => {
    const styleClass = selectedStep.workflow_step_type == value ? "bg-blue c-white" : "bg-gray"
    return (
      <Col xs={8}>
        <div className={`p-15 text-center radius-8 cursor-default ${styleClass}`} onClick={() => setSelectedStep({ ...selectedStep, workflow_step_type: value})}>
          <div className="fs-24">
            { renderIcon(value) }
          </div>
          { getActionTypeDescription(value)}
        </div>
      </Col>
    )
  }

  const renderActionTypes = () => {
    return (
      <Row gutter={[10,10]}>
        { renderActionType("email") }
        { renderActionType("text") }
        { renderActionType("todo") }
      </Row>
    )
  }

  const renderMessageField = () => {
    if (selectedStep.workflow_step_type != "email" && selectedStep.workflow_step_type != "text") {
      return null;
    }
    return (
      <Col xs={24}>
        { renderFormLabel("Message") }
        <Input.TextArea
            size="large" 
            rows={3}
            style={{ width: "100%" }}
            value={getMetadata("message")} 
            onChange={(e) => setMetadata("message", e.target.value)}/>
        <div className="bg-gray p-10 mt-10 fs-12 radius-8">
          <b>Note:</b> Add the tag <b>[client]</b> to automatically populate the client's name.
          <div className="mt-5">Ex: "Hey [client], I hope you are having a great day!"</div>
        </div>
      </Col>
    )
  }

  return (
    <Modal visible={isVisible} footer={null} onCancel={() => setVisible(false)} width={500} closable={false} maskClosable={false} wrapClassName="rounded-modal">
      <Row align="middle">
        <Col flex={1}>
          <div className="fw-700 fs-18">{title}</div>
        </Col>
        <Col>
          <div className="display-flex" onClick={() => setVisible(false)}><MdOutlineClose size={30} color={"#CCC"}/></div>
        </Col>
      </Row>
      <Row gutter={[10,10]} className="mt-20">
        <Col xs={24}>
          { renderFormLabel("Description") }
          <Input 
            size="large" 
            value={selectedStep.description} 
            onChange={(e) => setSelectedStep({ ...selectedStep, description: e.target.value})}/>
        </Col>
        <Col xs={24}>
          { renderFormLabel("Action Type") }
          { renderActionTypes() }
        </Col>
        { renderMessageField() }
        {/* <Col xs={24}>
          { renderFormLabel("Assigned To") }
          <Select
            style={{ width: '100%' }}
            placeholder={"Select"}
            size='large'
            value={selectedStep.assigned_to}
            onChange={(value) => setSelectedStep({ ...selectedStep, assigned_to: value})}
          >
            <Select.OptGroup label="Automatic">
              <Select.Option value={1}>Assigned Staff Member</Select.Option>
            </Select.OptGroup>
            <Select.OptGroup label="Staff Member">
              { employees.map((x,i) => (
                  <Select.Option value={x.user_id}>{ x.first_name } { x.last_name }</Select.Option>
              ))}
            </Select.OptGroup>
          </Select>
        </Col> */}
        <Col xs={24}>
          { renderFormLabel("When?") }
          <Row>
            <Col flex={0}>
              <Input 
                size="large" 
                style={{ width: 60 }}
                value={selectedStep.time_value} 
                onChange={(e) => setSelectedStep({ ...selectedStep, time_value: e.target.value})}/>
            </Col>
            <Col flex={0}>
              <Select
                style={{ width: 90 }}
                placeholder={"Select"}
                size='large'
                value={selectedStep.time_unit}
                onChange={(value) => setSelectedStep({ ...selectedStep, time_unit: value})}
              >
                <Select.Option value="days">days</Select.Option>
                <Select.Option value="weeks">weeks</Select.Option>
              </Select>
            </Col>
            <Col flex={1}>
              <Select
                style={{ width: '100%' }}
                placeholder={"Select"}
                size='large'
                value={selectedStep.time_period}
                onChange={(value) => setSelectedStep({ ...selectedStep, time_period: value})}
              >
                <Select.Option value="after_booking">{ getTimePeriodDescription("after_booking") }</Select.Option>
                <Select.Option value="before_event">{ getTimePeriodDescription("before_event") }</Select.Option>
                <Select.Option value="after_event">{ getTimePeriodDescription("after_event") }</Select.Option>
              </Select>
            </Col>
          </Row>
        </Col>
        <Col xs={24}>
          <div className="mt-20 text-right">
            <Button className="admin-small-button secondary" onClick={() => setVisible(false)}>Cancel</Button>
            <Button className="admin-small-button ml-10" onClick={handleSave}>
              Save
            </Button>
          </div>
        </Col>
      </Row>
    </Modal>
  );
};

export default WorkflowTaskModal;

import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { cloneDeep, isEmpty } from "lodash";
import { Row, Col, Grid, Progress } from 'antd';
import useApi from '../../../hooks/useApi';
import useDocumentTitle from '../../../hooks/useDocumentTitle';
import AdminContent from '../../../components/adminContent';
import LoadingSpinner from '../../../components/loading';
import FloatingContainer from '../../../components/floatingContainer'
import OnboardingChecklistTypes from '../../../constants/onboardingChecklistTypes'
import { FiCheckCircle, FiCheck, FiCalendar } from "react-icons/fi";
import { MdArrowForwardIos } from "react-icons/md";
import { HiOutlineTemplate } from "react-icons/hi";
import { AiOutlineTag } from "react-icons/ai";
import {
  getOnboardingChecklist,
  updateOnboardingChecklist
} from '../../../api';

const OnboardingPage = () => {

  const [isLoading, setLoading] = useState(true);
  const [checklistItems, setChecklistItems] = useState([]);

  useDocumentTitle("Onboarding")
  const navigate = useNavigate();
  const [sendRequest] = useApi()

  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();

  useEffect(() => {
    window.scrollTo(0, 0);
    loadPage()
  }, []);

  const loadPage = async () => {
    try {
      const checklistResults = await sendRequest(getOnboardingChecklist());
      setChecklistItems(checklistResults.checklist)
    } catch {}
    finally {
      setLoading(false)
    }
  }

  const updateItem = async (type, complete) => {
    try {
      await sendRequest(updateOnboardingChecklist({ type: type, complete: complete }));
      await loadPage()
    } catch {}
  }

  const getChecklistItemStatus = (type) => {
    const foundItem = checklistItems.find(x => x.checklist_type == type)
    return !isEmpty(foundItem) ? foundItem.complete : false
  }

  const getCompletedItemCount = () => {
    return cloneDeep(checklistItems).filter(x => x.complete).length
  }

  const getProgressPercentage = () => {
    return Math.round((getCompletedItemCount() / checklistItems.length) * 100)
  }

  const renderHeader = () => {
    return (
      <div className="p-20">
        <div>
          <span className="c-blue fw-700 cursor-default" onClick={() => navigate("/admin/dashboard")}>
            Dashboard
          </span>
          <span className="fs-10 mh-5"><MdArrowForwardIos/></span>
          <span className="cursor-default c-text-gray">
            Getting Started
          </span>
        </div>
        <div className="fw-700 fs-24 mt-5">Getting Started</div>
      </div>
    )
  }

  const renderTaskSection = (title, text, link, buttonText = "Let's go", type, showDivider = true) => {
    const complete = getChecklistItemStatus(type)
    const checkColor = complete ? "#10ac84" : "#CCC"
    const completedTitle = complete ? "strikethrough c-text-gray" : ""
    return (
      <>
        { showDivider && (
          <div className="b-border mv-20"></div>
        )}
        <Row gutter={[15,15]} wrap={false}>
          <Col flex={0}>
            <FiCheckCircle size={24} color={checkColor}/>
          </Col>
          <Col flex={1}>
            <div className={`fw-700 fs-16 ${completedTitle}`}>{title}</div>
            <div className="">{text}</div>
            <Row align="middle" className="mt-10" gutter={[15]}>
              <Col>
                <button className="small-primary-button" onClick={() => navigate(link)}>{buttonText}</button>
              </Col>
              <Col>
                { !complete && !screens.lg && (
                  <div className="blue-link flex-row flex-1 flex-middle" onClick={() => updateItem(type, true)}><FiCheck style={{ marginRight: 5 }}/>Mark as complete</div>
                )}
              </Col>
            </Row>
          </Col>
          <Col flex={0}>
            { !complete && screens.lg && (
              <div className="blue-link flex-row flex-1 flex-middle" onClick={() => updateItem(type, true)}><FiCheck style={{ marginRight: 5 }}/>Mark as complete</div>
            )}
          </Col>
        </Row>
      </>
    )
  }

  const renderOptionalTaskSection = (title, text, buttonText, link, icon) => {
    return (
      <>
        <Row gutter={[15,15]} wrap={false} className="t-border mt-15 pt-15">
          <Col flex={0}>
            <div className="fs-24 c-blue">{icon}</div>
          </Col>
          <Col flex={1}>
            <div className={`fw-700 fs-16`}>{title}</div>
            <div className="">{text}</div>
            <button className="small-primary-button mt-10" onClick={() => navigate(link)}>{buttonText}</button>
          </Col>
        </Row>
      </>
    )
  }

  const renderContent = () => {
    if (isLoading) {
      return <LoadingSpinner/>
    }
    const progressColor = getProgressPercentage() == 100 ? "#00b894" : "#536DFE"
    return (
      <>
        <FloatingContainer className="ph-20 bg-gray" verticalPadding={30} maxWidth={1000}>
          <div className="shadow-card p-20">
            <div className="fw-700 fs-24">Welcome to DJ Planning Center! 👋</div>
            <div className="mt-10">We know learning a new platform can be challenging, so we want to make it as easy as possible. Let's walk through some steps to get your account setup so you can make the most of your experience. </div>
            <div className="t-border mt-15 pt-15">
              <div className="fw-700">Your Progress <span className="fw-500 c-text-gray">({getCompletedItemCount()}/{checklistItems.length})</span></div>
              <Progress percent={getProgressPercentage()} strokeColor={progressColor}/>
            </div>
            { renderTaskSection("Update your General Settings", 
              "Confirm your country, timezone, date format, currency, and more!", 
              "/admin/setup/general", 
              "Go to General Settings", 
              OnboardingChecklistTypes.GENERAL_SETTINGS)}
            { renderTaskSection("Upload your Company Logo", 
              "Customize your brand by adding a logo that will display on documents such as invoices and contracts.", 
              "/admin/setup/branding", 
              "Go to Branding",
              OnboardingChecklistTypes.COMPANY_LOGO)}
            { renderTaskSection("Customize your Event Settings", 
              "Customize the list of event types your company offers as well as the available roles you plan on assigning to clients and staff members.", 
              "/admin/setup/event-settings", "Go to Event Settings", 
              OnboardingChecklistTypes.EVENT_SETTINGS )}
            { renderTaskSection("Setup Special Event Templates", 
              "Special event templates are the primary way your clients select music. Make sure and read our documentation to learn more!", 
              "/admin/special-event-templates", 
              "Go to Templates", 
              OnboardingChecklistTypes.SPECIAL_EVENT_TEMPLATES)}
            { renderTaskSection("Create Packages & Add-Ons", 
              "Configure all of the services your company offers.", "/admin/packages", 
              "Go to Packages", 
              OnboardingChecklistTypes.SERVICES)}
            { renderTaskSection("Create Questionnaires", 
              "Create questionnaires to gather important information from your clients.", 
              "/admin/questionnaires", 
              "Go to Questionnaires", 
              OnboardingChecklistTypes.QUESTIONNAIRES)}
            { renderTaskSection("Setup Contract Templates", 
              "Create a contract template that will automatically populate with event details when you create a new event.", 
              "/admin/contract-templates", 
              "Go to Templates",
               OnboardingChecklistTypes.CONTRACT_TEMPLATES)}
            { renderTaskSection("Invite Staff Members", 
              "If you have multiple staff members within your company, create a profile for them, set permissions, and send them an invitation to DJ Planning Center. These staff members can then be assigned to events.", 
              "/admin/staff", 
              "Go to Staff",
               OnboardingChecklistTypes.STAFF)}
            { renderTaskSection("Expore our Documentation", 
              "Learn more about DJ Planning Center by eploring our feature documentation!", 
              "/admin/docs", 
              "Go to Docs",
               OnboardingChecklistTypes.DOCUMENTATION)}
          </div>
          <div className="t-border mv-20"></div>
          <div className="shadow-card p-20">
            <div className="fw-700 fs-24">Additional Setup</div>
            <div className="mt-10">After the main items above are complete, you can continue customizing your account by setting up the following items. These are all optional, however you might find them useful as you start integrating DJ Planning Center into your workflow.</div>
            { renderOptionalTaskSection("Setup Event Templates", 
              "Once your packages, add-ons, and various templates are setup, you can create Event Templates to speed up the process when creating a new event.", 
              "Go to Templates", 
              "/admin/event-templates",
              <HiOutlineTemplate/>)}
            { renderOptionalTaskSection("Create Pricing Guides", 
              "Create pricing guides so you can send potential clients information about your services. You can include an optional form clients can fill out if they are interested in booking.", 
              "Go to Pricing Guides", 
              "/admin/pricing-guides",
              <AiOutlineTag/>)}
            { renderOptionalTaskSection("Create Workflows", 
              "Create a series of tasks that will help you remember important touch points leading up to an event.", 
              "Go to Workflows", 
              "/admin/workflows",
              <FiCheckCircle/>)}
            { renderOptionalTaskSection("Setup iCal Links", 
              "Create an iCal link so you can subscribe to DJ Planning Center and display your events on your personal calendar.", 
              "Go to Calendar Settings", 
              "/admin/setup/calendar",
              <FiCalendar/>)}
          </div>
        </FloatingContainer>
      </>
    )
  }

  return (
    <AdminContent body={renderContent()} header={renderHeader()}/>
  );
}

export default OnboardingPage;

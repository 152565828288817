import React from 'react';
import { isArray } from "lodash";
import { Row, Col, Grid } from 'antd';
import { getNumericCurrency, formatCurrencyString } from '../helpers/contractHelper';

const InvoiceSummaryV2 = props => {
  const { invoice = {} } = props;

  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();

  const getDiscountText = (item) => {
    if (item.discount_type == "PERCENTAGE") {
      return `Discount applied (${item.discount_percentage})`
    } else {
      return "Discount applied"
    }
  }

  const renderSummaryHeader = () => {
    if (screens.md) {
      return (
        <Row align="middle" className="pv-15 t-border b-border bg-gray ph-15">
          <Col xs={12}>
            <div className="fs-12 fw-700">ITEM</div>
          </Col>
          <Col xs={4}>
            <div className="fs-12 fw-700 text-right">QTY</div>
          </Col>
          <Col xs={4}>
            <div className="fs-12 fw-700 text-right">PRICE</div>
          </Col>
          <Col xs={4}>
            <div className="fs-12 fw-700 text-right">AMOUNT</div>
          </Col>
        </Row> 
      )
    } else {
      return (
        <Row align="middle" className="pv-15 t-border b-border bg-gray ph-15">
          <Col xs={12}>
            <div className="fs-12 fw-700">ITEM</div>
            <div className="fs-12 fw-700">QTY / PRICE</div>
          </Col>
          <Col xs={12}>
            <div className="fs-12 fw-700 text-right">AMOUNT</div>
          </Col>
        </Row> 
      )
    }
  }

  const renderLineItemRow = (item, index) => {
    if (screens.md) {
      return (
        <Row className="pv-15 b-border ph-15" key={index}>
          <Col xs={12}>
            <div className="fs-14 fw-500">{item.name}</div>
            { item.discount && (
              <div className="fs-12 c-blue ml-10">{ getDiscountText(item) }</div>
            )}
          </Col>
          <Col xs={4}>
            <div className="fs-14 fw-500 text-right">{item.quantity}</div>
          </Col>
          <Col xs={4}>
            <div className="fs-14 fw-500 text-right">{formatCurrencyString(item.item_price, invoice.currency)}</div>
          </Col>
          <Col xs={4}>
            <div className="fs-14 fw-500 text-right">{ formatCurrencyString(item.total_price, invoice.currency) }</div>
            { item.discount && (
              <div className="fs-12 c-blue text-right">- {formatCurrencyString(item.total_discount, invoice.currency)}</div>
            )}
          </Col>
        </Row> 
      )
    } else {
      return (
        <Row className="pv-15 b-border ph-15" key={index}>
          <Col flex={1}>
            <div className="fs-14 fw-500">{item.name}</div>
            <div className="fs-14 fw-500">{item.quantity} x { formatCurrencyString(item.item_price, invoice.currency) }</div>
            { item.discount && (
              <div className="fs-12 c-blue ml-10">{ getDiscountText(item) }</div>
            )}
          </Col>
          <Col flex={0}>
            <div className="fs-14 fw-500 text-right">{ formatCurrencyString(item.total_price, invoice.currency) }</div>
            { item.discount && (
              <div className="fs-12 c-blue text-right">- {formatCurrencyString(item.total_discount, invoice.currency)}</div>
            )}
          </Col>
        </Row> 
      )
    }
  }

  const renderContent = () => {
    const hasTax = getNumericCurrency(invoice.tax) > 0
    return (
      <>
        { renderSummaryHeader() }
        { isArray(invoice.line_items) && invoice.line_items.map((item,index) => renderLineItemRow(item, index))}
        { hasTax && (
          <Row align="middle" className="pv-15 ph-15">
            <Col xs={16} md={20}>
              <div className="fs-14 fw-700 text-right">Subtotal:</div>
            </Col>
            <Col xs={8} md={4}>
              <div className="fs-14 fw-500 text-right">{formatCurrencyString(invoice.subtotal, invoice.currency)}</div>
            </Col>
          </Row> 
        )}
        { hasTax && (
          <Row align="middle" className="pb-15 ph-15">
            <Col xs={16} md={20}>
              <div className="fs-14 fw-700 text-right">Tax:</div>
            </Col>
            <Col xs={8} md={4}>
              <div className="fs-14 fw-500 text-right">{formatCurrencyString(invoice.tax, invoice.currency)}</div>
            </Col>
          </Row> 
        )}
        <Row align="middle" className="pv-15 t-border ph-15">
          <Col xs={16} md={20}>
            <div className="fs-14 fw-700 text-right">Total:</div>
          </Col>
          <Col xs={8} md={4}>
            <div className="fs-14 fw-500 text-right">{ formatCurrencyString(invoice.total, invoice.currency)}</div>
          </Col>
        </Row> 
        <Row align="middle" className="pb-15 b-border ph-15">
          <Col xs={16} md={20}>
            <div className="fs-14 fw-700 text-right">Payments:</div>
          </Col>
          <Col xs={8} md={4}>
            <div className="fs-14 fw-500 text-right">({ formatCurrencyString(invoice.payment_total, invoice.currency)})</div>
          </Col>
        </Row> 
        <Row align="middle" className="pv-15 b-border ph-15">
          <Col xs={16} md={20}>
            <div className="fs-14 fw-700 text-right">Balance:</div>
          </Col>
          <Col xs={8} md={4}>
            <div className="fs-14 fw-700 text-right">{formatCurrencyString(invoice.balance, invoice.currency)}</div>
          </Col>
        </Row>
      </>
    )
  }

  return renderContent()
};

export default InvoiceSummaryV2;

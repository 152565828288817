import React, { useEffect, useState } from "react";
import { Row, Col, Select, Modal, Form, Button } from 'antd';
import {SortableContainer, sortableElement} from 'react-sortable-hoc';
import arrayMove from 'array-move';
import { LexoRank } from "lexorank";
import { 
  getEventTypes, 
  getSystemSpecialEvents, 
  getSystemRecommendedPlaylists, 
  createSystemSpecialEvents, 
  updateSystemSpecialEvents 
} from '../../api';
import LoadingSpinner from '../../components/loading';
import AdminContent from '../../components/adminContent';
import useApi from '../../hooks/useApi';
import { 
  renderInputField,
  renderCheckboxField,
  renderSearchSelectField
} from '../../components/formFields'
import { EllipsisOutlined } from '@ant-design/icons';
import { MdDragIndicator } from "react-icons/md";
import { isEmpty, isUndefined, cloneDeep } from "lodash";


const SystemSpecialEventsPage = (props) => {

  const [isLoading, setLoading] = useState(true);
  const [specialEvents, setSpecialEvents] = useState([]);
  const [recommendedPlaylists, setRecommendedPlaylists] = useState([]);
  const [eventTypes, setEventTypes] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState({});
  const [selectedEventType, setSelectedEventType] = useState();

  const [form] = Form.useForm();
  const [sendRequest] = useApi()

  useEffect(() => {
    window.scrollTo(0, 0);
    refreshPage()
  }, []);

  const refreshPage = () => {
    sendRequest(getEventTypes()).then(response => {
      const eventTypeData = response.map((eventType) => {
        return {
          value: eventType.system_event_type_id,
          text: eventType.event_type_name
        }
      })
      setEventTypes(eventTypeData);
      setLoading(false)
    }).catch((e) => {
      setLoading(false)
    })

    sendRequest(getSystemRecommendedPlaylists()).then(response => {
      setRecommendedPlaylists(response);
    }).catch((e) => setLoading(false))
  }

  const onEventTypeChange = (eventType) => {
    setSelectedEventType(eventType)
    sendRequest(getSystemSpecialEvents(eventType)).then(response => {
      setSpecialEvents(response)
    })
  }

  const onNewSpecialEvent = () => {
    setSelectedRecord({})
    form.resetFields()
    setIsModalVisible(true)
  }

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const onSelectRow = event => {
    setSelectedRecord(event)
    form.setFieldsValue(event)
    setIsModalVisible(true);
  }

  const getNextRank = () => {
    const newSpecialEvents = cloneDeep(specialEvents);
    if (newSpecialEvents.length == 0) {
      return LexoRank.middle().toString();
    } else {
      const lastRank = LexoRank.parse(newSpecialEvents[newSpecialEvents.length - 1].rank)
      return lastRank.genNext().toString()
    }
  }

  const onSubmit = values => {

    const body = {
      ...values,
      rank: isEmpty(selectedRecord) ? getNextRank() : selectedRecord.rank,
      system_event_type_id: selectedEventType,
      allow_song_selection: !isUndefined(values.allow_song_selection) ? values.allow_song_selection : false,
      include_as_timeline_option: !isUndefined(values.include_as_timeline_option) ? values.include_as_timeline_option : false,
      playlist: !isUndefined(values.playlist) ? values.playlist : false
    }

    // New Special Event
    if (isEmpty(selectedRecord)) {
      sendRequest(createSystemSpecialEvents(body)).then(response => {
        setIsModalVisible(false);
        onEventTypeChange(selectedEventType)
      })
    } else {
      // Existing Special Event
      sendRequest(updateSystemSpecialEvents(selectedRecord.system_special_event_id, body)).then(response => {
        setIsModalVisible(false);
        onEventTypeChange(selectedEventType)
      })
    }
  }

  const onSortEnd = (oldIndex, newIndex) => {
    var newSpecialEvents = cloneDeep(specialEvents)
    if (oldIndex !== newIndex) {
      const sortedSpecialEvents = arrayMove(newSpecialEvents, oldIndex, newIndex).filter(el => !!el);
      setSpecialEvents(sortedSpecialEvents)

      var rank = "";
      const lastIndex = sortedSpecialEvents.length - 1;
      if (newIndex == 0 && lastIndex > 0) {
        const rankAfter = LexoRank.parse(sortedSpecialEvents[newIndex + 1].rank)
        rank = rankAfter.genPrev().toString()
      } else if (newIndex == lastIndex && lastIndex > 0) {
        const rankBefore = LexoRank.parse(sortedSpecialEvents[lastIndex - 1].rank)
        rank = rankBefore.genNext().toString()
      } else {
        const rankBefore = LexoRank.parse(sortedSpecialEvents[newIndex - 1].rank)
        const rankAfter = LexoRank.parse(sortedSpecialEvents[newIndex + 1].rank)
        rank = rankBefore.between(rankAfter).toString()
      }

      const currentSpecialEvent = sortedSpecialEvents[newIndex]
      const body = {
        ...currentSpecialEvent,
        rank: rank
      }

      sendRequest(updateSystemSpecialEvents(currentSpecialEvent.system_special_event_id, body)).then(response => {
        onEventTypeChange(selectedEventType)
      })
    }
  };

  const getRecommendedPlaylistOptions = () => {
    return recommendedPlaylists.map(playlist => {
      return {
        value: playlist.system_recommended_playlist_id,
        text: playlist.playlist_name
      }
    })
  }

  const renderSpecialEvent = (value, index) => {
    return (
      <Row align="middle" gutter={[15,15]} key={index} className="shadow-card ph-20 pv-15 mb-5">
        <Col flex={1}>
          <div className="fs-14 fw-700 line-1-2">{value.special_event_name}</div>
        </Col>
        <Col>
          <div className="display-flex" onClick={() => onSelectRow(value)}>
            <div className="admin-icon-circle"><EllipsisOutlined/></div>
          </div>
        </Col>
        <Col>
          <div className="display-flex">
            <MdDragIndicator style={{ fontSize: 24, color: '#CCC'}}/>
          </div>
        </Col>
      </Row>
    )
  }

  const SortableItem = sortableElement(({value, itemIndex}) => (
    <li className="">
      {renderSpecialEvent(value, itemIndex)}
    </li>
  ));

  const SortableList = SortableContainer(({items}) => {
    return (
      <ul className="question-row-container">
        {items.map((value, index) => (
          <SortableItem key={`item-${index}`} index={index} itemIndex={index} value={value} />
        ))}
      </ul>
    );
  });

  const renderModal = () => {
    return (
      <Modal title={selectedRecord.special_event_name} visible={isModalVisible} footer={null} onCancel={handleCancel} wrapClassName="rounded-modal">
        <Form form={form} layout="vertical" name="specialEvent" onFinish={onSubmit}>
          <Row gutter={[10,10]}>
            <Col xs={24}>
              {renderInputField("Event Name", "special_event_name", true)}
            </Col>
            <Col xs={24}>
              {renderCheckboxField("Include as Timeline Option", "include_as_timeline_option")}
            </Col>
            <Col xs={24}>
              {renderCheckboxField("Allow Song Selection", "allow_song_selection")}
            </Col>
            <Col xs={24}>
              {renderCheckboxField("Playlist", "playlist")}
            </Col>
            <Col xs={24}>
              {renderInputField("Song Limit", "song_limit", false)}
            </Col>
            <Col xs={24}>
              {renderInputField("Category", "category", false)}
            </Col>
            <Col xs={24}>
              {renderSearchSelectField("Recommended Playlist", "system_recommended_playlist_id", "Select Playlist", getRecommendedPlaylistOptions())}
            </Col>
          </Row>
          <div className="admin-modal-footer">
            <Button className="admin-small-button secondary" onClick={handleCancel}>Cancel</Button>
            <Button className="admin-small-button ml-10" htmlType="submit">
              Save
            </Button>
          </div>
        </Form>
      </Modal>
    )
  }

  const renderHeader = () => {
    return (
      <Row align="middle" className="p-20">
        <Col flex={1}>
          <div className="admin-page-title">Special Events</div>
        </Col>
        <Col>
          <button className="page-title-button" onClick={() => onNewSpecialEvent()}>New</button>
        </Col>
      </Row>
    )
  }

  const renderSelectOptions = (options) => {
    if (options) {
      return options.map((option) => (
        <Select.Option value={option.value} key={option.value}>{option.text}</Select.Option>
      ))
    }
    return null;
  }

  const renderEventTypeDropdown = () => {
    return (
      <div className="mb-30">
        <Select
          placeholder={"Select Event Type"}
          size='large'
          style={{ width: "100%"}}
          onChange={(e) => onEventTypeChange(e)}
        >
          {renderSelectOptions(eventTypes)}
        </Select>
      </div>
    )
  }

  const renderContent = () => {
    return (
      <div className="p-15" style={{ maxWidth: 800 }}>
        { renderEventTypeDropdown() }
        <SortableList items={specialEvents} onSortEnd={({ oldIndex, newIndex }) => onSortEnd(oldIndex, newIndex)} helperClass="question-row-dragging" pressDelay={100} lockAxis="y"/>
        { renderModal() }
      </div>
    )
  }

  if (isLoading) {
    return <LoadingSpinner/>
  }

  return (
    <AdminContent header={renderHeader()} body={renderContent()}/>
  );
}

export default SystemSpecialEventsPage;

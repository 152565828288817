import React, { useEffect, useState, useContext } from "react";
import { useNavigate, useLocation, Navigate } from "react-router-dom";
import { isEmpty } from 'lodash';
import jwt_decode from "jwt-decode";
import qs from "qs";
import { Form, Input, Row, Col } from 'antd';
import UserTypes from '../../constants/userTypes';
import LoadingSpinner from '../../components/loading';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import AppContext from '../../app/context';
import { validatePassword } from '../../helpers/passwordHelper'

import { verifyClientInvitationToken, setClientInvitationPassword } from '../../api';

const InvitationPage = (props) => {

  const [isLoading, setLoading] = useState(true);
  const [token, setToken] = useState("");
  const [verificationStatus, setVerificationStatus] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [email, setEmail] = useState("");

  const navigate = useNavigate();
  const location = useLocation();
  const { auth, setAuth } = useContext(AppContext);

  useEffect(() => {
    window.scrollTo(0, 0)
    const queryStrings = qs.parse(location.search, { ignoreQueryPrefix: true })
    if (!queryStrings.token) {
      navigate("/login", { replace: true })
    } else {
      setToken(queryStrings.token)
      const jwt = jwt_decode(queryStrings.token);
      if (jwt) {
        setEmail(jwt.email)
      }
      verifyClientInvitationToken(queryStrings.token).then((response) => {
        const status = response.data.status;
        setVerificationStatus(status)
        if (auth.isAuthenticated) {
          setAuth({})
        }
        setLoading(false)
      }).catch((e) => setLoading(false))
    }
  }, [])

  const onSubmit = values => {
    const body = {
      token: token,
      password: values.password
    }
    setErrorMessage("");
    setClientInvitationPassword(body).then(response => {
      setAuth(response.data)
    }).catch(() => {
      setVerificationStatus("error")
    })
  }
  
  const renderError = () => {
    if (!isEmpty(errorMessage)) {
      return <div className="login-error">{errorMessage}</div>;
    }
    return null;
  }

  const renderContent = () => {
    if (isLoading) {
      return <LoadingSpinner/>
    } else if (auth.isAuthenticated && auth.user.user_type_id === UserTypes.ADMIN) {
      return <Navigate replace to="/admin/dashboard?type=welcome"/>;
    } else if (auth.isAuthenticated && auth.user.user_type_id === UserTypes.CLIENT) {
      return <Navigate replace to="/client/home?type=welcome"/>;
    } else if (verificationStatus == "success") {
      return renderSetPassword()
    } else if (verificationStatus == "token-expired") {
      return renderMessage("This invitation has expired", "Please contact your DJ to send a new invitation.");
    } else if (verificationStatus == "invalid-token") {
      return renderMessage("This link is invalid", "Please check the link and try again.");
    } else if (verificationStatus == "already-accepted") {
      return renderMessage("You're all set!", "This invitation has already been accepted.");
    }  else {
      return renderMessage("Something went wrong", "Please try again later.");
    }
  }

  const renderMessage = (title, subtitle) => {
    return (
      <div className="center-content-box">
        <div className="center-content-box--title">{title}</div>
        <div className="center-content-box--subtitle">{subtitle}</div>
        <div className="center-content-box--form">
          <button className="primary-button" onClick={() => navigate("/", { replace: true })}>OK</button>
        </div>
      </div>
    )
  }

  const renderSetPassword = () => {
    return (
      <div className="center-content-box">
        <div className="center-content-box--title">Welcome!</div>
        <div className="center-content-box--subtitle">It looks like this is the first time you are signing in with <b>{email}</b>. Create a password below to continue!</div>
        <div className="center-content-box--form">
        <Form layout="vertical" name="admin-login" onFinish={onSubmit}>
            <Row gutter={[20,20]}>
              <Col xs={24}>
                <Form.Item
                  name={"password"}
                  rules={[
                    { required: true, message: `Password is required!`, validateTrigger: "onBlur" },
                    { validator: validatePassword, validateTrigger: 'onBlur'  }
                  ]}
                >
                  <Input.Password
                    size="large"
                    placeholder="Enter Password"
                    iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                  />
                </Form.Item>
              </Col>
              <Col xs={24}>
                <Form.Item
                  name={"confirm_password"}
                  dependencies={['password']}
                  rules={[
                    { required: true, message: `Confirm Password is required!`, validateTrigger: "onBlur" },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue('password') === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(new Error('The two passwords you entered do not match!'));
                      },
                    })
                  ]}
                >
                  <Input.Password
                    size="large"
                    placeholder="Confirm Password"
                    iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                  />
                </Form.Item>
              </Col>
            </Row>
            { renderError() }
            <button className="primary-button" type="submit">Continue</button>
          </Form>
        </div>
      </div>
    )
  }

  return (
    <div className="blank-layout">
      <div className="center-content-page">
        { renderContent() }
      </div>
    </div>
  );
}

export default InvitationPage;

import React, { useEffect, useState, useContext } from "react";
import { Row, Col, Menu, Dropdown, Modal, Image } from 'antd';
import { isEmpty } from 'lodash';
import AdminContent from '../../components/adminContent';
import AppContext from '../../app/context';
import LoadingSpinner from '../../components/loading';
import useApi from '../../hooks/useApi';
import { getV1EventFinancialSummary, getEventPackage, getEventAddOn } from '../../api';
import { MdOutlineMoreHoriz, MdOutlineClose } from "react-icons/md";
import { FiGift, FiPlusSquare, FiCheck } from "react-icons/fi";
import { EyeOutlined } from '@ant-design/icons';
import FloatingContainer from '../../components/floatingContainer'
import { getDiscountMessage } from '../../helpers/contractHelper';
import { hasEventPermission } from '../../helpers/permissionHelper';
import EventPermissions from '../../constants/eventPermissions';
import useDocumentTitle from '../../hooks/useDocumentTitle';

const ServicesPage = () => {

  const [isLoading, setLoading] = useState(true);
  const [eventPackages, setEventPackages] = useState([]);
  const [eventAddOns, setEventAddOns] = useState([]);
  const [isPackageModalVisible, setPackageModalVisible] = useState(false);
  const [isAddOnModalVisible, setAddOnModalVisible] = useState(false);
  const [selectedPackage, setSelectedPackage] = useState({});
  const [selectedAddOn, setSelectedAddOn] = useState({});
  const [canViewFinancials, setCanViewFinancials] = useState(false);

  useDocumentTitle("Services")
  const { auth } = useContext(AppContext);
  const [sendRequest] = useApi()

  useEffect(() => {
    window.scrollTo(0, 0);
    refreshPage()
  }, []);

  const refreshPage = async () => {
    try {
      const financialSummaryResults = await sendRequest(getV1EventFinancialSummary(auth.user.event_id));
      setEventPackages(financialSummaryResults.packages ?? [])
      setEventAddOns(financialSummaryResults.add_ons ?? [])

      setCanViewFinancials(hasEventPermission(auth.user, EventPermissions.VIEW_FINANCIALS))
    } finally {
      setLoading(false)
    }
  }

  const onSelectPackage = async (eventPackage) => {
    try {
      const packageResults = await sendRequest(getEventPackage(auth.user.event_id, eventPackage.package_id))
      setSelectedPackage(packageResults)
      setPackageModalVisible(true)
    } catch {}
  }

  const onSelectAddOn = async (addOn) => {
    try {
      const addOnResults = await sendRequest(getEventAddOn(auth.user.event_id, addOn.add_on_id))
      setSelectedAddOn(addOnResults)
      setAddOnModalVisible(true)
    } catch {}
  }

  const renderHeader = () => {
    return (
      <div className="fs-24 fw-700 p-20">Services</div>
    )
  }

  const packageMenu = (item, index) => {
    return (
      <Menu>
        <Menu.Item className="ph-15 pv-10">
          <div onClick={() => onSelectPackage(item)}>
            <EyeOutlined style={{ marginRight: 8}}/> View Details
          </div>
        </Menu.Item>
      </Menu>
    )
  };

  const addOnMenu = (item, index) => {
    return (
      <Menu>
        <Menu.Item className="ph-15 pv-10">
          <div onClick={() => onSelectAddOn(item)}>
            <EyeOutlined style={{ marginRight: 8}}/> View Details
          </div>
        </Menu.Item>
      </Menu>
    )
  };

  const renderPackage = (item, index) => {
    return (
      <div key={index} className="flex-row p-20 b-border">
        <div>
          <div className="display-flex">
            <div className="user-profile-circle"><FiGift/></div>
          </div>
        </div>
        <div className="flex-col justify-content-center flex-1 mh-15">
          <div className="fs-14 fw-700 line-1-2">{item.package_name}</div>
          { canViewFinancials && (
            <>
              { item.discount ? (
                <>
                  <div>
                    <span className="c-text-gray strikethrough">{item.price}</span>
                    <span className=""> {item.total} </span>
                  </div>
                  <div className="c-blue fs-12">{getDiscountMessage(item)}</div>
                </>
              ) : (
                <span className="c-text-gray">{item.price}</span>
              )}
            </>
          )}
        </div>
        <div className="display-flex flex-middle mr-5">
          <Dropdown overlay={packageMenu(item, index)} placement="bottomRight" trigger="click">
            <div className="dots-container">
              <MdOutlineMoreHoriz style={{ fontSize: 24, color: '#999'}}/>
            </div>
          </Dropdown>
        </div>
      </div>
    )
  }

  const renderAddOn = (item, index) => {
    return (
      <div key={index} className="flex-row p-20 b-border">
        <div>
          <div className="display-flex">
            <div className="user-profile-circle"><FiPlusSquare/></div>
          </div>
        </div>
        <div className="flex-col justify-content-center flex-1 mh-15">
          <div className="fs-14 fw-700 line-1-2">{item.add_on_name}</div>
          { canViewFinancials ? (
            <div>
              { item.discount ? (
                <>
                  <div>
                    <span className="c-text-gray">{item.quantity} x </span>
                    <span className="c-text-gray strikethrough">{item.price}</span>
                    <span className=""> {item.item_total} </span>
                  </div>
                  <div className="c-blue fs-12">{getDiscountMessage(item)}</div>
                </>
              ) : (
                <span className="c-text-gray">{item.quantity} x {item.price}</span>
              )}
            </div>
          ) : (
            <span className="c-text-gray">Qty: {item.quantity}</span>
          )}
        </div>
        <div className="display-flex flex-middle mr-5">
          <Dropdown overlay={addOnMenu(item, index)} placement="bottomRight" trigger="click">
            <div className="dots-container">
              <MdOutlineMoreHoriz style={{ fontSize: 24, color: '#999'}}/>
            </div>
          </Dropdown>
        </div>
      </div>
    )
  }

  const renderIncludedItem = (item, index, count) => {
    const borderClass = index < count - 1 ? "b-border" : ""
    return (
      <div className={`${borderClass} pv-20 mh-20`} key={index}>
        <div className="display-flex flex-row">
          <div className="display-flex flex-0">
            <FiCheck style={{ fontSize: 20, color: "#536DFE", marginRight: 15, marginTop: 2 }}/>
          </div>
          <div className="display-flex flex-1">
            <div className="display-flex flex-col">
              <div className="fs-14 fw-700">{ item.name }</div>
              <div className="fs-12 c-text-gray">{ item.description }</div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  const renderPackageModal = () => {
    const includedItems = selectedPackage.included_items ?? []
    return (
      <Modal visible={isPackageModalVisible} footer={null} onCancel={() => setPackageModalVisible(false)} width={500} closable={false} maskClosable={false} wrapClassName="rounded-modal">
        <Row align="middle">
          <Col flex={1}>
            <div className="fw-700 fs-18">Package Details</div>
          </Col>
          <Col>
            <div className="display-flex" onClick={() => setPackageModalVisible(false)}><MdOutlineClose size={30} color={"#CCC"}/></div>
          </Col>
        </Row>
        <div className="mt-15">
          <div className="t-border pt-15">
            <Row gutter={[20,20]}>
              { !isEmpty(selectedPackage.photo) && (
                <Col xs={24}>
                  <div className="mt-5">
                    <Image src={selectedPackage.photo} style={{ width: '100%', borderRadius: 4 }}/>
                  </div>
                </Col>
              )}
              <Col xs={24} className="small-center">
                <div className="fs-18 fw-700 flex-1 small-center">{ selectedPackage.package_name }</div>
                <div className="fs-14 c-text-gray small-center mt-5">{ selectedPackage.description }</div>
              </Col>
            </Row>
            { includedItems.length > 0 && (
              <div className="bg-gray radius-8 mt-20">
                { includedItems.map((item,index) => renderIncludedItem(item, index, includedItems.length))}
              </div>
            )}
          </div>
          <button className="primary-button mt-20" type="button" onClick={() => setPackageModalVisible()}>Close</button>
        </div>
      </Modal>
    )
  }

  const renderAddOnModal = () => {
    return (
      <Modal visible={isAddOnModalVisible} footer={null} onCancel={() => setAddOnModalVisible(false)} width={500} closable={false} maskClosable={false} wrapClassName="rounded-modal">
        <Row align="middle">
          <Col flex={1}>
            <div className="fw-700 fs-18">Add-On Details</div>
          </Col>
          <Col>
            <div className="display-flex" onClick={() => setAddOnModalVisible(false)}><MdOutlineClose size={30} color={"#CCC"}/></div>
          </Col>
        </Row>
        <div className="mt-15">
          <div className="t-border pt-15">
            <Row gutter={[15,15]}>
              { !isEmpty(selectedAddOn.photo) && (
                <Col xs={24}>
                  <div className="">
                    <Image src={selectedAddOn.photo} style={{ width: '100%', borderRadius: 4 }}/>
                  </div>
                </Col>
              )}
              <Col xs={24}>
                <div className="fs-18 fw-700 flex-1 small-center">{ selectedAddOn.add_on_name }</div>
                <div className="fs-14 c-text-gray small-center mt-5">{ selectedAddOn.description }</div>
              </Col>
            </Row>
          </div>
          <button className="primary-button mt-20" type="button" onClick={() => setAddOnModalVisible()}>Close</button>
        </div>
      </Modal>
    )
  }

  const renderContent = () => {
    if (isLoading) {
      return <LoadingSpinner/>
    }
    return (
      <>
        <FloatingContainer className="ph-20 mb-20" verticalPadding={20} maxWidth={600}>
          <div className="shadow-card mb-20">
            <Row align="middle" className="mh-20 pv-15 b-border">
              <Col flex={1}>
                <div className="fs-18 fw-700">Packages</div>
              </Col>
            </Row>
            { eventPackages.map((x,i) => renderPackage(x,i)) }
            { eventPackages.length == 0 && (
              <div className="p-15 text-center">
                <div className="bg-gray radius-8 p-20 text-center">
                  <div>No packages have been assigned to this event.</div>
                </div>
              </div>
            )}
          </div>
          <div className="shadow-card mb-20">
            <Row align="middle" className="mh-20 pv-15 b-border">
              <Col flex={1}>
                <div className="fs-18 fw-700">Add-Ons</div>
              </Col>
            </Row>
            { eventAddOns.map((x,i) => renderAddOn(x,i)) }
            { eventAddOns.length == 0 && (
              <div className="p-15 text-center">
                <div className="bg-gray radius-8 p-20 text-center">
                  <div>No add-ons have been assigned to this event.</div>
                </div>
              </div>
            )}
          </div>
        </FloatingContainer>
        { renderPackageModal() }
        { renderAddOnModal() }
      </>
    )
  }

  return (
    <AdminContent header={renderHeader()} body={renderContent()}/>
  );
}

export default ServicesPage;

import React, { useEffect, useState, useRef } from "react";
import {SortableContainer, sortableElement} from 'react-sortable-hoc';
import arrayMove from 'array-move';
import { isEmpty, cloneDeep } from "lodash";
import { LexoRank } from "lexorank";
import { Row, Col, Form, Modal, Button } from 'antd';
import { getEventTypes, createSystemEventType, updateSystemEventType } from '../../api';
import useApi from '../../hooks/useApi';
import LoadingSpinner from '../../components/loading';
import AdminContent from '../../components/adminContent';
import { EllipsisOutlined } from '@ant-design/icons';
import { MdDragIndicator } from "react-icons/md";
import { renderInputField } from '../../components/formFields'

const SystemEventTypePage = (props) => {

  const [isLoading, setLoading] = useState(true);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [eventTypes, setEventTypes] = useState([]);
  const [selectedEventType, setSelectedEventType] = useState({});

  const [sendRequest] = useApi()

  const [form] = Form.useForm();

  useEffect(() => {
    window.scrollTo(0, 0);
    refreshPage();
    printLexo()
  }, []);

  const refreshPage = () => {
    sendRequest(getEventTypes()).then(response => {
      setEventTypes(response)
      setLoading(false)
    }).catch((e) => setLoading(false))
  }

  const printLexo = () => {
    var runningRank = LexoRank.middle()
    var array = []
    for (var x = 0; x < 20; x++) {
      array.push(runningRank.toString())
      runningRank = runningRank.genNext()
    }
  }

  const onSubmit = (values) => {

    if (isEmpty(selectedEventType)) {
      // New Event Type
      var rank = "";
      if (eventTypes.length == 0) {
        // If this is the first event type, use minimum rank
        rank = LexoRank.middle().toString()
      } else {
        // Otherwise, get last rank and find the next one
        const lastRankString = eventTypes[eventTypes.length - 1].rank
        const lastRank = LexoRank.parse(lastRankString)
        rank = lastRank.genNext().toString()
      }
      const body = {
        event_type_name: values.event_type_name,
        rank: rank
      }
      sendRequest(createSystemEventType(body)).then((response) => {
        refreshPage()
      })
    } else {
      // Existing Event Type
      const body = {
        event_type_name: values.event_type_name,
        rank: selectedEventType.rank
      }
      sendRequest(updateSystemEventType(selectedEventType.system_event_type_id, body)).then((response) => {
        refreshPage()
      })
    }

    setIsModalVisible(false)
  }

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleNew = () => {
    form.resetFields()
    setSelectedEventType({})
    setIsModalVisible(true);
  };

  const handleEdit = (item) => {
    setSelectedEventType(item)
    form.setFieldsValue(item)
    setIsModalVisible(true);
  };

  const onSortEnd = (oldIndex, newIndex) => {
    var newEventTypes = cloneDeep(eventTypes)
    if (oldIndex !== newIndex) {
      const sortedEventTypes = arrayMove(newEventTypes, oldIndex, newIndex).filter(el => !!el);
      setEventTypes(sortedEventTypes)

      var rank = "";
      const lastIndex = sortedEventTypes.length - 1;
      if (newIndex == 0 && lastIndex > 0) {
        const rankAfter = LexoRank.parse(sortedEventTypes[newIndex + 1].rank)
        rank = rankAfter.genPrev().toString()
      } else if (newIndex == lastIndex && lastIndex > 0) {
        const rankBefore = LexoRank.parse(sortedEventTypes[lastIndex - 1].rank)
        rank = rankBefore.genNext().toString()
      } else {
        const rankBefore = LexoRank.parse(sortedEventTypes[newIndex - 1].rank)
        const rankAfter = LexoRank.parse(sortedEventTypes[newIndex + 1].rank)
        rank = rankBefore.between(rankAfter).toString()
      }

      const currentEventType = sortedEventTypes[newIndex]
      const body = {
        event_type_name: currentEventType.event_type_name,
        rank: rank
      }
      sendRequest(updateSystemEventType(currentEventType.system_event_type_id, body)).then((response) => {
        refreshPage()
      })
    }

  };

  const SortableItem = sortableElement(({value, itemIndex}) => (
    <li className="">
      {renderEventType(value, itemIndex)}
    </li>
  ));

  const renderEventType = (value, index) => {
    return (
      <Row align="middle" gutter={[15,15]} key={index} className="shadow-card ph-20 pv-15 mb-5">
        <Col flex={1}>
          <div className="fs-14 fw-700 line-1-2">{value.event_type_name}</div>
        </Col>
        <Col>
          <div className="display-flex" onClick={() => handleEdit(value)}>
            <div className="admin-icon-circle"><EllipsisOutlined/></div>
          </div>
        </Col>
        <Col>
          <div className="display-flex">
            <MdDragIndicator style={{ fontSize: 24, color: '#CCC'}}/>
          </div>
        </Col>
      </Row>
    )
  }

  const SortableList = SortableContainer(({items}) => {
    return (
      <ul className="question-row-container">
        {items.map((value, index) => (
          <SortableItem key={`item-${index}`} index={index} itemIndex={index} value={value} />
        ))}
      </ul>
    );
  });

  const renderHeader = () => {
    return (
      <Row align="middle" className="p-20">
        <Col flex={1}>
          <div className="admin-page-title">Event Types</div>
        </Col>
        <Col>
          <button className="page-title-button" onClick={() => handleNew()}>New</button>
        </Col>
      </Row>
    )
  }

  const renderModal = () => {
    return (
      <Modal title={selectedEventType.event_type_name} visible={isModalVisible} footer={null} onCancel={handleCancel} wrapClassName="rounded-modal">
        <Form form={form} layout="vertical" name="eventType" onFinish={onSubmit}>
          <Row gutter={[10,10]}>
            <Col xs={24}>
              {renderInputField("Event Type Name", "event_type_name", true)}
            </Col>
          </Row>
          <div className="admin-modal-footer">
            <Button className="admin-small-button secondary" onClick={handleCancel}>Cancel</Button>
            <Button className="admin-small-button ml-10" htmlType="submit">
              Save
            </Button>
          </div>
        </Form>
      </Modal>
    )
  }

  const renderContent = () => {
    return (
      <div className="p-15" style={{ maxWidth: 600 }}>
        <SortableList 
          items={eventTypes} 
          onSortEnd={({ oldIndex, newIndex }) => onSortEnd(oldIndex, newIndex)} 
          helperClass="question-row-dragging" 
          pressDelay={100} 
          lockAxis="y"/>
        { renderModal() }
      </div>
    )
  }

  if (isLoading) {
    return <LoadingSpinner/>
  }

  return (
    <AdminContent header={renderHeader()} body={renderContent()}/>
  );
}

export default SystemEventTypePage;

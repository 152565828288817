export const validatePassword = (rule, value, callback) => {
  if (!value) {
    callback();
    return;
  } 
  
  // var regularExpression = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/;
  var minLengthError = value.length < 6;
  var maxLengthError = value.length > 50;
  var digitError = value.search(/[0-9]/) < 0

  if (maxLengthError) {
    callback("Your password must be less than 50 characters")
  } else if (minLengthError) {
    callback(`Your password must be at least 6 characters`)
  } else if (digitError) {
    callback(`Your password must contain at least one number`)
  } else {
    callback();
  }
};
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import uniqid from 'uniqid';
import moment from "moment";
import { isArray, isEmpty, isNull } from "lodash";
import { Row, Col, Input, Dropdown, Menu, Modal, Button, Form } from 'antd';
import { 
  getEvent,
  getV2EventSpecialEvents,
  updateV2EventSpecialEventActive,
  updateV2EventSpecialEventSongNote,
  createV2EventSpecialEventSong,
  deleteV2EventSpecialEventSong,
  createEventSpecialEvent
} from '../../../api';
import useApi from '../../../hooks/useApi';
import useAccountSettings from '../../../hooks/useAccountSettings';
import { DeleteOutlined } from '@ant-design/icons';
import { getRank } from '../../../helpers/rankHelper';
import LoadingSpinner from '../../../components/loading';
import AdminContent from '../../../components/adminContent';
import PlatformSelectionModal from '../../../modals/platformSelectionModal';
import FloatingContainer from '../../../components/floatingContainer'
import CustomSongModal from '../../../modals/customSongModal';
import MusicModal from '../../../components/musicModal';
import { formatEventDateLong } from '../../../helpers/dateHelper';
import { 
  renderInputField,
  renderSearchSelectField,
  renderFormLabel
} from '../../../components/formFields'
import { MdOutlineClose, MdArrowForwardIos, MdOutlineMoreHoriz, MdRemoveCircleOutline, MdOutlineStickyNote2, MdCheckBox, MdCheckBoxOutlineBlank, MdOutlineIosShare, MdLaunch } from "react-icons/md";
import { FiMusic, FiEdit, FiPlus } from "react-icons/fi";
import useDocumentTitle from '../../../hooks/useDocumentTitle';
const BASE_URL = process.env.REACT_APP_BASE_URL;

const EventSpecialEventsPage = () => {

  const [isLoading, setLoading] = useState(true);
  const [event, setEvent] = useState({});
  const [selectedSpecialEvent, setSelectedSpecialEvent] = useState({});
  const [selectedSongNote, setSelectedSongNote] = useState("");
  const [selectedPlatform, setSelectedPlatform] = useState("spotify");
  const [selectedSong, setSelectedSong] = useState({});
  const [shareLink, setShareLink] = useState("");
  const [specialEventSections, setSpecialEventSections] = useState([]);
  const [selectedSection, setSelectedSection] = useState({});

  // Modals
  const [isPlatformSelectionVisible, setPlatformSelectionVisible] = useState(false);
  const [isCustomSongModalVisible, setCustomSongModalVisible] = useState(false);
  const [isSpecialEventsModalVisible, setSpecialEventsModalVisible] = useState(false);
  const [isMusicModalVisibile, setMusicModalVisibile] = useState(false);
  const [isNotesModalVisible, setNotesModalVisible] = useState(false);
  const [isShareModalVisible, setShareModalVisible] = useState(false);
  const [isAddSpecialEventModalVisible, setAddSpecialEventModalVisible] = useState(false);

  const [addSpecialEventForm] = Form.useForm();

  useDocumentTitle("Event Special Events")
  const navigate = useNavigate();
  const params = useParams();
  const [sendRequest] = useApi()
  const [accountSettings] = useAccountSettings()

  const eventId = params.event_id;

  useEffect(() => {
    window.scrollTo(0, 0);
    setShareLink(`${BASE_URL}/event/${event.event_id}/special-events`)
    refreshPage()
  }, []);

  const refreshPage = async () => {
    try {
      const eventResults = await sendRequest(getEvent(eventId));
      setEvent(eventResults);

      await refreshSpecialEvents()

      // const eventSpecialEventsResults = await sendRequest(getV2EventSpecialEvents(eventId));
      // setSections(eventSpecialEventsResults)

      // const recPlaylistResults = await sendRequest(getRecommendedPlaylists());
      // setRecommendedPlaylists(recPlaylistResults)

      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  const refreshSpecialEvents = async () => {
    try {
      const eventSpecialEventsResults = await sendRequest(getV2EventSpecialEvents(eventId));
      setSpecialEventSections(eventSpecialEventsResults)
    } catch {}
  }

  // useEffect(() => {
  //   setShareLink(`${BASE_URL}/event/${event.event_id}/special-events`)
  // }, [])

  const handleSelectSong = (event) => {
    setSelectedSpecialEvent(event)
    setPlatformSelectionVisible(true)
  }

  const getActiveSpecialEventCount = () => {
    return specialEventSections.reduce((x,y) => {
      var count = 0;
      if (y.special_events) {
        count = y.special_events.filter(x => x.active && x.songs_allowed).length
      }
      return x + count;
    }, 0)
  }

  const getTemplateSpecialEventCount = () => {
    return specialEventSections.reduce((x,y) => {
      var count = 0;
      if (isArray(y.special_events)) {
        count = y.special_events.length
      }
      return x + count;
    }, 0)
  }

  const copyShareLink = () => {
    navigator.clipboard.writeText(shareLink)
    setShareModalVisible(false)
  }

  const onSelectSpecialEvent = async (specialEvent) => {
    try {
      await sendRequest(updateV2EventSpecialEventActive(event.event_id, specialEvent.event_special_event_id, { active: !specialEvent.active }))
      await refreshSpecialEvents()
    } catch {}
  }
  
  const saveCustomSong = async (values) => {
    const song = {
      song_id: uniqid(),
      title: values.title,
      artist: values.artist,
      image: "",
      notes: values.notes ?? "",
      duration: 0,
      url: "",
      preview_url: "",
      source: "custom",
      rank: null
    }
  
    submitSelectSong(song)
    setCustomSongModalVisible(false)
  }

  const removeSelectedSong = async (song, specialEvent) => {
    try {
      await sendRequest(deleteV2EventSpecialEventSong(event.event_id, specialEvent.event_special_event_id, song.special_event_song_id))
      await refreshSpecialEvents()
    } catch {}
  }

  const submitSelectSong = async (song) => {
    setMusicModalVisibile(false);
    try {
      const body = {
        ...song,
        rank: getRank(selectedSpecialEvent.songs)
      }
      await sendRequest(createV2EventSpecialEventSong(event.event_id, selectedSpecialEvent.event_special_event_id, body))
      await refreshSpecialEvents()
    } catch {}
  };

  const onAddEditNote = (song, event) => {
    setSelectedSpecialEvent(event)
    setSelectedSong(song)
    setSelectedSongNote(song.notes)
    setNotesModalVisible(true)
  }

  const saveSpecialEventSongNote = async () => {
    try {
      await sendRequest(updateV2EventSpecialEventSongNote(event.event_id, selectedSpecialEvent.event_special_event_id, selectedSong.special_event_song_id, { notes: selectedSongNote }))
      await refreshSpecialEvents()
    } finally {
      setNotesModalVisible(false)
    }
  }

  const onClearNote = async (song, specialEvent) => {
    try {
      await sendRequest(updateV2EventSpecialEventSongNote(event.event_id, specialEvent.event_special_event_id, song.special_event_song_id, { notes: "" }))
      await refreshSpecialEvents()
    } catch {}
  }

  const selectPlatform = (platform) => {
    setSelectedPlatform(platform)
    setPlatformSelectionVisible(false)
    setMusicModalVisibile(true)
  }
  
  const displayCustomSong = () => {
    setPlatformSelectionVisible(false)
    setCustomSongModalVisible(true)
  }

  const addCustomSpecialEvent = (section) => {
    setSelectedSection(section)
    setSpecialEventsModalVisible(false)
    setAddSpecialEventModalVisible(true)
    addSpecialEventForm.resetFields()
  }

  const saveCustomSpecialEvent = async (values) => {
    if (isEmpty(selectedSection)) {
      setAddSpecialEventModalVisible(false)
      return
    }
    try {
      const body = {
        special_event_name: values["special_event_name"],
        song_limit: values["type"] == "single" ? 1 : 0,
        songs_allowed: true,
        is_playlist: values["type"] == "playlist",
        recommended_playlist_id: null,
        rank: getRank(selectedSection.special_events),
        active: true,
        is_custom: true
      }
      await sendRequest(createEventSpecialEvent(event.event_id, selectedSection.event_special_event_section_id, body));
      await refreshSpecialEvents()
    } finally {
      setAddSpecialEventModalVisible(false)
    }
  }

  const specialEventMenu = (item, index) => {
    return (
      <Menu>
        <Menu.Item>
          <div onClick={() => navigate(`/admin/events/${event.event_id}/event-template`)}>
            <FiEdit style={{ marginRight: 8}}/> Update Template
          </div>
        </Menu.Item>
      </Menu>
    )
  };

  const songMenu = (song, index, event) => {
    return (
      <Menu>
        <Menu.Item>
          <div onClick={() => onAddEditNote(song, event)}>
            <MdOutlineStickyNote2 style={{ marginRight: 8}}/> Add / Edit Note
          </div>
        </Menu.Item>
        { !isEmpty(song.notes) && (
          <Menu.Item>
            <div onClick={() => onClearNote(song, event)}>
              <MdRemoveCircleOutline style={{ marginRight: 8}}/> Clear Note
            </div>
          </Menu.Item>
        )}
        { song.source == "spotify" && (
          <Menu.Item>
            <a href={song.url} target="_blank">
              <MdLaunch style={{ marginRight: 8}}/> Open in Spotify
            </a>
          </Menu.Item>
        )}
         { song.source == "apple" && (
          <Menu.Item>
            <a href={song.url} target="_blank">
              <MdLaunch style={{ marginRight: 8}}/> Open in Apple Music
            </a>
          </Menu.Item>
        )}
        <Menu.Item>
          <div onClick={() => removeSelectedSong(song, event)}>
            <DeleteOutlined style={{ marginRight: 8}}/> Remove Song
          </div>
        </Menu.Item>
      </Menu>
    )
  };

  const renderHeader = () => {
    return (
      <div className="p-20">
        <div>
          <span className="c-blue fw-700 cursor-default" onClick={() => navigate(`/admin/events`)}>
            Events
          </span>
          <span className="fs-10 mh-5"><MdArrowForwardIos/></span>
          <span className="c-blue fw-700 cursor-default" onClick={() => navigate(`/admin/events/${eventId}?tab=planning&section=music`)}>
            Event Details
          </span>
          <span className="fs-10 mh-5"><MdArrowForwardIos/></span>
          <span className="cursor-default c-text-gray">
            Special Events
          </span>
        </div>
        <div className="fw-700 fs-24 mt-5">{event.event_name}</div>
        <div className="fs-16 c-text-gray fw-500">{ formatEventDateLong(event.event_date, accountSettings) }</div>
      </div>
    )
  }

  const renderActiveSpecialEventSection = (section, index) => {
    const sectionSpecialEvents = isArray(section.special_events) ? section.special_events : []
    const sectionActiveSpecialEvents = sectionSpecialEvents.filter(x => x.active && x.songs_allowed);
    // if (sectionActiveSpecialEvents.length == 0) {
    //   return null;
    // }
    return (
      <div className="mb-20 pb-20 b-border">
        <div className="mb-10 ml-5">
          <div className="fs-20 fw-700">{ section.section_name }</div>
          { section.description && (
            <div className="c-text-gray line-breaks">{ section.description }</div>
          )}
        </div>
        { sectionActiveSpecialEvents.map((x,i) => renderSpecialEvent(x,i))}
        { sectionActiveSpecialEvents.length == 0 && (
            <div className="shadow-card ph-20 pv-30 text-center c-text-gray">The client has not selected any special events yet.</div>
          )}
      </div>
    )
  }

  const renderSpecialEventPlaylistOrSongs = (specialEvent) => {
    if (specialEvent.is_playlist) {
      const songCount = isArray(specialEvent.songs) ? specialEvent.songs.length : 0
      return (
        <div className={`ml-10`}>
          <Row align="middle" gutter={[15,15]} className="pv-10 pr-15" onClick={() => navigate(`/admin/events/${event.event_id}/special-events/${specialEvent.event_special_event_id}`)}>
            <Col>
              <div className="card-icon"><FiMusic/></div>
            </Col>
            <Col flex={1}>
              <div className="fw-700">Playlist</div>
              <div className="c-text-gray">{songCount} {songCount == 1 ? "Song" : "Songs"}</div>
            </Col>
            <Col>
              <div className="display-flex">
                <MdArrowForwardIos style={{ fontSize: 18, color: '#999'}}/>
              </div>
            </Col>
          </Row>
        </div>
      )
    } else {
      return (
        <>
          { isArray(specialEvent.songs) && specialEvent.songs.map((x,i) => renderSpecialEventSong(x,i,specialEvent))}
          { renderSelectSongButton(specialEvent) }
        </>
      )
    }
  }

  const renderSpecialEvent = (event, index) => {
    const tag = event.is_playlist ? "PLAYLIST" : (event.songs_allowed ? "SPECIAL SONG" : "NO SONGS")
    return (
      <div className="shadow-card mb-10" key={index}>
        <Row align="middle" gutter={[15,15]} className="b-border pv-15">
          <Col flex={1}>
            <div className="fw-600 c-text-gray fs-10 ph-15">{tag}</div>
            <div className="fw-700 fs-16 ph-15">{event.special_event_name}</div>
          </Col>
          <Col flex={0}>
            <div className="display-flex mr-5">
              <Dropdown overlay={specialEventMenu(event, index)} placement="bottomRight" trigger="click">
                <MdOutlineMoreHoriz style={{ fontSize: 24, color: '#999', marginRight: 10}}/>
              </Dropdown>
            </div>
          </Col>
        </Row>
        { renderSpecialEventPlaylistOrSongs(event) }
      </div>
    )
  }

  const renderSpecialEventSong = (item, index, event) => {
    const borderClass = (index + 1) < event.song_limit ? "b-border" : ""
    return (
      <div className={`song-card song-card--special-events-page no-shadow ph-15 ${borderClass}`} key={index}>
        <div className="song-card--row-1">
          <div className="song-card--image">
            { item.image ? (
              <img src={item.image} style={{ width: 50 }}/>
            ) : (
              <div className="song-image-icon"><FiMusic/></div>
            )}
          </div>
          <div className="song-card--title-artist">
            <div className="song-card--text fs-14 fw-700 line-1-5">{item.title}</div>
            <div className="song-card--text fs-14 c-text-gray line-1-5">{item.artist}</div>
          </div>
          <div className="song-card--icon">
            <Dropdown overlay={songMenu(item, index, event)} placement="bottomRight" trigger="click">
              <div className="song-card--dots-container">
                <MdOutlineMoreHoriz style={{ fontSize: 24, color: '#999'}}/>
              </div>
            </Dropdown>
          </div>
        </div>
        { !isEmpty(item.notes) && (
          <div className="song-card--row-2">
            { item.notes }
          </div>
        )}
      </div>
    )
  }

  const renderSelectSongButton = (event) => {
    const numSongs = isNull(event.songs) ? 0 : event.songs.length;
    if (numSongs < event.song_limit) {
      return (
        <div className="pb-10">
          <div className="special-events-select-song" onClick={() => handleSelectSong(event)}>
            <div className="admin-link">Select Song</div>
          </div>
        </div>
      )
    }
    return null;
  }

  // Modals
  const renderPlatformSelectionModal = () => {
    return (
      <PlatformSelectionModal 
        isVisible={isPlatformSelectionVisible}
        setVisible={setPlatformSelectionVisible}
        selectPlatform={selectPlatform}
        addCustomSong={displayCustomSong}
      />
    )
  }

  const renderCustomSongModal = () => {
    return (
      <CustomSongModal 
        isVisible={isCustomSongModalVisible}
        setVisible={setCustomSongModalVisible}
        saveCustomSong={saveCustomSong}
      />
    )
  }

  const renderSpecialEventModalSection = (section, index) => {
    const sectionSpecialEvents = isArray(section.special_events) ? section.special_events : []
    const sectionSongSpecialEvents = sectionSpecialEvents.filter(x => x.songs_allowed);
    return (
      <>
        <Row gutter={[20,20]} align="middle" key={index} className="pb-10 pt-15">
          <Col flex={1}>
            <div className="fw-700 fs-18 pl-5">{section.section_name}</div>
          </Col>
        </Row>
        <div className="shadow-card mb-5">
          { sectionSongSpecialEvents.map((x,i) => renderSpecialEventModalRow(x,i))}
          <Row gutter={[15,15]} align="middle" key={index} className="p-15" onClick={() => addCustomSpecialEvent(section)}>
            <Col flex={0}>
              <div className="special-event-add-icon"><FiPlus/></div>
            </Col>
            <Col flex={1}>
              <div className="fw-600">Add Custom Special Event</div>
            </Col>
          </Row>
        </div>
      </>
    )
  }

  const renderSpecialEventModalRow = (event, index) => {
    const tag = event.is_playlist ? "PLAYLIST" : (event.songs_allowed ? "SPECIAL SONG" : "NO SONGS")
    return (
      <div className="flex-row flex-middle b-border p-15" onClick={() => onSelectSpecialEvent(event)}>
        <div className="flex-1">
          <div className="fw-600">{event.special_event_name} <span className="bg-gray ph-5 c-text-gray fw-700 fs-10 ml-5" style={{ paddingTop: 2, paddingBottom: 2}}>{tag}</span></div>
        </div>
        <div className="flex-0">
          <div className="display-flex">
            { event.active ? <MdCheckBox size={24} color={"#536DFE"}/> : <MdCheckBoxOutlineBlank size={24} color={"#CCC"}/>}
          </div>
        </div>
      </div>
    )
  }

  const renderSpecialEventsModal = () => {
    return (
      <Modal visible={isSpecialEventsModalVisible} footer={null} onCancel={() => setSpecialEventsModalVisible(false)} width={500} closable={false} maskClosable={false} wrapClassName="rounded-modal">
        <Row align="middle">
          <Col flex={1}>
            <div className="fw-700 fs-18">Special Events</div>
          </Col>
          <Col>
            <div className="display-flex" onClick={() => setSpecialEventsModalVisible(false)}><MdOutlineClose size={30} color={"#CCC"}/></div>
          </Col>
        </Row>
        <div className="">
          <div className="pt-10 pb-10">
            <div className="bg-gray pv-20 ph-20 radius-8 fs-14 text-center"><b>IMPORTANT:</b> The list below represents all possible special events the client can include in their event. The client should be in charge of selecting from this list, however you are able to override their selection by making modifications below.</div>
            <div className="">
              { specialEventSections.map((x,i) => renderSpecialEventModalSection(x,i))}
            </div>
          </div>
          <button className="primary-button" type="button" onClick={() => setSpecialEventsModalVisible(false)}>Done</button>
        </div>
      </Modal>
    )
  }

  const renderMusicModal = () => {
    return (
      <MusicModal 
        isVisible={isMusicModalVisibile} 
        title="Select a Song" 
        onCancel={() => setMusicModalVisibile(false)}
        onSelect={submitSelectSong}
        selectedEvent={selectedSpecialEvent}
        eventName={selectedSpecialEvent.special_event_name}
        eventId={event.event_id}
        selectedPlatform={selectedPlatform}
      />
    )
  }

  const renderNotesModal = () => {
    return (
      <Modal visible={isNotesModalVisible} footer={null} onCancel={() => setNotesModalVisible(false)} width={400} closable={false} maskClosable={false} wrapClassName="rounded-modal">
        <Row align="middle">
          <Col flex={1}>
            <div className="fw-700 fs-18">Add / Edit Note</div>
          </Col>
          <Col>
            <div className="display-flex" onClick={() => setNotesModalVisible(false)}><MdOutlineClose size={30} color={"#CCC"}/></div>
          </Col>
        </Row>
        <div className="">
          <div className="pt-10 pb-15">
            <Input.TextArea 
              rows={3}
              value={selectedSongNote}
              onChange={(e) => setSelectedSongNote(e.target.value)}
              size="large"/>
          </div>
          <div className="text-right">
            <Button className="admin-small-button secondary mr-10" onClick={() => setNotesModalVisible(false)}>Cancel</Button>
            <Button className="admin-small-button" onClick={() => saveSpecialEventSongNote()}>Save</Button>
          </div>
        </div>
      </Modal>
    )
  }

  const renderPlanningSpecialEventsTab = () => {
    return (
      <>
        {/* <div className="shadow-card p-20 mb-15 text-center">
          <div className="fw-700 fs-16">Event Template</div>
          <div className="fw-500">Sections: { specialEventSections.length }</div>
          <div className="fw-500 pb-20">Special Events: { getTemplateSpecialEventCount() }</div>
          <div className="bg-gray radius-8 p-15">
            The template assigned to this event represents the list of all possible special events the client has to choose from. If you would like to make updates to the template, you can do so by clicking the following link.
          </div>
          <div className="b-border pv-20">
            <div className="blue-link" onClick={() => navigate(`/admin/events/${event.event_id}/special-events-template`)}>View / Update Template</div>
          </div>

          <div className="fw-700 fs-16 mt-15">Client Selections</div>
          <div className="fw-500 pb-20">Special events selected: { getActiveSpecialEventCount() }</div>
          <div className="bg-gray radius-8 ph-10 pv-15 text-center">
            If a special event appears in the list below, that means the client has selected it from the list and choosen to include it in their event. If you would like to override their selection and add songs for them, you can do so by clicking the following link.
          </div>
          <div className="mt-20">
            <div className="blue-link" onClick={() => setSpecialEventsModalVisible(true)}>Update Client Selection</div>
          </div>
        </div> */}
        { specialEventSections.map((x,i) => renderActiveSpecialEventSection(x,i))}
        { specialEventSections.length > 0 && (
          <div className="text-center mt-30 mb-10">
            <span className="bg-light-blue fw-600 c-blue ph-20 pv-10 radius-8 cursor-default" onClick={() => setSpecialEventsModalVisible(true)}>Update Client Selection</span>
          </div>
        )}
        {/* { getActiveSpecialEventCount() == 0 && (
          <div className="pb-20 t-border mt-20 pt-20">
            <div className="shadow-card ph-20 pv-50 text-center">
              <div className="fs-14 fw-500 ph-10 c-text-gray">
                The client has not selected any special events yet.
              </div>
            </div>
          </div>
        )} */}
        { renderSpecialEventsModal() }
        { renderAddSpecialEventModal() }
        { renderPlatformSelectionModal() }
        { renderCustomSongModal() }
        { renderMusicModal() }
        { renderNotesModal() }
      </>
    )
  }

  const renderAddSpecialEventModal = () => {
    const options = [{ text: "Special Song", value: "single" }, { text: "Playlist", value: "playlist" }]
    return (
      <Modal visible={isAddSpecialEventModalVisible} footer={null} onCancel={() => setAddSpecialEventModalVisible(false)} width={400} closable={false} maskClosable={false} wrapClassName="rounded-modal">
        <Row align="middle">
          <Col flex={1}>
            <div className="fw-700 fs-18">Add Custom Special Event</div>
          </Col>
          <Col>
            <div className="display-flex" onClick={() => setAddSpecialEventModalVisible(false)}><MdOutlineClose size={30} color={"#CCC"}/></div>
          </Col>
        </Row>
        <Form form={addSpecialEventForm} layout="vertical" name="client" onFinish={saveCustomSpecialEvent}>
          <div className="">
            <div className="pt-10 pb-15">
              <Row align="middle" gutter={[15,15]}>
                <Col xs={24}>
                  { renderInputField("Special Event Name", "special_event_name", true)}
                </Col>
                <Col xs={24}>
                  { renderFormLabel("Will you be selecting a specific song for this special event or building a playlist?")}
                  { renderSearchSelectField("", "type", "", options, true) }
                </Col>
              </Row>
            </div>
            <div className="text-right">
              <Button className="admin-small-button secondary mr-10" onClick={() => setAddSpecialEventModalVisible(false)}>Cancel</Button>
              <Button className="admin-small-button" htmlType="submit">Save</Button>
            </div>
          </div>
        </Form>
      </Modal>
    )
  }

  const renderShareModal = () => {
    return (
      <Modal visible={isShareModalVisible} footer={null} onCancel={() => setShareModalVisible(false)} width={400} closable={false} maskClosable={false} wrapClassName="rounded-modal">
        <Row align="middle">
          <Col flex={1}>
            <div className="fw-700 fs-18">Share Special Events</div>
          </Col>
          <Col>
            <div className="display-flex" onClick={() => setShareModalVisible(false)}><MdOutlineClose size={30} color={"#CCC"}/></div>
          </Col>
        </Row>
        <div className="">
          <div className="pt-10 pb-10">
            <Input 
              value={shareLink}
              size="large"/>
          </div>
          <button className="primary-button" type="button" onClick={() => copyShareLink()}>Copy Link</button>
          <div className="text-center mt-15">
            <div className="blue-link" onClick={() => setShareModalVisible(false)}>Close</div>
          </div>
        </div>
      </Modal>
    )
  }

  const renderContent = () => {
    return (
      <>
        <div className="p-15 text-center mb-5 bg-white b-border">
          <div className="flex-row nowrap justify-content-end">
            <div className="display-flex flex-0 bg-gray ph-10 pv-5 mr-10 c-blue radius-4 cursor-default" onClick={() => setSpecialEventsModalVisible(true)}>
              <FiEdit style={{ fontSize: 20, color: '#536DFE', marginRight: 8}}/> Update Client Selection
            </div>
            {/* <div className="display-flex flex-0 bg-gray ph-10 pv-5 c-blue radius-4 cursor-default" onClick={() => setShareModalVisible(true)}>
              <MdOutlineIosShare style={{ fontSize: 20, color: '#536DFE', marginRight: 8}}/> Share
            </div> */}
          </div>
        </div>
        <FloatingContainer className="ph-20 mb-20" verticalPadding={20} maxWidth={600}>
          { renderPlanningSpecialEventsTab() }
        </FloatingContainer>
        { renderShareModal() }
      </>
    )
  }

  if (isLoading) {
    return <LoadingSpinner/>
  }

  return (
    <AdminContent header={renderHeader()} body={renderContent()}/>
  );
}

export default EventSpecialEventsPage;
import React, { useEffect, useState, useContext, useRef } from "react";
import qs from "qs";
import { Navigate, Link, useNavigate, useLocation } from "react-router-dom";
import { get, isEmpty } from 'lodash';
import { Form, Input } from 'antd';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import AppContext from '../../../app/context';
import UserTypes from '../../../constants/userTypes';
import { getDeviceId } from '../../../helpers/deviceHelper';
import './loginPage.style.scss';
import djpcLogo from '../../../images/djpc-logo.png';
import FadeDownWhileInView from '../../../components/fadeDownWhileInView'
import useDocumentTitle from '../../../hooks/useDocumentTitle';

import { login } from '../../../api';

const LoginPage = () => {

  useDocumentTitle("Sign In")
  const navigate = useNavigate();
  const location = useLocation();
  const { auth, setAuth } = useContext(AppContext);
  const emailInputRef = useRef(null);
  const inputRef = useRef(null);

  const [errorMessage, setErrorMessage] = useState("");
  const [redirectUrl, setRedirectUrl] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0)
    const queryStrings = qs.parse(location.search, { ignoreQueryPrefix: true })
    if (queryStrings.redirect) {
      setRedirectUrl(queryStrings.redirect)
    }
  }, [])

  const onSubmit = values => {
    setErrorMessage("");
    const device_id = getDeviceId()
    login({ email: values.email, password: values.password, device_id: device_id }).then(response => {
      setAuth(response.data)
    }).catch(error => {
      const errorName = get(error, "response.data.name", "")
      if (errorName == "EmailNotVerifiedError") {
        navigate("/verify-email", { state: { email: values.email, from: "login" }})
      } else {
        setErrorMessage("Your credentials are incorrect. Please try again.");
      }
    })
  }

  const renderContent = () => {
    if (auth.isAuthenticated && auth.user.user_type_id === UserTypes.SYSTEM_ADMIN) {
      return <Navigate to="/system/dashboard"/>;
    } else if (auth.isAuthenticated && auth.user.user_type_id === UserTypes.ADMIN) {
      if (!isEmpty(redirectUrl)) {
        return <Navigate to={redirectUrl}/>;
      } else {
        return <Navigate to="/admin/dashboard"/>;
      }
    } else if (auth.isAuthenticated && auth.user.user_type_id === UserTypes.CLIENT) {
      return <Navigate to="/client/home"/>;
    }
    return renderSignInPage();
  }

  const renderError = () => {
    if (!isEmpty(errorMessage)) {
      return <div className="login-error">{errorMessage}</div>;
    }
    return null;
  }
  
  const renderSignInPage = () => {
    return (
      <FadeDownWhileInView className="center-content-box w-400">
        <div className="text-center mt-30" onClick={() => navigate("/")}>
          <img src={djpcLogo} style={{ width: 180 }}/>
        </div>
        <div className="text-center fs-16 fw-600 mt-20">Login to your account</div>
        <div className="center-content-box--form">
          <Form layout="vertical" name="verify" onFinish={onSubmit}>
            <div className="mb-10">
              <Form.Item
                name={"email"}
                rules={[
                  { required: true, message: `Email is required!`, validateTrigger: 'onBlur'  },
                  { type: "email", message: `Please enter a valid email address`, validateTrigger: 'onBlur' }
                ]}
              >
                <Input size="large" ref={emailInputRef} placeholder="Email" onChange={() => setErrorMessage("")}/>
              </Form.Item>
              <Form.Item
                name={"password"}
                className="mt-20"
                rules={[
                  { required: true, message: `Password is required!`, validateTrigger: 'onBlur'  }
                ]}
              >
                <Input.Password
                  ref={inputRef}
                  size="large"
                  placeholder="Password"
                  iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                />
              </Form.Item>
            </div>
            { renderError() }
            <button className="primary-button" type="submit">Sign In</button>
            <div className="center-content-box--link-container">
              <Link to="/forgot-password" className="center-content-box--link">Forgot password?</Link>
            </div>
          </Form>
        </div>
      </FadeDownWhileInView>
    )
  }

  return (
    <div className="blank-layout" style={ { display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
      <div className="center-content-page">
        { renderContent() }
      </div>
    </div>
  );
}

LoginPage.propTypes = {};

export default LoginPage;

import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Row, Col } from 'antd';
import FloatingContainer from '../../components/floatingContainer'
import FadeDownWhileInView from '../../components/fadeDownWhileInView'
import FeatureSections from '../../components/featureSections'
import ClientFeatures from '../../components/clientFeatures'
import DJFeatures from '../../components/djFeatures'
import useDocumentTitle from '../../hooks/useDocumentTitle';
import { pageViewFeaturesEvent } from '../../helpers/facebookHelper'

const FeaturesPage = () => {

  useDocumentTitle("Features")
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0)
    pageViewFeaturesEvent()
  }, [])

  const renderContent = () => {
      return (
      <div>
        <FloatingContainer className="bg-blue-gradient" verticalPadding={60} maxWidth={1000}>
          <FadeDownWhileInView className="fs-30 fw-700 text-center c-white">Features</FadeDownWhileInView>
        </FloatingContainer>
        <FeatureSections/>
        <ClientFeatures/>
        <DJFeatures/>
      </div>
      )
  }

  return (
    <div>
      { renderContent() }
    </div>
  );
}

export default FeaturesPage;

import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from 'react-router-dom';
import moment from "moment";
import { Row, Col, Modal, Menu, Dropdown, Form} from 'antd';
import AppContext from '../../../../app/context';
import { DeleteOutlined } from '@ant-design/icons';
import { MdOutlineClose, MdOutlineMoreHoriz, MdOutlineEdit } from "react-icons/md";
import { FiPlus } from "react-icons/fi";
import { isEmpty, isUndefined, isArray } from "lodash";
import FloatingContainer from '../../../../components/floatingContainer';
import {
  formatCurrencyString,
  getPaymentTotal, 
  getNumericCurrency,
  getDiscountMessage
} from '../../../../helpers/contractHelper';
import { formatDateShort } from '../../../../helpers/dateHelper';
import useApi from '../../../../hooks/useApi';
import useCurrency from '../../../../hooks/useCurrency';
import useAccountSettings from '../../../../hooks/useAccountSettings';
import {
  createPayment,
  updatePayment,
  deletePayment,
  upsertEventFinancials
} from '../../../../api';
import {
  renderSearchSelectField,
  renderCurrencyField,
  renderDateField
} from '../../../../components/formFields'
import Permissions from '../../../../constants/permissions';
import { hasPermission } from '../../../../helpers/permissionHelper';

const EventFinancialsTab = (props) => {

  const { event, eventPackages = [], eventAddOns = [], payments = [], eventFinancials = {}, eventFinancialSummary = {}, refreshFinancials = () => {}, onSelectTab = () => {}, isNewInvoicesEnabled = false, invoiceTotals = {} } = props;

  const [selectedPayment, setSelectedPayment] = useState({});
  const [isPaymentModalVisible, setPaymentModalVisible] = useState(false);
  const [isDepositDiscountModalVisible, setDepositDiscountModalVisible] = useState(false);
  const [isConfirmRemovePaymentModalVisible, setConfirmRemovePaymentModalVisible] = useState(false);
  const [isNewPayment, setNewPayment] = useState(false);

  const [paymentForm] = Form.useForm();
  const [financialsForm] = Form.useForm();
  const [sendRequest] = useApi()
  const [currency, _] = useCurrency()
  const [accountSettings] = useAccountSettings()

  const { auth, setAuth } = useContext(AppContext);
  const navigate = useNavigate();
  const user = auth.user ? auth.user : {}
  const canEditFinancials = hasPermission(user, Permissions.EDIT_EVENT_FINANCIALS);

  useEffect(() => {
  }, [])

  const onAddPayment = () => {
    paymentForm.resetFields()
    var paymentType = null
    var paymentAmount = null
    const total = getNumericCurrency(eventFinancialSummary.total_price)
    const balance = getNumericCurrency(eventFinancialSummary.balance)
    const deposit = getNumericCurrency(eventFinancialSummary.deposit)
    if (total == balance && deposit > 0) {
      paymentType = "DEPOSIT"
      paymentAmount = deposit
    } else if (balance > 0) {
      paymentType = "FINAL_PAYMENT"
      paymentAmount = balance
    }
    const values = {
      payment_type: paymentType,
      amount: paymentAmount,
      payment_datetime: moment()
    }
    paymentForm.setFieldsValue(values)
    setNewPayment(true)
    setPaymentModalVisible(true)
  }

  const onEditPayment= (payment) => {
    setSelectedPayment(payment)
    const values = {
      payment_type: payment.payment_type,
      amount: payment.amount,
      payment_datetime: moment.utc(payment.payment_datetime)
    }
    paymentForm.setFieldsValue(values)
    setNewPayment(false)
    setPaymentModalVisible(true)
  }

  const handleRemovePayment = (payment) => {
    setSelectedPayment(payment)
    setConfirmRemovePaymentModalVisible(true)
  }

  const removePayment = async () => {
    try {
      setConfirmRemovePaymentModalVisible(false)
      await sendRequest(deletePayment(event.event_id, selectedPayment.payment_id))
      await refreshFinancials()
    } catch {}
  }

  const onPaymentSubmit = async (values) => {
    try {
      const body = {
        payment_type: values["payment_type"],
        amount: getNumericCurrency(values["amount"]),
        payment_datetime: values["payment_datetime"].format("YYYY-MM-DD")
      }
      if (isNewPayment) {
        await sendRequest(createPayment(event.event_id, body))
      } else if (selectedPayment.payment_id) {
        await sendRequest(updatePayment(event.event_id, selectedPayment.payment_id, body))
      }
      await refreshFinancials()
      setPaymentModalVisible(false)
    } catch {
      setPaymentModalVisible(false)
    }
  }

  const onEditAmounts = () => {
    const fields = {
      deposit: !isUndefined(eventFinancials.deposit) ? eventFinancials.deposit : null
    }
    financialsForm.setFieldsValue(fields)
    setDepositDiscountModalVisible(true)
  }

  const onFinancialsSubmit = async (values) => {
    try {
      const body = {
        deposit: getNumericCurrency(values["deposit"])
      }
      await sendRequest(upsertEventFinancials(event.event_id, body))
      await refreshFinancials()
      setDepositDiscountModalVisible(false)
    } catch {
      setDepositDiscountModalVisible(false)
    }
  }

  const getPaymentTypes = () => {
    return [
      { value: "DEPOSIT", text: accountSettings.holding_fee_term},
      { value: "ADDITIONAL_PAYMENT", text: "Additional Payment" },
      { value: "FINAL_PAYMENT", text: "Final Payment" }
    ]
  }

  const paymentMenu = (item, index) => {
    return (
      <Menu>
        <Menu.Item className="ph-15 pv-10">
          <div onClick={() => onEditPayment(item)}>
            <MdOutlineEdit style={{ marginRight: 8}}/> Edit Payment
          </div>
        </Menu.Item>
        <Menu.Item className="ph-15 pv-10">
          <div onClick={() => handleRemovePayment(item)}>
            <DeleteOutlined style={{ marginRight: 8}}/> Remove Payment
          </div>
        </Menu.Item>
      </Menu>
    )
  };

  const renderPaymentModal = () => {
    return (
      <Modal visible={isPaymentModalVisible} footer={null} closable={false} wrapClassName="rounded-modal">
        <Row align="middle">
          <Col flex={1}>
            <div className="fw-700 fs-18">{ isNewPayment ? "Add Payment" : "Edit Payment"}</div>
          </Col>
          <Col>
            <div className="display-flex" onClick={() => setPaymentModalVisible(false)}><MdOutlineClose size={30} color={"#CCC"}/></div>
          </Col>
        </Row>
        <Form form={paymentForm} layout="vertical" name="payments" onFinish={onPaymentSubmit}>
          <Row gutter={[10,10]} className="mt-15">
            <Col xs={24}>
              { renderSearchSelectField("Payment Type", "payment_type", "Select payment type...", getPaymentTypes(), true) }
            </Col>
            <Col xs={24}>
              { renderCurrencyField("Amount", "amount", true, currency.code) }
            </Col>
            <Col xs={24}>
              { renderDateField("Payment Date", "payment_datetime", accountSettings) }
            </Col>
          </Row>
          <div className="admin-modal-footer">
            <button className="primary-button" type="submit">{ isNewPayment ? "Add Payment" : "Update Payment" }</button>
            <div className="text-center mt-15">
              <div className="blue-link" onClick={() => setPaymentModalVisible(false)}>Cancel</div>
            </div>
          </div>
        </Form>
      </Modal>
    )
  }

  const renderOtherFinancialsModal = () => {
    return (
      <Modal visible={isDepositDiscountModalVisible} footer={null} closable={false} wrapClassName="rounded-modal">
        <Row align="middle">
          <Col flex={1}>
            <div className="fw-700 fs-18">{accountSettings.holding_fee_term}</div>
          </Col>
          <Col>
            <div className="display-flex" onClick={() => setDepositDiscountModalVisible(false)}><MdOutlineClose size={30} color={"#CCC"}/></div>
          </Col>
        </Row>
        <Form form={financialsForm} layout="vertical" name="payments" onFinish={onFinancialsSubmit}>
          <Row gutter={[10,10]} className="mt-15">
            <Col xs={24}>
              { renderCurrencyField(accountSettings.holding_fee_term, "deposit", false, currency.code) }
            </Col>
          </Row>
          <div className="admin-modal-footer">
            <button className="primary-button" type="submit">{ "Save" }</button>
            <div className="text-center mt-15">
              <div className="blue-link" onClick={() => setDepositDiscountModalVisible(false)}>Cancel</div>
            </div>
          </div>
        </Form>
      </Modal>
    )
  }

  const renderConfirmRemovePaymentModal = () => {
    return (
      <Modal visible={isConfirmRemovePaymentModalVisible} closable={false} footer={null} width={400} wrapClassName="rounded-modal">
        <div className="text-right" onClick={() => setConfirmRemovePaymentModalVisible(false)}><MdOutlineClose size={30} color={"#CCC"}/></div>
        <div className="mt-10">
          <div className="fw-700 fs-20 text-center">Remove Payment</div>
          <div className="fw-500 fs-14 mt-20 mb-20 text-center">Are you sure you would like to remove the payment made on <span className="fw-700">{formatDateShort(selectedPayment.payment_datetime, accountSettings, true)}</span> from this event?</div>
          <button className="primary-button warning" type="button" onClick={() => removePayment()}>Remove Payment</button>
          <div className="text-center mt-15">
            <div className="blue-link" onClick={() => setConfirmRemovePaymentModalVisible(false)}>Cancel</div>
          </div>
        </div>
      </Modal>
    )
  }

  const renderPayment = (payment, index) => {
    const paymentType = getPaymentTypes().find(x => x.value == payment.payment_type)
    const paymentTypeText = isEmpty(paymentType) ? "Payment" : paymentType.text;
    const paymentDate = !isEmpty(payment.payment_datetime) ? formatDateShort(payment.payment_datetime, accountSettings, true) : "--"
    return (
      <Row align="middle" className="mh-20 pv-15 b-border ph-10" key={index} wrap={false}>
        <Col flex={1}>
          <div className="fs-14 fw-500">{paymentTypeText} of <b>{ formatCurrencyString(payment.amount, currency.code) }</b> paid on { paymentDate }</div>
        </Col>
        <Col className="text-right">
          { canEditFinancials && (
            <Dropdown overlay={paymentMenu(payment, index)} placement="bottomRight" trigger="click">
              <MdOutlineMoreHoriz style={{ fontSize: 24, color: '#999'}}/>
            </Dropdown>
          )}
        </Col>
      </Row>
    )
  }

  const renderInvoiceSummarySection = () => {
    return (
      <Row align="middle" gutter={[15,15]}>
        <Col xs={24} sm={8}>
          <div className="shadow-card text-center pv-20">
            <div className="fw-700">Total Outstanding</div>
            <div className="fs-20">{ formatCurrencyString(invoiceTotals.outstanding, currency.code) }</div>
          </div>
        </Col>
        <Col xs={24} sm={8}>
          <div className="shadow-card text-center pv-20">
            <div className="fw-700">Past Due</div>
            <div className="fs-20">{ formatCurrencyString(invoiceTotals.past_due, currency.code) }</div>
          </div>
        </Col>
        <Col xs={24} sm={8}>
          <div className="shadow-card text-center pv-20">
            <div className="fw-700">Total Paid</div>
            <div className="fs-20">{ formatCurrencyString(invoiceTotals.paid, currency.code) }</div>
          </div>
        </Col>
        <Col xs={24}>
          <div className="shadow-card mb-20 text-center pv-20">
            <div className="blue-link" onClick={() => onSelectTab("documents")}>View invoices</div>
          </div>
        </Col>
      </Row>
    )
  }

  const renderPaymentSection = () => {
    return (
      <div className="shadow-card mb-20">
        <Row align="middle" className="mh-20 pv-15 b-border">
          <Col flex={1}>
            <div className="fs-18 fw-700">Payments</div>
          </Col>
          { canEditFinancials && (
            <Col>
              <div className="plus-container mr-5" onClick={() => {}}>
                <FiPlus style={{ fontSize: 20, color: '#536DFE'}} onClick={() => onAddPayment()}/>
              </div>
            </Col>
          )}
        </Row>
        { payments.map((x,i) => renderPayment(x,i))}
        { payments.length == 0 && (
          <div className="p-15 text-center">
            <div className="bg-gray radius-8 p-15 text-center">
              <div>No payments have been added to this event.</div>
              <button className="small-primary-button mt-15" onClick={() => onAddPayment()}>Add Payment</button>
            </div>
          </div>
        )}
      </div>
    )
  }

  const renderPackage = (eventPackage, index) => {
    return (
      <Row align="middle" className="mh-20 pv-15 b-border ph-10" key={index}>
        <Col flex={1}>
          <div className="fs-14 fw-700">{eventPackage.package_name}</div>
          { eventPackage.discount && (
            <div className="c-blue fs-12">{getDiscountMessage(eventPackage)}</div>
          )}
        </Col>
        <Col>
          { eventPackage.discount ? (
            <>
              <div className="fs-14 fw-500 text-right c-text-gray strikethrough">{ eventPackage.price }</div>
              <div className="fs-14 fw-500 text-right">{ eventPackage.total }</div>
            </>
          ) : (
            <div className="fs-14 fw-500 text-right">{ eventPackage.price }</div>
          )}
        </Col>
      </Row> 
    )
  }

  const renderAddOn = (addOn, index) => {
    return (
      <Row align="middle" className="mh-20 pv-15 b-border ph-10" key={index}>
        <Col flex={1}>
          <div className="fs-14 fw-700">{ addOn.add_on_name }</div>
          <div className="">
            <span className="c-text-gray">{addOn.quantity} x {addOn.price}</span>
            { addOn.discount && (
              <div className="c-blue fs-12">{getDiscountMessage(addOn)}</div>
            )}
          </div>
        </Col>
        <Col>
        { addOn.discount ? (
          <>
            <div className="c-text-gray strikethrough text-right">{ addOn.sub_total }</div>
            <div className=" text-right">{ addOn.total }</div>
          </>
        ) : (
          <div className="fs-14 fw-500 text-right">{ addOn.total }</div>
        )}
        </Col>
      </Row>
    )
  }

  const renderServicesTab = () => {
    return (
      <FloatingContainer className="ph-20 mb-20" verticalPadding={20} maxWidth={600}>
        { isNewInvoicesEnabled && renderInvoiceSummarySection() }
        <div className="shadow-card mb-20">
          <Row align="middle" className="mh-20 pv-15 b-border">
            <Col flex={1}>
              <div className="fs-18 fw-700">Financial Summary</div>
            </Col>
          </Row>
          { isArray(eventFinancialSummary.packages) && eventFinancialSummary.packages.map((eventPackage,index) => renderPackage(eventPackage,index))}
          { isArray(eventFinancialSummary.add_ons) && eventFinancialSummary.add_ons.map((addOn,index) => renderAddOn(addOn, index))}
          { (eventPackages.length > 0 || eventAddOns.length > 0) && (
            <>
              <Row align="middle" className="mh-20 pt-15 ph-10">
                <Col xs={16}>
                  <div className="fs-14 fw-700 text-right">Subtotal:</div>
                </Col>
                <Col xs={8}>
                  <div className="fs-14 fw-500 text-right">{ eventFinancialSummary.subtotal }</div>
                </Col>
              </Row>
              <Row align="middle" className="mh-20 pv-15 b-border ph-10">
                <Col xs={16}>
                  <div className="fs-14 fw-700 text-right">Tax:</div>
                </Col>
                <Col xs={8}>
                  <div className="fs-14 fw-500 text-right">{ eventFinancialSummary.tax }</div>
                </Col>
              </Row>
              <Row align="middle" className="mh-20 pv-15 ph-10">
                <Col xs={16}>
                  <div className="fs-14 fw-700 text-right">Total:</div>
                </Col>
                <Col xs={8}>
                  <div className="fs-14 fw-700 text-right">{ eventFinancialSummary.total_price }</div>
                </Col>
              </Row>
              { !isNewInvoicesEnabled && (
                <Row align="middle" className="mh-20 pb-15 b-border ph-10">
                  <Col xs={16}>
                    <div className="fs-14 fw-700 text-right">Payments:</div>
                  </Col>
                  <Col xs={8}>
                    <div className="fs-14 fw-500 text-right">({eventFinancialSummary.total_payments })</div>
                  </Col>
                </Row>
              )}
              { !isNewInvoicesEnabled && (
                <Row align="middle" className="mh-20 pv-15 ph-10">
                  <Col xs={16}>
                    <div className="fs-14 fw-700 text-right">Balance:</div>
                  </Col>
                  <Col xs={8}>
                    <div className="fs-14 fw-700 text-right">{ eventFinancialSummary.balance }</div>
                  </Col>
                </Row>
              )}
            </>
          )}
          { (eventPackages.length == 0 && eventAddOns.length == 0) && (
            <div className="p-15 text-center">
              <div className="bg-gray radius-8 p-15 text-center">
                <div>Assign packages or add-ons to this event to see the financial summary.</div>
                <button className="small-primary-button mt-15" onClick={() => onSelectTab("services")}>Add Services</button>
              </div>
            </div>
          )}
        </div>
        <div className="shadow-card mb-20">
          <Row align="middle" className="mh-20 pv-15 b-border">
            <Col flex={1}>
              <div className="fs-18 fw-700">{accountSettings.holding_fee_term}</div>
            </Col>
            { canEditFinancials && (
              <Col>
                <div className="plus-container mr-5" onClick={() => onEditAmounts()}>
                  <MdOutlineEdit style={{ fontSize: 20, color: '#536DFE'}}/>
                </div>
              </Col>
            )}
          </Row>
          <Row align="middle" className="mh-20 pv-15 b-border ph-10">
            <Col xs={12}>
              <div className="fs-14 fw-700">{accountSettings.holding_fee_term}</div>
              <div className="fs-14 fw-500">{ formatCurrencyString(eventFinancials.deposit, currency.code) }</div>
            </Col>
          </Row> 
        </div>
        { !isNewInvoicesEnabled && renderPaymentSection() }
        { renderPaymentModal() }
        { renderConfirmRemovePaymentModal() }
        { renderOtherFinancialsModal() }
      </FloatingContainer>
    )
  }

  return renderServicesTab();
}

export default EventFinancialsTab;

import React, { useEffect, useState } from "react";
import { Navigate, Link, useNavigate, useLocation } from "react-router-dom";
import { isNull, isEmpty, get } from 'lodash';

import { resentVerificationEmail, sendVerificationEmail } from '../../api';

const VerifyEmailPage = () => {

  const PAGE_TYPE_FROM_LOGIN = "login";
  const PAGE_TYPE_FROM_EMAIL = "email"
  const PAGE_TYPE_FROM_SIGNUP= "signup"
  const PAGE_TYPE_SUCCESS = "success"

  const [email, setEmail] = useState("");
  const [pageType, setPageType] = useState(PAGE_TYPE_FROM_EMAIL);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0)

    const userEmail = get(location, 'state.email', null);
    const from = get(location, 'state.from', null);

    if (!isNull(userEmail)) {
      setEmail(userEmail)
    }
    if (!isNull(from)) {
      setPageType(from)
    }
    setLoading(false)
  }, [])

  const resendEmail = () => {
    sendVerificationEmail({ email: email }).then(response => {
    }).catch(e => console.log(e))
  }

  const sendEmail = () => {
    sendVerificationEmail({ email: email }).then(response => {
      setPageType(PAGE_TYPE_SUCCESS)
    }).catch(e => console.log(e))
  }

  const renderContent = () => {
    if (!loading && isEmpty(email)) {
      return <Navigate replace to="/login"/>
    } else if (pageType === PAGE_TYPE_FROM_LOGIN) {
      return renderPageFromLogin()
    } else if (pageType === PAGE_TYPE_SUCCESS) {
      return renderSuccessPage()
    } else {
      return renderPage()
    }
  }

  const renderPage = () => {
    return (
      <div className="center-content-box">
        <div className="center-content-box--title">Almost there!</div>
        <div className="center-content-box--subtitle">We have sent a confirmation email to <b>{email}</b>. Please follow the instructions in the email to continue.</div>
        <div className="center-content-box--form">
          <button className="primary-button" onClick={() => navigate("/login")}>I've verified my email</button>
          <div className="center-content-box--link-container">
            <div className="center-content-box--link" onClick={resendEmail}>Resend email</div>
          </div>
        </div>
      </div>
    )
  }

  const renderPageFromLogin = () => {
    return (
      <div className="center-content-box">
        <div className="center-content-box--title">Verify your email!</div>
        <div className="center-content-box--subtitle">It looks like your email address is not verified yet. Click on the button below to send a verification email to <b>{email}</b>.</div>
        <div className="center-content-box--form">
          <button className="primary-button" onClick={sendEmail}>Send verification email</button>
          <div className="center-content-box--link-container">
            <Link to="/login" className="center-content-box--link">Sign In</Link>
          </div>
        </div>
      </div>
    )
  }

  const renderSuccessPage = () => {
    return (
      <div className="center-content-box">
        <div className="center-content-box--title">Email sent!</div>
        <div className="center-content-box--subtitle">Please check your email for a verification link.</div>
        <div className="center-content-box--form">
          <button className="primary-button" onClick={() => navigate("/login")}>Sign in</button>
          <div className="center-content-box--link-container">
            <div className="center-content-box--link" onClick={resendEmail}>Resend email</div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="center-content-page">
      { renderContent() }
    </div>
  );
}

VerifyEmailPage.propTypes = {};

export default VerifyEmailPage;

import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Row, Col, Form, Input } from 'antd';
import { submitContactForm } from '../../api';
import FloatingContainer from '../../components/floatingContainer'
import FadeDownWhileInView from '../../components/fadeDownWhileInView'
import { FiPhoneCall, FiMail } from "react-icons/fi";
import useDocumentTitle from '../../hooks/useDocumentTitle';
import djpcConstants from '../../constants/djpc';

const ContactPage = () => {

  useDocumentTitle("Contact")
  const navigate = useNavigate();
  const [form] = Form.useForm();

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const onSubmit = async (values) => {
    try {
      await submitContactForm(values);
      navigate("/contact/thank-you")
    } catch(error) {

    }
  }

  const renderConctactForm = () => {
    return (
      <FadeDownWhileInView delay={0.2} className="shadow-card p-30">
        <div className="fs-24 fw-700 line-1-2 mb-30 text-center">Send us a message</div>
        <Form form={form} layout="vertical" name="client" onFinish={onSubmit}>
          <Row gutter={[20,20]} className="mt-15">
            <Col xs={24} md={12}>
              <Form.Item
                name={"first_name"}
                rules={[{ required: true, message: `First Name is required!`, validateTrigger: "onBlur" }]}
              >
                <Input size="large" placeholder="First Name"/>
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                name={"last_name"}
                rules={[{ required: true, message: `Last Name is required!`, validateTrigger: "onBlur" }]}
              >
                <Input size="large" placeholder="Last Name"/>
              </Form.Item>
            </Col>
            <Col xs={24}>
              <Form.Item
                name={"email"}
                rules={[
                  { required: true, message: `Email is required!`, validateTrigger: "onBlur" },
                  { type: "email", message: `Please enter a valid email address` }
                ]}
              >
                <Input size="large" placeholder="Email"/>
              </Form.Item>                
            </Col>
            <Col xs={24}>
              <Form.Item
                name={"message"}
                rules={[
                  { required: true, message: `Message is required!`, validateTrigger: "onBlur" }
                ]}
              >
                <Input.TextArea rows={4} size="large" placeholder="Message"/>
              </Form.Item>   
            </Col>
          </Row>
          <button className="primary-button" type="submit">
            Submit
          </button>
        </Form>
      </FadeDownWhileInView>
    )
  }

  const renderContent = () => {
      return (
      <div>
        <FloatingContainer className="bg-blue-gradient" verticalPadding={60} maxWidth={600}>
          <FadeDownWhileInView className="fs-30 fw-700 text-center c-white">Get in touch</FadeDownWhileInView>
          <FadeDownWhileInView className="fs-16 c-white mt-20 line-1-3 text-center ph-20">Have a question? We'd love to hear from you. Send us a message, and we'll respond as soon as possible.</FadeDownWhileInView>
        </FloatingContainer>
        <FloatingContainer className="bg-gray ph-20" verticalPadding={60} maxWidth={800}>
          <Row gutter={[30]} align="middle">
            <Col xs={24}>
              { renderConctactForm() }
            </Col>
          </Row>
        </FloatingContainer>
        <FloatingContainer className="bg-white text-center p-20" verticalPadding={60} maxWidth={800}>
          <Row gutter={[0,50]}>
            {/* <Col xs={24} sm={12}>
              <FadeDownWhileInView delay={0.4}>
                <div className="display-flex flex-1 flex-row justify-content-center">
                  <div style={{ height: 100, width: 100, backgroundColor: "#eef0ff", display: "flex", borderRadius: 50, justifyContent: 'center', alignItems: 'center'}}>
                    <FiPhoneCall size={50} color="#536DFE"/>
                  </div>
                </div>
                <div className="fs-20 fw-700 line-1-2 mt-20 text-center">Call Us</div>
                <div className="fs-16 c-text-gray mt-10 line-1-3">{ djpcConstants.PHONE_NUMBER }</div>
              </FadeDownWhileInView>
            </Col> */}
            <Col xs={24} sm={24}>
              <FadeDownWhileInView delay={0.6}>
                <div className="display-flex flex-1 flex-row justify-content-center">
                  <div style={{ height: 100, width: 100, backgroundColor: "#eef0ff", display: "flex", borderRadius: 50, justifyContent: 'center', alignItems: 'center'}}>
                    <FiMail size={50} color="#536DFE"/>
                  </div>
                </div>
                <div className="fs-20 fw-700 line-1-2 mt-20 text-center">Email Us</div>
                <div className="fs-16 c-text-gray mt-10 line-1-3">{ djpcConstants.INFO_EMAIL }</div>
              </FadeDownWhileInView>
            </Col>
          </Row>
        </FloatingContainer>
      </div>
      )
  }

  return (
    <div>
      { renderContent() }
    </div>
  );
}

export default ContactPage;

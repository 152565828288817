import { useState, useEffect } from "react";
import StorageKeys from '../constants/storageKeys';
import { isEmpty } from "lodash";
import useApi from './useApi'
import { getAccountCurrency } from '../api'

export default function useCurrency() {

  const [currency, setActualCurrency] = useState({})

  const [sendRequest] = useApi()

  useEffect(() => {
    const currencyObject = JSON.parse(localStorage.getItem(StorageKeys.CURRENCY)) || {}
    if (isEmpty(currencyObject)) {
      populateCurrency()
    } else {
      setActualCurrency(currencyObject)
    }
  }, []);

  const populateCurrency = async () => {
    try {
      const currencyResponse = await sendRequest(getAccountCurrency())
      setCurrency(currencyResponse)
    } catch {}
  }

  const setCurrency = (currency) => {
    if (isEmpty(currency)) {
      localStorage.removeItem(StorageKeys.CURRENCY);
    } else {
      localStorage.setItem(StorageKeys.CURRENCY, JSON.stringify(currency));
    }
    setActualCurrency(currency)
  }

  const getCurrency = async () => {
    try {
      const currencyObject = JSON.parse(localStorage.getItem(StorageKeys.CURRENCY)) || {}
      if (isEmpty(currencyObject)) {
        const currencyResponse = await sendRequest(getAccountCurrency())
        localStorage.setItem(StorageKeys.CURRENCY, JSON.stringify(currencyResponse));
        return currencyResponse
      } else {
        return currencyObject
      }
    } catch {
      return {}
    }
  }

  return [currency, setCurrency, getCurrency];
}
import React from "react";
import { Row, Col, Grid } from 'antd';
import { formatCurrencyString } from '../helpers/contractHelper';
import StatusTag from './statusTag';
import { formatDateShort } from '../helpers/dateHelper';

const InvoicePayments = props => {
  const { invoice = {}, payments = [], ghost = false } = props;

  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();

  const renderPaymentRow = (item, index) => {
    return (
      <Row className="pv-30 t-border">
        <Col xs={6}>
          {index + 1} of {payments.length}
        </Col>
        <Col xs={6}>
          {formatCurrencyString(item.total, invoice.currency)}
          { item.tip_amount > 0 && (
            <div className="fs-12 c-text-gray">
              (includes a {formatCurrencyString(item.tip_amount, invoice.currency)} tip)
            </div>
          )} 
        </Col>
        <Col xs={6}>
          {formatDateShort(item.payment_date, invoice.account_settings)}
        </Col>
        <Col xs={6}>
          <StatusTag status={item.status} size="medium"/>
        </Col>
      </Row>
    )
  }

  const renderContent = () => {
    const paddingClass = screens.md ? "p-30" : "p-20"
    const containerClass = ghost ? "" : `shadow-card-square ${paddingClass} mb-20`
    return (
      <div className={containerClass}>
        <div className="fw-700 fs-16">Payments</div>
        <Row className="b-border mt-20 pb-10 c-text-gray fs-12 fw-700">
          <Col xs={6}>
            PAYMENT
          </Col>
          <Col xs={6}>
            AMOUNT
          </Col>
          <Col xs={6}>
            PAID ON
          </Col>
          <Col xs={6}>
            STATUS
          </Col>
        </Row>
        { payments.map((x,i) => renderPaymentRow(x,i))}
      </div>
    )
  }

  return renderContent()
};

export default InvoicePayments;

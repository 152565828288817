export default {
  AUTH: "djpc:auth",
  SPOTIFY_AUTH: "djpc:spotifyauth",
  CURRENT_EVENT: "djpc:currentevent",
  DEVICE_ID: "djpc:deviceid",
  EVENT_FILTERS: "djpc:eventfilters",
  CONTRACT_FILTERS: "djpc:contractfilters",
  CALENDAR_FILTERS: "djpc:calendarfilters",
  DEFAULT_COUNTRY: "djpc:defaultcountry",
  DEFAULT_STATE: "djpc:defaultstate",
  DEFAULT_PHONE_COUNTRY: "djpc:defaultphonecountry",
  CURRENCY: "djpc:currency",
  ACCOUNT_SETTINGS: "djpc:accountsettings",
  SHOW_ALL_EVENTS_SUMMARY: "djpc:showalleventssummary",
  SHOW_ALL_EVENTS_CALENDAR: "djpc:showalleventscalendar",
  SHOW_ALL_COMPANY_TASKS: "djpc:showallcompanytasks",
  BETA_FEATURES: "djpc:betafeatures"
}
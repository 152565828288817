import React, { useEffect, useState, useContext, useRef } from "react";
import { useNavigate, useLocation } from 'react-router-dom';
import { Row, Col, Dropdown, Menu, Grid, Form, Select, Pagination, Modal, notification } from 'antd';
import { isEmpty, isUndefined, isNull } from 'lodash';
import qs from "qs";
import { 
  getTimeOffByAccount,
  getAdminUsers,
  deleteTimeOff,
  updateTimeOffStatus
} from '../../../api';
import LoadingSpinner from '../../../components/loading';
import AdminContent from '../../../components/adminContent';
import StatusTag from '../../../components/statusTag';
import FloatingContainer from '../../../components/floatingContainer'
import TimeOffModal from '../../../modals/timeOffModal'
import useApi from '../../../hooks/useApi';
import useAccountSettings from '../../../hooks/useAccountSettings';
import useDocumentTitle from '../../../hooks/useDocumentTitle';
import { EditOutlined, LoadingOutlined } from '@ant-design/icons';
import CalendarRepeatOptions from '../../../constants/calendarRepeatOptions';
import TimeOffStatus from '../../../constants/timeOffStatus';
import Permissions from '../../../constants/permissions';
import { MdOutlineMoreHoriz, MdOutlineKeyboardArrowDown, MdOutlineMoreTime, MdOutlineClose } from "react-icons/md";
import { BsArrowRight } from "react-icons/bs";
import { FiTrash } from "react-icons/fi";
import emptySearchImage from '../../../images/empty-search-icon.png';
import FadeWhileInView from '../../../components/fadeWhileInView'
import { formatEventDateShort, formatTime, formatEventDateLong } from "../../../helpers/dateHelper";
import ErrorCard from '../../../components/errorCard';
import AppContext from '../../../app/context';
import { hasPermission } from '../../../helpers/permissionHelper';

const TimeOffPage = () => {

  const [isLoading, setLoading] = useState(true);
  const [isContentLoading, setContentLoading] = useState(true);
  const [isError, setError] = useState(false);
  const [updatingStatusId, setUpdatingStatusId] = useState(null);
  const [timeOffRequests, setTimeOffRequests] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [selectedStaff, setSelectedStaff] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [limit, setLimit] = useState(50);
  const [currentOffset, setCurrentOffset] = useState(0);
  const [selectedFilter, setSelectedFilter] = useState("all");
  const [isTimeOffModalVisible, setTimeOffModalVisible] = useState(false);
  const [isConfirmRemoveTimeOffMobalVisible, setConfirmRemoveTimeOffMobalVisible] = useState(false);
  const [selectedTimeOff, setSelectedTimeOff] = useState({});

  useDocumentTitle("Time Off Requests")
  const [sendRequest] = useApi()
  const navigate = useNavigate();
  const location = useLocation()
  const [accountSettings] = useAccountSettings()
  const { auth, setAuth } = useContext(AppContext);
  const timeOffModalRef = useRef();

  const user = auth.user ? auth.user : {}
  const canViewAllTimeOff = hasPermission(user, Permissions.VIEW_TIME_OFF);
  const canManageTimeOff = hasPermission(user, Permissions.MANAGE_TIME_OFF);

  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();

  var primaryTabs = [
    { name: "All", key: "all" },
    { name: "Pending", key: "pending" },
    { name: "Approved", key: "approved" },
    { name: "Rejected", key: "rejected" }
  ]

  useEffect(() => {
    window.scrollTo(0, 0);
    refreshPage()
  }, []);

  const refreshPage = async () => {
    try {
      const queryStrings = qs.parse(location.search, { ignoreQueryPrefix: true })

      // Apply status param
      var appliedStatus = null;
      if (queryStrings.status) {
        appliedStatus = queryStrings.status;
      } else {
        appliedStatus = "pending"
      }

      await updateResults(appliedStatus, 0, 1)

      if (canViewAllTimeOff) {
        const employeeResults = await sendRequest(getAdminUsers());
        setEmployees(employeeResults)
      }
    } catch (error) {
      setError(true)
    } finally {
      setLoading(false)
    }
  }

  const handleAddTimeOff = () => {
    if (timeOffModalRef.current) {
      timeOffModalRef.current.handleAddTimeOff();
    }
  }

  const handleEditTimeOff = (timeOff) => {
    if (timeOffModalRef.current) {
      timeOffModalRef.current.handleEditTimeOff(timeOff);
    }
  }

  const onConfirmTimeOffDelete = (timeOff) => {
    setSelectedTimeOff(timeOff)
    setConfirmRemoveTimeOffMobalVisible(true)
   }

   const confirmTimeOffDelete = async () => {
    try {
      await sendRequest(deleteTimeOff(selectedTimeOff.time_off_id))
      await updateResults(selectedFilter, 0, selectedStaff)
      setConfirmRemoveTimeOffMobalVisible(false)
    } catch(error) {
      setConfirmRemoveTimeOffMobalVisible(false)
    }
  }

  const refreshTimeOff = () => {
    updateResults(selectedFilter, 0, selectedStaff)
  }
  
  const menuItem = (text, icon, action = () => {}) => {
    return (
      <Menu.Item className="ph-15 pv-10">
        <div onClick={action} className="display-flex flex-middle">
          {icon}<span style={{ marginLeft: 12}}>{text}</span>
        </div>
      </Menu.Item>
    )
  }

  const menu = (item) => {
    return (
      <Menu>
        { menuItem("Edit", <EditOutlined/>, () => handleEditTimeOff(item))}
        { menuItem("Delete", <FiTrash/>, () => onConfirmTimeOffDelete(item))}
      </Menu>
    )
  };

  const updateResults = async (filter, offset, staff) => {
    try {
      // set loading
      setContentLoading(true)

      // Set state variables
      setSelectedFilter(filter)
      setCurrentOffset(offset)
      setSelectedStaff(staff)
      updateUrl(filter)

      const filters = {
        filter: filter,
        offset: offset,
        limit: limit,
        staff: staff == 1 || isUndefined(staff) ? [] : [staff]
      }
      // Fetch records
      const results = await sendRequest(getTimeOffByAccount(filters))
      setTimeOffRequests(results.results)
      setTotalRecords(results.metadata.total)
    } catch (error) {
      console.log(error)
    } finally {
      setContentLoading(false)
    }
  }

  const onFilterChange = async (value) => {
    updateResults(value, 0, selectedStaff)
  }

  const isValid = (value) => {
    return !isNull(value) && !isUndefined(value) && !isEmpty(value)
  }

  const updateUrl = (status) => {
    var url = "/admin/time-off"
    var params = {}

    // Add individual filters
    if (isValid(status)) {
      params.status = status
    }

    // Stringify params
    const paramString = qs.stringify(params)

    if (!isEmpty(params)) {
      url += `?${paramString}`
    }

    navigate(url, { replace: true })
  }

  const onStaffChange = (value) => {
    updateResults(selectedFilter, 0, value)
  }

  const selectPagination = (page, pageSize) => {
    const newOffset = limit * (page - 1)
    setLimit(pageSize)
    updateResults(selectedFilter, newOffset, selectedStaff)
  }

  const onUpdateTimeOffStatus = async (timeOff, status) => {
    try {
      if (!isNull(updatingStatusId)) { return }
      setUpdatingStatusId(`${status}-${timeOff.time_off_id}`)
      await sendRequest(updateTimeOffStatus(timeOff.time_off_id, { status: status }))
      refreshTimeOff()
      notification.success({
        message: 'Success!',
        description: 'You have updated this time off request.',
        duration: 3
      });
    } catch {
      notification.error({
        message: 'Error',
        description: 'Something went wrong. Please try again.',
        duration: 3
      });
    } finally {
      setUpdatingStatusId(null)
    }
  }

  const renderHeader = () => {
    return (
      <Row align="middle" className="p-20">
        <Col flex={1}>
          <div className="fs-24 fw-700">Time Off Requests</div>
          <div className="blue-link flex-row flex-1 flex-middle" onClick={() => navigate("/admin/docs/time-off")}>Learn more <BsArrowRight style={{ marginLeft: 5 }}/></div>
        </Col>
        <Col>
          <button className="page-title-button" onClick={handleAddTimeOff}>Add Time Off</button>
        </Col>
      </Row>
    )
  }

  const renderTimeOffRange = (timeOff) => {
    if (timeOff.start_date == timeOff.end_date) {
      const time = timeOff.all_day ? "All day" : `${formatTime(timeOff.start_time, accountSettings)} - ${formatTime(timeOff.end_time, accountSettings)}`
      return (
        <>
          <div className="fs-12">{formatEventDateLong(timeOff.start_date, accountSettings)}</div>
          <div className="line-1-4 fs-12">{time}</div>
        </>
      )
    } else {
      const startTime = timeOff.all_day ? "" : `at ${formatTime(timeOff.start_time, accountSettings)}`
      const endTime = timeOff.all_day ? "" : `at ${formatTime(timeOff.end_time, accountSettings)}`
      return (
        <>
          <div className="fs-12"><span className="fw-600">Start:</span> {formatEventDateLong(timeOff.start_date, accountSettings)} {startTime}</div>
          <div className="line-1-4 fs-12"><span className="fw-600">End:</span> {formatEventDateLong(timeOff.end_date, accountSettings)} {endTime}</div>
        </>
      )
    }
  }

  const renderTimeOffRecurrenceText = (timeOff) => {
    var repeatText = ""
    if (timeOff.recurrence_type != CalendarRepeatOptions.NONE) {
      repeatText = `Repeats ${timeOff.recurrence_type}`
      if (!isNull(timeOff.recurrence_end_date)) {
        repeatText += ` until ${formatEventDateShort(timeOff.recurrence_end_date, accountSettings)}`
      }
    }
    return repeatText
  }

  const renderApprovalButtons = (timeOff) => {
    return (
      <>
        <Col flex={0}>
          <button style={{ width: 100 }} className="small-primary-button secondary" onClick={() => onUpdateTimeOffStatus(timeOff, TimeOffStatus.REJECTED)}>{ updatingStatusId == `${TimeOffStatus.REJECTED}-${timeOff.time_off_id}` ? <LoadingOutlined/> : "Reject"}</button>
        </Col>
        <Col flex={0}>
          <button style={{ width: 100 }} className="small-primary-button" onClick={() => onUpdateTimeOffStatus(timeOff, TimeOffStatus.APPROVED)}>{ updatingStatusId == `${TimeOffStatus.APPROVED}-${timeOff.time_off_id}` ? <LoadingOutlined/> : "Approve"}</button>
        </Col>
      </>
    )
  }

  const renderTimeOff = (timeOff, index) => {
    const showButtons = timeOff.status == TimeOffStatus.PENDING && canManageTimeOff
    return (
      <div className="shadow-card-square mt-10" key={index}>
        <div className="b-border ph-20 pv-15">
          <Row align="middle" gutter={[15,15]} wrap={false}>
            { screens.md && (
              <Col flex={0}>
                <div className="card-icon"><MdOutlineMoreTime/></div>
              </Col>
            )}
            { screens.md ? (
              <Col flex={1}>
                <div className="fw-700 line-1-4">{timeOff.first_name} {timeOff.last_name}</div>
                { renderTimeOffRange(timeOff) }
                <div className="line-1-4 c-text-gray fs-12 f-italic">{ renderTimeOffRecurrenceText(timeOff) }</div>
              </Col>
            ) : (
              <Col flex={1}>
                <StatusTag status={timeOff.status} size="small"/>
                <div className="fw-700 line-1-4 mt-10">{timeOff.first_name} {timeOff.last_name}</div>
                { renderTimeOffRange(timeOff) }
                <div className="line-1-4 c-text-gray fs-12 f-italic">{ renderTimeOffRecurrenceText(timeOff) }</div>
              </Col>
            )}
            { screens.md && (
              <Col flex={0}>
                <StatusTag status={timeOff.status} size="small"/>
              </Col>
            )}
            <Col flex={0}>
              <div className="display-flex" style={{ justifyContent: 'flex-end'}}>
                <Dropdown overlay={menu(timeOff)} placement="bottomRight" trigger="click">
                  <div className="dots-container">
                    <MdOutlineMoreHoriz style={{ fontSize: 24, color: '#999'}}/>
                  </div>
                </Dropdown>
              </div>
            </Col>
          </Row>
        </div>
        <div className="p-20">
          <Row gutter={[10,15]} wrap={false}>
            <Col flex={1}>
              <div className="fs-10 c-text-gray">REASON</div>
              <div className="line-breaks">{timeOff.description ?? "--"}</div>
            </Col>
            { showButtons && screens.md && renderApprovalButtons(timeOff) }
          </Row>
          { !screens.md && showButtons && (
            <Row gutter={[10,15]} className="t-border pt-20 mt-20" wrap={false}>
              <Col flex={1}></Col>
              { renderApprovalButtons(timeOff) }
            </Row>
          )}
        </div>
      </div>
    )
  }

  const renderTab = (text, value) => {
    const isSelected = selectedFilter == value
    const selectedStyle = isSelected ? { borderBottom: "2px solid #536DFE", color: "#536DFE", userSelect: 'none' } : { userSelect: 'none' }
    return (
      <div className={`ph-10 pv-10 mt-15 mr-20 fw-600`} style={selectedStyle} onClick={() => onFilterChange(value)}>
        { text }
      </div>
    )
  }

  const mobileTabMenu = () => {
    return (
      <Menu>
        { primaryTabs.map((x,i) => (
          <Menu.Item key={i}>
            <div onClick={() => onFilterChange(x.key)}>
              { x.name }
            </div>
          </Menu.Item>
        ))}
      </Menu>
    )
  };

  const renderTimeOffBox = () => {
    const tabRecord = primaryTabs.find(x => x.key == selectedFilter)
    const tabName = !isEmpty(tabRecord) ? tabRecord.name : ""
    return (
      <>
        <div className="shadow-card-square">
          { screens.sm ? (
            <div className="display-flex ph-20 b-border">
              { renderTab("All", "all") }
              { renderTab("Pending", "pending") }
              { renderTab("Approved", "approved") }
              { renderTab("Rejected", "rejected") }
            </div>
          ) : (
            <div className="ph-20 pt-10">
              <Dropdown overlay={mobileTabMenu()} placement="bottomRight" trigger="click">
                <div className="tab-dropdown mt-10">
                  <div className="tab-dropdown--text">{tabName}</div>
                  <div className="tab-dropdown--arrow"><MdOutlineKeyboardArrowDown/></div>
                </div>
              </Dropdown>
            </div>
          )}
          { canManageTimeOff && (
            <div className="p-15">
              <div className="display-flex flex-row flex-middle">
                <div className="flex-1">
                  <Select 
                    showSearch
                    size="large" 
                    placeholder="Select staff..."
                    value={selectedStaff} 
                    allowClear 
                    style={{ width: "100%" }}  
                    onChange={(value) => onStaffChange(value)}
                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                  >
                    <Select.Option value={1}>All Staff</Select.Option>
                    { employees.map(x => (
                      <Select.Option key={x.user_id} value={x.user_id}>{x.first_name + " " + x.last_name}</Select.Option>
                    ))}
                  </Select>
                </div>
              </div>
            </div>
          )}
        </div>
        { renderResults() }
      </>
    )
  }

  const getCurrentPage = () => {
    return (currentOffset + limit) / limit
  }

  const renderResults = () => {
    if (isContentLoading) {
      return (
        <div className="shadow-card pv-50 mt-10">
          <LoadingSpinner/>
        </div>
      )
    }
    if (timeOffRequests.length > 0) {
      return (
        <>
          { timeOffRequests.map((timeOff,i) => renderTimeOff(timeOff,i)) }
          { totalRecords > limit && (
            <div className="mt-10 text-right">
              <Pagination defaultCurrent={1} defaultPageSize={limit} pageSize={limit} current={getCurrentPage()} total={totalRecords} onChange={(page,pageSize) => selectPagination(page, pageSize)}/>
            </div>
          )}
        </>
      )
    } else {
      return (
        <div className="shadow-card mt-10">
          <FadeWhileInView duration={0.5} className="ph-20 pv-40 text-center">
            <div>
              <img src={emptySearchImage} width={200}/>
            </div>
            <div className="fs-18 fw-700 mt-30">
              No time off requests found
            </div>
            <div className="fs-14 fw-500 c-text-gray">
              Adjust your filter and try again!
            </div>
          </FadeWhileInView>
        </div>
      )
    }
  }

  const renderRemoveTimeOffMessage = () => {
    const isMyRequest = user.user_id == selectedTimeOff.user_id
    if (selectedTimeOff.recurrence_type == CalendarRepeatOptions.NONE) {
      if (isMyRequest) {
        return <div className="fw-500 fs-14 mt-20 mb-20 text-center">Are you sure you would like to remove this time off request? This action cannot be undone.</div>
      } else {
        return <div className="fw-500 fs-14 mt-20 mb-20 text-center">Are you sure you would like to remove this time off request from <span className="fw-700">{selectedTimeOff.first_name} {selectedTimeOff.last_name}</span>? This action cannot be undone.</div>
      }
    } else {
      if (isMyRequest) {
        return <div className="fw-500 fs-14 mt-20 mb-20 text-center">Are you sure you would like to remove this time off request? This will remove <span className="fw-700">all items</span> in this repeating series.</div>
      } else {
        return <div className="fw-500 fs-14 mt-20 mb-20 text-center">Are you sure you would like to remove this time off request from <span className="fw-700">{selectedTimeOff.first_name} {selectedTimeOff.last_name}</span>? This will remove <span className="fw-700">all items</span> in this repeating series.</div>
      }
    }
  }
  
  const renderConfirmRemoveTimeOffModal = () => {
    return (
      <Modal visible={isConfirmRemoveTimeOffMobalVisible} closable={false} footer={null} width={400} wrapClassName="rounded-modal">
        <div className="text-right" onClick={() => setConfirmRemoveTimeOffMobalVisible(false)}><MdOutlineClose size={30} color={"#CCC"}/></div>
        <div className="mt-10">
          <div className="fw-700 fs-20 text-center">Remove Time Off</div>
          { renderRemoveTimeOffMessage() }
          <button className="primary-button warning" type="button" onClick={() => confirmTimeOffDelete()}>Remove Time Off</button>
          <div className="text-center mt-15">
            <div className="blue-link" onClick={() => setConfirmRemoveTimeOffMobalVisible(false)}>Cancel</div>
          </div>
        </div>
      </Modal>
    )
  }

  const renderContent = () => {
    if (isLoading) {
      return <LoadingSpinner/>
    }
    if (isError) {
      return <ErrorCard/>
    }
    return (
      <FloatingContainer className="ph-20" verticalPadding={20} maxWidth={1000}>
        { renderTimeOffBox() }
        { renderConfirmRemoveTimeOffModal() }
        <TimeOffModal
          isTimeOffModalVisible={isTimeOffModalVisible}
          setTimeOffModalVisible={setTimeOffModalVisible}
          employees={employees}
          setSelectedTimeOff={setSelectedTimeOff}
          selectedTimeOff={selectedTimeOff}
          refreshTimeOff={refreshTimeOff}
          ref={timeOffModalRef}
        />
      </FloatingContainer>
    )
  }

  return (
    <AdminContent header={renderHeader()} body={renderContent()}/>
  );
}

export default TimeOffPage;

import React, { useEffect, useState } from "react";
import { Row, Col } from 'antd';
import LoadingSpinner from '../../components/loading';
import AdminContent from '../../components/adminContent';
import PaymentStatus from '../../components/paymentStatus';
import useApi from '../../hooks/useApi';
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const AddUpdatePaymentMethodCompletePage = (props) => {

  const [isLoading, setLoading] = useState(false);

  const [sendRequest] = useApi()

  useEffect(() => {
    window.scrollTo(0, 0);
    refreshPage()

    // const secret = new URLSearchParams(window.location.search).get(
    //   'setup_intent_client_secret'
    // );
    // setClientSecret(secret)
    // console.log(secret)
  }, []);

  const refreshPage = () => {
    // sendRequest(getStripeSetupIntent()).then(response => {
    //   console.log(response)
    //   setClientSecret(response.client_secret)
    //   setLoading(false)
    // }).catch((e) => {
    //   setLoading(false)
    // })
  }

  const renderHeader = () => {
    return (
      <Row align="middle" gutter={[15,15]} className="ph-20" style={{ height: 72 }}>
        <Col flex={1}>
          <div className="admin-page-title">Payment Method Complete</div>
        </Col>
        <Col>
          {/* <button className="page-title-button" onClick={() => navigate("/admin/recommended-playlists/new")}>New</button> */}
        </Col>
      </Row>
    )
  }

  const renderElements = () => {
    // if (isNull(clientSecret)) {
    //   return null
    // }

    // const options = {
    //   // passing the client secret obtained in step 2
    //   clientSecret: clientSecret,
    //   // Fully customizable with appearance API.
    //   // appearance: {/*...*/},
    // };
  
    return (
      <Elements stripe={stripePromise}>
        <PaymentStatus />
      </Elements>
    );
  }

  const renderContent = () => {
    return (
      <div className="center-content-box ph-20 pb-20">
        { renderElements() }
      </div>
    )
  }


  if (isLoading) {
    return <LoadingSpinner/>
  }

  return (
    <div className="center-content-page">
      { renderContent() }
    </div>
  );
}

export default AddUpdatePaymentMethodCompletePage;

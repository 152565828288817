import React, { useEffect, useState, useContext } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import qs from "qs";
import useApi from '../../../hooks/useApi';
import useCurrency from '../../../hooks/useCurrency';
import useAccountSettings from '../../../hooks/useAccountSettings';
import { Row, Col, Input, Checkbox, notification, Grid, Select, Modal } from 'antd';
import { sendInvoice, getInvoicePreviewV2, getEventClients, getAdminUsers, updateInvoiceStatus } from '../../../api';
import LoadingSpinner from '../../../components/loading';
import FloatingContainer from '../../../components/floatingContainer'
import StatusTag from '../../../components/statusTag'
import { HiOutlineArrowLeft } from "react-icons/hi";
import { UserOutlined, LoadingOutlined } from '@ant-design/icons';
import { isEmpty, isNull, cloneDeep } from "lodash";
import { formatCurrencyString } from '../../../helpers/contractHelper';
import { formatDateMedium } from '../../../helpers/dateHelper';
import invoiceStatus from "../../../constants/invoiceStatus";
import PaymentScheduleStatus from "../../../constants/paymentScheduleStatus";
import AppContext from '../../../app/context';
import { MdOutlineClose } from "react-icons/md";
const BASE_URL = process.env.REACT_APP_BASE_URL;

const SendInvoicePage = () => {

  const [isLoading, setLoading] = useState(true);
  const [isSending, setSending] = useState(false);
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [sendMeCopy, setSendMeCopy] = useState(false);

  const [eventInvoice, setEventInvoice] = useState({});
  const [billToFirstName, setBillToFirstName] = useState("");
  const [billToLastName, setBillToLastName] = useState("");
  const [billToEmail, setBillToEmail] = useState("");
  const [dueDate, setDueDate] = useState("");
  const [amountDue, setAmountDue] = useState("Payment");
  const [fromPage, setFromPage] = useState("");
  const [clients, setClients] = useState([]);
  const [ccEmails, setCCEmails] = useState([]);
  const [isEmailErrorModalVisible, setEmailErrorModalVisible] = useState(false);

  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();
  const [sendRequest] = useApi()
  const [_c, _s, getCurrency] = useCurrency()
  const [_d, _sa, getAccountSettings] = useAccountSettings()
  const { auth, _ } = useContext(AppContext);
  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();

  const eventId = params.event_id;
  const invoiceId = params.id;

  useEffect(() => {
    window.scrollTo(0, 0);
    refreshPage()
    const queryStrings = qs.parse(location.search, { ignoreQueryPrefix: true })
    if (queryStrings.from) {
      setFromPage(queryStrings.from)
    }
  }, []);

  const refreshPage = async () => {
    try {
      const invoiceResults = await getInvoicePreviewV2(invoiceId)
      const results = invoiceResults.data
      const accountCurrency = await getCurrency()
      const accountSettings = await getAccountSettings()
      setEventInvoice(results)

      var firstName = ""
      if (results.bill_to_user && !isEmpty(results.bill_to_user)) {
        const user = results.bill_to_user
        setBillToFirstName(user.first_name)
        setBillToLastName(user.last_name)
        setBillToEmail(user.email)
        firstName = ` ${user.first_name}`
      }

      const paymentSchedules = results.payment_schedules ?? []
      const unpaidSchedules = cloneDeep(paymentSchedules).filter(x => x.status != PaymentScheduleStatus.PAID)
      var nextPaymentText = ""
      if (paymentSchedules.length > 1 && unpaidSchedules.length > 0) {
        const nextPayment = unpaidSchedules[0]
        nextPaymentText = `Your next payment of ${formatCurrencyString(nextPayment.amount, accountCurrency.code)} is due by ${formatDateMedium(nextPayment.due_date, accountSettings, true)}. `
      }

      const invoiceDueDate = results.due_date ? formatDateMedium(results.due_date, accountSettings, true) : ""
      const invoiceAmountDue = results.total ? formatCurrencyString(results.total, accountCurrency.code) : formatCurrencyString(0, accountCurrency.code)
      const invoiceSubject = results.company_name ? `Invoice #${results.invoice_number} from ${results.company_name}` : "Invoice"
      setDueDate(invoiceDueDate)
      setAmountDue(invoiceAmountDue)
      setSubject(invoiceSubject)
      setMessage(`Hi${firstName}, \n\nHere is your invoice for ${invoiceAmountDue} that's due by ${invoiceDueDate}. ${nextPaymentText}Click the button below for details.\n\nThanks,\n${auth.user.first_name} ${auth.user.last_name}`)

      const clientResults = await sendRequest(getEventClients(results.event_id))
      const employeeResults = await sendRequest(getAdminUsers())
      const filteredEmployees = employeeResults.filter(x => x.user_id != auth.user.user_id)
      var filteredClients = clientResults
      if (results.bill_to_user_id) {
        filteredClients = clientResults.filter(x => x.user_id != results.bill_to_user_id)
      }
      const clientOptions = filteredClients.map(x => {
        return {
          value: x.email,
          label: `${x.first_name} ${x.last_name} (${x.email})`
        }
      })
      const employeeOptions = filteredEmployees.map(x => {
        return {
          value: x.email,
          label: `${x.first_name} ${x.last_name} (${x.email})`
        }
      })
      var emailOptions = clientOptions.concat(employeeOptions)
      setClients(emailOptions)
    } finally {
      setLoading(false)
    }
  }

  const onSendInvoice = async () => {
    try {
      if (!isAllEmailsValid()) {
        setEmailErrorModalVisible(true)
        return
      }
      setSending(true)
      const body = {
        subject: subject,
        message: message,
        send_copy: sendMeCopy,
        cc_emails: ccEmails
      }
      await sendRequest(sendInvoice(invoiceId, body))
      navigate(`/admin/invoices/${invoiceId}/confirmation`)
    } catch {
      setSending(false)
      notification.error({
        message: 'Error',
        description: 'Something went wrong! Please try again later.',
        duration: 3
      });
    }
  }

  const copyLink = async () => {
    try {
      notification.success({
        message: 'Success!',
        description: 'The link has been copied to your clipboard.',
        duration: 3
      });
      if (eventInvoice.status == invoiceStatus.DRAFT) {
        await sendRequest(updateInvoiceStatus(invoiceId, { status: invoiceStatus.SENT }))
        await refreshPage()
      }
    } catch {

    }
  }

  const isEmailValid = (email) => {
    const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    return email.match(emailRegex)
  }

  const isAllEmailsValid = () => {
    return ccEmails.filter(x => !isEmailValid(x)).length == 0
  }

  const renderHeader = () => {
    var backPath = `/admin/invoices/${invoiceId}/preview`
    var eventLinkText = "Go to event"
    if (fromPage == "invoices") {
      backPath = `/admin/invoices`
    } else if (fromPage == "invoice-details") {
      backPath = `/admin/invoices/${invoiceId}`
    } else if (fromPage == "invoice-details-edit-preview") {
      backPath = `/admin/invoices/${invoiceId}/preview?from=invoice-details-edit`
    } else if (fromPage == "invoice-details-preview") {
      backPath = `/admin/invoices/${invoiceId}/preview?from=invoice-details`
    } else if (fromPage == "invoices-preview") {
      backPath = `/admin/invoices/${invoiceId}/preview?from=invoices`
    } else {
      eventLinkText = "Back to event"
    }

    if (screens.md) {
      return (
        <div className="event-contract--header p-20">
          <div className="flex-row flex-middle">
            <div className="fs-20 display-flex mr-10" onClick={() => navigate(backPath)}><HiOutlineArrowLeft/></div>
            <div className="fs-18 fw-700 mr-15 flex-ellipsis">
              Invoice #{ eventInvoice.invoice_number }
            </div>
            <StatusTag status={eventInvoice.status}/>
            <div className="flex-1"></div>
            <div className="blue-link mr-10" onClick={() => navigate(`/admin/events/${eventId}?tab=documents`)}>{eventLinkText}</div>
          </div>
        </div>
      )
    } else {
      return (
        <div className="event-contract--header p-20">
          <div className="flex-row flex-middle">
            <div className="fs-20 display-flex mr-10" onClick={() => navigate(backPath)}><HiOutlineArrowLeft/></div>
            <div className="fs-18 fw-700 mr-15 flex-ellipsis">
              Invoice #{ eventInvoice.invoice_number }
            </div>
            <StatusTag status={eventInvoice.status}/>
            <div className="flex-1"></div>
          </div>
          <div className="text-center mt-10">
            <div className="blue-link" onClick={() => navigate(`/admin/events/${eventId}?tab=documents`)}>{eventLinkText}</div>
          </div>
        </div>
      )
    }
  }

  const renderBillToUser = () => {
    var clientPhoto = null;
    return (
      <Row gutter={[15,15]} align="middle" className="mb-20">
        <Col>
          {/* { clientPhoto ? (
            <div className="small-profile-photo size-60" style={{ backgroundImage: `url(${clientPhoto})`}} onClick={() => displayPhoto(clientPhoto)}></div>
          ) : ( */}
            <div className="display-flex">
              <div className="user-profile-circle small"><UserOutlined/></div>
            </div>
          {/* )} */}
        </Col>
        <Col flex={1}>
          <div className="fs-14 fw-700">{billToFirstName} {billToLastName}</div>
          <div className="fs-14 fw-500 c-text-gray line-1-1">{billToEmail}</div>
        </Col>
      </Row>
    )
  }

  const tagRender = (props) => {
    const { label, value, onClose } = props;
    const isValid = isEmailValid(value)
    const tagBackgroundColor = isValid ? "#eef0f1" : "#fadbd8"
    const tagFontColor = isValid ? "#576574" : "#e74c3c"
    return (
      <div style={{ 
        backgroundColor: tagBackgroundColor, 
        color: tagFontColor, 
        marginRight: 5,
        fontSize: 14, 
        display: "flex",
        padding: "8px 10px",
        alignItems: 'center',
        cursor: 'pointer'
      }}>
        {label} <span style={{ display: "flex", marginLeft: 5 }} onClick={onClose}><MdOutlineClose size={20}/></span>
      </div>
    );
  };

  const renderContent = () => {
    return (
      <>
        <FloatingContainer className="ph-20 mb-20 bg-gray" verticalPadding={20} maxWidth={screens.lg ? 1200 : 700}>
          <Row gutter={[20,20]}>
            <Col xs={24} lg={12}>
              <div className="bg-white border p-20">
                <Row gutter={[20,20]}>
                  <Col flex={0}>
                    <div className="fw-700 mt-10">To</div>
                  </Col>
                  <Col flex={1}>
                    { renderBillToUser() }
                  </Col>
                </Row>
                <Row gutter={[20,20]} className="mt-10" align="middle">
                  <Col xs={24}>
                    <div className="fw-700 mb-5">CC</div>
                    <Select
                      mode="tags"
                      tokenSeparators={[","]}
                      maxTagCount={6}
                      tagRender={tagRender}
                      size="large"
                      style={{ width: '100%' }}
                      placeholder="Email addresses (separated by commas)"
                      onChange={(values) => setCCEmails(values)}
                      options={clients}
                    />
                  </Col>
                </Row>
                <Row gutter={[20,20]} align="middle" className="mt-20">
                  <Col xs={24}>
                    <div className="fw-700 mb-5">Subject</div>
                    <Input size="large" value={subject} onChange={(e) => setSubject(e.target.value)}/>
                  </Col>
                  <Col xs={24}>
                    <div className="fw-700 mb-5">Message</div>
                    <Input.TextArea rows={8} size="large" value={message} onChange={(e) => setMessage(e.target.value)}/>
                  </Col>
                  <Col xs={12}>
                    <Checkbox value={true} onChange={(e) => setSendMeCopy(e.target.checked)}>{ "Send me a copy" }</Checkbox>
                  </Col>
                  <Col xs={12}>
                    <div className="text-right">
                      { isSending ? (
                        <button className="small-primary-button" style={{ width: 150 }}><LoadingOutlined/></button>
                      ) : (
                        <button className="small-primary-button" style={{ width: 150 }} onClick={() => onSendInvoice()}>Send Invoice</button>
                      )}
                    </div>
                  </Col>
                  <Col xs={24}>
                    <div className="t-border text-center pt-15">
                      <div className="bg-white p-15 radius-8">
                        <div className="fw-700">Prefer to copy a link and send in your own text or email?</div>
                        { renderInvoiceLink() }
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col xs={24} lg={12}>
              { !screens.lg ? (
                <div className="fw-700 fs-18 mb-10 text-center t-border pt-20 mt-10">Email Preview</div>
              ) : (
                <div className="fw-700 fs-18 mb-10 text-center">Email Preview</div>
              )}
              <div className="bg-white border ph-20 pv-50">
                { !isNull(eventInvoice.logo) && !isEmpty(eventInvoice.logo) ? (
                  <div className="text-center mb-30">
                    <img src={eventInvoice.logo} style={{ maxWidth: 200 }}/>
                  </div>
                ) : (
                  <div className="text-center bg-gray mh-20 mb-30 p-20 radius-8" style={{ border: "1px dashed #CCC"}}>
                    <div className="fw-700 mb-5">Company Logo</div>
                    <div className="fs-12">Upload a logo on the <span className="blue-link fs-12" onClick={() => navigate("/admin/setup/branding")}>Branding</span> page to display it here.</div>
                  </div>
                )}
                <div className="bg-gray p-20 text-center">
                  <div className="mb-5">Invoice #{eventInvoice.invoice_number}</div>
                  <div className="fw-700 fs-20 mb-5">{amountDue}</div>
                  <div className="">Due by {dueDate}</div>
                </div>
                <div className="mt-30" style={{ whiteSpace: "pre-line" }}>{ message }</div>
                <div className="text-center mt-30">
                  <button className="primary-button" style={{ width: 250 }} onClick={() => {}}>View Invoice</button>
                </div>
              </div>
            </Col>
          </Row>
        </FloatingContainer>
      </>
    )
  }

  const renderInvoiceLink = () => {
    const link = `${BASE_URL}/v/invoice/${invoiceId}`
    return (
      <div className="mt-15">
        <div className="display-flex flex-row flex-middle bg-gray p-15 mt-5 radius-4">
          <div className="flex-1" style={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'pre', userSelect: 'none' }}>{link}</div>
          <CopyToClipboard text={link} onCopy={() => copyLink()}>
            <div className="ml-5 blue-link">Copy link</div>
          </CopyToClipboard>
        </div>
      </div>
    )
  }

  const renderCCEmailError = () => {
    return (
      <Modal visible={isEmailErrorModalVisible} closable={false} footer={null} width={400} wrapClassName="rounded-modal">
        <div className="text-right" onClick={() => setEmailErrorModalVisible(false)}><MdOutlineClose size={30} color={"#CCC"}/></div>
        <div className="mt-10">
          <div className="fw-700 fs-20 text-center">Invalid Email</div>
          <div className="fw-500 fs-14 mt-20 mb-20 text-center">One or more email addresses in the "CC" field are invalid. Please provide valid emails before continuing.</div>
          <button className="primary-button" type="button" onClick={() => setEmailErrorModalVisible()}>Got it</button>
        </div>
      </Modal>
    )
  }

  if (isLoading) {
    return (
      <div className="event-contract--container">
        <LoadingSpinner/>
      </div>
    )
  }

  return (
    <div className="event-contract--container">
      { renderHeader()}
      <div className="event-contract--body">
        <div className="event-contract--content">
          { renderContent() }
        </div>
      </div>
      { renderCCEmailError() }
    </div>
  );
}

export default SendInvoicePage;

import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { isUndefined, cloneDeep, isEmpty, isNull } from "lodash";
import useApi from '../../../hooks/useApi';
import useCurrency from '../../../hooks/useCurrency';
import useDocumentTitle from '../../../hooks/useDocumentTitle';
import { 
  renderFormLabel,
  renderInputField,
  renderTextAreaField,
  renderCurrencyField,
  renderCheckboxField,
  renderNumberField
} from '../../../components/formFields'
import { Row, Col, notification, Form, Button, Modal, Upload, Input, Image, Select} from 'antd';
import { getAddOn, updateAddOn, uploadAddOnPhoto, getAddOnCategories, createAddOnCategory } from '../../../api';
import LoadingSpinner from '../../../components/loading';
import AdminContent from '../../../components/adminContent';
import FloatingContainer from '../../../components/floatingContainer'
import { getNumericCurrency } from '../../../helpers/contractHelper';
import { resizeFile, isValidFileType } from '../../../helpers/imageHelper';
import { MdArrowForwardIos, MdOutlineInsertPhoto } from "react-icons/md";
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';

const EditAddOnPage = () => {

  const [isLoading, setLoading] = useState(true);
  const [isSaving, setSaving] = useState(false);
  const [addOn, setAddOn] = useState({});
  const [addOnCategories, setAddOnCategories] = useState([]);
  const [imageUrl, setImageUrl] = useState(null);
  const [isPhotoSaving, setPhotoSaving] = useState(false);
  const [showMaxQuantity, setShowMaxQuantity] = useState(false);
  const [newCategoryName, setNewCategoryName] = useState('');
  const [isTaxable, setTaxable] = useState(false);

  useDocumentTitle("Edit Add-On")
  const navigate = useNavigate();
  const params = useParams();
  const [form] = Form.useForm();
  const [sendRequest] = useApi()
  const [currency, _] = useCurrency()
  const inputRef = useRef(null);

  const addOnId = params.id;

  useEffect(() => {
    window.scrollTo(0, 0);
    if (addOnId) {
      refreshPage();
    } else {
      setLoading(false)
    }
  }, []);

  const refreshPage = async () => {
    try {

      const addOnResults = await sendRequest(getAddOn(addOnId))
      setAddOn(addOnResults)
      setImageUrl(addOnResults.photo ?? "")
      setShowMaxQuantity(!isNull(addOnResults.max_quantity))
      setTaxable(addOnResults.taxable)

      const addOnCategoryResults = await sendRequest(getAddOnCategories())
      setAddOnCategories(addOnCategoryResults)

      const formValues = {
        add_on_name: addOnResults.add_on_name,
        add_on_category_id: addOnResults.add_on_category_id,
        allow_quantity: !isNull(addOnResults.max_quantity),
        max_quantity: addOnResults.max_quantity,
        description: addOnResults.description,
        price: addOnResults.price,
        active: addOnResults.active,
        taxable: addOnResults.taxable
      }
      form.setFieldsValue(formValues)

      setLoading(false)

      if (isEmpty(addOnResults)) {
        navigate("/admin/addons")
      }
    } catch (error) {
      setLoading(false)
    }
  }

  const onSubmit = async (values) => {
    try {
      setSaving(true)
      var body = {
        ...values,
        price: getNumericCurrency(values["price"]),
        active: !isUndefined(values["active"]) ? values["active"] : false,
        taxable: !isUndefined(values["taxable"]) ? values["taxable"] : false,
        max_quantity: (isUndefined(values["allow_quantity"]) || values["allow_quantity"] == false) ? null : values["max_quantity"]
      }
      await sendRequest(updateAddOn(addOnId, body))
      displaySuccess()
    } catch (error) {
      Modal.error({
        title: "Oops!",
        content: 'Something went wrong. Please try again.',
        onOk: () => {}
      });
      setSaving(false)
    }
  }

  const displaySuccess = () => {
    notification.success({
      message: 'Success!',
      description: 'Your changes have been saved.',
      duration: 3
    });
    navigate("/admin/addons");
  }

  const toggleAllowQuantity = (e) => {
    setShowMaxQuantity(e.target.checked)
    const formValues = {
      max_quantity: e.target.checked ? 1 : null
    }
    form.setFieldsValue(formValues)
  }

  const handleImageChange = async (info) => {
    try {
      if (!isValidFileType(info.file)) {
        notification.error({
          message: 'Error',
          description: 'Only file types of JPG or PNG are supported.',
          duration: 3
        });
        return
      }

      setPhotoSaving(true)

      // Resize photo
      const newFile = await resizeFile(info.file)

      // Get blob from URI
      let response = await fetch(newFile);
      let blob = await response.blob()

      // Upload photo
      const formData = new FormData();
      formData.append('photo', blob);
      const newPhoto = await sendRequest(uploadAddOnPhoto(addOnId, formData))
      setImageUrl(newPhoto.photo)
    } catch(error) { 
      notification.error({
        message: 'Error',
        description: 'Something went wrong! Please try again later.',
        duration: 3
      });
    }
    finally {
      setPhotoSaving(false)
    }
  };

  const handleBeforeUpload = () => {
    return false
  };

  const onCategpryNameChange = (event) => {
    setNewCategoryName(event.target.value);
  };
  const addItem = async (e) => {
    e.preventDefault();
    try {
      const newCategory = await sendRequest(createAddOnCategory({ category_name: newCategoryName }))
      const addOnCategoriesCopy = cloneDeep(addOnCategories)
      addOnCategoriesCopy.push(newCategory)
      setAddOnCategories(addOnCategoriesCopy)
      setNewCategoryName('');
      setTimeout(() => {
        inputRef.current?.focus();
      }, 0);
    } catch(error) {
      console.log(error)
    }
  };

  const onTaxableToggle = (e) => {
    setTaxable(e.target.checked)
  }

  const renderSaveButton = () => {
    if (isSaving) {
      return (
        <button className="page-title-button small-100"><LoadingOutlined/></button>
      )
    } else {
      return (
        <button className="page-title-button small-100" onClick={() => form.submit()}>Save</button>
      )
    }
  }

  const renderHeader = () => {
    return (
      <div className="p-20">
        <div>
          <span className="c-blue fw-700 cursor-default" onClick={() => navigate("/admin/addons")}>
            Add-Ons
          </span>
          <span className="fs-10 mh-5"><MdArrowForwardIos/></span>
          <span className="cursor-default c-text-gray">
            Edit
          </span>
        </div>
        <div className="fw-700 fs-24 mt-5">{addOn.add_on_name}</div>
      </div>
    )
  }

  const renderPhoto = () => {
    if (!isEmpty(imageUrl)) {
      return (
        <>
          <div>
            <Image src={imageUrl} style={{ width: '100%', maxWidth: 400 }}/>
          </div>
          { isPhotoSaving ? (
            <div className="blue-link mt-5"><LoadingOutlined/> Uploading...</div>
          ) : (
            <Upload
              showUploadList={false}
              beforeUpload={handleBeforeUpload}
              onChange={handleImageChange}
              maxCount={1}
            >
              <div className="blue-link mt-5">Change Photo</div>
            </Upload>
          )}
        </>
      )
    } else {
      return (
        <Upload
          showUploadList={false}
          beforeUpload={handleBeforeUpload}
          onChange={handleImageChange}
          maxCount={1}
        >
          <div className="fs-50 border c-text-gray radius-4 bg-gray display-flex flex-col flex-middle justify-content-center cursor-pointer" style={{ height: 200, width: 200 }}>
            { isPhotoSaving ? (
              <LoadingOutlined style={{ fontSize: 30 }}/>
            ) : (
              <>
                <MdOutlineInsertPhoto/>
                <div className="fs-12 mt-5">Upload Image</div>
              </>
            )}
          </div>
        </Upload>
      )
    }
  }

  const renderAddOnCategorySelect = () => {
    return (
      <>
        {renderFormLabel("Add-On Category")}
        <Form.Item 
          name={"add_on_category_id"}
          rules={[{ required: false, message: `Add-On Category is required!` }]}
        >
          <Select
            showSearch
            allowClear
            placeholder={"Select category..."}
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            size='large'
            onChange={() => {}}
            disabled={false}
            dropdownRender={(menu) => (
              <>
                {menu}
                <div className="mt-10 b-border"/>
                <div className="p-10">
                  <Row gutter={[10,10]}>
                    <Col flex={1}>
                      <Input
                        placeholder="New category"
                        ref={inputRef}
                        value={newCategoryName}
                        onChange={onCategpryNameChange}
                        style={{ width: '100%'}}
                      />
                    </Col>
                    <Col flex={0}>
                      <Button type="text" icon={<PlusOutlined />} onClick={addItem}>
                        Add item
                      </Button>
                    </Col>
                  </Row>
                </div>
              </>
            )}
          >
            { addOnCategories.map((option) => (
              <Select.Option value={option.add_on_category_id} key={option.add_on_category_id}>{option.category_name}</Select.Option>
            ))}
          </Select>
        </Form.Item>
      </>
    )
  }

  const renderContent = () => {
    return (
      <FloatingContainer className="ph-20 mb-80" verticalPadding={20} maxWidth={800}>
        <Form form={form} layout="vertical" name="employee" onFinish={onSubmit}>
          <div className="shadow-card p-20 mb-15">
            <div className="fs-20 fw-700 mb-20 line-1">Add-On Details</div>
            <Row gutter={[10,10]}>
              <Col xs={24}>
                {renderInputField("Add-On Name", "add_on_name", true)}
              </Col>
              <Col xs={24} sm={18}>
                { renderAddOnCategorySelect() }
              </Col>
              <Col xs={24} md={24}>
                {renderTextAreaField("Description", "description", 5, false)}
              </Col>
              <Col xs={24}>
                {renderCurrencyField("Price", "price", true, currency.code)}
              </Col>
              <Col xs={24}>
                {renderCheckboxField("Taxable", "taxable", onTaxableToggle)}
              </Col>
              { isTaxable && (
                <Col xs={24}>
                  <div className="bg-gray radius-8 p-10 fs-12 c-text-gray">Your tax rate is configured on the Setup page under General Settings.</div>
                </Col>
              )}
              <Col xs={24}>
                {renderCheckboxField("Allow quantity selection", "allow_quantity", toggleAllowQuantity)}
              </Col>
              { showMaxQuantity && (
                <Col xs={24}>
                  {renderNumberField("Max Quantity", "max_quantity", 0, true)}
                </Col>
              )}
              <Col>
                { renderFormLabel("Image") }
                { renderPhoto() }
              </Col>
            </Row>
          </div>
          <div className="shadow-card p-20 mb-15">
            <div className="fs-20 fw-700 mb-20 line-1">Display Options</div>
            <Row gutter={[10,10]}>
              <Col xs={24}>
                {renderCheckboxField("Active", "active")}
              </Col>
            </Row>
          </div>
        </Form>
        <div className="sticky-footer p-0">
          <div className="p-15 bg-white t-border">
            { renderSaveButton() }
          </div>
        </div>
      </FloatingContainer>
    )
  }

  if (isLoading) {
    return <LoadingSpinner/>
  }

  return (
    <AdminContent header={renderHeader()} body={renderContent()} headerHeight={96}/>
  );
}

export default EditAddOnPage;

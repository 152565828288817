import React, { useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { BackToHomeLink, DocLink } from '../../../components/docComponents'
import AdminContent from '../../../components/adminContent';
import FloatingContainer from '../../../components/floatingContainer'
import useDocumentTitle from '../../../hooks/useDocumentTitle';

const DocsTimeOffPage = () => {

  useDocumentTitle("Time Off Requests | Documentation")
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const renderContent = () => {
    return (
      <FloatingContainer className="ph-20 bg-white" verticalPadding={20} maxWidth={1000}>
        <BackToHomeLink/>
        <div className="fw-700 fs-24 mt-20">Time Off Requests</div>
        <div className="fw-500 fs-16 c-text-gray">Black out certain dates on your calendar and allow staff members to request time off.</div>
         <div className="b-border mv-20"></div>
        <div className="fw-700 fs-18 mb-15">Overview</div>
        DJ Planning Center allows staff members to add time off to their calendar to indicate which days they are not available. If your company has multiple staff members, you can adjust your settings to review each time off request before it's approved or automatically approve all requests. You will also receive email notifications when these requests are submitted.
        <div className="b-border mv-20"></div>
        <div className="fw-700 fs-18 mb-15">Approval Setttings</div>
        Before using the time off feature, you should confirm your approval settings.
        <ol>
          <li className="mb-15 mt-15">
            Go to the <b>Setup</b> {">"} <DocLink to="/admin/setup/calendar">Calendar</DocLink> page
          </li>
          <li className="mb-15 mt-15">
            Select the <b>Edit</b> link in the <b>Calendar Settings</b> section
          </li>
          <li className="mb-15">
            Select from one of the following options for <b>Time Off Approval</b>:
            <ul>
              <li className="mt-10"><b>Manually approve all requests</b>
                <br/>
                Based on this setting, all time off requests submitted by staff members will be created in a "Pending" status. Any admin or staff member with the "Manage all company time off" permission will have to manually approve or reject the time off request.
              </li>
              <li className="mt-10"><b>Automatically approve all requests</b>
                <br/>
                Based on this setting, all time off requests submitted by staff members will be automatically marked as "Approved".
              </li>
            </ul>
          </li>
        </ol>
        <div className="ml-20 mt-20">
          <button className="small-primary-button mb-5" onClick={() => navigate("/admin/setup/calendar")}>Go to Settings</button>
        </div>
        <div className="b-border mv-20"></div>
        <div className="fw-700 fs-18 mb-15">Adding Time Off</div>
        <ol>
          <li className="mb-15 mt-15">
            Go to the <DocLink to="/admin/calendar">Calendar</DocLink> page.
          </li>
          <li className="mb-15 mt-15">
            Select the <b>+ Add</b> button and choose <b>Time Off</b>
          </li>
          <li className="mb-15">
            Enter the following fields:
            <ul>
              <li className="mt-5">
                <b>Staff Member</b>
                <br/>
                <div className="ml-10">This field can only be edited if you are the admin or have the "Manage all company time off" permission</div>
              </li>
              <li className="mt-5">
                <b>Reason</b>
              </li>
              <li className="mt-5">
                <b>Status</b>
                <br/>
                <div className="ml-10">This field can only be edited if you are the admin or have the "Manage all company time off" permission</div>
              </li>
              <li className="mt-5">
                <b>All day</b>
                <br/>
                <div className="ml-10">Uncheck this toggle if your time off includes a specific start and end time</div>
              </li>
              <li className="mt-5"><b>Start Date</b></li>
              <li className="mt-5">
                <b>Start Time</b>
                <br/>
                <div className="ml-10">Only available if the "All day" toggle is not selected</div>
              </li>
              <li className="mt-5"><b>End Date</b></li>
              <li className="mt-5">
                <b>End Time</b>
                <br/>
                <div className="ml-10">Only available if the "All day" toggle is not selected</div>
              </li>
              <li className="mt-5">
                <b>Repeat</b>
                <br/>
                <div className="ml-10">Only available if the start and end date are on the same day</div>
              </li>
            </ul>
          </li>
          <li className="mb-15">
            Select the <b>Add Time Off</b> button
          </li>
          <li className="mb-15">
            If your <DocLink to="/admin/setup/calendar">Calendar Settings</DocLink> are set to "Automatically approve all requests", the status of your time off will immediately be marked as <b>Approved</b>. Otherwise, your time off will be in a <b>Pending</b> status until an admin approves the request.
          </li>
        </ol>
        <div className="ml-20 mt-20">
          <button className="small-primary-button mb-5" onClick={() => navigate("/admin/calendar")}>Go to Calendar</button>
        </div>
        <div className="b-border mv-20"></div>
        <div className="fw-700 fs-18 mb-15">Approving / Rejecting Time Off</div>
        Based on your Calendar Settings, all time off requests will either be automatically approved or created in a "Pending" status until an admin approves the request. Once time off has been submitted, all users who have the "Manage all company time off" permission will receive an email notification. The link in the email will take you to the Time Off page where you can approve or reject the request. Admins can also respond to time off requests directly from the Calendar page in either the website or mobile app.
        <div className="fw-700 mt-15">From the Time Off Page</div>
        <ol>
          <li className="mb-15 mt-15">
            Navigate to the <DocLink to="/admin/time-off">Time Off</DocLink> page
          </li>
          <li className="mb-15">
            Select the <b>Pending</b> tab
          </li>
          <li className="mb-15">
            Find the time off request and select either the <b>Approve</b> or <b>Reject</b> button
          </li>
        </ol>
        <div className="ml-20 mt-20">
          <button className="small-primary-button mb-5" onClick={() => navigate("/admin/time-off")}>Go to Time Off</button>
        </div>
        <div className="fw-700 mt-15">From the Calendar Page</div>
        <ol>
          <li className="mb-15 mt-15">
            Navigate to the <DocLink to="/admin/calendar">Calendar</DocLink> page
          </li>
          <li className="mb-15">
            Select a date on the calendar
          </li>
          <li className="mb-15">
            Find the time off request and select either the <b>Approve</b> or <b>Reject</b> button
          </li>
        </ol>
        <div className="ml-20 mt-20">
          <button className="small-primary-button mb-5" onClick={() => navigate("/admin/calendar")}>Go to Calendar</button>
        </div>
        <div className="b-border mv-50"></div>
        <div className="fw-700 fs-18 mb-15">Managing Permissions</div>
        By default, all staff members who are not an admin will only be able to view and request their own time off. If you would like certain staff members to either view or manage all time off requests for the company, you can adjust their permissions on their profile page.
        <ol>
          <li className="mb-15 mt-15">
            Navigate to the <DocLink to="/admin/staff">Staff</DocLink> page
          </li>
          <li className="mb-15">
            Select a staff member
          </li>
          <li className="mb-15">
            Go to the <b>Permissions</b> section and select from the following permissions:
            <ul>
              <li className="mt-10">
                <b>View all company time off</b>
                <br/>
                <div>Allows the staff member to view all time off that is entered into the system</div>
              </li>
              <li className="mt-10">
                <b>Manage all company time off</b>
                <br/>
                <div>Allows the staff member to add, edit, and delete time off for any staff member in the company</div>
              </li>
            </ul>
          </li>
        </ol>
        <div className="ml-20 mt-20">
          <button className="small-primary-button mb-5" onClick={() => navigate("/admin/staff")}>Go to Staff</button>
        </div>
        <div className="b-border mv-50"></div>
      </FloatingContainer>
    )
  }

  return (
    <AdminContent body={renderContent()}/>
  );
}

export default DocsTimeOffPage;

import React from 'react';
import { isEmpty } from 'lodash';
import { FiCheck } from "react-icons/fi";

const PlanCard = props => {
  const { price = "", discountPrice = "", plan = "", button } = props;

  const renderCheckMark = (text) => {
    return (
      <div className="display-flex pl-10 pb-5">
        <div className="display-flex mr-10" style={{ marginTop: 2 }}><FiCheck size={20} color="#536DFE"/></div>
        <div>{text}</div>
      </div>
    )
  }

  const renderStaffCheckMark = (number) => {
    return (
      <div className="display-flex pl-10 pb-5">
        <div className="display-flex mr-10" style={{ marginTop: 2 }}><FiCheck size={20} color="#536DFE"/></div>
        <div><span className="fw-700">Up to {number} employees/staff</span></div>
      </div>
    )
  }

  const renderPlanCard = () => {
    var employeeText = ""
    if (plan == "STANDARD") {
      employeeText = "10"
    } else if (plan == "PREMIUM") {
      employeeText = "50"
    } else if (plan == "ELITE") {
      employeeText = "100"
    }
    return (
      <div className="shadow-card p-30">
        <div>
          <div className="fw-700 fs-20 text-center">{plan}</div>
          <div className="text-center">
            <span style={{ borderBottom: "3px solid #536DFE", width: 40, display: 'inline-block' }}></span>
          </div>
          { !isEmpty(discountPrice) ? (
            <>
              <div className="fw-700 fs-36 line-1-1 text-center mt-20">${discountPrice}</div>
              <div className="fw-500 fs-14 text-center c-text-gray mb-10">per month</div>
              <div className="fw-500 fs-14 text-center c-text-gray strikethrough mb-30">{price} / month</div>
            </>
          ) : (
            <>
              <div className="fw-700 fs-36 line-1-1 text-center mt-20">{price}</div>
              <div className="fw-500 fs-14 text-center c-text-gray mb-30">per month</div>
            </>
          )}
        </div>
        <div style={{ borderBottom: "1px solid #e2e2e2" }}></div>
        <div className="pv-20">
          <div className="fw-700 mb-15 text-center">What's included</div>
          { renderCheckMark("Unlimited clients & events") }
          { renderStaffCheckMark(employeeText) }
          { renderCheckMark("State-of-the-art mobile app") }
          { renderCheckMark("Spotify & Apple Music integration") }
          { renderCheckMark("Digital contracts") }
          { renderCheckMark("Pricing guides") }
          { renderCheckMark("And so much more...") }
        </div>
        { button }
      </div>
    )
  }


  return renderPlanCard()
};

export default PlanCard;

import StorageKeys from '../constants/storageKeys';
import { isUndefined } from "lodash";
import moment from "moment";
import useApi from './useApi'
import { getAccountBetaFeatures } from '../api'

export default function useBetaFeatures() {

  const [sendRequest] = useApi()

  const needsUpdate = () => {
    const betaFeaturesObject = JSON.parse(localStorage.getItem(StorageKeys.BETA_FEATURES)) || {}
    if (!isUndefined(betaFeaturesObject.last_updated)) {
      return moment(betaFeaturesObject.last_updated).add(5, 'minutes').isBefore(moment())
    }
    return true
  }

  const getBetaFeatures = () => {
    const betaFeaturesObject = JSON.parse(localStorage.getItem(StorageKeys.BETA_FEATURES)) || {}
    const features = betaFeaturesObject.features || []
    return features
  }

  const setBetaFeatures = (features = []) => {
    const value = {
      last_updated: moment().valueOf(),
      features: features
    }
    localStorage.setItem(StorageKeys.BETA_FEATURES, JSON.stringify(value));
  }

  const hasBetaFeature = async (feature) => {
    try {
      if (needsUpdate()) {
        const betaFeaturesResponse = await sendRequest(getAccountBetaFeatures())
        setBetaFeatures(betaFeaturesResponse)
        return betaFeaturesResponse.includes(feature)
      } else {
        const features = getBetaFeatures()
        return features.includes(feature)
      }
    } catch {
      return false
    }
  }

  return [hasBetaFeature];
}
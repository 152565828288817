import React, { useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import { Image } from 'antd';
import { BackToHomeLink, DocLink } from '../../../../components/docComponents'
import AdminContent from '../../../../components/adminContent';
import FloatingContainer from '../../../../components/floatingContainer'
import paymentsSetupImage from '../../../../images/payments-setup.jpg';
import paymentsStripeBankTransferImage from '../../../../images/payments-stripe-bank-transfer.jpg';
import paymentsManualPaymentsImage from '../../../../images/payments-manual-payments.jpg';
import editInvoiceTips from '../../../../images/edit-invoice-tips.jpg';
import editInvoicePaymentReminders from '../../../../images/edit-invoice-payment-reminders.jpg';
import editInvoicePaymentSchedule from '../../../../images/edit-invoice-payment-schedule.jpg';
import invoicePaymentAmount from '../../../../images/invoice-payment-amount.jpg';
import invoicePaymentTip from '../../../../images/invoice-payment-tip.jpg';
import invoicePaymentMethod from '../../../../images/invoice-payment-method.jpg';
import invoicePaymentCard from '../../../../images/invoice-payment-card.jpg';
import invoicePaymentCheck from '../../../../images/invoice-payment-check.jpg';
import useDocumentTitle from '../../../../hooks/useDocumentTitle';

const DocsInvoicePaymentsPage = () => {

  useDocumentTitle("Invoices & Payments | Documentation")
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const scrollWithOffset = (el) => {
    const offset = 15;
    const bodyRect = document.getElementsByClassName("admin-content--body-main")[0].getBoundingClientRect().top;
    const bodyScrollTop = document.getElementsByClassName("admin-content--body-main")[0].scrollTop
    const elementRect = el.getBoundingClientRect().top;
    const elementPosition = elementRect - bodyRect;
    const offsetPosition = elementPosition - offset + bodyScrollTop;
    document.getElementsByClassName("admin-content--body-main")[0].scrollTo({top: offsetPosition, behavior: 'smooth'})
  }

  const renderHashLink = (id, text) => {
    return (
      <HashLink to={`#${id}`} scroll={scrollWithOffset} style={{ display: "inline-block"}}>
        <div className="blue-link">{text}</div>
      </HashLink>
    )
  }

  const renderContent = () => {
    return (
      <FloatingContainer className="ph-20 bg-white" verticalPadding={20} maxWidth={1000}>
        <BackToHomeLink/>
        <div className="fw-700 fs-24 mt-20">Invoices & Payments</div>
        <div className="fw-500 fs-16 c-text-gray">Create and send invoices to collect payments from your clients.</div>
        <div className="b-border mv-20"></div>

         {/* --------- TABLE OF CONTENTS --------- */}
        <div className="mt-50">
          <ul>
            <li>{renderHashLink("overview", "Overview")}</li>
            <li>{renderHashLink("payment-methods", "Setting Up Payment Methods")}</li>
            <li>{renderHashLink("creating-sending-invoice", "Creating & Sending an Invoice")}</li>
            <li>{renderHashLink("what-will-client-see", "What will the client see?")}</li>
            <li>{renderHashLink("managing-invoices", "Managing Invoices")}</li>
            <li>{renderHashLink("invoice-statuses", "Understanding Invoice Statuses")}</li>
          </ul>
        </div>

        {/* --------- OVERVIEW --------- */}
        <div className="b-border mv-50"></div>
        <div className="fw-700 fs-20" id="overview">Overview</div>
        <div className="mt-10">
          DJ Planning Center offers a seamless way for you to send invoices and collect payments from your clients. You have the option of connecting your Stripe account to accept credit cards and bank transfers directly from an invoice or setup manual payment methods to instruct your clients on how to pay using other methods such as cash or check.</div>
        <div className="mt-10">
          When payments are made through Stripe, they will automatically be recorded in the system and the invoice status will update to either "Partially Paid" or "Paid" depending on the payment amount.</div>
        <div className="mt-10">[INSERT VIDEO]</div>

        <div className="b-border mv-50"></div>

        {/* --------- SETTING UP PAYMENT METHODS --------- */}
        <div className="fw-700 fs-20" id="payment-methods">Setting Up Payment Methods</div>
        <div className="mt-10">In order to collect payments on an invoice, you must setup at least one payment method under your account settings.</div>
        <div className="mt-10">You can choose to connect your <b>Stripe</b> account or setup ways to collect manual payments outside of DJ Planning Center. These payment methods will display as options when the "Pay Now" button is selected on an invoice.</div>

        <div className="fw-700 fs-16 mt-30">Stripe</div>
        <div className="mt-10 mb-20">Stripe is an easy and secure way to accept credit cards and bank transfers. By connecting your Stripe account, you'll be able to accept the following forms of payment:</div>

        <ul>
          <li>Visa</li>
          <li>Mastercard</li>
          <li>American Express</li>
          <li>Discover</li>
          <li>Bank Transfers (US & UK only)</li>
        </ul>

        <div className="mt-10 mb-20">Stripe usually charges a processing fee of 2.9% + $0.30 for each credit/debit card transaction. This rate may vary based on your country.</div>

        <div className="mt-10 mb-20 fw-700">Connecting your Stripe Account</div>

        <ol>
          <li className="mb-15 mt-15">
            Go to the <b>Setup {">"}</b> <DocLink to="/admin/setup/payments">Invoices & Payments</DocLink> page
          </li>
          <li className="mb-15">
            Select the <b>Connect</b> button for Stripe within the <b>Payment Methods</b> section
          </li>
          <li className="mb-15">
           Follow the steps to create a Stripe account or connect an existing account
          </li>
          <li className="mb-15">
           After your account is successfully connected, you will see a "CONNECTED" status on the <b>Invoices & Payments</b> page
          </li>
        </ol>

        <div className="mt-15 ml-20">
          <Image src={paymentsSetupImage} style={{ width: '100%', maxWidth: 600, border: '1px solid #CCC' }}/>
        </div>

        <div className="mt-20 mb-20 fw-700">Enabling Bank Transfers</div>
        <div className="mb-20">If you live within the US or UK, you might see the option to enable Bank Transfers as a form of payment.</div>

        <ol>
          <li className="mb-15 mt-15">
            Connect your Stripe account using the steps above
          </li>
          <li className="mb-15 mt-15">
            Select the <b>Enable</b> link next to "Bank Transfer"
          </li>
        </ol>

        <div className="mt-15 ml-20">
          <Image src={paymentsStripeBankTransferImage} style={{ width: '100%', maxWidth: 600, border: '1px solid #CCC' }}/>
        </div>

        <div className="fw-700 fs-16 mt-30">Manual Payment Methods</div>
        <div className="mt-10 mb-20">If you choose to collect payments outside of DJ Planning Center (ex. Cash, Check, Zelle, etc.), you can setup manual payment methods that show up as options when a client attempts to pay an invoice. When one of these options is selected, instructions will display for how they can make their payment.</div>
        <ol>
          <li className="mb-15 mt-15">
            Go to the <b>Setup {">"}</b> <DocLink to="/admin/setup/payments">Invoices & Payments</DocLink> page
          </li>
          <li className="mb-15 mt-15">
            Select the <b>Add</b> button under the <b>Manual Payments</b> section
          </li>
          <li className="mb-15 mt-15">
            In the "Add Manual Payment Method" modal, enter a <b>Payment Method Name</b> (ex. Cash, Check, E-Transfer, etc.)
          </li>
          <li className="mb-15 mt-15">
            Enter instructions in the <b>Payment Instructions</b> field
          </li>
          <li className="mb-15 mt-15">
            Select the <b>Save</b> button
          </li>
        </ol>

        <div className="mt-15 ml-20">
          <Image src={paymentsManualPaymentsImage} style={{ width: '100%', maxWidth: 600, border: '1px solid #CCC' }}/>
        </div>

        <div className="b-border mv-50"></div>

        {/* --------- CREATING & SENDING AN INVOICE --------- */}
        <div className="fw-700 fs-20" id="creating-sending-invoice">Creating & Sending an Invoice</div>

        <div className="bg-gray pv-10 ph-15 radius-8 mt-10"><b>Note:</b> Before creating an invoice, make sure all necessary packages and/or add-ons have been added to an event. These will be automatically populated when an invoice is created.</div>

        <div className="fw-700 fs-16 mt-15">Steps</div>

        <ol>
          <li className="mb-15 mt-15">
            Select an event on the website or mobile app, go to <b>Documents</b>, and select the <b>"+"</b> icon in the <b>Invoices</b> section.
            <div className="bg-gray pv-10 ph-15 radius-8 mt-10"><b>Note:</b> You can also create an invoice on the <DocLink to="/admin/invoices">Invoices</DocLink> page by clicking the <b>New Invoice</b> button and selecting a future event from the dropdown.</div>
          </li>
          <li className="mb-15">
            In the <b>New Invoice</b> modal, enter the following details:
            <ul>
              <li className="mb-15 mt-10">
                <b>Invoice Template (optional)</b>
                <div className="mt-5">
                  Any invoice templates you have created will display in this dropdown. When a template is applied, the invoice will be automatically populated with the default settings you have configured in your template.
                </div>
                <div className="bg-gray pv-10 ph-15 radius-8 mt-10"><b>Note:</b> For more information, check out our <DocLink to="/admin/docs/invoice-templates">Invoice Templates</DocLink> documentation. </div>
              </li>
              <li className="mb-15">
                <b>Bill To</b>
                <div className="mt-5">
                  A list of clients that are assigned to this event will display in this dropdown, however you can also select "Other" to enter details for someone else who does not have access to DJ Planning Center.
                </div>
              </li>
            </ul>
          </li>
          <li className="mb-15">
            Select <b>Continue</b> to proceed to the invoice
          </li>
          <li className="mb-15">
            Confirm the invoice line items are correct and make adjustments as necessary.
            <div className="bg-gray pv-10 ph-15 radius-8 mt-10"><b>Note:</b> only packages and add-ons that have not been included on any previous invoices will be available as line items for this invoice. You can choose to remove line items from the invoice if you plan on billing the client for those services at a later time.</div>
          </li>
          <li className="mb-15">
            Enter any <b>Notes</b> you would like to include on the invoice
          </li>
          <li className="mb-15">
            Select the option for <b>Tips</b> if you would like to give your client an option to add a tip while paying the invoice.
            <div className="mt-15 ml-20">
              <Image src={editInvoiceTips} style={{ width: '100%', maxWidth: 600, border: '1px solid #CCC' }}/>
            </div>
          </li>
          <li className="mb-15">
            Turn on <b>Payment Reminders</b> if you would like DJ Planning Center to automatically send reminders to your client when a payment is due
            <div className="mt-15 ml-20">
              <Image src={editInvoicePaymentReminders} style={{ width: '100%', maxWidth: 600, border: '1px solid #CCC' }}/>
            </div>
          </li>
          <li className="mb-15">
            Turn on a <b>Payment Schedule</b> if you would like to split your invoice into multiple payments. For example, you can set your holding fee to be due within 7 days and the remaining balance to be due 30 days before the event. The amount for the last payment will automatically be populated based on the total of all the other payments. The dropdown next to each date can be used to help calculate the date based on a certain criteria such as "X" amount of days before the event.
            <div className="mt-15 ml-20">
              <Image src={editInvoicePaymentSchedule} style={{ width: '100%', maxWidth: 600, border: '1px solid #CCC' }}/>
            </div>
          </li>
          <li className="mb-15">
            Once you are happy with your changes, select the <b>Next: Save & Preview</b> button.
          </li>
          <li className="mb-15">
            Review the invoice to make sure all information is correct. Once you are done, select the <b>Next: Send Invoice</b> button.
          </li>
          <li className="mb-15">
            Update the <b>Subject</b> and <b>Message</b> of the email if needed, select "Send me a copy" (optional), and click <b>Send Invoice</b>. An email with a link to the invoice will be sent to your client for them to review and pay.
            <div className="bg-gray pv-10 ph-15 radius-8 mt-10"><b>Note:</b> If you prefer to copy a link to the invoice to send in your own email or text message, you can select <b>Copy link</b>.</div>
          </li>
        </ol>

        <div className="ml-20 mt-20">
          <button className="small-primary-button mb-5" onClick={() => navigate("/admin/invoices")}>Go to Invoices</button>
        </div>

        <div className="b-border mv-50"></div>

        {/* --------- WHAT WILL THE CLIENT SEE? --------- */}
        <div className="fw-700 fs-20" id="what-will-client-see">What will the client see?</div>

        <div className="fs-14 mt-15">Selecting a payment amount:</div>
        <div className="mt-15 ml-20">
          <Image src={invoicePaymentAmount} style={{ width: '100%', maxWidth: 600, border: '1px solid #CCC' }}/>
        </div>
        <div className="fs-14 mt-15">Adding a tip:</div>
        <div className="mt-15 ml-20">
          <Image src={invoicePaymentTip} style={{ width: '100%', maxWidth: 600, border: '1px solid #CCC' }}/>
        </div>
        <div className="fs-14 mt-15">Selecting a payment method:</div>
        <div className="mt-15 ml-20">
          <Image src={invoicePaymentMethod} style={{ width: '100%', maxWidth: 600, border: '1px solid #CCC' }}/>
        </div>
        <div className="fs-14 mt-15">Submitting a credit card payment:</div>
        <div className="mt-15 ml-20">
          <Image src={invoicePaymentCard} style={{ width: '100%', maxWidth: 600, border: '1px solid #CCC' }}/>
        </div>
        <div className="fs-14 mt-15">Submitting a manual payment:</div>
        <div className="mt-15 ml-20">
          <Image src={invoicePaymentCheck} style={{ width: '100%', maxWidth: 600, border: '1px solid #CCC' }}/>
        </div>

        <div className="b-border mv-50"></div>

        {/* --------- MANAGING INVOICES --------- */}
        <div className="fw-700 fs-20" id="managing-invoices">Managing Invoices</div>

        <div className="fw-700 fs-16 mt-15">Invoices Page</div>

        <ul>
          <li className="mb-15 mt-15">
            Navigate to the <DocLink to="/admin/invoices">Invoices</DocLink> page to start managing your invoices.
            <div className="bg-gray pv-10 ph-15 radius-8 mt-10"><b>Note:</b> you can also access invoices by going to the <b>Documents</b> tab of a specific event.</div>
          </li>
          <li className="mb-15">
            Select from the following filters to view invoices in a certain status:
            <ul className="mt-10">
              <li className="mt-10">
                <b>All Invoices</b> 
                <div>All invoices in the system.</div>
              </li>
              <li className="mt-10">
                <b>Draft</b> 
                <div>All invoices that have not been sent to the client.</div>
              </li>
              <li className="mt-10">
                <b>Outstanding</b> 
                <div>All invoices that have not been paid in full.</div>
              </li>
              <li className="mt-10">
                <b>Paid</b> 
                <div>All invoices that have been paid in full.</div>
              </li>
              <li className="mt-10">
                <b>Past Due</b> 
                <div>All invoices that have at least one scheduled payment that is past the due date.</div>
              </li>
              <li className="mt-10">
                <b>Cancelled</b> 
                <div className="">All invoices that are marked as "Cancelled"</div>
              </li>
            </ul>
          </li>
        </ul>

        <div className="fw-700 fs-16 mt-15">Invoice Detail Page</div>

        <ul>
          <li className="mb-15 mt-15">
            Select an invoice from the <DocLink to="/admin/invoices">Invoices</DocLink> page to see its details.
          </li>
          <li className="mb-15">
            In the <b>Actions</b> menu, select from one of the following options:
            <ul className="mt-10">
              <li>
                <b>Edit Invoice</b> <span className="c-text-gray">(Draft, Sent, or Pending status only)</span>
                <div className="mt-5 ml-10">Invoices can only be edited if a payment has not been made. This is meant to keep the integrity of the payments that have been automatically added.</div>
              </li>
              <li className="mt-10">
                <b>Preview Invoice</b> <span className="c-text-gray">(Draft & Sent status only)</span>
                <div className="mt-5 ml-10">This allows you to preview the invoice after you make updates.</div>
              </li>
              <li className="mt-10">
                <b>Send Invoice</b> <span className="c-text-gray">(Draft status only)</span>
                <div className="mt-5 ml-10">This allows you to send an email to your client with a link to the invoice. You can also choose the "Copy link" option on this page to send a link in your own text message or email.</div>
              </li>
              <li className="mt-10">
                <b>Re-send Invoice</b> <span className="c-text-gray">(Sent status only)</span>
                <div className="mt-5 ml-10">This allows you to re-send the invoice to your client if it has already been marked as "Sent".</div>
              </li>
              <li className="mt-10">
                <b>Mark as Sent</b> <span className="c-text-gray">(Draft status only)</span>
                <div className="mt-5 ml-10">This marks the invoice as "Sent" and will allow the invoice to display in the Documents section when the client logs in to the website or mobile app.</div>
              </li>
              <li className="mt-10">
                <b>Revert to Draft</b> <span className="c-text-gray">(Sent status only)</span>
                <div className="mt-5 ml-10">The client will no longer be able to access the invoice, and you will be able to make updates to the invoice.</div>
              </li>
              <li className="mt-10">
                <b>Copy Invoice Link</b> <span className="c-text-gray">(Sent, Partially Paid, & Paid status only)</span>
                <div className="mt-5 ml-10">This copies the invoice link to your clipboard so you can share it.</div>
              </li>
              <li className="mt-10">
                <b>View / Download PDF</b> <span className="c-text-gray">(All statuses)</span>
                <div className="mt-5 ml-10">This takes you to a page where you can view and/or download the invoice as a PDF.</div>
              </li>
              <li className="mt-10">
                <b>Delete Invoice</b> <span className="c-text-gray">(Draft status only)</span>
                <div className="mt-5 ml-10">This allows you to delete the invoice if you created it by mistake.</div>
              </li>
              <li className="mt-10">
                <b>Cancel Invoice</b> <span className="c-text-gray">(Sent & Partially Paid status only)</span>
                <div className="mt-5 ml-10">This marks the invoice as "Cancelled" and will no longer be available for the client to access.</div>
              </li>
            </ul>
          </li>
        </ul>

        <div className="b-border mv-50"></div>

        {/* --------- UNDERSTANDING INVOICE STATUSES --------- */}
        <div className="fw-700 fs-20 mb-15" id="invoice-statuses">Understanding Invoice Statuses</div>
        Each invoice will be assigned a status depending on what stage of the process it is in.
        <ul>
          <li className="mb-15 mt-15">
            <b>Draft</b> 
            <br/>
            The invoice is in the editing stage and has not been sent to the client yet. These invoices will not be visible in the client portal until they are marked as "Sent".
          </li>
          <li className="mb-15 mt-15">
            <b>Sent</b> 
            <br/>
            The invoice has been sent to the client, and it is awaiting payment. These invoices will display in the client portal for the client to access.
          </li>
          <li className="mb-15 mt-15">
            <b>Unpaid</b> 
            <br/>
            This is the status that displays to the client when the invoice has been "Sent".
          </li>
          <li className="mb-15 mt-15">
            <b>Past Due</b> 
            <br/>
            At least one scheduled payment is past its due date.
          </li>
          <li className="mb-15 mt-15">
            <b>Pending</b> 
            <br/>
            A manual payment has been initiated by the client and is awaiting your confirmation. When the client selects a manual payment method on an invoice and taps the "Send Message" button to notify you they are making a payment outside of DJ Planning Center, a pending payment will display on the invoice details page. Once you confirm you have received the payment, the invoice will transition to either "Partially Paid" or "Paid" status depending on the payment amount.
          </li>
          <li className="mb-15 mt-15">
            <b>Partially Paid</b> 
            <br/>
            At least one payment has been made, however there is still a balance left on the invoice.
          </li>
          <li className="mb-15 mt-15">
            <b>Paid</b> 
            <br/>
            The invoice has been paid in full by the client, therefore it is complete.
          </li>
          <li className="mb-15 mt-15">
            <b>Cancelled</b> 
            <br/>
            The invoice has been marked as "Cancelled" and can no longer be paid.
          </li>
        </ul>
        <div className="b-border mv-50"></div>
      </FloatingContainer>
    )
  }

  return (
    <AdminContent body={renderContent()}/>
  );
}

export default DocsInvoicePaymentsPage;

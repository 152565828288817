import { isNull } from 'lodash';

export const getPlaylistTime = (songs) => {
  const milliseconds = songs.reduce((x,y) => (isNull(y.duration) ? 0 : Number(y.duration)) + x, 0)
  let seconds = Math.floor(milliseconds / 1000);
  let minutes = Math.floor(seconds / 60);
  let hours = Math.floor(minutes / 60);

  minutes = minutes % 60;

  if (hours) {
    return `${hours}h ${minutes}m`
  } else {
    return minutes + "m"
  }
}

export const getPlaylistLengthAndTime = (songs) => {
  const totalTime = getPlaylistTime(songs)
  const totalSongs = `${songs.length} ${songs.length == 1 ? "song" : "songs"}`
  if (totalTime == "0m") {
    return totalSongs
  } else {
    return `${totalSongs}  •  ${totalTime}`
  }
}
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import NumberFormat from 'react-number-format';
import useApi from '../../../hooks/useApi';
import { Form, Row, Col, Modal } from 'antd';
import { getAccountDetails, updateAccountTaxRate, getCurrencies, updateAccountCurrency } from '../../../api';
import LoadingSpinner from '../../../components/loading';
import AdminContent from '../../../components/adminContent';
import FloatingContainer from '../../../components/floatingContainer'
import { MdArrowForwardIos, MdOutlineClose } from "react-icons/md";
import {
  renderFormLabel,
  renderSearchSelectField
} from '../../../components/formFields'
import useDocumentTitle from '../../../hooks/useDocumentTitle';
import { isUndefined } from "lodash";

const CompanyFinancialsPage = () => {

  const [isLoading, setLoading] = useState(true);
  const [accountDetails, setAccountDetails] = useState({});
  const [currencies, setCurrencies] = useState([]);
  const [isUpdateFinancialDetailsModalVisible, setUpdateFinancialDetailsModalVisible] = useState(false);

  useDocumentTitle("Financials")
  const navigate = useNavigate();
  const [updateFinancialDetailsForm] = Form.useForm();

  const [sendRequest] = useApi()

  useEffect(() => {
    window.scrollTo(0, 0);
    refreshPage();
  }, []);

  const refreshPage = async () => {
    try {
      const accountDetailsResults = await sendRequest(getAccountDetails())
      setAccountDetails(accountDetailsResults)

      const currencyResults = await sendRequest(getCurrencies())
      const items = currencyResults.map((x,i) => {
        return {
          text: `${x.name} (${x.symbol})`,
          value: x.code
        }
      })
      setCurrencies(items)

      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  const saveFinancialDetails = async (values) => {
    try {
      var taxRate = 0.0
      if (values["tax_rate"]) {
        const taxRateString = values["tax_rate"].replace("%","")
        const taxRateDecimal = Number(taxRateString) / 100
        taxRate = Number(taxRateDecimal.toFixed(5))  
      }
      await sendRequest(updateAccountTaxRate({ tax_rate: taxRate }))
      await sendRequest(updateAccountCurrency({ currency: values["currency"] }))
      await refreshPage()
      setUpdateFinancialDetailsModalVisible(false)
    } catch (error) {
      setUpdateFinancialDetailsModalVisible(false)
    }
  }

  const startUpdateFinancialDetails = () => {
    const taxRate = Number(accountDetails.tax_rate) * 100
    updateFinancialDetailsForm.setFieldsValue( { tax_rate: taxRate, currency: accountDetails.currency })
    setUpdateFinancialDetailsModalVisible(true)
  }

  const getAccountCurrencyName = () => {
    if (currencies.length > 0) {
      const currency = currencies.find(x => x.value == accountDetails.currency)
      return !isUndefined(currency) ? currency.text : ""
    }
    return ""
  }

  const renderHeader = () => {
    return (
      <div className="p-20">
        <div>
          <span className="c-blue fw-700 cursor-default" onClick={() => navigate("/admin/setup")}>
            Setup
          </span>
          <span className="fs-10 mh-5"><MdArrowForwardIos/></span>
          <span className="cursor-default c-text-gray">
            Financials
          </span>
        </div>
        <div className="fw-700 fs-24 mt-5">Financials</div>
      </div>
    )
  }

  const renderUpdateFinancialDetailsModal = () => {
    return (
      <Modal visible={isUpdateFinancialDetailsModalVisible} footer={null} onCancel={() => setUpdateFinancialDetailsModalVisible(false)} width={500} closable={false} maskClosable={false} wrapClassName="rounded-modal">
        <Row align="middle">
          <Col flex={1}>
            <div className="fw-700 fs-18">Update Financial Details</div>
          </Col>
          <Col>
            <div className="display-flex" onClick={() => setUpdateFinancialDetailsModalVisible(false)}><MdOutlineClose size={30} color={"#CCC"}/></div>
          </Col>
        </Row>
        <div className="">
          <Form form={updateFinancialDetailsForm} layout="vertical" name="verify" onFinish={saveFinancialDetails}>
            <div className="mt-10 mb-1">
              { renderFormLabel("Tax Rate") }
              <Form.Item
                name={"tax_rate"}
                className="mb-10"
              >
                <NumberFormat 
                  className="ant-input ant-input-lg" 
                  displayType={'input'}
                  suffix="%"
                  decimalScale={3}
                  fixedDecimalScale={true}
                  allowLeadingZeros={false}/>
              </Form.Item>
            </div>
            <div className="mt-10 mb-1">
              {renderSearchSelectField("Currency", "currency", "Select a currency...", currencies, true)}
            </div>
            <button className="primary-button" type="submit">Save</button>
            <div className="text-center mt-15">
              <div className="blue-link" onClick={() => setUpdateFinancialDetailsModalVisible(false)}>Cancel</div>
            </div>
          </Form>
        </div>
      </Modal>
    )
  }

  const renderContent = () => {
    const taxRate = Number(accountDetails.tax_rate) * 100
    return (
      <>
        <FloatingContainer className="ph-20 mb-80" verticalPadding={20} maxWidth={800}>
          <div className="shadow-card p-20">
            <Row className="b-border pb-15 mb-15">
              <Col flex={1}>
                <div className="fs-20 fw-700 line-1">Financial Details</div>
              </Col>
              <Col>
                <div className="blue-link" onClick={() => startUpdateFinancialDetails()}>Edit</div>
              </Col>
            </Row>
            <div className="fs-14 fw-700">Tax Rate</div>
            <div className="fs-14 mb-15">
              <NumberFormat
                displayType="text"
                value={taxRate}
                suffix="%"
                decimalScale={3}
                fixedDecimalScale={true}
                allowLeadingZeros={false}/>
            </div>
            <div className="fs-14 fw-700">Currency</div>
            <div className="fs-14 mb-15">{getAccountCurrencyName()}</div>
          </div>
        </FloatingContainer>
        { renderUpdateFinancialDetailsModal() }
      </>
    )
  }

  if (isLoading) {
    return <LoadingSpinner/>
  }

  return (
    <AdminContent header={renderHeader()} body={renderContent()}/>
  );
}

export default CompanyFinancialsPage;

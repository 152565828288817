import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import uniqid from 'uniqid';
import { isUndefined, isEmpty, cloneDeep } from "lodash";
import qs from "qs";
import useApi from '../../hooks/useApi';
import { Input, Dropdown, Menu, notification, Checkbox } from 'antd';
import { deleteSystemWorkflowStep, getSystemWorkflow, createSystemWorkflow, updateSystemWorkflow, createSystemWorkflowStep, updateSystemWorkflowStep } from '../../api';
import LoadingSpinner from '../../components/loading';
import AdminContent from '../../components/adminContent';
import WorkflowTaskModal from '../../components/workflowTaskModal';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { MdArrowForwardIos, MdOutlineMoreHoriz } from "react-icons/md";
import emptyStateImage from '../../images/empty-document-icon.png';
import { getActionTypeDescription, getTimePeriodDescription, sortSteps, getStepWhenDescription } from '../../helpers/workflowHelper';

const NewWorkflowTemplatePage = () => {

  const [isLoading, setLoading] = useState(true);
  const [isAddEditModalVisible, setAddEditModalVisible] = useState(false);
  const [workflowName, setWorkflowName] = useState("New Workflow");
  const [steps, setSteps] = useState([]);
  const [selectedTab, setSelectedTab] = useState("steps");
  const [selectedStep, setSelectedStep] = useState({});
  const [isNewStep, setIsNewStep] = useState(false);
  const [workflowActive, setWorkflowActive] = useState(true);

  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();

  const [sendRequest] = useApi()

  const workflowId = params.id;
  const isNew = isUndefined(workflowId);

  useEffect(() => {
    window.scrollTo(0, 0);
    const queryStrings = qs.parse(location.search, { ignoreQueryPrefix: true })
    if (queryStrings.tab) {
      setSelectedTab(queryStrings.tab)
    }

    if (!isNew) {
      refreshPage();
    } else {
      setLoading(false)
    }
  }, []);

  const refreshPage = async () => {
    try {
      const workflowResults = await sendRequest(getSystemWorkflow(workflowId));
      setWorkflowName(workflowResults.workflow_name)
      setWorkflowActive(workflowResults.active)
      setSteps(sortSteps(workflowResults.steps, true));

      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  const onSubmit = () => {

    var body = {
      workflow_name: workflowName,
      active: workflowActive,
      steps: steps
    }

    if (isNew) {
      sendRequest(createSystemWorkflow(body)).then(response => {
        displaySuccess()
      })
    }
  }

  const displaySuccess = () => {
    notification.success({
      message: 'Success!',
      description: 'Your workflow has been saved.',
      duration: 3
    });
    navigate("/system/workflows");
  }

  const menu = (step, index) => {
    return (
      <Menu>
        <Menu.Item>
          <div onClick={() => handleEditStep(step)}>
            <EditOutlined style={{ marginRight: 8}}/> Edit Step
          </div>
        </Menu.Item>
        <Menu.Item>
          <div onClick={() => handleRemove(step)}>
            <DeleteOutlined style={{ marginRight: 8}}/> Delete Step
          </div>
        </Menu.Item>
      </Menu>
    )
  };

  const handleSave = () => {
    if (isNewStep) {
      var newSteps = cloneDeep(steps)
      if (isNew) {
        newSteps.push(selectedStep)
        setSteps(sortSteps(newSteps, true))
        setAddEditModalVisible(false);
      } else {
        sendRequest(createSystemWorkflowStep(workflowId, selectedStep)).then(response => {
          newSteps.push(response)
          setSteps(sortSteps(newSteps, true))
          setAddEditModalVisible(false);
        })
      }
    } else {
      var newSteps = cloneDeep(steps)
      var newSelectedStep = cloneDeep(selectedStep)
      if (isNew) {
        for (var i = 0; i < newSteps.length; i++) {
          if (newSteps[i].system_workflow_step_id == newSelectedStep.system_workflow_step_id) {
            newSteps[i] = newSelectedStep
          }
        }
        setSteps(sortSteps(newSteps, true))
        setAddEditModalVisible(false);
      } else {
        sendRequest(updateSystemWorkflowStep(workflowId, newSelectedStep.system_workflow_step_id, newSelectedStep)).then(response => {
          for (var i = 0; i < newSteps.length; i++) {
            if (newSteps[i].system_workflow_step_id == response.system_workflow_step_id) {
              newSteps[i] = response
            }
          }
          setSteps(sortSteps(newSteps, true))
          setAddEditModalVisible(false);
        })
      }
    }
  };

  const handleRemove = (step) => {
    var newSteps = cloneDeep(steps)
    if (isNew) {
      newSteps = newSteps.filter(x => x.system_workflow_step_id != step.system_workflow_step_id)
      setSteps(sortSteps(newSteps, true))
      setAddEditModalVisible(false);
    } else {
      sendRequest(deleteSystemWorkflowStep(workflowId, step.system_workflow_step_id)).then(response => {
        newSteps = newSteps.filter(x => x.system_workflow_step_id != step.system_workflow_step_id)
        setSteps(sortSteps(newSteps, true))
        setAddEditModalVisible(false);
      })
    }
  }

  const handleAddStep = () => {
    setAddEditModalVisible(true)
    setIsNewStep(true)
    const newStep = {
      system_workflow_step_id: uniqid(),
      time_unit: "days",
      time_period: "before_event",
      metadata: {}
    }
    setSelectedStep(newStep)
  }

  const handleEditStep = (step) => {
    setAddEditModalVisible(true)
    setIsNewStep(false)
    setSelectedStep(step)
  }

  const handleUpdateWorkflow = () => {
    var body = {
      workflow_name: workflowName,
      active: workflowActive
    }
    sendRequest(updateSystemWorkflow(workflowId, body)).then(response => {
      notification.success({
        message: 'Success!',
        description: 'Your workflow has been updated.',
        duration: 2
      });
    })
  }

  const onSelectTab = (tab) => {
    if (isNew) {
      navigate(`/system/workflows/new?tab=${tab}`, { replace: true })
    } else {
      navigate(`/system/workflows/${workflowId}/edit?tab=${tab}`, { replace: true })
    }
    setSelectedTab(tab)
  }

  const renderHeader = () => {
    return (
      <div className="p-20">
        <div>
          <span className="c-blue fw-700 cursor-default" onClick={() => navigate(-1)}>
            Workflows
          </span>
          <span className="fs-10 mh-5"><MdArrowForwardIos/></span>
          <span className="cursor-default c-text-gray">
            { isNew ? "New" : "Edit" }
          </span>
        </div>
        <div className="fw-700 fs-24 mt-5">{ isEmpty(workflowName) ? "New Workflow" : workflowName }</div>
        <div className="tab-slider mt-10">
          <div className={`tab-slider--tab ${selectedTab == "steps" ? "selected" : ""}`} onClick={() => onSelectTab("steps")}>
            Steps
          </div>
          <div className={`tab-slider--tab ${selectedTab == "settings" ? "selected" : ""}`} onClick={() => onSelectTab("settings")}>
            Settings
          </div>
        </div>
      </div>
    )
  }

  const renderStep = (step, index) => {
    return (
      <div className="ph-20 pv-10 flex-row flex-middle b-border" key={index}>
        <div className="flex-0 mr-15">
          <div className="workflow-step-number">
            {index + 1}
          </div>
        </div>
        <div className="flex-1" onClick={() => handleEditStep(step)}>
          <div className="fw-500 line-1-3"><span className="fw-700">{ getActionTypeDescription(step.workflow_step_type) }: </span>{ step.description }</div>
          <div className="c-blue fs-12">{ getStepWhenDescription(step) }</div>
        </div>
        <div className="flex-0">
          <Dropdown overlay={menu(step)} placement="bottomRight" trigger="click">
            <div className="dots-container">
              <MdOutlineMoreHoriz style={{ fontSize: 24, color: '#999'}}/>
            </div>
          </Dropdown>
        </div>
      </div>
    )
  }

  const renderEmptyState = () => {
    return (
      <div className="shadow-card ph-20 pv-50 text-center">
        <img src={emptyStateImage} width="200"/>
        <div className="fs-14 c-text-gray mt-30">Get started by creating your first step.</div>
        <button className="page-title-button mt-20" onClick={handleAddStep}>Add Step</button>
      </div>
    )
  }

  const renderTab = () => {
    if (selectedTab === "steps") {
      return renderStepsTab()
    } else {
      return renderSettingsTab()
    }
  }

  const renderStepsTab = () => {
    if (steps && steps.length == 0) {
      return renderEmptyState()
    }
    return (
      <>
        <div className="shadow-card">
          { steps && steps.map((x, i) => renderStep(x, i))}
        </div>
        <button className="page-title-button mt-20" onClick={handleAddStep}>Add Step</button>
      </>
    )
  }

  const renderSettingsTab = () => {
    return (
      <>
        <div className="shadow-card p-20">
          <div className="c-text-gray mb-5">Workflow Name</div>
          <Input placeholder="Workflow Name" size="large" value={workflowName} onChange={(e) => setWorkflowName(e.target.value)}/> 
          <div className="mt-15">
            <Checkbox checked={workflowActive} onClick={(e) => setWorkflowActive(e.target.checked)}>Active</Checkbox>
          </div>
          { !isNew && (
            <button className="page-title-button mt-20" onClick={handleUpdateWorkflow}>Save</button>
          )}
        </div>
      </>
    )
  }

  const renderContent = () => {
    return (
      <div className="ph-15 pt-15" style={{ maxWidth: 600, paddingBottom: 90 }}>
        { renderTab() }
        { isNew && (
          <div className="sticky-footer p-0">
            <div className="p-15 bg-white t-border">
              <button className="page-title-button" onClick={onSubmit}>Save</button>
            </div>
          </div>
        )}
        <WorkflowTaskModal
          title={isNewStep ? "New Step" : "Edit Step"}
          isVisible={isAddEditModalVisible}
          setVisible={setAddEditModalVisible}
          selectedStep={selectedStep}
          setSelectedStep={setSelectedStep}
          handleSave={handleSave}
        />
      </div>
    )
  }

  if (isLoading) {
    return <LoadingSpinner/>
  }

  return (
    <AdminContent header={renderHeader()} body={renderContent()}/>
  );
}

export default NewWorkflowTemplatePage;

import React, { useEffect, useState } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import arrayMove from 'array-move';
import {SortableContainer, sortableElement} from 'react-sortable-hoc';
import { isUndefined, cloneDeep, isEmpty, isArray, sortBy, isNull, startsWith } from "lodash";
import { 
  renderFormLabel,
  renderTextAreaField,
  renderSearchSelectField
} from '../../components/formFields'
import { Row, Col, Input, Modal, Button, Form, Switch, Dropdown, Menu, Select, Tooltip } from 'antd';
import { 
  getEvent,
  getV2EventSpecialEvents,
  getEventTimeline,
  updateEventTimeline
} from '../../api';
import useApi from '../../hooks/useApi';
import LoadingSpinner from '../../components/loading';
import AdminContent from '../../components/adminContent';
import { getFormattedTime, getTimeOptions } from '../../helpers/timelineHelper';
import { 
  timelineHasSongs, 
  getSpecialEventSongSummary, 
  findSpecialEvent
} from '../../helpers/specialEventHelper';
import ShareModal from '../../modals/shareModal';
import FloatingContainer from '../../components/floatingContainer'
import emptyStateImage from '../../images/empty-document-icon.png';
import { MdDragIndicator, MdOutlineClose, MdArrowForwardIos, MdOutlineIosShare, MdOutlineKeyboardArrowDown } from "react-icons/md";
import { HiSortDescending } from "react-icons/hi";
import { PlusOutlined, EditOutlined, EllipsisOutlined, DeleteOutlined, UpOutlined, DownOutlined } from '@ant-design/icons';
import { FiDownload, FiEye, FiPlus } from "react-icons/fi";
import useDocumentTitle from '../../hooks/useDocumentTitle';

const BASE_URL = process.env.REACT_APP_BASE_URL;

const TimelinePage = () => {

  const [isLoading, setLoading] = useState(true);
  const [showMusic, setShowMusic] = useState(true);
  const [isShareTimelineModalVisible, setShareTimelineModalVisible] = useState(false);
  const [specialEventSections, setSpecialEventSections] = useState([]);
  const [isNewSectionModalVisible, setIsNewSectionModalVisible] = useState(false);
  const [selectedSectionIndex, setSelectedSectionIndex] = useState(null);
  const [selectedItemIndex, setSelectedItemIndex] = useState(null);
  const [sections, setSections] = useState([]);
  const [event, setEvent] = useState({});
  const [newSectionName, setNewSectionName] = useState(null);
  const [selectedTab, setSelectedTab] = useState("special-event");
  const [accountSettings, setAccountSettings] = useState({});

  useDocumentTitle("Timeline")
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const [sendRequest] = useApi()

  const [form] = Form.useForm();

  const eventId = params.event_id;

  useEffect(() => {
    window.scrollTo(0, 0);
    if (eventId) {
      refreshPage();
    } else {
      setLoading(false)
    }
  }, []);

  const refreshPage = async () => {
    try {
      const eventTimelineResults = await sendRequest(getEventTimeline(eventId));
      setSections(eventTimelineResults.content ?? [])
      setAccountSettings(eventTimelineResults.account_settings)

      const eventSpecialEventResults = await sendRequest(getV2EventSpecialEvents(eventId));
      setSpecialEventSections(eventSpecialEventResults)

      const eventResults = await sendRequest(getEvent(eventId));
      setEvent(eventResults)

      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  const saveTimeline = async (timelineSections) => {
    setSections(timelineSections)
    try {
      await sendRequest(updateEventTimeline(eventId, { content: timelineSections }))
    } catch {}
  }

  const handleCancel = () => {
    setSelectedItemIndex(null)
    setSelectedSectionIndex(null)
  };

  const isValid = (value) => {
    return !isUndefined(value) && !isNull(value) && value != ""
  }

  const handleSave = async (values) => {

    if (!isNull(selectedItemIndex) && !isNull(selectedSectionIndex)) {

      var custom = false
      var id = ""
      var name = ""
      var time = "TBD"
      var notes = ""
      if (selectedTab == "special-event") {
        const specialEvent = findSpecialEvent(values["event_special_event_id"], specialEventSections)
        custom = false
        id = specialEvent.event_special_event_id ?? ""
        name = specialEvent.special_event_name ?? ""
      } else {
        custom = true
        name = isValid(values["special_event_name"]) ? values["special_event_name"] : ""
      }
      time = isValid(values["time"]) ? values["time"] : "TBD"
      notes = isValid(values["notes"]) ? values["notes"] : ""

      var newItem = {
        event_special_event_id: id,
        special_event_name: name,
        time: time,
        notes: notes,
        custom: custom
      }

      var newSections = cloneDeep(sections)
      newSections[selectedSectionIndex].items[selectedItemIndex] = newItem
      setSelectedItemIndex(null)
      setSelectedSectionIndex(null)
      saveTimeline(newSections)
    }
  };

  const handleRowClick = (item, itemIndex, sectionIndex) => {
    setSelectedItemIndex(itemIndex)
    setSelectedSectionIndex(sectionIndex)
    setSelectedTab(item.custom ? "custom" : "special-event")
    const fields = {
      event_special_event_id: isEmpty(item.event_special_event_id) ? null : item.event_special_event_id,
      special_event_name: item.special_event_name,
      time: item.time,
      notes: item.notes
    }
    form.setFieldsValue(fields)
  };

  const handleReorder = (index) => {
    var newSections = cloneDeep(sections)
    var newItems = newSections[index].items
    const sortedItems = sortBy(newItems, 'time')
    newSections[index].items = sortedItems
    saveTimeline(newSections)
  }

  const handleAddSection = () => {
    setSelectedSectionIndex(null)
    setNewSectionName("")
    setIsNewSectionModalVisible(true)
  }

  const handleNewSectionCancel = () => {
    setNewSectionName("")
    setIsNewSectionModalVisible(false);
  };

  const handleNewSectionSave = () => {
    if (newSectionName == "") {
      return
    }
    setIsNewSectionModalVisible(false);
    var newSections = cloneDeep(sections)
    if (selectedSectionIndex == null) {
      newSections.push({ name: newSectionName, items: [] })
    } else {
      newSections[selectedSectionIndex].name = newSectionName;
    }
    saveTimeline(newSections)
  };

  const handleEditSectionName = (name, index) => {
    setSelectedSectionIndex(index)
    setNewSectionName(name)
    setIsNewSectionModalVisible(true)
  }

  const handleSectionDelete = (sectionIndex) => {
    var newSections = cloneDeep(sections)
    newSections.splice(sectionIndex, 1);
    saveTimeline(newSections)
  }

  const moveSection = (direction, index) => {
    var newSections = cloneDeep(sections)
    var newIndex = direction == "up" ? index - 1 : index + 1
    const sortedSections = arrayMove(newSections, index, newIndex).filter(el => !!el);
    saveTimeline(sortedSections)
  }

  const handleAddNewItem = (index) => {
    setSelectedSectionIndex(index)
    const fields = {
      type: "",
      event_special_event_id: "",
      special_event_name: "",
      time: "TBD",
      notes: ""
    }
    var newSections = cloneDeep(sections)
    newSections[index].items.push(fields);
    saveTimeline(newSections)
  }

  const handleRemoveItem = () => {
    var newSections = cloneDeep(sections)
    newSections[selectedSectionIndex].items.splice(selectedItemIndex, 1);
    setSelectedItemIndex(null)
    setSelectedSectionIndex(null)
    saveTimeline(newSections)
  }

  const handleTabClick = (tab) => {
    setSelectedTab(tab)
    const fields = {
      event_special_event_id: null,
      special_event_name: ""
    }
    form.setFieldsValue(fields)
  }

  const onSortEnd = async (oldIndex, newIndex, sectionIndex) => {
    var newSections = cloneDeep(sections)
    var newItems = cloneDeep(newSections[sectionIndex].items);
    if (oldIndex !== newIndex) {
      const sortedItems = arrayMove(newItems, oldIndex, newIndex).filter(el => !!el);
      newSections[sectionIndex].items = sortedItems
      setSections(newSections)
      saveTimeline(newSections)
    }
  };

  const downloadPDF = async () => {
    window.open(`${BASE_URL}/pdfs/event/${event.event_id}/timeline`, "_blank")
  }

  const menu = (section, index) => {
    return (
      <Menu>
        <Menu.Item>
          <div onClick={() => handleEditSectionName(section.name, index)}>
            <EditOutlined style={{ marginRight: 8}}/> Edit Section Name
          </div>
        </Menu.Item>
        { index > 0 && (
          <Menu.Item>
            <div onClick={() => moveSection("up", index)}>
              <UpOutlined style={{ marginRight: 8}}/> Move Section Up
            </div>
          </Menu.Item>
        )}
        { index < sections.length - 1 && (
          <Menu.Item>
            <div onClick={() => moveSection("down", index)}>
              <DownOutlined style={{ marginRight: 8}}/> Move Section Down
            </div>
          </Menu.Item>
        )}
        <Menu.Item>
          <div onClick={() => handleSectionDelete(index)}>
            <DeleteOutlined style={{ marginRight: 8}}/> Delete Section
          </div>
        </Menu.Item>
        <Menu.Item>
          <div onClick={() => handleReorder(index)}>
            <HiSortDescending style={{ marginRight: 8}}/> Order by Time
          </div>
        </Menu.Item>
      </Menu>
    )
  };

  const actionMenu = () => {
    return (
      <Menu>
        <Menu.Item>
          <div onClick={handleAddSection}>
            <FiPlus style={{ marginRight: 8}}/> Add Section
          </div>
        </Menu.Item>
        <Menu.Item>
          <div onClick={() => navigate(`/event/${eventId}/timeline`)}>
            <FiEye style={{ marginRight: 8}}/> Preview
          </div>
        </Menu.Item>
        <Menu.Item>
          <div onClick={() => setShareTimelineModalVisible(true)}>
            <MdOutlineIosShare style={{ marginRight: 8}}/> Share
          </div>
        </Menu.Item>
        <Menu.Item>
          <div onClick={() => downloadPDF()}>
            <FiDownload style={{ marginRight: 8}}/> View / Download PDF
          </div>
        </Menu.Item>
      </Menu>
    )
  };

  const renderSong = (song, index) => { 
    return (
      <div className="timeline-card--music-row" key={index}>
        <div className="timeline-card--music-image">
          <img src={song.image} width={32}/>
        </div>
        <div className="timeline-card--music-details">
          <div className="fs-12 fw-700 line-1-2">{song.title}</div>
          <div className="fs-10 c-text-gray line-1-2">{song.artist}</div>
        </div>
      </div>
    )
  }

  const renderPlaylist = (songs) => {
    return (
      <div className="timeline-card--music-row">
        <div className="timeline-card--music-image">
        </div>
        <div className="timeline-card--music-details">
          <div className="fs-12 fw-700 line-1-2">{"Playlist"}</div>
          <div className="fs-10 c-text-gray line-1-2">{songs.length} { songs.length == 1 ? "song" : "songs"} selected</div>
        </div>
      </div>
    )
  }

  const renderMusic = (item) => {
    const songSummary = getSpecialEventSongSummary(item.event_special_event_id, specialEventSections)
    if (songSummary.found) {
      if (songSummary.is_playlist && songSummary.songs.length > 0) {
          return renderPlaylist(songSummary.songs)
        } else {
          return songSummary.songs.map((x,i) => renderSong(x,i))
        }
    }
  }

  const renderRow = (event, itemIndex, sectionIndex) => {
    if (itemIndex == selectedItemIndex && sectionIndex == selectedSectionIndex) {
      return renderEditRow()
    }
    return (
      <div onClick={() => handleRowClick(event, itemIndex, sectionIndex)} className="timeline-card">
        <div className="timeline-card--col-1">
          <div className="timeline-card--details-row">
            <div className="timeline-card--time">
              { getFormattedTime(event.time, accountSettings) }
            </div>
            <div className="timeline-card--details">
              <div className="timeline-card--details--row-1">
                { isValid(event.special_event_name) ? (
                  <div className="timeline-card--name">{ event.special_event_name }</div>
                ) : (
                  <div className="c-text-gray">Click to edit...</div>
                )}
              </div>
              { isValid(event.notes) && (
                <div className="timeline-card--details--row-2">
                  <div className="timeline-card--notes line-breaks">{ event.notes }</div>
                </div>
              )}
            </div>
          </div>
          { showMusic && renderMusic(event) }
        </div>
        <div className="timeline-card--col-2 ph-5">
          <MdDragIndicator size={24} color={"#CCC"}/>
        </div>
      </div>
    )
  }

  const renderEditRow = () => {
    return (
      <div className="b-border pb-15">
        <Form form={form} layout="vertical" name="specialEvent" onFinish={handleSave}>
          <Row gutter={[15,15]} align="middle" className="mt-10">
            <Col xs={24}>
              <div className="tab-slider mt-10">
                <div className={`tab-slider--tab ${selectedTab == "special-event" ? "selected" : ""}`} onClick={() => handleTabClick("special-event")}>
                  Special Event
                </div>
                <div className={`tab-slider--tab ${selectedTab == "custom" ? "selected" : ""}`} onClick={() => handleTabClick("custom")}>
                  Custom
                </div>
              </div>
            </Col>
            { (selectedTab == "special-event" || selectedTab == "custom") && (
              <>
                <Col flex={0}>
                  <div style={{ width: 120 }}>
                    { renderSearchSelectField("", "time", "Time", getTimeOptions(accountSettings), false) }
                  </div>
                </Col>
                <Col flex={1}>
                  { selectedTab == "special-event" && (
                    <>
                      <Form.Item 
                        name={"event_special_event_id"}
                      >
                        <Select
                          style={{ width: '100%'}}
                          allowClear
                          placeholder={"Select an event..."}
                          size='large'
                        >
                          { specialEventSections.map(x => (
                            <Select.OptGroup label={x.section_name}>
                              { isArray(x.special_events) && x.special_events.map(e => (
                                <Select.Option value={e.event_special_event_id}>{e.special_event_name}</Select.Option>
                              ))}
                            </Select.OptGroup>
                          ))}
                        </Select>
                      </Form.Item>
                    </>
                  )}
                  { selectedTab == "custom" && (
                    <Form.Item
                      name={"special_event_name"}
                      rules={[{ required: true, message: `Special event is required!`, validateTrigger: "onBlur" }]}
                    >
                      <Input size="large" placeholder="Enter event"/>
                    </Form.Item>
                  )}
                </Col>
              </>
            )}
            <Col xs={24}>
              { renderTextAreaField("", "notes", 3, false, "Notes (optional)") }
            </Col>
            <Col xs={24}>
              <div className="text-right">
                <Button className="admin-small-button secondary" onClick={handleRemoveItem}>Remove</Button>
                <Button className="admin-small-button secondary ml-10" type="button" htmlType="button" onClick={handleCancel}>Cancel</Button>
                <Button className="admin-small-button ml-10" htmlType="submit">
                  Save
                </Button>
              </div>
            </Col>
          </Row>
        </Form>
      </div>
    )
  }

  const SortableItem = sortableElement(({value, itemIndex, sectionIndex}) => (
    <li className="">
      {renderRow(value, itemIndex, sectionIndex)}
    </li>
  ));

  const SortableList = SortableContainer(({items, sectionIndex}) => {
    return (
      <ul className="question-row-container">
        {items.map((value, index) => (
          <SortableItem key={`item-${index}`} index={index} itemIndex={index} sectionIndex={sectionIndex} value={value} disabled={!isNull(selectedItemIndex)}/>
        ))}
      </ul>
    );
  });

  const renderHeader = () => {
    const isAdmin = startsWith(location.pathname, "/admin/")
    return (
      <div className="p-20">
        { isAdmin ? (
          <div>
            <span className="c-blue fw-700 cursor-default" onClick={() => navigate(`/admin/events`)}>
              Events
            </span>
            <span className="fs-10 mh-5"><MdArrowForwardIos/></span>
            <span className="c-blue fw-700 cursor-default" onClick={() => navigate(`/admin/events/${eventId}?tab=planning&section=timeline`)}>
              Event Details
            </span>
            <span className="fs-10 mh-5"><MdArrowForwardIos/></span>
            <span className="cursor-default c-text-gray">
              Timeline
            </span>
          </div>
        ) : (
          <div>
            <span className="c-blue fw-700 cursor-default" onClick={() => navigate(-1)}>
              Timeline
            </span>
            <span className="fs-10 mh-5"><MdArrowForwardIos/></span>
            <span className="cursor-default c-text-gray">
              Edit Timeline
            </span>
          </div>
        )}
        <div className="flex-row flex-middle">
          <div className="fw-700 fs-24 mt-5 flex-1">Timeline</div>
        </div>
      </div>
    )
  }

  const renderSection = (section, index) => {
    return (
      <div key={index} className="mb-30">
        <div className="shadow-card p-20">
          <Row align="middle" gutter={[15]} className="b-border pb-15">
            <Col flex={1}>
              <div className="fw-700 fs-18">{ section.name }</div>
            </Col>
            <Col>
              <Dropdown overlay={menu(section, index)} placement="bottomRight" trigger="click">
                <div className="admin-icon-circle"><EllipsisOutlined/></div>
              </Dropdown>
            </Col>
          </Row>
          { section.items.length > 0 ? (
            <SortableList items={section.items} sectionIndex={index} onSortEnd={({ oldIndex, newIndex }) => onSortEnd(oldIndex, newIndex, index )} helperClass="question-row-dragging" pressDelay={200} lockAxis="y" />
          ) : (
            <div className="bg-gray radius-8 p-30 text-center mt-10">
              <div>No items have been added to this section yet.</div>
            </div>
          )}
          <div className="admin-link mt-15" onClick={() => handleAddNewItem(index)}><PlusOutlined/> Add Item</div>
        </div>
      </div>
    )
  }

  const renderContent = () => {
    if (isLoading) {
      return <LoadingSpinner/>
    }
    return (
      <>
        <div className="toolbar-container">
          <Dropdown overlay={actionMenu()} placement="bottomRight" trigger="click">
            <div className="toolbar-button">
              Actions <MdOutlineKeyboardArrowDown style={{ fontSize: 20, color: '#536DFE', marginLeft: 8}}/>
            </div>
          </Dropdown>
        </div>
        <FloatingContainer className="ph-20 mb-20" verticalPadding={20} maxWidth={800}>
          { sections && sections.length > 0 && (
            <>
              { timelineHasSongs(sections, specialEventSections) && (
                <div className="text-right mb-10">
                  <Switch checked={showMusic} onChange={(value) => setShowMusic(value)} />
                  <Tooltip title="Music is automatically populated from the music section.">
                    <span className="ml-10 mr-5 fw-600 cursor-default">Show music</span>
                  </Tooltip>
                </div>
              )}
              { sections && sections.map((x, i) => renderSection(x, i))}
              <div className="text-center t-border pt-20">
                <button className="page-title-button" onClick={handleAddSection}>Add Section</button>
              </div>
            </>
          )}
          { sections && sections.length == 0 && (
            <div className="shadow-card ph-20 pv-50 text-center">
              <img src={emptyStateImage} width="200"/>
              <div className="fs-14 c-text-gray mt-30">No sections have been added to this timeline yet.</div>
              <button className="page-title-button mt-20" onClick={handleAddSection}>Add Section</button>
            </div>
          )}
          { renderShareTimelineModal() }
          { renderNewSectionModal() }
        </FloatingContainer>
      </>
    )
  }

  const renderShareTimelineModal = () => {
    return (
      <ShareModal
        isVisible={isShareTimelineModalVisible}
        setVisible={setShareTimelineModalVisible}
        title="Share Timeline"
        link={`/event/${eventId}/timeline`}
      />
    )
  }

  const renderNewSectionModal = () => {
    return (
      <Modal visible={isNewSectionModalVisible} footer={null} onCancel={handleNewSectionCancel} width={400} closable={false} maskClosable={false} wrapClassName="rounded-modal">
        <Row align="middle">
          <Col flex={1}>
            <div className="fw-700 fs-18">{selectedSectionIndex == null ? "New Section" : "Edit Section"} </div>
          </Col>
          <Col>
            <div className="display-flex" onClick={handleNewSectionCancel}><MdOutlineClose size={30} color={"#CCC"}/></div>
          </Col>
        </Row>
        <Row gutter={[10,10]} className="mt-20">
          <Col xs={24}>
              <div className="bg-gray p-10 fs-12 radius-8">
                <b>Note:</b> A section is a way to logically group special events together. Most of the time events will only have one section, however the timeline can be split up into sections like "Ceremony" or "Reception" if the event is a wedding. Individual items with assigned times should be added within a section.
            </div>
          </Col>
          <Col xs={24}>
            { renderFormLabel("Section Name") }
            <Input size="large" value={newSectionName} onChange={(e) => setNewSectionName(e.target.value)}/>
          </Col>
        </Row>
        <div className="admin-modal-footer">
          <Button className="admin-small-button secondary" onClick={handleNewSectionCancel}>Cancel</Button>
          <Button className="admin-small-button ml-10" onClick={handleNewSectionSave}>
            {selectedSectionIndex == null ? "Add Section" : "Save"}
          </Button>
        </div>
      </Modal>
    )
  }

  return (
    <AdminContent header={renderHeader()} body={renderContent()}/>
  );
}

export default TimelinePage;

import React, { useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { BackToHomeLink, DocLink } from '../../../components/docComponents'
import AdminContent from '../../../components/adminContent';
import FloatingContainer from '../../../components/floatingContainer'
import useDocumentTitle from '../../../hooks/useDocumentTitle';

const DocsRecommendedPlaylistsPage = () => {

  useDocumentTitle("Recommended Playlists | Documentation")
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const renderContent = () => {
    return (
      <FloatingContainer className="ph-20 bg-white" verticalPadding={20} maxWidth={1000}>
        <BackToHomeLink/>
        <div className="fw-700 fs-24 mt-20">Recommended Playlists</div>
        <div className="fw-500 fs-16 c-text-gray">Customize the songs suggested to clients.</div>
        <div className="b-border mv-20"></div>
        <div className="fw-700 fs-18">Overview</div>
        <div className="fw-500 mb-15 line-1-4 mt-15">
          When a client selects a song for their special event, a playlist will appear with recommendations based on the type of event. For example, if the special event is the "First Dance" of a wedding, a playlist will appear with songs that are recommended for a first dance. By default, DJ Planning Center provides specially curated playlists for all special event types, however you have the option to override these default playlists with custom playlists of your own.
        </div>
        <div className="b-border mv-20"></div>
        <div className="fw-700 fs-18 mb-20">Creating a recommended playlist</div>
        <ol>
          <li className="mb-15">
            Go to the <DocLink to="/admin/recommended-playlists">Recommended Playlists</DocLink> page and add a new playlist.
          </li>
          <li className="mb-15">
            Enter a playlist name in the header.
          </li>
          <li className="mb-15">
            Select the <b>Add Songs</b> button to display the platform selection modal. You have the option to search Spotify, Apple Music, or add a song manually.
          </li>
          <li className="mb-15">
            Search songs and select the <b>"+"</b> button next to a song to add it to the playlist.
          </li>
          <li className="mb-15">
            After you are finished adding songs, close the search modal and reorder the songs if needed.
          </li>
        </ol>
        <div className="ml-20 mt-20">
          <button className="small-primary-button mb-5" onClick={() => navigate("/admin/recommended-playlists")}>Go to recommended playlists</button>
        </div>
        <div className="b-border mv-20"></div>
        <div className="fw-700 fs-18 mb-20">Assigning a playlist to a special event</div>
        <ol>
          <li className="mb-15">
            Go to the <DocLink to="/admin/special-event-templates">Special Event Templates</DocLink> page and create or select a template.
          </li>
          <li className="mb-15">
            Select a special event from the template to display the edit modal.
          </li>
          <li className="mb-15">
            Select the playlist you created from the <b>"Recommended Playlist"</b> dropdown and select the <b>Save</b> button.
          </li>
          <li className="mb-15">
            When your client selects songs for their special events, they will now see your playlist instead of the default playlist.
          </li>
        </ol>
        <div className="ml-20 mt-20">
          <button className="small-primary-button mb-5" onClick={() => navigate("/admin/special-event-templates")}>Go to special event templates</button>
        </div>
        <div className="b-border mv-50"></div>
      </FloatingContainer>
    )
  }

  return (
    <AdminContent body={renderContent()}/>
  );
}

export default DocsRecommendedPlaylistsPage;

import React, { useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { Row, Col } from 'antd';
import AdminContent from '../../components/adminContent';
import FloatingContainer from '../../components/floatingContainer'
import FeatureSections from '../../components/featureSections'
import FadeDownWhileInView from '../../components/fadeDownWhileInView'
import iosDownloadButton from '../../images/ios-download-button.png';
import androidDownloadButton from '../../images/android-download-button.png';
import useDocumentTitle from '../../hooks/useDocumentTitle';

const MobileAppPage = () => {

  useDocumentTitle("Mobile App")
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const renderQuickLink = (icon, subtitle, title, link) => {
    return (
      <Col xs={24} md={24} lg={12} xl={8}>
        <div className="gray-hover-card p-20" onClick={() => navigate(link)}>
          <div className="quick-link-row">
            <div className="quick-link-col-1">
              <div className="quick-link-icon">{icon}</div>
            </div>
            <div className="quick-link-col-2">
              <div className="fs-10 fw-600 c-text-gray">{subtitle}</div>
              <div className="fs-16 fw-700">{title}</div>
            </div>
          </div>
        </div>
      </Col>
    )
  }

  const renderContent = () => {
    return (
      <>
        <FloatingContainer className="bg-gray" verticalPadding={80}>
          <FadeDownWhileInView className="text-center">
            <div className="fs-30 fw-700 line-1-2">Download the app.</div>
            <div className="mt-30">
              <a href="https://play.google.com/store/apps/details?id=com.djpcapp" target="_blank">
                <img src={androidDownloadButton} height={48}/>
              </a>
              <a href="https://apps.apple.com/us/app/dj-planning-center/id1638751912" target="_blank">
                <img src={iosDownloadButton} height={48} className="ml-10"/>
              </a>
            </div>
          </FadeDownWhileInView>
        </FloatingContainer>
        <FeatureSections/>
      </>
    )
  }

  return (
    <AdminContent body={renderContent()}/>
  );
}

export default MobileAppPage;

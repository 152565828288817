import React, { useEffect, useState } from "react";
import { Row, Col, Table, Modal, Select, Input, Menu, Dropdown } from 'antd';
import { isArray } from 'lodash';
import moment from "moment";
import { useNavigate } from 'react-router';
import useApi from '../../hooks/useApi';
import { sendSystemAccountEmail, getSystemAccountEmails } from '../../api';
import LoadingSpinner from '../../components/loading';
import AdminContent from '../../components/adminContent';
import FloatingContainer from '../../components/floatingContainer'
import { MdOutlineClose } from "react-icons/md";

import { useEditor, EditorContent } from '@tiptap/react'
import { mergeAttributes } from '@tiptap/core'
import StarterKit from '@tiptap/starter-kit'
import TextAlign from '@tiptap/extension-text-align'
import Placeholder from '@tiptap/extension-placeholder'
import Paragraph from '@tiptap/extension-paragraph'
import Underline from '@tiptap/extension-underline'
import { HiOutlineChevronDown } from "react-icons/hi";
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { AiOutlineBold, AiOutlineItalic, AiOutlineUnderline, AiOutlineStrikethrough, AiOutlineAlignLeft, AiOutlineAlignRight, AiOutlineAlignCenter, AiOutlineUnorderedList, AiOutlineOrderedList, AiOutlineUndo, AiOutlineRedo } from "react-icons/ai";

const MenuBar = ({ editor }) => {
  if (!editor) {
    return null
  }

  const menuClass = "display-flex flex-middle justify-content-center fs-18 mr-5"
  
  const menu = () => {
    return (
      <Menu>
        <Menu.Item>
          <div onClick={() => editor.chain().focus().setParagraph().run()}>
            Paragraph
          </div>
        </Menu.Item>
        <Menu.Item>
          <div onClick={() => editor.chain().focus().setHeading({ level: 1 }).run()}>
            Heading 1
          </div>
        </Menu.Item>
        <Menu.Item>
          <div onClick={() => editor.chain().focus().setHeading({ level: 2 }).run()}>
            Heading 2
          </div>
        </Menu.Item>
        <Menu.Item>
          <div onClick={() => editor.chain().focus().setHeading({ level: 3 }).run()}>
            Heading 3
          </div>
        </Menu.Item>
        <Menu.Item>
          <div onClick={() => editor.chain().focus().setHeading({ level: 4 }).run()}>
            Heading 4
          </div>
        </Menu.Item>
      </Menu>
    )
  };

  const getActiveTextType = () => {
    if (editor.isActive('paragraph')) {
      return "Paragraph"
    } else 
    if (editor.isActive('heading', { level: 1 })) {
      return "Heading 1"
    } else if (editor.isActive('heading', { level: 2 })) {
      return "Heading 2"
    } else if (editor.isActive('heading', { level: 3 })) {
      return "Heading 3"
    } else if (editor.isActive('heading', { level: 4 })) {
      return "Heading 4"
    } else {
      return "Paragraph"
    }
  }

  return (
    <FloatingContainer className="" verticalPadding={0} maxWidth={850}>
      <div className="flex-row">
        <div className="display-flex flex-middle mh-10 cursor-pointer">
          <Dropdown overlay={menu()} placement="bottomRight" trigger="click">
            <div className="display-flex flex-middle">{getActiveTextType()} <HiOutlineChevronDown style={{ marginLeft: 5}}/></div>
          </Dropdown>
        </div>
        <div style={{ borderRight: "1px solid #CCC", marginLeft: 5, marginRight: 10 }}></div>
        <div style={{ height: 30, width: 30 }} className={`${menuClass} ${editor.isActive('bold') ? 'bg-gray' : ''}`} onClick={() => editor.chain().focus().toggleBold().run()}><AiOutlineBold/></div>
        <div style={{ height: 30, width: 30 }} className={`${menuClass}  ${editor.isActive('italic') ? 'bg-gray' : ''}`} onClick={() => editor.chain().focus().toggleItalic().run()}><AiOutlineItalic/></div>
        <div style={{ height: 30, width: 30 }} className={`${menuClass}  ${editor.isActive('underline') ? 'bg-gray' : ''}`} onClick={() => editor.chain().focus().toggleUnderline().run()}><AiOutlineUnderline/></div>
        <div style={{ height: 30, width: 30 }} className={`${menuClass}  ${editor.isActive('strike') ? 'bg-gray' : ''}`} onClick={() => editor.chain().focus().toggleStrike().run()}><AiOutlineStrikethrough/></div>
        <div style={{ borderRight: "1px solid #CCC", marginLeft: 5, marginRight: 10 }}></div>
        <div style={{ height: 30, width: 30 }} className={`${menuClass}  ${editor.isActive({ textAlign: 'left' })? 'bg-gray' : ''}`} onClick={() => editor.chain().focus().setTextAlign("left").run()}><AiOutlineAlignLeft/></div>
        <div style={{ height: 30, width: 30 }} className={`${menuClass}  ${editor.isActive({ textAlign: 'center' })? 'bg-gray' : ''}`} onClick={() => editor.chain().focus().setTextAlign("center").run()}><AiOutlineAlignCenter/></div>
        <div style={{ height: 30, width: 30 }} className={`${menuClass}  ${editor.isActive({ textAlign: 'right' })? 'bg-gray' : ''}`} onClick={() => editor.chain().focus().setTextAlign("right").run()}><AiOutlineAlignRight/></div>
        <div style={{ borderRight: "1px solid #CCC", marginLeft: 5, marginRight: 10 }}></div>
        <div style={{ height: 30, width: 30 }} className={`${menuClass}  ${editor.isActive('bulletList')? 'bg-gray' : ''}`} onClick={() => editor.chain().focus().toggleBulletList().run()}><AiOutlineUnorderedList/></div>
        <div style={{ height: 30, width: 30 }} className={`${menuClass}  ${editor.isActive('orderedList')? 'bg-gray' : ''}`} onClick={() => editor.chain().focus().toggleOrderedList().run()}><AiOutlineOrderedList/></div>
        <div style={{ borderRight: "1px solid #CCC", marginLeft: 5, marginRight: 10 }}></div>
        <div style={{ height: 30, width: 30 }} className={`${menuClass}  ${editor.isActive('bulletList')? 'bg-gray' : ''}`} onClick={() => editor.chain().focus().undo().run()}><AiOutlineUndo/></div>
        <div style={{ height: 30, width: 30 }} className={`${menuClass}  ${editor.isActive('orderedList')? 'bg-gray' : ''}`} onClick={() => editor.chain().focus().redo().run()}><AiOutlineRedo/></div>
        <div style={{ borderRight: "1px solid #CCC", marginLeft: 5, marginRight: 10 }}></div>
      </div>
    </FloatingContainer>
  )
}

const EmailsPage = () => {

  const audience = [
    {
      text: "Paying Users",
      value: "ACTIVE"
    },
    {
      text: "Trialing Users",
      value: "TRIALING"
    }
  ]

  const [isLoading, setLoading] = useState(true);
  const [isNewEmailModalVisible, setNewEmailModalVisible] = useState(false);
  const [isPreviewEmailModalVisible, setPreviewEmailModalVisible] = useState(false);
  const [selectedSubject, setSelectedSubject] = useState("");
  const [selectedAudience, setSelectedAudience] = useState("");
  const [systemEmails, setSystemEmails] = useState([]);
  const [selectedEmail, setSelectedEmail] = useState({});

  const navigate = useNavigate();
  const [sendRequest] = useApi()

  const editor = useEditor({
    extensions: [
      StarterKit,
      // Paragraph.extend({
      //   parseHTML() {
      //     return [{ tag: 'div' }]
      //   },
      //   renderHTML({ HTMLAttributes }) {
      //     return ['div', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), 0]
      //   },
      // }),
      Underline,
      TextAlign.configure({
        types: ['heading', 'paragraph'],
      }),
      Placeholder.configure({
        placeholder: "Start typing..."
      }),
    ],
    content: '',
  })

  const recepientsColumns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (text, record) => record.first_name + " " + record.last_name
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      render: (text) => text
    }
  ];

  const emailColumns = [
    {
      title: 'Subject',
      dataIndex: 'subject',
      key: 'subject',
      render: (text, record) => text
    },
    {
      title: 'Audience',
      dataIndex: 'audience',
      key: 'audience',
      render: (text) => text
    },
    {
      title: 'Sent',
      dataIndex: 'sent_datetime',
      key: 'sent_datetime',
      render: (text, record) => moment(text).format("MM/DD/YYYY @ h:mma")
    },
    {
      title: 'Success Count',
      dataIndex: 'success_count',
      key: 'success_count',
      render: (text) => text
    },
    {
      title: 'Failed Count',
      dataIndex: 'failed_count',
      key: 'failed_count',
      render: (text) => text
    },
  ];


  useEffect(() => {
    window.scrollTo(0, 0);
    refreshPage()
  }, []);
  

  const refreshPage = async () => {
    try {
      const emailResults = await sendRequest(getSystemAccountEmails());
      setSystemEmails(emailResults)
    } finally {
      setLoading(false)
    }
  }

  const selectEmail = async (email) => {
    try {
      setSelectedEmail(email)
      setPreviewEmailModalVisible(true)
    } catch {}
  }

  const sendEmail = () => {
    if (!selectedSubject) {
      return
    }
    if (!selectedAudience) {
      return
    }
    Modal.confirm({
      title: 'Confirm',
      icon: <ExclamationCircleOutlined />,
      content: 'Are you sure you want to send this email?',
      okText: 'Yes',
      cancelText: 'No',
      onOk: () => confirmSend()
    });
  }

  const confirmSend = async () => {
    try {
      const body = {
        audience: selectedAudience,
        subject: selectedSubject,
        message: editor.getHTML()
      }

      await sendRequest(sendSystemAccountEmail(body))
      await refreshPage()
      setNewEmailModalVisible(false)
    } catch {}
  }

  const onNewEmail = () => {
    setNewEmailModalVisible(true)
    setSelectedAudience(null)
    setSelectedSubject("")
    editor.commands.setContent('')
  }

  const renderHeader = () => {
    return (
      <Row align="middle" className="p-20">
        <Col flex={1}>
          <div className="admin-page-title">Emails</div>
        </Col>
        <Col>
          <button className="page-title-button" onClick={() => onNewEmail()}>New</button>
        </Col>
      </Row>
    )
  }

  const renderNewEmailModal = () => {
    return (
      <Modal visible={isNewEmailModalVisible} closable={false} footer={null} width={800} wrapClassName="rounded-modal">
        <div className="text-right" onClick={() => setNewEmailModalVisible(false)}><MdOutlineClose size={30} color={"#CCC"}/></div>
        <div className="mt-10">
          <Row align="middle" className="p-20" gutter={[20,20]}>
            <Col xs={24}>
              <div className="fw-700 mb-5">Audience</div>
              <Select
                style={{ width: "100%" }}
                placeholder={"Select an audience"}
                size='large'
                value={selectedAudience}
                onChange={(value) => setSelectedAudience(value)}
              >
                { audience.map((x,i) => (
                  <Select.Option value={x.value} key={i}>{x.text}</Select.Option>
                ))}
              </Select>
            </Col>
            <Col xs={24}>
              <div className="fw-700 mb-5">Subject</div>
              <Input 
                value={selectedSubject}
                onChange={(e) => setSelectedSubject(e.target.value)}
                placeholder="Subject"
                size="large"/>
            </Col>
            <Col xs={24}>
              <div className="fw-700 mb-5">Message</div>
              <div className="bg-white border p-10">
                <MenuBar editor={editor} />
              </div>
              <div style={{ minHeight: 400 }} className="border p-15">
                <EditorContent editor={editor} />
              </div>
            </Col>
          </Row>
          <button className="primary-button" type="button" onClick={() => sendEmail()}>Send Email</button>
        </div>
      </Modal>
    )
  }

  const renderPreviewEmailModal = () => {
    const recepients = isArray(selectedEmail.recepients) ? selectedEmail.recepients : []
    const data = recepients.map((x, i) => {
      return {
        ...x,
        key: i
      }
    });

    return (
      <Modal visible={isPreviewEmailModalVisible} closable={false} footer={null} width={800} wrapClassName="rounded-modal">
        <div className="text-right" onClick={() => setPreviewEmailModalVisible(false)}><MdOutlineClose size={30} color={"#CCC"}/></div>
        <div className="mt-10">
          <Row align="middle" className="p-20" gutter={[20,20]}>
            <Col xs={8}>
              <div className="fw-700 mb-5">Audience</div>
              <div className="">
                { selectedEmail.audience }
              </div>
            </Col>
            <Col xs={8}>
              <div className="fw-700 mb-5">Sent</div>
              <div className="">
                { moment(selectedEmail.sent_datetime).format("MM/DD/YYYY @ h:mma") }
              </div>
            </Col>
            <Col xs={8}>
              <div className="fw-700 mb-5">Success / Fail</div>
              <div className="">
                { selectedEmail.success_count } / { selectedEmail.failed_count }
              </div>
            </Col>
            <Col xs={24}>
              <div className="fw-700 mb-5">Subject</div>
              <div className="">
                { selectedEmail.subject }
              </div>
            </Col>
            <Col xs={24}>
              <div className="fw-700 mb-5">Message</div>
              <div className="border p-15">
                <div dangerouslySetInnerHTML={{ __html: selectedEmail.message}} />
              </div>
            </Col>
            <Col xs={24}>
              <div className="fw-700 mb-5">Recepients</div>
              <Table 
                columns={recepientsColumns} 
                dataSource={data}
                pagination={false}
              />
            </Col>
          </Row>
          <button className="primary-button" type="button" onClick={() => setPreviewEmailModalVisible(false)}>Close</button>
        </div>
      </Modal>
    )
  }

  const renderContent = () => {

    const data = systemEmails.map((x, i) => {
      return {
        ...x,
        key: i
      }
    });

    return (
      <FloatingContainer className="ph-20" verticalPadding={20} maxWidth={1000}>
        <div className="admin-page-content-box">
          <Table 
            columns={emailColumns} 
            dataSource={data}
            pagination={false}
            onRow={(record, rowIndex) => {
              return {
                onClick: e => selectEmail(record)
              };
            }}
           />
        </div>
        { renderNewEmailModal() }
        { renderPreviewEmailModal() }
      </FloatingContainer>
    )
  }

  if (isLoading) {
    return <LoadingSpinner/>
  }

  return (
    <AdminContent header={renderHeader()} body={renderContent()}/>
  );
}

export default EmailsPage;

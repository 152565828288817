import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import qs from "qs";
import VerificationStatus from '../../constants/verificationStatus';
import useDocumentTitle from '../../hooks/useDocumentTitle';

import { verifyEmail, sendVerificationEmail } from '../../api';

const VerificationPage = () => {

  const [loading, setLoading] = useState(true);
  const [verificationStatus, setVerificationStatus] = useState(false);
  const [email, setEmail] = useState("");
  const [linkSent, setLinkSent] = useState(false);

  useDocumentTitle("Email Verification")
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0)
    const queryStrings = qs.parse(location.search, { ignoreQueryPrefix: true })
    if (!queryStrings.token) {
      navigate("/login", { replace: true })
    } else {
      verifyEmail(queryStrings.token).then((response) => {
        setVerificationStatus(response.data.status)
        setEmail(response.data.email)
        setLoading(false)
      }).catch((e) => {
        setLoading(false)
        setVerificationStatus("")
      })
    }
  }, [])

  const sendEmail = () => {
    sendVerificationEmail({ email: email }).then(response => {
      setVerificationStatus("")
      setLinkSent(true)
    }).catch(e => console.log(e))
  }

  const renderContent = () => {
    if (loading) {
      return null;
    } else if (verificationStatus == VerificationStatus.TOKEN_EXPIRED) {
      return renderPage("Oops!", "This link has expired. Please click the button below to send a new link.", "Resend email", () => sendEmail()); 
    } else if (verificationStatus == VerificationStatus.INVALID_TOKEN || verificationStatus == VerificationStatus.NOT_VERIFIED) {
      return renderPage("Oops!", "This link is invalid. Please check the link and try again.", "Sign in", () => navigate("/login"));
    } else if (verificationStatus == VerificationStatus.VERIFIED) {
      return renderPage("You're all set!", "Your email address has been verified. Click the button below to sign in.", "Sign in", () => navigate("/login"));
    } else if (linkSent) {
      return renderPage("Email sent!", "Please check your email for a verification link.", "Sign in", () => navigate("/login"));
    } else {
      return renderPage("Something went wrong!", "Please try again.", "Sign in", () => navigate("/login"));
    }
  }

  const renderPage = (title, subtitle, buttonText, buttonAction) => {
    return (
      <div className="center-content-box">
        <div className="center-content-box--title">{title}</div>
        <div className="center-content-box--subtitle">{subtitle}</div>
        <div className="center-content-box--form">
          <button className="primary-button" onClick={buttonAction}>{buttonText}</button>
        </div>
      </div>
    )
  }

  return (
    <div className="center-content-page">
      { renderContent() }
    </div>
  );
}

export default VerificationPage;

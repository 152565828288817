import { isUndefined, isEmpty, isNull, get, cloneDeep } from "lodash";
import moment from 'moment';

const getTimePeriodDescription = (period) => {
  var timePeriod = "";
  switch (period) {
    case "after_booking":
      timePeriod = "after booking date";
      break;
    case "before_event":
      timePeriod = "before event date";
      break;
    case "after_event":
      timePeriod = "after event date";
      break;
  }
  return timePeriod;
}

const getActionTypeDescription = (workflow_step_type) => {
  var actionType = "";
  switch (workflow_step_type) {
    case "email":
      actionType = "Send Email";
      break;
    case "text":
      actionType = "Send Text";
      break;
    case "todo":
      actionType = "To-Do";
      break;
    default:
      actionType = "To-Do";
      break;
  }
  return actionType;
}

const sortSteps = (_steps, setDueDate = false) => {
  var steps = cloneDeep(_steps)
  if (steps.length > 0 && setDueDate) {
    steps = steps.map(step => {
      return {
        ...step,
        due_date: getDueDate(step)
      }
    })
  }
  steps.sort((a, b) => {
    let fa = a.due_date.toLowerCase(),
        fb = b.due_date.toLowerCase();

    if (fa < fb) {
        return -1;
    }
    if (fa > fb) {
        return 1;
    }
    return 0;
  });
  return steps;
}

const getDueDate = (step) => {
  const { time_value, time_unit, time_period } = step
  const todayDate = moment()
  const eventDateMoment = moment("2050-01-01", "YYYY-MM-DD")
  const days = time_unit == "weeks" ? Number(time_value) * 7 : Number(time_value)
  var dueDate = "";
  if (time_period == "before_event") {
    dueDate = eventDateMoment.subtract(days, "days").format("YYYY-MM-DD")
  } else if (time_period == "after_event") {
    dueDate = eventDateMoment.add(days, "days").format("YYYY-MM-DD")
  } else if (time_period == "after_booking") {
    dueDate = todayDate.add(days, "days").format("YYYY-MM-DD")
  }
  return dueDate;
}

const getStepWhenDescription = (step) => {
  const { time_value, time_unit, time_period } = step;

  const timeUnit = time_value == 1 ? time_unit.slice(0, -1) : time_unit

  return `${time_value} ${timeUnit} ${getTimePeriodDescription(time_period)}`
}

export {
  getTimePeriodDescription,
  getActionTypeDescription,
  sortSteps,
  getStepWhenDescription
}
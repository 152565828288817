import React from 'react';
import { Row, Col } from 'antd';
import { Carousel } from 'react-responsive-carousel';
import FloatingContainer from './floatingContainer'
import FadeDownWhileInView from './fadeDownWhileInView'
import clientSelectPlatformImage from '../images/client-select-platform.png';
import clientSpotifySearchImage from '../images/client-spotify-search.png';
import clientAppleMusicSearchImage from '../images/client-apple-music-search.png';
import clientSpecialEventsImage from '../images/client-special-events.png';
import clientSpecialEventPlaylistImage from '../images/client-special-event-playlist.png';
import clientImportPlaylistImage from '../images/client-import-playlist.png';
import clientSpecialEventNotesImage from '../images/client-special-event-notes.png';
import clientMusicSelectSpecialEvents from '../images/client-music-select-special-events.png';
import clientRecSongsImage from '../images/client-rec-songs.png';
import clientTimelineImage from '../images/client-timeline.png';
import clientTimelineAddSectionImage from '../images/client-timeline-add-section.png';
import clientTimelineEditSectionImage from '../images/client-timeline-edit-section.png';
import clientTimelineAddItemImage from '../images/client-timeline-add-item.png';
import clientTimelineOptionsImage from '../images/client-timeline-options.png';
import clientQuestionnaireImage from '../images/client-questionnaire.png';
import clientQuestionnaireOptionsImage from '../images/client-questionnaire-options.png';
import clientMusicSearchLandingImage from '../images/client-music-search-landing.png';
import clientMusicSearchPlaylistImage from '../images/client-music-search-playlist.png';

import clientMusicPreferencesImage from '../images/client-music-preferences.png';
import clientMusicPreferencesGenresImage from '../images/client-music-preferences-genres.png';
import clientMusicPreferencesGenreOptionsImage from '../images/client-music-preferences-genre-options.png';
import clientMusicPreferencesGenreNotesImage from '../images/client-music-preferences-genre-notes.png';

const ClientFeatures = props => {
  const { includeHeader = true } = props;
  return (
    <>
      { includeHeader && (
        <FloatingContainer className="bg-blue-gradient" verticalPadding={60} maxWidth={1000}>
          <FadeDownWhileInView className="fs-30 fw-700 text-center c-white">Client Features</FadeDownWhileInView>
        </FloatingContainer>
      )}
      
      <FloatingContainer className="bg-white" verticalPadding={60} maxWidth={1000}>
        <Row gutter={[0,50]} align="middle" className="text-center">
          <Col xs={24} md={12}>
            <FadeDownWhileInView>
              <div className="app-carousel">
                <Carousel 
                  showThumbs={false}
                  showArrows={false}
                  showStatus={false}
                  infiniteLoop={true}
                >
                  <div className="app-carousel--image">
                    <img src={clientSpecialEventsImage}/>
                  </div>
                  <div className="app-carousel--image">
                    <img src={clientMusicSelectSpecialEvents}/>
                  </div>
                  <div className="app-carousel--image">
                    <img src={clientSpecialEventPlaylistImage}/>
                  </div>
                  <div className="app-carousel--image">
                    <img src={clientSelectPlatformImage}/>
                  </div>
                  <div className="app-carousel--image">
                    <img src={clientSpotifySearchImage}/>
                  </div>
                  <div className="app-carousel--image">
                    <img src={clientAppleMusicSearchImage}/>
                  </div>
                  <div className="app-carousel--image">
                    <img src={clientImportPlaylistImage}/>
                  </div>
                  <div className="app-carousel--image">
                    <img src={clientSpecialEventNotesImage}/>
                  </div>
                </Carousel>
              </div>
            </FadeDownWhileInView>
          </Col>
          <Col xs={24} md={12}>
            <FadeDownWhileInView className="p-20">
              <div className="fs-24 fw-700 line-1-2">Select Music</div>
              <div className="fs-16 fw-500 c-text-gray mt-20 line-1-4">Selecting music for your event could not be easier. Choose which special events you would like to include and start adding music by searching <b>Spotify</b> or <b>Apple Music</b>. Already have a Spotify playlist created? No problem. Simply import your playlist with a few taps. You can also add an optional note to each song to provide more details for your DJ.</div>
            </FadeDownWhileInView>
          </Col>
        </Row>
      </FloatingContainer>

      <FloatingContainer className="bg-gray" verticalPadding={60} maxWidth={1000}>
        <Row gutter={[0,30]} align="middle" className="text-center">
          <Col xs={24} md={12}>
            <FadeDownWhileInView className="p-20">
              <div className="fs-24 fw-700 line-1-2">Browse Recommended Songs</div>
              <div className="fs-16 fw-500 c-text-gray mt-20 line-1-4">If you need help finding the perfect song, the app will give you recommendations based on what other people are selecting for their special events. You will also have the option of browsing popular songs by category or genre from the search screen.</div>
            </FadeDownWhileInView>
          </Col>
          <Col xs={24} md={12}>
            <div className="app-carousel">
              <Carousel 
                showThumbs={false}
                showArrows={false}
                showStatus={false}
                infiniteLoop={true}
              >
                <div className="app-carousel--image">
                  <img src={clientRecSongsImage}/>
                </div>
                <div className="app-carousel--image">
                  <img src={clientMusicSearchLandingImage}/>
                </div>
                <div className="app-carousel--image">
                  <img src={clientMusicSearchPlaylistImage}/>
                </div>
              </Carousel>
            </div>
          </Col>
        </Row>
      </FloatingContainer>

      <FloatingContainer className="bg-white" verticalPadding={60} maxWidth={1000}>
        <Row gutter={[0,30]} align="middle" className="text-center">
          <Col xs={24} md={12}>
            <div className="app-carousel">
              <Carousel 
                showThumbs={false}
                showArrows={false}
                showStatus={false}
                infiniteLoop={true}
              >
                <div className="app-carousel--image">
                  <img src={clientMusicPreferencesImage}/>
                </div>
                <div className="app-carousel--image">
                  <img src={clientMusicPreferencesGenresImage}/>
                </div>
                <div className="app-carousel--image">
                  <img src={clientMusicPreferencesGenreOptionsImage}/>
                </div>
                <div className="app-carousel--image">
                  <img src={clientMusicPreferencesGenreNotesImage}/>
                </div>
              </Carousel>
            </div>
          </Col>
          <Col xs={24} md={12}>
            <FadeDownWhileInView className="p-20">
              <div className="fs-24 fw-700 line-1-2">Choose preferences</div>
              <div className="fs-16 fw-500 c-text-gray mt-20 line-1-4">Along with choosing specific songs, you can also select your favorite genres to let your DJ know what type of music you like. This will give them an idea of what to play at your event. You can also create a "Do not play" list if there are certain songs you want them to avoid.</div>
            </FadeDownWhileInView>
          </Col>
        </Row>
      </FloatingContainer>

      <FloatingContainer className="bg-gray" verticalPadding={60} maxWidth={1000}>
        <Row gutter={[0,50]} align="middle" className="text-center">
          <Col xs={24} md={12}>
            <FadeDownWhileInView className="p-20">
              <div className="fs-24 fw-700 line-1-2">Build a Timeline</div>
              <div className="fs-16 fw-500 c-text-gray mt-20 line-1-4">Simply select a start time and start adding events. As you drag and drop items to reorder them, the starting time for each event will automatically update. Notes can be added to provide clarity, and song selections will populate automatically from the music section. You can also select the share option to send a read-only link to friends, family, or other vendors so they everyone can stay on the same page.</div>
            </FadeDownWhileInView>
          </Col>
         <Col xs={24} md={12}>
            <FadeDownWhileInView>
              <div className="app-carousel">
                <Carousel 
                  showThumbs={false}
                  showArrows={false}
                  showStatus={false}
                  infiniteLoop={true}
                >
                  <div className="app-carousel--image">
                    <img src={clientTimelineImage}/>
                  </div>
                  <div className="app-carousel--image">
                    <img src={clientTimelineAddSectionImage}/>
                  </div>
                  <div className="app-carousel--image">
                    <img src={clientTimelineEditSectionImage}/>
                  </div>
                  <div className="app-carousel--image">
                    <img src={clientTimelineAddItemImage}/>
                  </div>
                  <div className="app-carousel--image">
                    <img src={clientTimelineOptionsImage}/>
                  </div>
                </Carousel>
              </div>
            </FadeDownWhileInView>
          </Col>
        </Row>
      </FloatingContainer>

      <FloatingContainer className="bg-white" verticalPadding={60} maxWidth={1000}>
        <Row gutter={[30,30]} align="middle" className="text-center">
          <Col xs={24} md={12}>
            <FadeDownWhileInView>
              <div className="app-carousel">
                <Carousel 
                  showThumbs={false}
                  showArrows={false}
                  showStatus={false}
                  infiniteLoop={true}
                >
                  <div className="app-carousel--image">
                    <img src={clientQuestionnaireImage}/>
                  </div>
                  <div className="app-carousel--image">
                    <img src={clientQuestionnaireOptionsImage}/>
                  </div>
                </Carousel>
              </div>
            </FadeDownWhileInView>
          </Col>
          <Col xs={24} md={12}>
            <FadeDownWhileInView className="p-20">
              <div className="fs-24 fw-700 line-1-2">Answer Questionnaires</div>
              <div className="fs-16 fw-500 c-text-gray mt-20 line-1-4">Fill out custom questionnaires provided by your DJ. This is the easiest way for your DJ to collect information about your event. Answering questions might even help you realize things that you might have missed otherwise.</div>
            </FadeDownWhileInView>
          </Col>
        </Row>
      </FloatingContainer>
    </>
  );
};

export default ClientFeatures;

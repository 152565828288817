
import React, { useContext } from 'react';
import { Navigate, Outlet, useLocation } from "react-router-dom";
import AppContext from '../../app/context';
import UserTypes from '../../constants/userTypes';
import Roles from '../../constants/roles';
import AdminRestrictedPage from "../../pages/admin/restrictedPage";

const AdminProtectedRoute = ({roles, permissions}) => {

  const { auth } = useContext(AppContext);
  const location = useLocation();

  const exemptPaths = [
    "/admin/trial-ended",
    "/admin/subscribe",
    "/admin/subscribe/confirmation",
    "/admin/past-due",
    "/admin/inactive"
  ]

  // Roles
  var allowedRoles = roles;
  if (typeof roles === "string") {
    allowedRoles = [roles];
  }

  // Permissions
  var allowedPermissions = permissions;
  if (typeof permissions === "string") {
    allowedPermissions = [permissions];
  }

  // Check authentication
  if (!auth.isAuthenticated) {
    if (!exemptPaths.includes(location.pathname)) {
      return <Navigate replace to={`/login?redirect=${location.pathname}`} />
    }
    return <Navigate replace to={`/login`} />
  }
  // Check user type
  if (auth.user.user_type_id !== UserTypes.ADMIN && auth.user.user_type_id !== UserTypes.SYSTEM_ADMIN) {
    return <Navigate replace to='/' />
  }
  // Check roles
  if (allowedRoles && !allowedRoles.includes(auth.user.role)) {
    return <Navigate replace to='/' />
  }
  // Check permissions
  var hasPermission = true
  if (allowedPermissions && auth.user.role !== Roles.OWNER) {
    hasPermission = false
    for (const permission of auth.user.permissions) {
      if (allowedPermissions.includes(permission)) {
        hasPermission = true
      }
    }
  }
  if (!hasPermission) {
    return <AdminRestrictedPage/>
  }
  if (location.pathname == "/admin/payment-method" || location.pathname == "/admin/payment-method/complete") {
    return <Outlet/> 
  }
  if (auth.user.account_status == "TRIAL_ENDED" && 
      location.pathname != "/admin/trial-ended" && 
      location.pathname != "/admin/select-plan" && 
      location.pathname != "/admin/subscribe" && 
      location.pathname != "/admin/subscribe/confirmation") {
    return <Navigate replace to='/admin/trial-ended' />
  }
  if (auth.user.account_status == "PAST_DUE" && location.pathname != "/admin/past-due") {
    return <Navigate replace to='/admin/past-due' />
  }
  if (auth.user.account_status == "CANCELLED" && 
    location.pathname != "/admin/inactive" &&
    location.pathname != "/admin/select-plan" && 
    location.pathname != "/admin/subscribe" && 
    location.pathname != "/admin/subscribe/confirmation") {
    return <Navigate replace to='/admin/inactive' />
  }
  return <Outlet/>
}

export default AdminProtectedRoute;

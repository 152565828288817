import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Grid } from 'antd';
import useApi from '../../../../hooks/useApi';
import useAccountSettings from '../../../../hooks/useAccountSettings';
import { getEventInvoice } from '../../../../api';
import LoadingSpinner from '../../../../components/loading';
import FloatingContainer from '../../../../components/floatingContainer'
import { HiOutlineArrowLeft } from "react-icons/hi";
import InvoicePreview from '../../../../components/invoicePreview';
import InvoiceStatus from '../../../../constants/invoiceStatus';
import { isEmpty } from "lodash";

const PreviewInvoicePage = () => {

  const [isLoading, setLoading] = useState(true);
  const [eventInvoice, setEventInvoice] = useState({});

  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();

  const navigate = useNavigate();
  const params = useParams();
  const [sendRequest] = useApi()
  const [accountSettings] = useAccountSettings()

  const eventId = params.event_id;
  const invoiceId = params.id;

  useEffect(() => {
    window.scrollTo(0, 0);
    refreshPage()
  }, []);

  const refreshPage = async () => {
    try {
      const results = await sendRequest(getEventInvoice(eventId, invoiceId))
      setEventInvoice(results)
    } finally {
      setLoading(false)
    }
  }

  const onPayNow = () => {
    if (eventInvoice.payment_link) {
      window.open(eventInvoice.payment_link, "_blank")
    }
  }

  const renderHeaderButton = () => {
    return (
      <button className="small-primary-button" style={{ }} onClick={() => navigate(`/admin/events/${eventId}/invoices/${invoiceId}/send`)}>Next: Send Invoice</button>
    );
  }

  const renderHeader = () => {
    var backPath = `/admin/events/${eventId}/invoices/${invoiceId}/edit`
    if (eventInvoice.status == InvoiceStatus.PAID) {
      backPath = `/admin/events/${eventId}?tab=documents`
    }

    if (screens.md) {
      return (
        <div className="event-contract--header p-20"> 
          <div className="flex-row flex-middle">
            <div className="fs-20 display-flex mr-10" onClick={() => navigate(backPath)}><HiOutlineArrowLeft/></div>
            <div className="fs-18 fw-700 mr-15 flex-ellipsis">
              Invoice #{ eventInvoice.invoice_number }
            </div>
            <span className="bg-light-blue c-blue pv-5 ph-10 fs-10 fw-600 nowrap">{!isEmpty(eventInvoice.status) ? eventInvoice.status.replace("_", " ") : ""}</span>
            <div className="flex-1"></div>
            <div className="blue-link mh-20" onClick={() => navigate(`/admin/events/${eventId}?tab=documents`)}>Back to event</div>
            <div className="">
              { renderHeaderButton()}
            </div>
          </div>
        </div>
      )
    } else {
      return (
        <div className="event-contract--header p-20">
          <div className="flex-row flex-middle">
            <div className="fs-20 display-flex mr-10" onClick={() => navigate(backPath)}><HiOutlineArrowLeft/></div>
            <div className="fs-18 fw-700 mr-15 flex-ellipsis">
              Invoice #{ eventInvoice.invoice_number }
            </div>
            <span className="bg-light-blue c-blue pv-5 ph-10 fs-10 fw-600">{!isEmpty(eventInvoice.status) ? eventInvoice.status.replace("_", " ") : ""}</span>
            <div className="flex-1"></div>
          </div>
          <div className="text-center mt-15">
            <div className="">
              { renderHeaderButton()}
            </div>
            <div className="blue-link mt-15" onClick={() => navigate(`/admin/events/${eventId}?tab=documents`)}>Back to event</div>
          </div>
        </div>
      )
    }
  }

  const renderContent = () => {
    const paddingClass = screens.md ? "p-30" : "p-20"
    return (
      <>
        <FloatingContainer className={`ph-20 mb-20 bg-gray`} verticalPadding={20} maxWidth={850}>
          <div className="bg-white border radius-8 mb-20 p-20 text-center">
            <div className="fw-700">The preview below is what the client will see.</div>
            <div className="mt-5 c-text-gray">If everything looks correct, you can move on to the next step.</div>
          </div>
          <InvoicePreview invoice={eventInvoice} accountSettings={accountSettings}/>
        </FloatingContainer>
      </>
    )
  }

  if (isLoading) {
    return (
      <div className="event-contract--container">
        <LoadingSpinner/>
      </div>
    )
  }

  return (
    <div className="event-contract--container">
      { renderHeader()}
      <div className="event-contract--body">
        <div className="event-contract--content">
          { renderContent() }
        </div>
      </div>
    </div>
  );
}

export default PreviewInvoicePage;

import React, { useEffect, useState, useRef, useContext } from "react";
import moment from 'moment';
import { cloneDeep, isUndefined, startsWith } from 'lodash';
import { useNavigate, useParams, useLocation } from 'react-router';
import { Input, Select, Checkbox, Radio, DatePicker, Space, Dropdown, Menu } from 'antd';
import { PDFDownloadLink } from '@react-pdf/renderer';
import useApi from '../../hooks/useApi';
import AppContext from '../../app/context';
import AdminContent from '../../components/adminContent';
import LoadingSpinner from '../../components/loading';
import ShareModal from '../../modals/shareModal';
import { MdArrowForwardIos, MdOutlineIosShare, MdOutlineKeyboardArrowDown, MdOutlineInfo } from "react-icons/md";
import { FiCheckCircle, FiRefreshCw, FiEye, FiDownload } from "react-icons/fi";
import { getEventQuestionnaire, updateEventQuestionnaire, getEvent } from '../../api';
import QuestionnairePDF from '../../pdfs/questionnairePDF';
import FloatingContainer from '../../components/floatingContainer'
import EventPermissions from '../../constants/eventPermissions';
import { hasEventPermission } from '../../helpers/permissionHelper';
import { isEventLocked } from '../../helpers/eventHelper';
import { getDatePickerFormat } from '../../helpers/dateHelper';
import useDocumentTitle from '../../hooks/useDocumentTitle';

const QuestionnairePage = () => {

  const [isLoading, setLoading] = useState(true);
  const [sections, setSections] = useState([]);
  const [event, setEvent] = useState({});
  const [eventName, setEventName] = useState("");
  const [eventDate, setEventDate] = useState(moment());
  const [questionnaireName, setQuestionnaireName] = useState("");
  const [isShareModalVisible, setShareModalVisible] = useState(false);
  const [hasUnsavedChanges, setUnsavedChanges] = useState(false);
  const [isSaving, setSaving] = useState(false);
  const [eventLocked, setEventLocked] = useState(false);
  const [canEdit, setCanEdit] = useState(false);
  const [accountSettings, setAccountSettings] = useState({});

  useDocumentTitle("Questionnaire")
  const [sendRequest] = useApi()
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const saveTimerRef = useRef(null);

  const { auth } = useContext(AppContext);

  const eventId = params.event_id;
  const eventQuestionnaireId = params.id;

  useEffect(() => {
    window.scrollTo(0, 0);
    if (eventQuestionnaireId) {
      refreshPage();
    }
  }, []);

  const refreshPage = async () => {
    try {
      const questionnaireResults = await sendRequest(getEventQuestionnaire(eventId, eventQuestionnaireId))
      setQuestionnaireName(questionnaireResults.questionnaire_name)
      setSections(questionnaireResults.sections);
      setAccountSettings(questionnaireResults.account_settings)

      const eventResults = await sendRequest(getEvent(eventId));
      setEvent(eventResults)
      setEventName(eventResults.event_name)
      setEventDate(moment(eventResults.event_date))

      setEventLocked(isEventLocked(eventResults, auth.user))
      setCanEdit(hasEventPermission(auth.user, EventPermissions.EDIT_QUESTIONNAIRES))
    } finally {
      setLoading(false)
    }
  }

  const saveChanges = async (newSections) => {
    if (!canEdit || eventLocked) { return }
    try {
      const body = {
        sections: !isUndefined(newSections) ? newSections : sections,
        questionnaire_name: questionnaireName
      }
      await sendRequest(updateEventQuestionnaire(eventId, eventQuestionnaireId, body))
      setUnsavedChanges(false)
    } finally {
      setSaving(false)
    }
  }

  const onQuestionTextChanged = (value, sectionIndex, questionIndex) => {
    setUnsavedChanges(true)
    setSaving(true)

    // Save local changes
    const newSections = cloneDeep(sections);
    newSections[sectionIndex].questions[questionIndex].answer = value;
    setSections(newSections)

    // Save changes to database
    const questionType = newSections[sectionIndex].questions[questionIndex].type
    if (questionType == "text" || questionType == "textarea") {
      if (saveTimerRef.current) {
        clearTimeout(saveTimerRef.current)
      }
      saveTimerRef.current = setTimeout(() => saveChanges(newSections), 1000)
    } else {
      saveChanges(newSections)
    }
  }

  const onQuestionCheckboxChanged = (value, sectionIndex, questionIndex) => {
    setUnsavedChanges(true)

    // Save local changes
    var newSections = cloneDeep(sections);
    const currentAnswer = newSections[sectionIndex].questions[questionIndex].answer
    var answerArray = currentAnswer.split("::");
    answerArray = answerArray.filter(x => x != "");
    const answerFound = answerArray.filter(x => x == value).length > 0;
    if (answerFound) {
      answerArray = answerArray.filter(x => x != value)
    } else {
      answerArray.push(value)
    }
    const answerString = answerArray.join("::");
    newSections[sectionIndex].questions[questionIndex].answer = answerString;
    setSections(newSections)

    // Save changes to database
    setSaving(true)
    saveChanges(newSections)
  }

  const onQuestionDateChanged = (date, dateString, sectionIndex, questionIndex) => {
    setUnsavedChanges(true)

    // Save local changes
    const newSections = cloneDeep(sections);
    var newAnswer = "";
    if (date) {
      newAnswer = moment(date).format("YYYY-MM-DD");
    }
    newSections[sectionIndex].questions[questionIndex].answer = newAnswer;
    setSections(newSections)

    // Save changes to database
    setSaving(true)
    saveChanges(newSections)
  }

  const getQuestionValue = (sectionIndex, questionIndex) => {
    if (sections && sections.length > 0) {
      return sections[sectionIndex].questions[questionIndex].answer
    }
    return null;
  }

  const getDateValue = (sectionIndex, questionIndex) => {
    if (sections && sections.length > 0) {
      const date = sections[sectionIndex].questions[questionIndex].answer
      if (date != "" && moment(date, "YYYY-MM-DD", true).isValid()) {
        return moment(date, "YYYY-MM-DD");
      }
    }
    return null;
  }

  const getCheckboxValue = (value, sectionIndex, questionIndex) => {
    var newSections = cloneDeep(sections);
    if (newSections && newSections.length > 0) {
      const currentAnswer = newSections[sectionIndex].questions[questionIndex].answer
      var answerArray = currentAnswer.split("::");
      answerArray = answerArray.filter(x => x != "");
      return answerArray.filter(x => x == value).length > 0
    }
    return false;
  }

  const actionMenu = () => {
    return (
      <Menu>
        <Menu.Item>
          <div onClick={() => navigate(`/questionnaire/${eventQuestionnaireId}`)}>
            <FiEye style={{ marginRight: 8}}/> Preview
          </div>
        </Menu.Item>
        <Menu.Item>
          <div onClick={() => setShareModalVisible(true)}>
            <MdOutlineIosShare style={{ marginRight: 8}}/> Share
          </div>
        </Menu.Item>
        <Menu.Item>
          <PDFDownloadLink document={ renderPDF() } fileName={`questionnaire-${moment(event.event_date).format("YYYYMMDD")}.pdf`}>
            <div>
              <FiDownload style={{ marginRight: 8}}/> Download PDF
            </div>
          </PDFDownloadLink>
        </Menu.Item>
      </Menu>
    )
  };

  const renderPDF = () => {
    return <QuestionnairePDF questionnaireName={questionnaireName} sections={sections} eventName={eventName} eventDate={event.event_date} accountSettings={accountSettings}/>
  }

  const renderSubHeader = () => {
    if (eventLocked || !canEdit) {
      return null
    }
    if (isSaving) {
      return (
        <div className="mt-5 flex-row flex-middle c-text-gray">
          <FiRefreshCw style={{ marginRight: 5}}/> Saving changes...
        </div>
      )
    } else {
      return (
        <div className="mt-5 flex-row flex-middle c-text-gray">
          <FiCheckCircle style={{ marginRight: 5}}/> All changes saved!
        </div>
      )
    }
  }

  const renderReadOnlyAnswer = (question, index) => {
    if (question.type == "checkbox") {
      var answerArray = question.answer.split("::")
      answerArray = answerArray.filter(x => x != "");
      return (
        <div key={index}>
          { answerArray.map((x,i) => (
            <div key={i} className="fw-500">{x}</div>
          ))}
          { answerArray.length == 0 && (
            <div className="fw-500">--</div>
          )}
        </div>
      )
    } else {
      var answer = ""
      if (!question.answer) {
        answer = "--"
      } else {
        answer = question.answer
      }  
      return (
        <div key={index}>
          <div className="fw-500 line-breaks">{answer}</div>
        </div>
      )
    }
  }

  const renderInput = (question, questionIndex, sectionIndex) => {
    if (!canEdit || eventLocked) {
      return renderReadOnlyAnswer(question, questionIndex)
    }
    if (question.type == "text") {
      return <Input size="large" value={getQuestionValue(sectionIndex, questionIndex)} onChange={(e) => onQuestionTextChanged(e.target.value, sectionIndex, questionIndex)}/>;
    } else if (question.type == "textarea") {
      return <Input.TextArea rows={3} size="large" value={getQuestionValue(sectionIndex, questionIndex)} onChange={(e) => onQuestionTextChanged(e.target.value, sectionIndex, questionIndex)}/>;
    } else if (question.type == "select") {
      return (
        <Select
          style={{ width: '100%'}}
          placeholder={"Select"}
          optionFilterProp="children"
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          size='large'
          value={getQuestionValue(sectionIndex, questionIndex)} 
          onChange={(e) => onQuestionTextChanged(e, sectionIndex, questionIndex)}
        >
          {question.options.map((option, index) => (
            <Select.Option value={option} key={index}>{option}</Select.Option>
          ))}
        </Select>
      )
    } else if (question.type == "checkbox") {
      return question.options.map((option, index) => (
        <div className="mt-5" key={index}>
          <Checkbox value={option} checked={getCheckboxValue(option, sectionIndex, questionIndex)} onChange={(e) => onQuestionCheckboxChanged(e.target.value, sectionIndex, questionIndex)}>{option}</Checkbox>
        </div>
      ))
    } else if (question.type == "radio") {
      return (
        <Radio.Group value={getQuestionValue(sectionIndex, questionIndex)} onChange={(e) => onQuestionTextChanged(e.target.value, sectionIndex, questionIndex)}>
          <Space direction="vertical">
            {question.options.map((option, index) => (
              <Radio key={index} value={option}>{option}</Radio>
            ))}
          </Space>
        </Radio.Group>
      );
    } else if (question.type == "date") {
      return <DatePicker size="large" format={getDatePickerFormat(accountSettings)} value={getDateValue(sectionIndex, questionIndex)} onChange={(date, dateString) => onQuestionDateChanged(date, dateString, sectionIndex, questionIndex)}/>
    }
    return null;    
  }

  const renderQuestion = (question, index, sectionIndex) => {
    return (
      <div key={index} className="mb-20">
        <div className="mb-5">
          <div className="fs-14 fw-700">{ question.label }</div>
          { question.description && (
            <div className="fs-12 line-breaks bg-gray p-10 radius-8 mt-5 mb-10">{ question.description }</div>
          )}
        </div>
        { renderInput(question, index, sectionIndex) }
      </div>
    )
  }

  const renderSection = (section, index) => {
    return (
      <div key={index} className="shadow-card mb-20">
        <div className="mh-20 pt-15 pb-10 b-border">
          <div className="fs-20 fw-700">{ section.name }</div>
          { section.description && (
            <div className="c-text-gray line-breaks">{ section.description }</div>
          )}
        </div>
        <div className="p-20">
          {section.questions.map((x,i) => renderQuestion(x,i,index))}
        </div>
      </div>
    )
  }

  const renderHeader = () => {
    const isAdmin = startsWith(location.pathname, "/admin/")
    return (
      <div className="p-20">
        { isAdmin ? (
          <div>
            <span className="c-blue fw-700 cursor-default" onClick={() => navigate(`/admin/events`)}>
              Events
            </span>
            <span className="fs-10 mh-5"><MdArrowForwardIos/></span>
            <span className="c-blue fw-700 cursor-default" onClick={() => navigate(`/admin/events/${eventId}?tab=planning&section=questionnaires`)}>
              Event Details
            </span>
            <span className="fs-10 mh-5"><MdArrowForwardIos/></span>
            <span className="cursor-default c-text-gray">
              Questionnaire
            </span>
          </div>
        ) : (
          <div>
            <span className="c-blue fw-700 cursor-default" onClick={() => navigate(-1)}>
              Questionnaires
            </span>
            <span className="fs-10 mh-5"><MdArrowForwardIos/></span>
            <span className="cursor-default c-text-gray">
              Details
            </span>
          </div>
        )}
        
        <div className="flex-row flex-middle">
          <div className="fw-700 fs-24 mt-5 flex-1">{questionnaireName}</div>
        </div>
        {renderSubHeader() }
      </div>
    )
  }

  const renderContent = () => {
    if (isLoading) {
      return <LoadingSpinner/>
    }
    return (
      <>
        <div className="toolbar-container">
          <Dropdown overlay={actionMenu()} placement="bottomRight" trigger="click">
            <div className="toolbar-button">
              Actions <MdOutlineKeyboardArrowDown style={{ fontSize: 20, color: '#536DFE', marginLeft: 8}}/>
            </div>
          </Dropdown>
        </div>
        <FloatingContainer className="ph-20" verticalPadding={20} maxWidth={800}>
          { eventLocked && canEdit && (
            <div className="message-box mb-15">
              <div className="message-icon"><MdOutlineInfo/></div>
              <div className="message-text">Changes are no longer allowed</div>
            </div>
          )}
          { sections.map((section, i) => renderSection(section, i))}
          { renderShareModal() }
        </FloatingContainer>
      </>
    )
  }

  const renderShareModal = () => {
    return (
      <ShareModal
        isVisible={isShareModalVisible}
        setVisible={setShareModalVisible}
        title="Share Questionnaire"
        link={`/questionnaire/${eventQuestionnaireId}`}
      />
    )
  }

  return (
    <AdminContent header={renderHeader()} body={renderContent()}/>
  );
}

export default QuestionnairePage;

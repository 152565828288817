import Resizer from "react-image-file-resizer";
import { isNull } from "lodash";

const resizeFile = (file, maxWidth = 1000, maxHeight = 1000) => new Promise((resolve) => {
  Resizer.imageFileResizer(
    file,
    maxWidth,
    maxHeight,
    getFileType(file),
    50,
    0,
    (uri) => {
      resolve(uri);
    },
    "base64"
  );
});

const getFileType = (file) => {
  var fileType = null
  if (file.type === 'image/jpeg' || file.type === 'image/heic') {
    fileType = "JPEG"
  } else if (file.type === 'image/png') {
    fileType = "PNG"
  }
  return fileType
}

const isValidFileType = (file) => {
  const fileType = getFileType(file)
  return !isNull(fileType)
}

const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
}

export {
  resizeFile,
  getFileType,
  getBase64,
  isValidFileType
}
import axios from 'axios';
import { getDeviceId } from '../helpers/deviceHelper';
import StorageKeys from '../constants/storageKeys';
const API_URL = process.env.REACT_APP_API_URL;

export function post(url, data) {
  return axios.post(`${API_URL}${url}`, data, getOptions())
}

export function get(url) {
  return axios.get(`${API_URL}${url}`, getOptions())
}

export function put(url, data) {
  return axios
    .put(`${API_URL}${url}`, data, getOptions())
    .then(handleResponse)
    .catch(handleError);
}

export function del(url) {
  return axios
    .delete(`${API_URL}${url}`, getOptions())
    .then(handleResponse)
    .catch(handleError);
}

export function postImage(url, data) {
  return axios.post(`${API_URL}${url}`, data, getImageOptions())
}

function handleResponse(response) {
  return response.data;
}

function handleError(error) {
  console.log(error);
  return error;
}

function getOptions() {
  var options = {
    headers: {
      'source': "web",
      'deviceid': getDeviceId()
    }
  }
  const auth = JSON.parse(localStorage.getItem(StorageKeys.AUTH))
  if (auth && auth.access_token) {
    options.headers['Authorization'] = 'Bearer ' + auth.access_token;
  }
  return options;
}

function getImageOptions() {
  var options = {
    headers: {
      'source': "web",
      'deviceid': getDeviceId()
    }
  }
  const auth = JSON.parse(localStorage.getItem(StorageKeys.AUTH))
  if (auth && auth.access_token) {
    options.headers['Authorization'] = 'Bearer ' + auth.access_token;
    options.headers['Content-Type'] = 'multipart/form-data';
  }
  return options;
}
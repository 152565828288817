import React, { useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import AdminContent from '../../../components/adminContent';
import FloatingContainer from '../../../components/floatingContainer'
import { BackToHomeLink, DocLink, DocArrowLink } from '../../../components/docComponents'
import { Image } from 'antd';
import addClientModalImage from '../../../images/add-client-modal.png';
import useDocumentTitle from '../../../hooks/useDocumentTitle';

const DocsInvitingClients = () => {

  useDocumentTitle("Inviting Clients | Documentation")
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const scrollWithOffset = (el) => {
    const offset = 15;
    const bodyRect = document.getElementsByClassName("admin-content--body-main")[0].getBoundingClientRect().top;
    const bodyScrollTop = document.getElementsByClassName("admin-content--body-main")[0].scrollTop
    const elementRect = el.getBoundingClientRect().top;
    const elementPosition = elementRect - bodyRect;
    const offsetPosition = elementPosition - offset + bodyScrollTop;
    document.getElementsByClassName("admin-content--body-main")[0].scrollTo({top: offsetPosition, behavior: 'smooth'})
  }

  const renderHashLink = (id, text) => {
    return (
      <HashLink to={`#${id}`} scroll={scrollWithOffset} style={{ display: "inline-block"}}>
        <div className="blue-link">{text}</div>
      </HashLink>
    )
  }

  const renderContent = () => {
    return (
      <FloatingContainer className="ph-20 bg-white" verticalPadding={20} maxWidth={1000}>
        <BackToHomeLink/>
        <div className="fw-700 fs-24 mt-20">Inviting Clients</div>
        <div className="fw-500 fs-16 c-text-gray">How to send an invitation to your clients so they can plan their event.</div>
        <div className="fw-500 mb-15 line-1-4 mt-15 bg-gray p-15 radius-8">
          Clients can only access the website and mobile app if you send them an invitation. Once they accept the invite, they will have access to the client portal and receive information on how to download the app.
        </div>
        <ul>
          <li>{ renderHashLink("sending-invitation", "Sending an Invitation")}</li>
          <li>{ renderHashLink("resending-invitation", "Re-Sending an Invitation")}</li>
          <li>{ renderHashLink("checking-invitation-status", "Checking Invitation Status")}</li>
          <li>{ renderHashLink("troubleshooting", "Troubleshooting")}</li>
        </ul>
        <div className="b-border mv-20"></div>
        <div className="fw-700 fs-18" id="sending-invitation">Sending an Invitation</div>
        <div className="fw-700 mt-15">
          When creating an event
        </div>
        <ol className="mt-15">
          <li className="mb-15">
            Go to the <DocLink to="/admin/events">Events</DocLink> page and select <b>New Event</b>.
          </li>
          <li className="mb-15">
           While creating the event, click the <b>Add Client</b> button under the Client section.
          </li>
          <li className="mb-15">
           Enter the client's details and check the <b>Send invitation when event is created</b> checkbox.
            <div className="bg-gray pv-10 ph-15 mt-10 radius-8">
              Note: If you plan on creating a contract before allowing your client to access the planning center, leave this checkbox unchecked and send an invitation later on the event details page after your event is officially booked.
            </div>
           <div className="mt-15">
              <Image src={addClientModalImage} style={{ width: '100%', maxWidth: 400 }}/>
            </div>
          </li>
          <li className="mb-15">
            Select the <b>Save</b> button to create the event.
          </li>
          <li className="mb-15">
            An email will be sent to the client with an link to accept the invitation and create a password.
          </li>
        </ol>
        <div className="fw-700 mt-15">
          After an event is created
        </div>
        <ol className="mt-15">
          <li className="mb-15">
            Go to the <DocLink to="/admin/events">Events</DocLink> page and select an event.
          </li>
          <li className="mb-15">
           On the <b>Overview</b> tab of the event details page, scroll down to the <b>Clients</b> section
          </li>
          <li className="mb-15">
            Select from one of the following options below the client's details:
            <div className="bg-gray pv-10 ph-15 mt-10 radius-8">
              Note: These buttons will only be displayed if an invitation has never been sent to the client.
            </div>
            <ul className="mt-15">
              <li className="mb-15">
                Select the <b>Send Invitation</b> button. This will automatically send an email to the client with a link to accept the invitation and create a password.
              </li>
              <li className="mb-15">
                Select the <b>Share Invitation Link</b> button. This will generate a link that you can copy and share with your client in your own text message or email.
              </li>
            </ul>
          </li>
        </ol>
        <div className="b-border mv-20"></div>
        <div className="fw-700 fs-18" id="resending-invitation">Re-Sending an Invitation</div>
        <ol className="mt-15">
          <li className="mb-15">
            Go to the <DocLink to="/admin/events">Events</DocLink> page and select an event.
          </li>
          <li className="mb-15">
            On the <b>Overview</b> tab of the event details page, scroll down to the <b>Clients</b> section
          </li>
          <li className="mb-15">
            Select from one of the following options below the client's details:
            <div className="bg-gray pv-10 ph-15 mt-10 radius-8">
              Note: These buttons will only be displayed if an invitation has never been sent to the client. If you have questions regarding the display of these buttons, check out the <b>Troubleshooting</b> section below.
            </div>
            <ul className="mt-15">
              <li className="mb-15">
                Select the <b>Re-Send Invitation</b> button. This will automatically send another email to the client with a link to accept the invitation and create a password.
              </li>
              <li className="mb-15">
                Select the <b>Share Invitation Link</b> button. This will generate a link that you can copy and share with your client in your own text message or email.
              </li>
            </ul>
          </li>
        </ol>
        <div className="b-border mv-20"></div>
        <div className="fw-700 fs-18" id="checking-invitation-status">Checking Invitation Status</div>
        <ol className="mt-15">
          <li className="mb-15">
            Go to the <DocLink to="/admin/events">Events</DocLink> page and select an event.
          </li>
          <li className="mb-15">
            On the <b>Overview</b> tab of the event details page, scroll down to the <b>Clients</b> section
          </li>
          <li className="mb-15">
            The gray box below each client will display one of the following for <b>Invitation Status</b>:
            <ul className="mt-10">
              <li className="mt-10"><b>Not Sent.</b> <br/> An invitation has not been sent to the client, therefore a "Send Invitation" button should display below this section.</li>
              <li className="mt-10"><b>Pending.</b> <br/>An invitation has been sent to the client, however they have not accepted it yet. A "Re-Send Invitation" button should display below this section if you need to send the link again.</li>
              <li className="mt-10"><b>Accepted.</b> <br/>The invitation has been accepted and the client has setup a password. They now have access to the planning center based on the client permissions you have configured for them.</li>
            </ul>
          </li>
          <li className="mb-15">
            Other information about client activity is displayed in the gray section as well:
            <ul className="mt-10">
              <li className="mt-10"><b>Downloaded the app?</b> <br/>Whether or not the client has downloaded the app and logged in.</li>
              <li className="mt-10"><b>Last Login.</b> <br/>The last time the client logged in to either the app or the website.</li>
            </ul>
          </li>
        </ol>
        <div className="b-border mv-20"></div>
        <div className="fw-700 fs-18" id="troubleshooting">Troubleshooting</div>
        <ul className="mt-15">
          <li className="mb-15">
            What if my client says they don't have an invitation and the "Re-Send Invitation" button is not available?
            <ul className="mt-10">
              <li className="mt-10">Check the <b>Invitation Status</b> below the client details on the event. If the status is "Accepted", that means the client has accepted the invitation and setup a password, whether they remember it or not. If that is the case and the client cannot remember their password, they will need to click on the <b>Forgot Password</b> link on the Login page of the app or website and follow the instructions to reset their password.</li>
              <li className="mt-10">If you are still having issues with your client's access, please reach out to us via the <DocLink to="/admin/events">Support</DocLink> page, and we'll be happy to help.</li>
            </ul>
          </li>
        </ul>
        <div className="b-border mv-50"></div>
        <div className="fw-700 fs-18">What will my clients see?</div>
        <DocArrowLink to="/admin/docs/what-clients-see"/>
        <div className="b-border mv-50"></div>
      </FloatingContainer>
    )
  }
  return (
    <AdminContent body={renderContent()}/>
  );
}

export default DocsInvitingClients;

import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import useApi from '../../../hooks/useApi';
import { notification } from 'antd';
import { 
  spotifyLogout,
  getSpotifyUserProfile
} from '../../../api';
import LoadingSpinner from '../../../components/loading';
import AdminContent from '../../../components/adminContent';
import FloatingContainer from '../../../components/floatingContainer'
import { MdArrowForwardIos } from "react-icons/md";
import useDocumentTitle from '../../../hooks/useDocumentTitle';
import { isArray } from "lodash";
import spotifyLogo from '../../../images/spotify-logo-bw.png';
import AppContext from '../../../app/context';
import useBetaFeatures from '../../../hooks/useBetaFeatures';
import FeatureFlags from '../../../constants/featureFlags';
import BetaFeatures from '../../../constants/betaFeatures';

const API_URL = process.env.REACT_APP_API_URL;

const MusicIntegrationPage = () => {

  const [isLoading, setLoading] = useState(true);
  const [isSpotifyAuth, setSpotifyAuth] = useState(false);
  const [spotifyProfile, setSpotifyProfile] = useState({});
  const [isSpotifyExportEnabled, setSpotifyExportEnabled] = useState(true);

  useDocumentTitle("Music Integration")
  const { auth, _ } = useContext(AppContext);
  const navigate = useNavigate();
  const [hasBetaFeature] = useBetaFeatures()

  const [sendRequest] = useApi()

  useEffect(() => {
    window.scrollTo(0, 0);
    refreshPage();
  }, []);

  const refreshPage = async () => {
    try {
      const spotifyExportbetaResults = await hasBetaFeature(BetaFeatures.SPOTIFY_EXPORT)
      const spotifyEnabled = FeatureFlags.SPOTIFY_EXPORT || spotifyExportbetaResults
      setSpotifyExportEnabled(spotifyEnabled)
      if (!spotifyEnabled) {
        setLoading(false)
        return
      }

      const profileResults = await sendRequest(getSpotifyUserProfile())
      if (profileResults.status == "logged-out") {
        setSpotifyAuth(false)
      } else {
        setSpotifyAuth(true)
        setSpotifyProfile(profileResults)
      }

      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  const spotifyLogin = async () => {
    window.open(`${API_URL}/spotify/login`)
  }

  const onSpotifyLogout = async () => {
    try {
      await sendRequest(spotifyLogout())
      await refreshPage()
      notification.success({
        message: 'Success!',
        description: 'You have been successfully logged out of your Spotify account.',
        duration: 3
      });
    } catch {

    }
  }

  const renderHeader = () => {
    return (
      <div className="p-20">
        <div>
          <span className="c-blue fw-700 cursor-default" onClick={() => navigate("/admin/setup")}>
            Setup
          </span>
          <span className="fs-10 mh-5"><MdArrowForwardIos/></span>
          <span className="cursor-default c-text-gray">
            Music Integration
          </span>
        </div>
        <div className="fw-700 fs-24 mt-5">Music Integration</div>
      </div>
    )
  }

  const renderLoginSection = () => {
    return (
      <div className="shadow-card ph-20 pv-50">
        <div className="text-center">
          <img src={spotifyLogo} style={{ height: 50}}/>
          <div className="mt-10">Connect your Spotify account to start exporting playlists and more!</div>
          { isSpotifyExportEnabled ? (
            <div className="mt-30">
              <button className="small-primary-button" style={{width: 250}} onClick={() => spotifyLogin()}>Login with Spotify</button>
            </div>
          ) : (
            <div className="bg-gray p-20 mt-15">
              We recently launched this feature, however an issue was uncovered that requires us to disable it temporarily. We apologize for this inconvience, but please know we are working hard to resolve the issue.
            </div>
          )}
        </div>
      </div>
    )
  }

  const renderProfileSection = () => {
    var image = null
    if (isArray(spotifyProfile.images)) {
      if (spotifyProfile.images.length == 1) {
        image = spotifyProfile.images[0].url
      } else if (spotifyProfile.images.length > 1) {
        image = spotifyProfile.images[1].url
      }
    }
    return (
      <div className="shadow-card ph-20 pv-50">
        <div className="text-center">
          <img src={spotifyLogo} style={{ height: 50}}/>
          <div className="mt-10">You are connected!</div>
          <div className="mt-20">
            <img src={image} style={{ height: 200 }}/>
          </div>
          <div className="fw-700 fs-20 mt-20">{spotifyProfile.display_name}</div>
          <div className="c-text-gray">{spotifyProfile.email}</div>
          <div className="mt-30">
            <button className="small-primary-button" style={{width: 250}} onClick={() => onSpotifyLogout()}>Logout</button>
          </div>
        </div>
      </div>
    )
  }

  const renderContent = () => {
    return (
      <>
        <FloatingContainer className="ph-20 mb-80" verticalPadding={20} maxWidth={800}>
          { !isSpotifyAuth && renderLoginSection() }
          { isSpotifyAuth && renderProfileSection() }
        </FloatingContainer>
      </>
    )
  }

  if (isLoading) {
    return <LoadingSpinner/>
  }

  return (
    <AdminContent header={renderHeader()} body={renderContent()}/>
  );
}

export default MusicIntegrationPage;
import React, { useEffect, useState } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { Row, Col, Grid } from 'antd';
import qs from "qs";
import useDocumentTitle from '../../../hooks/useDocumentTitle';
import { getInvoicePreviewV2 } from '../../../api';
import LoadingSpinner from '../../../components/loading';
import FloatingContainer from '../../../components/floatingContainer'
import StatusTag from '../../../components/statusTag'
import { HiOutlineArrowLeft } from "react-icons/hi";
import { FiDownload } from "react-icons/fi";
import InvoicePreviewV2 from '../../../components/invoicePreviewV2';
import InvoicePaymentSchedules from '../../../components/invoicePaymentSchedules';
import { get } from "lodash";
import { formatDateMedium } from '../../../helpers/dateHelper';

const BASE_URL = process.env.REACT_APP_BASE_URL;

const PreviewInvoicePage = () => {

  const [isLoading, setLoading] = useState(true);
  const [eventInvoice, setEventInvoice] = useState({});
  const [eventId, setEventId] = useState("");
  const [paymentSchedulePayments, setPaymentSchedulePayments] = useState([]);
  const [fromPage, setFromPage] = useState("");

  useDocumentTitle("Preview Invoice")
  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();

  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();

  const invoiceId = params.id;

  useEffect(() => {
    window.scrollTo(0, 0);
    refreshPage()
    const queryStrings = qs.parse(location.search, { ignoreQueryPrefix: true })
    if (queryStrings.from) {
      setFromPage(queryStrings.from)
    }
  }, []);

  const refreshPage = async () => {
    try {
      const results = await getInvoicePreviewV2(invoiceId)
      if (results.data) {
        setEventInvoice(results.data)
      }
      const invoice = get(results, "data", {})
      const payments = get(results, "data.payment_schedules", [])
      setEventInvoice(invoice)
      setPaymentSchedulePayments(payments)
      setEventId(results.data.event_id)
    } finally {
      setLoading(false)
    }
  }

  const navigateToPDF = () => {
    window.open(`${BASE_URL}/pdfs/v2/invoice/${invoiceId}`, "_blank")
  }

  const renderHeaderButton = () => {
    var path = `/admin/invoices/${invoiceId}/send`
    if (fromPage == "invoices") {
      path = `/admin/invoices/${invoiceId}/send?from=invoices-preview`
    } else if (fromPage == "invoice-details") {
      path = `/admin/invoices/${invoiceId}/send?from=invoice-details-preview`
    } else if (fromPage == "invoice-details-edit") {
      path = `/admin/invoices/${invoiceId}/send?from=invoice-details-edit-preview`
    }
    return (
      <button className="small-primary-button" onClick={() => navigate(path)}>Next: Send Invoice</button>
    );
  }

  const renderBackLink = (className) => {
    if (fromPage == "invoices" || fromPage == "invoice-details" || fromPage == "invoices-edit" || fromPage == "invoice-details-edit") {
      return (
        <div className={`blue-link ${className}`} onClick={() => navigate(`/admin/events/${eventId}?tab=documents`)}>Go to event</div>
      )
    } else {
      return (
        <div className={`blue-link ${className}`} onClick={() => navigate(`/admin/events/${eventId}?tab=documents`)}>Back to event</div>
      )
    }
  }

  const renderHeader = () => {
    var backPath = `/admin/invoices/${invoiceId}/edit`
    if (fromPage == "invoices") {
      backPath = `/admin/invoices`
    } else if (fromPage == "invoices-edit") {
      backPath = `/admin/invoices/${invoiceId}/edit?from=invoices`
    } else if (fromPage == "invoice-details-edit") {
      backPath = `/admin/invoices/${invoiceId}/edit?from=invoice-details`
    } else if (fromPage == "invoice-details") {
      backPath = `/admin/invoices/${invoiceId}`
    }

    if (screens.md) {
      return (
        <div className="event-contract--header p-20"> 
          <div className="flex-row flex-middle">
            <div className="fs-20 display-flex mr-10" onClick={() => navigate(backPath)}><HiOutlineArrowLeft/></div>
            <div className="fs-18 fw-700 mr-15 flex-ellipsis">
              Invoice #{ eventInvoice.invoice_number }
            </div>
            <StatusTag status={eventInvoice.status}/>
            <div className="flex-1"></div>
            { renderBackLink("mh-20") }
            <div className="">
              { renderHeaderButton()}
            </div>
          </div>
        </div>
      )
    } else {
      return (
        <div className="event-contract--header p-20">
          <div className="flex-row flex-middle">
            <div className="fs-20 display-flex mr-10" onClick={() => navigate(backPath)}><HiOutlineArrowLeft/></div>
            <div className="fs-18 fw-700 mr-15 flex-ellipsis">
              Invoice #{ eventInvoice.invoice_number }
            </div>
            <StatusTag status={eventInvoice.status}/>
            <div className="flex-1"></div>
          </div>
          <div className="text-center mt-15">
            <div className="">
              { renderHeaderButton()}
            </div>
            { renderBackLink("mt-15") }
          </div>
        </div>
      )
    }
  }

  const renderContent = () => {
    const paddingClass = screens.md ? "p-30" : "p-20"
    const billToUser = eventInvoice.bill_to_user ? eventInvoice.bill_to_user : {}
    return (
      <>
        <FloatingContainer className={`ph-20 mb-20 bg-gray`} verticalPadding={20} maxWidth={850}>
          <div className="shadow-card-square mb-20 p-20 text-center">
            <div className="fw-700">PREVIEW INVOICE</div>
            <div className="mt-5 c-text-gray bg-gray p-15">The preview below is what the client will see. <br/>If everything looks correct, you can move on to the next step.</div>
          </div>
          <div className={`shadow-card-square ${paddingClass} mb-20`}>
            <Row align="middle">
              <Col flex={1}>
                <div className="fw-600 fs-20">Invoice #{eventInvoice.invoice_number}<StatusTag size="medium" className="ml-10" status={eventInvoice.status}/></div>
              </Col>
              <Col flex={0}>
                <div className="display-flex cursor-pointer p-5" onClick={() => navigateToPDF()}>
                  <FiDownload style={{ fontSize: 20, color: '#536DFE' }}/>
                </div>
              </Col>
            </Row>
            <Row className="mv-30" gutter={[15,15]}>
              <Col xs={24} sm={6}>
                <div className="c-text-gray fs-12 fw-600">FROM</div>
                <div className="">{eventInvoice.company_name}</div>
              </Col>
              <Col xs={24} sm={6}>
                <div className="c-text-gray fs-12 fw-600">TO</div>
                <div className="">{billToUser.first_name} {billToUser.last_name}</div>
              </Col>
              <Col xs={24} sm={6}>
                <div className="c-text-gray fs-12 fw-600">ISSUE DATE</div>
                <div className="">{formatDateMedium(eventInvoice.create_datetime, eventInvoice.account_settings)}</div>
              </Col>
              <Col xs={24} sm={6}>
                <div className="c-text-gray fs-12 fw-600">DUE DATE</div>
                <div className="">{formatDateMedium(eventInvoice.due_date, eventInvoice.account_settings, true)}</div>
              </Col>
            </Row>
            <button className="primary-button" style={{ borderRadius: 0}} onClick={() => {}}>Pay Now</button>
          </div>
          <InvoicePreviewV2 
            invoice={eventInvoice}
          />
          { eventInvoice.payment_schedule && (
            <InvoicePaymentSchedules 
              payments={paymentSchedulePayments}
              invoice={eventInvoice}
            />
          )}
        </FloatingContainer>
      </>
    )
  }

  if (isLoading) {
    return (
      <div className="event-contract--container">
        <LoadingSpinner/>
      </div>
    )
  }

  return (
    <div className="event-contract--container">
      { renderHeader()}
      <div className="event-contract--body">
        <div className="event-contract--content">
          { renderContent() }
        </div>
      </div>
    </div>
  );
}

export default PreviewInvoicePage;

import { isArray, cloneDeep, isEmpty, isUndefined, isNull, isNumber } from "lodash";
import SignerTypes from '../constants/signerTypes';
import StorageKeys from '../constants/storageKeys';

export const getClientSigners = (s) => {
  const signers = cloneDeep(s)
  if (isArray(signers)) {
    return signers.filter(x => x.signer_type == SignerTypes.CLIENT || x.signer_type == SignerTypes.EXTERNAL);
  }
  return []
}

export const getAdminSigners = (s) => {
  const signers = cloneDeep(s)
  if (isArray(signers)) {
    return signers.filter(x => x.signer_type == SignerTypes.ADMIN);
  }
  return []
}

export const getOtherAdminSigners = (s,id) => {
  const signers = cloneDeep(s)
  if (isArray(signers)) {
    return signers.filter(x => x.signer_type == SignerTypes.ADMIN && x.user_id != id);
  }
  return []
}

export const formatCurrencyString = (amount, code) => {
  var finalAmount = isUndefined(amount) || isNull(amount) ? 0 : amount
  var currencyCode = isUndefined(code) ? "USD" : code

  try {
    const countryCode = currencyCode.substring(0,2)
    var locale = `en-${countryCode}`

    const formatter = new Intl.NumberFormat(locale, {
      style: 'currency',
      currency: currencyCode,
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    });
  
    return formatter.format(finalAmount)
  } catch {
    return finalAmount
  }
}

export const formatCurrencyNotFixed = (amount, code) => {
  var finalAmount = isUndefined(amount) || isNull(amount) ? "0.00" : amount
  var currencyCode = isUndefined(code) ? "USD" : code

  try {
    var hasFraction = !String(finalAmount).includes(".00")
    const countryCode = currencyCode.substring(0,2)
    var locale = `en-${countryCode}`

    const formatter = new Intl.NumberFormat(locale, {
      style: 'currency',
      currency: currencyCode,
      minimumFractionDigits: hasFraction ? 2 : 0,
      maximumFractionDigits: 2
    });
  
    return formatter.format(finalAmount)
  } catch (error) {
    return finalAmount
  }
}

export const getCurrencySymbol = (code) => {
  var currencyCode = isUndefined(code) ? "USD" : code

  try {
    const countryCode = currencyCode.substring(0,2)
    var locale = `en-${countryCode}`

    const formatter = new Intl.NumberFormat(locale, {
      style: 'currency',
      currency: currencyCode
    });

    const parts = formatter.formatToParts(1)
    const currencyPart = parts.find(x => x.type == "currency")
    const symbol = !isEmpty(currencyPart) ? currencyPart.value : "$"
  
    return symbol
  } catch {
    return "$"
  }
}

export const getPackageTotal = (eventPackages) => {
  return eventPackages.reduce((x,y) => {
    return x + Number(y.price);
  }, 0)
}

export const getAddOnsTotal = (eventAddOns) => {
  return eventAddOns.reduce((x,y) => {
    const individualAddOnTotal = Number(y.quantity) * Number(y.price)
    return x + individualAddOnTotal;
  }, 0)
}

export const getSubTotal = (eventPackages, eventAddOns) => {
  const packageTotal = getPackageTotal(eventPackages)
  const addOnTotal = getAddOnsTotal(eventAddOns)
  return packageTotal + addOnTotal
}

export const getTotal = (eventPackages, eventAddOns, financials) => {
  const subTotal = getSubTotal(eventPackages, eventAddOns)
  const discount = getDiscountTotal(financials, eventPackages, eventAddOns)
  return subTotal - discount;
}

export const getAddOnSubTotal = (addOn) => {
  return Number(addOn.quantity) * Number(addOn.price)
}

export const getAddOnTotal = (addOn) => {
  const addOnTotal = getItemTotalPrice(addOn)
  return Number(addOn.quantity) * Number(addOnTotal)
}

export const getPaymentTotal = (payments) => {
  const paymentTotal = payments.reduce((x,y) => {
    return x + Number(y.amount);
  }, 0)
  return paymentTotal
}

export const getBalance = (eventPackages, eventAddOns, payments, financials) => {
  const total = getTotal(eventPackages, eventAddOns, financials)
  const paymentTotal = getPaymentTotal(payments)
  return total - paymentTotal
}

export const getNumericCurrency = (amount) => {
  if (isNumber(amount)) {
    return amount
  } else if (!isEmpty(amount) && !isUndefined(amount) && !isNull(amount)) {
    const filteredAmount = amount.replace(/[^0-9.]/g, '')
    return Number(filteredAmount)
  }
  return 0
}

export const getFormattedDiscount = (financials, code) => {
  if (financials.discount) {
    if (financials.discount_type == "AMOUNT") {
      return formatCurrencyString(financials.discount_amount, code)
    } else if (financials.discount_type == "PERCENTAGE") {
      return financials.discount_percentage + "%"
    }
    return formatCurrencyString(0, code)
  } else {
    return "None"
  }
}

export const getDiscountTotal = (financials, eventPackages, eventAddOns) => {
  const subTotal = getSubTotal(eventPackages, eventAddOns)
  var discountAmount = 0;
  if (financials.discount) {
    if (financials.discount_type == "AMOUNT" && !isEmpty(financials.discount_amount)) {
      discountAmount = Number(financials.discount_amount);
    } else if (financials.discount_type == "PERCENTAGE" && !isEmpty(financials.discount_percentage)) {
      const percentage = Number(financials.discount_percentage) / 100;
      discountAmount = subTotal * percentage
    }
    return discountAmount
  } else {
    return 0
  }
}

export const getDiscountedPrice = (item, code) => {
  if (!isEmpty(item) && item.discount == true) {
    if (item.discount_type == "AMOUNT") {
      return formatCurrencyString(item.price - item.discount_amount, code)
    } else if (item.discount_type == "PERCENTAGE") {
      const percentage = 1 - (Number(item.discount_percentage) / 100);
      const discountAmount = item.price * percentage
      return formatCurrencyString(discountAmount, code)
    }
  }
  return formatCurrencyString(0, code)
}

export const getItemDiscountAmount = (item, formatted = false, code) => {
  if (!isEmpty(item) && item.discount == true) {
    if (item.discount_type == "AMOUNT") {
      const discountAmount = item.discount_amount
      return formatted ? formatCurrencyString(discountAmount, code) : discountAmount
    } else if (item.discount_type == "PERCENTAGE") {
      const percentage = Number(item.discount_percentage) / 100;
      const discountAmount = item.price * percentage
      return formatted ? formatCurrencyString(discountAmount, code) : discountAmount
    }
  }
  return formatted ? formatCurrencyString(0, code) : 0
}

export const getAddOnItemDiscountTotal = (item, formatted = false, code) => {
  const discount = getItemDiscountAmount(item)
  const totalDiscount = discount * item.quantity
  return formatted ? formatCurrencyString(totalDiscount, code) : totalDiscount
}

export const getItemTotalPrice = (item, formatted = false, code) => {
  const discountAmount = getItemDiscountAmount(item, false, code)
  const discountedPrice = item.price - discountAmount;
  return formatted ? formatCurrencyString(discountedPrice, code) : discountedPrice
}

export const getDiscountMessage = (item) => {
  const isDiscountPercentage = item.discount_type == "PERCENTAGE"
  const discountString = `${isDiscountPercentage ? item.discount_percentage : item.discount_amount} discount applied`
  return discountString
}

export const getContractFilters = () => {
  return JSON.parse(localStorage.getItem(StorageKeys.CONTRACT_FILTERS)) || {}
}

export const setContractFilters = (status, filters, sort) => {
  const values = {
    status,
    filters,
    sort
  }
  localStorage.setItem(StorageKeys.CONTRACT_FILTERS, JSON.stringify(values));
}

export default {
  GENERAL_SETTINGS: "GENERAL_SETTINGS",
  COMPANY_LOGO: "COMPANY_LOGO",
  EVENT_SETTINGS: "EVENT_SETTINGS",
  SPECIAL_EVENT_TEMPLATES: "SPECIAL_EVENT_TEMPLATES",
  SERVICES: "SERVICES",
  QUESTIONNAIRES: "QUESTIONNAIRES",
  CONTRACT_TEMPLATES: "CONTRACT_TEMPLATES",
  DOCUMENTATION: "DOCUMENTATION",
  STAFF: "STAFF"
};
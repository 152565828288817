import React, { useEffect, useState } from "react";
import { Row, Col, Table, Modal, Tag } from 'antd';
import { isNull, isArray } from 'lodash';
import moment from "moment";
import { useNavigate } from 'react-router';
import useApi from '../../hooks/useApi';
import { getSystemEventInvoices, getSystemAccounts } from '../../api';
import LoadingSpinner from '../../components/loading';
import AdminContent from '../../components/adminContent';
import FloatingContainer from '../../components/floatingContainer'
import { MdOutlineClose } from "react-icons/md";

const EventInvoicesPage = () => {

  const [isLoading, setLoading] = useState(true);
  const [invoices, setInvoices] = useState([]);
  const [accounts, setAccounts] = useState([]);
  const [isInvoiceModalVisible, setInvoiceModalVisible] = useState(false);
  const [selectedInvoice, setSelectedInvoice] = useState({});

  const navigate = useNavigate();
  const [sendRequest] = useApi()

  const activeColumns = [
    {
      title: 'Invoice ID',
      dataIndex: 'invoice_id',
      key: 'invoice_id',
      render: (text, record) => text
    },
    {
      title: 'Invoice #',
      dataIndex: 'invoice_number',
      key: 'invoice_number',
      render: (text) => text
    },
    {
      title: 'Amount Due',
      dataIndex: 'amount_due',
      key: 'amount_due',
      render: (text) => text
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (text, record) => <Tag color={text == "PAID" ? "green" : "red"}>{text}</Tag>,
    },
    {
      title: 'Link',
      dataIndex: 'include_payment_link',
      key: 'include_payment_link',
      render: (text, record) => <Tag color={text ? "green" : "red"}>{text ? "YES" : "NO"}</Tag>,
    },
    {
      title: 'Created Date',
      dataIndex: 'create_datetime',
      key: 'create_datetime',
      render: (text) => !isNull(text) ? moment(text).format("MMM D, YYYY") : "--"
    },
    {
      title: 'Payments',
      dataIndex: 'payments',
      key: 'payments',
      render: (text, record) => renderPayments(record)
    },
  ];

  const accountColumns = [
    {
      title: 'Account ID',
      dataIndex: 'account_id',
      key: 'account_id',
      render: (text, record) => text
    },
    {
      title: 'Company Name',
      dataIndex: 'company_name',
      key: 'company_name',
      render: (text) => text
    },
    {
      title: 'Event Invoices',
      dataIndex: 'event_invoices',
      key: 'event_invoices',
      render: (text, record) => getNumberOfEventInvoices(record.account_id)
    }
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
    refreshPage()
  }, []);

  const refreshPage = async () => {
    try {
      const eventInvoicesResults = await sendRequest(getSystemEventInvoices());
      setInvoices(eventInvoicesResults)
      const accountResults = await sendRequest(getSystemAccounts());
      setAccounts(accountResults)
    } finally {
      setLoading(false)
    }
  }

  const selectRecord = (invoice) => {
    setSelectedInvoice(invoice)
    setInvoiceModalVisible(true)
  }

  const selectAccount = (account) => {
    navigate(`/system/event-invoices/${account.account_id}`)
  }

  const getNumberOfEventInvoices = (account_id) => {
    return invoices.filter(x => x.account_id == account_id).length
  }

  const renderHeader = () => {
    return (
      <Row align="middle" className="p-20">
        <Col flex={1}>
          <div className="admin-page-title">Event Invoices</div>
        </Col>
      </Row>
    )
  }

  const renderDate = (date) => {
    if (date) {
      return moment(date).format("MM/DD/YYYY @ hh:mma") 
    } else {
      return "--"
    }
  }

  const renderPayments = (record) => {
    if (isArray(record.payments)) {
      return record.payments.map((x,i) => (
        <div>{x.amount} ({x.payment_type})</div>
      ))
    }
    return null
  }

  const renderInvoiceModal = () => {
    return (
      <Modal visible={isInvoiceModalVisible} closable={false} footer={null} width={800} wrapClassName="rounded-modal">
        <div className="text-right" onClick={() => setInvoiceModalVisible(false)}><MdOutlineClose size={30} color={"#CCC"}/></div>
        <div className="mt-10">
          <div className="fw-700 fs-20 ml-10">Invoice Info</div>
          <Row align="middle" className="p-20" gutter={[20,20]}>
            <Col xs={12}>
              <div className="fw-700">Account ID</div>
              <div className="">{ selectedInvoice.account_id }</div>
            </Col>
            <Col xs={12}>
              <div className="fw-700">Status</div>
              <div className="">{ selectedInvoice.status }</div>
            </Col>
            <Col xs={12}>
              <div className="fw-700">Invoice ID</div>
              <div className="">{ selectedInvoice.invoice_id }</div>
            </Col>
            <Col xs={12}>
              <div className="fw-700">Invoice #</div>
              <div className="">{ selectedInvoice.invoice_number }</div>
            </Col>
            <Col xs={12}>
              <div className="fw-700">Amount Due</div>
              <div className="">{ selectedInvoice.amount_due }</div>
            </Col>
            <Col xs={12}>
              <div className="fw-700">Amount Due Type</div>
              <div className="">{ selectedInvoice.amount_due_type }</div>
            </Col>
            <Col xs={12}>
              <div className="fw-700">Bill To</div>
              <div className="">User ID: { selectedInvoice.bill_to_user_id }</div>
              <div className="">Name: { selectedInvoice.bill_to_first_name } { selectedInvoice.bill_to_last_name }</div>
              <div className="">Email: { selectedInvoice.bill_to_email }</div>
            </Col>
            <Col xs={12}>
              <div className="fw-700">Due Date:</div>
              <div className="">{ renderDate(selectedInvoice.due_date) }</div>
            </Col>
            <Col xs={12}>
              <div className="fw-700">Created Date</div>
              <div className="">{ renderDate(selectedInvoice.create_datetime) }</div>
            </Col>
            <Col xs={24}>
              <div className="fw-700">Notes</div>
              <div className="line-breaks">{ selectedInvoice.notes }</div>
            </Col>
          </Row>
          <div className="t-border mv-30"></div>
          <div className="fw-700 fs-20 ml-10">Payments</div>
          <Row align="middle" className="p-20" gutter={[20,20]}>
            <Col xs={24}>
              { isArray(selectedInvoice.payments) && selectedInvoice.payments.map((x,i) => (
                <div key={i} className="mb-15">
                  <div className="fw-700">{ moment(x.payment_datetime).format("MM/DD/YYYY @ hh:mma")}</div>
                  <div className="">{ x.amount }</div>
                  <div className="">{ x.payment_type }</div>
                </div>
              ))}
            </Col>
          </Row>
        </div>
      </Modal>
    )
  }

  const renderContent = () => {
    return (
      <FloatingContainer className="ph-20" verticalPadding={20} maxWidth={1000}>
        <Row align="middle" gutter={[15,15]}>
          <Col xs={24}>
            <div className="shadow-card p-30 text-center">
              <div className="fs-40 fw-700">{invoices.length}</div>
              <div className="fs-16 fw-400">INVOICES</div>
            </div>
          </Col>
        </Row>
        <div className="t-border mv-30"></div>
        <div className="fs-18 fw-700 mb-10 ml-5">Invoices</div>
        <div className="shadow-card">
          <Table 
            columns={activeColumns} 
            dataSource={invoices}
            pagination={false}
            onRow={(record, rowIndex) => {
              return {
                onClick: e => selectRecord(record)
              };
            }}
          />
        </div>
        <div className="t-border mv-30"></div>
        <div className="fs-18 fw-700 mb-10 ml-5">Accounts</div>
        <div className="shadow-card">
          <Table 
            columns={accountColumns} 
            dataSource={accounts}
            pagination={false}
            onRow={(record, rowIndex) => {
              return {
                onClick: e => selectAccount(record)
              };
            }}
          />
        </div>
        {renderInvoiceModal()}
      </FloatingContainer>
    )
  }

  if (isLoading) {
    return <LoadingSpinner/>
  }

  return (
    <AdminContent header={renderHeader()} body={renderContent()}/>
  );
}

export default EventInvoicesPage;

import { isEmpty, isUndefined } from 'lodash';
import React, { useContext } from 'react';
import { Navigate, Outlet, useLocation } from "react-router-dom";
import AppContext from '../../app/context';
import UserTypes from '../../constants/userTypes';
import ClientRestrictedPage from "../../pages/client/restrictedPage";
import Roles from '../../constants/roles';

const ProtectedRoute = ({roles, permissions}) => {

  const { auth, currentEvent } = useContext(AppContext);
  const location = useLocation();

  // Roles
  var allowedRoles = roles;
  if (typeof roles === "string") {
    allowedRoles = [roles];
  }

  // Permissions
  var allowedPermissions = permissions;
  if (typeof permissions === "string") {
    allowedPermissions = [permissions];
  }

  // Go to login if client is not authenticated
  if (!auth.isAuthenticated) {
    return <Navigate replace to='/login' />
  }

  // Go to home page if not a client
  if (auth.user.user_type_id !== UserTypes.CLIENT) {
    return <Navigate replace to='/' />
  }

  // Check if client has event
  if (isUndefined(auth.user.event_id) && location.pathname != "/client/event-selection") {
    const queryString = !isEmpty(location.search) ? location.search : ""
    return <Navigate replace to={`/client/event-selection${queryString}`} />
  }

  // Check role
  if (allowedRoles && !allowedRoles.includes(auth.user.role)) {
    return <Navigate replace to='/' />
  }
  
  // Check permissions
  var hasPermission = true
  if (allowedPermissions && auth.user.role !== Roles.OWNER) {
    hasPermission = false
    for (const permission of auth.user.permissions) {
      if (allowedPermissions.includes(permission)) {
        hasPermission = true
      }
    }
  }
  if (!hasPermission) {
    return <ClientRestrictedPage/>
  }

  return <Outlet />
}

export default ProtectedRoute;

import React from "react";
import { useNavigate } from 'react-router-dom';
import { BsArrowLeft, BsArrowRight } from "react-icons/bs";

export const BackToHomeLink = (props) => {
  const navigate = useNavigate();
  return <div className="blue-link flex-row flex-1 flex-middle" onClick={() => navigate("/admin/docs")}><BsArrowLeft style={{ marginRight: 5 }}/>Home</div>
}

export const DocLink = (props) => {
  const navigate = useNavigate();
  return <span className="blue-link" onClick={() => navigate(props.to)}>{ props.children }</span> 
}

export const DocArrowLink = (props) => {
  const navigate = useNavigate();
  return <span className="blue-link flex-row flex-1 flex-middle" style={{ display: "inline-flex" }} onClick={() => navigate(props.to)}>{ props.text ?? "Learn more"} <BsArrowRight style={{ marginLeft: 5 }}/></span>
}
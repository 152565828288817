import React, { useEffect, useState, useContext } from "react";
import moment from 'moment';
import qs from "qs";
import { useNavigate, useLocation } from "react-router-dom";
import { isEmpty, isNull } from "lodash";
import { Row, Col, Upload, Modal, notification } from 'antd';
import useApi from '../../hooks/useApi';
import AppContext from '../../app/context';
import LoadingSpinner from '../../components/loading';
import { MdCheck, MdOutlineArrowForwardIos, MdOutlineCameraAlt, MdOutlineClose } from "react-icons/md";
import { FiHeadphones } from "react-icons/fi";
import { EnvironmentOutlined, LoadingOutlined } from '@ant-design/icons';
import placeholderImage from '../../images/djpc-placeholder.png';
import welcomeImage from '../../images/client-home-preview.png';
import { uploadEventPhoto, getClientEvent, getAccountSettingsForEvent } from '../../api';
import FloatingContainer from '../../components/floatingContainer'
import iosDownloadButton from '../../images/ios-download-button.png';
import androidDownloadButton from '../../images/android-download-button.png';
import EventPermissions from '../../constants/eventPermissions';
import { hasEventPermission } from '../../helpers/permissionHelper';
import { resizeFile, isValidFileType } from '../../helpers/imageHelper';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import { getFormattedPhoneNumber } from '../../helpers/addressHelper';
import { formatEventDateLong } from '../../helpers/dateHelper';


const ClientHomePage = () => {

  const [isLoading, setLoading] = useState(true);
  const [eventDate, setEventDate] = useState(moment());
  const [countDownDays, setCountdownDays] = useState(0);
  const [countDownHours, setCountdownHours] = useState(0);
  const [countDownMinutes, setCountdownMinutes] = useState(0);
  const [countDownSeconds, setCountdownSeconds] = useState(0);
  const [imageUrl, setImageUrl] = useState(null);
  const [isWelcomeModalVisible, setWelcomeModalVisible] = useState(false);
  const [isSavingPhoto, setSavingPhoto] = useState(false);
  const [canViewMusic, setCanViewMusic] = useState(false);
  const [canViewQuestionnaires, setCanViewQuestionnaires] = useState(false);
  const [canViewTimeline, setCanViewTimeline] = useState(false);
  const [canEditMusic, setCanEditMusic] = useState(false);
  const [canEditQuestionnaires, setCanEditQuestionnaires] = useState(false);
  const [canEditTimeline, setCanEditTimeline] = useState(false);
  const [event, setEvent] = useState({});
  const [accountSettings, setAccountSettings] = useState({});
  const [staff, setStaff] = useState([]);
  const [clientPortalSettings, setClientPortalSettings] = useState({});

  useDocumentTitle("Home")
  const { auth } = useContext(AppContext);
  const navigate = useNavigate();
  const location = useLocation();
  const [sendRequest] = useApi()

  var interval = null;

  useEffect(() => {
    window.scrollTo(0, 0);
    const queryStrings = qs.parse(location.search, { ignoreQueryPrefix: true })
    if (queryStrings.type == "welcome") {
      setWelcomeModalVisible(true)
    }
    refreshPage()
    return () => clearInterval(interval);
  }, []);

  const refreshPage = async () => {
    try {
      await refreshEvent()

      setCanViewMusic(hasEventPermission(auth.user, EventPermissions.VIEW_MUSIC))
      setCanViewQuestionnaires(hasEventPermission(auth.user, EventPermissions.VIEW_QUESTIONNAIRES))
      setCanViewTimeline(hasEventPermission(auth.user, EventPermissions.VIEW_TIMELINE))
      setCanEditMusic(hasEventPermission(auth.user, EventPermissions.EDIT_MUSIC))
      setCanEditQuestionnaires(hasEventPermission(auth.user, EventPermissions.EDIT_QUESTIONNAIRES))
      setCanEditTimeline(hasEventPermission(auth.user, EventPermissions.EDIT_TIMELINE))
      setLoading(false)

      const accountSettingsResults = await sendRequest(getAccountSettingsForEvent(auth.user.event_id));
      setAccountSettings(accountSettingsResults)
    } catch {
      setLoading(false)
    }
  }

  const refreshEvent = async () => {
    try {
      const eventResults = await sendRequest(getClientEvent(auth.user.user_id, auth.user.event_id))
      setEvent(eventResults)
      setClientPortalSettings(eventResults.client_portal_settings ?? {})

      const dateTime = moment(eventResults.event_date + " " + eventResults.start_time)
      setEventDate(dateTime)
      if (dateTime.isAfter(moment())) {
        updateCountdown(dateTime)
        interval = setInterval(() => {
          updateCountdown(dateTime)
        }, 1000)
      }

      if (eventResults.employees && eventResults.employees.length > 0) {
        const clientVisibleEmployees = eventResults.employees.filter(x => x.client_visible == true)
        setStaff(clientVisibleEmployees)
      }
    } catch {}
  }

  const updateCountdown = (dateTime) => {
    const daysDiff = moment(dateTime).diff(moment(), 'days')
    const hoursDiff = moment(dateTime).diff(moment(), 'hours')
    const minutesDiff = moment(dateTime).diff(moment(), 'minutes')
    const secondsDiff = moment(dateTime).diff(moment(), 'seconds')
    setCountdownDays(daysDiff)
    setCountdownHours(hoursDiff % 24)
    setCountdownMinutes(minutesDiff % 60)
    setCountdownSeconds(secondsDiff % 60)
  }

  const handleImageChange = async (info) => {
    try {
      // Make sure the file type is JPG or PNG
      if (!isValidFileType(info.file)) {
        notification.error({
          message: 'Error',
          description: 'Only file types of JPG or PNG are supported.',
          duration: 3
        });
        return
      }

      setSavingPhoto(true)

      // Resize photo
      const newFile = await resizeFile(info.file)
      setImageUrl(newFile)

      // Get blob from URI
      let response = await fetch(newFile);
      let blob = await response.blob()

      // Upload photo
      const formData = new FormData();
      formData.append('photo', blob);
      formData.append('event_id', event.event_id);
      await sendRequest(uploadEventPhoto(formData))

      refreshEvent()
    } catch(error) {
      notification.error({
        message: 'Error',
        description: 'Something went wrong! Please try again later.',
        duration: 3
      });
    }
    finally {
      setSavingPhoto(false)
    }
  };

  const handleBeforeUpload = () => {
    return false
  };

  const openDownloadAppLink = (platform, closeModal) => {
    if (closeModal) {
      closeWelcomeModal()
    }
    if (platform == "ios") {
      window.open("https://apps.apple.com/us/app/dj-planning-center/id1638751912")
    } else {
      window.open("https://play.google.com/store/apps/details?id=com.djpcapp")
    }
  }


  const closeWelcomeModal = () => {
    setWelcomeModalVisible(false)
    navigate(`/client/home`, { replace: true })
  }

  const renderChecklistItem = (text, path) => {
    return (
      <div className="display-flex flex-1 flex-row pt-20 cursor-default ph-20" onClick={() => navigate(path)}>
        <div className="display-flex flex-0">
          <div style={{ width: 32, height: 32, marginRight: 10, backgroundColor: "#eef0ff", borderRadius: "50%", display: 'flex', alignItems: "center", justifyContent: 'center'}}>
            <MdCheck size={24} color={"#536DFE"}/>
          </div>
        </div>
        <div className="display-flex flex-1">
          <div className="fs-16 fw-600 line-1-2" style={{ marginTop: 6}}>{text}</div>
        </div>
        <div className="display-flex flex-0">
          <MdOutlineArrowForwardIos style={{ fontSize: 20, color: '#CCC' }}/>
        </div>
      </div>
    )
  }

  const renderCountdown = () => {
    return (
      <div className="shadow-card countdown-card">
        <Row align="middle" gutter={[10,10]} className="text-center">
          <Col xs={6} style={{ borderRight: "1px solid #ededed"}}>
            <div className="countdown-number">{countDownDays}</div>
            <div className="countdown-label">DAYS</div>
          </Col>
          <Col xs={6} style={{ borderRight: "1px solid #ededed"}}>
            <div className="countdown-number">{countDownHours}</div>
            <div className="countdown-label">HOURS</div>
          </Col>
          <Col xs={6} style={{ borderRight: "1px solid #ededed"}}>
            <div className="countdown-number">{countDownMinutes}</div>
            <div className="countdown-label">MINUTES</div>
          </Col>
          <Col xs={6}>
            <div className="countdown-number">{countDownSeconds}</div>
            <div className="countdown-label">SECONDS</div>
          </Col>
        </Row>
      </div>
    )
  }

  const renderImage = () => {
    if (imageUrl) {
      return (
        <img src={imageUrl} style={{ width: '100%', borderRadius: 10}}/>
      )
    } else if (event.event_photo) {
      return (
        <img src={event.event_photo} style={{ width: '100%', borderRadius: 10}}/>
      )
    } else {
      return (
        <img src={placeholderImage} style={{ width: '100%', borderRadius: 10}}/>
      )
    }
  }

  const renderImageLoading = () => {
    if (isSavingPhoto) {
      return (
        <div className="event-photo-loading"><LoadingOutlined/></div>
      )
    }
    return null;
  }

  const renderImageUploadButton = () => {
    return (
      <Upload
        showUploadList={false}
        beforeUpload={handleBeforeUpload}
        onChange={handleImageChange}
        maxCount={1}
      >
        <div className="event-photo-upload">
          <MdOutlineCameraAlt/>
        </div>
      </Upload>
    )
  }

  const renderVenue = () => {
    if (!isEmpty(event.venues) && event.venues.length > 0) {
      const venue = event.venues[0]
      return (
        <div className="shadow-card mt-20">
          <Row align="middle" className="mh-20 pv-15 b-border">
            <Col flex={1}>
              <div className="fs-18 fw-700">Venue</div>
            </Col>
          </Row>
          <Row  gutter={[15,15]} align="middle" className="p-20">
            <Col>
              <div className="display-flex">
                <div className="user-profile-circle"><EnvironmentOutlined/></div>
              </div>
            </Col>
            <Col flex={1}>
              <div className="fs-14 fw-700">{venue.venue_name}</div>
              <div className="fs-14 fw-500 c-text-gray line-1-1">{venue.address_line_1}</div>
              <div className="fs-14 fw-500 c-text-gray">{venue.city}, {venue.state} {venue.zip}</div>
            </Col>
          </Row>
        </div>
      )
    }
    return null;
  }

  const getUserPhoneNumber = (user) => {
    if (user.phone_numbers && user.phone_numbers.length > 0) {
      return user.phone_numbers[0].phone_number
    }
    return "";
  }

  const renderCustomStaffMember = (employee) => {
    const phoneNumber = getFormattedPhoneNumber(employee.phone)
    const name = isEmpty(employee.alias) ? `${employee.first_name} ${employee.last_name}` : employee.alias
    return (
      <div className="shadow-card mt-20">
        <Row align="middle" className="mh-20 pv-15 b-border">
          <Col flex={1}>
            <div className="fs-18 fw-700">My Team</div>
          </Col>
        </Row>
        <Row  gutter={[15,15]} align="middle" className="p-20">
          <Col>
            { employee.photo ? (
              <div className="small-profile-photo size-60" style={{ backgroundImage: `url(${employee.photo})`}}></div>
            ) : (
              <div className="display-flex">
                <div className="user-profile-circle"><FiHeadphones/></div>
              </div>
            )}
          </Col>
          <Col flex={1}>
            <div className="fs-14 fw-700">{name}</div>
            <div className="fs-14 fw-500 c-text-gray line-1-1">{employee.email}</div>
            { !isEmpty(phoneNumber) && (
              <div className="fs-14 fw-500 c-text-gray line-1-1 mt-5">{phoneNumber}</div>
            )}
          </Col>
        </Row>
      </div>
    )
  }

  const renderStaffMember = (employee, index) => {
    const phoneNumber = getFormattedPhoneNumber(getUserPhoneNumber(employee))
    const name = isEmpty(employee.alias) ? `${employee.first_name} ${employee.last_name}` : employee.alias
    const showContactInfo = employee.display_contact_info ?? false
    const borderClass = index < staff.length - 1 ? "b-border" : ""
    return (
      <div className={`p-20 ${borderClass}`} key={index}>
        <Row  gutter={[15,15]} align="middle">
          <Col>
            { employee.photo ? (
              <div className="small-profile-photo size-60" style={{ backgroundImage: `url(${employee.photo})`}}></div>
            ) : (
              <div className="display-flex">
                <div className="user-profile-circle"><FiHeadphones/></div>
              </div>
            )}
          </Col>
          <Col flex={1}>
            <div className="fs-14 fw-700">{name} <span className="fw-500 c-text-gray">({employee.role_name})</span></div>
            { showContactInfo && (
              <>
                <div className="fs-14 fw-500 c-text-gray line-1-1">{employee.email}</div>
                { !isEmpty(phoneNumber) && (
                  <div className="fs-14 fw-500 c-text-gray line-1-1 mt-5">{phoneNumber}</div>
                )}
              </>
            )}
          </Col>
        </Row>
      </div>
    )
  }

  const renderStaff = () => {
    // Show custom staff member
    if (clientPortalSettings.visible_staff == "CUSTOM" && !isEmpty(clientPortalSettings.custom_staff)) {
      return renderCustomStaffMember(clientPortalSettings.custom_staff)
    }

    // Show nothing if there are no assigned staff
    if (staff.length == 0) {
      return null
    }

    // Otherwise show all staff (with settings)
    return (
      <div className="shadow-card mt-20">
        <Row align="middle" className="mh-20 pv-15 b-border">
          <Col flex={1}>
            <div className="fs-18 fw-700">My Team</div>
          </Col>
        </Row>
        { staff.map((x,i) => renderStaffMember(x,i)) }
      </div>
    )
  }

  const renderWelcomeModal = () => {
    return (
      <Modal visible={isWelcomeModalVisible} footer={null} onCancel={() => closeWelcomeModal()} width={500} closable={false} maskClosable={false} wrapClassName="rounded-modal">
        <Row align="middle">
          <Col flex={1}>
          </Col>
          <Col>
            <div className="display-flex" onClick={() => closeWelcomeModal()}><MdOutlineClose size={30} color={"#CCC"}/></div>
          </Col>
        </Row>
        <div className="">
          <div className="fw-700 fs-18 text-center mt-20 mb-30">Welcome to DJ Planning Center!</div>
          <div className="text-center b-border">
            <img src={welcomeImage} style={{ width: 200}}/>
          </div>
          <div className="fw-500 fs-14 text-center mt-30 mb-20">Our mobile app is the best way to plan your event on the go. Download the app today to get the most out of your experience!</div>
          <div class="text-center">
            <span onClick={() => openDownloadAppLink("android", true)}><img src={androidDownloadButton} style={{ width: 150, marginRight: 10 }}/></span>
            <span onClick={() => openDownloadAppLink("ios", true)}><img src={iosDownloadButton} style={{ width: 150 }}/></span>
          </div>
          <div className="text-center mt-15">
            <div className="blue-link" onClick={() => closeWelcomeModal(false)}>Not Now</div>
          </div>
        </div>
      </Modal>
    )
  }

  const renderContent = () => {
    return (
      <FloatingContainer className="ph-20" verticalPadding={20} maxWidth={600}>
        <div className="shadow-card p-20">
          <div style={{position: "relative"}}>
            { renderImage() }
            { renderImageLoading() }
            { renderImageUploadButton() }
            { renderCountdown() }
          </div>
          <div className="fs-24 fw-700 line-1-2 text-center mt-50">{event && event.event_name}</div>
          <div className="fs-16 fw-500 text-center c-text-gray">{formatEventDateLong(event.event_date, accountSettings)}</div>
        </div>
        { renderVenue() }
        { renderStaff() }
        { ((canViewMusic && canEditMusic) || (canViewQuestionnaires && canEditQuestionnaires) || (canViewTimeline && canEditTimeline)) && (
          <div className="shadow-card mt-20 pb-20">
            <Row align="middle" className="mh-20 pv-15 b-border">
              <Col flex={1}>
                <div className="fs-18 fw-700">My Tasks</div>
              </Col>
            </Row>
            { canViewMusic && canEditMusic && renderChecklistItem("Select special event songs", "/client/music?tab=special-events") }
            { canViewMusic && canEditMusic && renderChecklistItem("Choose music preferences", "/client/music?tab=preferences") }
            { canViewQuestionnaires && canEditQuestionnaires && renderChecklistItem("Complete questionnaires", "/client/questionnaires") }
            { canViewTimeline && canEditTimeline && renderChecklistItem("Build a timeline", "/client/timeline") }
          </div>
        )}
      </FloatingContainer>
    )
  }

  if (isLoading) {
    return <LoadingSpinner/>
  }

  return (
    <div className="flex-1">
      { renderContent() }
      {renderWelcomeModal()}
    </div>
  );
}

export default ClientHomePage;

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useApi from '../../../hooks/useApi';
import { SortableContainer, sortableElement } from 'react-sortable-hoc';
import { Form, Row, Col, Modal, Tooltip, Alert, notification, Menu, Dropdown } from 'antd';
import { 
  getAccountClientPortalSettings,
  updateAccountClientPortalSettings,
  getAdminUsers,
  getAccountGenres,
  createAccountGenre,
  updateAccountGenre,
  deleteAccountGenre
} from '../../../api';
import LoadingSpinner from '../../../components/loading';
import AdminContent from '../../../components/adminContent';
import FloatingContainer from '../../../components/floatingContainer'
import { MdArrowForwardIos, MdOutlineClose, MdInfoOutline, MdOutlineEdit, MdOutlineMoreHoriz, MdDragIndicator } from "react-icons/md";
import { FiTrash } from "react-icons/fi";
import {
  renderSearchSelectField,
  renderInputField
} from '../../../components/formFields'
import useDocumentTitle from '../../../hooks/useDocumentTitle';
import { getRank, getNewRank, sortByRank } from '../../../helpers/rankHelper'
import { isEmpty, cloneDeep, isUndefined, isNull } from "lodash";
import FeatureFlags from '../../../constants/featureFlags';

const ClientPortalSettingsPage = () => {

  const [isLoading, setLoading] = useState(true);
  const [clientPortalSettings, setClientPortalSettings] = useState({});
  const [employees, setEmployees] = useState([]);
  const [isUpdateStaffOptionsModalVisible, setUpdateStaffOptionsModalVisible] = useState(false);
  const [visibleStaffOption, setVisibleStaffOption] = useState("ALL");

  // Genres
  const [isGenreModalVisible, setGenreModalVisible] = useState(false);
  const [isRemoveGenreModalVisible, setRemoveGenreModalVisible] = useState(false);
  const [genres, setGenres] = useState([]);
  const [selectedGenre, setSelectedGenre] = useState({});
  const [deleteGenreError, setDeleteGenreError] = useState(null);
  const [newGenreError, setNewGenreError] = useState(null);

  useDocumentTitle("Client Portal Settings")
  const navigate = useNavigate();
  const [updateStaffSettingsForm] = Form.useForm();

  const [sendRequest] = useApi()

  const [genreForm] = Form.useForm();

  const visibleStaffOptions = [
    { text: "All Assigned Staff (with settings)", value: "ALL"},
    { text: "Specific Staff Member", value: "CUSTOM"}
  ]

  const allAssignedStaffDescription = `Based on this selection, the client will see all assigned staff members who have the “Visible to client” checkbox selected on the event.  You also have the ability to control whether or not contact information is displayed for each staff member by using the “Display contact information” checkbox. These options will be checked by default when assigning a staff member, however you have the ability to customize this information for each event.`
  const customStaffDescription = `Based on this selection, the client will see the staff member selected above even if they have not been assigned to the event.`

  useEffect(() => {
    window.scrollTo(0, 0);
    refreshPage();
  }, []);

  const refreshPage = async () => {
    try {
      const clientPortalSettingsResults = await sendRequest(getAccountClientPortalSettings())
      setClientPortalSettings(clientPortalSettingsResults)
      setVisibleStaffOption(clientPortalSettingsResults.visible_staff)

      if (FeatureFlags.CUSTOM_GENRES) {
        const genreResults = await sendRequest(getAccountGenres())
        setGenres(genreResults)
      }

      const employeeResults = await sendRequest(getAdminUsers())
      const employeeData = employeeResults.map((employee) => {
        const fullName = `${employee.first_name} ${employee.last_name} ${employee.admin_user_role_id == "OWNER" ? "(Owner)" : ""}`
        return {
          value: employee.user_id,
          text: fullName
        }
      })
      setEmployees(employeeData)

      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  const startUpdateStaffSettings = () => {
    const fields = {
      visible_staff: clientPortalSettings.visible_staff,
      custom_staff_id: clientPortalSettings.custom_staff_id
    }
    updateStaffSettingsForm.setFieldsValue(fields)
    setUpdateStaffOptionsModalVisible(true)
  }

  const saveStaffSettings = async (values) => {
    try {
      await sendRequest(updateAccountClientPortalSettings(values))
      await refreshPage()
      setUpdateStaffOptionsModalVisible(false)
    } catch (error) {
      setUpdateStaffOptionsModalVisible(false)
    }
  }

  const onVisibleStaffOptionChange = (value) => {
    setVisibleStaffOption(value)
  }

  const getVisibleStaffName = () => {
    const option = visibleStaffOptions.find(x => x.value == clientPortalSettings.visible_staff)
    if (!isEmpty(option)) {
      if (clientPortalSettings.visible_staff == "CUSTOM" && !isEmpty(clientPortalSettings.custom_staff)) {
        return `${clientPortalSettings.custom_staff.first_name} ${clientPortalSettings.custom_staff.last_name}`
      }
      return option.text
    }
    return "--"
  }

  const getStaffVisibilityDescription = (option) => {
    if (option == "CUSTOM") {
      return customStaffDescription
    } else {
      return allAssignedStaffDescription
    }
  }

  // --------------------------
  // GENRE FUNCTIONS
  // --------------------------
  const onSubmitGenre = async (values) => {
    try {
      const genresCopy = cloneDeep(genres)
      if (isEmpty(selectedGenre)) {
        // Check if name already exists
        const foundName = genresCopy.find(x => x.genre_name == values.genre_name)
        if (!isUndefined(foundName)) {
          setNewGenreError("A genre with this name already exists.")
          return
        }
        // New Genre
        const body = {
          genre_name: values.genre_name,
          rank: getRank(genres)
        }
        await sendRequest(createAccountGenre(body))
      } else {
        // Check if name already exists
        const foundName = genresCopy.find(x => x.genre_name == values.genre_name && x.genre_id != selectedGenre.genre_id)
        if (!isUndefined(foundName)) {
          setNewGenreError("An genre with this name already exists.")
          return
        }
        // Existing Genre
        const body = {
          genre_name: values.genre_name,
          rank: selectedGenre.rank
        }
        await sendRequest(updateAccountGenre(selectedGenre.genre_id, body))
      }
      await refreshPage()
      setGenreModalVisible(false)
    } catch (error) {
      notification.error({
        message: 'Error',
        description: 'There was an issue adding/editing your genre. Please try again.',
        duration: 3
      });
    }
  }

  const handleCancelGenre = () => {
    setGenreModalVisible(false);
  };

  const handleNewGenre = () => {
    setNewGenreError(null)
    genreForm.resetFields()
    setSelectedGenre({})
    setGenreModalVisible(true);
  };

  const handleEditGenre = (item) => {
    setNewGenreError(null)
    genreForm.resetFields()
    genreForm.setFieldsValue(item)
    setSelectedGenre(item)
    setGenreModalVisible(true);
  };

  const handleDeleteGenre = (item) => {
    setDeleteGenreError(null)
    setSelectedGenre(item)
    setRemoveGenreModalVisible(true);
  };

  const confirmDeleteGenre = async () => {
    try {
      const results = await sendRequest(deleteAccountGenre(selectedGenre.genre_id))
      // if (results.status == "in-use") {
      //   setDeleteGenreError("This genre is currently assigned to one or more events. Please change the event type for those events before deleting this option.")
      //   return
      // }
      await refreshPage()
      setRemoveGenreModalVisible(false)
    } catch {
      notification.error({
        message: 'Error',
        description: 'There was an issue deleting this genre. Please try again.',
        duration: 3
      });
    }
  }

  const onGenreSortEnd = async (oldIndex, newIndex) => {
    if (oldIndex !== newIndex) {
      var newGenres = cloneDeep(genres)
      const currentGenre = newGenres[oldIndex]
      const rank = getNewRank(newGenres, oldIndex, newIndex)
      newGenres[oldIndex].rank = rank;
      const sortedGenres = sortByRank(newGenres);
      setGenres(sortedGenres)

      try {
        const body = {
          genre_name: currentGenre.genre_name,
          rank: rank
        }
        await sendRequest(updateAccountGenre(currentGenre.genre_id, body))
      } catch {
        notification.error({
          message: 'Error',
          description: 'An issue occured updating this genre',
          duration: 3
        });
      }
    }
  };

  const renderHeader = () => {
    return (
      <div className="p-20">
        <div>
          <span className="c-blue fw-700 cursor-default" onClick={() => navigate("/admin/setup")}>
            Setup
          </span>
          <span className="fs-10 mh-5"><MdArrowForwardIos/></span>
          <span className="cursor-default c-text-gray">
            Client Portal
          </span>
        </div>
        <div className="fw-700 fs-24 mt-5">Client Portal Settings</div>
      </div>
    )
  }

  const renderUpdateDisplayOptionsModal = () => {
    return (
      <Modal visible={isUpdateStaffOptionsModalVisible} footer={null} onCancel={() => setUpdateStaffOptionsModalVisible(false)} width={500} closable={false} maskClosable={false} wrapClassName="rounded-modal">
        <Row align="middle">
          <Col flex={1}>
            <div className="fw-700 fs-18">Update Staff Settings</div>
          </Col>
          <Col>
            <div className="display-flex" onClick={() => setUpdateStaffOptionsModalVisible(false)}><MdOutlineClose size={30} color={"#CCC"}/></div>
          </Col>
        </Row>
        <div className="">
          <Form form={updateStaffSettingsForm} layout="vertical" name="verify" onFinish={saveStaffSettings}>
            <div className="mt-10 mb-15">
              {renderSearchSelectField("Who is visible to the client?", "visible_staff", "Select an option...", visibleStaffOptions, true, onVisibleStaffOptionChange)}
              { visibleStaffOption == "CUSTOM" && (
              <>
                <div className="mt-15">
                  {renderSearchSelectField("Staff Member", "custom_staff_id", "Select an option...", employees, true)}
                </div>
              </>
            )}
              { visibleStaffOption != "" && (
                <div className="bg-gray p-10 mt-10 fs-12">{getStaffVisibilityDescription(visibleStaffOption)}</div>
              )}
            </div>
            <button className="primary-button" type="submit">Save</button>
            <div className="text-center mt-15">
              <div className="blue-link" onClick={() => setUpdateStaffOptionsModalVisible(false)}>Cancel</div>
            </div>
          </Form>
        </div>
      </Modal>
    )
  }

  const renderLabelWithTooltip = (label, tooltip) => {
    return (
      <div className="display-flex flex-middle">
        <div className="fs-14 fw-700 display-flex">{label}</div>
        <Tooltip title={tooltip}>
          <MdInfoOutline style={{ fontSize: 14, marginLeft: 6, color: '#777'}}/>
        </Tooltip>
      </div>
    )
  }

  // GENRES

  const renderMenuItem = (icon, title, action = () => {}) => {
    return (
      <Menu.Item>
        <Row className="pv-5" gutter={[10,10]}align="middle" onClick={() => action()}>
          <Col flex={0}>
            <div className="display-flex">{icon}</div>
          </Col>
          <Col flex={1}>{title}</Col>
        </Row>
      </Menu.Item>
    )
  }

  const genreMenu = (item) => {
    return (
      <Menu>
        { renderMenuItem(<MdOutlineEdit/>, "Edit Genre", () => handleEditGenre(item)) }
        { renderMenuItem(<FiTrash/>, "Delete Genre", () => handleDeleteGenre(item)) }
      </Menu>
    )
  };

  const renderGenre = (value, index) => {
    return (
      <Row align="middle" gutter={[10,10]} key={index} className="pr-10 pl-20 pv-10 user-select-none">
        <Col flex={1}>
          <div className="fs-14 fw-700 line-1-2">{value.genre_name}</div>
        </Col>
        <Col>
          <div className="display-flex mr-10">
            <Dropdown overlay={genreMenu(value)} placement="bottomRight" trigger="click">
              <div className="dots-container">
                <MdOutlineMoreHoriz style={{ fontSize: 24, color: '#999'}}/>
              </div>
            </Dropdown>
          </div>
        </Col>
        <Col>
          <div className="display-flex">
            <MdDragIndicator style={{ fontSize: 24, color: '#CCC'}}/>
          </div>
        </Col>
      </Row>
    )
  }

  const GenreSortableItem = sortableElement(({value, itemIndex}) => (
    <li className="border mb-5 mh-20">
      {renderGenre(value, itemIndex)}
    </li>
  ));

  const GenreSortableList = SortableContainer(({items}) => {
    return (
      <ul className="question-row-container">
        {items.map((value, index) => (
          <GenreSortableItem key={`item-${index}`} index={index} itemIndex={index} value={value} />
        ))}
      </ul>
    );
  });

  const renderGenreSection = () => {
    return (
      <div className="shadow-card pb-15 mb-20 mt-20">
        <Row className="b-border pb-15 pt-20 mh-20 mb-15">
          <Col flex={1}>
            <div className="fs-20 fw-700 line-1">Genres</div>
          </Col>
          { genres.length > 0 && (
            <Col>
              <div className="blue-link" onClick={() => handleNewGenre()}>Add</div>
            </Col>
          )}
          <Col xs={24}>
            <div className="bg-gray p-10 mt-10">The genres below will display as options in the "Favorite Genres" section of the Music page when your clients are selecting their music preferences. Drag and drop the items to arrange the order in which they will display in a list.</div>
          </Col>
        </Row>
        { genres.length == 0 ? (
          <div className="p-15 text-center">
            <div className="fs-14 fw-500 c-text-gray">
              No genres have been added yet.
            </div>
            <button className="small-primary-button mt-30" onClick={handleNewGenre}>Add Genre</button>
          </div>
        ) : (
          <GenreSortableList 
            items={genres} 
            onSortEnd={({ oldIndex, newIndex }) => onGenreSortEnd(oldIndex, newIndex)} 
            helperClass="question-row-dragging" 
            pressDelay={100} 
            lockAxis="y"/>
        )}
      </div>
    )
  }

  const renderGenreModal = () => {
    return (
      <Modal visible={isGenreModalVisible} footer={null} closable={false} wrapClassName="rounded-modal">
        <Row align="middle">
          <Col flex={1}>
            <div className="fw-700 fs-18">{ isEmpty(selectedGenre) ? "New" : "Edit"} Genre</div>
          </Col>
          <Col>
            <div className="display-flex" onClick={handleCancelGenre}><MdOutlineClose size={30} color={"#CCC"}/></div>
          </Col>
        </Row>
        <Form form={genreForm} layout="vertical" name="event-type" onFinish={onSubmitGenre}>
          <Row gutter={[10,10]} className="mt-15">
            <Col xs={24}>
              {renderInputField("Genre Name", "genre_name", true, false, "Enter a name...")}
            </Col>
            { !isNull(newGenreError) && (
              <Col xs={24}>
                <div className="c-red text-center">{newGenreError}</div>
              </Col>
            )}
          </Row>
          <div className="admin-modal-footer">
            <button className="primary-button" type="submit">{isEmpty(selectedGenre) ? "Add" : "Edit"} Genre</button>
            <div className="text-center mt-15">
              <div className="blue-link" onClick={handleCancelGenre}>Cancel</div>
            </div>
          </div>
        </Form>
      </Modal>
    )
  }

  const renderConfirmRemoveGenreModal = () => {
    return (
      <Modal visible={isRemoveGenreModalVisible} closable={false} footer={null} width={400} wrapClassName="rounded-modal">
        <div className="text-right" onClick={() => setRemoveGenreModalVisible(false)}><MdOutlineClose size={30} color={"#CCC"}/></div>
        <div className="mt-10">
          <div className="fw-700 fs-20 text-center">Are you sure?</div>
          <div className="fw-500 fs-14 mt-20 text-center">Are you sure you would like to delete <span className="fw-700">{selectedGenre.genre_name}</span> from your list of genres?</div>
          { !isNull(deleteGenreError) && (
            <div className="c-red text-center p-10 mt-10 bg-gray">{deleteGenreError}</div>
          )}
          <button className="primary-button warning mt-20" type="button" onClick={() => confirmDeleteGenre()}>Delete Genre</button>
          <div className="text-center mt-15">
            <div className="blue-link" onClick={() => setRemoveGenreModalVisible(false)}>Cancel</div>
          </div>
        </div>
      </Modal>
    )
  }

  const renderContent = () => {
    return (
      <>
        <FloatingContainer className="ph-20 mb-80" verticalPadding={20} maxWidth={800}>
          <Alert showIcon message={`The client portal settings below apply to both the website and the mobile app.`} type="info" style={{ marginBottom: 15 }}/>
          <div className="shadow-card p-20">
            <Row className="b-border pb-15 mb-15">
              <Col flex={1}>
                <div className="fs-20 fw-700 line-1">Staff</div>
              </Col>
              <Col>
                <div className="blue-link" onClick={() => startUpdateStaffSettings()}>Edit</div>
              </Col>
            </Row>
            { renderLabelWithTooltip("Who is visible to the client?", "Determines which staff members the client can see within the website and mobile app.")}
            <div className="fs-14 mt-5">{getVisibleStaffName()}</div>
            <div className="bg-gray p-10 mt-10 fs-12">{getStaffVisibilityDescription(clientPortalSettings.visible_staff)}</div>
          </div>
          { FeatureFlags.CUSTOM_GENRES && renderGenreSection() }
        </FloatingContainer>
        { renderUpdateDisplayOptionsModal() }
        { renderGenreModal() }
        { renderConfirmRemoveGenreModal() }
      </>
    )
  }

  if (isLoading) {
    return <LoadingSpinner/>
  }

  return (
    <AdminContent header={renderHeader()} body={renderContent()}/>
  );
}

export default ClientPortalSettingsPage;
import React, { useState, useContext, useEffect, useRef } from 'react';
import { useNavigate, useLocation, Outlet, Link } from 'react-router-dom';
import { Row, Col, Layout, Menu, Grid, Drawer } from 'antd';
import moment from 'moment';
import AppContext from '../../app/context';
import SiteHeader from '../header';
import NavMenu from '../navMenu';
import './siteLayout.style.scss';
import djpcLogoVertical from '../../images/djpc-logo-vertical.png';
import FloatingContainer from '../../components/floatingContainer'
import { logout } from '../../api';

import { UserOutlined, LaptopOutlined, NotificationOutlined } from '@ant-design/icons';
import { FiMenu, FiYoutube, FiMail } from "react-icons/fi";
import { MdOutlineClose } from "react-icons/md";
import { getDeviceId } from '../../helpers/deviceHelper';
import { removeBetaFeatures } from '../../helpers/betaFeatureHelper';
import djpcConstants from '../../constants/djpc';

const { SubMenu } = Menu;
const { Header, Content, Footer, Sider } = Layout;

const SiteLayout = props => {
  let date = new Date();
  let year = date.getFullYear();

  const { auth, setAuth, setCurrentEvent } = useContext(AppContext);
  const user = auth.user ? auth.user : {}

  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();
  const navigate = useNavigate();
  const location = useLocation();
  const siteLayoutRef = useRef();

  const [isDrawerVisible, setDrawerVisible] = useState(false);

  useEffect(() => {
    siteLayoutRef.current.scrollTo(0,0)
  }, [location.pathname]);

  const onNavigate = (path) => {
    navigate(path)
    setDrawerVisible(false)
  }

  const onLogout = async () => {
    try {
      if (user.user_id) {
        await logout(user.user_id, getDeviceId())
      }
      setDrawerVisible(false)
      setAuth({})
      setCurrentEvent({})
      removeBetaFeatures()
    } catch (error) {
      console.log(error)
    }
  }


  const renderMobileMenuIcon = () => {
    if (!screens.md) {
      return (
        <>
        <Col flex={1}></Col>
        <Col>
          <div className="display-flex" onClick={() => setDrawerVisible(true)}>
            <FiMenu size={24}/>
          </div>
        </Col>
        </>
      )
    }
  }

  const renderAuthButtons = () => {
    if (auth.isAuthenticated) {
      return (
        <>
          <div className="blue-link mr-30" onClick={() => onLogout()}>Sign Out</div> 
          <button className="page-title-button" onClick={() => onNavigate("/login")}>Go to Dashboard</button>
        </>
      )
    } else {
      return (
        <>
          <div className="blue-link mr-30" onClick={() => onNavigate("/login")}>Sign In</div> 
          <button className="page-title-button" onClick={() => onNavigate("/signup")}>Start Free Trial</button>
        </>
      )
    }
  }

  const renderHeaderButtons = () => {
    if (screens.md) {
      return (
        <>
        <Col flex={1}></Col>
         <Col>
            <Link className="site-layout--menu-item" to="/features">Features</Link>
            <Link className="site-layout--menu-item" to="/pricing">Pricing</Link>
            <Link className="site-layout--menu-item mr-30 pr-30" style={{ borderRight: "1px solid #CCC"}} to="/contact">Contact</Link>
            { renderAuthButtons() }
          </Col>
        </>
      )
    }
  }

  // const renderDrawerAuthButtons = () => {
  //   if (auth.isAuthenticated) {
  //     return (
  //       <>
  //         <div className="blue-link mr-30" onClick={() => onLogout()}>Sign Out</div> 
  //         <button className="page-title-button" onClick={() => navigate("/login")}>Go to Dashboard</button>
  //       </>
  //     )
  //   } else {
  //     return (
  //       <>
  //         <div className="blue-link mr-30" onClick={() => navigate("/login")}>Sign In</div> 
  //         <button className="page-title-button" onClick={() => navigate("/signup")}>Start Free Trial</button>
  //       </>
  //     )
  //   }
  // }

  const renderDrawerButtons = () => {
    if (auth.isAuthenticated) {
      return (
        <Col xs={24}>
          <div className="mobile-profile mb-10">
            <div className="mobile-profile-circle">
              <UserOutlined />
            </div>
            <div className="fw-700 mt-10">{ auth.user && auth.user.first_name } {auth.user && auth.user.last_name }</div>
            <button className="page-title-button mt-20" style={{ width: "100% "}} onClick={() => onNavigate("/login")}>Go to Dashboard</button> 
            <div className="blue-link mt-20" onClick={() => onLogout()}>Sign Out</div> 
          </div>
          <div className="pb-30 pt-20">
            <div className="site-layout--menu-item" onClick={() => onNavigate("/features")}>Features</div>
          </div>
          <div className="pb-30">
            <div className="site-layout--menu-item" onClick={() => onNavigate("/pricing")}>Pricing</div>
          </div>
          <div className="pb-30">
            <div className="site-layout--menu-item" onClick={() => onNavigate("/contact")}>Contact</div>
          </div>
          <div className="b-border"></div>
        </Col>
      )
    } else {
      return (
        <>
          <Col xs={24}>
            <div className="pb-30">
              <div className="site-layout--menu-item" onClick={() => onNavigate("/features")}>Features</div>
            </div>
            <div className="pb-30">
              <div className="site-layout--menu-item" onClick={() => onNavigate("/pricing")}>Pricing</div>
            </div>
            <div className="pb-30">
              <div className="site-layout--menu-item" onClick={() => onNavigate("/contact")}>Contact</div>
            </div>
            <div className="b-border"></div>
            <div className="blue-link pv-30" onClick={() => onNavigate("/login")}>Sign In</div> 
            <button className="page-title-button" style={{ width: "100% "}} onClick={() => onNavigate("/signup")}>Start Free Trial</button> 
          </Col>
        </>
      )
    }
  }

  const renderHeader = () => {
    return (
      <Row align="middle" gutter={[10,10]} className="flex-1">
        <Col>
          <div className="user-layout--logo" onClick={() => navigate("/")}>
            <img src={djpcLogoVertical}/>
          </div>
        </Col>
        { renderMobileMenuIcon() }
        { renderHeaderButtons() }
      </Row>
    )
  }

  const renderFooter = () => {
    return (
      <>
        <FloatingContainer className="bg-blue-gradient small-center" verticalPadding={50} maxWidth={1000}>
          <Row gutter={[10,30]} className="ph-15">
            <Col xs={24} md={8}>
              <div className="fw-700 fs-18 mb-10 c-white">EXPLORE</div>
              <div><Link className="fw-500 fs-16 c-white" to="/login">Sign In</Link></div>
              <div><Link className="fw-500 fs-16 c-white" to="/features">Features</Link></div>
              <div><Link className="fw-500 fs-16 c-white" to="/pricing">Pricing</Link></div>
            </Col>
            <Col xs={24} md={8}>
              <div className="fw-700 fs-18 mb-10 c-white">COMPANY</div>
              <div><Link className="fw-500 fs-16 c-white" to="/contact">Contact Us</Link></div>
              <div><Link className="fw-500 fs-16 c-white" to="/support">Support</Link></div>
              <div><Link className="fw-500 fs-16 c-white" to="/terms">Terms of Service</Link></div>
              <div><Link className="fw-500 fs-16 c-white" to="/privacy">Privacy Policy</Link></div>
            </Col>
            <Col xs={24} md={8}>
              <div className="fw-700 fs-18 mb-10 c-white">CONTACT</div>
              {/* <div className="fw-500 fs-16 c-white">{ djpcConstants.PHONE_NUMBER }</div> */}
              <div className="fw-500 fs-16 c-white">{ djpcConstants.INFO_EMAIL }</div>
            </Col>
          </Row>
        </FloatingContainer>
        <FloatingContainer className="bg-blue-gradient" verticalPadding={0} maxWidth={1000}>
          <Row align="middle" gutter={[10,30]} className="ph-15 pv-15" style={{ borderTop: '1px solid #768bf7'}}>
            <Col xs={16} md={16}>
              <div className="fw-500 fs-14 c-white">© {moment().format("YYYY")} DJ Planning Center LLC</div>
            </Col>
            <Col xs={8} md={8}>
              <div className="display-flex" style={{ justifyContent: 'flex-end'}}>
                <div className="flex-0 mr-10">
                  <a href="mailto:info@djplanningcenter.com"><FiMail size={24} color="#FFF"/></a>
                </div>
                <div className="flex-0">
                  <a href="https://www.youtube.com/channel/UCOQZutfrBzKAQBNwg3F1vJw"><FiYoutube size={24} color="#FFF"/></a>
                </div>
              </div>
            </Col>
          </Row>
        </FloatingContainer>
      </>
    )
  }

  return (
    <div className="site-layout" ref={siteLayoutRef}>
      <div className="site-layout--header">
        { renderHeader() }
      </div>
      <div className="site-layout--body">
        <div className="flex-1 site-layout--content">
          <Outlet/>
        </div>
        <div className="flex-0">
          { renderFooter() }
        </div>
      </div>
      <Drawer  placement="right" width={240} closable={false} onClose={() => setDrawerVisible(false)} visible={isDrawerVisible} bodyStyle={{ padding: 0 }}>
        <Row align="middle" className="p-20">
          <Col flex={1}>
            {/* <div className="fs-20 fw-700">
              Menu
            </div> */}
          </Col>
          <Col>
            <div className="display-flex" onClick={() => setDrawerVisible(false)}><MdOutlineClose size={30} color={"#CCC"}/></div>
          </Col>
        </Row>
        {/* { auth.isAuthenticated && (
          <div className="mobile-profile mb-10">
            <div className="mobile-profile-circle">
              <UserOutlined />
            </div>
            <div className="fw-700 mt-10">{ auth.user && auth.user.first_name } {auth.user && auth.user.last_name }</div>
          </div>
        )} */}
        <Row className="ph-15 pb-15 text-center">
          { renderDrawerButtons() }
        </Row>
        {/* { renderMenu() } */}
      </Drawer>
    </div>
  );
};

export default SiteLayout;

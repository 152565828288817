import React, { useEffect, useState, useContext } from "react";
import { Row, Col, Dropdown, Menu, Switch, Tooltip } from 'antd';
import { useNavigate } from 'react-router-dom';
import { isArray, isEmpty } from 'lodash';
import AdminContent from '../../components/adminContent';
import AppContext from '../../app/context';
import LoadingSpinner from '../../components/loading';
import useApi from '../../hooks/useApi';
import ShareModal from '../../modals/shareModal';
import { 
  timelineHasSongs, 
  getSpecialEventSongSummary
} from '../../helpers/specialEventHelper';
import { getFormattedTime } from '../../helpers/timelineHelper';
import { getV2EventSpecialEvents, getEventTimeline, getClientEvent } from '../../api';
import { MdOutlineIosShare, MdOutlineEdit, MdOutlineKeyboardArrowDown, MdOutlineInfo } from "react-icons/md";
import { FiEye, FiDownload } from "react-icons/fi";
import FloatingContainer from '../../components/floatingContainer'
import emptyStateImage from '../../images/empty-document-icon.png';
import EventPermissions from '../../constants/eventPermissions';
import { hasEventPermission } from '../../helpers/permissionHelper';
import { isEventLocked } from '../../helpers/eventHelper';
import useDocumentTitle from '../../hooks/useDocumentTitle';

const BASE_URL = process.env.REACT_APP_BASE_URL;

const TimelinesPage = () => {

  const [isLoading, setLoading] = useState(true);
  const [event, setEvent] = useState({})
  const [eventTimeline, setEventTimeline] = useState([]);
  const [specialEventSections, setSpecialEventSections] = useState([]);
  const [isShareTimelineModalVisible, setShareTimelineModalVisible] = useState(false);
  const [showMusic, setShowMusic] = useState(true);
  const [eventLocked, setEventLocked] = useState(false);
  const [canEdit, setCanEdit] = useState(false);
  const [accountSettings, setAccountSettings] = useState({});

  useDocumentTitle("Timelines")
  const { auth } = useContext(AppContext);
  const [sendRequest] = useApi()

  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
    refreshPage()
  }, []);

  const refreshPage = async () => {
    try {
      const eventResults = await sendRequest(getClientEvent(auth.user.user_id, auth.user.event_id))
      setEvent(eventResults)
      
      const eventTimelineResults = await sendRequest(getEventTimeline(eventResults.event_id));
      setEventTimeline(eventTimelineResults.content ?? [])
      setAccountSettings(eventTimelineResults.account_settings)

      const eventSpecialEventsResults = await sendRequest(getV2EventSpecialEvents(eventResults.event_id));
      setSpecialEventSections(eventSpecialEventsResults)

      setEventLocked(isEventLocked(eventResults, auth.user))
      setCanEdit(hasEventPermission(auth.user, EventPermissions.EDIT_TIMELINE))
    } finally {
      setLoading(false)
    }
  }

  const downloadPDF = async () => {
    window.open(`${BASE_URL}/pdfs/event/${event.event_id}/timeline`, "_blank")
  }

  const actionMenu = () => {
    return (
      <Menu>
        <Menu.Item>
          <div onClick={() => navigate(`/event/${event.event_id}/timeline`)}>
            <FiEye style={{ marginRight: 8}}/> Preview
          </div>
        </Menu.Item>
        <Menu.Item>
          <div onClick={() => setShareTimelineModalVisible(true)}>
            <MdOutlineIosShare style={{ marginRight: 8}}/> Share
          </div>
        </Menu.Item>
        <Menu.Item>
          <div onClick={() => downloadPDF()}>
            <FiDownload style={{ marginRight: 8}}/> View / Download PDF
          </div>
        </Menu.Item>
      </Menu>
    )
  };

  const renderSection = (section, index) => {
    const timelineEvents = section.items ?? []

    return (
      <div className="shadow-card mb-20 pb-10" key={index}>
        <Row align="middle" className="mh-20 pv-15 b-border">
          <Col flex={1}>
            <div className="fs-18 fw-700">{section.name}</div>
          </Col>
        </Row>
        <div className="ph-20">
          { timelineEvents.map((x,i) => renderTimelineItem(x,i,timelineEvents))}
          { timelineEvents.length == 0 && (
            <div className="p-30 text-center c-text-gray">No items have been added to this section yet.</div>
          )}
        </div>
      </div>
    )
  }

  const renderTimelineItem = (event, index, timelineEvents) => {
    const borderClass = (index + 1) < timelineEvents.length ? "b-border" : ""
    return (
      <div className={`${borderClass} pv-10`} key={index}>
        <div className={`display-flex`}>
          <div className="timeline-preview-row--time">
            <div className="fw-700">{ getFormattedTime(event.time, accountSettings) }</div>
          </div>
          <div className="timeline-preview-row--event">
            <div className="fw-600">{ event.special_event_name }</div>
            <div className="fw-500 c-text-gray line-1-2 mt-5">{ event.notes }</div>
          </div>
        </div>
        { showMusic && renderSongsOrPlaylist(event) }
      </div>
    )
  }

  const renderSongsOrPlaylist = (item) => {
    const songSummary = getSpecialEventSongSummary(item.event_special_event_id, specialEventSections)

    if (songSummary.found && songSummary.songs.length > 0) {
      if (songSummary.is_playlist) {
          return (
            <div className="bg-gray pv-5 ph-15 radius-8 mt-5">
              <div className="b-border pb-2 mb-5">
                <span className="fs-12 fw-600 line-1-2">Playlist</span>
                <span className="fs-12 fw-300 line-1-2"> ({songSummary.songs.length} {songSummary.songs.length == 1 ? "song" : "songs"})</span>
              </div>
              <div className="">
                { songSummary.songs.map((x,i) => renderEventSong(x,i)) }
              </div>
            </div>
          )
        } else {
          return (
            <div className="bg-gray pv-5 ph-15 radius-8 mt-5">
              { songSummary.songs.map((x,i) => renderEventSong(x,i)) }
            </div>
          )
        }
    }
  }

  const renderEventSong = (song, index) => {
    return (
      <div key={index}>
        <div>
          <span className="fs-12 fw-600 line-1-2">"{song.title}"</span>
          <span className="fs-12 fw-400 line-1-2"> - {song.artist}</span>
        </div>
        { !isEmpty(song.notes) && (
          <div className="fs-12 ml-20 c-text-gray">Note: {song.notes}</div>
        )}
      </div>
    )
  }

  const renderHeader = () => {
    return (
      <div className="fs-24 fw-700 p-20">Timeline</div>
    )
  }

  const renderSections = () => {
    if (!isArray(eventTimeline) || eventTimeline.length == 0) {
      return (
        <div className="shadow-card ph-20 pv-50 text-center">
          <img src={emptyStateImage} width="200"/>
          <div className="fs-14 c-text-gray mt-30">No items have been added to this timeline yet.</div>
          { canEdit && !eventLocked && (
            <button className="page-title-button mt-20" onClick={() => navigate(`/client/events/${event.event_id}/timeline`)}>Edit Timeline</button>
          )}
        </div>
      )
    }
    return (
      <div className="">
        { isArray(eventTimeline) && eventTimeline.map((x,i) => renderSection(x,i))}
      </div>
    )
  }

  const renderShareTimlineModal = () => {
    return (
      <ShareModal
        isVisible={isShareTimelineModalVisible}
        setVisible={setShareTimelineModalVisible}
        title="Share Timeline"
        link={`/event/${event.event_id}/timeline`}
      />
    )
  }

  const renderContent = () => {
    if (isLoading) {
      return <LoadingSpinner/>
    }
    return (
      <>
        <div className="toolbar-container">
          { canEdit && !eventLocked && (
            <div className="toolbar-button" onClick={() => navigate(`/client/events/${event.event_id}/timeline`)}>
              <MdOutlineEdit style={{ fontSize: 20, color: '#536DFE', marginRight: 8}}/> Edit
            </div>
          )}
          <Dropdown overlay={actionMenu()} placement="bottomRight" trigger="click">
            <div className="toolbar-button">
              Actions <MdOutlineKeyboardArrowDown style={{ fontSize: 20, color: '#536DFE', marginLeft: 8}}/>
            </div>
          </Dropdown>
        </div>
        <FloatingContainer className="ph-20" verticalPadding={20} maxWidth={600}>
          { eventLocked && canEdit && (
            <div className="message-box mb-15">
              <div className="message-icon"><MdOutlineInfo/></div>
              <div className="message-text">Changes are no longer allowed</div>
            </div>
          )}
          { timelineHasSongs(eventTimeline, specialEventSections) && (
            <div className="text-right mb-10">
              <Switch checked={showMusic} onChange={(value) => setShowMusic(value)} />
              <Tooltip title="Music is automatically populated from the music section.">
                <span className="ml-10 mr-5 fw-600 cursor-default">Show music</span>
              </Tooltip>
            </div>
          )}
          { renderSections() }
        </FloatingContainer>
        { renderShareTimlineModal( )}
      </>
    )
  }

  return (
    <AdminContent header={renderHeader()} body={renderContent()}/>
  );
}

export default TimelinesPage;

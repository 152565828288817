import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { Row, Col, Form, Modal, Dropdown, Menu, notification } from 'antd';
import { getPackages, createPackage, deletePackage } from '../../../api';
import LoadingSpinner from '../../../components/loading';
import AdminContent from '../../../components/adminContent';
import useApi from '../../../hooks/useApi';
import useCurrency from '../../../hooks/useCurrency';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { 
  renderInputField
} from '../../../components/formFields'
import FloatingContainer from '../../../components/floatingContainer'
import { MdOutlineClose, MdOutlineMoreHoriz } from "react-icons/md";
import { FiGift } from "react-icons/fi";
import emptyStateImage from '../../../images/empty-state-icon.png';
import useDocumentTitle from '../../../hooks/useDocumentTitle';
import { formatCurrencyString } from '../../../helpers/contractHelper';

const PackagesPage = () => {

  const [isLoading, setLoading] = useState(true);
  const [packages, setPackages] = useState([]);
  const [isNewModalVisible, setNewModalVisible] = useState(false);
  const [isConfirmRemoveVisible, setConfirmRemoveVisible] = useState(false);
  const [isRemoveErrorModalVisible, setRemoveErrorModalVisible] = useState(false);
  const [selectedPackage, setSelectedPackage] = useState({});

  useDocumentTitle("Packages")
  const [sendRequest] = useApi()
  const [currency, _] = useCurrency()
  const navigate = useNavigate();

  const [form] = Form.useForm();

  useEffect(() => {
    window.scrollTo(0, 0);
    refreshPage()
  }, []);

  const refreshPage = async () => {
    try {
      const packageResults = await sendRequest(getPackages());
      setPackages(packageResults);
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  const menu = (item) => {
    return (
      <Menu>
        <Menu.Item>
          <div onClick={() => handleEdit(item)}>
            <EditOutlined style={{ marginRight: 8}}/> Edit
          </div>
        </Menu.Item>
        {/* <Menu.Item>
          <div onClick={() => handleDuplicate(item)}>
            <CopyOutlined style={{ marginRight: 8}}/> Duplicate
          </div>
        </Menu.Item> */}
        <Menu.Item>
          <div onClick={() => handleDelete(item)}>
            <DeleteOutlined style={{ marginRight: 8}}/> Delete
          </div>
        </Menu.Item>
      </Menu>
    )
  };
  
  const onNewClick = () => {
    form.setFieldsValue({ package_name: "" })
    setNewModalVisible(true);
  }

  const handleCancel = () => {
    setNewModalVisible(false);
  };

  const handleEdit = (accountPackage) => {
    navigate(`/admin/packages/${accountPackage.package_id}/edit`)
  };

  const handleDelete = (accountPackage) => {
    setSelectedPackage(accountPackage)
    setConfirmRemoveVisible(true)
  };

  const confirmDelete = async () => {
    try {
      const results = await sendRequest(deletePackage(selectedPackage.package_id))
      if (results.status == "in-use") {
        setRemoveErrorModalVisible(true)
        return
      }
      await refreshPage()
    } catch {
      notification.error({
        message: 'Error!',
        description: 'There was a problem deleting this add-on.',
        duration: 3
      });
    } finally {
      setConfirmRemoveVisible(false)
    }
  }

  const onSubmit = async (values) => {
    try {
      const body = {
        package_name: values["package_name"],
        active: true
      }
      const results = await sendRequest(createPackage(body))
      navigate(`/admin/packages/${results.package_id}/edit`)
    } catch {}
  }
  
  const renderHeader = () => {
    return (
      <Row align="middle" className="p-20">
        <Col flex={1}>
          <div className="fs-24 fw-700">Packages</div>
        </Col>
        <Col>
          { packages.length > 0 && (
            <button className="page-title-button" onClick={onNewClick}>New Package</button>
          )}
        </Col>
      </Row>
    )
  }

  const renderPackage = (accountPackage, index) => {
    return (
      <div key={index} className="flex-row p-10 b-border">
        <div onClick={() => handleEdit(accountPackage)}>
          <div className="card-icon"><FiGift/></div>
        </div>
        <div className="flex-col justify-content-center flex-1 mh-15" onClick={() => handleEdit(accountPackage)}>
          <div className="fs-14 fw-700 line-1-2">{accountPackage.package_name}</div>
          <div className="fs-14 fw-500 c-text-gray mt-2">
            { formatCurrencyString(accountPackage.price, currency.code)}
          </div>
        </div>
        <div className="display-flex flex-middle mr-5">
          <Dropdown overlay={menu(accountPackage)} placement="bottomRight" trigger="click">
            <div className="dots-container">
              <MdOutlineMoreHoriz style={{ fontSize: 24, color: '#999'}}/>
            </div>
          </Dropdown>
        </div>
      </div>
    )
  }

  const renderPackagesBox = () => {
    return (
      <div className="shadow-card">
        { packages.map((x,i) => renderPackage(x,i)) }
      </div>
    )
  }

  const renderPackages = () => {
    if (packages.length == 0) {
      return (
        <div className="shadow-card ph-20 pv-50 text-center">
          <div>
            <img src={emptyStateImage} width={250}/>
          </div>
          <div className="fs-18 fw-700 mt-30">
            No packages yet
          </div>
          <div className="fs-14 fw-500 c-text-gray">
            Create a package to get started!
          </div>
          <button className="primary-button mt-30" style={{ width: 250 }} onClick={onNewClick}>Create Package</button>
        </div>
      )
    } else {
      return renderPackagesBox()
    }
  }

  const renderContent = () => {
    if (isLoading) {
      return <LoadingSpinner/>
    }
    return (
      <FloatingContainer className="ph-20" verticalPadding={20} maxWidth={800}>
        { renderPackages() }
        { renderModal() }
        { renderConfirmRemoveModal() }
        { renderRemoveErrorModal() }
      </FloatingContainer>
    )
  }

  const renderModal = () => {
    return (
      <Modal visible={isNewModalVisible} footer={null} closable={false} wrapClassName="rounded-modal">
        <Row align="middle">
          <Col flex={1}>
            <div className="fw-700 fs-18">New Package</div>
          </Col>
          <Col>
            <div className="display-flex" onClick={handleCancel}><MdOutlineClose size={30} color={"#CCC"}/></div>
          </Col>
        </Row>
        <Form form={form} layout="vertical" name="new-questionnaire" onFinish={onSubmit}>
          <Row gutter={[10,10]} className="mt-15">
            <Col xs={24}>
              {renderInputField("Package Name", "package_name", true)}
            </Col>
          </Row>
          <div className="admin-modal-footer">
            <button className="primary-button" type="submit" onClick={() => {}}>Continue</button>
            <div className="text-center mt-15">
              <div className="blue-link" onClick={handleCancel}>Cancel</div>
            </div>
          </div>
        </Form>
      </Modal>
    )
  }

  const renderConfirmRemoveModal = () => {
    return (
      <Modal visible={isConfirmRemoveVisible} closable={false} footer={null} width={400} wrapClassName="rounded-modal">
        <div className="text-right" onClick={() => setConfirmRemoveVisible(false)}><MdOutlineClose size={30} color={"#CCC"}/></div>
        <div className="mt-10">
          <div className="fw-700 fs-20 text-center">Remove Package</div>
          <div className="fw-500 fs-14 mt-20 mb-20 text-center">Are you sure you would like to remove <span className="fw-700">{selectedPackage.package_name}</span>? This action cannot be undone.</div>
          <button className="primary-button warning" type="button" onClick={() => confirmDelete()}>Remove Package</button>
          <div className="text-center mt-15">
            <div className="blue-link" onClick={() => setConfirmRemoveVisible(false)}>Cancel</div>
          </div>
        </div>
      </Modal>
    )
  }

  const renderRemoveErrorModal = () => {
    return (
      <Modal visible={isRemoveErrorModalVisible} closable={false} footer={null} width={400} wrapClassName="rounded-modal">
        <div className="text-right" onClick={() => setRemoveErrorModalVisible(false)}><MdOutlineClose size={30} color={"#CCC"}/></div>
        <div className="mt-10">
          <div className="fw-700 fs-20 text-center">Cannot Remove Package</div>
          <div className="fw-500 fs-14 mt-20 mb-20 text-center">This package is assigned to one or more events. All references to this package must be removed before continuing.</div>
          <button className="primary-button" type="button" onClick={() => setRemoveErrorModalVisible()}>OK</button>
        </div>
      </Modal>
    )
  }

  return (
    <AdminContent header={renderHeader()} body={renderContent()}/>
  );
}

export default PackagesPage;

import React, { useEffect, useState } from "react";
import { Row, Col, Table, Tag } from 'antd';
import { isNull } from 'lodash';
import { useNavigate } from 'react-router';
import useApi from '../../hooks/useApi';
import { getSystemRevenue } from '../../api';
import LoadingSpinner from '../../components/loading';
import AdminContent from '../../components/adminContent';
import FloatingContainer from '../../components/floatingContainer'
import { formatCurrencyString } from '../../helpers/contractHelper'

const RevenuePage = () => {

  const [isLoading, setLoading] = useState(true);
  const [accounts, setAccounts] = useState([]);
  const [total, setTotal] = useState(0);
  const [activeTotal, setActiveTotal] = useState(0);
  const [pastDueTotal, setPastDueTotal] = useState(0);
  const [feesTotal, setFeesTotal] = useState(0);
  const [netTotal, setNetTotal] = useState(0);

  const navigate = useNavigate();
  const [sendRequest] = useApi()

  const activeColumns = [
    {
      title: 'Account ID',
      dataIndex: 'account_id',
      key: 'account_id',
      render: (text, record) => text
    },
    {
      title: 'Company Name',
      dataIndex: 'company_name',
      key: 'company_name',
      render: (text) => text
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (text, record) => <Tag color={text == "ACTIVE" ? "green" : "red"}>{text}</Tag>,
    },
    {
      title: 'Price',
      dataIndex: 'price',
      key: 'price',
      render: (text) => !isNull(text) ? formatCurrencyString(text) : "--"
    },
    {
      title: 'Fee',
      dataIndex: 'fee',
      key: 'fee',
      render: (text) => !isNull(text) ? formatCurrencyString(text) : "--"
    },
    {
      title: 'Net',
      dataIndex: 'net',
      key: 'net',
      render: (text) => !isNull(text) ? formatCurrencyString(text) : "--"
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
    refreshPage()
  }, []);

  const refreshPage = async () => {
    try {
      const results = await sendRequest(getSystemRevenue());
      setAccounts(results.accounts)
      setTotal(results.total)
      setActiveTotal(results.active_total)
      setPastDueTotal(results.past_due_total)
      setFeesTotal(results.fee_total)
      setNetTotal(results.net_total)
    } finally {
      setLoading(false)
    }
  }

  const selectAccount = (account) => {
    navigate(`/system/accounts/${account.account_id}`)
  }

  const renderHeader = () => {
    return (
      <Row align="middle" className="p-20">
        <Col flex={1}>
          <div className="admin-page-title">Revenue</div>
        </Col>
      </Row>
    )
  }

  const renderContent = () => {

    const data = accounts.map((x, i) => {
      return {
        ...x,
        key: i
      }
    });

    return (
      <FloatingContainer className="ph-20" verticalPadding={20} maxWidth={1000}>
        <Row align="middle" gutter={[15,15]}>
          <Col xs={24}>
            <div className="shadow-card p-30 text-center">
              <div className="fs-40 fw-700">{formatCurrencyString(total)}</div>
              <div className="fs-16 fw-400">GROSS MONTHLY INCOME</div>
            </div>
          </Col>
          <Col xs={24} lg={12}>
            <div className="shadow-card p-30 text-center">
              <div className="fs-40 fw-700">{formatCurrencyString(activeTotal)}</div>
              <div className="fs-16 fw-400">ACTIVE</div>
            </div>
          </Col>
          <Col xs={24} lg={12}>
            <div className="shadow-card p-30 text-center">
              <div className="fs-40 fw-700">{formatCurrencyString(pastDueTotal)}</div>
              <div className="fs-16 fw-400">PAST DUE</div>
            </div>
          </Col>
        </Row>
        <div className="t-border mv-30"></div>
        <Row align="middle" gutter={[15,15]}>
          <Col xs={24}>
            <div className="shadow-card p-30">
              <Row>
                <Col xs={12}>
                  <div className="fs-14 fw-400">Gross</div>
                  <div className="fs-14 fw-400">Fees</div>
                  <div className="fs-14 fw-700">Net Profit</div>
                </Col>
                <Col xs={12} className="text-right">
                  <div className="fs-14">{formatCurrencyString(total)}</div>
                  <div className="fs-14">- {formatCurrencyString(feesTotal)}</div>
                  <div className="fs-14 fw-700">{formatCurrencyString(netTotal)}</div>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
        <div className="t-border mv-30"></div>
        <div className="fs-18 fw-700 mb-10 ml-5">Subscriptions ({accounts.length})</div>
        <div className="shadow-card">
          <Table 
            columns={activeColumns} 
            dataSource={data}
            pagination={false}
            onRow={(record, rowIndex) => {
              return {
                onClick: e => selectAccount(record)
              };
            }}
          />
        </div>
      </FloatingContainer>
    )
  }

  if (isLoading) {
    return <LoadingSpinner/>
  }

  return (
    <AdminContent header={renderHeader()} body={renderContent()}/>
  );
}

export default RevenuePage;

import React from "react";
import { Document, Page, Text, View, StyleSheet, Font } from '@react-pdf/renderer';
import { isEmpty, isNull, isArray } from "lodash";
import openSansRegular from '../fonts/OpenSans/OpenSans-Regular.ttf'
import openSansMedium from '../fonts/OpenSans/OpenSans-Medium.ttf'
import openSansSemiBold from '../fonts/OpenSans/OpenSans-SemiBold.ttf'
import openSansItalic from '../fonts/OpenSans/OpenSans-Italic.ttf'
import openSansBold from '../fonts/OpenSans/OpenSans-Bold.ttf'
import openSansBoldItalic from '../fonts/OpenSans/OpenSans-BoldItalic.ttf'
import { getFormattedTime } from '../helpers/timelineHelper';
import { formatEventDateLong } from '../helpers/dateHelper';
import { 
  getSpecialEventSongSummary
} from '../helpers/specialEventHelper';

Font.register({ family: 'OpenSans', fonts: [
  { src: openSansRegular },
  { src: openSansMedium, fontWeight: 500 },
  { src: openSansSemiBold, fontWeight: 600 },
  { src: openSansItalic, fontStyle: 'italic' },
  { src: openSansBold, fontWeight: 700 },
  { src: openSansBoldItalic, fontStyle: 'italic', fontWeight: 700 },
 ]});

const TimelinePDF = (props) => {
  const { event, showMusic = true } = props
  const { timeline = [], special_event_sections = [], event_name = "", event_date = null, account_settings = {} } = event

  const styles = StyleSheet.create({
    page: {
      paddingTop: 35,
      paddingBottom: 65,
      paddingHorizontal: 35
    },
    row: {
      display: "flex",
      flexDirection: 'row'
    },
    col: {
      flex: 1
    },
    section: {
      marginBottom: 20
    },
    sectionContainer: {
      marginBottom: 20
    },
    sectionHeader: {
      borderBottom: "3px solid #CCC",
      marginBottom: 5
    },
    sectionHeaderText: {
      fontSize: 12,
      fontFamily: "OpenSans",
      fontWeight: 700,
      paddingHorizontal: 5,
      paddingBottom: 5
    },
    header: {
      marginBottom: 20
    },
    headerText: {
      fontSize: 12,
      fontFamily: "OpenSans",
      fontWeight: 700,
      textAlign: "center"
    },
    subTitleText: {
      fontSize: 12,
      fontFamily: "OpenSans",
      fontWeight: 700,
      textAlign: "center",
      marginTop: 10
    },
    dateText: {
      fontSize: 10,
      fontFamily: "OpenSans",
      textAlign: "center"
    },
    specialEventRow: {
      borderBottom: "1px solid #e8e8e8",
      marginLeft: 15,
      paddingVertical: 10
    },
    specialEventName: {
      fontSize: 10,
      fontFamily: "OpenSans",
      fontWeight: 700,
      paddingHorizontal: 5,
      marginBottom: 2
    },
    songText: {
      fontSize: 8,
      fontFamily: "OpenSans",
      fontWeight: 600,
      paddingHorizontal: 5,
      paddingTop: 1
    },
    songArtist: {
      fontSize: 8,
      fontFamily: "OpenSans"
    },
    songNotesText: {
      fontSize: 8,
      fontFamily: "OpenSans",
      marginLeft: 20,
      marginTop: 2
    },
    notesText: {
      fontSize: 8,
      fontFamily: "OpenSans",
      marginLeft: 5,
    },
    emptyTextContainer: {
      backgroundColor: "#F7F7F7",
      paddingVertical: 20,
      paddingHorizontal: 10,
      borderRadius: 4,
      marginTop: 5
    },
    emptyText: {
      fontSize: 10,
      fontFamily: "OpenSans",
      color: "#777777",
      paddingHorizontal: 5,
      textAlign: "center"
    },
    timeCol: {
      width: 60
    },
    songContainer: {
      backgroundColor: "#F7F7F7",
      paddingVertical: 4,
      paddingHorizontal: 5,
      borderRadius: 4,
      marginTop: 5
    }
  });

  const renderDocumentSong = (song, index, songs) => {
    const marginBottom = index < songs.length - 1 ? 4 : 0
    return (
      <View style={{ marginBottom: marginBottom }} key={index}>
        <Text style={styles.songText}>"{song.title}" - <Text style={styles.songArtist}>{song.artist}</Text></Text>
        { !isEmpty(song.notes) && (
          <Text style={styles.songNotesText}>Note: {song.notes}</Text>
        )}
      </View>
    )
  }

  const renderSongsOrPlaylist = (item) => {
    const songSummary = getSpecialEventSongSummary(item.event_special_event_id, special_event_sections)

    if (songSummary.found && songSummary.songs.length > 0) {
      if (songSummary.is_playlist) {
          return (
            <View style={styles.songContainer}>
              { songSummary.songs.map((x,i) => renderDocumentSong(x, i, songSummary.songs))}
            </View>
          )
        } else {
          return (
            <View style={styles.songContainer}>
              { songSummary.songs.map((x,i) => renderDocumentSong(x, i, songSummary.songs))}
            </View>
          )
        }
    }
  }

  const renderDocumentSpecialEvent = (specialEvent, index) => {
    return (
      <View style={styles.specialEventRow} key={index}>
        <View style={styles.row}>
          <View style={styles.timeCol}>
            <Text style={styles.specialEventName}>{ getFormattedTime(specialEvent.time, account_settings) }</Text>
          </View>
          <View style={styles.col}>
            <Text style={styles.specialEventName}>{ specialEvent.special_event_name }</Text>
            { !isEmpty(specialEvent.notes) && (
              <Text style={styles.notesText}>{specialEvent.notes}</Text>
            )}
          </View>
        </View>
        { showMusic && renderSongsOrPlaylist(specialEvent) }
      </View>
    )
  }

  const renderSection = (section, index) => {
    const timelineEvents = section.items ?? []

    return (
      <View style={styles.sectionContainer} key={index}>
        <View style={styles.sectionHeader}>
          <Text style={styles.sectionHeaderText}>{ section.name }</Text>
        </View>
        { timelineEvents.map((x,i) => renderDocumentSpecialEvent(x,i))}
        { timelineEvents.length == 0 && (
          <View style={styles.emptyTextContainer}>
            <Text style={[styles.emptyText]}>No items have been added to this section.</Text>
          </View>
        )}
      </View>
    )
  }

  const renderDocument = () => {
    const eventTimeline = isArray(timeline.content) ? timeline.content : []
    return (
      <Document>
        <Page size="A4" style={styles.page}>
          <View style={styles.header}>
            <Text style={styles.headerText}>{ event_name }</Text>
            { !isNull(event_date) && (
              <Text style={styles.dateText}>{ formatEventDateLong(event_date, account_settings) }</Text>
            )}
            <Text style={styles.subTitleText}>Timeline</Text>
          </View>
          { eventTimeline.map((x,i) => renderSection(x,i)) }
          { eventTimeline.length == 0 && (
            <View style={styles.emptyTextContainer}>
              <Text style={[styles.emptyText]}>No items have been added to this timeline.</Text>
            </View>
          )}
        </Page>
      </Document>
    )
  }

  return renderDocument()
}

export default TimelinePDF;

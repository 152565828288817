import React, { useEffect, useState, useContext } from "react";
import { useLocation } from 'react-router-dom';
import { isNull } from 'lodash';
import useApi from '../../hooks/useApi';
import AppContext from '../../app/context';
import LoadingSpinner from '../../components/loading';
import FloatingContainer from '../../components/floatingContainer'
import FadeDownWhileInView from '../../components/fadeDownWhileInView'
import SubscribeForm from '../../components/subscribeForm';
import { getAccountDetails } from '../../api';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const SubscribePage = () => {

  const [isLoading, setLoading] = useState(true);
  const [clientSecret, setClientSecret] = useState(null);
  const [accountDetails, setAccountDetails] = useState({});

  const { auth, setAuth } = useContext(AppContext);
  const location = useLocation();

  const [sendRequest] = useApi()

  useEffect(() => {
    window.scrollTo(0, 0);
    if (location.state.clientSecret) {
      setClientSecret(location.state.clientSecret)
    }
    refreshPage()
  }, []);

  const refreshPage = async () => {
    try {
      const accountDetailsResults = await sendRequest(getAccountDetails())
      setAccountDetails(accountDetailsResults)
    } finally {
      setLoading(false)
    }
  }

  const renderElements = () => {
    if (isNull(clientSecret)) {
      return null
    }

    const options = {
      clientSecret: clientSecret
    };
  
    return (
      <Elements stripe={stripePromise} options={options}>
        <SubscribeForm 
          returnPath="/admin/account/payment-method/complete" 
          clientSecret={clientSecret}
          accountDetails={accountDetails}
          name={auth.user.first_name + " " + auth.user.last_name}/>
      </Elements>
    );
  }

  const renderPage = () => {
    return (
      <FloatingContainer className="ph-20" verticalPadding={40} maxWidth={600}>
        <FadeDownWhileInView className="text-center c-text-gray">STEP 2 OF 2</FadeDownWhileInView>
        <FadeDownWhileInView className="fs-24 fw-700 text-center mb-30">Add payment method</FadeDownWhileInView>
        <FadeDownWhileInView className="shadow-card p-30">
          { renderElements() }
        </FadeDownWhileInView>
      </FloatingContainer>
    )
  }

  if (isLoading) {
    return <LoadingSpinner/>;
  }

  return renderPage();
}

export default SubscribePage;

import { useRef, useEffect } from 'react'

function useDocumentTitle(title, prevailOnUnmount = false) {
  const defaultTitle = useRef(document.title);

  useEffect(() => {
    document.title = `${title} | DJ Planning Center`;
  }, [title]);

  useEffect(() => () => {
    if (!prevailOnUnmount) {
      document.title = "DJ Planning Center"; //defaultTitle.current;
    }
  }, [])
}

export default useDocumentTitle
import React, { useEffect, useState } from "react";
import { Row, Col, Table, Modal } from 'antd';
import { isArray, cloneDeep, isEmpty } from 'lodash';
import { useNavigate } from 'react-router';
import useApi from '../../hooks/useApi';
import { getSystemBetaFeatures, createSystemAccountBetaFeature, deleteSystemAccountBetaFeature } from '../../api';
import LoadingSpinner from '../../components/loading';
import AdminContent from '../../components/adminContent';
import FloatingContainer from '../../components/floatingContainer'
import { MdOutlineClose } from "react-icons/md";

const BetaUserFeaturesPage = () => {

  const [isLoading, setLoading] = useState(true);
  const [betaFeatures, setBetaFeatures] = useState([]);
  const [isAddBetaAccountModalVisible, setAddBetaAccountModalVisible] = useState(false);
  const [selectedFeature, setSelectedFeature] = useState("");

  const navigate = useNavigate();
  const [sendRequest] = useApi()

  const columns = [
    {
      title: 'Account ID',
      dataIndex: 'account_id',
      key: 'account_id',
      width: "180px",
      render: (text, record) => text
    },
    {
      title: 'Company',
      dataIndex: 'company_name',
      key: 'company_name',
      render: (text, record) => `${record.company_name}`
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      width: "180px",
      render: (text, record) => <div className="blue-link" onClick={() => onRemoveBetaAccount(record)}>Remove</div>
    }
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
    refreshPage()
  }, []);

  const refreshPage = async () => {
    try {
      const betaFeatureResults = await sendRequest(getSystemBetaFeatures());
      setBetaFeatures(betaFeatureResults)
    } finally {
      setLoading(false)
    }
  }

  const onAddBetaUser = (feature) => {
    setSelectedFeature(feature)
    setAddBetaAccountModalVisible(true)
  }

  const onConfirmAddBetaAccount = async (account_id) => {
    try {
      await sendRequest(createSystemAccountBetaFeature({account_id: account_id, feature: selectedFeature}))
      await refreshPage()
    } catch {}
    finally {
      setAddBetaAccountModalVisible(false)
    }
  }

  const onRemoveBetaAccount = async (record) => {
    try {
      await sendRequest(deleteSystemAccountBetaFeature({account_id: record.account_id, feature: record.feature}))
      await refreshPage()
    } catch {}
  }

  const isAdded = (account_id, feature) => {
    if (isEmpty(feature)) { return false }
    const clonedFeatures = cloneDeep(betaFeatures.features)
    const foundFeature = clonedFeatures.find(x => x.feature == feature)
    if (isArray(foundFeature.accounts)) {
      const accountIds = foundFeature.accounts.map(x => x.account_id)
      return accountIds.includes(account_id)
    }
    return false
  }

  const renderHeader = () => {
    return (
      <Row align="middle" className="p-20">
        <Col flex={1}>
          <div className="admin-page-title">Beta Features</div>
        </Col>
      </Row>
    )
  }

  const renderBetaUserModal = () => {
    return (
      <Modal visible={isAddBetaAccountModalVisible} closable={false} footer={null} width={800} wrapClassName="rounded-modal">
        <div className="text-right" onClick={() => setAddBetaAccountModalVisible(false)}><MdOutlineClose size={30} color={"#CCC"}/></div>
        <div className="mt-10">
          <div className="fw-700 fs-20 ml-10">Users</div>
          { betaFeatures.accounts.map((account,i) => (
            <Row className="ph-15 pv-10 b-border" key={i}>
              <Col xs={4}>
                { account.account_id }
              </Col>
              <Col xs={16}>
                { account.company_name}
              </Col>
              <Col xs={4} className="text-right">
                { !isAdded(account.account_id, selectedFeature) && (
                  <div className="blue-link" onClick={() => onConfirmAddBetaAccount(account.account_id)}>Add</div>
                )}
              </Col>
            </Row>
          ))}
        </div>
      </Modal>
    )
  }

  const renderContent = () => {
    return (
      <FloatingContainer className="ph-20" verticalPadding={20} maxWidth={1000}>
        { betaFeatures.features.map((x,i) => (
          <>
            <div className="fs-18 fw-700 mb-10 ml-5">{x.feature}</div>
            <div className="shadow-card">
              <Table 
                columns={columns} 
                dataSource={x.accounts}
                pagination={false}
              />
            </div>
            <div className="blue-link mt-10 ml-10 mb-30" onClick={() => onAddBetaUser(x.feature)}>+ Add Account</div>
          </>
        ))}
        {renderBetaUserModal()}
      </FloatingContainer>
    )
  }

  if (isLoading) {
    return <LoadingSpinner/>
  }

  return (
    <AdminContent header={renderHeader()} body={renderContent()}/>
  );
}

export default BetaUserFeaturesPage;

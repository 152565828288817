import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {SortableContainer, sortableElement} from 'react-sortable-hoc';
import arrayMove from 'array-move';
import { isUndefined, cloneDeep, isEmpty } from "lodash";
import qs from "qs";
import useApi from '../../hooks/useApi';
import { 
  renderFormLabel,
} from '../../components/formFields'
import { Row, Col, Input, Select, Modal, Button, Dropdown, Menu, notification, Checkbox, Radio, DatePicker, Space } from 'antd';
import { getQuestionnaireTemplate, updateQuestionnaireTemplate, createQuestionnaireTemplate } from '../../api';
import LoadingSpinner from '../../components/loading';
import AdminContent from '../../components/adminContent';
import { PlusOutlined, EditOutlined, EllipsisOutlined, DeleteOutlined } from '@ant-design/icons';
import { MdDragIndicator, MdOutlineClose, MdArrowForwardIos } from "react-icons/md";

const NewQuestionnaireTemplatePage = () => {

  const [isLoading, setLoading] = useState(true);
  const [isNewSectionModalVisible, setIsNewSectionModalVisible] = useState(false);
  const [isAddEditModalVisible, setAddEditModalVisible] = useState(false);
  const [newSectionName, setNewSectionName] = useState(null);
  const [isNewQuestion, setIsNewQuestion] = useState(false);
  const [selectedSectionIndex, setSelectedSectionIndex] = useState(null);
  const [selectedQuestionIndex, setSelectedQuestionIndex] = useState(null);
  const [selectedQuestion, setSelectedQuestion] = useState({});
  const [sections, setSections] = useState([]);
  const [questionnaireName, setQuestionnaireName] = useState("");
  const [selectedTab, setSelectedTab] = useState("questions");
  const [isActive, setActive] = useState(true);
  const [optionsText, setOptionsText] = useState("");

  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();

  const [sendRequest] = useApi()

  const questionnaireTemplateId = params.id;
  const isNewQuestionnaireTemplate = isUndefined(questionnaireTemplateId);

  useEffect(() => {
    window.scrollTo(0, 0);
    const queryStrings = qs.parse(location.search, { ignoreQueryPrefix: true })
    if (queryStrings.tab) {
      setSelectedTab(queryStrings.tab)
    }

    if (!isNewQuestionnaireTemplate) {
      refreshPage();
    } else {
      setLoading(false)
    }
  }, []);

  const refreshPage = () => {
    sendRequest(getQuestionnaireTemplate(questionnaireTemplateId)).then(response => {
      setQuestionnaireName(response.questionnaire_name)
      setSections(response.sections);
      setActive(response.active)
      setLoading(false)
    }).catch((e) => setLoading(false))
  }

  const onSubmit = () => {

    var body = {
      questionnaire_name: questionnaireName,
      sections: sections,
      active: isActive
    }

    if (isNewQuestionnaireTemplate) {
      sendRequest(createQuestionnaireTemplate(body)).then(() => {
        displaySuccess()
      })
    } else {
      sendRequest(updateQuestionnaireTemplate(questionnaireTemplateId, body)).then(() => {
        displaySuccess()
      })
    }
  }

  const displaySuccess = () => {
    notification.success({
      message: 'Success!',
      description: 'Your template has been saved.',
      duration: 3
    });
    navigate("/system/questionnaire-templates");
  }

  const menu = (section, index) => {
    return (
      <Menu>
        <Menu.Item>
          <div onClick={() => handleEditSectionName(section.name, index)}>
            <EditOutlined style={{ marginRight: 8}}/> Edit Section Name
          </div>
        </Menu.Item>
        <Menu.Item>
          <div onClick={() => handleSectionDelete(index)}>
            <DeleteOutlined style={{ marginRight: 8}}/> Delete Section
          </div>
        </Menu.Item>
      </Menu>
    )
  };

  const handleCancel = () => {
    setAddEditModalVisible(false);
    setSelectedQuestionIndex(null)
    setSelectedSectionIndex(null)
  };

  const handleNewSectionCancel = () => {
    setNewSectionName("")
    setIsNewSectionModalVisible(false);
  };

  const handleNewSectionSave = () => {
    setIsNewSectionModalVisible(false);
    var newSections = [].concat(sections)
    if (selectedSectionIndex == null) {
      newSections.push({ name: newSectionName, questions: [] })
    } else {
      newSections[selectedSectionIndex].name = newSectionName;
    }
    setSections(newSections);
  };

  const handleSectionDelete = (sectionIndex) => {
    var newSections = cloneDeep(sections)
    newSections.splice(sectionIndex, 1);
    setSections(newSections);
  }

  const handleSave = () => {
    var newSections = cloneDeep(sections)
    var newSelectedQuestion = Object.assign({}, selectedQuestion);
    newSelectedQuestion.options = newSelectedQuestion.options.filter(x => x != "")
    if (isNewQuestion) {
      newSections[selectedSectionIndex].questions.push(newSelectedQuestion)
    } else {
      newSections[selectedSectionIndex].questions[selectedQuestionIndex] = newSelectedQuestion;
    }
    setSections(newSections);
    setAddEditModalVisible(false);
    setSelectedQuestionIndex(null)
    setSelectedSectionIndex(null)
  };

  const handleRemoveQuestion = () => {
    setAddEditModalVisible(false);
    var newSections = cloneDeep(sections)
    newSections[selectedSectionIndex].questions.splice(selectedQuestionIndex, 1);
    setSections(newSections);
    setSelectedQuestionIndex(null)
    setSelectedSectionIndex(null)
  }

  const handleRowClick = (value, questionIndex, sectionIndex) => {
    setAddEditModalVisible(true)
    setIsNewQuestion(false)
    setSelectedSectionIndex(sectionIndex)
    setSelectedQuestionIndex(questionIndex)
    setSelectedQuestion(value)
    setOptionsText(value ? value.options.join("\n") : "")
  };

  const handleAddSection = () => {
    setSelectedSectionIndex(null)
    setNewSectionName("")
    setIsNewSectionModalVisible(true)
  }

  const handleEditSectionName = (name, index) => {
    setSelectedSectionIndex(index)
    setNewSectionName(name)
    setIsNewSectionModalVisible(true)
  }

  const handleAddNewQuestion = (index) => {
    setIsNewQuestion(true)
    setSelectedSectionIndex(index)
    const newQuestion = {
      label: "",
      answer: "",
      type: "",
      options: []
    }
    setSelectedQuestion(newQuestion)
    setAddEditModalVisible(true)
    setOptionsText("")
  }

  const onOptionChange = (value) => {
    const valueArray = value.split(/\r?\n/)
    const newOptions = valueArray.filter(x => x != "")
    setSelectedQuestion({ ...selectedQuestion, options: newOptions})
    setOptionsText(value)
  }

  const handlePreviewQuestionnaire = async () => {

    try {
      var body = {
        questionnaire_name: questionnaireName,
        sections: sections
      }
  
      if (isNewQuestionnaireTemplate) {
        await sendRequest(createQuestionnaireTemplate(body))
      } else {
        await sendRequest(updateQuestionnaireTemplate(questionnaireTemplateId, body))
      }
      navigate(`/admin/questionnaires/${questionnaireTemplateId}/preview`)
    } catch (error) {
      console.log(error)
    }
  }

  const onSelectTab = (tab) => {
    if (isNewQuestionnaireTemplate) {
      navigate(`/system/questionnaire-templates/new?tab=${tab}`, { replace: true })
    } else {
      navigate(`/system/questionnaire-templates/${questionnaireTemplateId}/edit?tab=${tab}`, { replace: true })
    }
    setSelectedTab(tab)
  }

  const renderInput = (question) => {
    if (question.type == "text") {
      return <Input disabled={true} size="large"/>;
    } else if (question.type == "textarea") {
      return <Input.TextArea disabled={true} rows={3} size="large"/>;
    } else if (question.type == "select") {
      return (
        <Select
          allowClear
          disabled={true}
          style={{ width: '100%'}}
          placeholder={"Select"}
          optionFilterProp="children"
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          size='large'
        >
          {question.options.map((option, index) => (
            <Select.Option value={option} key={index}>{option}</Select.Option>
          ))}
        </Select>
      )
    } else if (question.type == "checkbox") {
      return question.options.map((option, index) => (
        <div className="mb-10">
          <Checkbox disabled={true}>{option}</Checkbox>
        </div>
      ))
    } else if (question.type == "radio") {
      return (
        <Radio.Group>
          <Space direction="vertical">
            {question.options.map((option, index) => (
              <Radio key={index} value={option} disabled={true}>{option}</Radio>
            ))}
          </Space>
        </Radio.Group>
      );
    } else if (question.type == "date") {
      return <DatePicker size="large" format="MM/DD/YYYY" disabled={true}/>
    }

    return <Input size="large"/>;
    
  }

  const renderRow = (value, questionIndex, sectionIndex) => {
    return (
      <div onClick={() => handleRowClick(value, questionIndex, sectionIndex)} className={`question-row`}>
        <Row align="middle">
          <Col flex={1} className="p-5" style={{ maxWidth: "calc(100% - 24px)"}}>
            <div className="question-row--label">{ value.label }</div>
            { renderInput(value) }
          </Col>
          <Col>
            <div className="display-flex">
              <MdDragIndicator style={{ fontSize: 24, color: "#CCC" }}/>
            </div>
          </Col>
          </Row>
      </div>
    )
  }

  const onSortEnd = (oldIndex, newIndex, sectionIndex) => {
    var newSections = cloneDeep(sections)
    var newQuestions = cloneDeep(sections[sectionIndex].questions);
    if (oldIndex !== newIndex) {
      const sortedQuestions = arrayMove(newQuestions, oldIndex, newIndex).filter(el => !!el);
      newSections[sectionIndex].questions = sortedQuestions
      setSections(newSections)
      if (sectionIndex == selectedSectionIndex) {
        setSelectedQuestionIndex(newIndex)
      }
    }
  };

  const SortableItem = sortableElement(({value, questionIndex, sectionIndex}) => (
    <li className="">
      {renderRow(value, questionIndex, sectionIndex)}
    </li>
  ));

  const SortableList = SortableContainer(({items, sectionIndex}) => {
    return (
      <ul className="question-row-container">
        {items.map((value, index) => (
          <SortableItem key={`item-${index}`} index={index} questionIndex={index} sectionIndex={sectionIndex} value={value} />
        ))}
      </ul>
    );
  });

  const renderSection = (section, index) => {
    return (
      <div key={index} className="mb-30">
        <div className="admin-page-content-box add-padding">
          <Row align="middle" gutter={[15]} className="content-box-section-title-container">
            <Col flex={1}>
              <div className="content-box-section-title">{ section.name }</div>
            </Col>
            <Col>
              <Dropdown overlay={menu(section, index)} placement="bottomRight" trigger="click">
                <div className="admin-icon-circle"><EllipsisOutlined/></div>
              </Dropdown>
            </Col>
          </Row>
          <SortableList items={section.questions} sectionIndex={index} onSortEnd={({ oldIndex, newIndex }) => onSortEnd(oldIndex, newIndex, index )} helperClass="question-row-dragging" pressDelay={100} lockAxis="y" />
          <div className="admin-link" onClick={() => handleAddNewQuestion(index)}><PlusOutlined/> Add Question</div>
        </div>
      </div>
    )
  }

  const renderModalOptions = () => {
    if (selectedQuestion.type === "select" || selectedQuestion.type === "checkbox" || selectedQuestion.type === "radio") {
      return (
        <Col xs={24}>
          { renderFormLabel("Options (one per line)") }
          <Input.TextArea 
            rows={3}
            defaultValue={selectedQuestion ? selectedQuestion.options.join("\n") : ""}
            value={optionsText}
            onChange={(e) => onOptionChange(e.target.value)}
            size="large"/>
        </Col>
      )
    }
  }

  const renderNewSectionModal = () => {
    return (
      <Modal visible={isNewSectionModalVisible} footer={null} onCancel={handleNewSectionCancel} width={400} closable={false} maskClosable={false} wrapClassName="rounded-modal">
        <Row align="middle">
          <Col flex={1}>
            <div className="fw-700 fs-18">{selectedSectionIndex == null ? "New Section" : "Edit Section"} </div>
          </Col>
          <Col>
            <div className="display-flex" onClick={handleNewSectionCancel}><MdOutlineClose size={30} color={"#CCC"}/></div>
          </Col>
        </Row>
        <Row gutter={[10,10]} className="mt-20">
          <Col xs={24}>
            { renderFormLabel("Section Name") }
            <Input size="large" value={newSectionName} onChange={(e) => setNewSectionName(e.target.value)}/>
          </Col>
        </Row>
        <div className="admin-modal-footer">
          <Button className="admin-small-button secondary" onClick={handleNewSectionCancel}>Cancel</Button>
          <Button className="admin-small-button ml-10" onClick={handleNewSectionSave}>
            {selectedSectionIndex == null ? "Add Section" : "Save"}
          </Button>
        </div>
      </Modal>
    )
  }
   
  const renderHeader = () => {
    return (
      <div className="p-20">
        <div>
          <span className="c-blue fw-700 cursor-default" onClick={() => navigate(-1)}>
            Questionnaire Templates
          </span>
          <span className="fs-10 mh-5"><MdArrowForwardIos/></span>
          <span className="cursor-default c-text-gray">
            { isNewQuestionnaireTemplate ? "New" : "Edit" }
          </span>
        </div>
        <div className="fw-700 fs-24 mt-5">{ isEmpty(questionnaireName) ? "New Questionnaire Template" : questionnaireName }</div>
        <div className="tab-slider mt-10">
          <div className={`tab-slider--tab ${selectedTab == "questions" ? "selected" : ""}`} onClick={() => onSelectTab("questions")}>
            Questions
          </div>
          <div className={`tab-slider--tab ${selectedTab == "settings" ? "selected" : ""}`} onClick={() => onSelectTab("settings")}>
            Settings
          </div>
        </div>
      </div>
    )
  }

  const renderInputType = (value, text) => {
    const styleClass = selectedQuestion.type == value ? "bg-blue c-white" : "bg-gray"
    return (
      <Col xs={12} sm={8}>
        <div className={`p-15 text-center radius-8 cursor-default ${styleClass}`} onClick={() => setSelectedQuestion({ ...selectedQuestion, type: value})}>{text}</div>
      </Col>
    )
  }

  const renderInputTypes = () => {
    return (
      <Row gutter={[10,10]}>
        { renderInputType("text", "Text Box") }
        { renderInputType("textarea", "Text Area") }
        { renderInputType("checkbox", "Checkboxes") }
        { renderInputType("radio", "Radio Buttons") }
        { renderInputType("select", "Dropdown") }
        { renderInputType("date", "Date") }
      </Row>
    )
  }

  const renderAddEditModal = () => {
    return (
      <Modal visible={isAddEditModalVisible} footer={null} onCancel={() => setAddEditModalVisible(false)} width={500} closable={false} maskClosable={false} wrapClassName="rounded-modal">
        <Row align="middle">
          <Col flex={1}>
            <div className="fw-700 fs-18">{isNewQuestion ? "New Question" : "Edit Question"}</div>
          </Col>
          <Col>
            <div className="display-flex" onClick={() => setAddEditModalVisible(false)}><MdOutlineClose size={30} color={"#CCC"}/></div>
          </Col>
        </Row>
        <Row gutter={[10,10]} className="mt-20">
          <Col xs={24}>
            { renderFormLabel("Label") }
            <Input size="large" value={selectedQuestion.label} onChange={(e) => setSelectedQuestion({ ...selectedQuestion, label: e.target.value})}/>
          </Col>
          <Col xs={24}>
            { renderFormLabel("Input Type") }
            { renderInputTypes() }
          </Col>
          { renderModalOptions() }
          <Col xs={24}>
            <Row>
              <Col flex="1">
                { !isNewQuestion && (
                  <Button className="admin-small-button secondary mt-20" onClick={handleRemoveQuestion}>Remove</Button>
                )}
              </Col>
              <Col>
                <div className="mt-20 text-right">
                  <Button className="admin-small-button secondary" onClick={handleCancel}>Cancel</Button>
                  <Button className="admin-small-button ml-10" onClick={handleSave}>
                    Save
                  </Button>
                </div>
              </Col>
            </Row>
            {/* <div className="mt-20">
              <Button className="admin-small-button secondary" onClick={handleCancel}>Cancel</Button>
              <Button className="admin-small-button ml-10" onClick={handleSave}>
                Save
              </Button>
            </div>
            { !isNewQuestion && (
              <Button className="admin-small-button secondary mt-30" onClick={handleRemoveQuestion}>Remove</Button>
            )} */}
          </Col>
        </Row>
      </Modal>
    )
  }

  const renderQuestionsTab = () => {
    return (
      <div>
        { sections.map((x, i) => renderSection(x, i))}
        <button className="page-title-button" onClick={handleAddSection}>Add Section</button>
      </div>
    )
  }

  const renderSettingsTab = () => {
    return (
      <>
        <div className="shadow-card p-20">
          <div className="c-text-gray mb-5">Questionnaire Name</div>
          <Input placeholder="Workflow Name" size="large" value={questionnaireName} onChange={(e) => setQuestionnaireName(e.target.value)}/> 
          <div className="mt-15">
            <Checkbox checked={isActive} onClick={(e) => setActive(e.target.checked)}>Active</Checkbox>
          </div>
          {/* { !isNew && (
            <button className="page-title-button mt-20" onClick={handleUpdateWorkflow}>Save</button>
          )} */}
        </div>
      </>
    )
  }

  const renderTab = () => {
    if (selectedTab === "questions") {
      return renderQuestionsTab()
    } else {
      return renderSettingsTab()
    }
  }

  const renderContent = () => {
    return (
      <div className="ph-15 pt-15" style={{ maxWidth: 800, paddingBottom: 80 }}>
        {/* <div className="admin-page-content-box add-padding mb-30">
          <Input placeholder="Questionnaire Name" size="large" value={questionnaireName} onChange={(e) => setQuestionnaireName(e.target.value)}/>
        </div> */}
        { renderTab() }
        <div className="sticky-footer p-0">
          <div className="bg-white t-border p-15">
            <button className="page-title-button small-100" onClick={onSubmit}>Save</button>
          </div>
        </div>
        { renderNewSectionModal() }
        { renderAddEditModal() }
      </div>
    )
  }

  if (isLoading) {
    return <LoadingSpinner/>
  }

  return (
    <AdminContent header={renderHeader()} body={renderContent()}/>
  );
}

export default NewQuestionnaireTemplatePage;

import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { isUndefined, isEmpty } from "lodash";
import useApi from '../../../hooks/useApi';
import useDocumentTitle from '../../../hooks/useDocumentTitle';
import { notification, Form, Row, Col, Alert } from 'antd';
import { getVenue, createVenue, updateVenue } from '../../../api';
import LoadingSpinner from '../../../components/loading';
import AdminContent from '../../../components/adminContent';
import FloatingContainer from '../../../components/floatingContainer'
import { MdArrowForwardIos } from "react-icons/md";
import { 
  renderInputField,
  renderTextAreaField,
  renderEmailField,
  renderPhoneField,
  renderSearchSelectField,
  renderGlobalAddressField,
  renderGlobalSecondAddressField,
  renderGlobalCityField,
  renderGlobalStateField,
  renderGlobalZipField,
  renderCheckboxField
} from '../../../components/formFields'
import { 
  getDefaultCountryCode, 
  setDefaultCountryCode, 
  getDefaultState, 
  getCountryOptions, 
  getDefaultPhoneCountryCode,
  getFormattedPhoneNumber,
  getPhoneNumber,
  getCountryCodeForPhoneNumber
} from '../../../helpers/addressHelper';

const NewVenuePage = () => {

  const [isLoading, setLoading] = useState(true);
  const [venue, setVenue] = useState({});
  const [countries, setCountries] = useState([]);
  const [venueCountryCode, setVenueCountryCode] = useState("US");
  const [phoneCountryCode, setPhoneCountryCode] = useState("US");

  useDocumentTitle("Venue Details")
  const navigate = useNavigate();
  const params = useParams();
  const [form] = Form.useForm();

  const [sendRequest] = useApi()

  const venueId = params.id;
  const isNew = isUndefined(venueId);

  useEffect(() => {
    window.scrollTo(0, 0);

    loadCountries()
    if (!isNew) {
      refreshPage();
    } else {
      setCountryField()
      setPhoneCountryCode(getDefaultPhoneCountryCode())
      setStateField()
      setLoading(false)
    }
  }, []);

  const refreshPage = async () => {
    try {
      // Get venue info
      const venueResults = await sendRequest(getVenue(venueId));
      setVenue(venueResults)

      // Set fields
      const formValues = {
        ...venueResults,
        contact_phone: getFormattedPhoneNumber(venueResults.contact_phone)
      }
      form.setFieldsValue(formValues)

      // Set default country
      if (isEmpty(venueResults.country)) {
        setCountryField()
      } else {
        setVenueCountryCode(venueResults.country)
      }

      // Set default state if not populated
      if (isEmpty(venueResults.state)) {
        setStateField()
      }

      // Set default phone country code
      if (isEmpty(venueResults.contact_phone)) {
        setPhoneCountryCode(getDefaultPhoneCountryCode())
      } else {
        setPhoneCountryCode(getCountryCodeForPhoneNumber(venueResults.contact_phone))
      }

      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  const loadCountries = () => {
    const data = getCountryOptions()
    setCountries(data);
  }

  const setCountryField = () => {
    const defaultCountryCode = getDefaultCountryCode()
    const countryCode = !isEmpty(defaultCountryCode) ? defaultCountryCode : "US"
    setVenueCountryCode(countryCode)
    form.setFieldsValue({ country: countryCode})
  }

  const setStateField = () => {
    const defaultCountryCode = getDefaultCountryCode()
    if (defaultCountryCode == "US") {
      const defaultState = getDefaultState()
      const state = !isEmpty(defaultState) ? defaultState : null
      form.setFieldsValue({ state: state })
    }
  }

  const onVenueCountryChange = (value) => {
    setVenueCountryCode(value)
    setDefaultCountryCode(value)
    setStateField()
  }

  const onSubmit = async (values) => {

    var body = {
      ...values,
      one_time: values["one_time"] == true ?? false,
      contact_phone: getPhoneNumber(values["contact_phone"], phoneCountryCode)
    }

    try {
      if (isNew) {
        await sendRequest(createVenue(body))
      } else {
        await sendRequest(updateVenue(venueId, body))
      }
      displaySuccess()
    } catch {}
  }

  const displaySuccess = () => {
    notification.success({
      message: 'Success!',
      description: 'Your venue has been saved.',
      duration: 3
    });
    navigate("/admin/venues");
  }

  const renderHeader = () => {
    return (
      <div className="p-20">
        <div>
          <span className="c-blue fw-700 cursor-default" onClick={() => navigate("/admin/venues")}>
            Venues
          </span>
          <span className="fs-10 mh-5"><MdArrowForwardIos/></span>
          <span className="cursor-default c-text-gray">
            { isNew ? "New" : "Edit" }
          </span>
        </div>
        <div className="fw-700 fs-24 mt-5">{ isEmpty(venue.venue_name) ? "New Venue" : venue.venue_name }</div>
      </div>
    )
  }

  const renderContent = () => {
    return (
      <FloatingContainer className="ph-20 mb-80" verticalPadding={20} maxWidth={800}>
        <Form form={form} layout="vertical" name="contact" onFinish={onSubmit}>
          { !isNew && (
            <Alert message="Changes made here will update the venue information across all the events that are associated with this venue." type="info" showIcon className="mb-20" />
          )}
          <div className="shadow-card p-20">
            <div className="fs-20 fw-700 mb-20 line-1">Venue Details</div>
            <Row align="middle" gutter={[15,15]}>
              <Col xs={24} md={24}>
                { renderInputField("Venue Name", "venue_name", true) }
              </Col>
            </Row>
            <div className="b-border mv-20"></div>
            <Row align="middle" gutter={[15,15]}>
              <Col xs={24} md={24}>
                { renderSearchSelectField("Country/Region", "country", "Select a country/region...", countries, true, onVenueCountryChange) }
              </Col>
              <Col xs={24} md={24}>
                { renderGlobalAddressField(venueCountryCode, "address_line_1") }
              </Col>
              <Col xs={24} md={24}>
                { renderGlobalSecondAddressField(venueCountryCode, "address_line_2") }
              </Col>
              <Col xs={24} md={24}>
                { renderGlobalCityField(venueCountryCode, "city") }
              </Col>
              <Col xs={24} md={16}>
                { renderGlobalStateField(venueCountryCode, "state") }
              </Col>
              <Col xs={12} md={8}>
                { renderGlobalZipField(venueCountryCode, "zip") }
              </Col>
            </Row>
            <div className="b-border mv-20"></div>
            <Row align="middle" gutter={[15,15]}>
              <Col xs={24}>
                { renderCheckboxField("One Time Venue", "one_time", null, "Checking this box will hide this venue from the venue list when creating events.") }
              </Col>
              <Col xs={24} md={24}>
                { renderInputField("Website", "website", false) }
              </Col>
              <Col xs={24} md={12}>
                { renderInputField("Wi-Fi", "wifi", false) }
              </Col>
            </Row>
          </div>
          <div className="shadow-card p-20 mt-15">
            <div className="fs-20 fw-700 mb-20 line-1">Venue Contact</div>
            <Row align="middle" gutter={[15,15]}>
              <Col xs={24} md={24}>
                { renderInputField("Contact Name", "contact_name") }
              </Col>
              <Col xs={24} md={24}>
                { renderEmailField("Email", "contact_email") }
              </Col>
              <Col xs={24} md={12}>
                { renderPhoneField("Phone", "contact_phone", false, false, phoneCountryCode, setPhoneCountryCode) }
              </Col>
            </Row>
          </div>
          <div className="shadow-card p-20 mt-15">
            <div className="fs-20 fw-700 mb-20 line-1">Notes</div>
            <Row align="middle" gutter={[15,15]}>
              <Col xs={24} md={24}>
                { renderTextAreaField("", "notes", 4) }
              </Col>
            </Row>
          </div>
          <div className="sticky-footer p-0">
            <div className="p-15 bg-white t-border">
              <button className="page-title-button" type="submit">Save</button>
            </div>
          </div>
        </Form>
      </FloatingContainer>
    )
  }

  if (isLoading) {
    return <LoadingSpinner/>
  }

  return (
    <AdminContent header={renderHeader()} body={renderContent()}/>
  );
}

export default NewVenuePage;

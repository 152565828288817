import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useApi from '../../../hooks/useApi';
import {SortableContainer, sortableElement} from 'react-sortable-hoc';
import arrayMove from 'array-move';
import { isUndefined, cloneDeep } from "lodash";
import { Row, Col, Input, Dropdown, Menu } from 'antd';
import { updateRecommendedPlaylist, getRecommendedPlaylist } from '../../../api';
import LoadingSpinner from '../../../components/loading';
import AdminContent from '../../../components/adminContent';
import FloatingContainer from '../../../components/floatingContainer'
import MusicModal from '../../../components/musicModal';
import PlatformSelectionModal from '../../../modals/platformSelectionModal';
import ImportPlaylistModal from '../../../modals/importPlaylistModal';
import CustomSongModal from '../../../modals/customSongModal';
import { FiMusic, FiPlus, FiRefreshCw, FiCheckCircle } from "react-icons/fi";
import { MdDragIndicator, MdArrowForwardIos, MdOutlineMoreHoriz } from "react-icons/md";
import { DeleteOutlined } from '@ant-design/icons';
import emptyMusicImage from '../../../images/empty-music-icon.png';
import useDocumentTitle from '../../../hooks/useDocumentTitle';

const NewRecommendedPlaylistPage = () => {

  const [isLoading, setLoading] = useState(true);
  const [isSaving, setSaving] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isEditingTitle, setEditingTitle] = useState(false);
  const [playlistSongs, setPlaylistSongs] = useState([]);
  const [playlistName, setPlaylistName] = useState("");
  const [isMusicSearchModalVisible, setMusicSearchModalVisible] = useState(false);
  const [isPlatformSelectionVisible, setPlatformSelectionVisible] = useState(false);
  const [isCustomSongModalVisible, setCustomSongModalVisible] = useState(false);
  const [isImportPlaylistModalVisible, setImportPlaylistModalVisible] = useState(false);
  const [selectedPlatform, setSelectedPlatform] = useState("spotify");

  useDocumentTitle("Recommended Playlist Details")
  const navigate = useNavigate();
  const params = useParams();
  const inputRef = useRef(null);

  const [sendRequest] = useApi()

  const playlistId = params.id;

  useEffect(() => {
    window.scrollTo(0, 0);
    if (!isUndefined(playlistId)) {
      refreshPage();
    } else {
      setLoading(false)
    }
  }, []);

  const refreshPage = async () => {
    try {
      const response = await sendRequest(getRecommendedPlaylist(playlistId))
      setPlaylistName(response.playlist_name)
      setPlaylistSongs(response.songs)
    } finally {
      setLoading(false)
    }
  }

  const saveRecommendedPlaylist = async (newPlaylistSongs) => {
    try {
      setPlaylistSongs(newPlaylistSongs)
      setSaving(true)
      var body = {
        playlist_name: playlistName,
        songs: newPlaylistSongs
      }
      await sendRequest(updateRecommendedPlaylist(playlistId, body))
    } finally {
      setSaving(false)
    }
  }

  const onSortEnd = (oldIndex, newIndex) => {
    var newSongs = cloneDeep(playlistSongs)
    if (oldIndex !== newIndex) {
      const sortedSongs = arrayMove(newSongs, oldIndex, newIndex).filter(el => !!el);
      saveRecommendedPlaylist(sortedSongs)
    }
  };

  const songMenu = (item, index) => {
    return (
      <Menu>
        <Menu.Item>
          <div onClick={() => removeSong(item,index)}>
            <DeleteOutlined style={{ marginRight: 8}}/> Remove Song
          </div>
        </Menu.Item>
      </Menu>
    )
  };

  const SortableItem = sortableElement(({value, itemIndex}) => (
    <li className="">
      {renderPlaylistSong(value, itemIndex)}
    </li>
  ));

  const SortableList = SortableContainer(({items, sectionIndex}) => {
    return (
      <ul className="question-row-container">
        {items.map((value, index) => (
          <SortableItem key={`item-${index}`} index={index} itemIndex={index} value={value} disabled={!isEdit} />
        ))}
      </ul>
    );
  });

  const addSong = (song) => {
    const newPlaylistSongs = [].concat(playlistSongs);
    const existingSong = newPlaylistSongs.find((x) => x.song_id == song.song_id);

    if (existingSong) {
      return;
    }

    newPlaylistSongs.push(song)
    saveRecommendedPlaylist(newPlaylistSongs)
  }

  const removeSong = (song, index) => {
    const newPlaylistSongs = [].concat(playlistSongs);
    newPlaylistSongs.splice(index, 1);
    saveRecommendedPlaylist(newPlaylistSongs)
  }

  const selectPlatform = (platform) => {
    setSelectedPlatform(platform)
    setPlatformSelectionVisible(false)
    setMusicSearchModalVisible(true)
  }

  const displayCustomSong = () => {
    setPlatformSelectionVisible(false)
    setCustomSongModalVisible(true)
  }

  const saveCustomSong = async (values) => {
    const newPlaylistSongs = cloneDeep(playlistSongs);

    const song = {
      title: values.title,
      artist: values.artist,
      image: "",
      notes: "",
      duration: 0,
      url: "",
      preview_url: "",
      source: "custom",
      rank: null
    }

    newPlaylistSongs.push(song)
    saveRecommendedPlaylist(newPlaylistSongs)
    setCustomSongModalVisible(false)
  }

  const saveImportedSongs = async (songs) => {
    const newPlaylistSongs = cloneDeep(playlistSongs);

    for (const song of songs) {
      newPlaylistSongs.push(song)
    }

    saveRecommendedPlaylist(newPlaylistSongs)
    setImportPlaylistModalVisible(false)
  }

  const startImportPlaylist = () => {
    setMusicSearchModalVisible(false)
    setImportPlaylistModalVisible(true)
  }

  const startEditingTitle = () => {
    setEditingTitle(true)
    setTimeout(() => {
      inputRef.current.focus()
    }, 100)
  }

  const onKeyPress = (e) => {
    if (e.keyCode == 13) {
      saveName()
    }
  }

  const saveName = () => {
    setEditingTitle(false)
    saveRecommendedPlaylist(playlistSongs)
  }

  const renderPlaylistSong = (item, index) => {
    return (
      <div className="music-playlist-song" key={index}>
        <div className="flex-0">
          { item.image ? (
            <img src={item.image} style={{ width: 50 }}/>
          ) : (
            <div className="song-image-icon"><FiMusic/></div>
          )}
        </div>
        <div className="music-playlist-song--col">
          <div className="music-playlist-song-text fs-14 fw-700 line-1-2">{item.title}</div>
          <div className="music-playlist-song-text fs-14 c-text-gray">{item.artist}</div>
        </div>
        <div className="display-flex mr-10 flex-middle">
          { !isEdit && (
            <Dropdown overlay={songMenu(item, index)} placement="bottomRight" trigger="click">
              <div className="song-card--dots-container">
                <MdOutlineMoreHoriz style={{ fontSize: 24, color: '#999'}}/>
              </div>
            </Dropdown>
          )}
          { isEdit && (
            <MdDragIndicator style={{ fontSize: 24, color: '#CCC'}}/>
          )}
        </div>
      </div>
    )
  }

  const renderHeader = () => {
    return (
      <div className="p-20">
        <div>
          <span className="c-blue fw-700 cursor-default" onClick={() => navigate("/admin/recommended-playlists")}>
            Recommended Playlists
          </span>
          <span className="fs-10 mh-5"><MdArrowForwardIos/></span>
          <span className="cursor-default c-text-gray">
            Edit
          </span>
        </div>
        <div className={`header-editable-title mt-5 ${isEditingTitle ? "editing" : ""}`}>
          <div className="header-editable-title--label" onClick={() => startEditingTitle()}>{ playlistName }</div>
          <div className="header-editable-title--input">
            <Input 
              size="large" 
              placeholder="Playlist Name" 
              ref={inputRef}
              onBlur={() => saveName()} 
              value={playlistName} 
              onChange={(e) => setPlaylistName(e.target.value)} 
              onKeyUp={onKeyPress}
            />
          </div>
        </div>
        { renderSubHeader() }
      </div>
    )
  }

  const renderSubHeader = () => {
    return (
      <div className="flex-row flex-middle c-text-gray flex-1 mt-5">
        { isSaving ? (
          <div className="flex-row flex-middle c-text-gray flex-1">
            <FiRefreshCw style={{ marginRight: 5}}/> Saving changes...
          </div>
        ) : (
          <div className="flex-row flex-middle c-text-gray flex-1">
            <FiCheckCircle style={{ marginRight: 5}}/> All changes saved!
          </div>
        )}
      </div>
    )
  }

  const renderContent = () => {
    return (
      <FloatingContainer className="ph-20 mb-80" verticalPadding={20} maxWidth={800}>
       { playlistSongs.length == 0 && !isEdit ? (
        <div className="shadow-card ph-20 pv-50 text-center" style={{ maxWidth: 600 }}>
          <div>
            <img src={emptyMusicImage} width={200}/>
          </div>
          <div className="fs-18 fw-700 mt-30">
            No songs yet
          </div>
          <div className="fs-14 fw-500 c-text-gray">
            Add songs to get started.
          </div>
          <button className="primary-button mt-30" style={{ width: 200 }} onClick={() => setPlatformSelectionVisible(true)}>Add Songs</button>
        </div>
       ) : (
        <div>
          <Row>
            <Col flex={1}>
              <div className="fs-16 fw-700 mb-10 ml-10">Songs ({playlistSongs.length})</div>
            </Col>
            <Col>
              <div className="admin-link mr-10" onClick={() => setIsEdit(!isEdit)}>{isEdit ? "Done" : "Reorder"}</div>
            </Col>
          </Row>
          <div className="shadow-card">
            <div className="display-flex flex-middle p-10 b-border" onClick={() => setPlatformSelectionVisible(true)}>
              <div className="flex-0">
                <div className="song-image-icon"><FiPlus/></div>
              </div>
              <div className="flex-1 fw-700 ml-15">Add Songs</div>
            </div>
            <SortableList items={playlistSongs} onSortEnd={({ oldIndex, newIndex }) => onSortEnd(oldIndex, newIndex)} helperClass="question-row-dragging" pressDelay={100} lockAxis="y"/>
          </div>
        </div>
       )}
        <MusicModal 
          isVisible={isMusicSearchModalVisible} 
          title="Add Songs" 
          eventName={""} 
          onCancel={() => setMusicSearchModalVisible(false)}
          onSelect={addSong}
          selectedEvent={{}}
          selectedSongs={playlistSongs}
          selectedPlatform={selectedPlatform}
          startImportPlaylist={startImportPlaylist}
        />
        <PlatformSelectionModal 
          isVisible={isPlatformSelectionVisible}
          setVisible={setPlatformSelectionVisible}
          selectPlatform={selectPlatform}
          addCustomSong={displayCustomSong}
        />
        <ImportPlaylistModal 
          isVisible={isImportPlaylistModalVisible}
          setVisible={setImportPlaylistModalVisible}
          selectedPlatform={selectedPlatform}
          saveImportedSongs={saveImportedSongs}
        />
        <CustomSongModal 
          isVisible={isCustomSongModalVisible}
          setVisible={setCustomSongModalVisible}
          saveCustomSong={saveCustomSong}
        />
      </FloatingContainer>
    )
  }

  if (isLoading) {
    return <LoadingSpinner/>
  }

  return (
    <AdminContent header={renderHeader()} body={renderContent()} headerHeight={120}/>
  );
}

export default NewRecommendedPlaylistPage;

import React, { useState } from "react";
import FloatingContainer from '../components/floatingContainer'

const ErrorCard = props => {

  const [showContactUs, setShowContactUs] = useState(false);

  const renderErrorCard = () => {
    return (
      <FloatingContainer className="ph-20" verticalPadding={40} maxWidth={500}>
        <div className="shadow-card ph-20 pv-30 text-center">
          <div className="fw-700 fs-16">
            Something went wrong!
          </div>
          <div className="">
            There was an issue loading this page.
          </div>
          <div className="mb-20">
            Please try again later.
          </div>
          <button className="small-primary-button" onClick={() => setShowContactUs(true)}>Contact Us</button>
          { showContactUs && (
            <div className="bg-gray p-20 mt-20">
              <div className="fw-700">
                Send us an email at
              </div>
              <div>
                support@djplanningcenter.com
              </div>
            </div>
          )}
        </div>
      </FloatingContainer>
    )
  }

  return renderErrorCard()
};

export default ErrorCard;

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useApi from '../../../hooks/useApi';
import { 
  getStripeAccountLink 
} from '../../../api';
import LoadingSpinner from '../../../components/loading';
import AdminContent from '../../../components/adminContent';
import useDocumentTitle from '../../../hooks/useDocumentTitle';

const StripeRefreshRedirectPage = () => {

  const [isLoading, setLoading] = useState(true);

  useDocumentTitle("Stripe Refresh")
  const navigate = useNavigate();

  const [sendRequest] = useApi()

  useEffect(() => {
    window.scrollTo(0, 0);
    refreshPage();
  }, []);

  const refreshPage = async () => {
    try {
      const accountLinkResults = await sendRequest(getStripeAccountLink())
      if (accountLinkResults.status == "failed") {
        navigate("/admin/setup/payments")
      } else {
        window.location.href = accountLinkResults.url
      }
    } catch (error) {
      navigate("/admin/setup/payments")
    }
  }

  const renderContent = () => {
    return (
      <>
      </>
    )
  }

  if (isLoading) {
    return (
      <div className="mt-80">
        <LoadingSpinner/>
      </div>
    )
  }

  return (
    <AdminContent body={renderContent()}/>
  );
}

export default StripeRefreshRedirectPage;

import React from 'react';
import { isNull } from 'lodash';

const FullPageLayout = props => {
  const { header, children } = props;

  const hasHeader = !isNull(header);

  const renderHeader = () => {
    if (hasHeader) {
      return (
        <div className="full-page-layout--header">
          { header }
        </div>
      )
    }
    return null;
  }

  return (
    <div className="full-page-layout--container">
      { renderHeader() }
      <div className="full-page-layout--body">
        { children }
      </div>
    </div>
  );
};

export default FullPageLayout;

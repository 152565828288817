import React, { useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { BackToHomeLink, DocLink } from '../../../components/docComponents'
import AdminContent from '../../../components/adminContent';
import FloatingContainer from '../../../components/floatingContainer'
import useDocumentTitle from '../../../hooks/useDocumentTitle';

const DocsPricingGuidesPage = () => {

  useDocumentTitle("Pricing Guides | Documentation")
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const renderContent = () => {
    return (
      <FloatingContainer className="ph-20 bg-white" verticalPadding={20} maxWidth={1000}>
        <BackToHomeLink/>
        <div className="fw-700 fs-24 mt-20">Pricing Guides</div>
        <div className="fw-500 fs-16 c-text-gray">Send your pricing to a potential client.</div>
        <div className="b-border mv-20"></div>
        <div className="fw-700 fs-18">Overview</div>
        <div className="fw-500 mb-15 line-1-4 mt-15">
          Pricing guides are a way for you to send a potential client information about your services. It's simply a link that you can send through email or text message that includes a listing of all your packages and add-ons along with their pricing. You can also include an optional contact form at the end that allows them to take the next steps and request a booking.
          <br/><br/>
          The goal of this feature is to offer a non-pressured way for people to view your information and respond only if they are interested in taking the next steps. The built-in contact form gives them an easy way to submit their information, helping you get booked faster.
        </div>
        <div className="b-border mv-20"></div>
        <div className="fw-700 fs-18 mb-15">Creating a pricing guide</div>
        <ol>
          <li className="mb-15">
            Create any <DocLink to="/admin/packages">Packages</DocLink> or <DocLink to="/admin/addons">Add-Ons</DocLink>  (if you haven't already) before continuing to the next step.
            <div className="bg-gray pv-10 ph-15 radius-8 mt-10"><b>Note:</b> The more information you can provide in the details of your packages and add-ons, the better your pricing guide will look. Make sure and include photos and descriptions to give your potential clients an accurate picture of what you offer. Use the "Included Items" on packages to list the selling points in a visually pleasing way.</div>
          </li>
          <li className="mb-15">
            Go to the <DocLink to="/admin/pricing-guides">Pricing Guides</DocLink> page and click on <b>"New Guide"</b>.
          </li>
          <li className="mb-15">
            In the <b>“New Pricing Guide"</b> modal, enter a name and click <b>"Continue"</b>. This internal name is only visible to you.
          </li>
          <li className="mb-15">
            Enter a "Display Name" for the pricing guide. This will be displayed as the title of the page. We suggest something like "Pricing Guide" or "Sales Guide".
          </li>
          <li className="mb-15">
            Check the <b>"Display contact form"</b> checkbox if you would like to include a "Next Steps" section at the end of your pricing guide. This is a contact form that collects the client's contact information and allows them to select the services they are intested in.
              <div className="bg-gray pv-10 ph-15 radius-8 mt-10">This is highly suggested if you would like a way for your potential clients to contact you directly after reviewing your pricing.</div>
          </li>
          <li className="mb-15">
            Add any services you would like to include in the Packages and Add-Ons sections. Drag and drop the services to reorder them if needed.
          </li>
          <li className="mb-15">
            Click the <b>"Save"</b> button to save your changes.
          </li>
          <li className="mb-15">
            On the Pricing Guides page, click the option icon next to the guide and select <b>"Preview"</b> to see what your clients will see.
          </li>
        </ol>
        <div className="ml-20 mt-20">
          <button className="small-primary-button mb-5" onClick={() => navigate("/admin/pricing-guides")}>Go to pricing guides</button>
        </div>
        <div className="b-border mv-20"></div>
        <div className="fw-700 fs-18 mb-15">Sending a pricing guide</div>
        <ol>
          <li className="mb-15">
            On the <DocLink to="/admin/pricing-guides">Pricing Guides</DocLink> page, select the option icon next to a pricing guide and select <b>"Share Link"</b>.
          </li>
          <li className="mb-15">
            Copy the link and send it in an email or text message.
          </li>
        </ol>
        <div className="b-border mv-20"></div>
        <div className="fw-700 fs-18 mb-15">Viewing booking requests</div>
        <ol>
          <li className="mb-15">
            View the docs for <DocLink to="/admin/docs/booking-requests">Booking Requests</DocLink> to learn more.
          </li>
        </ol>
        <div className="b-border mv-50"></div>
      </FloatingContainer>
    )
  }

  return (
    <AdminContent body={renderContent()}/>
  );
}

export default DocsPricingGuidesPage;

import React, { useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { Image } from 'antd';
import { BackToHomeLink, DocLink } from '../../../components/docComponents'
import AdminContent from '../../../components/adminContent';
import FloatingContainer from '../../../components/floatingContainer'
import iCalGoogleCalendarImage from '../../../images/ical-google-calendar.jpg';
import iCalGoogleUrlImage from '../../../images/ical-google-url.jpg';
import iCalAppleCalendarButtonImage from '../../../images/ical-apple-calendar-button.jpg';
import iCalAppleAddCalendarImage from '../../../images/ical-apple-add-calendar.jpg';
import iCalAppleSubscribeImage from '../../../images/ical-apple-subscribe.jpg';

import useDocumentTitle from '../../../hooks/useDocumentTitle';

const DocsCalendarLinksPage = () => {

  useDocumentTitle("iCalendar Links | Documenation")
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const renderContent = () => {
    return (
      <FloatingContainer className="ph-20 bg-white" verticalPadding={20} maxWidth={1000}>
        <BackToHomeLink/>
        <div className="fw-700 fs-24 mt-20">iCalendar Links</div>
        <div className="fw-500 fs-16 c-text-gray">Export your events to your calendar.</div>
         <div className="b-border mv-20"></div>
        <div className="fw-700 fs-18 mb-15">Overview</div>
        Creating an iCal link allows you to subscribe to your DJ Planning Center calendar and display your events in your Google Calendar, Apple Calendar, etc. By adding this URL as a new calendar in your calendar application, your events will automatically be updated any time changes are made within DJ Planning Center.
        <div className="b-border mv-20"></div>
        <div className="fw-700 fs-18 mb-15">Creating an iCal Link</div>
        <ol>
          <li className="mb-15 mt-15">
            Go to <b>Setup {'>'}</b> <DocLink to="/admin/setup/calendar">Calendar</DocLink>.
          </li>
          <li className="mb-15">
            Select the <b>Add Link</b> button under the <b>iCal Links</b> section.
          </li>
          <li className="mb-15">
            Enter a name for your link.
            <div className="bg-gray pv-10 ph-15 radius-8 mt-10"><b>Note:</b> This is used for identifying your link internally if you have more than one.</div>
          </li>
          <li className="mb-15">
            Select the staff members whose events you would like to include in your calendar. This will export all events that have these staff members assigned to them. You can also include <b>Unassigned</b> events if you choose.
          </li>
          <li className="mb-15">
            Select the <b>Save</b> button.
          </li>
        </ol>
        <div className="ml-20 mt-20">
          <button className="small-primary-button mb-5" onClick={() => navigate("/admin/setup/calendar")}>Go to Calendar Settings</button>
        </div>
        <div className="b-border mv-20"></div>
        <div className="fw-700 fs-18 mb-15">Adding to Google Calendar</div>
        <ol>
          <li className="mb-15 mt-15">
            Copy an iCal Link from the <b>Setup {'>'}</b> <DocLink to="/admin/setup/calendar">Calendar</DocLink> page.
          </li>
          <li className="mb-15 mt-15">
            Navigate to your Google Calendar.
          </li>
          <li className="mb-15">
            Under <b>Other Calendars</b>, select the <b>+</b> button and choose <b>From URL</b>.
            <div className="mt-15">
              <Image src={iCalGoogleCalendarImage} style={{ width: '100%', maxWidth: 600, border: '1px solid #CCC' }}/>
            </div>
          </li>
          <li className="mb-15">
            Paste the iCal Link in the <b>URL of calendar</b> field.
            <div className="mt-15">
              <Image src={iCalGoogleUrlImage} style={{ width: '100%', maxWidth: 600, border: '1px solid #CCC' }}/>
            </div>
          </li>
          <li className="mb-15">
            Select the <b>Add calendar</b> button.
          </li>
          <li className="mb-15">
            Update the name of your calendar (if desired).
          </li>
        </ol>
        <div className="b-border mv-20"></div>
        <div className="fw-700 fs-18 mb-15">Adding to Apple Calendar</div>
        <ol>
          <li className="mb-15 mt-15">
            Copy an iCal Link from the <b>Setup {'>'}</b> <DocLink to="/admin/setup/calendar">Calendar</DocLink> page.
          </li>
          <li className="mb-15 mt-15">
            Open the Calendar app on your iOS device.
          </li>
          <li className="mb-15">
            Navigate to your list of calendars.
            <div className="mt-15">
              <Image src={iCalAppleCalendarButtonImage} style={{ width: '100%', maxWidth: 300, border: '1px solid #CCC' }}/>
            </div>
          </li>
          <li className="mb-15 mt-15">
            Select the <b>Add Calendar</b> button and then <b>Add Subscription Calendar</b>.
            <div className="mt-15">
              <Image src={iCalAppleAddCalendarImage} style={{ width: '100%', maxWidth: 300, border: '1px solid #CCC' }}/>
            </div>
          </li>
          <li className="mb-15">
            Paste the iCal Link in the <b>Subscription URL</b> field.
            <div className="mt-15">
              <Image src={iCalAppleSubscribeImage} style={{ width: '100%', maxWidth: 300, border: '1px solid #CCC' }}/>
            </div>
          </li>
          <li className="mb-15">
            Select the <b>Subscribe</b> button.
          </li>
          <li className="mb-15">
            Update the name of your calendar (if desired).
          </li>
        </ol>
        <div className="b-border mv-50"></div>
      </FloatingContainer>
    )
  }

  return (
    <AdminContent body={renderContent()}/>
  );
}

export default DocsCalendarLinksPage;

import React, { useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import { Row, Col, Image } from 'antd';
import AdminContent from '../../../components/adminContent';
import FloatingContainer from '../../../components/floatingContainer'
import { BackToHomeLink, DocLink, DocArrowLink } from '../../../components/docComponents'
import iosDownloadButton from '../../../images/ios-download-button.png';
import androidDownloadButton from '../../../images/android-download-button.png';
import clientQuestionnaireImage from '../../../images/client-questionnaire.png';
import djTasksSendTextImage from '../../../images/dj-tasks-send-text.png';
import djTasksTextMessageImage from '../../../images/dj-tasks-text-message.png';
import djSendTextStepImage from '../../../images/dj-send-text-step.png';
import specialEventsTemplateImage from '../../../images/special-events-template-example.png';
import clientSpecialEventTemplateImage from '../../../images/client-special-event-template.png';
import clientSpecialEventSelectionImage from '../../../images/client-special-event-selection.png';
import contractTemplateExampleImage from '../../../images/contract-template-example.png';
import useDocumentTitle from '../../../hooks/useDocumentTitle';

const DocsSetupPage = () => {

  useDocumentTitle("Getting Started | Documentation")
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const scrollWithOffset = (el) => {
    const offset = 15;
    const bodyRect = document.getElementsByClassName("admin-content--body-main")[0].getBoundingClientRect().top;
    const bodyScrollTop = document.getElementsByClassName("admin-content--body-main")[0].scrollTop
    const elementRect = el.getBoundingClientRect().top;
    const elementPosition = elementRect - bodyRect;
    const offsetPosition = elementPosition - offset + bodyScrollTop;
    document.getElementsByClassName("admin-content--body-main")[0].scrollTo({top: offsetPosition, behavior: 'smooth'})
  }

  const renderHashLink = (id, text) => {
    return (
      <HashLink to={`#${id}`} scroll={scrollWithOffset} style={{ display: "inline-block"}}>
        <div className="blue-link">{text}</div>
      </HashLink>
    )
  }

  const renderContent = () => {
    return (
      <>
        <FloatingContainer className="ph-20 bg-white" verticalPadding={20} maxWidth={1000}>
          <BackToHomeLink/>
          <div className="fw-700 fs-24 mt-20">Getting Started</div>
          <div className="fw-500 fs-16 c-text-gray">Learn how to setup your account to get the most out of your experience.</div>
          <div className="bg-gray radius-8 p-20 mt-20">
            <div className="fw-500 line-1-4">
              Before you create your first event, there are a few things you might want to setup. You can always make changes later, but this will save you time in the future and help you get the most out of DJ Planning Center.
            </div>
          </div>
          <div className="mt-20">
            {/* <div className="fw-700 fs-18 mt-20">Overview</div> */}
            <ol>
              <li>{renderHashLink("setup-special-event-templates", "Setup Special Event Templates")}</li>
              <li>{renderHashLink("create-packages-addons", "Create Packages & Add-Ons")}</li>
              <li>{renderHashLink("setup-contract-templates", "Setup Contract Templates")}</li>
              <li>{renderHashLink("create-questionnaires", "Create Questionnaires")}</li>
              <li>{renderHashLink("build-workflows", "Build Workflows")}</li>
              <li>{renderHashLink("add-staff", "Add Staff")}</li>
              <li>{renderHashLink("download-the-app", "Download the App")}</li>
            </ol>
          </div>
          <div className="b-border mt-50 mb-50"></div>
          <Row gutter={[15]}>
            <Col flex="0">
              <div className="doc-number">1</div>
            </Col>
            <Col flex="1">
              <div className="fw-700 fs-18" id="setup-special-event-templates">Setup Special Event Templates</div>
              <div className="fw-500 mb-15 line-1-4 mt-15 bg-gray p-15 radius-8">
                One of the key features of DJ Planning Center is special event templates. This is a way for you to create a list of all possible special events a client can choose from. We recommend creating one for each type of event you might perform.  Once a template is created, you can simply apply it when creating an event. This gives your clients a starting point when selecting music and creating a timeline.
               <div className="mt-10">
                <DocArrowLink to="/admin/docs/special-event-templates"/>
               </div>
              </div>
              <ol>
                <li className="mb-15">
                  Go to the <DocLink to="/admin/special-event-templates">Special Event Templates</DocLink> page and click the button to create a template.
                </li>
                <li className="mb-15">
                  In the <b>New Template</b> modal, enter a name and select <b>Continue</b>.
                </li>
                <li className="mb-15">
                  Create as many sections and special events as you would like. Think of "sections" as things like "Ceremony" and "Reception" if the template is for a wedding. This is a way to logically group special events together.
                  <div className="mt-15">You will give each special event a type of "Special Song", "Playlist", or "No Songs". This determines how the client will select music (if applicable) for each one.</div>
                  <div className="mt-15 fw-600">Example:</div>
                  <div className="mt-15">
                    <Image src={specialEventsTemplateImage} style={{ width: '100%', maxWidth: 400 }}/>
                  </div>
                </li>
              </ol>
              <div className="mt-15">
                After applying the template to an event, clients will be able to select from this list when adding music.
                <div className="mt-15">
                  <Row gutter={[20,20]}>
                    <Col>
                      <Image src={clientSpecialEventTemplateImage} style={{ width: '100%', maxWidth: 200 }}/>
                    </Col>
                    <Col>
                      <Image src={clientSpecialEventSelectionImage} style={{ width: '100%', maxWidth: 200 }}/>
                    </Col>
                  </Row>
                </div> 
              </div>
              <div className="ml-20 mt-20">
                <button className="small-primary-button mb-5 mt-15" onClick={() => navigate("/admin/special-event-templates")}>Go to special event templates</button>
              </div>
            </Col>
          </Row>
          <div className="b-border mv-50"></div>
          <Row gutter={[15]}>
            <Col flex="0">
              <div className="doc-number">2</div>
            </Col>
            <Col flex="1">
              <div className="fw-700 fs-18" id="create-packages-addons">Create Packages & Add-Ons</div>
              <div className="fw-500 mb-15 line-1-4 mt-15 bg-gray p-15 radius-8">
                Configure all the packages and add-ons your company offers so you can assign them to an event. The breakdown of items and pricing will be displayed on the event details page and can be automatically populated on contracts.
              </div>
              <div className="fw-700 fs-16 mb-10">
                Packages
              </div>
              <ol>
                <li className="mb-15">
                  Go to the <span className="blue-link" onClick={() => navigate("/admin/packages")}>Packages</span> page and select <b>New Package</b>.
                </li>
                <li className="mb-15">
                  In the <b>New Package</b> modal, enter a name and select <b>Continue</b>.
                </li>
                <li className="mb-15">
                  Enter the details of your package, upload a photo (optional), and select <b>Save</b>.
                </li>
              </ol>
              <div className="ml-20 mt-20">
                <button className="small-primary-button mb-5" onClick={() => navigate("/admin/packages")}>Go to packages</button>
              </div>
              <div className="fw-700 fs-16 mt-20 mb-10">
                Add-Ons
              </div>
              <ol>
                <li className="mb-15">
                  Go to the <span className="blue-link" onClick={() => navigate("/admin/addons")}>Add-Ons</span> page and select <b>New Add-On</b>.
                </li>
                <li className="mb-15">
                  In the <b>New Add-On</b> modal, enter a name and select <b>Continue</b>.
                </li>
                <li className="mb-15">
                  Enter the details of your add-on, upload a photo (optional), and select <b>Save</b>.
                  <div className="bg-gray pv-10 mt-10 ph-15 radius-8">Note: Assigning an add-on category will allow you to group add-ons together for better organization.</div>
                </li>
              </ol>
              <div className="ml-20 mt-20">
                <button className="small-primary-button mb-5" onClick={() => navigate("/admin/addons")}>Go to add-ons</button>
              </div>
            </Col>
          </Row>
          <div className="b-border mv-50"></div>
          <Row gutter={[15]}>
            <Col flex="0">
              <div className="doc-number">3</div>
            </Col>
            <Col flex="1">
              <div className="fw-700 fs-18" id="setup-contract-templates">Setup Contract Templates</div>
              <div className="fw-500 mb-15 line-1-4 mt-15 bg-gray p-15 radius-8">
                Create contracts that can be used as a template later. Within the editor, you can add contract variables that automatically populate information based on the event.
                <div className="mt-10">
                <DocArrowLink to="/admin/docs/contracts"/>
               </div>
              </div>
              <ol>
                <li className="mb-15">
                  Go to the <DocLink to="/admin/contract-templates">Contract Templates</DocLink> page and select <b>New Template</b>.
                </li>
                <li className="mb-15">
                  In the <b>New Contract Template</b> modal, enter a name for the template and select <b>Continue</b>.
                </li>
                <li className="mb-15">
                  Use the editor to create your contract. The formatting tools in the toolbar can be used to customize the look and feel of the document.
                  <div className="bg-gray pv-10 ph-15 radius-8 mt-10">Note: If you have an existing contract, simply copy and paste the contents into the editor and update the formatting if needed.</div>
                </li>
                <li className="mb-15">
                  Place your cursor at a specific location in the editor and select the <b>Insert</b> option in the toolbar to add <b>Contract Variables</b> or <b>Services</b>.
                  <ul className="mt-10">
                    <li>
                      <b>Contract Variables.</b> This allows you to add a placeholder within the text that will automatically populate information based on the event. You can add placeholders for things like the client's name and venue information.
                    </li>
                    <li className="mt-10">
                      <b>Services.</b> This creates a pricing table within the contract that atuomatically populates any packages and/or add-ons that have been assigned to the event. It will include line itmes for each service, the subtotal, any discounts applied, and the overall total.
                    </li>
                  </ul>
                </li>
                <Image src={contractTemplateExampleImage} style={{ width: '100%', maxWidth: 600, border: '1px solid #CCC' }}/>
              </ol>
              <div className="ml-20 mt-20">
                <button className="small-primary-button mb-5" onClick={() => navigate("/admin/contract-templates")}>Go to Contract Templates</button>
              </div>
            </Col>
          </Row>
          <div className="b-border mv-50"></div>
          <Row gutter={[15]}>
            <Col flex="0">
              <div className="doc-number">4</div>
            </Col>
            <Col flex="1">
              <div className="fw-700 fs-18" id="create-questionnaires">Create Questionnaires</div>
              <div className="fw-500 mb-15 line-1-4 mt-15 bg-gray p-15 radius-8">
                Use our form builder to create questionnaires for different event types or service offerings. Once they are setup, simply assign one or more to a client when creating an event. 
              </div>
              <ol>
                <li className="mb-15">
                  Go to the <DocLink to="/admin/questionnaires">Questionnaires</DocLink> page and select <b>New Questionnaire</b>.
                </li>
                <li className="mb-15">
                  In the <b>New Questionnaire</b> modal, enter a name and select a template. You can choose a template we created to give you a starting point or "Blank Questionnaire" to create one from scratch.
                </li>
                <li className="mb-15">
                  Create sections and add questions. Drag and drop the questions within a section to reorder them if needed.
                </li>
                <li className="mb-15">
                  Assign one or more questionnaires when creating an event. Clients will see their questionnaires and be able to fill them out when they login to their account.
                  <div className="mt-15">
                    <Image src={clientQuestionnaireImage} style={{ width: '100%', maxWidth: 200 }}/>
                    <div className="fs-12 mt-10 ml-5 c-text-gray">(Client's view)</div>
                  </div>
                </li>
              </ol>
              <div className="ml-20 mt-20">
                <button className="small-primary-button mb-5 mt-15" onClick={() => navigate("/admin/questionnaires")}>Go to questionnaires</button>
              </div>
            </Col>
          </Row>
          <div className="b-border mv-50"></div>
          <Row gutter={[15]}>
            <Col flex="0">
              <div className="doc-number">5</div>
            </Col>
            <Col flex="1">
              <div className="fw-700 fs-18" id="build-workflows">Build Workflows</div>
              <div className="fw-500 mb-15 line-1-4 mt-15 bg-gray p-15 radius-8">
                Create a series of tasks that will help you remember important touch points leading up to an event. For example, create one that says “send an email to initiate a planning meeting 30 days before the event.” After your tasks are created, the workflow can be applied to an event, and the tasks will appear under <span className="blue-link" onClick={() => navigate("/admin/tasks")}>My Tasks</span> when they are due. 
              </div>
              <ol>
                <li className="mb-15">
                  Go to the <span className="blue-link" onClick={() => navigate("/admin/workflows")}>Workflows</span> page and select <b>New Workflow</b>.
                </li>
                <li className="mb-15">
                  In the <b>New Workflow</b> modal, enter a name and select a template. You can choose an existing template we have created to give you a starting point or <b>Blank Workflow</b> to create one from scratch.
                </li>
                <li className="mb-15">
                  Add / edit as many steps as you would like. If <b>Send Email</b> or <b>Send Text</b> is selected for the action type, you can create an optional message that will populate automatically in a text message or email if completing the task from the mobile app.
                  <div className="mt-15">
                    <Image src={djSendTextStepImage} style={{ width: '100%', maxWidth: 300 }}/>
                    <div className="fs-12 mt-10 ml-5 c-text-gray">(New Workflow screen)</div>
                  </div>
                  <div className="mt-15">
                    <Row gutter={[20,20]} style={{ marginBottom: 15 }}>
                      <Col>
                        <Image src={djTasksSendTextImage} style={{ width: '100%', maxWidth: 200 }}/>
                      </Col>
                      <Col>
                        <Image src={djTasksTextMessageImage} style={{ width: '100%', maxWidth: 200 }}/>
                      </Col>
                    </Row>
                    <div className="fs-12 ml-5 c-text-gray">(DJ's view in mobile app)</div>
                  </div>
                </li>
              </ol>
              <div className="ml-20 mt-20">
                <button className="small-primary-button mb-5" onClick={() => navigate("/admin/workflows")}>Go to workflows</button>
              </div>
            </Col>
          </Row>
          <div className="b-border mv-50"></div>
          <Row gutter={[15]}>
            <Col flex="0">
              <div className="doc-number">6</div>
            </Col>
            <Col flex="1">
              <div className="fw-700 fs-18" id="add-staff">Add Staff</div>
              <div className="fw-500 mb-15 line-1-4 mt-15 bg-gray p-15 radius-8">
                If you have other DJs or assistants who work at your company, you can add them to the account and assign them events. 
              </div>
              <ol>
                <li className="mb-15">
                  Go to the <span className="blue-link" onClick={() => navigate("/admin/staff")}>Staff</span> page and create a new staff account.
                </li>
                <li className="mb-15">
                  Enter the staff member's details and select which permissions you would like them to have. By default, they will only be able to see events that are assigned to them. You have the option of allowing them to view all of the company's events.
                </li>
              </ol>
              <div className="ml-20 mt-20">
                <button className="small-primary-button mb-5" onClick={() => navigate("/admin/staff")}>Go to staff</button>
              </div>
            </Col>
          </Row>
          <div className="b-border mv-50"></div>
          <Row gutter={[15]}>
            <Col flex="0">
              <div className="doc-number">7</div>
            </Col>
            <Col flex="1">
              <div className="fw-700 fs-18" id="download-the-app">Download the App</div>
              <div className="fw-500 mb-15 line-1-4 mt-15 bg-gray p-15 radius-8">
                To get the most out of DJ Planning Center, download the mobile app and turn on your push notifications. This is the best way to manage your events on the go.
              </div>
              <div className="mt-30 mb-30">
                <a href="https://play.google.com/store/apps/details?id=com.djpcapp" target="_blank">
                  <img src={androidDownloadButton} height={40}/>
                </a>
                <a href="https://apps.apple.com/us/app/dj-planning-center/id1638751912" target="_blank">
                  <img src={iosDownloadButton} height={40} className="ml-10"/>
                </a>
              </div>
            </Col>
          </Row>
          <div className="b-border mv-50"></div>
          <Row gutter={[15]}>
            <Col flex="1">
              <div className="fw-700 fs-18">Learn more</div>
              <div className="fw-500 mb-15 line-1-4 mt-15 bg-gray p-15 radius-8">
                Explore our <DocLink to="/admin/docs">Documentation</DocLink> page to learn more about how to use DJ Planning Center.
              </div>
              <ul>
                <li className="mb-15">
                  <DocLink to="/admin/docs/inviting-clients">How do I invite clients?</DocLink>
                </li>
                <li className="mb-15">
                  <DocLink to="/admin/docs/what-clients-see">What will my clients see?</DocLink>
                </li>
              </ul>
            </Col>
          </Row>
        </FloatingContainer>
      </>
    )
  }

  return (
    <AdminContent body={renderContent()}/>
  );
}

export default DocsSetupPage;

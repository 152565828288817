import React from 'react';

const FloatingContainer = props => {
  return (
    <div className={`floating-container ${props.className ?? ""}`}>
        <div className="floating-container--inner" style={{ maxWidth: props.maxWidth ?? 1200, paddingTop: props.verticalPadding ?? 0, paddingBottom: props.verticalPadding ?? 0 }}>
          { props.children }
        </div>
      </div>
  );
};

export default FloatingContainer;

import React, { useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import { BackToHomeLink, DocLink } from '../../../../components/docComponents'
import AdminContent from '../../../../components/adminContent';
import FloatingContainer from '../../../../components/floatingContainer'
import useDocumentTitle from '../../../../hooks/useDocumentTitle';

const DocsInvoiceTemplatesPage = () => {

  useDocumentTitle("Invoice Templates | Documentation")
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const scrollWithOffset = (el) => {
    const offset = 15;
    const bodyRect = document.getElementsByClassName("admin-content--body-main")[0].getBoundingClientRect().top;
    const bodyScrollTop = document.getElementsByClassName("admin-content--body-main")[0].scrollTop
    const elementRect = el.getBoundingClientRect().top;
    const elementPosition = elementRect - bodyRect;
    const offsetPosition = elementPosition - offset + bodyScrollTop;
    document.getElementsByClassName("admin-content--body-main")[0].scrollTo({top: offsetPosition, behavior: 'smooth'})
  }

  const renderHashLink = (id, text) => {
    return (
      <HashLink to={`#${id}`} scroll={scrollWithOffset} style={{ display: "inline-block"}}>
        <div className="blue-link">{text}</div>
      </HashLink>
    )
  }

  const renderContent = () => {
    return (
      <FloatingContainer className="ph-20 bg-white" verticalPadding={20} maxWidth={1000}>
        <BackToHomeLink/>
        <div className="fw-700 fs-24 mt-20">Invoice Templates</div>
        <div className="fw-500 fs-16 c-text-gray">Use templates to populate default settings when creating invoices.</div>
        <div className="b-border mv-20"></div>
        <div className="mt-50">
          <ul>
            <li>{renderHashLink("creating-an-invoice-template", "Creating an Invoice Template")}</li>
            <li>{renderHashLink("applying-an-invoice-template", "Applying an Invoice Template")}</li>
          </ul>
        </div>

        <div className="b-border mv-50"></div>
        <div className="fw-700 fs-20" id="creating-an-invoice-template">Creating an Invoice Template</div>

        <ol>
          <li className="mb-15 mt-15">
            Go to the <b>Setup {">"}</b> <DocLink to="/admin/invoice-templates">Invoice Templates</DocLink> page and select the <b>New Template</b> button.
          </li>
          <li className="mb-15">
            In the <b>New Invoice Template</b> modal, enter a name for the template and click <b>Continue</b>.
          </li>
          <li className="mb-15">
            Enter any <b>Notes</b> you would like to display on your invoices.
          </li>
          <li className="mb-15">
            Select the option for <b>Tips</b> if you would like to give your client an option to add a tip while paying the invoice.
          </li>
          <li className="mb-15">
            Turn on <b>Payment Reminders</b> if you would like DJ Planning Center to automatically send reminders to your client when a payment is due
          </li>
          <li className="mb-15">
            Turn on a <b>Payment Schedule</b> if you would like to split your invoice into multiple payments. For example, you can set your holding fee to be due within 7 days and the remaining balance to be due 30 days before the event. The amount for the last payment will automatically be populated based on the total of all the other payments. The dropdown next to each date can be used to help calculate the date based on a certain criteria such as "X" amount of days before the event.
          </li>
        </ol>
        <div className="ml-20 mt-20">
          <button className="small-primary-button mb-5" onClick={() => navigate("/admin/invoice-templates")}>Go to Invoice Templates</button>
        </div>

        <div className="b-border mv-50"></div>
        <div className="fw-700 fs-20" id="applying-an-invoice-template">Applying an Invoice Template</div>

        <ol>
          <li className="mb-15 mt-15">
            Go to the <DocLink to="/admin/invoices">Invoices</DocLink> page and select the <b>New Invoice</b> button.
          </li>
          <li className="mb-15">
            In the <b>New Invoice</b> modal, select the invoice template you created, select a client, and click <b>Continue</b>.
          </li>
          <li className="mb-15">
            The invoice will automatically populate with the settings you defined in your template.
          </li>
          <li className="mb-15">
            Make any necessary adjustments and follow the remaining steps to send the invoice to your client.
            <div className="bg-gray pv-10 ph-15 radius-8 mt-10"><b>Note:</b> For more information, check out our <DocLink to="/admin/docs/invoices-payments">Invoices & Payments</DocLink> documentation. </div>
          </li>
        </ol>

        <div className="b-border mv-50"></div>
      </FloatingContainer>
    )
  }

  return (
    <AdminContent body={renderContent()}/>
  );
}

export default DocsInvoiceTemplatesPage;

import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import FloatingContainer from '../../components/floatingContainer'
import appHeroImage from '../../images/app-hero.png';

const ContactThankYouPage = () => {

  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const renderContent = () => {
      return (
      <div>
        <FloatingContainer className="bg-white text-center p-20" verticalPadding={60} maxWidth={600}>
          <div className="fs-30 fw-700 line-1-2 text-center">Thank you for your message!</div>
          <div className="fs-16 c-text-gray mt-20 line-1-3">We will get back to you as soon as we can.</div>
          <button className="primary-button mt-30" style={{ width: 300 }} onClick={() => navigate("/")}>Got it!</button>
        </FloatingContainer>
        <FloatingContainer className="bg-gray ph-20" verticalPadding={60} maxWidth={600}>
          <img src={appHeroImage} width={"100%"}/>
        </FloatingContainer>
      </div>
      )
  }

  return (
    <div>
      { renderContent() }
    </div>
  );
}

export default ContactThankYouPage;

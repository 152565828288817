import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { Row, Col, Form, Modal, Dropdown, Menu } from 'antd';
import { getWorkflows, getSystemWorkflows, deleteWorkflow, createWorkflow, getSystemWorkflow } from '../../../api';
import LoadingSpinner from '../../../components/loading';
import AdminContent from '../../../components/adminContent';
import useApi from '../../../hooks/useApi';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { 
  renderInputField,
  renderSearchSelectField
} from '../../../components/formFields'
import FloatingContainer from '../../../components/floatingContainer'
import { MdOutlineClose, MdOutlineMoreHoriz } from "react-icons/md";
import { FiCheckCircle } from "react-icons/fi";
import { BsArrowRight } from "react-icons/bs";
import emptyStateImage from '../../../images/empty-state-icon.png';
import { isArray } from "lodash";
import useDocumentTitle from '../../../hooks/useDocumentTitle';

const WorkflowsPage = () => {

  const [isLoading, setLoading] = useState(true);
  const [isNewModalVisible, setNewModalVisible] = useState(false);
  const [isConfirmRemoveVisible, setConfirmRemoveVisible] = useState(false);
  const [templates, setTemplates] = useState([]);
  const [workflows, setWorkflows] = useState([]);
  const [selectedWorkflow, setSelectedWorkflow] = useState({});

  useDocumentTitle("Workflows")
  const [sendRequest] = useApi()
  const navigate = useNavigate();

  const [form] = Form.useForm();

  useEffect(() => {
    window.scrollTo(0, 0);
    refreshPage()
  }, []);

  const refreshPage = async () => {

    try {
      const workflowResults = await sendRequest(getWorkflows());
      setWorkflows(workflowResults);

      const templateResults = await sendRequest(getSystemWorkflows("active"));
      var templateOptions = []
      const blankOption = {
        value: "blank",
        text: "Blank Workflow"
      }
      templateOptions.push(blankOption);
      for (const template of templateResults) {
        const option = {
          value: template.system_workflow_id,
          text: template.workflow_name
        }
        templateOptions.push(option);
      }
      setTemplates(templateOptions);

      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  const menu = (item) => {
    return (
      <Menu>
        <Menu.Item>
          <div onClick={() => handleEdit(item)}>
            <EditOutlined style={{ marginRight: 8}}/> Edit
          </div>
        </Menu.Item>
        <Menu.Item>
          <div onClick={() => handleDelete(item)}>
            <DeleteOutlined style={{ marginRight: 8}}/> Remove
          </div>
        </Menu.Item>
      </Menu>
    )
  };
  
  const onNewClick = () => {
    form.setFieldsValue({ name: "", template_id: "blank" })
    setNewModalVisible(true);
  }

  const handleCancel = () => {
    setNewModalVisible(false);
  };

  const handleEdit = (workflow) => {
    navigate(`/admin/workflows/${workflow.workflow_id}/edit`)
  };

  const handleDelete = (workflow) => {
    setSelectedWorkflow(workflow)
    setConfirmRemoveVisible(true)
  };

  const confirmDeleteWorkflow = async () => {
    try {
      await sendRequest(deleteWorkflow(selectedWorkflow.workflow_id));
      await refreshPage()
      setConfirmRemoveVisible(false)
    } catch(error) {
      setConfirmRemoveVisible(false)
    }
  }

  const onSubmit = async (values) => {
    try {
      var steps = []
      if (values.template_id != "blank") {
        const templateResults = await sendRequest(getSystemWorkflow(values.template_id));
        steps = templateResults.steps;
      }
      const body = {
        workflow_name: values.name,
        steps: steps
      }
      const results = await sendRequest(createWorkflow(body))
      navigate(`/admin/workflows/${results.workflow_id}/edit`)
    } catch {
      setNewModalVisible(false);
    }
  }

  const renderHeader = () => {
    return (
      <Row align="middle" className="p-20">
        <Col flex={1}>
          <div className="fs-24 fw-700">Workflows</div>
          <div className="blue-link flex-row flex-1 flex-middle" onClick={() => navigate("/admin/docs/workflows")}>Learn more <BsArrowRight style={{ marginLeft: 5 }}/></div>
        </Col>
        <Col>
          { workflows.length > 0 && (
            <button className="page-title-button" onClick={onNewClick}>New Workflow</button>
          )}
        </Col>
      </Row>
    )
  }

  const renderWorkflow = (workflow, index) => {
    const numSteps = isArray(workflow.steps) ? workflow.steps.length : 0
    return (
      <div key={index} className="flex-row p-10 b-border">
        <div onClick={() => handleEdit(workflow)}>
          <div className="card-icon"><FiCheckCircle/></div>
        </div>
        <div className="flex-col justify-content-center flex-1 mh-15" onClick={() => handleEdit(workflow)}>
          <div className="fs-14 fw-700 line-1-2">{workflow.workflow_name}</div>
          <div className="fs-14 fw-500 c-text-gray line-1-1">{numSteps} { numSteps == 1 ? "step" : "steps" }</div>
        </div>
        <div className="display-flex flex-middle mr-5">
          <Dropdown overlay={menu(workflow)} placement="bottomRight" trigger="click">
            <div className="dots-container">
              <MdOutlineMoreHoriz style={{ fontSize: 24, color: '#999'}}/>
            </div>
          </Dropdown>
        </div>
      </div>
    )
  }

  const renderWorkflowBox = () => {
    return (
      <div className="shadow-card">
        { workflows.map((x,i) => renderWorkflow(x,i)) }
      </div>
    )
  }

  const renderWorkflows = () => {
    if (workflows.length == 0) {
      return (
        <div className="shadow-card ph-20 pv-50 text-center">
          <div>
            <img src={emptyStateImage} width={250}/>
          </div>
          <div className="fs-18 fw-700 mt-30">
            No workflows yet
          </div>
          <div className="fs-14 fw-500 c-text-gray">
            Create a workflow to get started!
          </div>
          <button className="primary-button mt-30" style={{ width: 250 }} onClick={onNewClick}>Create Workflow</button>
          <div className="mt-10">
            <div className="blue-link" onClick={() => navigate("/admin/docs/workflows")}>Learn More</div>
          </div>
        </div>
      )
    } else {
      return renderWorkflowBox()
    }
  }

  const renderContent = () => {
    if (isLoading) {
      return <LoadingSpinner/>
    }
    return (
      <FloatingContainer className="ph-20" verticalPadding={20} maxWidth={800}>
        { renderWorkflows() }
        { renderModal() }
        { renderConfirmRemoveModal() }
      </FloatingContainer>
    )
  }

  const renderConfirmRemoveModal = () => {
    return (
      <Modal visible={isConfirmRemoveVisible} closable={false} footer={null} width={400} wrapClassName="rounded-modal">
        <div className="text-right" onClick={() => setConfirmRemoveVisible(false)}><MdOutlineClose size={30} color={"#CCC"}/></div>
        <div className="mt-10">
          <div className="fw-700 fs-20 text-center">Remove Workflow</div>
          <div className="fw-500 fs-14 mt-20 mb-20 text-center">Are you sure you would like to remove <span className="fw-700">{selectedWorkflow.workflow_name}</span>? This action cannot be undone.</div>
          <button className="primary-button warning" type="button" onClick={() => confirmDeleteWorkflow()}>Remove Workflow</button>
          <div className="text-center mt-15">
            <div className="blue-link" onClick={() => setConfirmRemoveVisible(false)}>Cancel</div>
          </div>
        </div>
      </Modal>
    )
  }

  const renderModal = () => {
    return (
      <Modal visible={isNewModalVisible} footer={null} closable={false} wrapClassName="rounded-modal">
        <Row align="middle">
          <Col flex={1}>
            <div className="fw-700 fs-18">New Workflow</div>
          </Col>
          <Col>
            <div className="display-flex" onClick={handleCancel}><MdOutlineClose size={30} color={"#CCC"}/></div>
          </Col>
        </Row>
        <Form form={form} layout="vertical" name="new-workflow" onFinish={onSubmit}>
          <Row gutter={[10,10]} className="mt-15">
            <Col xs={24}>
              {renderInputField("Name", "name", true)}
            </Col>
            <Col xs={24}>
              { renderSearchSelectField("Template", "template_id", "Select a template", templates, true) }
            </Col>
          </Row>
          <div className="admin-modal-footer">
            <button className="primary-button" type="submit" onClick={() => {}}>Continue</button>
            <div className="text-center mt-15">
              <div className="blue-link" onClick={handleCancel}>Cancel</div>
            </div>
          </div>
        </Form>
      </Modal>
    )
  }

  return (
    <AdminContent header={renderHeader()} body={renderContent()}/>
  );
}

export default WorkflowsPage;

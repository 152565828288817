import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import qs from "qs";
import useApi from '../../../hooks/useApi';
import { 
  saveSpotifyAccessToken
} from '../../../api';
import LoadingSpinner from '../../../components/loading';
import FloatingContainer from '../../../components/floatingContainer'
import useDocumentTitle from '../../../hooks/useDocumentTitle';

const SpotifyLoginCallbackPage = () => {

  const [isLoading, setLoading] = useState(true);

  useDocumentTitle("Music Integration")
  const navigate = useNavigate();
  const location = useLocation();

  const [sendRequest] = useApi()

  useEffect(() => {
    window.scrollTo(0, 0);
    refreshPage();
  }, []);

  const refreshPage = async () => {
    try {
      const queryStrings = qs.parse(location.search, { ignoreQueryPrefix: true })
      // Check for error
      if (queryStrings.error) {
        setLoading(false)
        return
      }
      // Save tokens
      if (queryStrings.access_token && queryStrings.refresh_token) {
        const body = {
          access_token: queryStrings.access_token,
          refresh_token: queryStrings.refresh_token
        }
        const response = await sendRequest(saveSpotifyAccessToken(body))
        if (response.status != "success") {
          setLoading(false)
          return
        }
        navigate("/admin/setup/music-integration")
      }
    } catch (error) {
      setLoading(false)
    }
  }

  const renderContent = () => {
    return (
      <>
        <FloatingContainer className="ph-20 text-center" verticalPadding={50} maxWidth={800}>
          <div className="fw-700 fs-20 mt-20">There was an error logging into your Spotify account</div>
          <div className="fw-500 c-text-gray mt-5">Please try again later</div>
          <button className="small-primary-button mt-20" onClick={() => navigate("/admin/setup/music-integration")}>Back to Setup</button>
        </FloatingContainer>
      </>
    )
  }

  if (isLoading) {
    return (
      <div className="mt-80">
        <LoadingSpinner/>
      </div>
    )
  }

  return renderContent()
}

export default SpotifyLoginCallbackPage;
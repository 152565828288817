import { isUndefined, isNull, cloneDeep, isEmpty, isArray } from "lodash";

const isValid = (value) => {
  return !isUndefined(value) && !isNull(value) && value != ""
}

const getSpecialEventList = (sections) => {
  const sectionsClone = cloneDeep(sections)
  return sectionsClone.reduce((x,y) => {
    if (isNull(y.special_events)) {
      return x
    } else {
      return x.concat(y.special_events)
    }
  }, [])
}

const findSpecialEvent = (event_special_event_id, sections) => {
  const specialEvents = getSpecialEventList(sections)
  const index = specialEvents.findIndex(x => x.event_special_event_id == event_special_event_id)
  if (index > -1) {
    return specialEvents[index]
  }
  return {}
}

const getSpecialEventSongSummary = (event_special_event_id, sections) => {
  if (isValid(event_special_event_id)) {
    const specialEvents = getSpecialEventList(sections)
    const index = specialEvents.findIndex(x => x.event_special_event_id == event_special_event_id)
    if (index > -1) {
      const specialEvent = specialEvents[index]
      const isPlaylist = specialEvent.is_playlist ? true : false;
      const songs = isArray(specialEvent.songs) ? specialEvent.songs : []
      return {
        found: true,
        is_playlist: isPlaylist,
        songs: songs
      }
    }
  }
  return {
    found: false,
    is_playlist: false,
    songs: []
  }
}

const getSpecialEventName = (event_special_event_id, sections) => {
  const specialEvent = findSpecialEvent(event_special_event_id, sections)
  if (!isEmpty(specialEvent)) {
    return specialEvent.special_event_name
  } 
  return ""
}

const timelineHasSongs = (timelineSections, sections) => {
  const specialEvents = getSpecialEventList(sections)
  const timeline = cloneDeep(timelineSections)
  const allSpecialEventIds = specialEvents.map(x => x.event_special_event_id)
  const filtered = specialEvents.filter(x => (!isNull(x.songs) && isArray(x.songs) && x.songs.length > 0 && allSpecialEventIds.includes(x.event_special_event_id)))
  return filtered.length > 0
}

export {
  getSpecialEventList,
  findSpecialEvent,
  getSpecialEventSongSummary,
  getSpecialEventName,
  timelineHasSongs
}
import React, { useEffect, useState, useContext } from "react";
import { Row, Col, Menu, Dropdown, Modal, Input, Form, Button } from 'antd';
import { isNull, isEmpty, isArray, cloneDeep, sortBy } from 'lodash';
import { useNavigate, useLocation } from 'react-router-dom';
import qs from "qs";
import moment from 'moment';
import AdminContent from '../../components/adminContent';
import AppContext from '../../app/context';
import MusicModal from '../../components/musicModal';
import PlatformSelectionModal from '../../modals/platformSelectionModal';
import CustomSongModal from '../../modals/customSongModal';
import ShareModal from '../../modals/shareModal';
import useApi from '../../hooks/useApi';
import LoadingSpinner from '../../components/loading';
import { getRank } from '../../helpers/rankHelper';
import { isEventLocked } from '../../helpers/eventHelper';
import { 
  getClientEvent,
  getEventPlaylists, 
  getV2EventSpecialEvents, 
  updateV2EventSpecialEventActive, 
  createV2EventSpecialEventSong, 
  updateV2EventSpecialEventSongNote, 
  deleteV2EventSpecialEventSong, 
  getEventGenres, 
  getSystemGenres, 
  updateEventGenres,
  createEventSpecialEvent
} from '../../api';
import { 
  renderInputField,
  renderSearchSelectField,
  renderFormLabel
} from '../../components/formFields'
import { MdArrowForwardIos, MdOutlineSearch, MdRemoveCircleOutline, MdOutlineMoreHoriz, MdOutlineStickyNote2, MdOutlineClose, MdOutlineKeyboardArrowDown, MdCheckBoxOutlineBlank, MdCheckBox, MdOutlineEdit, MdOutlineIosShare, MdOutlineInfo, MdLaunch } from "react-icons/md";
import { FiMusic, FiStar, FiEye, FiDownload, FiPlus } from "react-icons/fi";
import { DeleteOutlined } from '@ant-design/icons';
import FloatingContainer from '../../components/floatingContainer'
import emptyMusicImage from '../../images/empty-music-icon.png';
import EventPermissions from '../../constants/eventPermissions';
import { hasEventPermission } from '../../helpers/permissionHelper';
import useDocumentTitle from '../../hooks/useDocumentTitle';

const BASE_URL = process.env.REACT_APP_BASE_URL;

const ClientMusicPage = () => {

  const [isLoading, setLoading] = useState(true);
  const [playlists, setPlaylists] = useState([]);
  const [specialEventSections, setSpecialEventSections] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState({});
  const [selectedTab, setSelectedTab] = useState("special-events");
  const [event, setEvent] = useState({});
  const [isNotesModalVisible, setNotesModalVisible] = useState(false);
  const [isGenreNotesModalVisible, setGenreNotesModalVisible] = useState(false);
  const [selectedSpecialEvent, setSelectedSpecialEvent] = useState({});
  const [selectedSong, setSelectedSong] = useState({});
  const [selectedSongNote, setSelectedSongNote] = useState("");
  const [selectedGenre, setSelectedGenre] = useState({});
  const [selectedGenreNote, setSelectedGenreNote] = useState("");
  const [isPlatformSelectionVisible, setPlatformSelectionVisible] = useState(false);
  const [isCustomSongModalVisible, setCustomSongModalVisible] = useState(false);
  const [isSpecialEventsModalVisible, setSpecialEventsModalVisible] = useState(false);
  const [isEventGenresModalVisible, setEventGenresModalVisible] = useState(false);
  const [isAddSpecialEventModalVisible, setAddSpecialEventModalVisible] = useState(false);
  const [selectedPlatform, setSelectedPlatform] = useState("spotify");
  const [systemGenres, setSystemGenres] = useState([]);
  const [eventGenres, setEventGenres] = useState([]);
  const [isShareMusicModalVisible, setShareMusicModalVisible] = useState(false);
  const [eventName, setEventName] = useState("");
  const [eventDate, setEventDate] = useState(moment());
  const [selectedSection, setSelectedSection] = useState({});
  const [eventLocked, setEventLocked] = useState(false);
  const [canEdit, setCanEdit] = useState(false);

  const [addSpecialEventForm] = Form.useForm();

  useDocumentTitle("Music")
  const { auth } = useContext(AppContext);
  const [sendRequest] = useApi()
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
    const queryStrings = qs.parse(location.search, { ignoreQueryPrefix: true })
    if (queryStrings.tab) {
      setSelectedTab(queryStrings.tab)
    }
    refreshPage()
  }, []);

  const refreshPage = async () => {
    try {

      const eventResults = await sendRequest(getClientEvent(auth.user.user_id, auth.user.event_id))
      setEvent(eventResults)
      setEventName(eventResults.event_name)
      setEventDate(moment(eventResults.event_date))
      setEventLocked(isEventLocked(eventResults, auth.user))
      setCanEdit(hasEventPermission(auth.user, EventPermissions.EDIT_MUSIC))

      const eventPlaylistsResults = await sendRequest(getEventPlaylists(eventResults.event_id));
      setPlaylists(eventPlaylistsResults);

      const systemGenresResults = await sendRequest(getSystemGenres())
      setSystemGenres(systemGenresResults)

      const eventGenresResults = await sendRequest(getEventGenres(eventResults.event_id));
      setEventGenres(eventGenresResults.genres ? eventGenresResults.genres : [])

      const eventSpecialEventsResults = await sendRequest(getV2EventSpecialEvents(eventResults.event_id));
      setSpecialEventSections(eventSpecialEventsResults)

      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  const refreshSpecialEvents = async () => {
    try {
      const eventSpecialEventsResults = await sendRequest(getV2EventSpecialEvents(event.event_id));
      setSpecialEventSections(eventSpecialEventsResults)
    } catch {}
  }

  const refreshEventGenres = async () => {
    try {
      const eventGenresResults = await sendRequest(getEventGenres(event.event_id));
      setEventGenres(eventGenresResults.genres ? eventGenresResults.genres : [])
    } catch {}
  }

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleSelect = async (song) => {
    setIsModalVisible(false);
    try {
      const body = {
        ...song,
        rank: getRank(selectedEvent.songs)
      }
      await sendRequest(createV2EventSpecialEventSong(event.event_id, selectedEvent.event_special_event_id, body))
      await refreshSpecialEvents()
    } catch {}
  };

  const handleSelectSong = (event) => {
    setSelectedEvent(event)
    setPlatformSelectionVisible(true)
  }

  const removeSelectedSong = async (song, specialEvent) => {
    try {
      await sendRequest(deleteV2EventSpecialEventSong(event.event_id, specialEvent.event_special_event_id, song.special_event_song_id))
      await refreshSpecialEvents()
    } catch {}
  }

  const onAddEditNote = (song, index, event) => {
    setSelectedSpecialEvent(event)
    setSelectedSong(song)
    setSelectedSongNote(song.notes)
    setNotesModalVisible(true)
  }

  const saveSpecialEventSongNote = async () => {
    try {
      await sendRequest(updateV2EventSpecialEventSongNote(event.event_id, selectedSpecialEvent.event_special_event_id, selectedSong.special_event_song_id, { notes: selectedSongNote }))
      await refreshSpecialEvents()
    } finally {
      setNotesModalVisible(false)
    }
  }

  const onClearNote = async (song, specialEvent) => {
    try {
      await sendRequest(updateV2EventSpecialEventSongNote(event.event_id, specialEvent.event_special_event_id, song.special_event_song_id, { notes: "" }))
      await refreshSpecialEvents()
    } catch {}
  }

  const onSelectSpecialEvent = async (specialEvent) => {
    try {
      await sendRequest(updateV2EventSpecialEventActive(event.event_id, specialEvent.event_special_event_id, { active: !specialEvent.active }))
      await refreshSpecialEvents()
    } catch {}
  }

  const onSelectGenre = async (genre) => {
    try {
      var newEventGenres = cloneDeep(eventGenres)
      const isSelected = !isEmpty(newEventGenres.find(x => x.system_genre_id == genre.system_genre_id))
      if (isSelected) {
        newEventGenres = newEventGenres.filter(x => x.system_genre_id != genre.system_genre_id)
      } else {
        const newGenre = {
          ...genre,
          notes: ""
        }
        newEventGenres.push(newGenre)
      }
      newEventGenres = sortBy(newEventGenres, "genre_name");
      setEventGenres(newEventGenres)

      await sendRequest(updateEventGenres(event.event_id, { genres: newEventGenres }))
    } catch {}
  }

  const selectPlatform = (platform) => {
    setSelectedPlatform(platform)
    setPlatformSelectionVisible(false)
    setIsModalVisible(true)
  }

  const displayCustomSong = () => {
    setPlatformSelectionVisible(false)
    setCustomSongModalVisible(true)
  }

  const saveCustomSong = async (values) => {
    const song = {
      title: values.title,
      artist: values.artist,
      image: "",
      notes: values.notes ?? "",
      duration: 0,
      url: "",
      preview_url: "",
      source: "custom",
    }

    handleSelect(song)
    setCustomSongModalVisible(false)
  }

  const getActiveSpecialEventCount = () => {
    return specialEventSections.reduce((x,y) => {
      var count = 0;
      if (y.special_events) {
        count = y.special_events.filter(x => x.active && x.songs_allowed).length
      }
      return x + count;
    }, 0)
  }

  const addCustomSpecialEvent = (section) => {
    setSelectedSection(section)
    setSpecialEventsModalVisible(false)
    setAddSpecialEventModalVisible(true)
    addSpecialEventForm.resetFields()
  }

  const saveCustomSpecialEvent = async (values) => {
    if (isEmpty(selectedSection)) {
      setAddSpecialEventModalVisible(false)
      return
    }
    try {
      const body = {
        special_event_name: values["special_event_name"],
        song_limit: values["type"] == "single" ? 1 : 0,
        songs_allowed: true,
        is_playlist: values["type"] == "playlist",
        recommended_playlist_id: null,
        rank: getRank(selectedSection.special_events),
        active: true,
        is_custom: true
      }
      await sendRequest(createEventSpecialEvent(event.event_id, selectedSection.event_special_event_section_id, body));
      await refreshSpecialEvents()
    } finally {
      setAddSpecialEventModalVisible(false)
    }
  }

  const onAddEditGenreNote = (genre) => {
    setSelectedGenre(genre)
    setSelectedGenreNote(genre.notes)
    setGenreNotesModalVisible(true)
  }

  const onClearGenreNote = async (genre) => {
    try {
      var newEventGenres = cloneDeep(eventGenres);
      for (var eventGenre of newEventGenres) {
        if (eventGenre.system_genre_id == genre.system_genre_id) {
          eventGenre.notes = ""
        }
      }
      setEventGenres(newEventGenres)
      await sendRequest(updateEventGenres(event.event_id, { genres: newEventGenres }))
      await refreshEventGenres()
    } catch {}
  }

  const saveGenreNote = async () => {
    try {
      var newEventGenres = cloneDeep(eventGenres);
      for (var genre of newEventGenres) {
        if (genre.system_genre_id == selectedGenre.system_genre_id) {
          genre.notes = selectedGenreNote
        }
      }
      setEventGenres(newEventGenres)
      await sendRequest(updateEventGenres(event.event_id, { genres: newEventGenres }))
      await refreshEventGenres()
      setGenreNotesModalVisible(false)
    } catch {}
  }

  const onRemoveGenre = async (genre) => {
    try {
      var newEventGenres = cloneDeep(eventGenres);
      var filteredEventGenres = newEventGenres.filter(x => x.system_genre_id != genre.system_genre_id)
      setEventGenres(filteredEventGenres)
      await sendRequest(updateEventGenres(event.event_id, { genres: filteredEventGenres }))
      await refreshEventGenres()
    } catch {}
  }

  const downloadPDF = async () => {
    window.open(`${BASE_URL}/pdfs/event/${event.event_id}/music`, "_blank")
  }

  const renderPlaylist = (playlist, index) => {
    const borderClass = index < playlists.length - 1 ? "b-border" : ""
    return (
      <div className={`ml-10 ${borderClass}`} key={index}>
        <Row key={index} align="middle" gutter={[15,15]} className="pv-10 pr-15" onClick={() => navigate(`/client/events/${event.event_id}/playlists/${playlist.event_playlist_id}`)}>
          <Col>
            <div className="card-icon"><FiMusic/></div>
          </Col>
          <Col flex={1}>
            <div className="fw-700">{playlist.playlist_name}</div>
            <div className="c-text-gray">{playlist.song_count} {playlist.song_count == 1 ? "Song" : "Songs"}</div>
          </Col>
          <Col>
            <div className="display-flex">
              <MdArrowForwardIos style={{ fontSize: 18, color: '#999'}}/>
            </div>
          </Col>
        </Row>
      </div>
    )
  }

  const songMenu = (song, index, event) => {
    return (
      <Menu>
        { !eventLocked && canEdit && (
          <Menu.Item>
            <div onClick={() => onAddEditNote(song, index, event)}>
              <MdOutlineStickyNote2 style={{ marginRight: 8}}/> Add / Edit Note
            </div>
          </Menu.Item>
        )}
        { !isEmpty(song.notes) && !eventLocked && canEdit && (
          <Menu.Item>
            <div onClick={() => onClearNote(song, event)}>
              <MdRemoveCircleOutline style={{ marginRight: 8}}/> Clear Note
            </div>
          </Menu.Item>
        )}
        { song.source == "spotify" && (
          <Menu.Item>
            <a href={song.url} target="_blank">
              <MdLaunch style={{ marginRight: 8}}/> Open in Spotify
            </a>
          </Menu.Item>
        )}
         { song.source == "apple" && (
          <Menu.Item>
            <a href={song.url} target="_blank">
              <MdLaunch style={{ marginRight: 8}}/> Open in Apple Music
            </a>
          </Menu.Item>
        )}
        { !eventLocked && canEdit && (
          <Menu.Item>
            <div onClick={() => removeSelectedSong(song, event)}>
              <DeleteOutlined style={{ marginRight: 8}}/> Remove Song
            </div>
          </Menu.Item>
      )}
      </Menu>
    )
  };

  const genreMenu = (genre) => {
    return (
      <Menu>
        <Menu.Item>
          <div onClick={() => onAddEditGenreNote(genre)}>
            <MdOutlineStickyNote2 style={{ marginRight: 8}}/> Add / Edit Note
          </div>
        </Menu.Item>
        { !isEmpty(genre.notes) && (
          <Menu.Item>
            <div onClick={() => onClearGenreNote(genre)}>
              <MdRemoveCircleOutline style={{ marginRight: 8}}/> Clear Note
            </div>
          </Menu.Item>
        )}
        <Menu.Item>
          <div onClick={() => onRemoveGenre(genre)}>
            <DeleteOutlined style={{ marginRight: 8}}/> Remove
          </div>
        </Menu.Item>
      </Menu>
    )
  };

  const actionMenu = () => {
    return (
      <Menu>
        <Menu.Item>
          <div onClick={() => navigate(`/event/${event.event_id}/music`)}>
            <FiEye style={{ marginRight: 8}}/> Preview
          </div>
        </Menu.Item>
        <Menu.Item>
          <div onClick={() => setShareMusicModalVisible(true)}>
            <MdOutlineIosShare style={{ marginRight: 8}}/> Share
          </div>
        </Menu.Item>
        <Menu.Item>
          <div onClick={() => downloadPDF()}>
            <FiDownload style={{ marginRight: 8}}/> View / Download PDF
          </div>
        </Menu.Item>
      </Menu>
    )
  };

  const renderSpecialEventSong = (item, index, event) => {
    const borderClass = (index + 1) < event.song_limit ? "b-border" : ""
    return (
      <div className={`song-card song-card--special-events-page no-shadow ph-15 ${borderClass}`} key={index}>
        <div className="song-card--row-1">
          <div className="song-card--image">
            { item.image ? (
              <img src={item.image} style={{ width: 50 }}/>
            ) : (
              <div className="song-image-icon"><FiMusic/></div>
            )}
          </div>
          <div className="song-card--title-artist">
            <div className="song-card--text fs-14 fw-700 line-1-5">{item.title}</div>
            <div className="song-card--text fs-14 c-text-gray line-1-5">{item.artist}</div>
          </div>
          <div className="song-card--icon">
            <Dropdown overlay={songMenu(item, index, event)} placement="bottomRight" trigger="click">
              <div className="song-card--dots-container">
                <MdOutlineMoreHoriz style={{ fontSize: 24, color: '#999'}}/>
              </div>
            </Dropdown>
          </div>
        </div>
        { !isEmpty(item.notes) && (
          <div className="song-card--row-2">
            { item.notes }
          </div>
        )}
      </div>
    )
  }

  const renderSelectSongButton = (event) => {
    const numSongs = isNull(event.songs) ? 0 : event.songs.length;
    if (numSongs == 0 && (eventLocked || !canEdit)) {
      return (
        <div className="p-10">
          <div className="bg-gray ph-10 pv-15 text-center c-text-gray radius-8 fs-12">
            No songs have been selected yet
          </div>
        </div>
      )
    }
    if (numSongs < event.song_limit) {
      return (
        <div className="pb-10">
          <div className="special-events-select-song" onClick={() => handleSelectSong(event)}>
            <div className="admin-link">Select Song</div>
          </div>
        </div>
      )
    }
    return null;
  }

  const renderActiveSpecialEventSection = (section, index) => {
    const sectionSpecialEvents = isArray(section.special_events) ? section.special_events : []
    const sectionActiveSpecialEvents = sectionSpecialEvents.filter(x => x.active && x.songs_allowed);
    if (sectionActiveSpecialEvents.length == 0) {
      return null;
    }
    return (
      <div className="mb-20" key={index}>
        <div className="mb-10 ml-5">
          <div className="fs-20 fw-700">{ section.section_name }</div>
          { section.description && (
            <div className="c-text-gray line-breaks">{ section.description }</div>
          )}
        </div>
        { sectionActiveSpecialEvents.map((x,i) => renderSpecialEvent(x,i))}
      </div>
    )
  }

  const renderPlaylistOrSongs = (specialEvent) => {
    if (specialEvent.is_playlist) {
      const songCount = isArray(specialEvent.songs) ? specialEvent.songs.length : 0
      return (
        <div className={`ml-10`}>
          <Row align="middle" gutter={[15,15]} className="pv-10 pr-15" onClick={() => navigate(`/client/events/${event.event_id}/special-events/${specialEvent.event_special_event_id}`)}>
            <Col>
              <div className="card-icon"><FiMusic/></div>
            </Col>
            <Col flex={1}>
              <div className="fw-700">Playlist</div>
              <div className="c-text-gray">{songCount} {songCount == 1 ? "Song" : "Songs"}</div>
            </Col>
            <Col>
              <div className="display-flex">
                <MdArrowForwardIos style={{ fontSize: 18, color: '#999'}}/>
              </div>
            </Col>
          </Row>
        </div>
      )
    } else {
      return (
        <>
          { isArray(specialEvent.songs) && specialEvent.songs.map((x,i) => renderSpecialEventSong(x,i,specialEvent))}
          { renderSelectSongButton(specialEvent) }
        </>
      )
    }
  }

  const renderSpecialEvent = (event, index) => {
    const tag = event.is_playlist ? "PLAYLIST" : (event.songs_allowed ? "SPECIAL SONG" : "NO SONGS")
    return (
      <div className="shadow-card mb-10" key={index}>
        <Row align="middle" gutter={[15,15]} className="b-border pv-15">
          <Col flex={1}>
            <div className="fw-600 c-text-gray fs-10 ph-15">{tag}</div>
            <div className="fw-700 fs-16 ph-15">{event.special_event_name}</div>
          </Col>
          <Col flex={0}>
          </Col>
        </Row>
        { renderPlaylistOrSongs(event) }
      </div>
    )
  }

  const suffix = (
    <MdOutlineSearch
      style={{
        fontSize: 18,
        color: '#CCC',
      }}
    />
  );

  const onSelectTab = (tab) => {
    navigate("/client/music?tab=" + tab, { replace: true })
    setSelectedTab(tab)
  }

  const renderHeader = () => {
    return (
      <div className="p-20">
        <div className="fs-24 fw-700">Music</div>
        <div className="tab-slider mt-5">
          <div className={`tab-slider--tab ${selectedTab == "special-events" ? "selected" : ""}`} onClick={() => onSelectTab("special-events")}>
            Special Events
          </div>
          <div className={`tab-slider--tab ${selectedTab == "preferences" ? "selected" : ""}`} onClick={() => onSelectTab("preferences")}>
            Preferences
          </div>
        </div>
      </div>
    )
  }

  const renderSpecialEventsTab = () => {
    if (getActiveSpecialEventCount() == 0 ) {
      return (
        <div className="pb-20">
          <div className="shadow-card ph-20 pv-50 text-center">
            <div>
              <img src={emptyMusicImage} width={200}/>
            </div>
            <div className="fs-14 fw-500 mt-30 ph-20">
              Before you select your music, tell us what special events you will be including in your event.
            </div>
            { !eventLocked && canEdit && (
              <button className="primary-button mt-30" style={{ width: 300 }} onClick={() => setSpecialEventsModalVisible(true)}>Select Special Events</button>
            )}
          </div>
        </div>
      )
    }

    return (
      <div className="pb-30">
        { specialEventSections.map((x,i) => renderActiveSpecialEventSection(x,i))}
        { !eventLocked && canEdit && (
          <div className="text-center mt-30 mb-10">
            <span className="bg-light-blue fw-600 c-blue ph-20 pv-10 radius-8 cursor-default" onClick={() => setSpecialEventsModalVisible(true)}>Add / Remove Special Events</span>
          </div>
        )}
      </div>
    )
  }

  const renderEventGenre = (genre, index) => {
    const borderClass = (index + 1) < eventGenres.length ? "b-border" : ""
    return (
      <div key={index} className={`mh-20 pv-10 ${borderClass}`}>
        <div className="fw-600 display-flex flex-middle">
          <div className="display-flex radius-8 flex-0 bg-light-blue p-10 flex-middle mr-10">
            <FiStar style={{ fontSize: 20, color: '#536DFE' }}/>
          </div>
          <div className="flex-1">
            { genre.genre_name }
          </div>
          { !eventLocked && canEdit && (
            <div className="flex-0">
              <Dropdown overlay={genreMenu(genre, index)} placement="bottomRight" trigger="click">
                <div className="song-card--dots-container">
                  <MdOutlineMoreHoriz style={{ fontSize: 24, color: '#999'}}/>
                </div>
              </Dropdown>
            </div>
          )}
        </div>
        { !isEmpty(genre.notes) && (
          <div className="bg-gray radius-8 pv-10 ph-15 fs-12 mt-5">{ genre.notes }</div>
        )}
      </div>
    )
  }

  const renderPreferencesTab = () => {
    return (
      <>
        <div className="shadow-card mb-20 pb-5">
          <Row align="middle" className="mh-20 pv-15 b-border">
            <Col flex={1}>
              <div className="fs-18 fw-700">Favorite Genres</div>
            </Col>
            <Col>
              { eventGenres.length > 0 && canEdit && !eventLocked && (
                <div className="plus-container mr-5" onClick={() => setEventGenresModalVisible(true)}>
                  <MdOutlineEdit style={{ fontSize: 20, color: '#536DFE'}}/>
                </div>
              )}
            </Col>
          </Row>
          { eventGenres.map((genre,index) => renderEventGenre(genre,index))}
          { eventGenres.length == 0 && canEdit && !eventLocked && (
            <div className="ph-30 pv-30 text-center">
              <div className="fw-700 fs-16 mb-5">What type of music do you like?</div>
              <div className="mb-15">This will give your DJ a general idea of what you would like them to play at your event.</div>
              <button className="primary-button" type="button" style={{ width: 250 }} onClick={() => setEventGenresModalVisible(true)}>Select Genres</button>
            </div>
          )}
          { eventGenres.length == 0 && (!canEdit || eventLocked) && (
            <div className="ph-15 pv-15 text-center">
              <div className="bg-gray pv-20 ph-20 radius-8">No genres have been selected yet.</div>
            </div>
          )}
        </div>
        <div className="shadow-card mb-20 pb-5">
          <Row align="middle" className="mh-20 pv-15 b-border">
            <Col flex={1}>
              <div className="fs-18 fw-700">Playlists</div>
            </Col>
          </Row>
          { playlists.map((x,i) => renderPlaylist(x,i))}
        </div>
      </>
    )
  }

  const renderTabs = () => {
   if (selectedTab == "special-events") {
      return renderSpecialEventsTab();
    } else {
      return renderPreferencesTab()
    }
  }

  const renderAddEditNotesModal = () => {
    return (
      <Modal visible={isNotesModalVisible} footer={null} onCancel={() => setNotesModalVisible(false)} width={400} closable={false} maskClosable={false} wrapClassName="rounded-modal">
        <Row align="middle">
          <Col flex={1}>
            <div className="fw-700 fs-18">Add / Edit Note</div>
          </Col>
          <Col>
            <div className="display-flex" onClick={() => setNotesModalVisible(false)}><MdOutlineClose size={30} color={"#CCC"}/></div>
          </Col>
        </Row>
        <div className="">
          <div className="pt-10 pb-10">
            <Input.TextArea 
              rows={3}
              value={selectedSongNote}
              onChange={(e) => setSelectedSongNote(e.target.value)}
              size="large"/>
          </div>
          <button className="primary-button" type="button" onClick={() => saveSpecialEventSongNote()}>Save</button>
          <div className="text-center mt-15">
            <div className="blue-link" onClick={() => setNotesModalVisible(false)}>Cancel</div>
          </div>
        </div>
      </Modal>
    )
  }

  const renderAddEditGenreNotesModal = () => {
    return (
      <Modal visible={isGenreNotesModalVisible} footer={null} onCancel={() => setGenreNotesModalVisible(false)} width={400} closable={false} maskClosable={false} wrapClassName="rounded-modal">
        <Row align="middle">
          <Col flex={1}>
            <div className="fw-700 fs-18">Add / Edit Note</div>
          </Col>
          <Col>
            <div className="display-flex" onClick={() => setGenreNotesModalVisible(false)}><MdOutlineClose size={30} color={"#CCC"}/></div>
          </Col>
        </Row>
        <div className="">
          <div className="pt-10 pb-10">
            <Input.TextArea 
              rows={3}
              value={selectedGenreNote}
              onChange={(e) => setSelectedGenreNote(e.target.value)}
              size="large"/>
          </div>
          <button className="primary-button" type="button" onClick={() => saveGenreNote()}>Save</button>
          <div className="text-center mt-15">
            <div className="blue-link" onClick={() => setGenreNotesModalVisible(false)}>Cancel</div>
          </div>
        </div>
      </Modal>
    )
  }

  const renderSpecialEventModalSection = (section, index) => {
    const sectionSpecialEvents = isArray(section.special_events) ? section.special_events : []
    const sectionSongSpecialEvents = sectionSpecialEvents.filter(x => x.songs_allowed);
    return (
      <>
        <Row gutter={[20,20]} align="middle" key={index} className="pb-10 pt-15">
          <Col flex={1}>
            <div className="fw-700 fs-18 pl-5">{section.section_name}</div>
          </Col>
        </Row>
        <div className="shadow-card mb-5">
          { sectionSongSpecialEvents.map((x,i) => renderSpecialEventModalRow(x,i))}
          <Row gutter={[15,15]} align="middle" key={index} className="p-15" onClick={() => addCustomSpecialEvent(section)}>
            <Col flex={0}>
              <div className="special-event-add-icon"><FiPlus/></div>
            </Col>
            <Col flex={1}>
              <div className="fw-600">Add Custom Special Event</div>
            </Col>
          </Row>
        </div>
      </>
    )
  }

  const renderSpecialEventModalRow = (event, index) => {
    const tag = event.is_playlist ? "PLAYLIST" : (event.songs_allowed ? "SPECIAL SONG" : "NO SONGS")
    return (
      <div className="flex-row flex-middle b-border p-15" onClick={() => onSelectSpecialEvent(event)} key={index}>
        <div className="flex-1">
          <div className="fw-600">{event.special_event_name} <span className="bg-gray ph-5 c-text-gray fw-700 fs-10 ml-5" style={{ paddingTop: 2, paddingBottom: 2}}>{tag}</span></div>
        </div>
        <div className="flex-0">
          <div className="display-flex">
            { event.active ? <MdCheckBox size={24} color={"#536DFE"}/> : <MdCheckBoxOutlineBlank size={24} color={"#CCC"}/>}
          </div>
        </div>
      </div>
    )
  }

  const renderSpecialEventsModal = () => {
    return (
      <Modal visible={isSpecialEventsModalVisible} footer={null} onCancel={() => setSpecialEventsModalVisible(false)} width={500} closable={false} maskClosable={false} wrapClassName="rounded-modal">
        <Row align="middle">
          <Col flex={1}>
            <div className="fw-700 fs-18">Special Events</div>
          </Col>
          <Col>
            <div className="display-flex" onClick={() => setSpecialEventsModalVisible(false)}><MdOutlineClose size={30} color={"#CCC"}/></div>
          </Col>
        </Row>
        <div className="">
          <div className="pt-10 pb-10">
            <div className="bg-gray pv-20 ph-20 radius-8 fs-14 text-center">Select the special events you would like to include in your event.</div>
            <div className="">
              { specialEventSections.map((x,i) => renderSpecialEventModalSection(x,i))}
            </div>
          </div>
          <button className="primary-button" type="button" onClick={() => setSpecialEventsModalVisible(false)}>Done</button>
        </div>
      </Modal>
    )
  }

  const renderSystemGenre = (genre, index) => {
    const isSelected = !isEmpty(eventGenres.find(x => x.system_genre_id == genre.system_genre_id))
    return (
      <div className="flex-row flex-middle b-border p-15" onClick={() => onSelectGenre(genre)} key={index}>
        <div className="flex-1">
          <div className="fw-600">{genre.genre_name}</div>
        </div>
        <div className="flex-0">
          <div className="display-flex">
            { isSelected ? <MdCheckBox size={24} color={"#536DFE"}/> : <MdCheckBoxOutlineBlank size={24} color={"#CCC"}/>}
          </div>
        </div>
      </div>
    )
  }

  const renderEventGenresModal = () => {
    return (
      <Modal visible={isEventGenresModalVisible} footer={null} onCancel={() => setEventGenresModalVisible(false)} width={500} closable={false} maskClosable={false} wrapClassName="rounded-modal">
        <Row align="middle">
          <Col flex={1}>
            <div className="fw-700 fs-18">Favorite Genres</div>
          </Col>
          <Col>
            <div className="display-flex" onClick={() => setEventGenresModalVisible(false)}><MdOutlineClose size={30} color={"#CCC"}/></div>
          </Col>
        </Row>
        <div className="">
          <div className="pt-10 pb-10">
            <div className="bg-gray pv-20 ph-20 radius-8 fs-14 text-center">Select the generes you would like to be played at your event.</div>
            <div className="shadow-card mb-5 mt-15">
              { systemGenres.map((x,i) => renderSystemGenre(x,i))}
            </div>
          </div>
          <button className="primary-button" type="button" onClick={() => setEventGenresModalVisible(false)}>Done</button>
        </div>
      </Modal>
    )
  }

  const renderAddSpecialEventModal = () => {
    const options = [{ text: "Special Song", value: "single" }, { text: "Playlist", value: "playlist" }]
    return (
      <Modal visible={isAddSpecialEventModalVisible} footer={null} onCancel={() => setAddSpecialEventModalVisible(false)} width={400} closable={false} maskClosable={false} wrapClassName="rounded-modal">
        <Row align="middle">
          <Col flex={1}>
            <div className="fw-700 fs-18">Add Custom Special Event</div>
          </Col>
          <Col>
            <div className="display-flex" onClick={() => setAddSpecialEventModalVisible(false)}><MdOutlineClose size={30} color={"#CCC"}/></div>
          </Col>
        </Row>
        <Form form={addSpecialEventForm} layout="vertical" name="client" onFinish={saveCustomSpecialEvent}>
          <div className="">
            <div className="pt-10 pb-15">
              <Row align="middle" gutter={[15,15]}>
                <Col xs={24}>
                  { renderInputField("Special Event Name", "special_event_name", true)}
                </Col>
                <Col xs={24}>
                  { renderFormLabel("Will you be selecting a specific song for this special event or building a playlist?")}
                  { renderSearchSelectField("", "type", "", options, true) }
                </Col>
              </Row>
            </div>
            <div className="text-right">
              <Button className="admin-small-button secondary mr-10" onClick={() => setAddSpecialEventModalVisible(false)}>Cancel</Button>
              <Button className="admin-small-button" htmlType="submit">Save</Button>
            </div>
          </div>
        </Form>
      </Modal>
    )
  }


  const renderShareMusicModal = () => {
    return (
      <ShareModal
        isVisible={isShareMusicModalVisible}
        setVisible={setShareMusicModalVisible}
        title="Share Music"
        link={`/event/${event.event_id}/music`}
      />
    )
  }

  const renderContent = () => {
    if (isLoading) {
      return <LoadingSpinner/>
    }
    return (
      <>
        <div className="toolbar-container">
          <Dropdown overlay={actionMenu()} placement="bottomRight" trigger="click">
            <div className="toolbar-button">
              Actions <MdOutlineKeyboardArrowDown style={{ fontSize: 20, color: '#536DFE', marginLeft: 8}}/>
            </div>
          </Dropdown>
        </div>
        <FloatingContainer className="ph-20" verticalPadding={20} maxWidth={600}>
          { eventLocked && canEdit && (
            <div className="message-box mb-15">
              <div className="message-icon"><MdOutlineInfo/></div>
              <div className="message-text">Changes are no longer allowed</div>
            </div>
          )}
          { renderTabs() }
          <MusicModal 
            isVisible={isModalVisible} 
            title="Select a Song" 
            onCancel={handleCancel}
            onSelect={handleSelect}
            selectedEvent={selectedEvent}
            eventName={selectedEvent.special_event_name}
            eventId={event.event_id}
            selectedPlatform={selectedPlatform}
          />
          <PlatformSelectionModal 
            isVisible={isPlatformSelectionVisible}
            setVisible={setPlatformSelectionVisible}
            selectPlatform={selectPlatform}
            addCustomSong={displayCustomSong}
          />
          <CustomSongModal 
            isVisible={isCustomSongModalVisible}
            setVisible={setCustomSongModalVisible}
            saveCustomSong={saveCustomSong}
          />
        { renderAddEditNotesModal() }
        { renderAddEditGenreNotesModal() }
        { renderSpecialEventsModal() }
        { renderAddSpecialEventModal() }
        { renderEventGenresModal() }
        { renderShareMusicModal() }
        </FloatingContainer>
      </>
    )
  }

  return (
    <AdminContent header={renderHeader()} body={renderContent()}/>
  );
}

export default ClientMusicPage;

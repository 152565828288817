import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import moment from 'moment';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { FiDownload } from "react-icons/fi";
import FloatingContainer from '../../components/floatingContainer'
import LoadingSpinner from '../../components/loading';
import ContractPreview from '../../components/contractPreview';
import ContractPDF from '../../pdfs/contractPDF';
import { get } from "lodash";
import { getEventContractPreview } from '../../api';

const ContractPreviewPage = () => {

  const [isLoading, setLoading] = useState(true);
  const [contractStatus, setContractStatus] = useState("");
  const [contract, setContract] = useState({});
  const [signers, setSigners] = useState([]);

  const params = useParams();

  useEffect(() => {
    window.scrollTo(0, 0)
    if (params.id) {
      loadPage()
    } else {
      setContractStatus("INVALID")
      setLoading(false)
    }
  }, [])

  const loadPage = async () => {
    try {
      const results = await getEventContractPreview(params.id)
      const status = get(results, "data.status", null)
      if (status == "not-found" || status == "failed") {
        setContractStatus("INVALID")
      } else if (results.data) {
        setContract(results.data)
        setSigners(results.data.signers)
      }
      setLoading(false)
    } catch {
      setContractStatus("INVALID")
      setLoading(false)
    }
  }

  const renderDocument = () => {
    return <ContractPDF contract={contract}/>
  }

  const renderContent = () => {
    if (contractStatus == "INVALID") {
      return (
        <FloatingContainer className="ph-15" verticalPadding={50} maxWidth={800}>
          <div className="bg-white border radius-8 mb-20 p-30 text-center">
            <div className="fw-700">This link is invalid.</div>
            <div className="mt-5 c-text-gray">Please check the link and try again.</div>
          </div>
        </FloatingContainer>
      )
    }

    return (
      <div>
        <div className="toolbar-container hide-print">
          <PDFDownloadLink document={ renderDocument() } fileName={`contract-${moment().format("YYYYMMDD")}.pdf`}>
            <div className="toolbar-button" onClick={() => {}}>
              <FiDownload style={{ fontSize: 20, color: '#536DFE', marginRight: 8}}/> Download PDF
            </div>
          </PDFDownloadLink>
        </div>
        <FloatingContainer verticalPadding={30} maxWidth={1000}>
          <div className="print-card">
            <ContractPreview 
              contract={contract} 
              signers={signers}
              reload={loadPage}
            />
          </div>
        </FloatingContainer>
      </div>
    )
  }

  if (isLoading) {
    return (
      <div className="mt-80">
        <LoadingSpinner/>
      </div>
    )
  }

  return (
    <div className="event-contract--container" style={{ minHeight: '100vh' }}>
      { renderContent() }
    </div>
  );
}

export default ContractPreviewPage;

import React, {useState, useEffect, useContext } from 'react';
import {useStripe} from '@stripe/react-stripe-js';
import useApi from '../hooks/useApi';
import AppContext from '../app/context';
import { refreshToken } from '../api';
import { Navigate, Link, useNavigate } from "react-router-dom";
import LoadingSpinner from '../components/loading';
import { getDeviceId } from '../helpers/deviceHelper';
import checkmarkIcon from '../images/checkmark-icon.png';

const PaymentStatus = () => {
  const stripe = useStripe();
  const [status, setStatus] = useState(null);

  const navigate = useNavigate();
  const { auth, setAuth } = useContext(AppContext);
  const [sendRequest] = useApi();

  useEffect(() => {

    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      'setup_intent_client_secret'
    );

    if (!clientSecret) {
      setStatus("secret_not_found")
      return
    }

    // Retrieve the SetupIntent
    stripe
      .retrieveSetupIntent(clientSecret)
      .then(({setupIntent}) => {
        switch (setupIntent.status) {
          case 'succeeded':
            setStatus(setupIntent.status)
            if (auth.user.account_status == "PAST_DUE") {
              setStatus("succeeded_past_due")
              setAuth({})
            } else {
              setStatus("succeeded")
              sendRequest(refreshToken({ user_id: auth.user.user_id, refresh_token: auth.refresh_token, source: "web", device_id: getDeviceId() })).then(response => {
                setAuth(response)
              })
            }
            break;
          case 'processing':
            setAuth({})
            setStatus(setupIntent.status)
            break;

          case 'requires_payment_method':
            setStatus(setupIntent.status)
            break;
        }
      });
  }, [stripe]);

  const renderPage = () => {
    if (status == "succeeded") {
      return (
        <div>
          <div className="center-content-box--title pb-20">Success!</div>
          <div className="text-center pb-20">Your payment method has been saved.</div>
          <button className="primary-button" onClick={() => navigate("/admin/dashboard")}>Continue</button>
        </div>
      )
    } else if (status == "succeeded_past_due") {
      return (
        <div>
          <div className="center-content-box--title pb-20">Success!</div>
          <div className="text-center pb-20">Your payment method has been saved. Please sign in again to access your account. If your account still shows as past due, your payment might still be processing. If this happens, please wait a few minutes and try again.</div>
          <button className="primary-button" onClick={() => navigate("/login")}>Sign In</button>
        </div>
      )
    } else if (status == "processing") {
      return (
        <div>
          <div className="center-content-box--title pb-20">Processing</div>
          <div className="text-center pb-20">Your payment is processing. Please check back later.</div>
          <button className="primary-button" onClick={() => navigate("/login")}>Sign In</button>
        </div>
      )
    } else if (status == "secret_not_found") {
      return <Navigate to="/"/>; 
    }
    return <LoadingSpinner/>;
  }


  return renderPage()
};

export default PaymentStatus;
import React, { useEffect, useState } from "react";
import { Navigate, Link, useNavigate, useLocation } from "react-router-dom";
import { isNull, isEmpty, get } from 'lodash';

import { resentVerificationEmail, sendVerificationEmail } from '../../api';
import FloatingContainer from '../../components/floatingContainer'
import appHeroImage from '../../images/app-hero.png';

const ThankYouPage = () => {

  const PAGE_TYPE_FROM_LOGIN = "login";
  const PAGE_TYPE_FROM_EMAIL = "email"
  const PAGE_TYPE_FROM_SIGNUP= "signup"
  const PAGE_TYPE_SUCCESS = "success"

  const [email, setEmail] = useState("");
  const [pageType, setPageType] = useState(PAGE_TYPE_FROM_EMAIL);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const renderContent = () => {
      return (
      <div>
        <FloatingContainer className="bg-white text-center p-20" verticalPadding={60} maxWidth={600}>
          <div className="fs-30 fw-700 line-1-2 text-center">Thank you for signing up!</div>
          <div className="fs-16 c-text-gray mt-20 line-1-3">Thank you for your interest in DJ Planning Center. As soon as the app is out of beta with the early adopters and ready for official launch, you will be notified by email.</div>
          {/* <div className="fs-16 c-text-gray mt-20 line-1-3">Thank you for your interest in DJ Planning Center. Your request has been received. We will notify you soon regarding next steps.</div> */}
          <div className="fs-16 c-text-gray mt-20 line-1-3">In the meantime, feel free to explore this site to learn more about all of it's cool features. We can't wait for you to join us!</div>
          {/* <div className="fs-18 fw-300 text-center mb-30 ph-20"></div> */}
          <button className="primary-button mt-30" style={{ width: 300 }} onClick={() => navigate("/")}>Learn More</button>
        </FloatingContainer>
        <FloatingContainer className="bg-gray ph-20" verticalPadding={60} maxWidth={600}>
          <img src={appHeroImage} width={"100%"}/>
        </FloatingContainer>
      </div>
      )
  }

  return (
    <div>
      { renderContent() }
    </div>
  );
}

export default ThankYouPage;

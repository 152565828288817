import React, { useState } from "react";
import { Row, Col, Modal, Menu, Dropdown, Form, Select, Grid } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { MdOutlineClose, MdOutlineMoreHoriz, MdOutlineEdit } from "react-icons/md";
import { FiPlus, FiGift, FiPlusSquare, FiEdit, FiTrash } from "react-icons/fi";
import { isEmpty, isUndefined, isNull, cloneDeep, chain } from "lodash";
import NumberFormat from 'react-number-format';
import { 
  getPackages,
  getAddOns
} from '../../../../api';
import { 
  renderCheckboxField,
  renderCurrencyField,
  renderFormLabel,
  renderNumberField,
  renderPercentageField,
  renderSearchSelectField,
  renderSelectField
} from '../../../../components/formFields'
import useApi from '../../../../hooks/useApi';
import useCurrency from '../../../../hooks/useCurrency';
import useAccountSettings from '../../../../hooks/useAccountSettings';
import { getNumericCurrency, formatCurrencyString, getDiscountedPrice } from '../../../../helpers/contractHelper';

const NewEventServices = (props) => {

  const { 
    selectedPackages = [], 
    selectedAddOns = [], 
    setSelectedPackages = () => {}, 
    setSelectedAddOns = () => {},
    selectedDeposit,
    setSelectedDeposit = () => {},
    fromPage = ""
  } = props;

  const [isAddPackageModalVisible, setAddPackageModalVisible] = useState(false);
  const [isNewAddOnModalVisible, setNewAddOnModalVisible] = useState(false);
  const [isEditPackageModalVisible, setEditPackageModalVisible] = useState(false);
  const [isEditAddOnModalVisible, setEditAddOnModalVisible] = useState(false);
  const [packages, setPackages] = useState([]);
  const [addOns, setAddOns] = useState([]);
  const [addOnsByCategory, setAddOnsByCategory] = useState([]);
  const [selectedPackage, setSelectedPackage] = useState({});
  const [selectedAddOn, setSelectedAddOn] = useState({});
  const [showNewAddOnQuantity, setShowNewAddOnQuantity] = useState(false);
  const [showEditAddOnQuantity, setShowEditAddOnQuantity] = useState(false);
  const [showPackageDiscount, setShowPackageDiscount] = useState(false);
  const [selectedPackageDiscountType, setSelectedPackageDiscountType] = useState("");
  const [showAddOnDiscount, setShowAddOnDiscount] = useState(false);
  const [selectedAddOnDiscountType, setSelectedAddOnDiscountType] = useState("");

  const [sendRequest] = useApi()
  const [currency, _] = useCurrency()
  const [accountSettings] = useAccountSettings()
  const [newPackageForm] = Form.useForm();
  const [newAddOnForm] = Form.useForm();
  const [editPackageForm] = Form.useForm();
  const [editAddOnForm] = Form.useForm();

  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();

  const discountTypes = [
    { value: "AMOUNT", text: "Amount" },
    { value: "PERCENTAGE", text: "Percentage" }
  ]

  const onAddPackage = async () => {
    try {
      if (packages.length == 0) {
        const packageResults = await sendRequest(getPackages())
        setPackages(packageResults)
      }
      const fieldValues = {
        package_id: null, 
        price: "", 
        discount: false, 
        discount_type: null, 
        discount_amount: "", 
        discount_percentage: ""
      }
      newPackageForm.setFieldsValue(fieldValues)
      setShowPackageDiscount(false)
      setSelectedPackageDiscountType("")
      setAddPackageModalVisible(true)
    } catch {}
  }

  const onAddPackageSubmit = (values) => {
    const foundPackage = packages.find(x => x.package_id == values["package_id"])
    const exists = !isEmpty(selectedPackages.find(x => x.package_id == values["package_id"]))
    if (!isEmpty(foundPackage) && !exists) {
      const eventPackage = {
        package_name: foundPackage.package_name,
        package_id: foundPackage.package_id,
        price: getNumericCurrency(values["price"]),
        discount: values["discount"] == true,
        discount_type: !isUndefined(values["discount_type"]) ? values["discount_type"] : null,
        discount_amount: !isUndefined(values["discount_amount"]) ? getNumericCurrency(values["discount_amount"]) : null,
        discount_percentage: !isUndefined(values["discount_percentage"]) ? values["discount_percentage"] : null
      }
      var selectedPackagesClone = cloneDeep(selectedPackages)
      selectedPackagesClone.push(eventPackage)
      setSelectedPackages(selectedPackagesClone)
    }
    setAddPackageModalVisible(false)
  }

  const onEditPackageSubmit = (values) => {
    var selectedPackagesClone = cloneDeep(selectedPackages).map(x => {
      if (x.package_id == selectedPackage.package_id) {
        return {
          ...x,
          price: getNumericCurrency(values["price"]),
          discount: values["discount"] == true,
          discount_type: !isUndefined(values["discount_type"]) ? values["discount_type"] : null,
          discount_amount: !isUndefined(values["discount_amount"]) ? getNumericCurrency(values["discount_amount"]) : null,
          discount_percentage: !isUndefined(values["discount_percentage"]) ? values["discount_percentage"] : null
        }
      }
      return x
    })
    setSelectedPackages(selectedPackagesClone)
    setEditPackageModalVisible(false)
  }

  const onPackageChange = (value) => {
    const foundPackage = packages.find(x => x.package_id == value)
    if (!isEmpty(foundPackage)) {
      newPackageForm.setFieldsValue({ price: foundPackage.price })
    } else {
      newPackageForm.setFieldsValue({ price: null })
    }
  }

  const onEditPackage = (eventPackage) => {
    setSelectedPackage(eventPackage)
    const fieldValues = {
      price: eventPackage.price, 
      discount: eventPackage.discount, 
      discount_type: eventPackage.discount_type, 
      discount_amount: eventPackage.discount_amount, 
      discount_percentage: eventPackage.discount_percentage
    }
    editPackageForm.setFieldsValue(fieldValues)
    setEditPackageModalVisible(true)
    setShowPackageDiscount(eventPackage.discount == true)
    setSelectedPackageDiscountType(eventPackage.discount_type)
  }

  const onRemovePackage = (eventPackage) => {
    var selectedPackagesClone = cloneDeep(selectedPackages)
    selectedPackagesClone = selectedPackagesClone.filter(x => x.package_id != eventPackage.package_id)
    setSelectedPackages(selectedPackagesClone)
  }

  const onAddOn = async () => {
    try {
      if (addOns.length == 0) {
        const addOnResults = await sendRequest(getAddOns())
        setAddOns(addOnResults)
        const addOnByCategoryResults = chain(addOnResults).groupBy("category_name").map((value, key) => ({ category: key, add_ons: value})).value()
        setAddOnsByCategory(addOnByCategoryResults)
      }
      const fieldValues = {
        add_on_id: null, 
        price: "",
        quantity: 1,
        discount: false, 
        discount_type: null, 
        discount_amount: "", 
        discount_percentage: ""
      }
      newAddOnForm.setFieldsValue(fieldValues)
      setShowNewAddOnQuantity(false)
      setShowAddOnDiscount(false)
      setSelectedAddOnDiscountType("")
      setNewAddOnModalVisible(true)
    } catch {}
  }

  const onNewAddOnSubmit = (values) => {
    const foundAddOn = addOns.find(x => x.add_on_id == values["add_on_id"])
    const exists = !isEmpty(selectedAddOns.find(x => x.add_on_id == values["add_on_id"]))
    if (!isEmpty(foundAddOn) && !exists) {
      const eventAddOn = {
        add_on_name: foundAddOn.add_on_name,
        add_on_id: foundAddOn.add_on_id,
        price: getNumericCurrency(values["price"]),
        quantity: (isUndefined(values["quantity"]) || isNull(values["quantity"])) ? 1 : values["quantity"],
        discount: values["discount"] == true,
        discount_type: !isUndefined(values["discount_type"]) ? values["discount_type"] : null,
        discount_amount: !isUndefined(values["discount_amount"]) ? getNumericCurrency(values["discount_amount"]) : null,
        discount_percentage: !isUndefined(values["discount_percentage"]) ? values["discount_percentage"] : null
      }
      var selectedAddOnsClone = cloneDeep(selectedAddOns)
      selectedAddOnsClone.push(eventAddOn)
      setSelectedAddOns(selectedAddOnsClone)
    }
    setNewAddOnModalVisible(false)
  }

  const onEditAddOnSubmit = (values) => {
    var selectedAddOnsClone = cloneDeep(selectedAddOns).map(x => {
      if (x.add_on_id == selectedAddOn.add_on_id) {
        return {
          ...x,
          price: getNumericCurrency(values["price"]),
          quantity: (isUndefined(values["quantity"]) || isNull(values["quantity"])) ? 1 : values["quantity"],
          discount: values["discount"] == true,
          discount_type: !isUndefined(values["discount_type"]) ? values["discount_type"] : null,
          discount_amount: !isUndefined(values["discount_amount"]) ? getNumericCurrency(values["discount_amount"]) : null,
          discount_percentage: !isUndefined(values["discount_percentage"]) ? values["discount_percentage"] : null
        }
      }
      return x
    })
    setSelectedAddOns(selectedAddOnsClone)
    setEditAddOnModalVisible(false)
  }

  const onEditAddOn = (eventAddOn) => {
    setSelectedAddOn(eventAddOn)
    setShowEditAddOnQuantity(!isNull(eventAddOn.max_quantity))
    var quantity = isNull(eventAddOn.max_quantity) ? null : eventAddOn.quantity;
    const fieldValues = {
      price: eventAddOn.price,
      quantity: quantity,
      discount: eventAddOn.discount, 
      discount_type: eventAddOn.discount_type, 
      discount_amount: eventAddOn.discount_amount, 
      discount_percentage: eventAddOn.discount_percentage
    }
    editAddOnForm.setFieldsValue(fieldValues)
    setEditAddOnModalVisible(true)
    setShowAddOnDiscount(eventAddOn.discount == true)
    setSelectedAddOnDiscountType(eventAddOn.discount_type)
  }

  const onRemoveAddOn = (eventAddOn) => {
    var selectedAddOnsClone = cloneDeep(selectedAddOns)
    selectedAddOnsClone = selectedAddOnsClone.filter(x => x.add_on_id != eventAddOn.add_on_id)
    setSelectedAddOns(selectedAddOnsClone)
  }

  const onAddOnChange = (value) => {
    const foundAddOn = addOns.find(x => x.add_on_id == value)
    if (!isEmpty(foundAddOn)) {
      setShowNewAddOnQuantity(!isNull(foundAddOn.max_quantity))
      var quantity = isNull(foundAddOn.max_quantity) ? null : 1;
      newAddOnForm.setFieldsValue({ price: foundAddOn.price, quantity: quantity })
    } else {
      setShowNewAddOnQuantity(false)
      newPackageForm.setFieldsValue({ price: null, quantity: null })
    }
  }

  const onNewPackageDiscountToggle = (e) => {
    setShowPackageDiscount(e.target.checked)
    if (e.target.checked) {
      setSelectedPackageDiscountType("AMOUNT")
      newPackageForm.setFieldsValue({ discount_type: "AMOUNT", discount_amount: "", discount_percentage: ""})
    } else {
      setSelectedPackageDiscountType("")
      newPackageForm.setFieldsValue({ discount_type: "", discount_amount: "", discount_percentage: ""})
    }
  }

  const onNewPackageDiscountTypeChange = (value) => {
    setSelectedPackageDiscountType(value)
    newPackageForm.setFieldsValue({ discount_amount: "", discount_percentage: ""})
  }

  const onEditPackageDiscountToggle = (e) => {
    setShowPackageDiscount(e.target.checked)
    if (e.target.checked) {
      setSelectedPackageDiscountType("AMOUNT")
      editPackageForm.setFieldsValue({ discount_type: "AMOUNT", discount_amount: "", discount_percentage: ""})
    } else {
      setSelectedPackageDiscountType("")
      editPackageForm.setFieldsValue({ discount_type: "", discount_amount: "", discount_percentage: ""})
    }
  }

  const onEditPackageDiscountTypeChange = (value) => {
    setSelectedPackageDiscountType(value)
    editPackageForm.setFieldsValue({ discount_amount: "", discount_percentage: ""})
  }

  const onNewAddOnDiscountToggle = (e) => {
    setShowAddOnDiscount(e.target.checked)
    if (e.target.checked) {
      setSelectedAddOnDiscountType("AMOUNT")
      newAddOnForm.setFieldsValue({ discount_type: "AMOUNT", discount_amount: "", discount_percentage: ""})
    } else {
      setSelectedAddOnDiscountType("")
      newAddOnForm.setFieldsValue({ discount_type: "", discount_amount: "", discount_percentage: ""})
    }
  }

  const onNewAddOnDiscountTypeChange = (value) => {
    setSelectedAddOnDiscountType(value)
    newAddOnForm.setFieldsValue({ discount_amount: "", discount_percentage: ""})
  }

  const onEditAddOnDiscountToggle = (e) => {
    setShowAddOnDiscount(e.target.checked)
    if (e.target.checked) {
      setSelectedAddOnDiscountType("AMOUNT")
      editAddOnForm.setFieldsValue({ discount_type: "AMOUNT", discount_amount: "", discount_percentage: ""})
    } else {
      setSelectedAddOnDiscountType("")
      editAddOnForm.setFieldsValue({ discount_type: "", discount_amount: "", discount_percentage: ""})
    }
  }

  const onEditAddOnDiscountTypeChange = (value) => {
    setSelectedAddOnDiscountType(value)
    editAddOnForm.setFieldsValue({ discount_amount: "", discount_percentage: ""})
  }

  const packageMenu = (item, index) => {
    return (
      <Menu>
        <Menu.Item className="ph-15 pv-10">
          <div onClick={() => onEditPackage(item)}>
            <MdOutlineEdit style={{ marginRight: 8}}/> Update Package
          </div>
        </Menu.Item>
        <Menu.Item className="ph-15 pv-10">
          <div onClick={() => onRemovePackage(item)}>
            <DeleteOutlined style={{ marginRight: 8}}/> Remove Package
          </div>
        </Menu.Item>
      </Menu>
    )
  };

  const addOnMenu = (item, index) => {
    return (
      <Menu>
        <Menu.Item className="ph-15 pv-10">
          <div onClick={() => onEditAddOn(item)}>
            <MdOutlineEdit style={{ marginRight: 8}}/> Update Add-On
          </div>
        </Menu.Item>
        <Menu.Item className="ph-15 pv-10">
          <div onClick={() => onRemoveAddOn(item)}>
            <DeleteOutlined style={{ marginRight: 8}}/> Remove Add-On
          </div>
        </Menu.Item>
      </Menu>
    )
  };

  const renderPackage = (eventPackage, index) => {
    const isDiscount = eventPackage.discount == true
    const isDiscountPercentage = eventPackage.discount_type == "PERCENTAGE"
    const price = formatCurrencyString(eventPackage.price, currency.code)
    const discountedPrice = getDiscountedPrice(eventPackage, currency.code)
    const discountPercentage = eventPackage.discount_percentage ? String(eventPackage.discount_percentage).replace(".00", "") + "%" : "0%"
    const discountString = `${isDiscountPercentage ? discountPercentage : formatCurrencyString(eventPackage.discount_amount, currency.code)} discount applied`

    return (
      <Row key={index} gutter={[15,15]} align="middle" className="border radius-8 pv-10 ph-5 mb-10 mh-0" wrap={false}>
        <Col onClick={() => onEditPackage(eventPackage)}>
          <div className="music-icon"><FiGift/></div>
        </Col>
        <Col flex={1} onClick={() => onEditPackage(eventPackage)}>
          <div className="fs-14 fw-600">{eventPackage.package_name}</div>
            <div className="line-1-2">
              { isDiscount ? (
                <>
                  <div>
                    <span className="c-text-gray strikethrough">{price}</span>
                    <span className=""> {discountedPrice} </span>
                  </div>
                  <div className="c-blue fs-12 mt-2">{discountString}</div>
                </>
              ) : (
                <span className="c-text-gray">{price}</span>
              )}
            </div>
        </Col>
        <Col>
          <div className="display-flex">
            <FiEdit size={20} color="#ccc" onClick={() => onEditPackage(eventPackage)}/>
          </div>
        </Col>
        <Col>
          <div className="display-flex mr-5">
            <FiTrash size={20} color="#ccc" onClick={() => onRemovePackage(eventPackage)}/>
          </div>
        </Col>
      </Row>
    )
  }

  const renderAddOn = (addOn, index) => {
    const isDiscount = addOn.discount == true
    const isDiscountPercentage = addOn.discount_type == "PERCENTAGE"
    const price = formatCurrencyString(addOn.price, currency.code)
    const discountedPrice = getDiscountedPrice(addOn, currency.code)
    const discountPercentage = addOn.discount_percentage ? String(addOn.discount_percentage).replace(".00", "") + "%" : "0%"
    const discountString = `${isDiscountPercentage ? discountPercentage : formatCurrencyString(addOn.discount_amount, currency.code)} discount applied`

    return (
      <Row key={index} gutter={[15,15]} align="middle" className="border radius-8 pv-10 ph-5 mb-10 mh-0" wrap={false}>
        <Col onClick={() => onEditAddOn(addOn)}>
          <div className="music-icon"><FiPlusSquare/></div>
        </Col>
        <Col flex={1} onClick={() => onEditAddOn(addOn)}>
          <div className="fs-14 fw-600">{addOn.add_on_name}</div>
          <div className="line-1-2">
            { isDiscount ? (
              <>
                <div>
                  <span className="c-text-gray">{addOn.quantity} x </span>
                  <span className="c-text-gray strikethrough">{price}</span>
                  <span className=""> {discountedPrice} </span>
                </div>
                <div className="c-blue fs-12 mt-2">{discountString}</div>
              </>
            ) : (
              <span className="c-text-gray">{addOn.quantity} x {price}</span>
            )}
          </div>
        </Col>
        <Col>
          <div className="display-flex">
            <FiEdit size={20} color="#ccc" onClick={() => onEditAddOn(addOn)}/>
          </div>
        </Col>
        <Col>
          <div className="display-flex mr-5">
            <FiTrash size={20} color="#ccc" onClick={() => onRemoveAddOn(addOn)}/>
          </div>
        </Col>
      </Row>
    )
  }

  const renderAddPackageModal = () => {
    const packageData = packages.map((eventPackage) => {
      return {
        value: eventPackage.package_id,
        text: eventPackage.package_name
      }
    })
    return (
      <Modal visible={isAddPackageModalVisible} footer={null} closable={false} wrapClassName="rounded-modal">
        <Row align="middle">
          <Col flex={1}>
            <div className="fw-700 fs-18">Add Package</div>
          </Col>
          <Col>
            <div className="display-flex" onClick={() => setAddPackageModalVisible(false)}><MdOutlineClose size={30} color={"#CCC"}/></div>
          </Col>
        </Row>
        <Form form={newPackageForm} layout="vertical" name="new-questionnaire" onFinish={onAddPackageSubmit}>
          <Row gutter={[10,10]} className="mt-15">
            <Col xs={24}>
              { renderSearchSelectField("Package", "package_id", "Select a package...", packageData, true, onPackageChange) }
            </Col>
            <Col xs={24}>
              { renderCurrencyField("Price", "price", true, currency.code) }
            </Col>
            <Col xs={24}>
              { renderCheckboxField("Apply discount", "discount", onNewPackageDiscountToggle) }
            </Col>
            { showPackageDiscount && (
                <Col xs={12}>
                  { renderSelectField("Discount Type", "discount_type", "Select dicount type...", discountTypes, true, onNewPackageDiscountTypeChange) }
                </Col>
              )}
              { selectedPackageDiscountType == "AMOUNT" && (
                <Col xs={24}>
                  { renderCurrencyField("Discount Amount", "discount_amount", true, currency.code) }
                </Col>
              )}
              { selectedPackageDiscountType == "PERCENTAGE" && (
                <Col xs={24}>
                  { renderPercentageField("Discount Percentage", "discount_percentage", true) }
                </Col>
              )}
          </Row>
          <div className="admin-modal-footer">
            <button className="primary-button" type="submit">Add Package</button>
            <div className="text-center mt-15">
              <div className="blue-link" onClick={() => setAddPackageModalVisible(false)}>Cancel</div>
            </div>
          </div>
        </Form>
      </Modal>
    )
  }

  const renderNewAddOnModal = () => {
    return (
      <Modal visible={isNewAddOnModalVisible} footer={null} closable={false} wrapClassName="rounded-modal">
        <Row align="middle">
          <Col flex={1}>
            <div className="fw-700 fs-18">Add Add-On</div>
          </Col>
          <Col>
            <div className="display-flex" onClick={() => setNewAddOnModalVisible(false)}><MdOutlineClose size={30} color={"#CCC"}/></div>
          </Col>
        </Row>
        <Form form={newAddOnForm} layout="vertical" name="new-add-on" onFinish={onNewAddOnSubmit}>
          <Row gutter={[10,10]} className="mt-15">
            <Col xs={24}>
              {renderFormLabel("Add-On")}
              <Form.Item 
                name={"add_on_id"}
                rules={[{ required: true, message: `Add-on is required!` }]}
              >
                <Select
                  allowClear
                  placeholder={"Select an add-on..."}
                  size='large'
                  onChange={onAddOnChange}
                >
                  { addOnsByCategory.map((x,i) => (
                    <Select.OptGroup label={x.category}>
                      {x.add_ons.map((addOn) => (
                        <Select.Option value={addOn.add_on_id} key={addOn.add_on_id}>{addOn.add_on_name}</Select.Option>
                      ))}
                    </Select.OptGroup>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24}>
              { renderCurrencyField("Price", "price", true, currency.code) }
            </Col>
            { showNewAddOnQuantity && (
              <Col xs={24}>
                { renderNumberField("Quantity", "quantity") }
              </Col>
            )}
            <Col xs={24}>
              { renderCheckboxField("Apply discount (per item)", "discount", onNewAddOnDiscountToggle) }
            </Col>
            { showAddOnDiscount && (
              <Col xs={12}>
                { renderSelectField("Discount Type", "discount_type", "Select dicount type...", discountTypes, true, onNewAddOnDiscountTypeChange) }
              </Col>
            )}
            { selectedAddOnDiscountType == "AMOUNT" && (
              <Col xs={24}>
                { renderCurrencyField("Discount Amount", "discount_amount", true, currency.code) }
              </Col>
            )}
            { selectedAddOnDiscountType == "PERCENTAGE" && (
              <Col xs={24}>
                { renderPercentageField("Discount Percentage", "discount_percentage", true) }
              </Col>
            )}
          </Row>
          <div className="admin-modal-footer">
            <button className="primary-button" type="submit">Add Add-On</button>
            <div className="text-center mt-15">
              <div className="blue-link" onClick={() => setNewAddOnModalVisible(false)}>Cancel</div>
            </div>
          </div>
        </Form>
      </Modal>
    )
  }

  const renderEditPackageModal = () => {
    return (
      <Modal visible={isEditPackageModalVisible} footer={null} closable={false} wrapClassName="rounded-modal">
        <Row align="middle">
          <Col flex={1}>
            <div className="fw-700 fs-18">Update Package</div>
          </Col>
          <Col>
            <div className="display-flex" onClick={() => setEditPackageModalVisible(false)}><MdOutlineClose size={30} color={"#CCC"}/></div>
          </Col>
        </Row>
        <Form form={editPackageForm} layout="vertical" name="edit-package" onFinish={onEditPackageSubmit}>
          <Row gutter={[10,10]} className="mt-15">
            <Col xs={24}>
              { renderFormLabel("Package")}
              <div>{ selectedPackage.package_name }</div>
            </Col>
            <Col xs={24}>
              { renderCurrencyField("Price", "price", true, currency.code) }
            </Col>
            <Col xs={24}>
              { renderCheckboxField("Apply discount", "discount", onEditPackageDiscountToggle) }
            </Col>
            { showPackageDiscount && (
              <Col xs={12}>
                { renderSelectField("Discount Type", "discount_type", "Select dicount type...", discountTypes, true, onEditPackageDiscountTypeChange) }
              </Col>
            )}
            { selectedPackageDiscountType == "AMOUNT" && (
              <Col xs={24}>
                { renderCurrencyField("Discount Amount", "discount_amount", true, currency.code) }
              </Col>
            )}
            { selectedPackageDiscountType == "PERCENTAGE" && (
              <Col xs={24}>
                { renderPercentageField("Discount Percentage", "discount_percentage", true) }
              </Col>
            )}
          </Row>
          <div className="admin-modal-footer">
            <button className="primary-button" type="submit">Update Package</button>
            <div className="text-center mt-15">
              <div className="blue-link" onClick={() => setEditPackageModalVisible(false)}>Cancel</div>
            </div>
          </div>
        </Form>
      </Modal>
    )
  }

  const renderEditAddOnModal = () => {
    return (
      <Modal visible={isEditAddOnModalVisible} footer={null} closable={false} wrapClassName="rounded-modal">
        <Row align="middle">
          <Col flex={1}>
            <div className="fw-700 fs-18">Update Add-On</div>
          </Col>
          <Col>
            <div className="display-flex" onClick={() => setEditAddOnModalVisible(false)}><MdOutlineClose size={30} color={"#CCC"}/></div>
          </Col>
        </Row>
        <Form form={editAddOnForm} layout="vertical" name="edit-add-on" onFinish={onEditAddOnSubmit}>
          <Row gutter={[10,10]} className="mt-15">
            <Col xs={24}>
              { renderFormLabel("Add-On")}
              <div>{ selectedAddOn.add_on_name }</div>
            </Col>
            <Col xs={24}>
              { renderCurrencyField("Price", "price", true, currency.code) }
            </Col>
            { showEditAddOnQuantity && (
              <Col xs={24}>
                { renderNumberField("Quantity", "quantity") }
              </Col>
            )}
            <Col xs={24}>
              { renderCheckboxField("Apply discount (per item)", "discount", onEditAddOnDiscountToggle) }
            </Col>
            { showAddOnDiscount && (
              <Col xs={12}>
                { renderSelectField("Discount Type", "discount_type", "Select dicount type...", discountTypes, true, onEditAddOnDiscountTypeChange) }
              </Col>
            )}
            { selectedAddOnDiscountType == "AMOUNT" && (
              <Col xs={24}>
                { renderCurrencyField("Discount Amount", "discount_amount", true, currency.code) }
              </Col>
            )}
            { selectedAddOnDiscountType == "PERCENTAGE" && (
              <Col xs={24}>
                { renderPercentageField("Discount Percentage", "discount_percentage", true) }
              </Col>
            )}
          </Row>
          <div className="admin-modal-footer">
            <button className="primary-button" type="submit">Update Add-On</button>
            <div className="text-center mt-15">
              <div className="blue-link" onClick={() => setEditAddOnModalVisible(false)}>Cancel</div>
            </div>
          </div>
        </Form>
      </Modal>
    )
  }

  const renderSectionHeader = (title, showAddButton = false, showLearnMore = false, action = () => {}) => {
    return (
      <Row align="middle" className="pb-10 mb-15 b-border" wrap={false}>
        <Col flex={1}>
          <div className="fs-18 fw-700">{title}</div>
        </Col>
        { showAddButton && (
          <Col>
            <div className="plus-container mr-5" onClick={() => action()}>
              <FiPlus style={{ fontSize: 20, color: '#536DFE'}}/>
            </div>
          </Col>
        )}
        { showLearnMore && (
          <Col>
            <div className="blue-link" onClick={() => action()}>Learn more</div>
          </Col>
        )}
      </Row>
    )
  }

  const renderNewEventServices = () => {
    const paddingClass = screens.md ? "ph-30 pv-25" : "p-20"
    return (
      <>
        <div className={`shadow-card mt-15 ${paddingClass}`}>
          { renderSectionHeader("Packages", selectedPackages.length > 0, false, onAddPackage) }
          { selectedPackages.map((x,i) => renderPackage(x,i)) }
          { selectedPackages.length == 0 && (
            <div className="">
              <div className="bg-gray radius-8 p-20 text-center">
                <div>A package has not been selected yet.</div>
                <button className="page-title-button mt-15" type="button" onClick={() => onAddPackage()}>Add Package</button>
              </div>
            </div>
          )}
        </div>
        <div className={`shadow-card mt-15 ${paddingClass}`}>
          { renderSectionHeader("Add-Ons", selectedAddOns.length > 0, false, onAddOn) }
          { selectedAddOns.map((x,i) => renderAddOn(x,i)) }
          { selectedAddOns.length == 0 && (
            <div className="">
              <div className="bg-gray radius-8 p-20 text-center">
                <div>No add-ons have been selected yet.</div>
                <button className="page-title-button mt-15" type="button" onClick={() => onAddOn()}>Add Add-On</button>
              </div>
            </div>
          )}
        </div>
        <div className={`shadow-card mt-15 ${paddingClass}`}>
          { renderSectionHeader("Financials") }
          <div className="">
            <Row align="middle" gutter={[20,20]}>
              <Col xs={24}>
                { renderFormLabel(accountSettings.holding_fee_term) }
                <NumberFormat 
                  className="ant-input ant-input-lg" 
                  displayType={'input'} 
                  thousandSeparator={true} 
                  prefix={currency.symbol} 
                  decimalScale={2} 
                  fixedDecimalScale={true}
                  style={{ width: '100%', maxWidth: '200px' }}
                  placeholder={`${currency.symbol}0.00`}
                  value={selectedDeposit}
                  onChange={(e) => setSelectedDeposit(e.target.value)}
                />
              </Col>
            </Row>
            { fromPage != "event-template" && (
              <div className="bg-gray radius-8 p-15 mt-15 c-text-gray fs-12 text-center">Note: A contract can be created from the event details page after the event is created.</div>
            )}
          </div>
        </div>
        { renderAddPackageModal() }
        { renderNewAddOnModal() }
        { renderEditPackageModal() }
        { renderEditAddOnModal() }
      </>
    )
  }

  return renderNewEventServices();
}

export default NewEventServices;

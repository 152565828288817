import React, { useEffect, useState } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { isUndefined, isNull, isEmpty, get } from 'lodash';
import './forgotPasswordSent.style.scss';
import djpcLogo from '../../../images/djpc-logo.png';

const ForgotPasswordSentPage = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0)

    const userEmail = get(location, 'state.email', null);
    if (!isNull(userEmail)) {
      setEmail(userEmail)
    }
    setLoading(false)
  }, [])

  const renderContent = () => {
    if (!loading && isEmpty(email)) {
      return <Navigate to="/forgot/password"/>
    } else {
      return renderPage()
    }
  }

  const renderPage = () => {
    return (
      <div className="center-content-box">
        <div className="center-content-box--title">Email sent!</div>
        <div className="center-content-box--subtitle">We have sent a link to <b>{email}</b>. Please follow the instructions in the email to reset your password.</div>
        <div className="center-content-box--form">
          <button className="primary-button" onClick={() => navigate("/login")}>Sign In</button>
        </div>
      </div>
    )
  }

  return (
    <div className="center-content-page">
      <div className="login-form-logo">
        <img src={djpcLogo} style={{ width: 350 }}/>
      </div>
      { renderContent() }
    </div>
  );
}

ForgotPasswordSentPage.propTypes = {};

export default ForgotPasswordSentPage;

import React from 'react';
import { LoadingOutlined } from '@ant-design/icons'
import './loadingSpinner.style.scss';

const LoadingSpinner = props => {
  return (
    <div className="loading-spinner-container"><LoadingOutlined style={{ fontSize: 40 }}/></div>
  );
};

export default LoadingSpinner;

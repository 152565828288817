import React from "react";
import { Row, Col, Modal } from 'antd';
import { MdOutlineClose } from "react-icons/md";
import spotifyLogo from '../images/spotify-logo.png';
import appleMusicLogo from '../images/apple-music-logo.png';

const PlatformSelectionModal = props => {

  const { isVisible, setVisible = () => {}, selectPlatform = () => {}, addCustomSong = () => {} } = props;

  return (
    <Modal visible={isVisible} footer={null} onCancel={() => setVisible(false)} width={400} closable={false} maskClosable={false} wrapClassName="rounded-modal">
      <Row align="middle">
        <Col flex={1}>
          <div className="fw-700 fs-18 text-center" style={{ marginRight: -30 }}>Select a platform</div>
        </Col>
        <Col>
          <div className="display-flex" onClick={() => setVisible(false)}><MdOutlineClose size={30} color={"#CCC"}/></div>
        </Col>
      </Row>
      <div className="bg-gray p-15 radius-8 mt-20">
        <div className="bg-white p-15 radius-8 text-center" onClick={() => selectPlatform("spotify")}>
          <img src={spotifyLogo} style={{ width: 120}}/>
        </div>
        <div className="bg-white p-15 radius-8 text-center mt-10" onClick={() => selectPlatform("apple")}>
          <img src={appleMusicLogo} style={{ width: 120}}/>
        </div>
      </div>
      <div className="display-flex flex-middle mt-15">
        <div className="b-border flex-1"></div>
        <div className="c-text-gray text-center mh-10">OR</div>
        <div className="b-border flex-1"></div>
      </div>
      <div>
        <button className="primary-button" onClick={() => addCustomSong()}>Add a custom song</button>
      </div>
    </Modal>
  );
};

export default PlatformSelectionModal;

import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Row, Col } from 'antd';
import FloatingContainer from '../../components/floatingContainer'
import FadeDownWhileInView from '../../components/fadeDownWhileInView'
import PlanCard from '../../components/planCard'
import FeatureSections from '../../components/featureSections'
import useDocumentTitle from '../../hooks/useDocumentTitle';
import { pageViewPricingEvent } from '../../helpers/facebookHelper'

const PricingPage = () => {

  const isPromo = false

  useDocumentTitle("Pricing")
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0)
    pageViewPricingEvent()
  }, [])

  const renderPlanButton = () => {
    return (
      <button className="primary-button" onClick={() => navigate("/signup")}>Start Free Trial</button>
    )
  }

  const renderContent = () => {
      return (
      <div>
        <FloatingContainer className="bg-blue-gradient" verticalPadding={60} maxWidth={1000}>
          <FadeDownWhileInView className="fs-30 fw-700 text-center c-white">Pricing</FadeDownWhileInView>
        </FloatingContainer>
        <FloatingContainer className="p-20" verticalPadding={30} maxWidth={800}>
          { isPromo ? (
            <>
              <FadeDownWhileInView delay={0.2} className="fs-16 fw-700 line-1-2 text-center c-blue mb-10">Limited time only!</FadeDownWhileInView>
              <FadeDownWhileInView delay={0.2} className="fs-24 fw-700 line-1-2 text-center mb-10">$4.99 per month for your first 6 months</FadeDownWhileInView>
              <FadeDownWhileInView delay={0.2} className="fs-14 fw-500 line-1-2 text-center mb-50">(After 30-day free trial)</FadeDownWhileInView>
            </>
          ) : (
            <FadeDownWhileInView delay={0.2} className="fs-24 fw-700 line-1-2 text-center mb-15">Choose a plan that is right for you</FadeDownWhileInView>
          )}
          <FadeDownWhileInView delay={0.2} className="line-1-2 text-center mb-30">Try DJ Planning Center FREE for 30 days. No credit card required.</FadeDownWhileInView>
          <Row gutter={[20,20]}>
            <Col xs={24} md={12}>
              <FadeDownWhileInView delay={0.4}>
                <PlanCard plan="STANDARD" price="$35" discountPrice={isPromo ? "4.99" : ""} button={renderPlanButton()}/>
              </FadeDownWhileInView>
            </Col>
            <Col xs={24} md={12}>
              <FadeDownWhileInView delay={0.6}>
                <PlanCard plan="PREMIUM" price="$60" discountPrice={isPromo ? "9.99" : ""} button={renderPlanButton()}/>
              </FadeDownWhileInView>
            </Col>
            <Col xs={24}>
              <FadeDownWhileInView delay={0.6}>
                <div className="text-center fs-12 c-text-gray f-italic">For 50+ staff members, <span style={{ cursor: "default", textDecoration: "underline" }} onClick={() => navigate("/contact")}>contact us</span> and we are happy to help!</div>
              </FadeDownWhileInView>
            </Col>
          </Row>
        </FloatingContainer>
        <FeatureSections/>
      </div>
      )
  }

  return renderContent();
}

export default PricingPage;

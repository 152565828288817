import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { Row, Col, Form, Modal, Dropdown, Menu, Input } from 'antd';
import { cloneDeep, toLower } from 'lodash';
import { getVenues, deleteVenue } from '../../../api';
import LoadingSpinner from '../../../components/loading';
import AdminContent from '../../../components/adminContent';
import FloatingContainer from '../../../components/floatingContainer'
import ErrorCard from '../../../components/errorCard';
import useApi from '../../../hooks/useApi';
import useDocumentTitle from '../../../hooks/useDocumentTitle';
import { DeleteOutlined, EditOutlined, ExclamationCircleOutlined, SearchOutlined } from '@ant-design/icons';
import { MdOutlineMoreHoriz, MdOutlineClose } from "react-icons/md";
import { FiMapPin } from "react-icons/fi";
import emptyStateImage from '../../../images/empty-state-icon.png';
import emptySearchImage from '../../../images/empty-search-icon.png';
import FadeWhileInView from '../../../components/fadeWhileInView'

const VenuesPage = () => {

  const [isLoading, setLoading] = useState(true);
  const [isError, setError] = useState(false);
  const [venues, setVenues] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [isConfirmRemoveVisible, setConfirmRemoveVisible] = useState(false);
  const [isRemoveErrorModalVisible, setRemoveErrorModalVisible] = useState(false);
  const [selectedVenue, setSelectedVenue] = useState({});

  useDocumentTitle("Venues")
  const [sendRequest] = useApi()
  const navigate = useNavigate();

  const [form] = Form.useForm();

  useEffect(() => {
    window.scrollTo(0, 0);
    refreshPage()
  }, []);

  const refreshPage = async () => {

    try {
      const venueResults = await sendRequest(getVenues("all"));
      setVenues(venueResults);
    } catch {
      setError(true)
    } finally {
      setLoading(false)
    }
  }

  const menu = (item) => {
    return (
      <Menu>
        <Menu.Item>
          <div onClick={() => handleEdit(item)}>
            <EditOutlined style={{ marginRight: 8}}/> Edit
          </div>
        </Menu.Item>
        <Menu.Item>
          <div onClick={() => handleDelete(item)}>
            <DeleteOutlined style={{ marginRight: 8}}/> Remove
          </div>
        </Menu.Item>
      </Menu>
    )
  };

  const getFilteredVenues = () => {
    var filteredVenues = cloneDeep(venues);
    if (searchText != "") {
      filteredVenues = filteredVenues.filter(x => {
        const lowerVenueName = toLower(x.venue_name)
        return lowerVenueName.includes(toLower(searchText))
      })
    }

    return filteredVenues;
  }
  
  const onNewClick = () => {
    navigate(`/admin/venues/new`)
  }

  const handleEdit = (venue) => {
    navigate(`/admin/venues/${venue.venue_id}/edit`)
  };

  const handleDelete = (venue) => {
    setSelectedVenue(venue)
    setConfirmRemoveVisible(true)
  };

  const confirmDeleteVenue = async () => {
    try {
      const deleteResponse = await sendRequest(deleteVenue(selectedVenue.venue_id));
      if (deleteResponse.status == "in-use") {
        setConfirmRemoveVisible(false)
        setRemoveErrorModalVisible(true)
      } else {
        await refreshPage()
        setConfirmRemoveVisible(false)
      }
    } catch(error) {
      setConfirmRemoveVisible(false)
    }
  }

  const renderHeader = () => {
    return (
      <Row align="middle" className="p-20">
        <Col flex={1}>
          <div className="fs-24 fw-700">Venues</div>
        </Col>
        <Col>
          { venues.length > 0 && (
            <button className="page-title-button" onClick={onNewClick}>New Venue</button>
          )}
        </Col>
      </Row>
    )
  }

  const renderVenue = (venue, index) => {
    const cityState = venue.state ? `${venue.city}, ${venue.state}` : venue.city
    return (
      <div key={index} className="flex-row flex-middle p-10 b-border">
        <div onClick={() => handleEdit(venue)}>
          <div className="card-icon"><FiMapPin/></div>
        </div>
        <div className="flex-1 mh-15" onClick={() => handleEdit(venue)}>
          <div className="fs-14 fw-700 line-1-2">{venue.venue_name}</div>
          <div className="fs-14 fw-500 c-text-gray line-1-1">{ venue.address_line_1 }, { cityState }</div>
        </div>
        <div className="mr-5">
          <Dropdown overlay={menu(venue)} placement="bottomRight" trigger="click">
            <div className="dots-container">
              <MdOutlineMoreHoriz style={{ fontSize: 24, color: '#999'}}/>
            </div>
          </Dropdown>
        </div>
      </div>
    )
  }

  const renderVenueBox = () => {
    return (
      <div className="shadow-card">
        <div className="p-15 b-border display-flex flex-row flex-middle">
          <div className="flex-1">
            <Input className="list-search-input-square" size="large" placeholder="Search venues" value={searchText} onChange={(e) => setSearchText(e.target.value)} allowClear={true} prefix={<SearchOutlined color="#e4e4e4" style={{ marginRight: 6 }}/>} />
          </div>
        </div>
        { renderVenueResults() }
      </div>
    )
  }

  const renderVenues = () => {
    if (venues.length == 0) {
      return (
        <div className="shadow-card ph-20 pv-50 text-center">
          <div>
            <img src={emptyStateImage} width={250}/>
          </div>
          <div className="fs-18 fw-700 mt-30">
            No venues yet
          </div>
          <div className="fs-14 fw-500 c-text-gray">
            Add a venue to get started!
          </div>
          <button className="primary-button mt-30" style={{ width: 250 }} onClick={onNewClick}>New Venue</button>
        </div>
      )
    } else {
      return renderVenueBox()
    }
  }

  const renderVenueResults = () => {
    if (getFilteredVenues().length > 0) {
      return getFilteredVenues().map((venue,i) => renderVenue(venue,i));
    } else {
      return (
        <FadeWhileInView duration={0.5} className="ph-20 pv-40 text-center">
          <div>
            <img src={emptySearchImage} width={200}/>
          </div>
          <div className="fs-18 fw-700 mt-30">
            No venues found
          </div>
          <div className="fs-14 fw-500 c-text-gray">
            Adjust your filter or search again!
          </div>
        </FadeWhileInView>
      )
    }
  }

  const renderConfirmRemoveModal = () => {
    return (
      <Modal visible={isConfirmRemoveVisible} closable={false} footer={null} width={400} wrapClassName="rounded-modal">
        <div className="text-right" onClick={() => setConfirmRemoveVisible(false)}><MdOutlineClose size={30} color={"#CCC"}/></div>
        <div className="mt-10">
          <div className="fw-700 fs-20 text-center">Remove Venue</div>
          <div className="fw-500 fs-14 mt-20 mb-20 text-center">Are you sure you would like to remove <span className="fw-700">{selectedVenue.venue_name}</span>? This action cannot be undone.</div>
          <button className="primary-button warning" type="button" onClick={() => confirmDeleteVenue()}>Remove Venue</button>
          <div className="text-center mt-15">
            <div className="blue-link" onClick={() => setConfirmRemoveVisible(false)}>Cancel</div>
          </div>
        </div>
      </Modal>
    )
  }

  const renderRemoveErrorModal = () => {
    return (
      <Modal visible={isRemoveErrorModalVisible} closable={false} footer={null} width={400} wrapClassName="rounded-modal">
        <div className="text-right" onClick={() => setRemoveErrorModalVisible(false)}><MdOutlineClose size={30} color={"#CCC"}/></div>
        <div className="mt-10">
          <div className="fw-700 fs-20 text-center">Cannot Remove Venue</div>
          <div className="fw-500 fs-14 mt-20 mb-20 text-center">The venue "<span className="fw-700">{selectedVenue.venue_name}</span>" is currently being used and cannot be removed.</div>
          <button className="primary-button" type="button" onClick={() => setRemoveErrorModalVisible()}>OK</button>
        </div>
      </Modal>
    )
  }

  const renderContent = () => {
    if (isLoading) {
      return <LoadingSpinner/>
    }
    if (isError) {
      return <ErrorCard/>
    }
    return (
      <FloatingContainer className="ph-20" verticalPadding={20} maxWidth={800}>
        { renderVenues() }
        { renderConfirmRemoveModal() }
        { renderRemoveErrorModal() }
      </FloatingContainer>
    )
  }

  return (
    <AdminContent header={renderHeader()} body={renderContent()}/>
  );
}

export default VenuesPage;

import React from 'react';
import { isNull } from 'lodash';
import { Grid } from 'antd';
import './adminContent.style.scss';

const TOP_HEADER_HEIGHT = 64;
const PANEL_WIDTH = 400;

const AdminContent = props => {
  const { header = null, body = null, panel = null, showPanel = false, headerHeight = 72, whiteBackground = false } = props;

  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();

  // hide / display variables
  const hasHeader = !isNull(header);
  const hasPanel = !isNull(panel);
  
  // Dynamic styles
  const bodyMainStyle1 = hasHeader ? { paddingTop: headerHeight + 20 } : {};
  const bodyMainStyle2 = hasPanel && showPanel ? { paddingRight: PANEL_WIDTH + 20 } : {};
  const panelStyle = hasHeader ? { top: TOP_HEADER_HEIGHT + headerHeight } : {};
  const backgroundColor = whiteBackground ? { backgroundColor: "#FFF" } : {};

  const renderHeader = () => {
    if (hasHeader) {
      return (
        <div className="admin-content--header">
          { header }
        </div>
      )
    }
    return null;
  }

  const renderPanel = () => {
    if (hasPanel && showPanel) {
      return (
        // <div className="admin-content--body-panel" style={panelStyle}>
        //   { panel }
        // </div>
        <div className="admin-content--body-panel">
          { panel }
        </div>
      )
    }
    return null;
  }

  return (
    <div className="admin-content--container">
      { renderHeader() }
      <div className="admin-content--body">
        <div className="admin-content--body-main" style={{ ...bodyMainStyle2, ...backgroundColor}}>
          { body }
        </div>
        {renderPanel()}
        {/* <div className="flex-0 display-flex">
          <div className="bg-blue" style={{ width: 400 }}>This</div>
        </div> */}
      </div>
      {/* { renderHeader() }
      <div className="admin-content--body">
        <div className="admin-content--body-main" style={{ ...bodyMainStyle1, ...bodyMainStyle2}}>
          <div className="admin-content--body-main-content">
            { body }
          </div>
        </div>
        { renderPanel() }
      </div> */}
    </div>
  );
};

export default AdminContent;

export const startTrialEvent = () => {
    if (process.env.NODE_ENV !== "development") {
        window.fbq("track", "StartTrial")
    }
}

export const pageViewSignUpEvent = () => {
    if (process.env.NODE_ENV !== "development") {
        window.fbq("track", "PageView-SignUp")
    }
}

export const pageViewHomeEvent = () => {
    if (process.env.NODE_ENV !== "development") {
        window.fbq("track", "PageView-Home")
    }
}

export const pageViewFeaturesEvent = () => {
    if (process.env.NODE_ENV !== "development") {
        window.fbq("track", "PageView-Features")
    }
}

export const pageViewPricingEvent = () => {
    if (process.env.NODE_ENV !== "development") {
        window.fbq("track", "PageView-Pricing")
    }
}
import moment from 'moment-timezone';
import { isUndefined, isNull } from "lodash";
import { formatTime } from "./dateHelper";

const isValid = (value) => {
  return !isUndefined(value) && !isNull(value) && value != ""
}

export const getTimeOptions = (settings) => {
  var array = []
  array.push({ text: "TBD", value: "TBD"})
  array.push({ text: "No Time", value: "NONE"})
  for (var h = 0; h < 24; h++) {
    for (var m = 0; m < 60; m += 1) {
      const hour = h > 9 ? `${h}` : `0${h}`
      const minute = m > 9 ? `${m}` : `0${m}`
      const militaryTime = `${hour}:${minute}`
      const normalTime = formatTime(militaryTime, settings)
      array.push({ text: normalTime, value: militaryTime})
    }
  }
  return array
}

export const getFormattedTime = (time, settings) => {
  if (time == "TBD") {
    return time
  } else if (time == "NONE") {
    return "--"
  } else if (isValid(time)) {
    return formatTime(time, settings)
  }
  return "TBD";
}
import UserTypes from '../constants/userTypes';
import Roles from '../constants/roles';

const hasPermission = (user, permission) => {
  if (user.user_type_id == UserTypes.SYSTEM_ADMIN) {
    return true;
  }
  if (user.role == Roles.OWNER) {
    return true;
  }
  if (user.permissions && user.permissions.includes(permission)) {
    return true;
  }
  return false;
}

const hasEventPermission = (user, permission) => {
  if (user.user_type_id != UserTypes.CLIENT) {
    return true;
  }
  if (user.permissions && user.permissions.includes(permission)) {
    return true;
  }
  return false;
}

export {
  hasPermission,
  hasEventPermission
}
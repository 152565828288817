import React, { useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Row, Col, Form, Input } from 'antd';
import AppContext from '../../app/context';
import { submitSupportTicket } from '../../api';
import FloatingContainer from '../../components/floatingContainer'
import FadeDownWhileInView from '../../components/fadeDownWhileInView'
import AdminContent from '../../components/adminContent';
import { DocArrowLink } from '../../components/docComponents'
import { FiPhoneCall, FiMail } from "react-icons/fi";
import useDocumentTitle from '../../hooks/useDocumentTitle';
import djpcConstants from '../../constants/djpc';

const AdminSupportPage = () => {

  useDocumentTitle("Support")
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const { auth, setAuth } = useContext(AppContext);
  const user = auth.user ? auth.user : {}

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const onSubmit = async (values) => {
    const body = {
      first_name: user.first_name,
      last_name: user.last_name,
      email: user.email,
      user_id: user.user_id,
      message: values.message
    }
    try {
      await submitSupportTicket(body);
      navigate("/admin/support/thank-you")
    } catch(error) {
      
    }
  }

  const renderConctactForm = () => {
    return (
      <FadeDownWhileInView className="shadow-card p-30">
        <div className="fs-24 fw-700 line-1-2 mb-30 text-center">Send us a message</div>
        <div className="fs-16 c-text-gray mt-20 line-1-3 text-center mb-40">Have a question or need some help? We are here for you. Send us a message, and we'll respond as soon as we can.</div>

        <Form form={form} layout="vertical" name="client" onFinish={onSubmit}>
          <Row gutter={[20,20]} className="mt-15">
            <Col xs={24}>
              <Form.Item
                name={"message"}
                rules={[
                  { required: true, message: `Message is required!`, validateTrigger: "onBlur" }
                ]}
              >
                <Input.TextArea rows={8} size="large" placeholder="Message"/>
              </Form.Item>   
            </Col>
          </Row>
          <button className="primary-button" type="submit">
            Submit
          </button>
        </Form>
      </FadeDownWhileInView>
    )
  }

  const renderContent = () => {
      return (
        <div>
          {/* <FloatingContainer className="bg-blue-gradient" verticalPadding={60} maxWidth={600}>
            <FadeDownWhileInView className="fs-30 fw-700 text-center c-white">Support</FadeDownWhileInView>
            <FadeDownWhileInView className="fs-16 c-white mt-20 line-1-3 ph-20 text-center">Have a question or need some help? We are here for you. Send us a message, and we'll respond as soon as we can.</FadeDownWhileInView>
          </FloatingContainer> */}
          {/* <FloatingContainer className="bg-gray text-center ph-20 pt-60" verticalPadding={0} maxWidth={600}>
            <div className="fs-16 c-text-gray mt-20 line-1-3">Have a question or need some help? We are here for you. Send us a message, and we'll respond as soon as we can.</div>
          </FloatingContainer> */}
          <FloatingContainer className="bg-gray ph-20" verticalPadding={40} maxWidth={700}>
            <Row gutter={[30]} align="middle">
              <Col xs={24}>
                { renderConctactForm() }
              </Col>
            </Row>
          </FloatingContainer>
          <FloatingContainer className="bg-white text-center p-20" verticalPadding={40} maxWidth={800}>
            <Row gutter={[0,50]}>
              {/* <Col xs={24} sm={12}>
                <FadeDownWhileInView delay={0.2}>
                  <div className="display-flex flex-1 flex-row justify-content-center">
                    <div style={{ height: 100, width: 100, backgroundColor: "#eef0ff", display: "flex", borderRadius: 50, justifyContent: 'center', alignItems: 'center'}}>
                      <FiPhoneCall size={50} color="#536DFE"/>
                    </div>
                  </div>
                  <div className="fs-20 fw-700 line-1-2 mt-20 text-center">Call Us</div>
                  <div className="fs-16 c-text-gray mt-10 line-1-3">{ djpcConstants.PHONE_NUMBER }</div>
                </FadeDownWhileInView>
              </Col> */}
              <Col xs={24} sm={24}>
                <FadeDownWhileInView delay={0.4}>
                  <div className="display-flex flex-1 flex-row justify-content-center">
                    <div style={{ height: 100, width: 100, backgroundColor: "#eef0ff", display: "flex", borderRadius: 50, justifyContent: 'center', alignItems: 'center'}}>
                      <FiMail size={50} color="#536DFE"/>
                    </div>
                  </div>
                  <div className="fs-20 fw-700 line-1-2 mt-20 text-center">Email Us</div>
                  <div className="fs-16 c-text-gray mt-10 line-1-3">{ djpcConstants.SUPPORT_EMAIL }</div>
                </FadeDownWhileInView>
              </Col>
            </Row>
          </FloatingContainer>
          <FloatingContainer className="bg-gray text-center p-20" verticalPadding={40} maxWidth={800}>
            <div className="fs-20 fw-700 line-1-2 mb-5 text-center">Explore Documentation</div>
            <DocArrowLink text="Go to docs" to="/admin/docs"/>
          </FloatingContainer>
        </div>
      )
  }

  const renderHeader = () => {
    return (
      <Row align="middle" className="p-20">
        <Col flex={1}>
          <div className="fs-24 fw-700">Help & Support</div>
        </Col>
      </Row>
    )
  }

  return (
    <AdminContent header={renderHeader()} body={renderContent()}/>
  );
}

export default AdminSupportPage;

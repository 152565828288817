import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import moment from 'moment';
import {isMobile, browserName} from 'react-device-detect';
import { BlobProvider, PDFDownloadLink } from '@react-pdf/renderer';
import LoadingSpinner from '../../components/loading';
import QuestionnairePDF from '../../pdfs/questionnairePDF';

import { getQuestionnairePreview } from '../../api';

const QuestionnairePDFPage = () => {

  const [isLoading, setLoading] = useState(true);
  const [questionnaireName, setQuestionnaireName] = useState("");
  const [eventName, setEventName] = useState("");
  const [eventDate, setEventDate] = useState("");
  const [sections, setSections] = useState([]);
  const [accountSettings, setAccountSettings] = useState({});

  const navigate = useNavigate();
  const params = useParams();

  useEffect(() => {
    window.scrollTo(0, 0)
    const questionnaireId = params.id;
    if (!questionnaireId) {
      navigate("/", { replace: true })
    } else {
      loadPage()
    }
  }, [])

  const loadPage = async () => {
    try {
      const response = await getQuestionnairePreview(params.id);
      setEventName(response.data.event_name)
      setQuestionnaireName(response.data.questionnaire_name)
      setEventDate(response.data.event_date)
      setSections(response.data.sections);
      setAccountSettings(response.data.account_settings)
      setLoading(false)
    } catch {}
  }

  if (isLoading) {
    return (
      <div className="mt-80">
        <LoadingSpinner/>
      </div>
    )
  }

  const renderDocument = () => {
    return <QuestionnairePDF questionnaireName={questionnaireName} sections={sections} eventName={eventName} eventDate={eventDate} accountSettings={accountSettings}/>
  }

  const renderDownloadPage = () => {
    return (
      <div className="text-center ph-20 pt-80">
        <div className="fw-700 fs-16">{eventName}</div>
        <div className="mb-20">Questionnaire</div>
        <PDFDownloadLink document={ renderDocument() } fileName={`questionnaire-${eventDate.format("YYYYMMDD")}.pdf`}>
          <button className="small-primary-button" onClick={() => {}} style={{ width: 200 }}>Download PDF</button>
        </PDFDownloadLink>
      </div>
    )
  }

  return (
    <BlobProvider document={renderDocument()}>
      {({ blob, url, loading, error }) => {
        if (url) {
          if (isMobile && browserName == "Chrome") {
            return renderDownloadPage()
          } else {
            window.location.replace(url)
          }
          return null;
        }

        if (error) {
          return <div className="mt-80 text-center">An error occured loading the PDF. Please try again.</div>;
        }

        return (
          <div className="mt-80">
            <LoadingSpinner/>
          </div>
        )
      }}
    </BlobProvider>
  );
}

export default QuestionnairePDFPage;

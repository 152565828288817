import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import moment from 'moment';
import { Row, Col, DatePicker, Radio, Checkbox, Input, Select, Space } from 'antd';
import { getQuestionnaire } from '../../../api';
import useApi from '../../../hooks/useApi';
import useAccountSettings from '../../../hooks/useAccountSettings';
import LoadingSpinner from '../../../components/loading';
import FloatingContainer from '../../../components/floatingContainer'
import { ArrowLeftOutlined, CheckOutlined } from '@ant-design/icons';
import AdminContent from '../../../components/adminContent';
import FullPageLayout from '../../../components/fullPageLayout';
import { getDatePickerFormat } from '../../../helpers/dateHelper';

const QuestionnairePage = (props) => {

  const navigate = useNavigate();
  const params = useParams();

  const [isLoading, setLoading] = useState(true);
  const [sections, setSections] = useState([]);
  const [questionnaireName, setQuestionnaireName] = useState("");

  const [sendRequest] = useApi()
  const [accountSettings] = useAccountSettings()

  const questionnaireId = params.id;

  useEffect(() => {
    window.scrollTo(0, 0)
    sendRequest(getQuestionnaire(questionnaireId)).then(response => {
      setQuestionnaireName(response.questionnaire_name)
      setSections(response.sections);
      setLoading(false)
    }).catch((e) => setLoading(false))
  }, [])

  const renderInput = (question) => {

    if (question.type == "text") {
      return <Input size="large"/>;
    } else if (question.type == "textarea") {
      return <Input.TextArea rows={3} size="large"/>;
    } else if (question.type == "select") {
      return (
        <Select
          allowClear
          style={{ width: '100%'}}
          placeholder={"Select"}
          optionFilterProp="children"
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          size='large'
        >
          {question.options.map((option, index) => (
            <Select.Option value={option} key={index}>{option}</Select.Option>
          ))}
        </Select>
      )
    } else if (question.type == "checkbox") {
      return question.options.map((option, index) => (
        <div className="mb-10" key={index}>
          <Checkbox>{option}</Checkbox>
        </div>
      ))
    } else if (question.type == "radio") {
      return (
        <Radio.Group>
          <Space direction="vertical">
            {question.options.map((option, index) => (
              <Radio key={index} value={option}>{option}</Radio>
            ))}
          </Space>
        </Radio.Group>
      );
    } else if (question.type == "date") {
      return <DatePicker size="large" format={getDatePickerFormat(accountSettings)}/>
    }

    return <Input size="large"/>;
    
  }

  const renderQuestion = (question, index) => {
    return (
      <div key={index} className="mb-20">
        <div className="mb-5">
          <div className="fs-14 fw-700">{ question.label }</div>
          { question.description && (
            <div className="c-text-gray line-breaks fs-12 bg-gray p-10 radius-8 mt-5 mb-10">{ question.description }</div>
          )}
        </div>
        { renderInput(question) }
    </div>
    )
  }

  const renderSection = (section, index) => {
    return (
      <div key={index} className="shadow-card mb-20">
        <div className="mh-20 pt-15 pb-10 b-border">
          <div className="fs-20 fw-700">{ section.name }</div>
          { section.description && (
            <div className="c-text-gray line-breaks">{ section.description }</div>
          )}
        </div>
        <div className="p-20">
          {section.questions.map((x,i) => renderQuestion(x,i))}
        </div>
      </div>
    )
  }

  const renderHeader = () => {
    return (
      <Row align="middle" gutter={[15,15]} className="ph-20" style={{ height: 72 }}>
        <Col>
          <div className="admin-page-back-arrow" onClick={() => navigate(-1)}> <ArrowLeftOutlined/></div>
        </Col>
        <Col flex={1}>
          <div className="admin-page-title">{ questionnaireName } <span className="fw-400 c-text-gray">(Preview)</span></div>
        </Col>
      </Row>
    )
  } 

  const renderContent = () => {
    return (
      <FloatingContainer verticalPadding={0} maxWidth={1000}>
        { sections.map((section, i) => renderSection(section, i))}
      </FloatingContainer>
    )
  }

  if (isLoading) {
    return <LoadingSpinner/>
  }

  return (
    <FullPageLayout header={renderHeader()}>
      {renderContent()}
    </FullPageLayout>
    // <AdminContent header={renderHeader()} body={renderContent()}/>
  );
}

export default QuestionnairePage;

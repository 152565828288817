import moment from 'moment-timezone';
import Timezones from '../constants/timezones';

export const getTimezoneOptions = () => {

  var options = []
  for (const [key, value] of Object.entries(Timezones)) {
    const offset = moment().tz(key).format('Z')
    const option = {
      text: `(GMT${offset}) ${value}`,
      value: key
    }
    options.push(option)
  }

  // const options = Timezones.map(x => {
  //   return {
  //     text: x.label,
  //     value: x.value
  //   }
  // })
  return options
}
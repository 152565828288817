import React, { useEffect, useContext } from "react";
import { useNavigate } from 'react-router-dom';
import AppContext from '../../app/context';
import Roles from '../../constants/roles';

const TrialEndedPage = (props) => {

  const { auth, setAuth } = useContext(AppContext);
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const renderPage = () => {
    if (auth.user.role != Roles.OWNER) {
      return (
        <div className="center-content-box p-20">
          <div className="fw-700 fs-24 text-center mt-20">Your trial has ended</div>
          <div className="mt-20 text-center">Thank you for choosing DJ Planning Center!</div>
          <div className="mt-20 mb-30 text-center">It looks like your trial has expired. Please reach out to your account owner about upgrading to a paid plan to continue using all the features DJ Planning Center has to offer.</div>
        </div>
      )
    } else {
      return (
        <div className="center-content-box p-20">
          <div className="fw-700 fs-24 text-center mt-20">Your trial has ended</div>
          <div className="mt-20 text-center">Thank you for choosing DJ Planning Center!</div>
          <div className="mv-20 text-center">It looks like your trial has expired, but it's not too late to take the next step. Upgrade to a paid plan to continue using all the features DJ Planning Center has to offer.</div>
          <button className="primary-button" onClick={() => navigate("/admin/select-plan")}>Select a Plan</button>
        </div>
      )
    }
  }

  return (
    <div className="center-content-page">
      { renderPage() }
    </div>
  );
}

export default TrialEndedPage;

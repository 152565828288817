import React, { useEffect, useContext } from "react";
import { useNavigate } from 'react-router-dom';
import { Row, Col } from 'antd';
import AdminContent from '../../../components/adminContent';
import FloatingContainer from '../../../components/floatingContainer'
import { FiMusic, FiCalendar } from "react-icons/fi";
import { AiOutlineLineChart } from "react-icons/ai";
import Permissions from '../../../constants/permissions';
import { hasPermission } from '../../../helpers/permissionHelper';
import AppContext from '../../../app/context';
import useDocumentTitle from '../../../hooks/useDocumentTitle';

const ReportsPage = () => {

  const { auth, setAuth } = useContext(AppContext);
  const user = auth.user ? auth.user : {}

  useDocumentTitle("Reports")
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const renderQuickLink = (icon, subtitle, title, link) => {
    return (
      <Col xs={24} md={24} lg={12} xl={8}>
        <div className="shadow-card p-20" onClick={() => navigate(link)}>
          <div className="quick-link-row">
            <div className="quick-link-col-1">
              <div className="quick-link-icon">{icon}</div>
            </div>
            <div className="quick-link-col-2">
              <div className="fs-10 fw-600 c-text-gray">{subtitle}</div>
              <div className="fs-16 fw-700">{title}</div>
            </div>
          </div>
        </div>
      </Col>
    )
  }

  const renderLink = (title, link, includeBorder = true) => {
    const borderClass = includeBorder ? "b-border" : ""
    return (
      <div className={`ml-15 ph-10 pv-5 ${borderClass}`}>
        <div className="blue-link" onClick={() => navigate(link)}>{title}</div>
      </div>
    )
  }

  const renderHeader = () => {
    return (
      <Row align="middle" className="p-20">
        <Col flex={1}>
          <div className="fs-24 fw-700">Reports</div>
        </Col>
      </Row>
    )
  }

  const renderContent = () => {
    return (
      <>
        <FloatingContainer className="ph-20 bg-gray" verticalPadding={20} maxWidth={1000}>
          {/* <div className="fw-700 fs-20 mb-10 pl-10">Custom Reports</div>
          <div className="shadow-card p-20">
            <div className="p-15 b-border">
              Report Name
            </div>
          </div>

          <div className="fw-700 fs-20 mb-10 pl-10 mt-20">Default Reports</div> */}
          <div className="shadow-card p-20">
            <Row gutter={[15, 15]}>
              <Col xs={24} md={12}>
                <div className="fw-700 fs-18 mb-5 display-flex flex-middle"><FiCalendar style={{ marginRight: 8 }}/> Events</div>
                { renderLink("Events by Staff", "/admin/reports/events-by-staff")}
                { renderLink("Events by Type", "/admin/reports/events-by-event-type")}
                { renderLink("Events by Day", "/admin/reports/events-by-day-of-week")}
                { renderLink("Events by Month", "/admin/reports/events-by-month")}
                { renderLink("Events by Year", "/admin/reports/events-by-year")}
                { renderLink("Events by Venue", "/admin/reports/events-by-venue")}
                { renderLink("Events by Package", "/admin/reports/events-by-package")}
                { renderLink("Events by Add-On", "/admin/reports/events-by-add-on", false)}
              </Col>
              <Col xs={24} md={12}>
                <div className="fw-700 fs-18 mb-5 display-flex flex-middle"><FiMusic style={{ marginRight: 8 }}/> Music</div>
                { renderLink("Special Event Songs", "/admin/reports/special-event-songs", false)}
                <div className="fw-700 fs-18 mb-5 display-flex flex-middle mt-20"><AiOutlineLineChart style={{ marginRight: 8 }}/> Income</div>
                { renderLink("Monthly Summary", "/admin/reports/income/monthly-summary", false)}
              </Col>
            </Row>
          </div>
          {/* { hasPermission(user, Permissions.VIEW_ALL_EVENTS) && (
            <>
              <div className="fw-700 fs-20 mb-10">Events</div>
              <Row gutter={[15, 15]}>
                { renderQuickLink(<FiCalendar/>, "EVENTS", "Events by Staff", "/admin/reports/events-by-staff")}
                { renderQuickLink(<FiCalendar/>, "EVENTS", "Events by Type", "/admin/reports/events-by-event-type")}
                { renderQuickLink(<FiCalendar/>, "EVENTS", "Events by Day", "/admin/reports/events-by-day-of-week")}
                { renderQuickLink(<FiCalendar/>, "EVENTS", "Events by Month", "/admin/reports/events-by-month")}
                { renderQuickLink(<FiCalendar/>, "EVENTS", "Events by Year", "/admin/reports/events-by-year")}
                { renderQuickLink(<FiCalendar/>, "EVENTS", "Events by Venue", "/admin/reports/events-by-venue")}
                { renderQuickLink(<FiCalendar/>, "EVENTS", "Events by Package", "/admin/reports/events-by-package")}
                { renderQuickLink(<FiCalendar/>, "EVENTS", "Events by Add-On", "/admin/reports/events-by-add-on")}
                { renderQuickLink(<FiCalendar/>, "EVENTS", "Event Details", "/admin/reports/events-by-detail")}
              </Row>
              <div className="b-border mt-40 mb-30"></div>
            </>
          )}
          <div className="fw-700 fs-20 mb-10">Music</div>
          <Row gutter={[15, 15]}>
            { renderQuickLink(<FiMusic/>, "REPORT", "Special Event Songs", "/admin/reports/special-event-songs") }
          </Row>
          { hasPermission(user, Permissions.VIEW_EVENT_FINANCIALS) && (
            <>
              <div className="b-border mt-40 mb-30"></div>
              <div className="fw-700 fs-20 mb-10">Income</div>
              <Row gutter={[15, 15]}>
                { renderQuickLink(<AiOutlineLineChart/>, "REPORT", "Monthly Summary", "/admin/reports/income/monthly-summary") }
              </Row>
            </>
          )} */}
        </FloatingContainer>
      </>
    )
  }

  return (
    <AdminContent body={renderContent()} header={renderHeader()}/>
  );
}

export default ReportsPage;

import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { Row, Col, Form, Modal, Dropdown, Menu, notification } from 'antd';
import { cloneDeep, isNull } from "lodash";
import { getAddOns, createAddOn, deleteAddOn, getAddOnCategories, deleteAddOnCategory, updateAddOnCategory } from '../../../api';
import LoadingSpinner from '../../../components/loading';
import AdminContent from '../../../components/adminContent';
import useApi from '../../../hooks/useApi';
import useCurrency from '../../../hooks/useCurrency';
import useDocumentTitle from '../../../hooks/useDocumentTitle';
import { EditOutlined, ExclamationCircleOutlined, DeleteOutlined } from '@ant-design/icons';
import { 
  renderInputField
} from '../../../components/formFields'
import FloatingContainer from '../../../components/floatingContainer'
import { MdOutlineClose, MdOutlineMoreHoriz } from "react-icons/md";
import { FiPlusSquare } from "react-icons/fi";
import emptyStateImage from '../../../images/empty-state-icon.png';
import { formatCurrencyString } from '../../../helpers/contractHelper';

const AddOnsPage = () => {

  const [isLoading, setLoading] = useState(true);
  const [addOns, setAddOns] = useState([]);
  const [addOnCategories, setAddOnCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState({});
  const [isNewModalVisible, setNewModalVisible] = useState(false);
  const [isEditCatgegoryNameModalVisible, setEditCatgegoryNameModalVisible] = useState(false);
  const [isConfirmRemoveVisible, setConfirmRemoveVisible] = useState(false);
  const [isRemoveErrorModalVisible, setRemoveErrorModalVisible] = useState(false);
  const [selectedAddOn, setSelectedAddOn] = useState({});

  useDocumentTitle("Add-Ons")
  const [sendRequest] = useApi()
  const [currency, _] = useCurrency()
  const navigate = useNavigate();

  const [form] = Form.useForm();
  const [categoryNameForm] = Form.useForm();

  useEffect(() => {
    window.scrollTo(0, 0);
    refreshPage()
  }, []);

  const refreshPage = async () => {

    try {
      const addOnResults = await sendRequest(getAddOns());
      setAddOns(addOnResults);

      const addOnCategoryResults = await sendRequest(getAddOnCategories())
      setAddOnCategories(addOnCategoryResults)

      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  const menu = (item) => {
    return (
      <Menu>
        <Menu.Item className="ph-15 pv-10">
          <div onClick={() => handleEdit(item)}>
            <EditOutlined style={{ marginRight: 8}}/> Edit
          </div>
        </Menu.Item>
        <Menu.Item className="ph-15 pv-10">
          <div onClick={() => handleDelete(item)}>
            <DeleteOutlined style={{ marginRight: 8}}/> Delete
          </div>
        </Menu.Item>
      </Menu>
    )
  };

  const categoryMenu = (item, count) => {
    return (
      <Menu>
        <Menu.Item className="ph-15 pv-10">
          <div onClick={() => handleEditCategory(item)}>
            <EditOutlined style={{ marginRight: 8}}/> Edit Category Name
          </div>
        </Menu.Item>
        { count == 0 && (
          <Menu.Item className="ph-15 pv-10">
            <div onClick={() => handleDeleteCategory(item)}>
              <DeleteOutlined style={{ marginRight: 8}}/> Delete Category
            </div>
          </Menu.Item>
          )}
      </Menu>
    )
  };
  
  const onNewClick = () => {
    form.setFieldsValue({ package_name: "" })
    setNewModalVisible(true);
  }

  const handleCancel = () => {
    setNewModalVisible(false);
  };

  const handleEdit = (addOn) => {
    navigate(`/admin/addons/${addOn.add_on_id}/edit`)
  };

  const handleDelete = (addOn) => {
    setSelectedAddOn(addOn)
    setConfirmRemoveVisible(true)
  };

  const confirmDelete = async () => {
    try {
      const results = await sendRequest(deleteAddOn(selectedAddOn.add_on_id))
      if (results.status == "in-use") {
        setRemoveErrorModalVisible(true)
        return
      }
      await refreshPage()
    } catch {
      notification.error({
        message: 'Error!',
        description: 'There was a problem deleting this add-on.',
        duration: 3
      });
    } finally {
      setConfirmRemoveVisible(false)
    }
  }

  const handleEditCategory = (category) => {
    setSelectedCategory(category)
    categoryNameForm.setFieldsValue({ category_name: category.category_name })
    setEditCatgegoryNameModalVisible(true);
  };

  const handleDeleteCategory = (category) => {
    Modal.confirm({
      title: 'Confirm',
      icon: <ExclamationCircleOutlined />,
      content: 'Are you sure you want to delete this category?',
      okText: 'Yes',
      cancelText: 'No',
      onOk: () => confirmDeleteCategory(category)
    });
  };

  const confirmDeleteCategory = async (category) => {
    try {
      await sendRequest(deleteAddOnCategory(category.add_on_category_id))
      await refreshPage()
    } catch {}
  }

  const onSubmit = async (values) => {
    try {
      const body = {
        add_on_name: values["add_on_name"],
        active: true
      }
      const results = await sendRequest(createAddOn(body))
      navigate(`/admin/addons/${results.add_on_id}/edit`)
    } catch {}
  }

  const onCategoryNameSubmit = async (values) => {
    try {
      const body = {
        category_name: values["category_name"]
      }
      await sendRequest(updateAddOnCategory(selectedCategory.add_on_category_id, body))
      await refreshPage()
      setEditCatgegoryNameModalVisible(false);
    } catch {}
  }
  
  const renderHeader = () => {
    return (
      <Row align="middle" className="p-20">
        <Col flex={1}>
          <div className="fs-24 fw-700">Add-Ons</div>
        </Col>
        <Col>
          { addOns.length > 0 && (
            <button className="page-title-button" onClick={onNewClick}>New Add-On</button>
          )}
        </Col>
      </Row>
    )
  }

  const renderAddOn = (addOn, index) => {
    return (
      <div key={index} className="flex-row p-10 b-border">
        <div onClick={() => handleEdit(addOn)}>
          <div className="card-icon"><FiPlusSquare/></div>
        </div>
        <div className="flex-col justify-content-center flex-1 mh-15" onClick={() => handleEdit(addOn)}>
          <div className="fs-14 fw-700 line-1-2">{addOn.add_on_name}</div>
          <div className="fs-14 fw-500 c-text-gray mt-2">
            { formatCurrencyString(addOn.price, currency.code) }
          </div>
        </div>
        <div className="display-flex flex-middle mr-15">
          <Dropdown overlay={menu(addOn)} placement="bottomRight" trigger="click">
            <div className="dots-container">
              <MdOutlineMoreHoriz style={{ fontSize: 24, color: '#999'}}/>
            </div>
          </Dropdown>
        </div>
      </div>
    )
  }

  const renderAddOnCategoryBox = (category, index) => {
    const addOnsCopy = cloneDeep(addOns)
    const categoryAddOns = addOnsCopy.filter(x => x.add_on_category_id == category.add_on_category_id)
    return (
      <div className="shadow-card mb-15" key={index}>
        <Row align="middle" className="mh-20 pv-15 b-border">
          <Col flex={1}>
            <div className="fs-18 fw-700">{category.category_name}</div>
          </Col>
          <Col>
            <div className="display-flex mr-5">
              <Dropdown overlay={categoryMenu(category, categoryAddOns.length)} placement="bottomRight" trigger="click">
                <div className="dots-container">
                  <MdOutlineMoreHoriz style={{ fontSize: 24, color: '#999'}}/>
                </div>
              </Dropdown>
            </div>
          </Col>
        </Row>
        { categoryAddOns.map((x,i) => renderAddOn(x,i)) }
        { categoryAddOns.length == 0 && (
          <div className="p-10">
            <div className="bg-gray radius-8 p-20 text-center">
              No add-ons are assigned to this category.
            </div>
          </div>
        )}
      </div>
    )
  }

  const renderUncategorizedAddOnBox = () => {
    const addOnsClone = cloneDeep(addOns)
    const uncategorizedAddOns = addOnsClone.filter(x => isNull(x.add_on_category_id))
    if (uncategorizedAddOns.length == 0) {
      return null
    }
    return (
      <div className="shadow-card mb-15">
        <Row align="middle" className="mh-20 pv-15 b-border">
          <Col flex={1}>
            <div className="fs-18 fw-700">Uncategorized</div>
          </Col>
        </Row>
        { uncategorizedAddOns.map((x,i) => renderAddOn(x,i)) }
      </div>
    )
  }

  const renderAddOns = () => {
    if (addOns.length == 0 && addOnCategories.length == 0) {
      return (
        <div className="shadow-card ph-20 pv-50 text-center">
          <div>
            <img src={emptyStateImage} width={250}/>
          </div>
          <div className="fs-18 fw-700 mt-30">
            No add-ons yet
          </div>
          <div className="fs-14 fw-500 c-text-gray">
            Create an add-on to get started!
          </div>
          <button className="primary-button mt-30" style={{ width: 250 }} onClick={onNewClick}>Create Add-On</button>
        </div>
      )
    } else {
      return (
        <div>
          { addOnCategories.map((x,i) => renderAddOnCategoryBox(x,i)) }
          { renderUncategorizedAddOnBox() } 
        </div>
      )
    }
  }

  const renderContent = () => {
    if (isLoading) {
      return <LoadingSpinner/>
    }
    return (
      <FloatingContainer className="ph-20" verticalPadding={20} maxWidth={800}>
        { renderAddOns() }
        { renderModal() }
        { renderEditCategoryNameModal() }
        { renderConfirmRemoveModal() }
        { renderRemoveErrorModal() }
      </FloatingContainer>
    )
  }

  const renderModal = () => {
    return (
      <Modal visible={isNewModalVisible} footer={null} closable={false} wrapClassName="rounded-modal">
        <Row align="middle">
          <Col flex={1}>
            <div className="fw-700 fs-18">New Add-On</div>
          </Col>
          <Col>
            <div className="display-flex" onClick={handleCancel}><MdOutlineClose size={30} color={"#CCC"}/></div>
          </Col>
        </Row>
        <Form form={form} layout="vertical" name="new-questionnaire" onFinish={onSubmit}>
          <Row gutter={[10,10]} className="mt-15">
            <Col xs={24}>
              {renderInputField("Add-On Name", "add_on_name", true)}
            </Col>
          </Row>
          <div className="admin-modal-footer">
            <button className="primary-button" type="submit">Continue</button>
            <div className="text-center mt-15">
              <div className="blue-link" onClick={handleCancel}>Cancel</div>
            </div>
          </div>
        </Form>
      </Modal>
    )
  }

  const renderEditCategoryNameModal = () => {
    return (
      <Modal visible={isEditCatgegoryNameModalVisible} footer={null} closable={false} wrapClassName="rounded-modal">
        <Row align="middle">
          <Col flex={1}>
            <div className="fw-700 fs-18">Edit Category Name</div>
          </Col>
          <Col>
            <div className="display-flex" onClick={() => setEditCatgegoryNameModalVisible(false)}><MdOutlineClose size={30} color={"#CCC"}/></div>
          </Col>
        </Row>
        <Form form={categoryNameForm} layout="vertical" name="edit-category-name" onFinish={onCategoryNameSubmit}>
          <Row gutter={[10,10]} className="mt-15">
            <Col xs={24}>
              {renderInputField("Category Name", "category_name", true)}
            </Col>
          </Row>
          <div className="admin-modal-footer">
            <button className="primary-button" type="submit">Save</button>
            <div className="text-center mt-15">
              <div className="blue-link" onClick={() => setEditCatgegoryNameModalVisible(false)}>Cancel</div>
            </div>
          </div>
        </Form>
      </Modal>
    )
  }

  const renderConfirmRemoveModal = () => {
    return (
      <Modal visible={isConfirmRemoveVisible} closable={false} footer={null} width={400} wrapClassName="rounded-modal">
        <div className="text-right" onClick={() => setConfirmRemoveVisible(false)}><MdOutlineClose size={30} color={"#CCC"}/></div>
        <div className="mt-10">
          <div className="fw-700 fs-20 text-center">Remove Add-On</div>
          <div className="fw-500 fs-14 mt-20 mb-20 text-center">Are you sure you would like to remove <span className="fw-700">{selectedAddOn.add_on_name}</span>? This action cannot be undone.</div>
          <button className="primary-button warning" type="button" onClick={() => confirmDelete()}>Remove Add-On</button>
          <div className="text-center mt-15">
            <div className="blue-link" onClick={() => setConfirmRemoveVisible(false)}>Cancel</div>
          </div>
        </div>
      </Modal>
    )
  }

  const renderRemoveErrorModal = () => {
    return (
      <Modal visible={isRemoveErrorModalVisible} closable={false} footer={null} width={400} wrapClassName="rounded-modal">
        <div className="text-right" onClick={() => setRemoveErrorModalVisible(false)}><MdOutlineClose size={30} color={"#CCC"}/></div>
        <div className="mt-10">
          <div className="fw-700 fs-20 text-center">Cannot Remove Add-On</div>
          <div className="fw-500 fs-14 mt-20 mb-20 text-center">This add-on is assigned to one or more events. All references to this add-on must be removed before continuing.</div>
          <button className="primary-button" type="button" onClick={() => setRemoveErrorModalVisible()}>OK</button>
        </div>
      </Modal>
    )
  }

  return (
    <AdminContent header={renderHeader()} body={renderContent()}/>
  );
}

export default AddOnsPage;

import axios from "axios";
import StorageKeys from '../constants/storageKeys';
const API_URL = process.env.REACT_APP_API_URL;

const handleRequest = config => {
  const auth = JSON.parse(localStorage.getItem(StorageKeys.AUTH))
  if (auth.access_token) {
    config.headers.Authorization = `Bearer ${auth.access_token}`;
  }
  return Promise.resolve(config);
};

const handleSuccess = response => {
  return response.data;
};

const handleError = async (error) => {
  var originalRequest = error.config;
  if (error.response.status === 401 && !originalRequest._retry) {
    originalRequest._retry = true;
    await refreshAccessToken();     
    return authenticatedService(originalRequest);
  }
  return Promise.reject(error.response.data);
};

const refreshAccessToken = async () => {
  const auth = JSON.parse(localStorage.getItem(StorageKeys.AUTH))
  const response = await axios.post(`${API_URL}/auth/token`, { user_id: auth.user.user_id, refresh_token: auth.refresh_token});
  localStorage.setItem(StorageKeys.AUTH, JSON.stringify(response.data))
  return response.data.access_token;
}

let authenticatedService = axios.create({
  baseURL: API_URL
});
authenticatedService.interceptors.request.use(handleRequest);
authenticatedService.interceptors.response.use(handleSuccess, handleError);

export default authenticatedService;

import React, { useEffect, useState, useContext } from "react";
import { Form } from 'antd';
import useApi from '../../hooks/useApi';
import { getAccountDetails } from '../../api';
import { useNavigate } from 'react-router-dom';
import AppContext from '../../app/context';
import LoadingSpinner from '../../components/loading';
import { isNull } from "lodash";

const AccountInactivePage = (props) => {

  const [isLoading, setLoading] = useState(true);
  const [isReactivate, setReactivate] = useState(false);

  const { auth, setAuth } = useContext(AppContext);
  const navigate = useNavigate();

  const [sendRequest] = useApi()

  useEffect(() => {
    window.scrollTo(0, 0);
    refreshPage()
  }, []);

  const refreshPage = async () => {
    try {
      const accountDetailsResults = await sendRequest(getAccountDetails())
      const needSubscription = isNull(accountDetailsResults.subscription_id) || isNull(accountDetailsResults.payment_method_id)
      setReactivate(needSubscription)
      setLoading(false)
    } catch {
      setLoading(false)
    }
  }

  const renderContent = () => {
    if (isReactivate) {
      return (
        <div className="center-content-box">
          <div className="center-content-box--title">Account Inactive!</div>
          <div className="center-content-box--subtitle">It looks like your account is inactive. Please click the button below to select a plan and reactivate your account.</div>
          <div className="center-content-box--form">
            <Form layout="vertical" name="admin-login" onFinish={() => {}}>
              <button className="primary-button" onClick={() => navigate("/admin/select-plan")}>Reactivate Account</button>
            </Form>
          </div>
        </div>
      )
    } else {
      return (
        <div className="center-content-box">
          <div className="center-content-box--title">Account Inactive!</div>
          <div className="center-content-box--subtitle">It looks like your account is inactive. Please contact DJ Planning Center to re-activate your account.</div>
          <div className="center-content-box--form">
            <Form layout="vertical" name="admin-login" onFinish={() => {}}>
              <button className="primary-button" onClick={() => navigate("/")}>Got it!</button>
            </Form>
          </div>
        </div>
      )
    }
  }

  if (isLoading) {
    return <LoadingSpinner/>
  }

  return (
    <div className="center-content-page">
      { renderContent() }
    </div>
  );
}

export default AccountInactivePage;

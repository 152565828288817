import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import useApi from '../../../hooks/useApi';
import { Row, Col, notification } from 'antd';
import { 
  spotifyLogout,
  getSpotifyUserProfile
} from '../../../api';
import LoadingSpinner from '../../../components/loading';
import AdminContent from '../../../components/adminContent';
import FloatingContainer from '../../../components/floatingContainer'
import { MdArrowForwardIos } from "react-icons/md";
import useDocumentTitle from '../../../hooks/useDocumentTitle';
import { isArray } from "lodash";
import AppContext from '../../../app/context';
import useBetaFeatures from '../../../hooks/useBetaFeatures';
import FeatureFlags from '../../../constants/featureFlags';
import BetaFeatures from '../../../constants/betaFeatures';

const API_URL = process.env.REACT_APP_API_URL;

const IntegrationsPage = () => {

  const [isLoading, setLoading] = useState(true);

  useDocumentTitle("Integrations")
  const { auth, _ } = useContext(AppContext);
  const navigate = useNavigate();
  const [hasBetaFeature] = useBetaFeatures()

  const [sendRequest] = useApi()

  useEffect(() => {
    window.scrollTo(0, 0);
    refreshPage();
  }, []);

  const refreshPage = async () => {
    try {
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  const renderHeader = () => {
    return (
      <div className="p-20">
        <div>
          <span className="c-blue fw-700 cursor-default" onClick={() => navigate("/admin/setup")}>
            Setup
          </span>
          <span className="fs-10 mh-5"><MdArrowForwardIos/></span>
          <span className="cursor-default c-text-gray">
            Integrations
          </span>
        </div>
        <div className="fw-700 fs-24 mt-5">Integrations</div>
      </div>
    )
  }

  const renderContent = () => {
    return (
      <>
        <FloatingContainer className="ph-20 mb-80" verticalPadding={20} maxWidth={800}>
          <div className="shadow-card p-20">
            <Row className="b-border pb-15 mb-15">
              <Col flex={1}>
                <div className="fs-20 fw-700 line-1">Email Integration</div>
              </Col>
              <Col>
                <div className="blue-link" onClick={() => {}}>Learn More</div>
              </Col>
            </Row>
            <div>
              Connect your Gmail account to DJ Planning Center to send emails from your email address.
            </div>
          </div>
        </FloatingContainer>
      </>
    )
  }

  if (isLoading) {
    return <LoadingSpinner/>
  }

  return (
    <AdminContent header={renderHeader()} body={renderContent()}/>
  );
}

export default IntegrationsPage;
import React, { useEffect, useState, useContext } from "react";
import qs from "qs";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import useApi from '../../../hooks/useApi';
import useAccountSettings from '../../../hooks/useAccountSettings';
import { Row, Col, Modal, Input, Checkbox, notification, Grid } from 'antd';
import { getEventContract, sendEventContract, updateEventContractStatus, getAccountSummary } from '../../../api';
import LoadingSpinner from '../../../components/loading';
import FloatingContainer from '../../../components/floatingContainer'
import { HiOutlineArrowLeft } from "react-icons/hi";
import { MdOutlineClose } from "react-icons/md";
import { UserOutlined, LoadingOutlined } from '@ant-design/icons';
import { isEmpty, isNull } from "lodash";
import { getClientSigners, getAdminSigners, getOtherAdminSigners} from '../../../helpers/contractHelper';
import ContractStatus from '../../../constants/contractStatus';
import AppContext from '../../../app/context';
import useDocumentTitle from '../../../hooks/useDocumentTitle';
import { formatDateMedium } from '../../../helpers/dateHelper';
const BASE_URL = process.env.REACT_APP_BASE_URL;

const SendEventContractPage = () => {

  const [isLoading, setLoading] = useState(true);
  const [isSending, setSending] = useState(false);
  const [contract, setContract] = useState({});
  const [isSendLinksModalVisible, setSendLinksModalVisible] = useState(false);
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [sendMeCopy, setSendMeCopy] = useState(false);
  const [account, setAccount] = useState({});
  const [isAdminSigner, setAdminSigner] = useState(false);
  const [fromPage, setFromPage] = useState("");

  useDocumentTitle("Send Contract")
  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();
  const [sendRequest] = useApi()
  const [accountSettings] = useAccountSettings()
  const { auth, setAuth } = useContext(AppContext);
  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();

  const eventId = params.event_id;
  const contractId = params.id;

  useEffect(() => {
    window.scrollTo(0, 0);
    refreshPage()
    const queryStrings = qs.parse(location.search, { ignoreQueryPrefix: true })
    if (queryStrings.from) {
      setFromPage(queryStrings.from)
    }
  }, []);

  const refreshPage = async () => {
    try {
      const accountSummaryResults = await sendRequest(getAccountSummary())
      setAccount(accountSummaryResults)
      const results = await sendRequest(getEventContract(eventId, contractId))
      const adminIsSigner = results.signers.filter(x => x.user_id == auth.user.user_id)
      setAdminSigner(adminIsSigner.length > 0)
      setContract(results)
      if (accountSummaryResults.company_name) {
        setSubject(`Contract from ${accountSummaryResults.company_name}`)
      } else {
        setSubject("Contract to Review and Sign")
      }
      setMessage(`Hi, \n\nYour contract is ready for review. Click the button below for details.\n\nThanks,\n${auth.user.first_name} ${auth.user.last_name}`)
    } finally {
      setLoading(false)
    }
  }

  const sendContract = async () => {
    try {
      setSending(true)
      const body = {
        subject: subject,
        message: message,
        send_copy: sendMeCopy
      }
      const results = await sendRequest(sendEventContract(eventId, contractId, body))
      navigate(`/admin/events/${eventId}/contracts/${contractId}/confirmation`)
    } catch {
      setSending(false)
    }
  }

  const copySignatureLink = async (signer) => {
    try {
      notification.success({
        message: 'Success!',
        description: 'The link has been copied to your clipboard.',
        duration: 3
      });
      if ((contract.status == ContractStatus.DRAFT || contract.status == ContractStatus.AWAITING_SEND) && signer.user_id != auth.user.user_id) {
        await sendRequest(updateEventContractStatus(eventId, contractId, { status: ContractStatus.AWAITING_SIGNATURE }))
        const results = await sendRequest(getEventContract(eventId, contractId))
        setContract(results)
      }
    } catch {}
  }

  const renderHeader = () => {
    var backPath = `/admin/events/${eventId}/contracts/${contractId}/preview`;
    var eventButtonText = "Back to event"
    if (fromPage == "contracts") {
      backPath = `/admin/contracts`
      eventButtonText = "Go to event"
    }

    if (screens.md) {
      return (
        <div className="event-contract--header p-20">
          <div className="flex-row flex-middle">
            <div className="fs-20 display-flex mr-10" onClick={() => navigate(backPath)}><HiOutlineArrowLeft/></div>
            <div className="fs-18 fw-700 mr-15 flex-ellipsis">
              { contract.event_contract_name }
            </div>
            <span className="bg-light-blue c-blue pv-5 ph-10 fs-10 fw-600 nowrap">{!isEmpty(contract.status) ? contract.status.replace("_", " ") : ""}</span>
            <div className="flex-1"></div>
            <div className="blue-link mr-10" onClick={() => navigate(`/admin/events/${eventId}?tab=documents`)}>{eventButtonText}</div>
          </div>
        </div>
      )
    } else {
      return (
        <div className="event-contract--header p-20">
          <div className="flex-row flex-middle">
            <div className="fs-20 display-flex mr-10" onClick={() => navigate(backPath)}><HiOutlineArrowLeft/></div>
            <div className="fs-18 fw-700 mr-15 flex-ellipsis">
              { contract.event_contract_name }
            </div>
            <span className="bg-light-blue c-blue pv-5 ph-10 fs-10 fw-600 nowrap">{!isEmpty(contract.status) ? contract.status.replace("_", " ") : ""}</span>
            <div className="flex-1"></div>
          </div>
          <div className="text-center mt-10">
            <div className="blue-link" onClick={() => navigate(`/admin/events/${eventId}?tab=documents`)}>{eventButtonText}</div>
          </div>
        </div>
      )
    }
  }

  const renderClientSigner = (client) => {
    var clientPhoto = null;
    return (
      <Row gutter={[15,15]} align="middle" className="mb-20">
        <Col>
          {/* { clientPhoto ? (
            <div className="small-profile-photo size-60" style={{ backgroundImage: `url(${clientPhoto})`}} onClick={() => displayPhoto(clientPhoto)}></div>
          ) : ( */}
            <div className="display-flex">
              <div className="user-profile-circle small"><UserOutlined/></div>
            </div>
          {/* )} */}
        </Col>
        <Col flex={1}>
          <div className="fs-14 fw-700">{client.first_name} {client.last_name}</div>
          <div className="fs-14 fw-500 c-text-gray line-1-1">{client.email}</div>
        </Col>
      </Row>
    )
  }

  const renderContent = () => {
    return (
      <>
        <FloatingContainer className="ph-20 mb-20 bg-gray" verticalPadding={20} maxWidth={screens.lg ? 1200 : 700}>
          <Row gutter={[20,20]}>
            <Col xs={24} lg={12}>
              <div className="bg-white border p-20">
                <Row gutter={[20,20]}>
                  <Col flex={0}>
                    <div className="fw-700">To</div>
                  </Col>
                  <Col flex={1}>
                    { getClientSigners(contract.signers).map((x,i) => renderClientSigner(x,i))}
                    { getOtherAdminSigners(contract.signers, auth.user.user_id).map((x,i) => renderClientSigner(x,i))}
                  </Col>
                </Row>
                <Row gutter={[20,20]} align="middle" className="mt-20">
                  <Col xs={24}>
                    <div className="fw-700 mb-5">Subject</div>
                    <Input size="large" value={subject} onChange={(e) => setSubject(e.target.value)}/>
                  </Col>
                  <Col xs={24}>
                    <div className="fw-700 mb-5">Message</div>
                    <Input.TextArea rows={8} size="large" value={message} onChange={(e) => setMessage(e.target.value)}/>
                  </Col>
                  <Col xs={12}>
                    <Checkbox value={true} onChange={(e) => setSendMeCopy(e.target.checked)}>{"Send me a copy"}</Checkbox>
                  </Col>
                  <Col xs={12}>
                    <div className="text-right">
                      { isSending ? (
                        <button className="small-primary-button" style={{ width: 150 }}><LoadingOutlined/></button>
                      ) : (
                        <button className="small-primary-button" style={{ width: 150 }} onClick={() => sendContract()}>Send Contract</button>
                      )}
                    </div>
                  </Col>
                  <Col xs={24}>
                    <div className="t-border text-center pt-15">
                      <div className="bg-gray p-15 radius-8">
                        <div>Prefer to copy a link and send in your own email?</div>
                        <div className="blue-link mt-5" onClick={() => setSendLinksModalVisible(true)}>Get contract links</div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col xs={24} lg={12}>
              { !screens.lg ? (
                <div className="fw-700 fs-18 mb-10 text-center t-border pt-20 mt-10">Email Preview</div>
              ) : (
                <div className="fw-700 fs-18 mb-10 text-center">Email Preview</div>
              )}
              <div className="bg-white border ph-20 pv-50">
                { !isNull(account.logo) ? (
                  <div className="text-center mb-30">
                    <img src={account.logo} style={{ maxWidth: 200 }}/>
                  </div>
                ) : (
                  <div className="text-center bg-gray mh-20 mb-30 p-20 radius-8" style={{ border: "1px dashed #CCC"}}>
                    <div className="fw-700 mb-5">Company Logo</div>
                    <div className="fs-12">Upload a logo on the <span className="blue-link fs-12" onClick={() => navigate("/admin/setup/branding")}>Branding</span> page to display it here.</div>
                  </div>
                )}
                <div className="bg-gray p-20 text-center">
                  <div className="mb-5">Your contract to review and sign</div>
                  <div className="fw-700 fs-20 mb-5">{ contract.event_contract_name }</div>
                  { !isNull(contract.expiration_datetime) && (
                    <div className="mb-5">Please sign by {formatDateMedium(contract.expiration_datetime, accountSettings, true)}</div>
                  )}
                </div>
                <div className="mt-30" style={{ whiteSpace: "pre-line" }}>{ message }</div>
                <div className="text-center mt-30">
                  <button className="primary-button" style={{ width: 250 }} onClick={() => {}}>Review Contract</button>
                </div>
              </div>
            </Col>
          </Row>
        </FloatingContainer>
      </>
    )
  }

  const renderSignatureLink = (signer, index) => {
    const link = `${BASE_URL}/contract/sign/${signer.event_contract_signer_id}`
    return (
      <div key={index} className="mt-15">
        <div className="fw-700">{ signer.first_name } { signer.last_name }</div>
        <div className="display-flex flex-row flex-middle bg-gray p-15 mt-5 radius-4">
          <div className="flex-1" style={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'pre', userSelect: 'none' }}>{link}</div>
          <CopyToClipboard text={link} onCopy={() => copySignatureLink(signer)}>
            <div className="ml-5 blue-link">Copy link</div>
          </CopyToClipboard>
        </div>
      </div>
    )
  }

  const renderSendLinksModal = () => {
    return (
      <Modal visible={isSendLinksModalVisible} closable={false} footer={null} width={500} wrapClassName="rounded-modal">
        <div className="text-right" onClick={() => setSendLinksModalVisible(false)}><MdOutlineClose size={30} color={"#CCC"}/></div>
        <div className="mt-10">
          <div className="fw-700 fs-20 text-center">Contract Links</div>
          <div className="fw-500 fs-14 mt-20 mb-20 text-center">Copy the links below to share with your clients. It's very important that you send each client their specific link, otherwise someone else could sign on their behalf.</div>
          { getClientSigners(contract.signers).map((x,i) => renderSignatureLink(x,i))}
          { getAdminSigners(contract.signers).map((x,i) => renderSignatureLink(x,i))}
          <button className="primary-button mt-50" type="button" onClick={() => setSendLinksModalVisible(false)}>Done</button>
        </div>
      </Modal>
    )
  }

  if (isLoading) {
    return (
      <div className="event-contract--container">
        <LoadingSpinner/>
      </div>
    )
  }

  return (
    <div className="event-contract--container">
      { renderHeader()}
      <div className="event-contract--body">
        <div className="event-contract--content">
          { renderContent() }
        </div>
      </div>
      { renderSendLinksModal() }
    </div>
  );
}

export default SendEventContractPage;
